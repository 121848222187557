/* eslint-disable class-methods-use-this */
class FacebookShare {
  title = ''
  fields = ['id', 'first_name', 'last_name', 'middle_name', 'name', 'email', 'link']
  scope = 'public_profile,user_link'

  process = (options) => {
    this.initSharing(options)
  }

  initSharing = ({
    callback, onError, ssrLink, id
  }) => {
    if (!ssrLink) {
      onError()
    }

    const getProfileUrl = () => {
      window.FB.login((response) => {
        const token = response.authResponse.accessToken
        if (response.authResponse) {
          window.FB.api('/me', 'get', { access_token: token, fields: this.fields, scope: this.scope }, (res) => {
            callback({ id, link: res.link })
          })
        } else {
          callback({ id, link: null })
        }
      })
    }

    window.FB.ui({
      method: 'share',
      scope: 'publish_actions',
      href: ssrLink.replace('publications/', ''), // TODO: remove replacing
    }, (response) => {
      if (Array.isArray(response)) {
        getProfileUrl()
      } else {
        onError()
      }
    })
  }

  stop() {}
}

export default new FacebookShare()
