import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { withTheme } from '@material-ui/core/styles'
import { TextField, IconButton } from '@material-ui/core'
import CopyToClipboard from 'react-copy-to-clipboard'
import Copy from '@material-ui/icons/FileCopy'

@observer
class DirectLinkTabContent extends React.Component {
  static propTypes = {
    socializeStore: PropTypes.shape({
      directLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      createDirectLink: PropTypes.func.isRequired,
      sendCopyId: PropTypes.func
    })
  }

  @observable
  isCopied = false

  @action
  setCopied = () => {
    if (!this.isCopied) {
      const { socializeStore } = this.props
      socializeStore.sendCopyId(socializeStore.directLink.id)
    }
    this.isCopied = true
  }

  onClick = (e) => {
    e.target.select()
  }

  render() {
    const {
      socializeStore: { directLink }
    } = this.props

    return (
      <div className="refari-direct-link-wrap" style={{ padding: 0 }}>
        <h4
          style={{
            marginLeft: 0,
            textAlign: 'right',
            width: '100%',
            maxWidth: '100%'
          }}
        >
          Share direct link:
        </h4>
        <div
          className="refari-align-items-center refari-copied-wrap"
          style={{
            marginLeft: 'auto',
            marginRight: 0,
            padding: 0,
            maxWidth: '100%',
            width: '100%'
          }}
        >
          {this.isCopied && (
            <TextField
              fullWidth
              name="directLink"
              onClick={this.onClick}
              value={directLink.ssr_link}
              style={{ margin: 0 }}
            />
          )}
          <CopyToClipboard text={directLink.ssr_link} onCopy={this.setCopied}>
            <div
              className="refari-copied-click-box"
              style={{ marginLeft: 'auto' }}
            >
              <IconButton>
                <Copy />
              </IconButton>
              {!this.isCopied && (
                <span className="refari-copied-click-msg">
                  Click here to copy direct link
                </span>
              )}
            </div>
          </CopyToClipboard>
          {this.isCopied && <span className="refari-copied-msg">Copied!</span>}
        </div>
      </div>
    )
  }
}

export default withTheme(DirectLinkTabContent)
