/* eslint-disable no-underscore-dangle */
import qp from 'query-parse'
import { ApiRoutes } from '../Urls'

class LinkedinGetShareToken {
  linkedinApiAuth = 'https://www.linkedin.com/oauth/v2/authorization'
  title = 'Authorization via LinkedIn'
  options = qp
    .toString({
      width: 600,
      height: 400,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')

  process = (option) => {
    this.getToken(option)
  }

  getToken = ({ clientId, callback, onError }) => {
    let callbackIsApplied = false
    window.newAuthLinkedInWindow && window.newAuthLinkedInWindow.close()

    const queries = qp.toString({
      response_type: 'code',
      client_id: clientId,
      state: Math.random().toString(36).substring(7),
      redirect_uri: ApiRoutes.auth.linkedinCallback,
      scope: 'r_liteprofile,r_emailaddress,w_member_social'
    })

    window.newAuthLinkedInWindow = window.open(
      `${this.linkedinApiAuth}?${queries}`,
      this.title,
      this.options
    )

    const receiveMessage = (event) => {
      if (event.data && event.source === window.newAuthLinkedInWindow) {
        window.removeEventListener('message', receiveMessage)
        callbackIsApplied = true
        this.stop()
        window.newAuthLinkedInWindow && window.newAuthLinkedInWindow.close()
        delete window.newAuthLinkedInWindow
        callback(event.data.code)
      }
    }

    window.addEventListener('message', receiveMessage, false)

    // check if popup is closed, call onError
    const check = () => {
      if (
        window.newAuthLinkedInWindow
        && window.newAuthLinkedInWindow.closed
        && !callbackIsApplied
      ) {
        this.stop()
        window.removeEventListener('message', receiveMessage)
        onError && onError()
        return
      }
      this._timer = setTimeout(check, 1500)
    }
    this._timer = setTimeout(check, 1500)
  }

  stop() {
    clearTimeout(this._timer)
  }
}

export default new LinkedinGetShareToken()
