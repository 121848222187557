import { RefariDTOTypes } from '@refari-frontend/types'

type WorkAddress =
  RefariDTOTypes['/dashboard/job-ads/{id}/']['get']['responses']['200']['content']['application/json']['work_address']

const formatWorkAddress = (workAddress: WorkAddress): string => {
  if (!workAddress) {
    return ''
  }

  const formattableData = []

  if (workAddress.country) {
    formattableData.push(workAddress.country)
  }

  if (workAddress.state) {
    formattableData.push(workAddress.state)
  }

  if (workAddress.city) {
    formattableData.push(workAddress.city)
  }

  if (workAddress.postal_code) {
    formattableData.push(workAddress.postal_code)
  }

  return formattableData.join(', ')
}

export default formatWorkAddress
