/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { baseUrls } from '../../utils/Urls'
import routesUrls from '../../router/routesUrls'

const Error = ({ theme, history }) => {
  const styles = {
    colorWhite: { color: theme.palette.whiteTextColor },
    colorPrimary: { color: theme.palette.primary1Color },
    backgroundPrimary: { background: theme.palette.primary1Color },
  }
  return (
    <div
      style={styles.backgroundPrimary}
      className="error flex"
    >
      <div className="container align-items-center">
        <div className="full-width row middle-md start-md center-sm start-xs">
          <div className="col-md-6 col-sm-12 col-xs-12 error-left">
            <h1
              style={styles.colorWhite}
            >
              404 – Page not found
            </h1>
            <div className="flex start-md center-sm start-xs">
              <div className="col-md-9 col-sm-6 col-xs-12">
                <h3
                  style={styles.colorWhite}
                >
                  Sorry, the page you were looking for doesn't exist.
                </h3>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: '#aff7fb',
                    color: theme.palette.primary1Color
                  }}
                  variant="contained"
                  onClick={() => {
                    history.push(routesUrls.home.link)
                  }}
                >
                  Let's go home
                </Button>
                <div
                  className="static-link"
                  style={styles.colorWhite}
                >
                  {'If this is not the first time you’ve seen this error – '}
                  <Link
                    to={routesUrls.contactUs.link}
                    style={{ color: '#aff7fb' }}
                  >
                    Contact us
                  </Link>.
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 center-md error-right">
            <img
              src={`${baseUrls.static}error.png`}
              alt="Home"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(Error)
