/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { observer } from 'mobx-react'
import Field from '../../../models/form/Field'

@observer
class SelectWithAutocomplete extends React.Component {
  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    hint: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    options: [],
    hint: ''
  }

  constructor(props) {
    super(props)

    this.selectRef = React.createRef()
  }

  onChange = (e, selected) => {
    const { fieldData, cb } = this.props
    if (!selected) return
    fieldData.update({
      value: selected.value,
      label: selected.label
    })
    cb && cb()
  }

  render() {
    const {
      hint,
      options,
      fieldData,
    } = this.props
    const { value } = fieldData
    return (
      <div>
        <Autocomplete
          id="auto-complete"
          value={value}
          ref={this.selectRef}
          onChange={this.onChange}
          autoComplete
          includeInputInList
          options={options}
          renderInput={(params) => <TextField {...params} label={hint} margin="normal" />}
          getOptionLabel={(option) => option.label}
        />
      </div>
    )
  }
}

export default SelectWithAutocomplete
