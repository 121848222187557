import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Laptop = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M422.846,132.411H89.154c-6.085,0-11.019,4.933-11.019,11.019v166.424c0,6.086,4.934,11.019,11.019,11.019    s11.019-4.933,11.019-11.019V154.448h311.654v155.405h0c0,6.086,4.934,11.019,11.019,11.019s11.019-4.933,11.019-11.019V143.429    C433.864,137.344,428.93,132.411,422.846,132.411z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M502.083,349.202h-20.295V117.797c0-18.366-14.942-33.308-33.308-33.308H63.52c-18.367,0-33.308,14.942-33.308,33.308    v231.405H9.917c-5.477,0-9.917,4.439-9.917,9.917v19.468c0,26.977,21.947,48.924,48.924,48.924h414.151    c26.977,0,48.924-21.947,48.924-48.924v-19.468C512,353.641,507.561,349.202,502.083,349.202z M52.249,117.797    c0-6.214,5.057-11.271,11.271-11.271h384.958c6.216,0,11.271,5.056,11.271,11.271v231.405H310.549    c-0.703-0.023-1.428,0.037-2.148,0.176c-4.668,0.898-8.041,4.984-8.041,9.737c0,8.371-6.81,15.18-15.179,15.18h-58.361    c-8.371,0-15.179-6.81-15.179-15.18c0-5.553-4.601-10.034-10.147-9.915H52.249V117.797z M492.166,378.587L492.166,378.587    c0,16.041-13.051,29.09-29.09,29.09H48.924c-16.04,0-29.09-13.049-29.09-29.09v-9.551h173.403    c4.287,14.491,17.72,25.094,33.582,25.094h58.361c15.862,0,29.295-10.604,33.583-25.094h173.404V378.587z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M272.331,196.459c-4.304-4.303-11.279-4.303-15.584,0l-69.297,69.3c-4.303,4.303-4.303,11.28,0,15.583    c2.152,2.152,4.972,3.227,7.791,3.227c2.82,0,5.639-1.077,7.791-3.227l69.299-69.299    C276.634,207.74,276.634,200.763,272.331,196.459z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M313.405,236.619c-4.304-4.303-11.279-4.303-15.583,0l-27.606,27.606c-4.303,4.303-4.303,11.28,0,15.584    c2.152,2.152,4.972,3.227,7.791,3.227c2.821,0,5.639-1.075,7.791-3.227l27.606-27.606    C317.708,247.9,317.708,240.923,313.405,236.619z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Laptop
Laptop.propTypes = propTypes
Laptop.defaultProps = defaultProps
