export default class EmlModal {
  constructor(data) {
    if (!data) {
      return null
    }
    this.id = data.id || ''
    this.provider = data.provider || ''
    this.title = data.title || ''
    // this.showDetailedLocation = data.show_detailed_location || false
    this.hasRecentActivity = data.has_recent_activity || false
    this.xml = data.xml || ''
    this.version = data.version
  }
}
