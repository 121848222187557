import React from 'react'
import './utils/polyfills'
import ReactDOM from 'react-dom'
import { Route, BrowserRouter, withRouter } from 'react-router-dom'
import { configure } from 'mobx'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { notifyPopupOpener } from './utils/helpers'
import renderDevGuard from './DevGuard'
import Storage from './utils/Storage'
import App from './App'
import ScrollToTop from './ScrollToTop'

configure({
  enforceActions: 'observed'
})
notifyPopupOpener()

// prevent scroll restoration
if (window.history && ('scrollRestoration' in window.history)) {
  window.history.scrollRestoration = 'manual'
}

const renderApp = () => {
  ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter>
        <ScrollToTop />
        <Route
          path="/"
          component={withRouter(App)}
        />
      </BrowserRouter>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
  )
}

if (process.env.REACT_APP_BUILD_MODE === 'production' || process.env.REACT_APP_BUILD_MODE === 'production_new') {
  renderApp()
} else if (Storage.get('dev-pass-accepted')) {
  renderApp()
} else {
  renderDevGuard(renderApp)
}
