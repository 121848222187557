import React, { Component, type FormEvent } from 'react'
import { inject, observer } from 'mobx-react'
import { Box } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import RaisedButton from 'src/components/shared/common/RaisedButton'
import FlatButton from 'src/components/shared/common/FlatButton'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import Field from 'src/models/form/Field'
import FieldsGroup from 'src/models/form/FieldsGroup'
import { validationErrors as errors } from 'src/utils/helpers'
import Input from 'src/components/shared/formComponents/Input'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'

type TemplateNameChangeFormProps = {
  theme: MUIThemeFuncReturnType
  talentAlertStore: ITalentAlertStore
  updateMessageTemplate: (templateName: string) => void
}

type TemplateNameChangeFormState = {
  duplicateNameError: boolean
}

@inject('talentAlertStore')
@observer
class TemplateNameChangeForm extends Component<
  TemplateNameChangeFormProps,
  TemplateNameChangeFormState
> {
  form: Record<string, any>

  constructor(props: TemplateNameChangeFormProps) {
    super(props)

    this.form = new FieldsGroup({
      // @ts-ignore TODO: Apply proper type after migrating Field to typescript
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),

      updatedName: new Field({
        name: 'updated_name',
        value: '',
        validators: [errors.required()]
      })
    })

    this.state = {
      duplicateNameError: false
    }
  }

  componentDidMount() {
    const { talentAlertStore } = this.props

    this.form.fields.updatedName.update(
      talentAlertStore.selectedMessageTemplate?.name || ''
    )
  }

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { updateMessageTemplate, talentAlertStore } = this.props

    if (this.form.isValid) {
      const filteredOutCurrentTemplateName =
        talentAlertStore.templateNames.filter(
          (name) => name !== talentAlertStore.selectedMessageTemplate?.name
        )

      if (
        filteredOutCurrentTemplateName.includes(
          this.form.fields.updatedName.value
        )
      ) {
        this.setState({ duplicateNameError: true })
      } else {
        updateMessageTemplate(this.form.fields.updatedName.value)
      }
    }
  }

  render() {
    const { talentAlertStore } = this.props
    const { duplicateNameError } = this.state

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Box className="mb-40">
          <Input
            required
            disableFullWidthMargin
            floatingLabelFixed="true"
            fieldData={this.form.fields.updatedName}
            label="Template name"
            type="text"
          />
        </Box>

        {duplicateNameError && (
          <Box className="mb-10">
            <p style={{ color: '#f44336' }}>
              A template with this name is already in use. Please try a
              different name.
            </p>
          </Box>
        )}

        <div>
          <FlatButton
            color="primary"
            style={{ border: '1px solid', marginRight: '24px' }}
            className="m_r_2x refari-button-flat"
            onClick={talentAlertStore.closeMessageTemplateNameChangeModal}
          >
            Cancel
          </FlatButton>
          <RaisedButton
            className="refari-button-raised"
            style={{ color: '#fff' }}
            color="primary"
            type="submit"
          >
            Update
          </RaisedButton>
        </div>
      </form>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TemplateNameChangeForm)
