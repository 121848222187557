/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable max-classes-per-file */
import { observable, computed } from 'mobx'
import { utils, helpers } from '../utils/helpers'
import RootStore from '../stores/RootStore'
import capitalize from 'lodash.capitalize'

const referrer = 'referrer'

export default class User {
  @observable email
  @observable firstName
  @observable lastName
  @observable id
  @observable role
  @observable userType
  @observable phone
  @observable position
  @observable location
  @observable locationJson
  @observable primaryAgency

  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.firstName = data.first_name || ''
    this.lastName = data.last_name || ''
    this.email = data.email
    this.userType = data.user_type

    // if(this.userType === "agency")
    this.role = this.userType === 'agency' ? data.agency_user.role : null
    this.availableProfiles = data.available_profiles || []
    this.unverifiedEmail =
      this.userType === 'agency' ? data.agency_user.new_unverified_email : null
    this.phone =
      this.userType === 'agency'
        ? data.agency_user?.phone
        : data.referrer?.phone
    this.agencies =
      this.userType === 'agency' || this.userType === 'hiring_manager'
        ? data.agency_user?.agencies
        : data.referrer?.agencies
    this.position =
      this.userType === 'agency' ? data.agency_user.position : null
    /**
     * @deprecated
     */
    this.location =
      this.userType === 'agency' ? data.agency_user.location || '' : ''
    this.locationJson =
      helpers.isObject(data?.agency_user?.location_json) &&
      Object.keys(data.agency_user.location_json).length > 0
        ? data.agency_user.location_json
        : null

    /**
     * @todo not present in the endpoint
     */
    this.memberSinces = data.member_since || ''
    this.primaryAgency = data.primary_agency
    this.canCrmDelete = data.can_crm_delete || false
    this.needPrimaryAgency = data.need_primary_agency
    this.lastActive = data.last_active || ''
  }

  @computed
  get fullName() {
    if (!this.firstName && !this.lastName) {
      return this.email || '-'
    }

    return `${this.firstName} ${this.lastName}`.trim() || '-'
  }

  @computed
  get isReferrer() {
    return this.userType === referrer
  }

  @computed
  get isHiringManager() {
    return this.userType === 'hiring_manager'
  }

  @computed
  get isAgency() {
    return this.userType === 'agency'
  }

  @computed
  get memberSinceDate() {
    if (this.memberSinces) {
      return utils.dateFormatter(
        this.memberSinces,
        '/',
        '',
        RootStore.agency.currency
      )
    }
    return utils.dateFormatter('', '/', '', RootStore.agency.currency)
  }

  @computed
  get memberLastActiveDate() {
    if (this.lastActive) {
      return utils.dateFormatter(this.lastActive, '/', '')
    }
    return ''
  }

  @computed
  get agenciesPresentation() {
    if (!this.agencies || (this.agencies && !this.agencies.length)) {
      return '-'
    }
    return this.agencies.map((agency) => agency.name).join(', ')
  }

  @computed
  get primaryCompanyPresentation() {
    if (
      !this.agencies ||
      (this.agencies && !this.agencies.length) ||
      this.userType !== 'agency'
    ) {
      return 'NA'
    }
    if (!this.primaryAgency) {
      return '-'
    }
    const selectedAgency = this.agencies
      .filter((agency) => agency.id === this.primaryAgency)
      .map((ag) => ag.name)
    return selectedAgency && selectedAgency.length > 0 ? selectedAgency[0] : '-'
  }

  @computed
  get userTypePresentation() {
    if (this.userType === 'referrer') {
      return 'General user'
    }
    return this.userType + (this.role ? ` (${this.role})` : '')
  }
}

export class Consultant extends User {
  @observable registered
  @observable status
  @observable referralsCount
  @observable jobsCount
  @observable slug
  @observable avatar
  @observable suggestionCount
  @observable suggestionAcceptedCount
  @observable applicantsСount
  @observable progressEventsСount

  constructor(data) {
    if (!data) data = {}
    super(data)
    this.registered = data.member_since
    this.status = data.status
    this.referralsCount = data.referrals_count
    this.jobsCount = data.jobs_count
    this.externalId = data.external_id
    this.suggestionCount = data.suggestion_count
    this.suggestionAcceptedCount = data.suggestion_accepted_count
    this.applicantsСount = data.applicants_count
    this.canDelete = data.can_delete || false
    this.progressEventsСount = data.progress_events_count
    this.gamificationShowInLeaders = data.gamification_show_in_leaders || false
    this.useToprec = data.use_toprec || false
    this.source = data.source
    this.slug = data.slug
    this.video = data.video
    this.role = data.role
    this.inviterName = data.inviter_name
    this.avatar = helpers.isObject(data.avatar)
      ? new Avatar(data.avatar)
      : data.avatar
    this.avatarAlternative = helpers.isObject(data.avatar_alternative)
      ? new Avatar(data.avatar_alternative)
      : data.avatar_alternative
    /**
     * @note backend changes to user response is not reflected to consultant details
     */
    this.role = this.role || data.role
    this.canBeProfileOnly = data.can_be_profile_only
    this.is_multi_company = data.is_multi_company
    this.is_toprec_agency = data.is_toprec_agency
    this.is_gamification_agency = data.is_gamification_agency
    this.is_ats_linked = data.is_ats_linked
    this.is_user_invited = data.is_user_invited
    this.is_profile_filled = data.is_profile_filled
  }

  @computed
  get memberSince() {
    return utils.dateFormatter(
      this.registered,
      '/',
      '',
      RootStore.agency.currency
    )
  }

  @computed
  get memberRole() {
    if (this.role === 'profile_only') return 'Profile Only'
    return capitalize(this.role)
  }
}

export class Referrer extends User {
  @observable avgRating
  @observable avgSharesRating
  @observable avgApplicationsRating
  @observable externalId
  @observable dateLastShareCreated
  @observable isTrusted
  @observable multiplier

  constructor(data) {
    if (!data) data = {}
    super(data)
    this.avgRating = data.avg_rating || 0
    this.avgSharesRating = data.avg_shares_rating || 0
    this.avgApplicationsRating = data.avg_applications_rating || 0
    this.referralsCount = data.referrals_count
    this.externalId = data.external_id
    this.dateLastShareCreated = data.date_last_share_created
    this.isTrusted = data.is_trusted
    this.isAgencyUser = data.is_agency_user
    this.residentForAgencies = data.resident_for_agencies || []
    this.multiplier = data.multiplier || '1.0'
    this.applicantsCount = data.applicants_count || 0
    this.progressEventsСount = data.progress_events_count
  }

  @computed
  get lastShare() {
    if (!this.dateLastShareCreated) return '-'
    return utils.dateFormatter(
      this.dateLastShareCreated,
      '/',
      '',
      RootStore.agency.currency
    )
  }
}

export class Candidate extends User {
  @observable agency
  @observable jobTitle
  @observable companyName
  @observable linkedinLink
  @observable resume

  constructor(data) {
    if (!data) data = {}
    super(data)
    this.agency = data.agency
    this.jobTitle = data.job_title || '-'
    this.companyName = data.company_name || '-'
    this.linkedinLink = data.linkedin_profile_url || '-'
    this.resume = data.resume
  }
}

class Avatar {
  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.original = data.original
    this.thumb = data.thumb
    this.thumb_small = data.thumb_small
  }
}
