type QuestionSetLiteSchema = {
  id: number
  name: string
}

export interface IQuestionSetLite {
  id: QuestionSetLiteSchema['id']
  value: QuestionSetLiteSchema['id']
  name: QuestionSetLiteSchema['name']
}

class QuestionSetLite implements IQuestionSetLite {
  id: IQuestionSetLite['id']
  value: QuestionSetLiteSchema['id']
  name: IQuestionSetLite['name']

  constructor(data: QuestionSetLiteSchema) {
    this.id = data.id
    this.value = data.id
    this.name = data.name
  }
}

export default QuestionSetLite
