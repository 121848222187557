import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { matchPath } from 'react-router'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('widgetsStore')
@inject('agenciesStore')
@observer
class Widgets extends React.Component {
  static propTypes = {
    widgetsStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.widgetsStore.initTab(pathname)
  }

  componentDidUpdate() {
    const { pathname } = this.props.history.location
    this.props.widgetsStore.initTab(pathname)
  }

  onTabChange = (link) => {
    this.props.widgetsStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props
    const isCategoryIDsPath = matchPath(this.props.location.pathname, {
      path: URLs.widgets.children.CategoryIDs.link
    })
    const containerStyle = isCategoryIDsPath ? { maxWidth: '100%' } : {}
    return (
      <div className="widgets">
        <div className="widgets__container container" style={containerStyle}>
          <div
            className={classNames({
              'row flex-center': !isCategoryIDsPath
            })}
          >
            {isFetch ? (
              <div className="page-preload-spinner col-xs-12 col-sm-3 center-xs align-items-center">
                <CircularProgress size={80} color="primary" />
              </div>
            ) : (
              <div
                className={classNames({
                  'col-xs-12 col-sm-10 col-lg-9': !isCategoryIDsPath
                })}
              >
                <Switch>
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.jobBoard.path}`}
                    component={asyncComponent(() => import('./JobBoard'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.talentBoard.path}`}
                    component={asyncComponent(() => import('./TalentBoard'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.searchBar.path}`}
                    component={asyncComponent(() => import('./SearchBar'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.latestJobs.path}`}
                    component={asyncComponent(() => import('./LatestJobs'))}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.widgets.children.widgetAbout.path}`}
                    component={asyncComponent(() => import('./WidgetAbout'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.floatingLink.path}`}
                    component={asyncComponent(() => import('./FloatingLink'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.yourRatings.path}`}
                    component={asyncComponent(() => import('./YourRatings'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.testimonials.path}`}
                    component={asyncComponent(() => import('./Testimonial'))}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.testimonialGrids.path}`}
                    component={asyncComponent(() =>
                      import('./TestimonialGrid')
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.registerWithUs.path}`}
                    component={asyncComponent(() =>
                      import('./CandidateRegistration')
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={`${match.url}${URLs.widgets.children.referral.path}`}
                    component={asyncComponent(() => import('./Referral'))}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.widgets.children.CategoryIDs.path}`}
                    component={asyncComponent(() => import('./CategoryIDs'))}
                  />
                </Switch>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(Widgets)
