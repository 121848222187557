/* eslint-disable prefer-destructuring */
/* eslint-disable import/extensions */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import { Image, Group } from 'react-konva'
import { parseGIF, decompressFrames } from 'gifuct-js'

const CanvasGIFCircleImage = ({
  x,
  y,
  width,
  height,
  imageUrl,
  setFrames,
  manualFrameIndex = 0,
  selectedLogo,
  isFinalRender = false,
  isTestimonials
}) => {
  const imageRef = React.useRef(null)
  const canvas = React.useMemo(() => document.createElement('canvas'), [])
  const ctx = canvas.getContext('2d')

  const [frameIndex, setFrameIndex] = useState(null)
  const [loadedFrames, setLoadedFrames] = useState([])
  const [timerId, setTimerId] = useState(null)

  React.useEffect(() => {
    fetch(imageUrl)
      .then((resp) => resp.arrayBuffer())
      .then((buff) => parseGIF(buff))
      .then((_gif) => {
        const frames = decompressFrames(_gif, true)
        setFrames(frames)
        setLoadedFrames(frames)
      })
    return () => {
      setLoadedFrames([])
    }
  }, [imageUrl, canvas])

  React.useEffect(() => {
    if (isFinalRender) {
      setFrameIndex(0)
    }
  }, [isFinalRender])

  React.useEffect(() => {
    if (isFinalRender) {
      setFrameIndex(manualFrameIndex)
    }
  }, [manualFrameIndex])

  React.useEffect(() => {
    if (loadedFrames.length > 0) {
      canvas.width = loadedFrames[0].dims.width
      canvas.height = loadedFrames[0].dims.height

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      setFrameIndex(0)
    }
  }, [loadedFrames])

  React.useEffect(() => {
    renderFrame()
  }, [frameIndex])

  const renderFrame = () => {
    if (loadedFrames.length === 0 || frameIndex > loadedFrames.length - 1)
      return
    // get the frame
    const frame = loadedFrames[frameIndex]

    const start = new Date().getTime()

    // draw the patch
    drawPatch(frame)

    imageRef.current.getLayer().draw()

    const end = new Date().getTime()
    const diff = end - start

    if (!isFinalRender) {
      const delay = Math.max(0, Math.floor((frame.delay || 100) - diff))

      // delay the next gif frame
      const tId = setTimeout(() => {
        // update the frame index
        requestAnimationFrame(() => {
          setFrameIndex(
            frameIndex < loadedFrames.length - 1 ? frameIndex + 1 : 0
          )
        })
      }, delay)

      if (timerId) clearTimeout(timerId)
      setTimerId(tId)
    }
  }

  const drawPatch = (frame) => {
    const { dims } = frame

    const imageData = ctx.createImageData(dims.width, dims.height)
    const origData = ctx.getImageData(
      dims.left,
      dims.top,
      dims.width,
      dims.height
    ).data

    const arrPatch = new Array(dims.width * dims.height * 4)
    // Iterate through every pixel
    for (let i = 0; i < frame.pixels.length; i += 1) {
      // Modify pixel data
      if (frame.transparentIndex === frame.pixels[i]) {
        arrPatch[i * 4 + 0] = origData[i * 4 + 0]
        arrPatch[i * 4 + 1] = origData[i * 4 + 1]
        arrPatch[i * 4 + 2] = origData[i * 4 + 2]
        arrPatch[i * 4 + 3] = origData[i * 4 + 3]
      } else {
        const color = frame.colorTable[frame.pixels[i]]
        arrPatch[i * 4 + 0] = color[0] // R value
        arrPatch[i * 4 + 1] = color[1] // G value
        arrPatch[i * 4 + 2] = color[2] // B value
        arrPatch[i * 4 + 3] = 255 // A value
      }
    }
    // set the patch data as an override
    imageData.data.set(arrPatch)

    // draw the patch back over the canvas
    ctx.putImageData(imageData, dims.left, dims.top)
  }

  const clipFunc = (ctxData) => {
    ctxData.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, false)
  }
  // if(isTestimonials) {

  // }

  return (
    <>
      {selectedLogo && selectedLogo === 'wide_logo' && !isTestimonials ? (
        <Group draggable={false}>
          <Image
            image={canvas}
            ref={imageRef}
            x={x}
            y={y}
            width={width}
            height={height}
          />
        </Group>
      ) : (
        <Group clipFunc={clipFunc} draggable={false}>
          <Image
            image={canvas}
            ref={imageRef}
            x={x}
            y={y}
            width={width}
            height={height}
          />
        </Group>
      )}
    </>
  )
}

export default CanvasGIFCircleImage
