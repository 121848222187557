import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Cloud = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <path
      d="m568.8125 399.992188c11.835938 0 21.464844-9.628907 21.464844-21.464844 0-11.839844-9.628906-21.46875-21.464844-21.46875-11.84375 0-21.472656 9.628906-21.472656 21.46875 0 11.835937 9.628906 21.464844 21.472656 21.464844zm0-24.238282c1.523438 0 2.765625 1.246094 2.765625 2.769532 0 1.527343-1.242187 2.773437-2.765625 2.773437-1.53125 0-2.773438-1.246094-2.773438-2.773437 0-1.523438 1.242188-2.769532 2.773438-2.769532zm0 0"
      fill={fill}
    />
    <path
      d="m522.378906 388.78125c5.164063 0 9.351563-4.183594 9.351563-9.347656 0-5.164063-4.1875-9.347656-9.351563-9.347656h-.089844c-5.167968 0-9.304687 4.183593-9.304687 9.347656 0 5.164062 4.234375 9.347656 9.394531 9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m499.941406 388.78125c5.160156 0 9.34375-4.183594 9.34375-9.347656 0-5.164063-4.183594-9.347656-9.34375-9.347656h-.09375c-5.167968 0-9.300781 4.183593-9.300781 9.347656 0 5.164062 4.226563 9.347656 9.394531 9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m477.5 388.78125c5.160156 0 9.34375-4.183594 9.34375-9.347656 0-5.164063-4.183594-9.347656-9.34375-9.347656h-.09375c-5.167969 0-9.308594 4.183593-9.308594 9.347656 0 5.164062 4.234375 9.347656 9.402344 9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m639.996094 454.324219c0-15.5-7.503906-29.277344-19.066406-37.902344 11.5625-8.617187 19.066406-22.394531 19.066406-37.894531 0-26.054688-21.195313-47.25-47.246094-47.25h-210.945312c-26.050782 0-47.246094 21.195312-47.246094 47.25 0 15.5 7.5 29.277344 19.066406 37.894531-11.566406 8.625-19.066406 22.402344-19.066406 37.902344 0 15.503906 7.5 29.277343 19.066406 37.898437-11.566406 8.625-19.066406 22.402344-19.066406 37.902344 0 26.050781 21.195312 47.246094 47.246094 47.246094h88.613281c5.167969 0 9.351562-4.183594 9.351562-9.347656 0-5.164063-4.183593-9.347657-9.351562-9.347657h-18.875v-57.101562h141.207031c15.742188 0 28.550781 12.808593 28.550781 28.550781s-12.808593 28.550781-28.550781 28.550781h-81.621094c-5.160156 0-9.34375 4.183594-9.34375 9.347657 0 5.164062 4.183594 9.347656 9.34375 9.347656h81.621094c26.054688 0 47.253906-21.191406 47.253906-47.246094 0-15.5-7.507812-29.277344-19.070312-37.902344 11.558594-8.621094 19.0625-22.394531 19.0625-37.898437zm-18.695313-75.800781c0 15.742187-12.808593 28.554687-28.550781 28.554687h-141.214844v-57.105469h141.214844c15.742188.007813 28.550781 12.808594 28.550781 28.550782zm-188.460937 28.554687h-29.773438v-57.097656h29.773438zm-29.773438 18.695313h29.773438v57.101562h-29.773438zm-49.8125-47.25c0-15.742188 12.808594-28.550782 28.550782-28.550782h2.558593v57.105469h-2.558593c-15.742188 0-28.550782-12.808594-28.550782-28.554687zm0 75.800781c0-15.742188 12.808594-28.550781 28.550782-28.550781h2.558593v57.101562h-2.558593c-15.742188 0-28.550782-12.808594-28.550782-28.550781zm0 75.800781c0-15.742188 12.808594-28.550781 28.550782-28.550781h2.558593v57.101562h-2.558593c-15.742188 0-28.550782-12.808593-28.550782-28.550781zm49.8125 28.550781v-57.101562h29.773438v57.101562zm189.683594-75.800781h-141.214844v-57.101562h141.214844c15.742188 0 28.550781 12.808593 28.550781 28.550781 0 15.742187-12.808593 28.550781-28.550781 28.550781zm0 0"
      fill={fill}
    />
    <path
      d="m568.8125 432.859375c-11.84375 0-21.472656 9.628906-21.472656 21.464844 0 11.835937 9.628906 21.46875 21.472656 21.46875 11.835938 0 21.464844-9.632813 21.464844-21.46875 0-11.835938-9.628906-21.464844-21.464844-21.464844zm0 24.238281c-1.53125 0-2.773438-1.246094-2.773438-2.773437 0-1.523438 1.242188-2.769531 2.773438-2.769531 1.523438 0 2.765625 1.246093 2.765625 2.769531 0 1.527343-1.242187 2.773437-2.765625 2.773437zm0 0"
      fill={fill}
    />
    <path
      d="m522.4375 445.882812h-.089844c-.019531 0-.039062 0-.058594 0-5.136718 0-9.273437 4.148438-9.304687 9.289063-.03125 5.164063 4.171875 9.375 9.332031 9.410156h.0625c5.136719 0 9.316406-4.144531 9.351563-9.285156.035156-5.167969-4.125-9.382813-9.292969-9.414063zm0 0"
      fill={fill}
    />
    <path
      d="m500 445.882812h-.09375c-.019531 0-.039062 0-.058594 0-5.136718 0-9.269531 4.148438-9.300781 9.289063-.035156 5.164063 4.167969 9.375 9.328125 9.410156h.066406c5.132813 0 9.3125-4.144531 9.34375-9.285156.035156-5.167969-4.121094-9.382813-9.285156-9.414063zm0 0"
      fill={fill}
    />
    <path
      d="m477.558594 445.882812h-.09375c-.019532 0-.042969 0-.0625 0-5.132813 0-9.269532 4.148438-9.304688 9.289063-.027344 5.164063 4.175782 9.375 9.335938 9.410156h.066406c5.128906 0 9.308594-4.144531 9.34375-9.285156.035156-5.167969-4.125-9.382813-9.285156-9.414063zm0 0"
      fill={fill}
    />
    <path
      d="m590.28125 530.125c0-11.835938-9.632812-21.46875-21.46875-21.46875s-21.472656 9.632812-21.472656 21.46875 9.636718 21.464844 21.472656 21.464844 21.46875-9.628906 21.46875-21.464844zm-24.238281 0c0-1.527344 1.238281-2.769531 2.769531-2.769531 1.527344 0 2.765625 1.242187 2.765625 2.769531s-1.238281 2.769531-2.765625 2.769531c-1.53125 0-2.769531-1.242187-2.769531-2.769531zm0 0"
      fill={fill}
    />
    <path
      d="m531.730469 531.03125c0-5.164062-4.1875-9.347656-9.351563-9.347656h-.089844c-5.167968 0-9.304687 4.183594-9.304687 9.347656s4.234375 9.347656 9.394531 9.347656c5.164063 0 9.351563-4.183594 9.351563-9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m509.285156 531.03125c0-5.164062-4.183594-9.347656-9.34375-9.347656h-.09375c-5.167968 0-9.300781 4.183594-9.300781 9.347656s4.226563 9.347656 9.394531 9.347656c5.160156 0 9.34375-4.183594 9.34375-9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m477.5 521.683594h-.09375c-5.167969 0-9.308594 4.183594-9.308594 9.347656s4.234375 9.347656 9.402344 9.347656c5.160156 0 9.34375-4.183594 9.34375-9.347656s-4.183594-9.347656-9.34375-9.347656zm0 0"
      fill={fill}
    />
    <path
      d="m259.34375 335.59375h-54.855469c-5.167969 0-9.351562 4.183594-9.351562 9.351562 0 5.160157 4.183593 9.34375 9.351562 9.34375h54.855469c1.058594 0 1.917969.859376 1.917969 1.921876v144.652343h-242.566407v-144.652343c0-1.054688.859376-1.914063 1.917969-1.914063h143.160157c5.167968 0 9.351562-4.191406 9.351562-9.351563 0-5.160156-4.183594-9.351562-9.351562-9.351562h-143.160157c-11.367187 0-20.613281 9.25-20.613281 20.617188v172.332031c0 11.367187 9.246094 20.613281 20.613281 20.613281h43.40625v9.523438h-3.066406c-5.164063 0-9.351563 4.183593-9.351563 9.34375 0 5.167968 4.1875 9.351562 9.351563 9.351562h158.054687c5.160157 0 9.347657-4.183594 9.347657-9.351562 0-5.160157-4.1875-9.34375-9.347657-9.34375h-3.066406v-9.523438h43.402344c11.367188 0 20.617188-9.246094 20.617188-20.613281v-172.332031c0-11.367188-9.25-20.617188-20.617188-20.617188zm-62.097656 223.085938h-114.53125v-9.523438h114.53125zm62.097656-28.21875h-238.730469c-1.058593 0-1.917969-.859376-1.917969-1.917969v-8.984375h242.566407v8.984375c0 1.058593-.859375 1.917969-1.917969 1.917969zm0 0"
      fill={fill}
    />
    <path
      d="m343.816406 292.113281h177.816406c7.980469 0 14.46875 6.488281 14.46875 14.46875 0 5.160157 4.183594 9.34375 9.351563 9.34375 5.160156 0 9.34375-4.183593 9.34375-9.34375 0-18.292969-14.878906-33.171875-33.164063-33.171875h-177.816406c-7.976562 0-14.46875-6.488281-14.46875-14.460937v-13.882813c0-5.160156-4.183594-9.351562-9.34375-9.351562-5.167968 0-9.351562 4.191406-9.351562 9.351562v13.882813c0 7.972656-6.488282 14.460937-14.46875 14.460937h-177.816406c-18.285157 0-33.164063 14.882813-33.164063 33.171875 0 5.160157 4.1875 9.34375 9.347656 9.34375 5.164063 0 9.351563-4.183593 9.351563-9.34375 0-7.980469 6.488281-14.46875 14.464844-14.46875h177.816406c9.335937 0 17.785156-3.886719 23.816406-10.121093 6.035156 6.234374 14.476562 10.121093 23.816406 10.121093zm0 0"
      fill={fill}
    />
    <path
      d="m224.125 221.507812h180.960938c39.394531 0 71.445312-32.050781 71.445312-71.445312 0-29.414062-17.699219-55.367188-44.589844-66.226562-4.460937-18.644532-13.695312-35.832032-26.882812-49.984376-3.523438-3.777343-9.441406-3.984374-13.214844-.464843-3.777344 3.523437-3.988281 9.441406-.464844 13.214843 12.011719 12.890626 20.078125 28.792969 23.320313 45.996094.640625 3.382813 3.082031 6.140625 6.359375 7.183594 21.996094 6.980469 36.773437 27.1875 36.773437 50.277344 0 29.085937-23.664062 52.746094-52.746093 52.746094h-180.960938c-24.121094 0-43.738281-19.621094-43.738281-43.742188 0-23.261719 18.175781-42.445312 41.386719-43.675781 4.828124-.253907 8.671874-4.152344 8.851562-8.984375.894531-24.0625 10.945312-46.550782 28.304688-63.3125 17.398437-16.800782 40.308593-26.058594 64.507812-26.058594 12.960938 0 25.527344 2.640625 37.351562 7.839844 4.726563 2.082031 10.246094-.066406 12.320313-4.789063 2.078125-4.730469-.0625-10.242187-4.789063-12.324219-14.210937-6.253906-29.3125-9.421874-44.882812-9.421874-29.074219 0-56.59375 11.117187-77.496094 31.308593-18.914062 18.265625-30.609375 42.1875-33.421875 68.058594-29.085937 5.457031-50.832031 30.96875-50.832031 61.359375 0 34.433594 28.007812 62.445312 62.4375 62.445312zm0 0"
      fill={fill}
    />
  </svg>
)


export default Cloud
Cloud.propTypes = propTypes
Cloud.defaultProps = defaultProps
