/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Close from '@material-ui/icons/Clear'
import { IconButton } from '@material-ui/core'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object
}

const CloseModalButton = ({ onClose, style }) => (
  <div className="refari-modal-close-btn" style={style}>
    <IconButton onClick={onClose}>
      <Close />
    </IconButton>
  </div>
)

export default CloseModalButton
CloseModalButton.propTypes = propTypes
