import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import qp from 'query-parse'
import { inject, observer } from 'mobx-react'

// import ListStore from '../../../stores/ListStore'
import API from '../../../utils/API'
import { ApiRoutes } from '../../../utils/Urls'
import * as axios from 'axios'
import PitchMePage from '../tools/PitchMe/PitchMePage'

@inject('rootStore')
@inject('agenciesStore')
@inject('pitchMeStore')
@observer
class PitchMeUrlPage extends React.Component {
  constructor(props) {
    super(props)
    const { pathname } = props.history.location
    const queryData = qp.toObject(props.history.location.search.slice(1))
    this.apiKey = queryData.apiKey
    const agencyStore = props.agenciesStore
    agencyStore.setData('agencyKey', this.apiKey)
    agencyStore.setData('agencyApiKey', this.apiKey)
    axios.defaults.headers['Api-Key'] = this.apiKey
    this.resizeEventObserver = null
  }

  postHeightParentEvent = () => {
    if (typeof window !== 'undefined') {
      let url =
        window.location != window.parent.location
          ? document.referrer
          : document.location.href
      let body = document.body,
        html = document.documentElement

      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
      window.parent.postMessage(
        { type: 'PITCH_ME_HEIGHT', height: height },
        url
      )
    }
  }

  componentDidMount() {
    this.postHeightParentEvent()
    if (typeof window !== 'undefined') {
      const targetNode = document.getElementById(
        'pitch-me-iframe-page-container'
      )
      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true }
      this.resizeEventObserver = new MutationObserver(
        this.postHeightParentEvent
      )
      // Start observing the target node for configured mutations
      this.resizeEventObserver.observe(targetNode, config)
    }
  }

  componentWillUnmount() {
    if (this.resizeEventObserver) {
      this.resizeEventObserver.disconnect()
    }
  }

  closeModal = () => {
    console.log('close modal')
  }
  render() {
    //   const {} = this.props
    const {
      rootStore: { user, agency, fetchUser },
      pitchMeStore
    } = this.props
    if (
      !(
        !user.id ||
        !agency.id ||
        // !consultantItem.data ||
        // (consultantItem.data && !consultantItem.data.id) ||
        // (user.id && user.id !== consultantItem.data.id) ||
        pitchMeStore.isFetching
      )
    ) {
      this.postHeightParentEvent()
    }

    return (
      <div id="pitch-me-iframe-page-container" style={{ padding: '1rem' }}>
        <PitchMePage {...this.props} />
      </div>
    )
  }
}

export default withTheme(PitchMeUrlPage)
