/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'
import { Dialog } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseButton from '../buttons/CloseButton'

@inject('utilsStore')
@observer
class ErrorModal extends React.Component {
  static propTypes = {
    utilsStore: PropTypes.shape({
      errorKey: PropTypes.string.isRequired,
      toggleModal: PropTypes.func.isRequired,
      modals: PropTypes.object.isRequired
    })
  }

  toggleModal = () => {
    const { toggleModal, errorKey } = this.props.utilsStore
    toggleModal(errorKey)
  }

  UNSAFE_componentWillMount() {
    const { errorKey, modals } = this.props.utilsStore
    this.disposeReaction = autorun(() => {
      if (modals[errorKey].isOpen) {
        setTimeout(() => {
          this.toggleModal()
        }, 5000)
      }
    })
  }

  componentWillUnmount() {
    this.disposeReaction()
  }

  render() {
    const {
      utilsStore: { errorKey, modals },
      theme,
      title
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }
    return (
      <Dialog
        className="modal modal-override"
        fullWidth
        open={modals[errorKey].isOpen}
        onClose={this.toggleModal}
      >
        <div className="modal-content">
          <CloseButton onClose={this.toggleModal} />
          <h3 style={style.title}>{modals[errorKey].title || 'Error!'}</h3>
          <h4>
            {modals[errorKey].message}
            {modals[errorKey].footerMessage && (
              <>
                <br />
                {modals[errorKey].footerMessage}
              </>
            )}
          </h4>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(ErrorModal)
