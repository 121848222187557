class LocalStorage {
  static isLocalStorageSupported = (() => {
    const testKey = 'testLocalStorageFunctionality'
    let supported = true
    try {
      window.localStorage.setItem(testKey, testKey)
      window.localStorage.removeItem(testKey)
    } catch (error) {
      supported = false
    }
    return !!supported
  })();

  static get(key) {
    let value
    if (LocalStorage.isLocalStorageSupported) {
      value = window.localStorage.getItem(key)
    }

    if (value) {
      value = JSON.parse(value)
    }

    return value
  }

  static set(key, data) {
    const value = JSON.stringify(data)
    if (LocalStorage.isLocalStorageSupported) {
      window.localStorage.setItem(key, value)
    }
  }

  static remove(key) {
    if (LocalStorage.isLocalStorageSupported) {
      window.localStorage.removeItem(key)
    }
  }
}


export default LocalStorage
