import React from 'react'

const LoadingAnimation = () => {
  return (
    <div className="bullet-animation-container">
      <div className="bullet" data-delay="100" />
      <div className="bullet" data-delay="200" />
      <div className="bullet" data-delay="300" />
      <div className="bullet" data-delay="400" />
    </div>
  )
}

export default LoadingAnimation
