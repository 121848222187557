/* eslint-disable react/style-prop-object */
import React from 'react'

const FilledStarIcon = ({ value }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 51 48"
    xmlSpace="preserve"
  >
    {value === 1 && (
    <path
      d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
      fill="rgb(242, 201, 76)"
    />
    )}
    {value === 0 && (
    <path
      d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
      fill="rgb(255, 255, 255)"
    />
    )}
    {value !== 0 && value !== 1 && (
    <>
      <defs>
        <linearGradient
          id="widgetGrad803449666474111"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="rgb(242, 201, 76)" stopOpacity="1" />
          <stop
            offset={`${value * 100}%`}
            stopColor="rgb(242, 201, 76)"
            stopOpacity="1"
          />
          <stop
            offset={`${value * 100}%`}
            stopColor="rgb(255, 255, 255)"
            stopOpacity="1"
          />
          <stop
            offset="100%"
            stopColor="rgb(255, 255, 255)"
            stopOpacity="1"
          />
        </linearGradient>
      </defs>
      <path
        d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
        fill="url(#widgetGrad803449666474111)"
      />
    </>
    )}
  </svg>
)

export default FilledStarIcon
