/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import Slider from '@material-ui/core/Slider'
import { observer } from 'mobx-react'
import { observable, action, runInAction } from 'mobx'
import { withTheme, withStyles } from '@material-ui/core/styles'

@observer
class SliderLabled extends React.Component {
  static propTypes = {
    defaultValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    minLabelText: PropTypes.string,
    maxLabelText: PropTypes.string
  }

  @observable currentValue = 0
  @observable labelIsExpanded = false

  constructor(props) {
    super(props)
    this.currentValue = props.value
  }

  @action
  onChange = (e, val) => {
    this.currentValue = val
    this.props.onChange(val)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      runInAction(() => {
        this.currentValue = nextProps.value
      })
    }
  }

  render() {
    const {
      value,
      label,
      theme: {
        palette: { primary1Color }
      },
      minLabelText,
      maxLabelText,
      ...restProps
    } = this.props

    const style = {
      header: {
        fontSize: '15px',
        margin: '0 0 5px'
      },
      sliderStyle: {
        margin: '0'
      }
    }

    const ThemeSlider = withStyles({
      root: {
        color: '#bdbdbd'
      },
      thumb: {
        backgroundColor: primary1Color,
        '&:focus, &:hover, &$active': {
          boxShadow:
            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)'
        }
      },
      valueLabel: {
        '& *': {
          background: primary1Color,
          color: '#fff'
        }
      }
    })(Slider)

    return (
      <div className="refari-slider__wrapper">
        {!!label && (
          <label style={style.header} className="refari-slider__label">
            {label}
          </label>
        )}
        <ThemeSlider
          {...restProps}
          className="refari-slider"
          value={this.currentValue}
          onChange={this.onChange}
          valueLabelDisplay="auto"
        />
        <div className="refari-slider__line-indicator">
          <span className="refari-line-indicator--min">
            {minLabelText || restProps.min}
          </span>
          {restProps.min !== 0 && (
            <span className="refari-line-indicator--zero">0</span>
          )}
          <span className="refari-line-indicator--max">
            {maxLabelText || restProps.max}
          </span>
        </div>
      </div>
    )
  }
}

export default withTheme(SliderLabled)
