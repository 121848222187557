import React from 'react'
//@ts-ignore add type for gif support
import CopyEmojiGif from './gif/copy.gif'

type Props = React.ComponentProps<'img'>

function CopyGif(props: Props) {
  return <img src={CopyEmojiGif} style={props.style} />
}

export default CopyGif
