import React, { Component } from 'react'
import classNames from 'classnames'

type UpsellBgProps = {
  blurImageType?: 'report' | 'ai' | 'rating' | 'talentAlert'
}

class UpsellBg extends Component<UpsellBgProps> {
  render() {
    const { blurImageType = 'report' } = this.props

    return (
      <div
        className={classNames({
          'report-blur-image': blurImageType === 'report',
          'ai-budget-blur-image': blurImageType === 'ai',
          'rating-blur-image': blurImageType === 'rating',
          'talent-alert-blur-image': blurImageType === 'talentAlert'
        })}
      />
    )
  }
}

export default UpsellBg
