/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Dialog, Button, CircularProgress } from '@material-ui/core'
import ReactCodeInput from 'react-verification-code-input'
import TelInput from '../../formComponents/TelInput'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import { validationErrors as errors } from '../../../../utils/helpers'

@inject('authTwoFactorStore')
@observer
class PhoneVerficationModal extends React.Component {
  static propTypes = {
    authTwoFactorStore: PropTypes.shape({
      togglePhoneVerificationModal: PropTypes.func.isRequired,
      isOpenPhoneVerificationModal: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      phoneError: false,
      counter: 0
    }
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      phone: new Field({
        name: 'phone',
        value: '',
        validators: [errors.required()],
      }),
      token: new Field({
        name: 'token',
        value: '',
        validators: [errors.required()],
      })
    })
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      const { counter } = this.state
      if (counter > 0) {
        this.setState({ counter: (counter - 1) })
      }
    }, 1000)
  }

  onClose = () => {
    const { authTwoFactorStore: { togglePhoneVerificationModal } } = this.props
    togglePhoneVerificationModal()
    this.timer && clearInterval(this.timer)
    this.form.reset()
  }

  onChangeTel = (hasError) => {
    this.setState({ phoneError: hasError })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { authTwoFactorStore: { updatePhoneNumber } } = this.props
    if (this.form.fields.phone.value.length < 6 || this.state.phoneError) {
      return
    }
    this.form.submit(updatePhoneNumber)
  }

  onSubmitCode = (e) => {
    e.preventDefault()
    const { authTwoFactorStore: { verifyPhoneCode } } = this.props
    if (this.form.fields.token.value.length < 6) {
      return
    }
    this.form.submit(verifyPhoneCode, () => {
      this.timer && clearInterval(this.timer)
    })
  }

  handleChange = (vals) => {
    this.form.fields.token.update(vals)
  }

  resendPhoneCode = () => {
    const { authTwoFactorStore: { resendPhoneCode } } = this.props
    resendPhoneCode(() => {
      this.timer && clearInterval(this.timer)
      this.setState({ counter: 59 }, () => { this.startTimer() })
    })
  }

  render() {
    const { authTwoFactorStore: { isOpenPhoneVerificationModal, isLoading, phoneID }, theme } = this.props
    const { phoneError, counter } = this.state

    return (
      <div className="question-mapping-section-answer-modal">
        <div className="question-mapping-section-answer-modal-box">
          <Dialog
            className="modal-container-without-left refari-modal"
            BackdropProps={{ className: 'modal-container-without-left-overlay' }}
            maxWidth="xs"
            fullWidth
            open={isOpenPhoneVerificationModal}
            onClose={this.onClose}
          >
            <div className="refari-modal-content">
              <div className="refari-placements-wrapper refari-phone-code-wrapper">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" style={{ color: theme.palette.primary1Color }}>{!phoneID ? 'Phone number verification' : 'Verification'}</h5>
                    <button type="button" onClick={this.onClose} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: '0 4rem 2rem' }}>
                    <div className="setting-cont">
                      {
                        !phoneID ? (
                          <form onSubmit={this.onSubmit}>
                            <p className="refari-phone-code-subheading">
                              Please enter your phone number
                            </p>
                            <TelInput
                              fieldData={this.form.fields.phone}
                              label=""
                              countriesOptions={['au', 'hk', 'nz', 'sg', 'gb', 'us']}
                              showTicks
                              cb={this.onChangeTel}
                            />
                            <div className="refari-w-row refari-justify-center refari-align-items-center m-t-3">
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={this.form.fields.phone.value.length < 6 || phoneError}
                              >
                                Send
                              </Button>
                              {isLoading && (
                                <CircularProgress size={25} thickness={2} color="primary" style={{ marginLeft: 10 }} />
                              )}
                            </div>
                          </form>
                        ) : (
                          <form onSubmit={this.onSubmitCode}>
                            <p className="refari-phone-code-subheading" style={{ marginBottom: 0 }}>
                              The code has been sent via SMS to the number
                            </p>
                            <p className="refari-phone-code-subheading">
                              {`*** *** ${this.form.fields.phone.value.slice(-4)}`}
                            </p>
                            <ReactCodeInput
                              onChange={this.handleChange}
                              fieldWidth={38}
                              fieldHeight={38}
                              className="refari-phone-code-input"
                            />
                            <div className="refari-w-row refari-justify-center refari-align-items-center m-t-3">
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={this.form.fields.token.value.length < 6}
                              >
                                Verify
                              </Button>
                              {isLoading && (
                                <CircularProgress size={25} thickness={2} color="primary" style={{ marginLeft: 10 }} />
                              )}
                            </div>
                            <div className="refari-phone-code-subheading-bottom">
                              {
                                counter > 0 ? (
                                  <div className="refari-phone-code-subheading-bottom-text">Resend code in <span style={{ color: 'green' }}>00:{counter > 9 ? counter : `0${counter}`}</span></div>
                                ) : (
                                  <>
                                    <div className="refari-phone-code-subheading-bottom-text">Didn't receive verification?</div>
                                    <div
                                      className="refari-phone-code-subheading-bottom-text"
                                      style={{
                                        color: theme.palette.primary1Color,
                                        cursor: 'pointer',
                                        textDecoration: 'underline'
                                      }}
                                      onClick={this.resendPhoneCode}
                                    >Resend Verification</div>
                                  </>
                                )
                              }
                            </div>
                          </form>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTheme(PhoneVerficationModal)
