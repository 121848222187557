import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import Select from '../../formComponents/Select'
import RaisedButton from '../../common/RaisedButton'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import Input from '../../formComponents/Input'
import Modal from '../Modal'
import CloseModalButton from '../CloseModalButton'

@inject('pitchMeStore')
@observer
class PitchMeCTAModal extends React.Component {
  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      title: new Field({
        name: 'title',
        value: props.pitchMeStore.pitchMeCTATitle || ''
      }),
      link: new Field({
        name: 'link',
        value: props.pitchMeStore.pitchMeCTALink || ''
      }),
      buttonWidth: new Field({
        name: 'buttonWidth',
        value: props.pitchMeStore.pitchMeCTAWidth
      }),
      buttonHeight: new Field({
        name: 'buttonHeight',
        value: props.pitchMeStore.pitchMeCTAHeight
      }),
      buttonBorderRadius: new Field({
        name: 'buttonBorderRadius',
        value: props.pitchMeStore.pitchMeCTABorderRadius
      })
    })
    this.selectedFontSize = new Field({
      name: 'selected_font_size',
      value: props.pitchMeStore.pitchMeCTAFontSize
    })
  }

  submit = (e) => {
    e.preventDefault()
    const { pitchMeStore } = this.props
    pitchMeStore.setData('pitchMeCTATitle', this.form.fields.title.value || '')
    pitchMeStore.setData('pitchMeCTALink', this.form.fields.link.value || '')
    pitchMeStore.setData(
      'pitchMeCTAWidth',
      this.form.fields.buttonWidth.value
        ? Number(this.form.fields.buttonWidth.value)
        : 200
    )
    pitchMeStore.setData(
      'pitchMeCTAHeight',
      this.form.fields.buttonHeight.value
        ? Number(this.form.fields.buttonHeight.value)
        : 36
    )
    pitchMeStore.setData(
      'pitchMeCTABorderRadius',
      this.form.fields.buttonBorderRadius.value
        ? Number(this.form.fields.buttonBorderRadius.value)
        : 20
    )
    this.togglePitchMeCTAModal()
  }

  togglePitchMeCTAModal = () => {
    this.props.pitchMeStore.togglePitchMeCTAModal()
  }

  onChangeFontSize = () => {
    const {
      pitchMeStore: { setData }
    } = this.props
    setData('pitchMeCTAFontSize', this.selectedFontSize.value)
  }

  render() {
    const { pitchMeStore, theme } = this.props
    const style = {
      title: { color: theme.palette.popupPrimary },
      moneyIcon: {
        margin: '-5px -10px 0 -4px',
        verticalAlign: 'middle',
        color: 'inherit'
      }
    }
    return (
      <Modal
        className="refari-w-modal-container"
        open={pitchMeStore.isOpenPitchMeCTAModal}
        onClose={this.togglePitchMeCTAModal}
        classes={{
          container: 'iframe-dialog-end'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-refer-modal-wrap',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <h3 style={style.title} className="refari-long-text">
            Please enter call to action information:
          </h3>
          <CloseModalButton onClose={this.togglePitchMeCTAModal} />
          <form
            onSubmit={this.submit}
            className="refari-modal-content__linkedin-apply-form"
          >
            <div className="refari-modal-content__linkedin-apply-form-content refari-iframe">
              <Input fieldData={this.form.fields.title} label="Button Title" />
              <Input fieldData={this.form.fields.link} label="Button URL" />
              <Select
                fieldData={this.selectedFontSize}
                options={pitchMeStore.fontSizeArray}
                emptyOption=""
                optionValueKey="value"
                optionTextKey="label"
                label="Font size"
                floatingLabelFixed
                cb={this.onChangeFontSize}
                menuProps={{
                  keepMounted: true,
                  disablePortal: true,
                  disableEnforceFocus: true,
                  disableAutoFocus: true
                }}
              />
              <Input
                className="details-message"
                type="number"
                fieldData={this.form.fields.buttonWidth}
                label="Button width"
              />
              <Input
                className="details-message"
                type="number"
                fieldData={this.form.fields.buttonHeight}
                label="Button height"
              />
              <Input
                className="details-message"
                type="number"
                fieldData={this.form.fields.buttonBorderRadius}
                label="Button border radius"
              />
            </div>
            <RaisedButton
              type="submit"
              color="primary"
              className="refari-submit-btn refari-button-raised"
            >
              Save
            </RaisedButton>
          </form>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeCTAModal)
