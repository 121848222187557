/* eslint-disable class-methods-use-this */
import {
  observable,
  action
} from 'mobx'

export class DefaultReportStore {
  @observable activeTab = ''

  @action
  initTab = (path) => {
    this.activeTab = path
  }

  @action
  onTabChange = (link, cb) => {
    cb && cb(link)
    this.activeTab = link
  }
}

export default new DefaultReportStore()
