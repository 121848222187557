import React, { Component } from 'react'
import { Tabs } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import type { History } from 'history'
import qp from 'query-parse'

import { CustomTab } from 'src/components/shared/common/CustomTab'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import TalentAlertForm from './TalentAlertForm'
import EmailTemplatePanelContent from './EmailTemplatePanelContent'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'

type TalentAlertContentProps = {
  rootStore: Record<string, any>
  theme: MUIThemeFuncReturnType
  talentAlertStore: ITalentAlertStore
  history: History
  email: string
  editSuggestionId: string
  keywords?: string
}

type TalentAlertContentState = {
  currentTabValue: string
}

@inject('rootStore', 'talentAlertStore')
@observer
class TalentAlertContent extends Component<
  TalentAlertContentProps,
  TalentAlertContentState
> {
  queryParam: {
    email?: string
  }
  email: string

  constructor(props: TalentAlertContentProps) {
    super(props)

    this.state = {
      currentTabValue: 'createTalentAlert'
    }

    this.email = ''

    const queryData = qp.toObject(props.history.location?.search.slice(1)) ?? {}

    this.queryParam = {
      email: queryData.email || queryData['amp;email']
    }

    if (this.queryParam.email || props.email) {
      this.email = this.queryParam.email || props.email
    }
  }

  handleChange = (
    _event: React.ChangeEvent<Record<string, any>>,
    newValue: string
  ) => {
    const { talentAlertStore } = this.props

    talentAlertStore.setSelectedMessageTemplate(null)

    this.setState({ currentTabValue: newValue })

    const url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage(
      {
        viewType: newValue
      },
      url
    )
  }

  isAgencyUser = () => {
    const {
      rootStore: { loggedIn, user }
    } = this.props
    return loggedIn && !user.isHiringManager
  }

  render() {
    const { theme, history, editSuggestionId, keywords } = this.props

    const { currentTabValue } = this.state

    return (
      <>
        {this.isAgencyUser() ? (
          <>
            <Tabs
              value={currentTabValue}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <CustomTab
                value="createTalentAlert"
                label={`Suggest a talent alert ${
                  this.isAgencyUser() ? 'for a hiring manager' : ''
                }`}
                style={{
                  minHeight: 'auto',
                  maxWidth: 'none',
                  flexBasis: '50%',
                  borderRight: `2px solid ${theme.palette.primary1Color}`
                }}
              />

              <CustomTab
                value="createEmailTemplate"
                label="Talent alert suggestion template"
                style={{
                  minHeight: 'auto',
                  maxWidth: 'none',
                  flexBasis: '50%'
                }}
              />
            </Tabs>

            {currentTabValue === 'createTalentAlert' && (
              // @ts-ignore store props are injected
              <TalentAlertForm
                location={history.location}
                email={this.email}
                editSuggestionId={editSuggestionId}
              />
            )}

            {currentTabValue === 'createEmailTemplate' && (
              <div className="modal-content">
                {/* @ts-ignore store props are injected */}
                <EmailTemplatePanelContent />
              </div>
            )}
          </>
        ) : (
          <div className="modal-content">
            <h3
              className="refari-long-text refari-modal-header undefined"
              style={{ color: theme.palette.primary1Color }}
            >
              {this.isAgencyUser()
                ? 'Suggest a talent alert'
                : 'Create a talent alert'}
            </h3>
            {/* @ts-ignore store props are injected */}
            <TalentAlertForm
              location={history.location}
              email={this.email}
              editSuggestionId={editSuggestionId}
              keywords={keywords}
            />
          </div>
        )}
      </>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TalentAlertContent)
