import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Switch } from 'react-router-dom'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('rootStore')
@inject('agenciesStore')
@observer
class AccountSettings extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.rootStore.initTab(pathname)
  }

  onTabChange = (link) => {
    this.props.agenciesStore.progressNew()
    this.props.rootStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props
    return (
      <div className="settings">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container">
            <div className="settings-container">
              <Switch>
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.editProfile.path}`}
                  component={asyncComponent(() => import('./EditProfile'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.agency.path}`}
                  component={asyncComponent(() => import('./AgencySettings'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.accountSettings.path}`}
                  component={asyncComponent(() => import('./AccountSettings'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.agencyBranding.path}`}
                  component={asyncComponent(() => import('./AgencyBranding'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.socialNetworks.path}`}
                  component={asyncComponent(() => import('./SocialNetworks'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.settings.children.floatingLink.path}`}
                  component={asyncComponent(() => import('./FloatingLink'))}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(AccountSettings)
