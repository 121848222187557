/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from 'src/components/shared/modals/CloseModalButton'
import Modal from 'src/components/shared/modals/Modal'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'

type EmailPreviewModalProps = {
  talentAlertStore: ITalentAlertStore
  theme: MUIThemeFuncReturnType
}

@inject('talentAlertStore')
@observer
class EmailPreviewModal extends Component<EmailPreviewModalProps> {
  closeModal = () => {
    const { talentAlertStore } = this.props

    talentAlertStore.closeEmailPreviewModal()
    talentAlertStore.setEmailPreview(null)
  }

  processBody = (body: string) =>
    body
      .replace(/href="(.*?)"/g, 'href="javascript: void(0)"')
      .replace(/class=/g, '') // remove class attributes

  render() {
    const { theme, talentAlertStore } = this.props

    if (!talentAlertStore.emailPreview) {
      return null
    }

    const style = {
      // @ts-ignore TODO: fix this
      title: { color: theme.palette.popupPrimary },
      // @ts-ignore TODO: fix this
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        maxWidth="xs"
        open={talentAlertStore.isOpenEmailPreviewModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            // @ts-ignore TODO: fix this
            backgroundColor: theme.widgetBackgroundColor,
            // @ts-ignore TODO: fix this
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Email preview
          </h3>
          <div
            style={{
              width: '100%',
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            {talentAlertStore.emailPreview.message_html && (
              <iframe
                title="Email preview"
                // @ts-ignore TODO: fix this
                border="0"
                className="mail-preview__body"
                srcDoc={this.processBody(
                  talentAlertStore.emailPreview.message_html
                )}
                style={{ width: '100%', minHeight: '620px' }}
              />
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(EmailPreviewModal)
