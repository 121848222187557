/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Button } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const FlatButton = ({ children, style = {}, theme, ...restProps }) => (
  <Button
    {...restProps}
    style={{
      ...style,
      borderRadius: style.borderRadius || theme.flatButton.borderRadius,
      color: style && style.color ? style.color : theme.palette.primary1Color,
      borderColor:
        style && style.borderColor
          ? style.borderColor
          : theme.palette.primary1Color
    }}
  >
    {children}
  </Button>
)

export default withTheme(FlatButton)
