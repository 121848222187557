/* eslint-disable max-len */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { withTheme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { truncate } from '../../../utils/helpers'

const getOptionContent = (data) => {
  if (data.title) {
    return data.title
  }
  if (data.authorFirstName) {
    return `${data.authorFirstName} ${data.authorLastName}`
  }

  return data.name
}

const Option = (props) => {
  const {
    className,
    cx,
    isFocused,
    isSelected,
    isDisabled,
    innerRef,
    innerProps,
    data,
    theme
  } = props

  return (
    <div
      ref={innerRef}
      style={{ color: theme.colors.primary }}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        'auto-complete-component-item',
        className
      )}
      {...innerProps}
    >
      <p className="auto-complete-component-item--content font-bold">
        {getOptionContent(data)}
      </p>
      {(data.category || data.text) && (
        <p className="auto-complete-component-item--content">
          {data.category
            ? `${
              data.category.parent.name && `${data.category.parent.name} >`
            } ${data.category.name}`
            : truncate(data.text, 35)}
        </p>
      )}
      <span
        className="auto-complete-component-item--plus-icon"
        style={{ color: theme.colors.primary }}
      >
        &#x2b;
      </span>
    </div>
  )
}

class AutoCompleteComponent extends Component {
  static propTypes = {
    jobSearch: PropTypes.func,
    handleJobSearch: PropTypes.func,
    selectJob: PropTypes.func,
    list: PropTypes.any,
    placeholder: PropTypes.string,
    placeholderColor: PropTypes.string,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func
  }

  state = {
    searchText: '',
    defaultOptions: [],
    isLoading: false
  }

  componentDidMount() {
    const { searchText } = this.state
    this.loadDefaultOptions(searchText, false)
  }

  loadDefaultOptions = (inputValue, isLoading) => {
    if (isLoading) {
      this.setState({ isLoading: true })
    }
    this.loadOptions(inputValue).then((defaultOptions) => this.setState({ defaultOptions, isLoading: false }))
  }

  handleUpdateInput = (searchText, { action }) => {
    if (action === 'input-change') {
      this.props.jobSearch(searchText)
      this.setState({ searchText })
    }
    if (action === 'menu-close') {
      this.loadDefaultOptions('', false)
    }
  }

  handleChange = (val) => {
    this.props.selectJob(val)
  }

  handleFocus = () => {
    this.props.jobSearch('')
    this.setState({
      searchText: ''
    })
    this.loadDefaultOptions('', true)
  }

  loadOptions = (inputValue) => new Promise((resolve) => resolve(this.props.handleJobSearch(inputValue)))

  render() {
    const { theme, placeholder, placeholderColor, getOptionLabel, getOptionValue } = this.props

    return (
      <div>
        <AsyncSelect
          onFocus={this.handleFocus}
          className="async-react-select"
          value={this.state.searchText}
          defaultOptions={this.state.defaultOptions}
          isLoading={this.state.isLoading}
          cacheOptions={false}
          loadOptions={this.loadOptions}
          getOptionLabel={getOptionLabel || ((option) => option)}
          getOptionValue={getOptionValue || ((option) => option)}
          onChange={this.handleChange}
          placeholder={placeholder || 'Add item'}
          onInputChange={this.handleUpdateInput}
          styles={{
            control: (provided) => ({
              ...provided,
              borderLeft: '0 !important',
              borderRight: '0 !important',
              borderTop: '0 !important',
              boxShadow: '0 !important',
              flexDirection: 'row-reverse',
              '&:hover': {
                borderLeft: '0 !important',
                borderRight: '0 !important',
                borderTop: '0 !important'
              }
            }),
            indicatorContainer: () => ({
              display: 'none'
            }),
            placeholder: (provided) => ({
              ...provided,
              color: placeholderColor || '#808080'
            })
          }}
          components={{
            DropdownIndicator: () => (
              <SearchIcon style={{ color: theme.palette.primary1Color }} />
            ),
            IndicatorSeparator: () => null,
            Option
          }}
          theme={(intheme) => ({
            ...intheme,
            borderRadius: 0,
            colors: {
              ...intheme.colors,
              primary25: '#C9C9C9',
              primary: theme.palette.primary1Color
            }
          })}
        />
      </div>
    )
  }
}

export default withTheme(AutoCompleteComponent)
