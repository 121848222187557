import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Paper } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import ArrowIcon from '../../../shared/icons/ArrowIcon'
import URLs from '../../../../router/routesUrls'

@inject('edmStore')
@observer
class EdmPreviewPage extends React.Component {
  handleClick = () => {
    const { edmStore } = this.props
    const element = document.createElement('a')
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(edmStore.preview)}`)
    element.setAttribute('download', 'htmlTemplate.html')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  handleBack = () => {
    this.props.history.push(URLs.tools.children.EdmBuilder.link)
  }

  render() {
    const { edmStore } = this.props
    if (!edmStore.preview) {
      window.history.back()
      return null
    }
    return (
      <div className="edm-preview-page container">
        <p className="edm-preview-page--back" onClick={this.handleBack}>
          <ArrowIcon className="icon-arrow" /> Back to EDM builder
        </p>
        <Paper>
          <div className="edm-builder-section__title">
            <p className="edm-builder-section--title">
              Preview
            </p>
            <div>
              <Button
                variant="contained"
                type="button"
                className="m-t-1"
                onClick={this.handleClick}
                color="primary"
              >
                Download code
              </Button>
            </div>
          </div>
          <div className="settings-container">
            <div dangerouslySetInnerHTML={{ __html: edmStore.preview }} />
          </div>
        </Paper>
      </div>
    )
  }
}

export default withTheme(EdmPreviewPage)
