import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Chimney = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <path
        d="M63.833,317.583H72.5h55.583h7.334c4.418,0,8-3.582,8-8c0-4.193-3.228-7.627-7.334-7.966v-71.95c0-4.418-3.582-8-8-8H72.5   c-4.418,0-8,3.582-8,8v71.917h-0.667c-4.418,0-8,3.582-8,8S59.415,317.583,63.833,317.583z M120.083,237.666v23.958H80.5v-23.958   H120.083z M80.5,277.625h39.583v23.958H80.5V277.625z"
        fill={fill}
      />
      <path
        d="M192.58,317.586c2.109,0,4.17-0.86,5.66-2.35c1.489-1.49,2.34-3.55,2.34-5.65c0-2.11-0.851-4.17-2.34-5.66   c-1.49-1.49-3.551-2.34-5.66-2.34c-2.101,0-4.17,0.85-5.65,2.34c-1.49,1.49-2.35,3.55-2.35,5.66c0,2.1,0.859,4.17,2.35,5.65   C188.41,316.726,190.479,317.586,192.58,317.586z"
        fill={fill}
      />
      <path
        d="M296.292,317.583h8.666h55.584h7.333c4.418,0,8-3.582,8-8c0-4.193-3.228-7.627-7.333-7.966v-71.95c0-4.418-3.582-8-8-8   h-55.584c-4.418,0-8,3.582-8,8v71.917h-0.666c-4.418,0-8,3.582-8,8S291.874,317.583,296.292,317.583z M352.542,237.666v23.958   h-39.584v-23.958H352.542z M312.958,277.625h39.584v23.958h-39.584V277.625z"
        fill={fill}
      />
      <path
        d="M424.083,382.166h-7.416V202.094H424c0.006,0,0.014,0,0.02,0c4.419,0,8-3.582,8-8c0-0.956-0.167-1.873-0.475-2.723   L399.951,79.001c-0.97-3.451-4.117-5.835-7.701-5.835h-31.708v-31.25c0-4.418-3.582-8-8-8h-47.584c-4.418,0-8,3.582-8,8v31.25   H39.75c-3.584,0-6.731,2.384-7.701,5.835L0.299,191.928c-0.678,2.411-0.188,5,1.324,6.996c1.513,1.996,3.873,3.169,6.377,3.169h8   v180.073H8.583c-4.418,0-8,3.582-8,8s3.582,8,8,8h415.5c4.418,0,8-3.582,8-8S428.501,382.166,424.083,382.166z M256,382.166h-79.5   v-144.5H256V382.166z M272,349.416h128.667v32.75H272V349.416z M312.958,49.916h31.584v23.25h-31.584V49.916z M45.81,89.166   h340.379l27.251,96.927H18.559L45.81,89.166z M400.667,202.094v131.323H272v-103.75c0-4.418-3.582-8-8-8h-95.5   c-4.418,0-8,3.582-8,8v103.75H32V202.094H400.667z M32,349.416h128.5v32.75H32V349.416z"
        fill={fill}
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Chimney
Chimney.propTypes = propTypes
Chimney.defaultProps = defaultProps
