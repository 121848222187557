import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import { mapFormServerErrors } from '../utils/helpers'
import alertMessages from '../constants/alertMessages'

export class ConsultantStore {
  @observable isOpenModal = false
  @observable isOpenInviteModal = false
  @observable justAddedNewUserId = null
  @observable isSendingInvite = false

  @action
  closeConsultantAddModal = () => {
    this.isOpenModal = false
  }

  @action
  closeSendInviteModal = () => {
    this.isOpenInviteModal = false
  }

  @action
  setJustAddedNewUserId = (id) => {
    this.justAddedNewUserId = id
  }

  @action
  sendInvite = async (userId) => {
    try {
      this.isSendingInvite = true

      await API.postData(ApiRoutes.dashboard.consultants.invite, {
        ids: userId
      })

      toast.success('Selected users were invited')

      this.closeSendInviteModal()
      this.closeConsultantAddModal()
    } catch (error) {
      Logger.error(error)
    } finally {
      this.isSendingInvite = false
    }
  }

  @action
  addConsultant = async (form, consultants) => {
    try {
      form.pending(true)

      const formData = form.data

      const payload = {
        ...formData,
        agencies: Array.isArray(formData.agencies)
          ? formData.agencies.map((agencyId) => agencyId)
          : [formData.agencies],
        ...(formData.primary_agency && {
          primary_agency: formData.primary_agency
        })
      }

      const res = await API.postData(
        ApiRoutes.dashboard.consultants.create,
        payload
      )
      if (
        res.response &&
        (res.response.status === 400 || res.response.status === 500)
      ) {
        const { data } = res.response
        mapFormServerErrors(data, form.fields)

        toast.error(alertMessages.genericErrorMessage)
      } else {
        if (consultants) {
          consultants.fetchListData()
        }
        runInAction(() => {
          this.isOpenInviteModal = true
          this.justAddedNewUserId = res.data.id
        })

        toast.success(alertMessages.addRecruiter)
      }
    } catch (error) {
      Logger.error(error)
      form.pending(false)
      if (error) {
        const { data } = error
        mapFormServerErrors(data, form.fields)
      }
    }
  }

  @action
  toggleModal = () => {
    this.isOpenModal = !this.isOpenModal
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new ConsultantStore()
