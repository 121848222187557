import { observable, computed } from 'mobx'
import { utils, createUUID } from '../utils/helpers'
import Currency from './Currency'
import RootStore from '../stores/RootStore'

export default class Invoice {
  @observable id
  @observable dateFrom
  @observable dateTo
  @observable rates
  @observable placementsCount
  @observable status
  @observable.ref placements
  @observable.ref total

  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.dateFrom = data.date_from
    this.dateTo = data.date_to
    this.totalUsd = data.total_usd
    this.rates = data.rates
    this.description = data.description
    this.total = new Currency(data.total, 'amount')
    this.status = data.status === 'approved' ? 'new' : data.status // new/approved/accepted/denied/
    this.placements = Array.isArray(data.placements) ? data.placements.map((item) => new Placement(item)) : []
    this.placementsCount = data.placements_count
    this.taxRate = data.tax_rate || null
    this.totalNoTax = data.total_no_tax || null
    this.totalNoTax = data.total_no_tax || null
    this.pdf = data.pdf || null
  }

  @computed
  get isEditable() {
    return this.status === 'new'
  }

  @computed
  get presentationalID() {
    return this.id.substring(0, 8)
  }

  @computed
  get formattedDateFrom() {
    return utils.dateFormatter(this.dateFrom, '/', '', RootStore.agency.currency)
  }

  @computed
  get formattedDateTo() {
    return utils.dateFormatter(this.dateTo, '/', '', RootStore.agency.currency)
  }

  @computed
  get formattedPeriod() {
    return `${this.formattedDateFrom} - ${this.formattedDateTo}`
  }
}


export class Placement {
  constructor(data) {
    if (!data) data = {}

    this.id = data.id || createUUID()
    this.job = {
      title: data.job ? data.job.title : '',
      salary: data.job && new Currency(data.job.salary, 'amount')
    }
    this.application = data.application.value || '0.00'
    this.referrer = data.referrer ? data.referrer.value : '0.00'
    this.total = new Currency(data.total, 'amount')
    this.total_native = new Currency(data.total_native, 'amount')
    this.total_usd = new Currency(data.total_usd, 'amount')
    this.candidate = `${data.candidate.first_name} ${data.candidate.last_name}`
  }
}
