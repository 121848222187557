/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const FacebookIcon = (props) => {
  const style = {
    width: '10px',
    height: '20px',
    marginBottom: '-4px',
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 10 20"
    >
      <path
        transform="translate(-464 -364)"
        fill={props.style && props.style.color ? props.style.color : '#fff'}
        d="M466.02 367.87v2.76H464v3.36h2.02V384h4.14v-10.01h2.78s.26-1.61.39-3.38h-3.15v-2.3c0-.34.45-.8.9-.8h2.25V364h-3.07c-4.35 0-4.24 3.37-4.24 3.87z"
      />
    </SvgIcon>
  )
}

export default FacebookIcon
