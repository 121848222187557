/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type ActivityIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const ActivityIcon = ({
  iconColor,
  ...restProps
}: ActivityIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="ActivityIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M0 .927h24v24H0z"
          data-name="Rectangle 1395"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#activityIcon)" transform="translate(0 -.927)">
      <g fill={iconColor} data-name="Group 2981">
        <path
          d="M14.539 4.361a1.766 1.766 0 1 1 0 2.633v3.394h3.394a1.766 1.766 0 1 0 2.633 0H24V2.105A1.178 1.178 0 0 0 22.822.927h-8.283Z"
          data-name="Path 2400"
        />
        <path
          d="M12.406 16.715a1.761 1.761 0 0 1 .449-1.249H9.461v3.394a1.766 1.766 0 1 0 0 2.633v3.434h8.283a1.178 1.178 0 0 0 1.178-1.178v-8.283h-3.434a1.766 1.766 0 1 1-3.081 1.249Z"
          data-name="Path 2401"
        />
        <path
          d="M10.638 8.988a1.76 1.76 0 0 0-1.178.45V6.004H1.178A1.178 1.178 0 0 0 0 7.183v6.873h1.585a3.187 3.187 0 0 1 3.043-2.942h.122a3.177 3.177 0 0 1 3.165 2.942h1.546v-1.988a1.766 1.766 0 1 0 1.178-3.083Z"
          data-name="Path 2402"
        />
        <path
          d="M6 22.377a3.148 3.148 0 0 1-.885-2.322 3.187 3.187 0 0 1 2.942-3.043v-1.546H6.066a1.767 1.767 0 1 0-2.633 0H0v8.283a1.178 1.178 0 0 0 1.178 1.178h6.876v-1.586A3.191 3.191 0 0 1 6 22.377Z"
          data-name="Path 2403"
        />
      </g>
    </g>
  </SvgIcon>
)

export default ActivityIcon
