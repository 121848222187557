import React from 'react'
import { Button, InputAdornment, TextField, withTheme } from '@material-ui/core'

type DefaultAddViewProps = {
  value: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder: string
  id: string
  onSaveClick: () => void
  disableSave?: boolean
}

function DefaultAddView(props: DefaultAddViewProps) {
  const { value, onChange, placeholder, id, onSaveClick, disableSave } = props

  return (
    <>
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        InputProps={
          disableSave
            ? undefined
            : {
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={onSaveClick} color="primary" size="small">
                      Save
                    </Button>
                  </InputAdornment>
                )
              }
        }
      />
    </>
  )
}

export default withTheme(DefaultAddView)
