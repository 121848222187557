/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type JobsIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const JobsIcon = ({
  iconColor,
  ...restProps
}: JobsIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="jobsIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M0-.3h24v24H0z"
          data-name="Rectangle 1393"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#jobsIcon)" transform="translate(0 .3)">
      <g fill={iconColor} data-name="Group 2983">
        <path d="M9.89 12.325h4.219v1.406H9.89Zm0 0" data-name="Path 2390" />
        <path
          d="M19.47 13.791h-3.955v.659a.682.682 0 0 1-.7.659H9.187a.682.682 0 0 1-.7-.659v-.659H4.529a2.1 2.1 0 0 1-2-1.352L0 5.328v14.39a2.049 2.049 0 0 0 2.109 1.977H21.89A2.049 2.049 0 0 0 24 19.718V5.329l-2.528 7.11a2.1 2.1 0 0 1-2 1.352Zm0 0"
          data-name="Path 2391"
        />
        <path
          d="M14.812 1.7H9.187a2.112 2.112 0 0 0-2.109 2.109v.7H1.21l2.652 7.957a.7.7 0 0 0 .667.481h3.955v-.7a.7.7 0 0 1 .7-.7h5.625a.7.7 0 0 1 .7.7v.7h3.955a.7.7 0 0 0 .667-.481l2.652-7.957h-5.861v-.7a2.112 2.112 0 0 0-2.11-2.109ZM8.484 4.512v-.7a.7.7 0 0 1 .7-.7h5.625a.7.7 0 0 1 .7.7v.7Zm0 0"
          data-name="Path 2392"
        />
      </g>
    </g>
  </SvgIcon>
)

export default JobsIcon
