/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Dialog, Button, CircularProgress } from '@material-ui/core'
import ReactCodeInput from 'react-verification-code-input'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import { validationErrors as errors } from '../../../../utils/helpers'

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

@inject('authTwoFactorStore')
@observer
class AuthAppCodeModal extends React.Component {
  static propTypes = {
    authTwoFactorStore: PropTypes.shape({
      toggleAuthAppCodeModal: PropTypes.func.isRequired,
      isOpenAuthAppCodeModal: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      token: new Field({
        name: 'token',
        value: '',
        validators: [errors.required()]
      })
    })
  }

  onClose = () => {
    const {
      authTwoFactorStore: { toggleAuthAppCodeModal }
    } = this.props
    toggleAuthAppCodeModal()
    this.form.reset()
  }

  onSubmitCode = (e) => {
    e.preventDefault()
    const {
      authTwoFactorStore: { verifySignInAuthCode }
    } = this.props
    if (this.form.fields.token.value.length < 6) {
      return
    }
    this.form.submit(verifySignInAuthCode)
  }

  handleChange = (vals) => {
    this.form.fields.token.update(vals)
  }

  handleAuthAppLink = () => {
    const {
      authTwoFactorStore: { toggleOneTimePasswordModal, sendBackupPhoneCode }
    } = this.props
    sendBackupPhoneCode(() => {
      toggleOneTimePasswordModal()
    })
  }

  render() {
    const {
      authTwoFactorStore: { isOpenAuthAppCodeModal, isLoading },
      theme
    } = this.props

    return (
      <div className="question-mapping-section-answer-modal">
        <div className="question-mapping-section-answer-modal-box">
          <Dialog
            className="refari-modal"
            maxWidth="xs"
            fullWidth
            open={isOpenAuthAppCodeModal}
            onClose={this.onClose}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <div className="refari-modal-content">
              <div className="refari-placements-wrapper refari-phone-code-wrapper">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      style={{ color: theme.palette.primary1Color }}
                    >
                      Authentication app code
                    </h5>
                    <button
                      type="button"
                      onClick={this.onClose}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body refari-phone-code-body">
                    <div className="setting-cont">
                      <form onSubmit={this.onSubmitCode}>
                        <p className="refari-phone-code-subheading">
                          Please enter your authenticator app code here
                        </p>
                        <ReactCodeInput
                          onChange={this.handleChange}
                          fieldWidth={isMobileDevice() ? 24 : 38}
                          fieldHeight={38}
                          className="refari-phone-code-input"
                        />
                        <div className="refari-w-row refari-justify-center refari-align-items-center m-t-3">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={this.form.fields.token.value.length < 6}
                            style={{ marginLeft: '35px' }}
                          >
                            Verify
                          </Button>
                          <CircularProgress
                            size={25}
                            thickness={2}
                            color="primary"
                            style={{
                              marginLeft: 10,
                              visibility: isLoading ? 'visible' : 'hidden'
                            }}
                          />
                        </div>
                        <div className="refari-phone-code-subheading-bottom">
                          <div
                            className="refari-phone-code-subheading-bottom-text"
                            style={{
                              color: theme.palette.primary1Color,
                              cursor: 'pointer',
                              textTransform: 'uppercase',
                              textDecoration: 'underline'
                            }}
                            onClick={this.handleAuthAppLink}
                          >
                            Authentication app not working
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTheme(AuthAppCodeModal)
