/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from './CloseModalButton'
import RaisedButton from '../common/RaisedButton'
import Modal from './Modal'

@observer
class DeleteMessageTemplateModal extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      suggestionTemplateMessageID: PropTypes.number
    }),
    templateSelectRef: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      disableBtn: false
    }
  }

  closeModal = () => {
    const { rootStore } = this.props

    rootStore.toggleDeleteSuggestionMessageModal()
    rootStore.setData('suggestionTemplateMessageID', null)

    // Interestingly focusing from componentDidUpdate does not work
    // when close icon button is clicked
    this.props.templateSelectRef.current?.reactSelectRef.current?.focus()
  }

  deleteTemplate = async () => {
    this.setState({ disableBtn: true })

    const { rootStore } = this.props

    const selectedMessage = rootStore.messageTemplates.filter(
      (message) => message.id === rootStore.suggestionTemplateMessageID
    )

    if (selectedMessage && selectedMessage.length > 0) {
      await rootStore.deleteSuggestionMessage(
        rootStore.suggestionTemplateMessageID
      )
    }

    this.setState({ disableBtn: false })
  }

  componentDidUpdate() {
    this.props.templateSelectRef.current?.reactSelectRef.current?.focus()
  }

  render() {
    const { rootStore, theme } = this.props
    const { disableBtn } = this.state

    if (!rootStore.suggestionTemplateMessageID) {
      return null
    }

    const selectedMessage = rootStore.messageTemplates.filter(
      (message) => message.id === rootStore.suggestionTemplateMessageID
    )

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={rootStore.isOpenSuggestionMessageDeleteModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Delete Message Template
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 300,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <p className="refari-message-template-modal--content">
              Are you sure you want to delete this template:{' '}
              {selectedMessage && selectedMessage.length > 0
                ? selectedMessage[0].name
                : ''}
              ?
            </p>
            <RaisedButton
              color="primary"
              className="refari-button-raised"
              style={{ width: 'auto' }}
              onClick={this.deleteTemplate}
              disabled={disableBtn}
            >
              Yes, Delete it!
            </RaisedButton>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(DeleteMessageTemplateModal)
