import React from 'react'
import PropTypes from 'prop-types'

const CharCounter = ({
  currentLength,
  maxLength,
  className,
  color,
  label,
  alertColor
}) => (
  <div className={className} style={{ color }}>
    <div style={{ float: 'right' }}>
      <span style={{ color: currentLength > maxLength ? alertColor : null }}>
        {currentLength}
      </span>{' '}
      / {maxLength}
    </div>
    <span style={{ color: currentLength > maxLength ? alertColor : null }}>
      {!!label && label}
    </span>
  </div>
)

CharCounter.propTypes = {
  currentLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.node,
  alertColor: PropTypes.string
}

CharCounter.defaultProps = {
  className: '',
  color: 'inherit'
}

export default CharCounter
