import React from 'react'
import { SvgIcon } from '@material-ui/core'

const TickIcon = ({ fill, ...props }) => {
  const style = {
    width: '18px',
    height: '12px',
    marginRight: '5px',
    ...props.style
  }
  return (
    <SvgIcon {...props} style={style} viewBox="0 0 10 13">
      <path
        d="M4.45455 8.70149L1.11364 5.25373L0 6.40299L4.45455 11L14 1.14925L12.8864 0L4.45455 8.70149Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default TickIcon

TickIcon.defaultProps = {
  fill: '#27ae60'
}
