/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Paper } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import ThumbUp from '../../../shared/icons/ThumbUp'

class TwitterLinking extends React.Component {
  constructor(props) {
    super(props)
    this.removeHeaderAndSideBar()
  }

  componentDidMount() {
    this.removeHeaderAndSideBar()
  }

  componentDidUpdate() {
    this.removeHeaderAndSideBar()
  }

  removeHeaderAndSideBar = () => {
    const headerButton = document.getElementsByClassName(
      'brand-logo-section--button'
    )
    if (headerButton && headerButton.length > 0) {
      headerButton[0].remove()
    }
  }

  render() {
    const { theme } = this.props
    return (
      <div className="flex-center full-width">
        <Paper className="testimonials-feedback testimonials-feedback__thank-modal">
          <ThumbUp fill={theme.palette.primary1Color} />
          <p
            className="testimonials-feedback--header-content m-t-2"
            style={{ color: theme.palette.primary1Color }}
          >
            Thank you for linking your twitter account.
          </p>
        </Paper>
      </div>
    )
  }
}

export default withTheme(TwitterLinking)
