import React, { ChangeEvent } from 'react'
import {
  Avatar,
  Button,
  CircularProgress,
  List,
  ListItem,
  Paper,
  Typography,
  withTheme
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import qp from 'query-parse'
import URLs from '../../../router/routesUrls'

import { RouteComponentProps } from 'react-router'
import { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import hexToRgba from 'hex-to-rgba'
import { Rating } from '@material-ui/lab'
import AddTestimonials from './AddTestimonials'

type QueryParams = {
  id: string
}

interface TestimonialRevisionProps extends RouteComponentProps<QueryParams> {
  theme: MUIThemeFuncReturnType
  testimonialsStore: any
}

@inject('testimonialsStore')
@observer
class TestimonialRevision extends React.Component<TestimonialRevisionProps> {
  revisionTestimonialID: string | undefined
  constructor(props: TestimonialRevisionProps) {
    super(props)
    const queryData: QueryParams = qp.toObject(props.location.search.slice(1))
    this.revisionTestimonialID = queryData.id
    this.state = {}
    props.testimonialsStore.fetchTestimonialRevision(queryData.id)
  }

  validateInputValueSize = (value: string) => {
    const { testimonialsStore } = this.props
    const minLength =
      testimonialsStore.consultantResponse?.rating === 5 ? 100 : 0
    const maxLength =
      testimonialsStore.consultantResponse?.rating === 5 ? 1000 : 100
    if (value.length > maxLength) {
      return `Field must be maximum of ${maxLength} characters`
    }
    if (value.length < minLength) {
      return `Field must be minimum of ${minLength} characters`
    }
    return null
  }

  onSubmit = () => {
    console.log('onsubmit invoked')
  }

  nextStep() {
    const {
      testimonialsStore: { consultantInfo },
      history
    } = this.props
    history.push(
      `${URLs.testimonials.children.thank}?redirect=${encodeURIComponent(
        consultantInfo.agency.widget_base_url
      )}&agencyname=${consultantInfo.agency.name}`
    )
  }

  onChangeConsultant = (consultant: any) => {
    const { testimonialsStore } = this.props
    const { consultantInfo } = testimonialsStore

    const updateConsultandInfo = { ...consultantInfo }
    updateConsultandInfo.consultant = consultant
    testimonialsStore.setData('consultantInfo', updateConsultandInfo)
  }

  onSubmitConsultantChange = () => {
    const { testimonialsStore } = this.props
    const { consultantInfo } = testimonialsStore

    testimonialsStore.setData('consultantResponse', { ...consultantInfo })
    testimonialsStore.setData('selectRecruiter', false)
  }

  updateConsultantInfo = (event: ChangeEvent<unknown>) => {
    const { testimonialsStore } = this.props
    const { consultantInfo } = testimonialsStore
    const value = Number((event as ChangeEvent<HTMLInputElement>).target.value)
    testimonialsStore.setData('consultantInfo', {
      ...consultantInfo,
      rating: value
    })
  }

  render(): React.ReactNode {
    const { theme, testimonialsStore, history } = this.props
    const { selectRecruiter, consultantInfo, fetchDate, redirect, errors } =
      testimonialsStore

    console.log('testimonials revision -> render')
    if (!fetchDate) {
      return (
        <div className="page-preload-spinner center-xs align-items-center full-width">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    if (redirect) {
      if (errors === 'expired') {
        history.push(URLs.testimonials.children.testimonialsExpired)
      } else if (errors !== 'expired') {
        history.push(URLs.testimonials.children.testimonialsReviewed)
      }
    }

    if (!consultantInfo || (consultantInfo && !consultantInfo.consultant)) {
      return null
    }

    if (!selectRecruiter)
      return (
        <AddTestimonials
          isReviewedTestimonial
          history={this.props.history}
          location={this.props.location}
          revisionID={this.revisionTestimonialID}
        />
      )

    console.log(consultantInfo)

    return (
      <div className="flex-center-all full-width">
        <Paper className="testimonials-feedback">
          <div className="testimonials-feedback__header">
            <p
              className="testimonials-feedback--header-content"
              style={{ color: theme.palette.primary1Color }}
            >
              Choose Correct Recruiter
            </p>
          </div>
          <div
            className="testimonials-feedback__content"
            style={{
              backgroundColor: 'white',
              paddingTop: 0
            }}
          >
            <Avatar
              src={consultantInfo.consultant.avatar?.thumb}
              style={{ width: '6.25rem', height: '6.25rem' }}
            />
            <Typography style={{ marginTop: '1rem', color: '#949496' }}>
              {consultantInfo.consultant.first_name}{' '}
              {consultantInfo.consultant.last_name}
            </Typography>
            <Rating
              value={consultantInfo.rating}
              onChange={this.updateConsultantInfo}
            />
            <Paper style={{ width: '100%', maxWidth: 400, marginTop: '1rem' }}>
              <List
                component="div"
                style={{
                  height: 300,
                  width: '100%',
                  overflow: 'auto',
                  padding: 0
                }}
              >
                {consultantInfo.consultants.map((consultant: any) => (
                  <ListItem
                    key={consultant.id}
                    button
                    style={{
                      borderBottom: '1px solid #E3E3E3',
                      backgroundColor:
                        consultant.id === consultantInfo.consultant.id
                          ? hexToRgba(theme.palette.primary1Color, 0.1)
                          : 'transparent'
                    }}
                    onClick={() => this.onChangeConsultant(consultant)}
                  >
                    <Typography
                      style={{
                        color:
                          consultant.id === consultantInfo.consultant.id
                            ? theme.palette.primary1Color
                            : '#515151'
                      }}
                    >
                      {consultant.first_name} {consultant.last_name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Paper>
            <div style={{ width: '100%', maxWidth: 400, marginTop: '2rem' }}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.onSubmitConsultantChange}
                fullWidth
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TestimonialRevision)
