import React, { Component } from 'react'
import { Tabs, CircularProgress } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { observable, runInAction } from 'mobx'
import type { RouteComponentProps } from 'react-router-dom'
import { withTheme } from '@material-ui/core/styles'
import qp from 'query-parse'

import AlertContainer from 'src/components/pages/tools/Shared/AlertContainer'
import CreateJobAlert from '../CreateJobAlert'
import EmailTemplatePanelContnet from './EmailTemplatePanelContent'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import { CustomTab } from 'src/components/shared/common/CustomTab'
import { ApiRoutes } from 'src/utils/Urls'

type QueryParam = {
  action_id?: string
  external_id?: string
  company?: string
  email?: string
}

type JobAlertProps = {
  isPublic: boolean
  history: RouteComponentProps['history']
  rootStore: Record<string, any>
  utilsStore: Record<string, any>
  theme: MUIThemeFuncReturnType
  email?: string
}

type JobAlertState = {
  currentTabValue: string
}

@inject('rootStore', 'utilsStore')
@observer
class JobAlert extends Component<JobAlertProps, JobAlertState> {
  queryParam: QueryParam

  @observable
  isChangeAgencyPopup = false

  @observable
  email: string | undefined = undefined

  constructor(props: JobAlertProps) {
    super(props)

    this.state = {
      currentTabValue: 'createJobAlert'
    }

    const queryData =
      qp.toObject(this.props.history.location?.search.slice(1)) ?? {}

    this.queryParam = {
      action_id: queryData.action_id || queryData['amp;action_id'],
      external_id: queryData.external_id || queryData['amp;external_id'],
      company: queryData.company || queryData['amp;company'],
      email: queryData.email || queryData['amp;email']
    }

    if (this.queryParam.email || props.email) {
      this.email = this.queryParam.email || props.email
    }

    if (this.queryParam.action_id && this.queryParam.external_id) {
      runInAction(() => {
        this.isChangeAgencyPopup = true
      })

      this.fetchParterActions(Number(this.queryParam.company) || 0)
    }
  }

  fetchParterActions = async (agencyId: number) => {
    const {
      utilsStore: { toggleModal, switchAgencyKey },
      rootStore
    } = this.props
    const result = await rootStore.fetchParterActions(
      ApiRoutes.dashboard.jobAlerts.partnerActions,
      this.queryParam.action_id,
      this.queryParam.external_id,
      agencyId
    )
    if (result && result.candidate) {
      runInAction(() => {
        this.isChangeAgencyPopup = false
        this.email = result.candidate.email
      })
    } else if (result && result.length > 0) {
      runInAction(() => {
        this.isChangeAgencyPopup = true
      })
      toggleModal(switchAgencyKey, { isOpen: true, agencies: result })
    } else {
      runInAction(() => {
        this.isChangeAgencyPopup = false
      })
    }
  }

  handleChange = (
    _event: React.ChangeEvent<Record<string, any>>,
    newValue: string
  ) => {
    const { rootStore } = this.props

    rootStore.setSelectedMessageTemplate(null)

    this.setState({ currentTabValue: newValue })

    const url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage(
      {
        viewType: newValue
      },
      url
    )
  }

  isAgencyUser = () => {
    const {
      rootStore: { loggedIn, user }
    } = this.props
    return loggedIn && !user.isReferrer
  }

  render() {
    const { currentTabValue } = this.state
    const { isPublic, theme, history } = this.props

    if (this.isChangeAgencyPopup) {
      return (
        <div className="page-preload-spinner center-xs align-items-center">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    return (
      <div className="reports">
        <div className="blur-mask">
          <AlertContainer
            className={`blur-mask-modal-container modal ${
              this.props.isPublic && 'ml-0 w-100 mt-1'
            }`}
            fullWidth
            open
            BackdropProps={{ className: 'blur-mask-modal-container-overlay' }}
            disableBackdropClick
            disableEscapeKeyDown
            isPublic={isPublic}
          >
            {this.isAgencyUser() ? (
              <>
                <Tabs
                  value={currentTabValue}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <CustomTab
                    value="createJobAlert"
                    label={`Suggest a job alert ${
                      this.isAgencyUser() ? 'for a candidate' : ''
                    }`}
                    style={{
                      minHeight: 'auto',
                      maxWidth: 'none',
                      flexBasis: '50%',
                      borderRight: `2px solid ${theme.palette.primary1Color}`
                    }}
                  />

                  <CustomTab
                    value="createEmailTemplate"
                    label="Job alert suggestion template"
                    style={{
                      minHeight: 'auto',
                      maxWidth: 'none',
                      flexBasis: '50%'
                    }}
                  />
                </Tabs>

                {currentTabValue === 'createJobAlert' && (
                  <div className="modal-content">
                    <CreateJobAlert
                      location={history.location}
                      email={this.email}
                      isChangeAgencyPopup={this.isChangeAgencyPopup}
                    />
                  </div>
                )}

                {currentTabValue === 'createEmailTemplate' && (
                  /* @ts-ignore store props are injected */
                  <div className="modal-content">
                    <EmailTemplatePanelContnet />
                  </div>
                )}
              </>
            ) : (
              <div className="modal-content">
                <h3
                  className="refari-long-text refari-modal-header undefined"
                  style={{ color: theme.palette.primary1Color }}
                >
                  {this.isAgencyUser()
                    ? 'Suggest a job alert'
                    : 'Create a job alert'}
                </h3>
                <CreateJobAlert
                  location={location}
                  email={this.email}
                  isChangeAgencyPopup={this.isChangeAgencyPopup}
                />
              </div>
            )}
          </AlertContainer>
        </div>
      </div>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(JobAlert)
