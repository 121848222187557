import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Heart = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M472.08,81.12c-26.96-27.572-63.022-42.757-101.546-42.757c-37.93,0-73.593,14.773-100.421,41.602L256.267,93.82    l-13.859-13.859c-26.826-26.826-62.482-41.599-100.401-41.599c-37.931,0-73.593,14.773-100.417,41.599    C14.77,106.78,0,142.439,0,180.37s14.77,73.59,41.59,100.409L219.072,458.26c10.253,10.251,23.717,15.377,37.184,15.377    c13.464-0.001,26.931-5.126,37.181-15.376l176.339-176.323C525.161,226.551,526.195,136.466,472.08,81.12z M451.183,263.346    L274.845,439.671c-10.251,10.25-26.931,10.25-37.181-0.001L60.181,262.186c-21.854-21.853-33.889-50.909-33.889-81.817    s12.035-59.964,33.889-81.818c21.858-21.859,50.918-33.897,81.826-33.897c30.896,0,59.95,12.038,81.809,33.897l13.866,13.866    l-21.233,21.249c-5.132,5.136-5.128,13.459,0.007,18.591c2.567,2.565,5.929,3.847,9.292,3.847c3.367,0,6.732-1.286,9.299-3.854    l53.658-53.7c21.859-21.859,50.918-33.897,81.826-33.897c31.389,0,60.775,12.376,82.747,34.847    C497.374,144.597,496.433,218.097,451.183,263.346z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M466.29,174.403c-1.445-23.38-11.35-45.366-27.891-61.909c-11.712-11.712-26.245-20.222-42.026-24.615    c-6.995-1.946-14.242,2.147-16.188,9.142c-1.946,6.994,2.147,14.242,9.142,16.188c11.427,3.179,21.967,9.36,30.482,17.875    c12.004,12.004,19.192,27.963,20.241,44.938c0.431,6.971,6.218,12.336,13.109,12.336c0.272,0,0.547-0.008,0.823-0.026    C461.228,187.887,466.738,181.65,466.29,174.403z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Heart
Heart.propTypes = propTypes
Heart.defaultProps = defaultProps
