import { RefariDTOTypes } from '@refari-frontend/types'

export type ListResponse =
  RefariDTOTypes['/dashboard/system-admin/clients/']['get']['responses']['200']['content']['application/json']

type ResultSchema = ListResponse['results'][0]

export type IClientsListModel = ResultSchema & {
  lastInvoiceValue: string
}

class ClientsListModel implements IClientsListModel {
  id: IClientsListModel['id']
  name: IClientsListModel['name']
  user_count: IClientsListModel['user_count']
  invoice_value: IClientsListModel['invoice_value']
  subscription_count: IClientsListModel['subscription_count']
  publication_count: IClientsListModel['publication_count']
  suggestion_count: IClientsListModel['suggestion_count']
  is_toprec: IClientsListModel['is_toprec']
  is_gamification: IClientsListModel['is_gamification']
  is_website: IClientsListModel['is_website']
  is_talent_board: IClientsListModel['is_talent_board']
  is_ats_linked: IClientsListModel['is_ats_linked']
  lastInvoiceValue: IClientsListModel['lastInvoiceValue']
  is_refari: IClientsListModel['is_refari']
  is_hosting: IClientsListModel['is_hosting']
  widget_base_url?: IClientsListModel['widget_base_url']

  constructor(data: ResultSchema) {
    this.id = data.id
    this.name = data.name
    this.user_count = data.user_count
    this.invoice_value = data.invoice_value
    this.subscription_count = data.subscription_count
    this.publication_count = data.publication_count
    this.suggestion_count = data.suggestion_count
    this.is_toprec = data.is_toprec
    this.is_gamification = data.is_gamification
    this.is_website = data.is_website
    this.is_talent_board = data.is_talent_board
    this.is_ats_linked = data.is_ats_linked
    this.is_refari = data.is_refari
    this.lastInvoiceValue = data.invoice_value
      ? `${data.invoice_value.currency_sign.prefix}${data.invoice_value.amount}`
      : ''
    this.is_hosting = data.is_hosting
    this.widget_base_url = data.widget_base_url
  }
}

export default ClientsListModel
