/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable max-len */
import React from 'react'
import {
  Button, CircularProgress, Avatar, Paper
} from '@material-ui/core'
import qp from 'query-parse'
import hexToRgba from 'hex-to-rgba'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import Input from '../../shared/formComponents/Input'
import Select from '../../shared/formComponents/Select'
import lamp from '../../../assets/images/lamp.png'
import Rating from '../../shared/formComponents/RatingComponent'
import URLs from '../../../router/routesUrls'
import { validationErrors as errors } from '../../../utils/helpers'
import { customAuthorType } from '../../../constants/AgencySettingsForm'

@inject('testimonialsStore')
@observer
class RequestRating extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setQuery(props.location.search.slice(1))
    this.nextPage = this.nextPage.bind(this)
    this.state = {
      rating: 5,
      stepIndex: 0
    }
    this.previousForm = new FieldsGroup(
      {
        nonFieldErrors: new Field({
          name: 'non_field_errors',
          value: '',
          isErrorField: true,
        }),
        authorFirstName: new Field({
          name: 'first_name',
          value: '',
          validators: [errors.required()],
        }),
        authorLastName: new Field({
          name: 'last_name',
          value: '',
        }),
        authorEmail: new Field({
          name: 'email',
          value: '',
          validators: [errors.required(), errors.isEmail()],
        }),
        authorType: new Field({
          name: 'author_type',
          value: 'candidate',
        })
      }
    )
    this.nextForm = new FieldsGroup(
      {
        nonFieldErrors: new Field({
          name: 'non_field_errors',
          value: '',
          isErrorField: true,
        }),
        data: new Field({
          name: 'data',
          value: '',
          validators: [errors.required(), errors.min(this.state.rating === 5 ? 100 : 0), errors.max(this.state.rating === 5 ? 1000 : 100)]
        }),
        jobTitle: new Field({
          name: 'job_title',
          value: '',
          validators: [errors.required()]
        }),
        jobCompanyName: new Field({
          name: 'job_company_name',
          value: ''
        })
      }
    )
    props.testimonialsStore.fetchInviteTestimonials(this.query)
  }

  setQuery = (data) => {
    const newData = qp.toObject(data)
    this.query = {
      uid: newData.uid
    }
  }

  submit = () => {
    const { rating } = this.state
    const { testimonialsStore } = this.props
    const data = {
      uid: this.query.uid,
      rating,
      author: {
        first_name: this.previousForm.fields.authorFirstName.value,
        last_name: this.previousForm.fields.authorLastName.value,
        email: this.previousForm.fields.authorEmail.value,
        author_type: this.previousForm.fields.authorType.value
      },
      job_title: this.nextForm.fields.jobTitle.value,
      job_company_name: this.nextForm.fields.jobCompanyName.value
    }
    if (rating === 5) {
      data.text = this.nextForm.fields.data.value
    } else {
      data.comment = this.nextForm.fields.data.value
    }
    testimonialsStore.setInviteTestimonials(data, this.nextPage)
  }

  onRatingChange = (val) => {
    this.setState({ rating: val })
  }

  handleNext = () => {
    const { rating } = this.state
    const { testimonialsStore } = this.props
    const data = {
      uid: this.query.uid,
      rating,
      author: {
        first_name: this.previousForm.fields.authorFirstName.value,
        last_name: this.previousForm.fields.authorLastName.value,
        email: this.previousForm.fields.authorEmail.value,
        author_type: this.previousForm.fields.authorType.value
      }
    }
    testimonialsStore.checkInviteTestimonial(data, () => this.setState({ stepIndex: 1 }))
  }

  handlePrevious = () => {
    this.setState({ stepIndex: 0 })
  }

  nextPage() {
    const { testimonialsStore: { consultantInfo }, history } = this.props
    history.push(`${URLs.testimonials.children.thank}?redirect=${encodeURIComponent(consultantInfo.agency.widget_base_url)}&agencyname=${consultantInfo.agency.name}`)
  }

  render() {
    const {
      theme, testimonialsStore: {
        consultantInfo, fetchDate, redirect, loader
      }, history
    } = this.props
    const {
      rating, stepIndex
    } = this.state

    if (!fetchDate) {
      return <div className="page-preload-spinner center-xs align-items-center full-width">
        <CircularProgress
          size={80}
          color="primary"
        />
      </div>
    }

    if (redirect) {
      history.push(URLs.testimonials.children.testimonialsReviewed)
    }

    if (!consultantInfo || (consultantInfo && !consultantInfo.consultant)) {
      return null
    }

    return (
      <div className="flex-center-all full-width">
        <Paper className="testimonials-feedback">
          <div className="testimonials-feedback__header">
            <p
              className="testimonials-feedback--header-content"
              style={{ color: theme.palette.primary1Color }}
            >
              Your Feedback
            </p>
          </div>
          <div
            className="testimonials-feedback__content"
            style={{ backgroundColor: hexToRgba(theme.palette.primary1Color, 0.1) }}
          >
            <img className="testimonials-feedback--content-lamp" src={lamp} alt="lamp" />
            <p
              className="testimonials-feedback--content testimonials-feedback--content-small"
              style={{ color: theme.palette.primary1Color }}
            >
              <span>How was your experience with?</span>
            </p>
            <Avatar src={consultantInfo.consultant.avatar && consultantInfo.consultant.avatar.thumb} />
            <p className="testimonials-feedback--user-name">
              {consultantInfo.consultant.first_name} {consultantInfo.consultant.last_name}
            </p>
            {
              stepIndex === 0 && <div className="full-width pad-top-3x">
                <Rating value={rating} onChange={this.onRatingChange} />
                <div>
                  <div className="testimonials-feedback--field-section">
                    <Input
                      fieldData={this.previousForm.fields.authorFirstName}
                      label="Your first name:"
                      required
                      hint="Your first name:"
                      className="testimonials-feedback--field-section-left"
                      disabled={loader}
                    />
                    <Input
                      fieldData={this.previousForm.fields.authorLastName}
                      label="Your last name:"
                      hint="Your last name:"
                      className="testimonials-feedback--field-section-right"
                      disabled={loader}
                    />
                  </div>
                  <div className="testimonials-feedback--field-section">
                    <Input
                      fieldData={this.previousForm.fields.authorEmail}
                      label="Your email:"
                      required
                      hint="Your email:"
                      className="testimonials-feedback--field-section-full"
                      disabled={loader}
                    />
                  </div>
                  <div className="testimonials-feedback--field-section" style={{ marginTop: 20 }}>
                    <Select
                      fieldData={this.previousForm.fields.authorType}
                      label={`Your Relationship to ${consultantInfo.consultant.first_name}:`}
                      hint={`Your Relationship to ${consultantInfo.consultant.first_name}:`}
                      floatingLabelFixed
                      optionValueKey="value"
                      className="testimonials-feedback--field-section-right-select"
                      emptyOption={false}
                      options={customAuthorType}
                      disabled={loader}
                    />
                  </div>
                  <div className="flex">
                    <Button
                      variant="contained"
                      type="button"
                      className="m-t-2"
                      color="primary"
                      disabled={!this.previousForm.isValid || loader}
                      onClick={this.handleNext}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            }
            {
              stepIndex === 1
              && <div className="full-width pad-top-3x">
                {
                  rating === 5
                    ? <p className="testimonials-feedback--comments-description">
                      We are very grateful that you shared your opinion!
                      Would you like to leave a public testimonial about your recruiter?
                      Share your experience with the community
                    </p>
                    : <p className="testimonials-feedback--comments-description">
                      If you would like to add your comment, please share your thoughts.
                      All comments are reviewed by our management team :)
                    </p>
                }
                <div>
                  <div className="testimonials-feedback--field-section">
                    <Input
                      fieldData={this.nextForm.fields.jobTitle}
                      label="Job title"
                      hint="Job title"
                      required
                      maxLength={100}
                      className="testimonials-feedback--field-section-left"
                      disabled={loader}
                    />
                    <Input
                      fieldData={this.nextForm.fields.jobCompanyName}
                      label="Job company name"
                      hint="Job company name"
                      className="testimonials-feedback--field-section-right"
                      disabled={loader}
                    />
                  </div>
                  <Input
                    fieldData={this.nextForm.fields.data}
                    label={rating === 5 ? 'Add your testimonials' : 'Add your comment'}
                    required
                    rows={3}
                    rowsMax={5}
                    multiline
                    fullWidth
                    maxLength={rating === 5 ? 1000 : 100}
                    counter
                  />
                  <div className="flex m-t-2">
                    <Button
                      variant="outlined"
                      type="button"
                      color="primary"
                      style={{ marginRight: 15 }}
                      disabled={loader}
                      onClick={this.handlePrevious}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      disabled={!this.nextForm.isValid || loader}
                      onClick={this.submit}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            }
          </div>
        </Paper>
      </div>
    )
  }
}

export default withTheme(RequestRating)
