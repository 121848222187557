import React from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import PencilIcon from '../icons/PencilIcon'

const { Style } = Radium
const InptWrap = ({
  wrapDiv,
  value,
  name,
  handleChange,
  handleEdit,
  inputClass,
  placeholder,
  iconFill,
  maxLength,
  style,
  placeholderColor
}) => (
  <div className={wrapDiv}>
    <Style
      scopeSelector={`.${inputClass}::placeholder`}
      rules={{
        color: placeholderColor
      }}
    />
    <input
      type="text"
      value={value}
      name={name}
      style={style}
      onChange={handleChange}
      className={inputClass}
      placeholder={placeholder}
      maxLength={maxLength}
    />
    <PencilIcon
      className="pencil-icon"
      fill={iconFill}
      onClick={handleEdit}
      style={{ cursor: 'pointer' }}
    />
    {value.length === maxLength && (
    <p className="error-text">
      Max length should be less than {maxLength} symbols
    </p>
    )}
  </div>
)

InptWrap.propTypes = {
  wrapDiv: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  handleEdit: PropTypes.func,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  iconFill: PropTypes.string,
  maxLength: PropTypes.number,
  placeholderColor: PropTypes.string
}

InptWrap.defaultProps = {
  maxLength: 10000,
  placeholderColor: '#757575',
  inputClass: 'refari-input-box'
}

export const CustomInputWrap = Radium(InptWrap)
