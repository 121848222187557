// eslint-disable-next-line max-classes-per-file
import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import classnames from 'classnames'
import PitchMe from './PitchMe'
import DetailsStore from '../../../../stores/DetailsStore'
import { Consultant } from '../../../../models/User'
import RaisedButton from '../../../shared/common/RaisedButton'
import FlatButton from '../../../shared/common/FlatButton'
import ShareIcon from '../../../shared/icons/TestimonialsShareIcon'
import { MainJob } from '../../../../models/MainJob'
import { MainTestimonials } from '../../../../models/MainTestimonials'
import { ApiRoutes, widgetUrls } from '../../../../utils/Urls'
import PitchMeTemplateModal from '../../../shared/modals/pitchMe/PitchMeTemplateModal'
import PitchMeWarningModal from '../../../shared/modals/pitchMe/PitchMeWarningModal'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import Select from '../../../shared/formComponents/Select'
import PitchMeRenameTemplateModal from 'src/components/shared/modals/pitchMe/PitchMeRenameTemplateModal'

const consultantItem = new DetailsStore()
@inject('pitchMeStore')
@inject('rootStore')
@inject('utilsStore')
@inject('agenciesStore')
class PitchMePage extends React.Component {
  render() {
    if (!this.props.rootStore.user.id) return null
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <PitchMePageInternal {...this.props} />
  }
}

@observer
class PitchMePageInternal extends React.Component {
  constructor(props) {
    super(props)
    this.sectionRef = React.createRef()
    const { agenciesStore } = props
    if (!agenciesStore.agencyKey) {
      agenciesStore.fetchApiKey(() => {
        this.setupData()
      })
    } else {
      this.setupData()
    }
    this.form = new FieldsGroup({
      template: new Field({
        name: 'template',
        value: ''
      })
    })
    this.state = {
      openTemplateRenameModal: false
    }
  }

  setupData = () => {
    const { pitchMeStore, rootStore } = this.props
    consultantItem.initDetails({
      id: rootStore.user.id,
      model: Consultant,
      params: {}
    })
    consultantItem.fetchDetailData(ApiRoutes.agency.consultants.read)

    pitchMeStore.initList({
      model: MainJob,
      testimonialModel: MainTestimonials,
      url: ApiRoutes.agency.consultants.jobs(rootStore.user.id),
      testimonialURL: ApiRoutes.testimonials.profileList(rootStore.user.id, 1),
      expertiseURL: ApiRoutes.agency.consultants.read(rootStore.user.id),
      activeOnly: false,
      modelDerivedData: { id: rootStore.user.id },
      initialFetching: false
    })
    pitchMeStore.fetchListData()
    pitchMeStore.setData(
      'titleTextColor',
      rootStore.agency && rootStore.agency.branding
        ? rootStore.agency.branding.primaryColor
        : null
    )
  }

  componentWillUnmount() {
    const { pitchMeStore } = this.props
    consultantItem.setData('data', {})
    pitchMeStore.setData('isFetching', true)
  }

  removeVideo = () => {
    const data = { ...consultantItem.data }
    data.video = {}
    consultantItem.setData('data', data)
  }

  handleBack = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('previewData', {})
    window.history.back()
  }

  onSearchJobs = (name) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('search', name)
    pitchMeStore.fetchListData()
  }

  onSearchTestimonials = (name) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('testimonialSearch', name)
    pitchMeStore.fetchTestimonialData()
  }

  onSearchExpertise = (name) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('expertiseSearch', name)
    pitchMeStore.fetchExpertiseData()
  }

  handlePreview = async () => {
    const { pitchMeStore, rootStore } = this.props
    const user = rootStore?.user
    const { widgetBaseUrl } = rootStore.agency
    console.log('handle preview')
    console.log(pitchMeStore)
    console.log(this.form.fields.template)
    console.log(this.props.rootStore)
    let draftID = this.form?.fields?.template?.value || ''
    const fullName = `${user?.firstName} ${user?.lastName}`
    // if (this.refs.consultantPitchMe) {
    //   pitchMeStore.setData('creationPost', true)
    //   await this.refs.consultantPitchMe.savePreviewData()
    // history.push(routesUrls.PitchMePreview.link)
    // }
    console.log('draftID')
    console.log(draftID)

    if (!draftID) {
      const form = new FieldsGroup({
        nonFieldErrors: new Field({
          name: 'non_field_errors',
          value: '',
          isErrorField: true
        }),
        name: new Field({
          name: 'name',
          value: '',
          validators: []
        })
      })
      console.log('await onSUBMITTEMPLATE')
      // pitchMeStore.setData('creationPost', true)
      await this.refs.consultantPitchMe.savePreviewData()
      // await pitchMeStore.onSubmitTemplate(form)
      draftID = await pitchMeStore.saveTemplateData(form)
    }
    if (typeof window !== 'undefined' && draftID) {
      window.open(
        widgetUrls.pitchMePreviewPage(
          widgetBaseUrl,
          fullName,
          user?.id,
          draftID
        ),
        '_blank'
      )
    }
  }

  handleSaveTemplate = async () => {
    const { pitchMeStore } = this.props
    if (this.refs.consultantPitchMe) {
      if (pitchMeStore.selectedTemplate) {
        pitchMeStore.toggleWarningPopup()
      } else {
        await this.refs.consultantPitchMe.savePreviewData()
        pitchMeStore.toggleTemplateModal()
      }
    }
  }

  handleOverwrite = async () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('creationPost', true)
    await this.refs.consultantPitchMe.savePreviewData()

    await pitchMeStore.onUpdateTemplate(pitchMeStore.selectedTemplate)
    pitchMeStore.toggleWarningPopup()
  }

  handleNewCopy = async () => {
    const { pitchMeStore } = this.props
    await this.refs.consultantPitchMe.savePreviewData()
    pitchMeStore.toggleWarningPopup()
    pitchMeStore.toggleTemplateModal()
    pitchMeStore.setData('selectedTemplate', null)
  }

  onShare = async () => {
    const { pitchMeStore } = this.props
    if (this.refs.consultantPitchMe) {
      pitchMeStore.setData('creationPost', true)
      await this.refs.consultantPitchMe.savePreviewData()
      await pitchMeStore.onShareData()
    }
  }

  onChangeTemplate = (id) => {
    const { pitchMeStore } = this.props
    pitchMeStore.resetStoreData()
    this.refs.consultantPitchMe.resetFormData()
    if (id) {
      const { pitchMeStore } = this.props
      pitchMeStore.loadTemplate(id)
    } else {
      this.setupData()
    }
  }

  deleteOption = (id) => () => {
    const { pitchMeStore } = this.props
    console.log('deleteOptions pitchMepage')
    pitchMeStore.deleteTemplate(id)
    // const modalOptions = {
    //   isOpen: true,
    //   title: 'Delete Template',
    //   message: 'Are you sure you want to delete it?',
    //   buttonName: 'Yes, Delete it!',
    //   cb: () => {
    //     pitchMeStore.deleteTemplate(id)
    //   }
    // }
    // utilsStore.toggleModal('warning', modalOptions)
  }

  render() {
    const {
      rootStore,
      rootStore: { user, agency, fetchUser },
      containerQuery,
      theme,
      pitchMeStore
    } = this.props
    if (
      fetchUser &&
      !rootStore.loggedIn &&
      consultantItem.data &&
      !pitchMeStore.isFetching
    ) {
      this.redirectBack()
    } else if (
      fetchUser &&
      rootStore.loggedIn &&
      consultantItem.data &&
      !pitchMeStore.isFetching &&
      user.id &&
      user.id !== consultantItem.data.id
    ) {
      this.redirectBack()
    }

    if (
      !user.id ||
      !agency.id ||
      !consultantItem.data ||
      (consultantItem.data && !consultantItem.data.id) ||
      (user.id && user.id !== consultantItem.data.id) ||
      pitchMeStore.isFetching
    ) {
      return (
        <div className="refari-page-wrap refari-unset-overflow">
          <div className="refari-pitch-me-loader" style={{ minHeight: 1400 }}>
            <CircularProgress size={25} thickness={2} color="primary" />
          </div>
        </div>
      )
    }

    // console.log('pitch me page', pitchMeStore)
    // console.log(pitchMeStore.isComponentUpdated)

    const isTemplateSelected = pitchMeStore.selectedTemplate

    return (
      <>
        <div
          className="refari-filter-header-section-wrap refari-filter-header-section-wrap-detail"
          ref={this.sectionRef}
        >
          <div
            className={classnames(
              'refari-filter-header-section refari-filter-header-section-detail refari-filter-header-section-new'
            )}
            id="filter-header-section-wrap"
          >
            <div className="refari-w-row refari-page-wrap refari-detail-top-header">
              <div className="refari-detail-top-header-left" />
              <div className="refari-detail-top-header-right refari-detail-top-header-right-pitch">
                {pitchMeStore.selectedTemplate && (
                  <RaisedButton
                    className="refari-button-raised refari-pitch-me-preview-button"
                    onClick={() =>
                      this.setState({ openTemplateRenameModal: true })
                    }
                    style={{
                      border: `1px solid ${theme.palette.primary1Color}`,
                      color: theme.palette.primary1Color,
                      backgroundColor: '#fff',
                      marginRight: '1rem'
                    }}
                  >
                    Rename Template
                  </RaisedButton>
                )}
                {pitchMeStore.templates &&
                  pitchMeStore.templates.length > 0 && (
                    <div
                      className="refari-pitch-me-button-block"
                      style={{ marginRight: '1rem' }}
                    >
                      <Select
                        fieldData={this.form.fields.template}
                        emptyOption="Default"
                        options={pitchMeStore.templates}
                        optionValueKey="id"
                        optionTextKey="name"
                        autoWidth
                        floatingLabelFixed
                        showDeleteIcon
                        cb={this.onChangeTemplate}
                        deleteOption={this.deleteOption}
                        labelStyle={{
                          // color: customTextColor
                          color: theme.palette.primary1Color
                        }}
                        removeDefaultDisableOptionStyle
                      />
                    </div>
                  )}

                <RaisedButton
                  onClick={this.handleSaveTemplate}
                  className="refari-button-raised refari-pitch-me-preview-button"
                  style={
                    pitchMeStore.isPitchMeUpdated
                      ? {
                          border: `1px solid ${theme.palette.primary1Color}`,
                          color: theme.palette.primary1Color,
                          backgroundColor: '#fff'
                        }
                      : {}
                  }
                  disabled={
                    pitchMeStore.creationPost || !pitchMeStore.isPitchMeUpdated
                  }
                >
                  {isTemplateSelected ? 'Update Template' : 'Save As Template'}
                </RaisedButton>
                <RaisedButton
                  onClick={this.handlePreview}
                  className="refari-button-raised refari-pitch-me-preview-button"
                  style={{
                    border: `1px solid ${theme.palette.primary1Color}`,
                    color: theme.palette.primary1Color,
                    backgroundColor: '#fff',
                    marginLeft: 20
                  }}
                  disabled={pitchMeStore.creationPost}
                >
                  Preview pitch
                </RaisedButton>
                <FlatButton
                  className="refari-button-flat"
                  color="primary"
                  onClick={this.onShare}
                  style={{
                    marginLeft: 20
                  }}
                  startIcon={
                    <ShareIcon
                      fill={theme.palette.primary1Color}
                      styles={{ marginLeft: 10 }}
                    />
                  }
                  disabled={pitchMeStore.creationPost}
                >
                  Share
                </FlatButton>
                {pitchMeStore.creationPost && (
                  <div className="refari-pitch-me-create-loader">
                    <CircularProgress size={25} thickness={2} color="primary" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="refari-page-wrap refari-unset-overflow">
          <PitchMe
            ref="consultantPitchMe"
            agencyData={agency}
            containerQuery={containerQuery}
            showAgencyLink={agency.show_about_page_links}
            data={consultantItem.data}
            jobsList={pitchMeStore.jobsList}
            isFetching={pitchMeStore.isFetching}
            onSearchJobs={this.onSearchJobs}
            handleJobSearch={pitchMeStore.handleJobSearch}
            selectJob={pitchMeStore.selectJob}
            removeJob={pitchMeStore.removeJob}
            selectedJobList={pitchMeStore.selectedJobList}
            testimonialsList={pitchMeStore.testimonialsList}
            onSearchTestimonials={this.onSearchTestimonials}
            handleTestimonialSearch={pitchMeStore.handleTestimonialSearch}
            selectTestimonial={pitchMeStore.selectTestimonial}
            removeTestimonial={pitchMeStore.removeTestimonial}
            selectedTestimonialList={pitchMeStore.selectedTestimonialList}
            expertiseList={pitchMeStore.expertiseList}
            onSearchExpertise={this.onSearchExpertise}
            removeVideo={this.removeVideo}
            handleExpertiseSearch={pitchMeStore.handleExpertiseSearch}
            selectExpertise={pitchMeStore.selectExpertise}
            removeExpertise={pitchMeStore.removeExpertise}
            selectedExpertiseList={pitchMeStore.selectedExpertiseList}
          />
        </div>
        <PitchMeTemplateModal pitchMeStore={pitchMeStore} />
        <PitchMeWarningModal
          pitchMeStore={pitchMeStore}
          handleOverwrite={this.handleOverwrite}
          handleNewCopy={this.handleNewCopy}
        />
        <PitchMeRenameTemplateModal
          isOpen={this.state.openTemplateRenameModal}
          onClose={() => this.setState({ openTemplateRenameModal: false })}
        />
      </>
    )
  }
}

export default withTheme(PitchMePage)
