import { observable, computed } from 'mobx'
import { withCurrencySign, numberFormatter } from '../utils/helpers'
import RootStore from '../stores/RootStore'

export default class Currency {
  @observable value
  constructor(data, keyValue) {
    if (!data) data = {}
    this.value = data.value || data[keyValue] || 0
    this.currency = data.currency || ''
    this.currencySign = data.currency_sign || {}
  }

  @computed
  get formattedCurrency() {
    const currencyValue = RootStore.agency.showCurrencyCode
      ? numberFormatter(this.value) + ` ${this.currency}`
      : numberFormatter(this.value)
    return withCurrencySign(currencyValue, this.currencySign)
  }

  @computed
  get getRoundedFormattedCurrency() {
    const currencyValue = RootStore.agency.showCurrencyCode
      ? numberFormatter(this.value, 0) + ` ${this.currency}`
      : numberFormatter(this.value, 0)
    return withCurrencySign(currencyValue, this.currencySign)
  }

  @computed
  get formattedCurrencyWithCurrencyCode() {
    const currencyValue = numberFormatter(this.value) + ` ${this.currency}`
    return withCurrencySign(currencyValue, this.currencySign)
  }
}
