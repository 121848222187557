import { CandidateSkills } from '../../types/APITypes'

export type ICandidateSkillsModel = {
  id: number
  value: string
  name: string
}

class CandidateSkillsModel implements ICandidateSkillsModel {
  id: ICandidateSkillsModel['id']
  value: ICandidateSkillsModel['value']
  name: ICandidateSkillsModel['name']

  constructor(data: CandidateSkills) {
    this.id = data.id
    this.value = String(data.id)
    this.name = data.name
  }
}

export default CandidateSkillsModel
