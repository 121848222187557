import { NOT_APPLICABLE_OPTION_NAME } from 'src/constants/AgencySettingsForm'

type ListData = {
  name: string
  selected: boolean
  disabled: boolean
}

type ProgressionStatus = {
  id: string
  name: string
  data: Array<ListData>
}

type ProgressionStatusList = Array<ProgressionStatus>

type ProgressionStatusParams = { [key: string]: Array<string> }

const prepareProgressionStatusParams = (
  list: ProgressionStatusList
): ProgressionStatusParams => {
  const params = {} as ProgressionStatusParams

  list.forEach(({ id, data }) => {
    let selectedOptions = data
      .filter(({ selected }) => selected)
      .map(({ name }) => name)

    if (
      selectedOptions.length > 1 &&
      selectedOptions.includes(NOT_APPLICABLE_OPTION_NAME)
    ) {
      selectedOptions = ['N/A']
    }

    params[id] = selectedOptions
  })

  return params
}

export default prepareProgressionStatusParams
