import { observable, action, runInAction, computed } from 'mobx'

import Logger from 'src/utils/Logger'
import { APIResponse } from 'src/types/APITypes'
import API from 'src/utils/API'
import { ApiRoutes } from 'src/utils/Urls'
import ClientsListModel, {
  type IClientsListModel,
  type ListResponse
} from 'src/models/Clients/ListModel'

export type ToolboxOptions =
  | ''
  | 'toprec'
  | 'gamification'
  | 'website'
  | 'talent_board'
  | 'ats_linked'
  | 'refari'

type ListParams = {
  ordering?: string
  search?: string
  page?: number
  toolbox?: ToolboxOptions
  recruitment_type?: RecruitmentType
}

type SortConfig = {
  param: string
  direction: boolean // true means ascending order
}

type Filters = Partial<Omit<ListParams, 'toolbox'>> & {
  toolbox?: Array<ToolboxOptions>
}

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

export type RecruitmentType = 'internal' | 'external'

export type IClientsStore = {
  isFetchingClients: boolean
  clients: Array<IClientsListModel>
  pagination: Pagination
  shouldShowPagination: boolean
  sortConfig: SortConfig
  search: string
  recruitmentType: RecruitmentType
  setRecruitmentType: (recruitmentType: RecruitmentType) => void
  fetchClients(isForceRefreshCalculate?: boolean): Promise<void>
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  filterClients: (filters: Filters) => Promise<void>
  sortList: (sortKey: string) => Promise<void>
}

class ClientsStore implements IClientsStore {
  @observable isFetchingClients = false
  @observable clients: IClientsStore['clients'] = []
  @observable pagination: IClientsStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable sortConfig: IClientsStore['sortConfig'] = {
    param: '',
    direction: false
  }
  @observable search = ''
  @observable toolbox: ToolboxOptions = ''
  @observable recruitmentType: RecruitmentType = 'external'

  @action
  private setIsFetchingClients = (value: boolean) => {
    this.isFetchingClients = value
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {}
    params['recruitment_type'] = this.recruitmentType
    if (this.search) {
      params.search = this.search
    }

    if (this.sortConfig.param) {
      params.ordering = this.sortConfig.direction
        ? this.sortConfig.param
        : `-${this.sortConfig.param}`
    }

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    if (this.toolbox) {
      params.toolbox = this.toolbox
    }

    return params
  }

  @action
  setRecruitmentType: IClientsStore['setRecruitmentType'] = (
    recruitmentType
  ) => {
    this.recruitmentType = recruitmentType
  }

  @action
  fetchClients: IClientsStore['fetchClients'] = async (
    isForceRefreshCalculate
  ) => {
    try {
      this.setIsFetchingClients(true)

      const params = isForceRefreshCalculate
        ? { ...this.getParams, force_calculate: true }
        : this.getParams

      const response: APIResponse<ListResponse> = await API.getData(
        ApiRoutes.dashboard.systemAdmin.clients.list,
        params
      )

      if (!response.data) {
        throw new Error("FetchClients API Response:: doesn't contain data")
      }

      const clientsResponseResults = response.data.results

      runInAction(() => {
        this.clients = clientsResponseResults.map(
          (item) => new ClientsListModel(item)
        )
        /**
         * @TODO default 0 value setting in here is not safe
         */
        this.setPaginationParams({
          totalRecordCount: response.data?.count ?? 0,
          currentPage: response.data?.page ?? 0,
          totalPageCount: response.data?.page_count ?? 0,
          pageSize: response.data?.page_size ?? 0
        })
      })
    } catch (error) {
      Logger.error(error as any)
    } finally {
      this.setIsFetchingClients(false)
    }
  }

  @action
  filterClients: IClientsStore['filterClients'] = async (filters) => {
    if (typeof filters.search !== 'undefined') {
      this.search = filters.search
    }

    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    if (typeof filters.toolbox !== 'undefined') {
      this.toolbox = filters.toolbox.join(',') as ToolboxOptions
    }

    this.fetchClients()
  }

  @action
  setPaginationParams: IClientsStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  sortList: IClientsStore['sortList'] = async (sortKey: string) => {
    this.pagination.currentPage = 1

    if (this.sortConfig.param === sortKey) {
      /**
       * change the direction
       */
      this.sortConfig = {
        param: sortKey,
        direction: !this.sortConfig.direction
      }
    } else {
      this.sortConfig = {
        param: sortKey,
        direction: false
      }
    }

    this.fetchClients()
  }

  @computed
  get shouldShowPagination(): boolean {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }
}

export default new ClientsStore()
