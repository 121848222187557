import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import Modal from '../Modal'
import CloseModalButton from '../CloseModalButton'

class LinkSocialNetworkModal extends React.Component {
  constructor(props) {
    super(props)
  }

  onClose = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  render() {
    const { open } = this.props
    return (
      <Modal
        className="refari-w-modal-container"
        open={open}
        onClose={this.onClose}
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: '#fff',
            // borderColor: theme.widgetBackgroundColor,
            padding: 0,
            height: '200'
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton
            onClose={this.onClose}
            style={{ top: 0, right: 0, padding: 0 }}
          />
          <div
            style={{
              width: '100%',
              // maxWidth: 625,
              margin: 'auto',
              minHeight: 250,
              padding: '1rem'
            }}
          >
            <h3 style={{ color: this.props?.theme?.palette?.primary1Color }}>
              Link Social Network
            </h3>
            <p
              style={{
                padding: '3rem',
                paddingTop: '1rem',
                textAlign: 'center',
                lineHeight: 1.5
              }}
            >
              Please link your Social Network accounts to your Refari account.
              Click on the 'Link Social Network' Button to begin
            </p>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(LinkSocialNetworkModal)
