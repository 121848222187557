/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from '../CloseModalButton'
import Field from '../../../../models/form/Field'
import RaisedButton from '../../common/RaisedButton'
import Select from '../../formComponents/Select'
import SelectWithAutocomplete from '../../formComponents/SelectWithAutocomplete'
import ColorPicker from '../../formComponents/colorSlider/ColorPicker'
import Modal from '../Modal'

@observer
class CanvasEditModal extends React.Component {
  static propTypes = {
    canvasCardStore: PropTypes.shape({
      editedCanvasType: PropTypes.string.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.selectedFontField = new Field({
      name: 'selected_font',
      value: {
        value: props.rootStore.agency.branding.socializeFont,
        label: props.rootStore.agency.branding.socializeFont
      }
    })
    this.selectedFontAlignment = new Field({
      name: 'selected_font_alignment',
      value: props.canvasCardStore.selectedFontAlignment
    })
  }

  closeModal = () => {
    const {
      canvasCardStore: { toggleEditedCanvasModal, setData }
    } = this.props
    toggleEditedCanvasModal()
    setData('editedCanvasType', '')
    setData('isEditedUpdated', false)
  }

  componentDidUpdate() {
    const { canvasCardStore } = this.props

    if (
      canvasCardStore.isOpenEditedCanvasModal
      && canvasCardStore.editedCanvasType
      && !canvasCardStore.isEditedUpdated
    ) {
      const selectedFont = canvasCardStore[`${canvasCardStore.editedCanvasType}SelectedFont`]
      const selectedFontAlignment = canvasCardStore[
        `${canvasCardStore.editedCanvasType}SelectedFontAlignment`
      ]
      canvasCardStore.setData('isEditedUpdated', true)
      this.selectedFontField.update({
        value: selectedFont
          ? selectedFont.value
          : canvasCardStore.selectedFont
            ? canvasCardStore.selectedFont.value
            : this.props.rootStore.agency.branding.socializeFont,
        label: selectedFont
          ? selectedFont.label
          : canvasCardStore.selectedFont
            ? canvasCardStore.selectedFont.label
            : this.props.rootStore.agency.branding.socializeFont
      })
      this.selectedFontAlignment.update(
        selectedFontAlignment || canvasCardStore.selectedFontAlignment
      )
    }
  }

  handleChangeComplete = (color) => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData(
      `${canvasCardStore.editedCanvasType}TextColor`,
      color.hex
    )
  }

  toggleBold = () => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData(
      `${canvasCardStore.editedCanvasType}IsBold`,
      !canvasCardStore[`${canvasCardStore.editedCanvasType}IsBold`]
    )
  }

  toggleItalic = () => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData(
      `${canvasCardStore.editedCanvasType}IsItalic`,
      !canvasCardStore[`${canvasCardStore.editedCanvasType}IsItalic`]
    )
  }

  onChangeFont = () => {
    const {
      canvasCardStore: { selectEditedFont, editedCanvasType }
    } = this.props
    selectEditedFont(this.selectedFontField.value.value, editedCanvasType)
  }

  onChangeFontAlignment = () => {
    const {
      canvasCardStore: { setData, editedCanvasType }
    } = this.props
    setData(
      `${editedCanvasType}SelectedFontAlignment`,
      this.selectedFontAlignment.value
    )
  }

  render() {
    const {
      canvasCardStore: { isOpenEditedCanvasModal, editedCanvasType },
      canvasCardStore,
      rootStore,
      theme
    } = this.props
    if (!editedCanvasType) {
      return null
    }
    const style = {
      title: { color: theme.palette.primary1Color },
      background: { backgroundColor: theme.palette.primary1Color }
    }
    const selectedFont = canvasCardStore[`${editedCanvasType}SelectedFont`]
      || canvasCardStore.selectedFont
    const textColor = canvasCardStore[`${editedCanvasType}TextColor`]
      || canvasCardStore.textColor
    const isBold = canvasCardStore[`${editedCanvasType}IsBold`] || false
    const isItalic = canvasCardStore[`${editedCanvasType}IsItalic`] || false
    const fontFamily = [
      selectedFont
        ? selectedFont.value
        : rootStore.agency.branding.socializeFont
          ? rootStore.agency.branding.socializeFont
          : '',
      canvasCardStore.siteFont
    ]
      .filter((font) => !!font)
      .join(',')

    return (
      <Modal
        className="refari-w-modal-container"
        maxWidth="xs"
        open={isOpenEditedCanvasModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Font Settings
          </h3>
          <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
            <div className="refari-font-selector" style={{ marginTop: 10 }}>
              <label
                style={{ fontSize: '15px', margin: '0 0 15px' }}
                className="refari-font-selector__label"
              >
                Font family:{' '}
                <span style={{ fontFamily }}>
                  {selectedFont
                    ? selectedFont.value
                    : rootStore.agency.branding.socializeFont
                      ? rootStore.agency.branding.socializeFont
                      : `${canvasCardStore.siteFont} (current font applied on site)`}
                </span>
              </label>
              <SelectWithAutocomplete
                hint="Select font"
                options={canvasCardStore.fonts}
                fieldData={this.selectedFontField}
                cb={this.onChangeFont}
              />
              <div
                className="refari-font-selector__provider"
                style={{ fontSize: 12, marginTop: 5, marginBottom: 12 }}
              >
                Fonts provided by{' '}
                <a
                  style={{ color: theme.palette.primary1Color }}
                  href="https://fonts.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Fonts
                </a>
              </div>
              <Select
                fieldData={this.selectedFontAlignment}
                options={canvasCardStore.fontAlignment}
                emptyOption=""
                optionValueKey="value"
                optionTextKey="label"
                label="Font alignment"
                floatingLabelFixed
                cb={this.onChangeFontAlignment}
              />
            </div>
            <div
              className="refari-font-selector"
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              <label
                style={{ fontSize: '15px', margin: '0 0 15px' }}
                className="refari-font-selector__label"
              >
                Font style:
              </label>
              <div
                className="refari-font-style"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <FormControl>
                  <FormGroup aria-label="switch" row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isBold}
                          onChange={this.toggleBold}
                        />
                      }
                      label="Bold"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isItalic}
                          onChange={this.toggleItalic}
                        />
                      }
                      label="Italic"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <ColorPicker
              label="Font color:"
              color={textColor}
              onChangeComplete={this.handleChangeComplete}
              shouldScrollToBottom
            />
            <div className="align-center m-t-2">
              <RaisedButton
                color="primary"
                className="refari-button-raised"
                onClick={this.closeModal}
              >
                Save settings
              </RaisedButton>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(CanvasEditModal)
