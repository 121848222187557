import React, { Component } from 'react'
import { components, OptionProps } from 'react-select'
import { observer, inject } from 'mobx-react'
import { IconButton } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { canDeleteEmailTemplate } from 'src/utils/helpers'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'

type CustomEmailTemplateOptionProps = OptionProps & {
  selectProps: {
    onMenuItemDelete: (id: number) => void
    refariTheme: MUIThemeFuncReturnType
  }
  rootStore: any
  value: number
}

@inject('rootStore')
@observer
class CustomEmailTemplateOption extends Component<CustomEmailTemplateOptionProps> {
  render() {
    const {
      children,
      selectProps,
      value,
      data,
      isSelected,
      rootStore,
      innerRef
    } = this.props

    // @ts-ignore data type is not going to affect outcome
    const showDeleteOption = canDeleteEmailTemplate(data, rootStore.user?.role)

    return (
      // @ts-ignore we need spread here
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      <components.Option innerRef={innerRef} {...this.props}>
        <li
          style={{
            display: 'flex',
            alignItems: 'center',
            ...(isSelected && {
              color: selectProps.refariTheme.palette.primary1Color
            })
          }}
        >
          <span style={{ flex: 1 }}>{children}</span>
          {showDeleteOption && (
            <IconButton
              className="refari-delete-option-button"
              onClick={(event) => {
                event.stopPropagation()

                selectProps.onMenuItemDelete(value)
              }}
              style={{ padding: 4 }}
              edge="end"
              aria-label="delete"
            >
              <DeleteForeverIcon
                style={{
                  width: 20,
                  height: 20,
                  color: '#FE5E5E'
                }}
              />
            </IconButton>
          )}
        </li>
      </components.Option>
    )
  }
}

export default CustomEmailTemplateOption
