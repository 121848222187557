import React from 'react'
//@ts-ignore add type for gif support
import SmileyEmojiGif from './gif/emoji-smile.gif'

type Props = React.ComponentProps<'img'>

function SmileyGif(props: Props) {
  return <img src={SmileyEmojiGif} style={props.style} />
}

export default SmileyGif
