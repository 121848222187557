/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Dialog, Button, CircularProgress } from '@material-ui/core'
import ReactCodeInput from 'react-verification-code-input'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import { validationErrors as errors } from '../../../../utils/helpers'
import alertMessages from '../../../../constants/alertMessages'

@inject('authTwoFactorStore')
@observer
class OneTimePasswordModal extends React.Component {
  static propTypes = {
    authTwoFactorStore: PropTypes.shape({
      toggleOneTimePasswordModal: PropTypes.func.isRequired,
      isOpenOneTimePasswordModal: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      counter: 0
    }
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      token: new Field({
        name: 'token',
        value: '',
        validators: [errors.required()]
      })
    })
  }

  startTimer = () => {
    this.timer = setInterval(() => {
      const { counter } = this.state
      if (counter > 0) {
        this.setState({ counter: counter - 1 })
      }
    }, 1000)
  }

  onClose = () => {
    const {
      authTwoFactorStore: { toggleOneTimePasswordModal }
    } = this.props
    toggleOneTimePasswordModal()
    this.timer && clearInterval(this.timer)
    this.form.reset()
  }

  onSubmitCode = (e) => {
    e.preventDefault()
    const {
      authTwoFactorStore: { verifySignInAuthCode }
    } = this.props
    if (this.form.fields.token.value.length < 6) {
      return
    }
    this.form.submit(verifySignInAuthCode, () => {
      this.timer && clearInterval(this.timer)
    })
  }

  handleChange = (vals) => {
    this.form.fields.token.update(vals)
  }

  resendPhoneCode = () => {
    const {
      authTwoFactorStore: { sendBackupPhoneCode }
    } = this.props
    sendBackupPhoneCode(() => {
      toast.success(alertMessages.codeSent)
      this.timer && clearInterval(this.timer)
      this.setState({ counter: 59 }, () => {
        this.startTimer()
      })
    })
  }

  render() {
    const {
      authTwoFactorStore: { isOpenOneTimePasswordModal, isLoading, phoneData },
      theme
    } = this.props
    const { counter } = this.state

    return (
      <div className="question-mapping-section-answer-modal">
        <div className="question-mapping-section-answer-modal-box">
          <Dialog
            className="refari-modal"
            maxWidth="xs"
            fullWidth
            open={isOpenOneTimePasswordModal}
            onClose={this.onClose}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <div className="refari-modal-content">
              <div className="refari-placements-wrapper refari-phone-code-wrapper">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title"
                      style={{ color: theme.palette.primary1Color }}
                    >
                      One time password
                    </h5>
                    <button
                      type="button"
                      onClick={this.onClose}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div
                    className="modal-body"
                    style={{ padding: '0 4rem 2rem' }}
                  >
                    <div className="setting-cont">
                      <form onSubmit={this.onSubmitCode}>
                        <p
                          className="refari-phone-code-subheading"
                          style={{ marginBottom: 0 }}
                        >
                          The code has been sent via SMS to your phone number
                        </p>
                        {phoneData && phoneData.last4 && (
                          <p className="refari-phone-code-subheading">
                            {`*** *** ${phoneData.last4}`}
                          </p>
                        )}
                        <ReactCodeInput
                          onChange={this.handleChange}
                          fieldWidth={38}
                          fieldHeight={38}
                          className="refari-phone-code-input"
                        />
                        <div className="refari-w-row refari-justify-center refari-align-items-center m-t-3">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={this.form.fields.token.value.length < 6}
                            style={{ marginLeft: '35px' }}
                          >
                            Verify
                          </Button>
                          <CircularProgress
                            size={25}
                            thickness={2}
                            color="primary"
                            style={{
                              marginLeft: 10,
                              visibility: isLoading ? 'visible' : 'hidden'
                            }}
                          />
                        </div>
                        <div className="refari-phone-code-subheading-bottom">
                          {counter > 0 ? (
                            <div className="refari-phone-code-subheading-bottom-text">
                              Resend OTP in{' '}
                              <span style={{ color: 'green' }}>
                                00:{counter > 9 ? counter : `0${counter}`}
                              </span>
                            </div>
                          ) : (
                            <>
                              <div className="refari-phone-code-subheading-bottom-text">
                                Didn't receive OTP?
                              </div>
                              <div
                                className="refari-phone-code-subheading-bottom-text"
                                style={{
                                  color: theme.palette.primary1Color,
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                  textDecoration: 'underline'
                                }}
                                onClick={this.resendPhoneCode}
                              >
                                Resend OTP
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTheme(OneTimePasswordModal)
