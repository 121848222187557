/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import { observable, action, computed, reaction } from 'mobx'
import {
  CircularProgress,
  Checkbox as MUICheckbox,
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  ListSubheader,
  Button,
  IconButton,
  Tooltip,
  RadioGroup,
  FormLabel,
  Radio,
  Typography,
  Grid,
  Paper
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { FacebookProvider, Initialize } from 'react-facebook'
import RaisedButton from '../../common/RaisedButton'
import FlatButton from '../../common/FlatButton'
import Field from '../../../../models/form/Field'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import credentials from '../../../../constants/credentials'
import colors from '../../../../constants/colors'
import { FacebookHelper } from '../../../../utils/socialSharingHelpers'
import MultipleSelect from '../../formComponents/MultipleSelect'
import LinkedinIcon from '../../icons/LinkedInIcon'
import FacebookIcon from '../../icons/FacebookIcon'
import TwitterIcon from '../../icons/TwitterIcon'
import XIcon from '../../icons/XIcon'
import DirectLinkTabContent from './DirectLinkTabContent'
import AddSocialAccount from './AddSocialAccount'
import LinkSocialNetworkModal from './LinkSocialNetworkModal'
import ScheduleSocialise from './ScheduleSocialise'
import { pSBC } from 'src/utils/helpers'
import TwitterDisableMessageTooltip from '../../TwitterDisableMessageTooltip'
import { toast } from 'react-toastify'
import ScheduleSocialiseModal from './ScheduleSocialiseModal'
import FacebookWarningModal from './FacebookWarningModal'
/**
 * @todo find websiteroutes alternative and setup ADD ACCOUNT button
 */

const disabledSocialNetworkIcon = {
  facebook: (
    <FacebookIcon
      style={{
        color: '#A1A1A1'
      }}
    />
  ),
  twitter: (
    <XIcon
      style={{
        marginTop: 0,
        marginBottom: 0
      }}
    />
  ),
  linkedin: (
    <LinkedinIcon
      style={{
        color: '#A1A1A1',
        marginTop: 0,
        width: '19px',
        height: '18px'
      }}
    />
  )
}

const socialNetworks = [
  {
    name: 'facebook',
    icon: (
      <FacebookIcon
        style={{
          color: colors.facebook
        }}
      />
    ),
    passed: false,
    companies: true,
    helper: FacebookHelper,
    onError() {
      this.share()
    },
    onSuccess(res) {
      this.props.socializeStore.socialCallback({
        name: 'job',
        socialName: credentials.facebook.key,
        publication: res.id,
        next: this.share,
        link: res.link
      })
    }
  },
  {
    name: 'linkedin',
    icon: (
      <LinkedinIcon
        style={{
          color: colors.linkedin,
          marginTop: 0,
          width: '19px',
          height: '18px'
        }}
      />
    ),
    passed: false,
    companies: true,
    // helper: LinkedinHelper,
    helper: {
      process: ({ callback }) => {
        callback(undefined)
      }
    },
    onError() {
      this.share()
    },
    onSuccess(res) {
      this.props.socializeStore.socialCallback({
        name: 'job',
        socialName: credentials.linkedin.key,
        code: res,
        next: this.share
      })
    }
  },
  {
    name: 'twitter',
    icon: (
      <TwitterIcon
        style={{
          color: colors.twitter,
          marginTop: 0,
          marginBottom: 0,
          width: '20px',
          height: '20px'
        }}
      />
    ),
    passed: false,
    companies: true,
    // helper: TwitterHelper,
    helper: {
      process: ({ callback }) => {
        callback(undefined)
      }
    },
    onError() {
      this.share()
    },
    onSuccess(res) {
      this.props.socializeStore.socialCallback({
        name: 'job',
        socialName: credentials.twitter.key,
        // code: res.oauth_verifier,
        // temp_obj: res.id,
        // hasToken: res.hasToken,
        next: this.share
      })
    }
  }
]

const facebookCompany = {
  name: 'facebook',
  icon: (
    <FacebookIcon
      style={{
        color: colors.facebook
      }}
    />
  ),
  passed: false,
  companies: false,
  helper: FacebookHelper,
  onError() {
    this.share()
  },
  onSuccess(res) {
    this.refreshPassStatus()
    this.props.socializeStore.socialCallback({
      name: 'job',
      socialName: credentials.facebook.key,
      publication: res.id,
      next: this.props.socializeStore.finishSharing,
      link: res.link,
      type: 'facebook_company'
    })
  }
}

@inject('networkStore')
@inject('rootStore')
@observer
class SecondStep extends React.Component {
  static propTypes = {
    socialCallback: PropTypes.func.isRequired,
    socializeStore: PropTypes.shape({
      needInvite: PropTypes.bool.isRequired,
      pending: PropTypes.shape({
        actions: PropTypes.bool.isRequired
      }),
      finishSharing: PropTypes.func.isRequired,
      preparePublish: PropTypes.func.isRequired
    }),
    closeModal: PropTypes.func.isRequired
  }

  selectedNetworks = []

  @observable
  facebookIsReady = false
  @observable
  linkedinIsReady = false
  @observable
  twitterIsReady = false

  constructor(props) {
    super(props)
    const fields = {}
    const companyFields = {}

    this.state = {
      openAddAccountModal: false,
      openLinkSocialNetworkModal: false,
      isPostingTwitterPersonal: false,
      isPostingTwitterCompany: false,
      isSchedulePostModalOpen: false,
      isFacebookWarningModalOpen: false
    }

    socialNetworks.forEach((network) => {
      fields[`${network.name}Field`] = new Field({
        name: network.name,
        value: true
      })

      this[`onCheck${network.name}`] = (e, isChecked) => {
        fields[`${network.name}Field`].update(isChecked)
      }
    })

    this.networkSelection = new FieldsGroup(fields)

    socialNetworks
      .filter((network) => network.companies)
      .forEach((network) => {
        companyFields[`${network.name}CompaniesField`] = new Field({
          name: `${network.name}_companies`,
          value: []
        })
      })

    companyFields.facebookCompaniesField = new Field({
      name: 'facebook_companies',
      value: false
    })

    this.companiesSelection = new FieldsGroup(companyFields)

    socialNetworks
      .filter((network) => network.companies)
      .forEach((network) => {
        this.companiesSelection.fields[`${network.name}CompaniesField`].update(
          []
        )
      })

    this.reaction = reaction(
      () => this.facebookIsReady,
      (isReady, reaction) => {
        this.toggleIsReady()
        reaction.dispose()
      }
    )
  }

  postTwitterLinkCopy = async (isPersonal) => {
    const { socializeStore } = this.props
    let copyLink = ''
    if (isPersonal) {
      this.setState({ isPostingTwitterPersonal: true })
      copyLink = await socializeStore.createTwitterDirectLink(isPersonal)
    } else {
      this.setState({ isPostingTwitterCompany: true })
      copyLink = await socializeStore.createTwitterDirectLink()
    }
    this.setState({
      isPostingTwitterPersonal: false,
      isPostingTwitterCompany: false
    })
    navigator.clipboard.writeText(copyLink)
    toast.success('the link is copied')
  }

  copyDirectLink = async () => {
    const { socializeStore } = this.props
    const copyLink = socializeStore?.directLink?.ssr_link
    console.log(this.props)
    await socializeStore.markDirectLinkPublished(socializeStore?.directLink?.id)

    await navigator.clipboard.writeText(copyLink)
    toast.success('the link is copied')
  }

  getSelectedCompaniesIDs = () => {
    const selectedCompaniesIDs = []
    const networkNames = ['facebook', 'linkedin', 'twitter']
    networkNames.map((network) => {
      const IDsArray =
        this.companiesSelection.fields[`${network}CompaniesField`]?.value
      if (IDsArray && Array.isArray(IDsArray)) {
        selectedCompaniesIDs.push(...IDsArray)
      }
    })
    return selectedCompaniesIDs
  }

  UNSAFE_componentWillMount() {
    const {
      socializeStore: { fetchCompanies },
      rootStore: { user }
    } = this.props
    if (
      (user.role === 'admin' || user.role === 'manager') &&
      user.userType === 'agency'
    ) {
      fetchCompanies()
    }
  }

  handleShare = async () => {
    this.refreshPassStatus()
    this.share()
  }

  share = async () => {
    const {
      finishSharing,
      preparePublish,
      enableSharingViaCompanies,
      publishToCompanies,
      personalSocialNets
    } = this.props.socializeStore
    const { onSuccessShare } = this.props

    // get only selected for sharing and not passed
    const filteredNetworks = socialNetworks.filter(
      (network) =>
        this.networkSelection.data[network.name] &&
        !network.passed &&
        (personalSocialNets.includes(network?.name) ||
          network.name === 'facebook')
    )

    try {
      if (!filteredNetworks.length && enableSharingViaCompanies) {
        await publishToCompanies()
        // if (
        //   this.companiesSelection.fields.facebookCompaniesField.value &&
        //   !facebookCompany.passed
        // ) {
        //   facebookCompany.passed = true
        //   const prepublishData = await preparePublish('facebook')
        //   facebookCompany.helper.process({
        //     clientId: credentials.facebook.clientId,
        //     callback: facebookCompany.onSuccess.bind(this),
        //     onError: facebookCompany.onError.bind(this),
        //     title: 'Share via Facebook company page',
        //     ...prepublishData
        //   })
        // } else {
        //   this.refreshPassStatus()
        //   finishSharing('job')
        // }
        this.refreshPassStatus()
        finishSharing('job', onSuccessShare)
        return
      }
      if (!filteredNetworks.length && !enableSharingViaCompanies) {
        finishSharing('job', onSuccessShare)
        return
      }
      const firstFromQueue = filteredNetworks[0]
      const prepublishData = await preparePublish(firstFromQueue.name)
      firstFromQueue.passed = true
      firstFromQueue.helper.process({
        clientId: credentials[firstFromQueue.name].clientId,
        callback: firstFromQueue.onSuccess.bind(this),
        onError: firstFromQueue.onError.bind(this),
        ...prepublishData
      })
    } catch (error) {
      console.log(error)
    }
  }

  refreshPassStatus = () => {
    socialNetworks.forEach((network) => {
      network.passed = false
    })
    facebookCompany.passed = false
  }
  @action
  FBReadyHandler = () => {
    this.facebookIsReady = true
  }

  @action
  toggleIsReady = () => {
    this.linkedinIsReady = true
    this.twitterIsReady = true
  }

  @computed
  get isReadyToShare() {
    return this.facebookIsReady || (this.linkedinIsReady && this.twitterIsReady)
  }

  componentDidMount() {
    const { canvasCardStore } = this.props
    this.to = setTimeout(
      (self) => {
        self.toggleIsReady()
        clearTimeout(self.to)
      },
      6000,
      this
    )
    canvasCardStore.setData('canvasImageSaved', canvasCardStore.canvasImage)
  }

  componentWillUnmount() {
    if (this.to) {
      clearTimeout(this.to)
    }
    if (this.reaction) {
      this.reaction()
    }
    socialNetworks.forEach((network) => {
      // network.helper.stop()
      if (network?.name === 'facebook') network.helper.stop()
    })
  }

  getWarn = (network) => {
    const networkName = typeof network === 'string' ? network : network.name
    return `${credentials[networkName].title} was blocked by browser. Please turn off browser Tracking Protection`
  }

  selectionRenderer = (network) => (values) => {
    switch (values.length) {
      case 0:
        return `${credentials[network].title} companies`
      case 1:
        return `${values.length} ${credentials[network].title} company selected`
      default:
        return `${values.length} ${credentials[network].title} companies selected`
    }
  }

  getAllCompanySelectedIDS = () => {
    let ids = []
    socialNetworks
      .filter((network) => network.companies)
      .map((network) => {
        const selectedNetworkCompaniesIDs =
          this.companiesSelection.fields[
            `${network.name}CompaniesField`
          ]?.value?.filter(Number)
        if (
          selectedNetworkCompaniesIDs &&
          Array.isArray(selectedNetworkCompaniesIDs) &&
          selectedNetworkCompaniesIDs.length > 0
        ) {
          ids = ids.concat(selectedNetworkCompaniesIDs)
        }
      })
    return ids
  }

  onChangeSelection = (network) => () => {
    const { updateCompaniesList, setData } = this.props.socializeStore
    const ids =
      this.companiesSelection.fields[`${network}CompaniesField`].value.filter(
        Number
      )
    const allNetworksSelectedIDs = this.getAllCompanySelectedIDS()
    console.log(allNetworksSelectedIDs)
    if (
      Array.isArray(allNetworksSelectedIDs) &&
      allNetworksSelectedIDs.length > 0
    ) {
      setData('enableSharingViaCompanies', true)
    } else {
      setData('enableSharingViaCompanies', false)
    }
    updateCompaniesList(network, ids)
  }

  onCopyLink = () => {
    toast.success('the link is copied')
  }

  renderCompaniesSelection = () => {
    const {
      theme,
      socializeStore: { toggle, enableSharingViaCompanies, companies, pending },
      networkStore,
      rootStore: { user }
    } = this.props
    console.log(user)

    const styles = {
      subheader: {
        lineHeight: '18px',
        marginBottom: '15px',
        fontSize: '12px',
        padding: 0,
        width: '100%'
      }
    }

    return (
      <div
        className="refari-socialize_companies"
        style={{
          marginBottom: '0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        {/* <FormControl
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <FormGroup aria-label="switch" row>
            {!user.isAgencyConsultantUser ? (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={enableSharingViaCompanies}
                    onChange={toggle('enableSharingViaCompanies')}
                  />
                }
                label="Share via Company Networks"
                labelPlacement="end"
                style={{ marginBottom: '10px' }}
              />
            ) : (
              <Tooltip
                title="Available to admin and manager level user only"
                placement="bottom-start"
                TransitionProps={{
                  style: {
                    backgroundColor: '#fff',
                    color: 'rgba(0, 0, 0, 0.87)',
                    boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
                    fontSize: 12
                  }
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={enableSharingViaCompanies}
                      onChange={toggle('enableSharingViaCompanies')}
                    />
                  }
                  label="Share via Company Networks"
                  labelPlacement="end"
                  style={{ marginBottom: '10px' }}
                  disabled
                />
              </Tooltip>
            )}
          </FormGroup>
          
        </FormControl> */}
        <div>
          {socialNetworks
            .filter((network) => network.companies)
            .map((network) => (
              <div
                key={`${network.name}CompaniesField`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: network.name === 'twitter' ? 0 : '1rem'
                }}
              >
                <MultipleSelect
                  disabled={
                    pending.actions ||
                    companies[network.name].list.length === 0 ||
                    network.name === 'twitter'
                  }
                  className={
                    network.name === 'twitter' &&
                    (user.isAgencyAdminUser || user.isAgencyManagerUser)
                      ? 'w-50'
                      : 'w-100'
                  }
                  emptyOption=""
                  floatingLabelFixed
                  fieldData={
                    this.companiesSelection.fields[
                      `${network.name}CompaniesField`
                    ]
                  }
                  multiple
                  options={companies[network.name].list}
                  renderValue={this.selectionRenderer(network.name)}
                  cb={this.onChangeSelection(network.name)}
                  autoClose
                />
                {network.name === 'twitter' &&
                  (user.isAgencyAdminUser || user.isAgencyManagerUser) && (
                    <div>
                      <Tooltip
                        placement="bottom"
                        title={'Copy Direct Link'}
                        TransitionProps={{
                          style: {
                            backgroundColor: '#fff',
                            color: 'rgba(0, 0, 0, 0.87)',
                            boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
                            fontSize: 12
                          }
                        }}
                      >
                        <IconButton
                          style={{
                            marginBottom: '8px',
                            color: theme.palette.primary1Color
                          }}
                          onClick={() => this.postTwitterLinkCopy()}
                          size="small"
                          disableFocusRipple
                          disableRipple
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                      <span className="refari-copied-click-msg">
                        Click here to copy direct link
                      </span>
                    </div>
                  )}
                {network.name !== 'twitter' && (
                  <div
                    style={{
                      width: '24px',
                      display: 'flex',
                      justifyContent: 'end'
                    }}
                  >
                    {network.icon}
                  </div>
                )}
              </div>
            ))}
        </div>

        <div>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Other Social Network
          </Typography>{' '}
          <IconButton
            style={{
              marginBottom: '8px',
              color: theme.palette.primary1Color
            }}
            onClick={() => this.copyDirectLink()}
            size="small"
            disableFocusRipple
            disableRipple
          >
            <FileCopyIcon />
          </IconButton>{' '}
          <Typography display="inline">
            Click here to copy generic direct link
          </Typography>
        </div>

        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              networkStore.setData('source', 'company')
              this.setState({ openAddAccountModal: true })
            }}
          >
            ADD COMPANY ACCOUNT
          </Button>
        </div>
      </div>
    )
  }

  handleLinkedInPostingPreferenceChange = (value) => {
    this.props.socializeStore.setData('linkedInPostingPrefernce', value)
  }

  render() {
    const {
      theme,
      closeModal,
      socializeStore: { needInvite, pending, toggle, linkedInPostingPrefernce },
      socializeStore
    } = this.props

    const styles = {
      subheader: {
        fontSize: '16px',
        paddingLeft: 0
      }
    }

    const { personalSocialNets } = socializeStore

    const filteredPersonalNetworks = socialNetworks.filter((network) => {
      if (network.name === 'facebook') return true
      return personalSocialNets.includes(network?.name)
    })

    const filteredPersonalNetworksNameArray = filteredPersonalNetworks.map(
      (item) => item.name
    )

    const unCheckFacebook = () => {
      this[`onCheckfacebook`]?.(undefined, false)
    }

    const isShareDisabled =
      pending.actions || pending.schedulePost || !this.isReadyToShare
    const isScheduleConfimationDisabled = pending.schedulePost

    const isPersonalFacebookEnabled =
      this.networkSelection.fields[`facebookField`].value

    const isAnyCompanyNetworkSelected =
      this.getSelectedCompaniesIDs().length > 0

    const filteredNetworks = socialNetworks.filter(
      (network) =>
        this.networkSelection.data[network.name] &&
        (personalSocialNets.includes(network?.name) ||
          network.name === 'facebook')
    )

    const isAnyPersonalNetworkSelected = filteredNetworks.length > 0
    const isAnyNetworkSelected =
      isAnyCompanyNetworkSelected || isAnyPersonalNetworkSelected

    return (
      <FacebookProvider appId={credentials.facebook.appId} version="v19.0">
        <Initialize>
          {({ isReady }) => {
            isReady && this.FBReadyHandler()
            if (!isReady) {
              this[`onCheckfacebook`]?.(undefined, false)
            }
            return null
          }}
        </Initialize>
        <Typography
          variant="body1"
          style={{ fontWeight: 'bold', marginBottom: 0 }}
        >
          Share to Social Network
        </Typography>
        <div className="refari-socialize__second-step">
          <div
            className="refari-socialize__need-invite"
            style={{ marginBottom: 0 }}
          >
            <FormControl component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={
                    <MUICheckbox
                      checked={needInvite}
                      onChange={toggle('needInvite')}
                      disabled={pending.actions}
                      name="invite-recruiters"
                      color="primary"
                    />
                  }
                  label="Promote this post with your team"
                />
              </FormGroup>
            </FormControl>
          </div>

          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <ListSubheader style={styles.subheader} disableSticky>
                  Share via Personal Networks
                </ListSubheader>
                <Paper
                  style={{
                    borderRadius: '8px',
                    height: '100%',
                    flex: 1,
                    padding: '1rem',
                    paddingRight: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                  elevation={1}
                >
                  <div
                    className="refari-socialize_networks"
                    style={{ marginBottom: '0.2rem' }}
                  >
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <FormGroup>
                        {socialNetworks.map((network) => (
                          <>
                            {network.name !== 'twitter' ? (
                              <FormControlLabel
                                key={`${network.name}Field`}
                                disabled={
                                  !filteredPersonalNetworksNameArray.includes(
                                    network.name
                                  )
                                }
                                style={{ width: '100%', display: 'flex' }}
                                control={
                                  <MUICheckbox
                                    checked={
                                      !filteredPersonalNetworksNameArray.includes(
                                        network.name
                                      )
                                        ? false
                                        : this.networkSelection.fields[
                                            `${network.name}Field`
                                          ].value &&
                                          this[`${network.name}IsReady`]
                                    }
                                    onChange={this[`onCheck${network.name}`]}
                                    disabled={
                                      pending.actions ||
                                      !this[`${network.name}IsReady`] ||
                                      network.name === 'twitter'
                                    }
                                    inputProps={{
                                      'aria-label': 'disabled checkbox'
                                    }}
                                    name={
                                      this.networkSelection.fields[
                                        `${network.name}Field`
                                      ].name
                                    }
                                    color="primary"
                                  />
                                }
                                onClick={() => {
                                  if (
                                    !filteredPersonalNetworksNameArray.includes(
                                      network.name
                                    )
                                  ) {
                                    this.setState({
                                      openLinkSocialNetworkModal: true
                                    })
                                  }
                                }}
                                classes={{
                                  label: 'w-100'
                                }}
                                label={
                                  <span
                                    className="refari-socialize_network__label"
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%'
                                    }}
                                  >
                                    {credentials[network.name].title}
                                    {!filteredPersonalNetworksNameArray.includes(
                                      network.name
                                    )
                                      ? disabledSocialNetworkIcon[network.name]
                                      : network.icon}{' '}
                                  </span>
                                }
                              />
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex'
                                  }}
                                >
                                  <span
                                    className="refari-socialize_network__label"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                      marginRight: '5px'
                                    }}
                                  >
                                    <span style={{ marginLeft: '6px' }}>
                                      {credentials[network.name].title}
                                    </span>
                                  </span>

                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom: '8px',
                                      marginRight: '12px'
                                    }}
                                  >
                                    <TwitterDisableMessageTooltip />
                                  </div>

                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      'Click here to copy Twitter direct link'
                                    }
                                    TransitionProps={{
                                      style: {
                                        backgroundColor: '#fff',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        boxShadow:
                                          '1px 1px 10px rgba(0,0,0,.5)',
                                        fontSize: 12
                                      }
                                    }}
                                  >
                                    <IconButton
                                      style={{
                                        marginBottom: '8px',
                                        color: theme.palette.primary1Color
                                      }}
                                      onClick={() =>
                                        this.postTwitterLinkCopy(true)
                                      }
                                      size="small"
                                      disableFocusRipple
                                      disableRipple
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div style={{ marginRight: '16px' }}>
                                  {disabledSocialNetworkIcon['twitter']}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <div>
                      <Typography
                        display="inline"
                        style={{ fontWeight: 'bold' }}
                      >
                        Other Social Network
                      </Typography>{' '}
                      <IconButton
                        style={{
                          marginBottom: '8px',
                          color: theme.palette.primary1Color
                        }}
                        onClick={() => this.copyDirectLink()}
                        size="small"
                        disableFocusRipple
                        disableRipple
                      >
                        <FileCopyIcon />
                      </IconButton>{' '}
                      <Typography display="inline">
                        Click here to copy generic direct link
                      </Typography>
                    </div>
                  </div>
                  {filteredPersonalNetworks.length < 3 && (
                    <div
                      style={{
                        display: 'block'
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ padding: '6px 16px' }}
                        size="small"
                        onClick={() => {
                          this.setState({ openAddAccountModal: true })
                        }}
                      >
                        Link Social Network
                      </Button>
                    </div>
                  )}
                  <AddSocialAccount
                    open={this.state.openAddAccountModal}
                    onClose={() => {
                      this.setState({ openAddAccountModal: false })
                    }}
                    socializeStore={socializeStore}
                  />
                  <LinkSocialNetworkModal
                    open={this.state.openLinkSocialNetworkModal}
                    onClose={() => {
                      this.setState({ openLinkSocialNetworkModal: false })
                    }}
                  />
                </Paper>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <ListSubheader style={styles.subheader} disableSticky>
                  Share via Company Networks
                </ListSubheader>
                <Paper
                  style={{
                    borderRadius: '8px',
                    flex: 1,
                    padding: '1rem'
                  }}
                  elevation={1}
                >
                  {this.renderCompaniesSelection()}
                </Paper>
              </div>
            </Grid>
          </Grid>
          {/* this is not right change this */}

          <div
            className="refari-socialize__need-invite"
            style={{ marginBottom: 0 }}
          >
            <ListSubheader
              style={{
                ...styles.subheader,
                display: 'block',
                fontWeight: 'bold'
              }}
              disableSticky
            >
              LinkedIn Posting Preferences
            </ListSubheader>

            <FormControl
              component="fieldset"
              style={{
                display: 'flex',
                gap: '8px',
                marginLeft: '12px',
                marginRight: '-12px'
              }}
            >
              <RadioGroup
                aria-label="linkedin posting preference"
                name="linkedin-posting-preference"
                value={linkedInPostingPrefernce}
                onChange={(_event, value) =>
                  this.handleLinkedInPostingPreferenceChange(value)
                }
                row
              >
                <FormControlLabel
                  value="non-clickable"
                  control={<Radio color="primary" />}
                  label={
                    <div
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight: '4px'
                      }}
                    >
                      <Typography style={{ marginBottom: 0 }}>
                        Non-Clickable
                      </Typography>
                      <Typography style={{ marginBottom: 0 }}>
                        The post will be displayed as a non-clickable image
                        only.
                      </Typography>
                    </div>
                  }
                  style={{
                    border: '1px solid #A5A3A3',
                    borderRadius: '4px',
                    alignItems: 'flex-start',
                    flex: 1,
                    display: 'flex'
                  }}
                />
                <FormControlLabel
                  value="clickable"
                  control={<Radio color="primary" />}
                  label={
                    <div
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingRight: '4px'
                      }}
                    >
                      <Typography style={{ marginBottom: 0 }}>
                        Clickable (Small Image)
                      </Typography>
                      <Typography style={{ marginBottom: 0 }}>
                        The post will be displayed as a Clickable Small
                        thumbnail image only.
                      </Typography>
                    </div>
                  }
                  style={{
                    border: '1px solid #A5A3A3',
                    borderRadius: '4px',
                    alignItems: 'flex-start',
                    flex: 1,
                    display: 'flex'
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <ScheduleSocialise
                isFacebookEnabled={isPersonalFacebookEnabled}
                socializeStore={socializeStore}
                openFacebookWarningPopup={() =>
                  this.setState({ isFacebookWarningModalOpen: true })
                }
                openSchedulePopup={() =>
                  this.setState({ isSchedulePostModalOpen: true })
                }
              />
            </div>
            {/* <div className="col-md-6 col-sm-12">
              <DirectLinkTabContent socializeStore={socializeStore} />
            </div> */}
          </div>
          <div
            className="refari-w-row refari-justify-start refari-align-items-center refari-social-btn-section"
            style={{ justifyContent: 'end' }}
          >
            <RaisedButton
              className={`refari-button-raised ${
                pending.actions || !this.isReadyToShare
                  ? 'refari-button-raised-disabled'
                  : ''
              }`}
              color="primary"
              disabled={pending.actions}
              onClick={this.props.socializeStore.setPreviousStep}
              style={{
                borderRadius: '20px',
                marginRight: '1rem'
              }}
            >
              Back
            </RaisedButton>
            {socializeStore.scheduleSocialise ? (
              <RaisedButton
                style={{
                  marginRight: '15px',
                  backgroundColor: isScheduleConfimationDisabled
                    ? pSBC(0.8, theme.palette.primary1Color)
                    : theme.palette.primary1Color,
                  color: '#fff',
                  borderRadius: '20px'
                }}
                onClick={() => {
                  const personalNet = []
                  if (
                    this.networkSelection.fields['linkedinField']?.value &&
                    this[`linkedinIsReady`] &&
                    filteredPersonalNetworksNameArray.includes('linkedin')
                  ) {
                    personalNet.push('linkedin')
                  }
                  if (
                    this.networkSelection.fields['twitterField']?.value &&
                    this[`twitterIsReady`] &&
                    filteredPersonalNetworksNameArray.includes('twitter')
                  ) {
                    personalNet.push('twitter')
                  }
                  const companiesIDs = this.getSelectedCompaniesIDs()
                  socializeStore.scheduleSocialisePost(
                    personalNet,
                    companiesIDs
                  )
                }}
                disabled={isScheduleConfimationDisabled}
                className={`refari-button-raised ${
                  pending.actions || !this.isReadyToShare
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
              >
                CONFIRM SCHEDULED POST
              </RaisedButton>
            ) : (
              <RaisedButton
                style={{
                  marginRight: '15px',
                  backgroundColor:
                    isShareDisabled || !isAnyNetworkSelected
                      ? pSBC(0.8, theme.palette.primary1Color)
                      : theme.palette.primary1Color,
                  color: '#fff',
                  borderRadius: '20px'
                }}
                disabled={
                  pending.actions ||
                  !this.isReadyToShare ||
                  !isAnyNetworkSelected
                }
                className={`refari-button-raised ${
                  pending.actions ||
                  !this.isReadyToShare ||
                  !isAnyNetworkSelected
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                onClick={() => {
                  if (isPersonalFacebookEnabled) {
                    this.setState({ isFacebookWarningModalOpen: true })
                  } else {
                    this.setState({ isSchedulePostModalOpen: true })
                  }
                }}
              >
                Schedule
              </RaisedButton>
            )}

            <RaisedButton
              style={{
                marginRight: '15px',
                backgroundColor:
                  isShareDisabled ||
                  socializeStore.scheduleSocialise ||
                  !isAnyNetworkSelected
                    ? '#A5A3A3'
                    : '#00B365',
                color: '#fff',
                borderRadius: '20px'
              }}
              onClick={this.handleShare}
              disabled={
                isShareDisabled ||
                socializeStore.scheduleSocialise ||
                !isAnyNetworkSelected
              }
              className={`refari-button-raised ${
                pending.actions || !this.isReadyToShare
                  ? 'refari-button-raised-disabled'
                  : ''
              }`}
            >
              Share Now
            </RaisedButton>

            {/* <FlatButton
              color="primary"
              disabled={pending.actions}
              onClick={closeModal}
              style={{ marginRight: '15px' }}
              className="refari-button-flat"
            >
              Close
            </FlatButton> */}
            {(pending.actions || !this.isReadyToShare) && (
              <CircularProgress size={25} thickness={2} color="primary" />
            )}
          </div>
          <ScheduleSocialiseModal
            socializeStore={socializeStore}
            isScheduleOpen={this.state.isSchedulePostModalOpen}
            onClose={() => this.setState({ isSchedulePostModalOpen: false })}
            disableFacebook={unCheckFacebook}
            isFacebookEnabled={isPersonalFacebookEnabled}
          />
          <FacebookWarningModal
            open={this.state.isFacebookWarningModalOpen}
            onClose={() => {
              this.setState({ isFacebookWarningModalOpen: false })
              unCheckFacebook()
            }}
          />
        </div>
      </FacebookProvider>
    )
  }
}

export default withTheme(SecondStep)
