import {
  observable,
  computed,
  action,
  runInAction,
  toJS,
} from 'mobx'
import Field from './Field'
import { helpers } from '../../utils/helpers'

export default class FieldsArray {
  name

  @observable fields = []

  @computed
  get initField() {
    return new Field({ name: this.fields.length.toString(), value: '' })
  }

  @observable isPending = false

  @computed
  get isValid() {
    return this.fields.every((field) => {
      if (helpers.isObject(field) && !(field instanceof Field)) {
        return Object.values(field).every((el) => el.isValid)
      }
      return field.isValid
    })
  }

  @computed
  get isDirty() {
    return this.fields.some((field) => !field.isDirty)
  }

  @computed
  get data() {
    let data = []
    this.fields.forEach((item) => {
      switch (item.value) {
        case 'string':
          if (!item.isErrorField && item.value && item.value.trim()) {
            data = [...data, item.value]
          }
          break
        default:
          if (!item.isErrorField && item.value) {
            data = [...data, item.value]
          }
          break
      }
    })

    return data
  }

  constructor(fields = [], name, setInitField = true) {
    fields = toJS(fields)
    if (!Array.isArray(fields)) {
      throw new Error('Property "fields" must be an array!', 'FieldsArray.js', '45')
    }

    runInAction('Initialize form:', () => {
      this.name = name

      this.fields = fields

      if (!fields.length && setInitField) {
        this.fields = [this.initField]
      }
    })
  }

  @action('Submit form')
  submit = (request, cb) => {
    if (!request || typeof request !== 'function') {
      throw new Error(
        'Property "callback" is required and this property must be a function!',
        'FieldsArray.js',
        '62',
      )
    }
    this.pending(true)
    request(this, cb)
      .then(() => {
        this.pending(false)
      })
      .catch(() => {
        this.pending(false)
      })
  }

  @action('Reset form')
  reset = () => {
    this.fields.forEach((field) => {
      field.reset()
    })
  }

  @action
  pending = (bool) => {
    this.isPending = bool
  }

  @action
  addField = () => {
    this.fields = [...this.fields, this.initField]
  }

  @action
  removeField = (field) => {
    this.fields.remove(field)
  }

  @action
  pushField = (field) => {
    this.fields.push(field)
  }

  @computed
  get isEmptyLastField() {
    return !!this.fields[this.fields.length - 1].value.trim()
  }
}
