/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, Chip, List, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { toJS } from 'mobx'
import ReactTagsInput from 'react-tagsinput'
import Field from '../../../models/form/Field'
import JobFilterListItem from '../../pages/mainPages/filters/JobFilterListItem'
import { selectedFields, getSearchResults } from '../../../utils/helpers'

@observer
class TagsSelect extends React.Component {
  constructor(props) {
    super(props)
    this.inputElement = React.createRef()
    this.state = {
      open: false,
      value: '',
      showInputTag: true
    }
  }

  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    valueProcessing: PropTypes.func,
    cb: PropTypes.func,
    required: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    required: false,
    requiredIndicator: '*',
    valueProcessing: (value) => value
  }
  removeValue = true

  removeChips = (cb, key) => () => {
    this.removeValue = false
    cb && cb(key)
  }

  toggleInput = () => {
    if (this.state.showInputTag) {
      this.setState({ showInputTag: false })
    } else {
      this.setState({ showInputTag: true }, this.onFocus)
    }
  }

  onFocus = () => {
    if (this.inputElement && this.inputElement.input) {
      this.inputElement.focus()
      if (!this.state.open) {
        this.setState({ open: true })
        document.body.addEventListener('click', this.closePopup)
      }
    }
  }

  renderTag = (props) => {
    const { primary1Color } = this.props.theme.palette
    const { tag, key, onRemove, getTagDisplayValue, ...other } = props
    const { value } = this.props.fieldData
    let showBetweenOp = true
    if (value && toJS(value).length > 0 && toJS(value).length - 1 === key) {
      showBetweenOp = false
    }

    return (
      <React.Fragment key={key}>
        <div
          key={key}
          className={
            this.props.inBetweenValues !== undefined &&
            this.props.inBetweenValues !== ''
              ? 'refari-react-tagsinput-tag-wrap refari-react-tagsinput-tag-wrap-new'
              : 'refari-react-tagsinput-tag-wrap'
          }
        >
          <Chip
            style={{
              color: '#fff',
              backgroundColor:
                this.props.inBetweenValues !== undefined &&
                this.props.inBetweenValues !== ''
                  ? '#65707b'
                  : primary1Color
            }}
            onDelete={this.removeChips(onRemove, key)}
            {...other}
            label={getTagDisplayValue(tag)}
          />
          {this.props.inBetweenValues !== undefined &&
            this.props.inBetweenValues !== '' &&
            showBetweenOp && (
              <span
                className={
                  this.props.inBetweenValues.toLowerCase() === 'or'
                    ? 'refari-react-tagsinput-between-op refari-react-tagsinput-between-op-or'
                    : 'refari-react-tagsinput-between-op'
                }
              >
                {this.props.inBetweenValues}
              </span>
            )}
        </div>
        {this.props.inBetweenValues !== undefined &&
          this.props.inBetweenValues !== '' &&
          !showBetweenOp &&
          !this.state.showInputTag && (
            <div className="refari-react-tagsinput-tag-add-button">
              <IconButton
                className="add-tag-button"
                onClick={this.toggleInput}
                style={{ color: primary1Color || '#000' }}
              >
                <AddIcon
                  width="35"
                  height="35"
                  style={{ width: 30, height: 30 }}
                  fill={primary1Color || '#000'}
                />
              </IconButton>
            </div>
          )}
      </React.Fragment>
    )
  }

  openPopup = () => {
    if (!this.state.open) {
      if (
        this.props.inBetweenValues !== undefined &&
        this.props.inBetweenValues !== ''
      ) {
        this.setState({ showInputTag: true, open: true })
      } else {
        this.setState({ open: true })
      }
      document.body.addEventListener('click', this.closePopup)
    }
  }

  closePopup = (e) => {
    const path = e.path || (e.composedPath && e.composedPath())
    const idList = path.map((el) => el.id)
    if (idList.includes('refari-react-tags-select-input__modal')) {
      return null
    }
    document.body.removeEventListener('click', this.closePopup)

    const { dataList } = this.props
    if (
      this.props.inBetweenValues !== undefined &&
      this.props.inBetweenValues !== ''
    ) {
      if (dataList && selectedFields(dataList).length > 0) {
        this.setState({ showInputTag: false, open: false })
      } else {
        this.setState({ showInputTag: true, open: false })
      }
    } else {
      this.setState({ open: false })
    }
    return null
  }

  handleChangeValue = (e) => {
    this.setState({ value: e.target.value })
  }

  handleChange = (filter, removed) => {
    if (!filter && !removed) {
      this.setState({ value: '' })
    }
    console.log('handleChange -> label', this.props.label)
    const { fieldData, dataList } = this.props
    const { value } = this.state
    if (value.length && this.removeValue && removed) {
      this.setState((prevState) => ({ value: prevState.value.slice(0, -1) }))
    } else {
      if (removed) {
        this.changeStatus(dataList, removed)
      }
      this.removeValue = true
      this.setState({ value: '' })
      fieldData.update(selectedFields(dataList))
      this.props.cb && this.props.cb()
    }

    if (
      this.props.inBetweenValues !== undefined &&
      this.props.inBetweenValues !== ''
    ) {
      if (dataList && selectedFields(dataList).length < 1) {
        this.setState({ showInputTag: true })
      }
    }
  }

  changeStatus = (data, removed) => {
    const removedFilter = removed.toString().replace('_', ' - ')
    data.forEach((el) => {
      if (el.label === removedFilter) {
        el.toggle()
      }
      if (el.children.data) {
        el.children.data.forEach((item) => {
          if (item.label === removedFilter) {
            if (removedFilter === 'Other' && !item.selected) return
            item.toggle()
          }
        })
      }
    })
  }

  checkList = (list) => {
    return getSearchResults({
      list,
      searchTerm: this.state.value.toLowerCase()
    })
  }

  renderInput = (props) => {
    const { label, dataList, rootStore } = this.props
    const { onBlur, ref, ...other } = props
    const { open } = this.state
    return (
      <div
        style={
          this.state.showInputTag
            ? { display: 'inline-block' }
            : { display: 'none' }
        }
        className={
          this.props.inBetweenValues !== undefined &&
          this.props.inBetweenValues !== ''
            ? 'refari-react-tags-select-input refari-react-tags-select-input-new'
            : 'refari-react-tags-select-input'
        }
      >
        <TextField
          name="tagSelect"
          ref={ref}
          onClick={this.openPopup}
          fullWidth
          autoComplete="off"
          inputProps={{
            form: {
              autoComplete: 'off'
            }
          }}
          {...other}
          value={this.state.value}
          onChange={this.handleChangeValue}
          placeholder={`Select your ${label}`}
        />
        {open && this.checkList(dataList).length > 0 && (
          <Paper
            id="refari-react-tags-select-input__modal"
            className="refari-react-tags-select-input__modal refari-filter-list-wrap"
          >
            <List className="refari-filter-list" key={`${label}_filter-list`}>
              {this.checkList(dataList)
                .sort((a, b) => {
                  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
                  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
                  return 0
                })
                .map((filter) => {
                  const { count } = filter
                  return (
                    <JobFilterListItem
                      rootStore={rootStore}
                      title="Category"
                      showCounters
                      count={count}
                      key={`${filter.key}_filter`}
                      data={filter}
                      filters={dataList}
                      onChangeFilter={this.handleChange}
                      theme={this.props.theme}
                      saveDataInStore={false}
                    />
                  )
                })}
            </List>
          </Paper>
        )}
      </div>
    )
  }

  render() {
    const { fieldData, label, className, required, requiredIndicator } =
      this.props
    const { serverErrors, clientErrors, isTouched, value } = fieldData
    const errors = serverErrors.length
      ? serverErrors
      : isTouched && clientErrors

    return (
      <div
        className={
          this.props.inBetweenValues !== undefined &&
          this.props.inBetweenValues !== ''
            ? `refari-field-tags-wrap refari-field-tags-wrap-new ${className}`
            : `refari-field-tags-wrap ${className}`
        }
      >
        {label && (
          <label style={{ color: 'rgba(74, 74, 74, 0.6)' }}>{`${label}${
            required ? requiredIndicator : ''
          }`}</label>
        )}
        <ReactTagsInput
          value={Array.isArray(value) ? toJS(value) : []}
          ref={(el) => (this.inputElement = el)}
          onChange={this.handleChange}
          renderTag={this.renderTag}
          renderInput={this.renderInput}
        />
        <span>{errors[0]}</span>
      </div>
    )
  }
}

export default withTheme(TagsSelect)
