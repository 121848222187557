import React, { Component, type FormEvent } from 'react'
import { inject, observer } from 'mobx-react'
import { Box } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import RaisedButton from 'src/components/shared/common/RaisedButton'
import FlatButton from 'src/components/shared/common/FlatButton'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import Field from 'src/models/form/Field'
import FieldsGroup from 'src/models/form/FieldsGroup'
import { validationErrors as errors } from 'src/utils/helpers'
import Input from 'src/components/shared/formComponents/Input'

type TemplateNameChangeFormProps = {
  theme: MUIThemeFuncReturnType
  rootStore: Record<string, any>
  updateMessageTemplate: (templateName: string) => void
}

@inject('rootStore')
@observer
class TemplateNameChangeForm extends Component<TemplateNameChangeFormProps> {
  form: Record<string, any>

  constructor(props: TemplateNameChangeFormProps) {
    super(props)

    this.form = new FieldsGroup({
      // @ts-ignore TODO: Apply proper type after migrating Field to typescript
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),

      updatedName: new Field({
        name: 'updated_name',
        value: '',
        validators: [errors.required()]
      })
    })
  }

  componentDidMount() {
    const { rootStore } = this.props

    this.form.fields.updatedName.update(
      rootStore.selectedMessageTemplate?.name || ''
    )
  }

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { updateMessageTemplate } = this.props

    if (this.form.isValid) {
      updateMessageTemplate(this.form.fields.updatedName.value)
    }
  }

  render() {
    const { rootStore } = this.props

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <Box className="mb-40">
          <Input
            required
            disableFullWidthMargin
            floatingLabelFixed="true"
            fieldData={this.form.fields.updatedName}
            label="Template name"
            type="text"
          />
        </Box>

        <div>
          <FlatButton
            color="primary"
            style={{ border: '1px solid', marginRight: '24px' }}
            className="m_r_2x refari-button-flat"
            onClick={rootStore.closeMessageTemplateNameChangeModal}
          >
            Cancel
          </FlatButton>
          <RaisedButton
            className="refari-button-raised"
            style={{ color: '#fff' }}
            color="primary"
            type="submit"
          >
            Update
          </RaisedButton>
        </div>
      </form>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TemplateNameChangeForm)
