import { observable, action } from 'mobx'

export default class SocialCompany {
  @observable id
  @observable name
  @observable isActive
  @observable logo
  constructor(data) {
    /**
     * @example
     * {
     *  'id': 9,
     *  'is_active': true,
     *  'name': 'Company Name'
     * }
     */
    if (!data) return {}
    this.id = data.id
    this.name = data.name
    this.isActive = data.is_active
    this.socialUrl = data.social_url
    this.logo = data.logo
  }

  @action
  toggleCompany = () => {
    this.isActive = !this.isActive
  }
}

// export default class SocialCompany {
//   @observable id
//   @observable name
//   @observable isActive

//   constructor(data, account) {
//     if (!data) data = {}
//     this.id = data.id
//     this.name = data.name
//     this.isActive = data.is_active
//     this.account = account
//   }

//   getAccount = () => this.account
// }
