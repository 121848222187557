export default class Image {
  constructor(data) {
    if (!data) data = {}
    this.image = data.image || ''
    this.thumb = data.thumb || data.thumb_small || ''
    this.thumb_large = data.thumb_large || ''
    this.thumb_list = data.thumb_list || ''
    this.id = data.id ? data.id.toString() : ''
    this.isVideo = false
  }
}
