/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-konva'
import Konva from 'konva'
import { observer } from 'mobx-react'
import { observable, runInAction } from 'mobx'

const cachConfig = {
  pixelRatio: 4
}

@observer
class CanvasCircleImage extends React.Component {
  static propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
    blur: PropTypes.number,
    brightness: PropTypes.number
  }
  static defaultTypes = {
    blur: 0,
    brightness: 0
  }

  @observable.ref image
  @observable opacity = 1
  constructor() {
    super()
    this.image = new window.Image()
    this.image.setAttribute('crossOrigin', 'anonymous')
    // hack - safari can't draw an image that is not loaded yet
    this.image.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
  }

  componentDidMount() {
    this.draw(this.props.imageUrl)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.imageUrl && nextProps.imageUrl !== this.props.imageUrl) {
      this.draw(nextProps.imageUrl)
    }
  }

  draw = (src) => {
    const image = new window.Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = src
    image.onload = () => {
      runInAction(() => {
        this.image = image
      })
      this.refs.imageNode && this.refs.imageNode.cache(cachConfig)
      // if (!this.tween && this.refs.imageNode) {
      //   this.tween = new Konva.Tween({
      //     node: this.refs.imageNode,
      //     opacity: 1,
      //     easing: Konva.Easings.EaseInOut,
      //     duration: 0.5,
      //     onFinish: () => {
      //       runInAction(() => {
      //         this.opacity = 1
      //       })
      //     }
      //   }).play()
      // }
    }
  }

  componentDidUpdate() {
    this.refs.imageNode.cache(cachConfig)
  }

  // componentWillUnmount() {
  //   if (this.tween) {
  //     this.tween.finish()
  //     this.tween.destroy()
  //   }
  // }

  render() {
    const {
      width, height, x, y, blur, brightness
    } = this.props

    return (
      <Image
        ref="imageNode"
        filters={[
          Konva.Filters.Blur,
          Konva.Filters.Brighten,
          Konva.Filters.RGBA
        ]}
        image={this.image}
        x={x}
        y={y}
        blurRadius={blur}
        brightness={brightness}
        width={width}
        height={height}
        alpha={0}
      />
    )
  }
}

export default CanvasCircleImage
