import React from 'react'
import PropTypes from 'prop-types'

import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core'
import Refresh from '@material-ui/icons/Refresh'
import FieldsGroup from '../../../models/form/FieldsGroup'
import CharCounter from '../formComponents/CharCounter'
import Input from '../formComponents/Input'
import { validationErrors as errors, helpers } from '../../../utils/helpers'

import TagsInput from '../formComponents/TagsInput'
import socializeConfig from '../../../constants/socialize'
import qp from 'query-parse'
import { toast } from 'react-toastify'
import { Alert, AlertTitle } from '@material-ui/lab'

const isGreaterThanZero = (num) => {
  return Number(num) > 0 ? true : false
}

function convertDollarsToCents(dollars) {
  return dollars * 100
}

@inject('socializeStore')
@inject('canvasCardStore')
@inject('rootStore')
@observer
class SocializeEditForm extends React.Component {
  static propTypes = {
    socializeStore: PropTypes.shape({
      tagString: PropTypes.string.isRequired
    })
  }

  styles = {
    IconButton: {
      width: '35px',
      height: '35px',
      padding: '0'
    }
  }

  titleMaxLength = 100
  maxLength = 280

  constructor(props) {
    super(props)

    this.props.socializeStore.title
      .setData('validators', [errors.required()])
      .setData('maxLength', socializeConfig.title.maxLength)

    this.props.socializeStore.description.setData('validators', [
      errors.required()
    ])

    const { canvasCardStore } = props

    const isTestimonials = canvasCardStore?.isTestimonials

    const fields = {
      title: this.props.socializeStore.title,
      description: this.props.socializeStore.description
    }

    if (isTestimonials) {
      const title = this.props.socializeStore.title.value
      const isGoogleReview =
        this.props.socializeStore.initialData?.isGoogleReview
      if (isGoogleReview) {
        this.props.socializeStore.title.update(`Google Review`)
      } else {
        this.props.socializeStore.title.update(`Testimonial About ${title}`)
      }
    }

    if (this.props.socializeStore.postType === 'anything') {
      fields.destinationURL = this.props.socializeStore.destinationURL
    }

    this.form = new FieldsGroup(fields)

    this.state = {
      isGeneratingContent: false,
      remainingToken: 0
    }
    this.getAISettings(props)
  }

  getAISettings = async (props) => {
    const { canvasCardStore } = props
    const isAIEnabled = props.rootStore?.agency?.allowAI
    if (!isAIEnabled) return
    const availableTokens = await canvasCardStore.getGenerativeAISettings()
    this.setState({
      remainingToken: availableTokens
    })
  }

  renderCounterLabel = () => {
    const currentLength =
      this.form.fields.description.value.length +
      this.props.socializeStore.tagString?.length
    return (
      <span>
        Summary with tags
        {currentLength > socializeConfig.description.maxLength
          ? '. Characters limit exceeded. Please reduce quantity to preventing the possible issue appearing.'
          : null}
      </span>
    )
  }

  generateContentForDescriptionAndHashtags = async () => {
    const { socializeStore, canvasCardStore } = this.props
    const { updateTags } = socializeStore

    const postType = socializeStore?.initialData?.postType
    this.setState({ isGeneratingContent: true })
    let result = ''
    console.log(socializeStore?.initialData)
    if (postType === 'job') {
      const jobID = socializeStore?.initialData?.jobId
      result = await canvasCardStore.generateDescriptionAndHashtags({
        postType,
        jobID
      })
    } else if (postType === 'job_list') {
      console.log('job_list generate content')
      const linkQueryParams = qp.toObject(socializeStore?.initialData?.link)
      result = await canvasCardStore.generateDescriptionAndHashtags({
        postType,
        locations:
          linkQueryParams.location
            ?.split(',')
            ?.map((location) => Number(location)) || [],
        categories:
          linkQueryParams.category
            ?.split(',')
            ?.map((category) => Number(category)) || [],
        search: linkQueryParams.rSearch
      })
    } else if (postType === 'profile') {
      console.log('profile generate content')
      result = await canvasCardStore.generateDescriptionAndHashtags({
        postType
      })
    } else if (postType === 'candidate_ad') {
      //candidate_ad
      result = await canvasCardStore.generateDescriptionAndHashtags({
        postType,
        candidateAdId: socializeStore?.initialData.candidateAdId
      })
    } else {
      /**
       * if post type is not available that means it is testimonials
       */
      console.log('testimonial generate content')
      const testimonialID = socializeStore?.initialData?.jobId
      result = await canvasCardStore.generateDescriptionAndHashtags({
        postType: 'testimonial',
        testimonialID
      })
    }
    this.setState({
      isGeneratingContent: false,
      remainingToken: result?.availableBudget
    })
    if (!result) return
    if (result?.message) {
      this.form.fields.description.update(result.message)

      const toastBackground =
        'transparent linear-gradient(19deg, #25B4D5 0%, #19DCE6 51%, #21A6FE 100%) 0% 0% no-repeat padding-box'
      toast.info(
        <Alert icon={false} style={{ background: toastBackground }}>
          <AlertTitle style={{ color: 'white', fontWeight: '700' }}>
            To generate AI-Generated description and tags
          </AlertTitle>
          <div
            style={{ display: 'flex', flexDirection: 'column', color: 'white' }}
          >
            <span>
              Cost{' '}
              <span style={{ fontWeight: '700' }}>
                ${result?.price || 0.05}USD
              </span>
            </span>
            <span>
              Remaining AI Budget{' '}
              <span style={{ fontWeight: '700' }}>
                ${result.availableBudget}USD
              </span>
            </span>
          </div>
        </Alert>,
        {
          autoClose: 8000,
          style: {
            background: toastBackground
          }
        }
      )
    }
    updateTags(result?.hashtags || [])

    socializeStore.setData('isUsedAI', true)
  }

  render() {
    const { tags, pending, refreshTags, tagString, postType } =
      this.props.socializeStore
    const {
      palette: { disabledColor, secondaryTextColor },
      textField: { errorColor }
    } = this.props.theme
    const iconButtonStyle = {
      width: '16px',
      height: '16px',
      padding: '0'
    }

    const isAIEnabled = this.props.rootStore?.agency?.allowAI
    console.log('socialize edit form', this.props)

    return (
      <div tabIndex={-1}>
        {isAIEnabled && postType !== 'anything' && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg="auto"
              alignItems="center"
              style={{ display: 'flex' }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.generateContentForDescriptionAndHashtags}
                disabled={
                  this.state.isGeneratingContent ||
                  !isGreaterThanZero(this.state.remainingToken)
                }
                style={{
                  marginRight: '1rem',
                  borderRadius: '1rem',
                  background:
                    this.state.isGeneratingContent ||
                    !isGreaterThanZero(this.state.remainingToken)
                      ? 'rgba(0, 0, 0, 0.12)'
                      : 'transparent linear-gradient(19deg, #25B4D5 0%, #19DCE6 51%, #21A6FE 100%) 0% 0% no-repeat padding-box'
                }}
                classes={{ label: 'text-transform-none' }}
                size="large"
              >
                AI Generate Description and Tags
              </Button>
              {this.state.isGeneratingContent && <CircularProgress size={24} />}
            </Grid>
          </Grid>
        )}
        {postType === 'anything' && (
          <Input
            className="refari-card-destination-field"
            style={{ color: 'black' }}
            fieldData={this.form.fields.destinationURL}
            label="Destination URL"
            inputStyle={{ color: secondaryTextColor }}
            required
            showTicks
          />
        )}
        <Input
          counter
          counterIndicator
          maxLength={100}
          className="refari-card-title-field"
          style={{ color: 'black' }}
          fieldData={this.form.fields.title}
          label="Post Title"
          inputStyle={{ color: secondaryTextColor }}
        />
        <Input
          className="refari-card-description-field"
          maxLength={socializeConfig.description.maxLength}
          fieldData={this.form.fields.description}
          label="Post Content"
          multiline
          rows={3}
          rowsMax={null}
        />
        <div className="refari-card-tags" style={{ color: secondaryTextColor }}>
          <TagsInput
            fieldData={tags}
            creatable
            searchable
            valueProcessing={helpers.removeSymbols}
            placeholder="Add hashtag"
          />
          <Tooltip
            title="Refresh"
            placement="bottom"
            TransitionProps={{
              style: {
                backgroundColor: '#fff',
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
                fontSize: 12
              }
            }}
          >
            <IconButton
              style={iconButtonStyle}
              disabled={pending.actions}
              onClick={refreshTags}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        </div>

        <CharCounter
          currentLength={
            this.form.fields.description.value.length + tagString?.length
          }
          maxLength={socializeConfig.description.maxLength}
          label={this.renderCounterLabel()}
          className="refari-description-counter"
          color={disabledColor}
          alertColor={errorColor}
        />
      </div>
    )
  }
}

export default withTheme(SocializeEditForm)
