import React from 'react'
import ButtonLink from '../buttons/ButtonLink'

const ResendVerifyForm = ({ resendEmailVerify }) => (
  <div>
    <h4 className="align-center">Thank you for signing up! Please check your email to confirm your account</h4>
    <p className="align-center resend-verify">
      If you did not receive your email, click
      <ButtonLink
        onClick={resendEmailVerify}
        textLink="Resend activation email"
        className="inline"
      />
    </p>
  </div>
)

export default ResendVerifyForm
