import type {
  Option,
  GroupedOption
} from 'src/components/shared/formComponents/v2/Select'

type AugmentedOption = Option & { templateType: string }

const groupByTemplateType = (
  options: Array<AugmentedOption>
): Array<GroupedOption> => {
  const output: GroupedOption | { [key: string]: any } = {}

  options.forEach((option) => {
    const { templateType } = option

    if (!output[templateType]) {
      output[templateType] = { name: templateType, options: [] }
    }

    output[templateType].options.push({ ...option })
  })

  return Object.values(output)
}

export default groupByTemplateType
