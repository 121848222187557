import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import classnames from 'classnames'
import { withTheme } from '@material-ui/core/styles'
import { action, observable } from 'mobx'
import { Controller, Scene } from 'scrollmagic'
import ParticlesBackground from '../shared/common/ParticlesBackground'
import Background from '../shared/common/Background'
import LogoIcon from '../shared/icons/LogoIcon'

@inject('rootStore')
@inject('requestStore')
@observer
class Home extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      newsSubscription: PropTypes.func.isRequired,
      isNewsFormSubmit: PropTypes.bool.isRequired,
    }),
  }

  @observable isEnter = false
  @observable direction = ''

  constructor(props) {
    super(props)
    this.sectionRef = React.createRef()
    const controllerConfig = {}
    this.constroller = new Controller(controllerConfig)
  }

  componentDidMount() {
    this.scene = new Scene({
      triggerElement: this.sectionRef.current,
      triggerHook: 0.6,
      duration: '75%'
    })
      .on('start end enter leave', this.updateBox)
      .addTo(this.constroller)
  }

  componentWillUnmount() {
    if (this.scene) {
      this.scene.removeIndicators()
      this.scene.destroy(true)
    }
  }

  handleOpenRequestModal = () => {
    this.props.requestStore.setRequestData()
    this.props.requestStore.toggleModal()
  }

  getTransitionDelay = (i) => ({ transitionDelay: `${i * 0.1}s` })

  @action
  updateBox = (e) => {
    this.direction = e.scrollDirection
    switch (e.type) {
      case 'enter':
        this.isEnter = true
        break
      default:
        break
    }
  }

  render() {
    const { theme } = this.props
    const styles = {
      colorWhite: {
        color: theme.palette.whiteTextColor
      },
      borderWhite: { borderColor: theme.palette.whiteTextColor },
      backgroundPrimary: { background: '#159ccc' },
      gradientPrimary: {},
    }
    return (
      <div className="home-page-section">
        <div className="home with-particle-background flex">
          <Background
            className="home-background"
            containerStyle={styles.backgroundPrimary}
            style={styles.gradientPrimary}
          />
          <ParticlesBackground />
          <div className="container align-items-center">
            <div className="full-width row center-xs">
              <div
                ref={this.sectionRef}
                className={classnames(
                  'col-xs-12 col-md-8 col-lg-9 home-block',
                  {
                    'a-on-enter-animate': this.isEnter,
                  }
                )}
              >
                <a href="https://refari.co" target="_blank" rel="noopener noreferrer" style={{ zIndex: 1 }}>
                  <LogoIcon style={{ width: 145, height: 70 }} className="brand-logo m-b-2" fill="#fff" />
                </a>
                {/* <h1 style={styles.colorWhite} className="home-page-section--header">
                  A seamless suite of digital tools designed especially for recruiters
                </h1> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(Home)
