/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { observable, action, computed, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import RootStore from './RootStore'
import permissions from '../constants/permissions'
import Invoice from '../models/Invoice'
import Payments from '../models/Payments'
import PaymentConsultants from '../models/PaymentConsultants'
import Currency from '../models/Currency'
import Logger from '../utils/Logger'
import { ApiRoutes } from '../utils/Urls'
import API from '../utils/API'
import alertMessages from '../constants/alertMessages'
import { RecruiterBudgetModel } from 'src/models/RecruiterBudget'

export class BillingStore {
  @observable activeTab = ''
  @observable displayLoader = false
  @observable accountData = {}
  @observable billingData = {}
  @observable paymentData = {}
  @observable showCardForm = false
  @observable isFetching = true
  @observable invoiceBreakdownModal = false
  @observable.ref invoice = {}
  @observable.ref transactionData = []
  @observable.ref invoiceBreakDownData = []
  @observable getAccountDataRequest = null
  @observable recruitersBudgetDetails = []
  @observable budgetPageStatus = {
    isFetching: false,
    currentPage: 1,
    totalPages: 1
  }
  @observable defaultAgencyBudget

  @computed
  get billingPermissions() {
    const { role, userType, isAgencyAdminUser } = RootStore.user

    if (RootStore.agency.access === 'candidate_widget') {
      return permissions.candidateWidget.hasAccessToBilling.pages
    }

    if (RootStore.loggedIn && isAgencyAdminUser) {
      return permissions[userType][role].hasAccessToBilling.pages
    }
    return []
  }

  @computed
  get badgesPermissions() {
    const { role, userType, isAgencyAdminUser } = RootStore.user

    if (RootStore.loggedIn && isAgencyAdminUser) {
      return permissions[userType][role].hasAccessToBadges.pages
    }
    return []
  }

  @action
  initTab = (path) => {
    this.activeTab = path
  }

  @action
  onTabChange = (link, cb) => {
    cb && cb(link)
    this.activeTab = link
  }

  fetchPdfFile = async (id) => {
    try {
      const res = await API.getData(ApiRoutes.billing.payments.loadFile(id))
      return res
    } catch (e) {
      Logger.error(e)
    }
  }

  @action
  fetchTransactions = async (id) => {
    try {
      const response = await API.getData(
        ApiRoutes.billing.payments.transactions(id)
      )
      const res = await API.getData(ApiRoutes.billing.payments.transaction(id))
      if (response && response.data) {
        runInAction(() => {
          this.transactionData = response.data.map((el) => new Payments(el))
          this.paymentData = new Payments(res.data)
          if (this.paymentData.consultantCount) {
            this.transactionData.map(
              (el) => (el.consultantCount = this.paymentData.consultant_count)
            )
          }
        })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action cleaningPaymentData = () => {
    this.paymentData = {}
    this.invoiceBreakDownData = []
  }

  @action
  fetchInvoiceData = async (id, cb) => {
    try {
      const response = await API.getData(ApiRoutes.billing.invoices.read(id))
      runInAction(() => {
        this.invoice = new Invoice(response.data)
      })
    } catch (error) {
      Logger.error(error)
      cb && cb()
    }
  }

  @action
  resetInvoiceData = () => {
    this.invoice = {}
  }

  @action.bound
  toggleInvoiceBreakdownModal() {
    this.invoiceBreakdownModal = !this.invoiceBreakdownModal
  }

  checkAcceptance = async (invoice) => {
    try {
      const response = await API.getData(
        ApiRoutes.billing.invoices.acceptCheck(invoice.id)
      )
      return {
        canAccept: true,
        total: new Currency(response.data.total, 'amount'),
        internal: response.data.internal,
        stripe: response.data.stripe
      }
    } catch (error) {
      return {
        canAccept: false,
        message: 'Oops!'
      }
    }
  }

  accept = async (invoice, cb) => {
    try {
      const res = await API.postData(
        ApiRoutes.billing.invoices.accept(invoice.id)
      )

      if (res.response && res.response.status === 400) {
        if (res.response.data.payment && res.response.data.payment[0]) {
          toast.error(res.response.data.payment[0])
        }
      } else {
        runInAction(() => {
          invoice.status = 'accepted'
        })
        // toast.success(response.data)
      }
      cb && cb()
    } catch (error) {
      cb && cb()
      if (error.response.data.payment && error.response.data.payment[0]) {
        toast.error(error.response.data.payment[0])
      }
      Logger.error(error)
    }
  }

  pay = async (invoice, cb, errorCb) => {
    try {
      const response = await API.postData(
        ApiRoutes.billing.payments.pay(invoice)
      )
      if (response.response && response.response.status === 400) {
        if (
          response.response.data.payment &&
          response.response.data.payment[0]
        ) {
          toast.error(response.response.data.payment[0])
          Logger.error(response.response.data.payment[0])
        }
      } else {
        runInAction(() => {
          invoice.status = 'accepted'
        })
        toast.success(response.data)
      }
      cb && cb()
    } catch (error) {
      errorCb && errorCb(error?.data?.payment[0])
      // console.log('error -> pay')
      // console.log(error)
      // if (Array.isArray(error?.data?.payment) && error.data.payment[0]) {
      //   toast.error(error.data.payment[0])
      //   Logger.error(error.data.payment[0])
      // }
    }
  }

  reject = async (invoice, field) => {
    try {
      await API.postData(ApiRoutes.billing.invoices.reject(invoice.id), {
        [field.name]: field.value
      })
      runInAction(() => {
        invoice.status = 'rejected'
      })
      toast.error(alertMessages.invoiceRejected)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  getAccountData = async () => {
    try {
      const response = await API.getData(ApiRoutes.billing.account.read)
      runInAction(() => {
        this.accountData = response.data
        if (this.accountData.payment_data) {
          this.showCardForm = false
        } else {
          this.showCardForm = true
        }
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  getBillingData = async () => {
    try {
      const response = await API.getData(
        ApiRoutes.billing.account.billingAddress
      )
      runInAction(() => {
        this.billingData = response.data
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchInvoiceBreakdown = async (id) => {
    try {
      const response = await API.getData(
        ApiRoutes.billing.payments.consultants(id)
      )
      runInAction(() => {
        if (response.data && response.data.consultants) {
          this.invoiceBreakDownData = response.data.consultants.map(
            (el) => new PaymentConsultants(el)
          )
        }
      })
      this.setData('isFetching', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateBillingData = async (form) => {
    try {
      await API.postData(ApiRoutes.billing.account.billingAddress, form.data)
      toast.success(alertMessages.updateBilling)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  confirmCardSetup = async () => {
    try {
      let msg = alertMessages.addCard
      if (this.accountData && this.accountData.payment_data) {
        msg = alertMessages.replaceCard
      }
      this.getAccountDataRequest = setInterval(async () => {
        const response = await API.getData(ApiRoutes.billing.account.read)
        if (
          response.data &&
          response.data.payment_data &&
          response.data.payment_data.is_new
        ) {
          runInAction(() => {
            this.accountData = response.data
            this.showCardForm = false
          })
          toast.success(msg)
          clearInterval(this.getAccountDataRequest)
        }
      }, 2000)
    } catch (err) {
      Logger.error(err)
    }
  }

  @action
  createCard = async () => {
    try {
      const response = await API.postData(
        ApiRoutes.billing.account.createCard,
        {}
      )
      if (response.response && response.response.status === 400) {
        if (response.response.data.non_field_errors) {
          toast.error(response.response.data.non_field_errors[0])
          return null
        }
        return 'error'
      }
      return response.data.client_secret
    } catch (err) {
      if (err.data.non_field_errors) {
        toast.error(err.data.non_field_errors[0])
        return null
      }
      Logger.error(err)
      return 'error'
    }
  }

  @action
  updateEmail = async (email) => {
    try {
      let response = null
      if (this.accountData && this.accountData.payment_data) {
        response = await API.patchData(ApiRoutes.billing.account.update, {
          email
        })
      } else {
        response = await API.postData(ApiRoutes.billing.account.update, {
          email
        })
      }
      if (response && response.response && response.response.status === 400) {
        if (response.response.data.non_field_errors) {
          toast.error(response.response.data.non_field_errors[0])
        }
        return false
      }
      return true
    } catch (err) {
      if (err.data.non_field_errors) {
        toast.error(err.data.non_field_errors[0])
      }
      Logger.error(err)
      return false
    }
  }

  @action
  getAgencyBudget = async (cb) => {
    try {
      const response = await API.getData(ApiRoutes.billing.budget.agencyBudget)
      if (typeof response?.data['ai_budget'] !== 'undefined') {
        runInAction(() => {
          this.defaultAgencyBudget = Number(response.data['ai_budget'])
        })
      }
      cb &&
        cb(
          typeof response?.data['ai_budget'] !== 'undefined'
            ? response?.data['ai_budget']
            : undefined
        )
    } catch (err) {
      Logger.error(err)
    }
  }

  @action
  updateAgencyBudget = async (newBudget) => {
    try {
      const response = await API.postData(
        ApiRoutes.billing.budget.agencyBudget,
        {
          ai_budget: newBudget
        }
      )
      if (typeof response?.data['ai_budget'] !== 'undefined') {
        runInAction(() => {
          this.defaultAgencyBudget = Number(response.data['ai_budget'])
          toast.success(alertMessages.updateAgencyBudget)
        })
      }
    } catch (err) {
      if (err.data['ai_budget']) {
        toast.error(err.data['ai_budget'][0])
      }
      Logger.error(err)
    }
  }

  @action
  getRecruitersBudgetDetails = async ({ currentPage, cb } = {}) => {
    try {
      if (this.budgetPageStatus.isFetching) return
      this.budgetPageStatus = {
        ...this.budgetPageStatus,
        isFetching: true
      }
      const response = await API.getData(
        ApiRoutes.billing.budget.listRecruiters,
        {
          page: currentPage || this.budgetPageStatus.currentPage
        }
      )
      const result = response?.data?.results
      if (Array.isArray(result)) {
        runInAction(() => {
          this.recruitersBudgetDetails = result.map(
            (recruiter) => new RecruiterBudgetModel(recruiter)
          )

          this.budgetPageStatus = {
            isFetching: false,
            currentPage: response.data.page,
            totalPages: response.data.page_count
          }
        })
      }
      cb && cb()

      console.log(response)
    } catch (err) {
      if (err.data.non_field_errors) {
        toast.error(err.data.non_field_errors[0])
      }
      Logger.error(err)
    }
  }

  @action
  setNewBudgetForRecruiter = async (recruiterID, newBudget) => {
    try {
      if (this.budgetPageStatus.isFetching) return

      const response = await API.patchData(
        ApiRoutes.billing.budget.updateRecruiters(recruiterID),
        {
          amount: newBudget
        }
      )
      const amount = response?.data?.amount
      console.log(response)

      if (typeof amount !== 'undefined') {
        runInAction(() => {
          this.recruitersBudgetDetails = this.recruitersBudgetDetails.map(
            (recruiter) => {
              if (recruiter.id === recruiterID)
                return new RecruiterBudgetModel(response.data)
              return recruiter
            }
          )
          toast.success(alertMessages.updateRecruiterBudget)
        })
      }
    } catch (err) {
      Logger.error(err)
      if (err.data?.amount) {
        toast.error(err.data.amount[0])
      }
    }
  }

  @action
  toggleCardForm = () => {
    this.showCardForm = !this.showCardForm
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new BillingStore()
