/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'react-konva'

// TODO: rework method of calculating scaled font size
export default class CanvasResizableText extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    maxFontSize: PropTypes.number.isRequired,
    fontFamily: PropTypes.string,
    fontStyle: PropTypes.string.isRequired,
    padding: PropTypes.number.isRequired,
    fontAlignment: PropTypes.string,
    fill: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.measureContainer = document.createElement('div')
    this.measureContainer.style.visibility = 'hidden'
    this.measureContainer.style.overflow = 'hidden'
    this.measureContainer.style.height = 0
    this.measureContainer.style.width = 0
    this.measureContainer.style.position = 'fixed'
    this.measureContainer.style.left = '-9999px'
    document.body.insertBefore(
      this.measureContainer,
      document.body.childNodes[0]
    )
  }

  static defaultProps = {
    size: 26,
    fontAlignment: 'center'
  }

  static contextTypes = {
    attrs: PropTypes.object.isRequired
  }

  scaledFontsize = () => {
    const {
      maxFontSize, text, fontFamily, fontWeight, padding
    } = this.props
    const { width, height } = this.context.attrs

    const box = document.createElement('div')
    const textSpan = document.createElement('span')
    textSpan.innerText = text
    textSpan.style.fontSize = `${maxFontSize}px`
    textSpan.style.fontFamily = fontFamily
    textSpan.style.fontWeight = fontWeight

    box.style.width = `${width}px`
    box.style.height = `${height}px`
    box.style.padding = `${padding}px`
    box.appendChild(textSpan)
    let scaledFontsize = maxFontSize
    this.measureContainer.innerText = ''
    this.measureContainer.appendChild(box)
    const lineHeight = 1
    let textHeight = textSpan.getBoundingClientRect().height
    let textWidth = textSpan.getBoundingClientRect().width
    do {
      textSpan.style.fontSize = `${scaledFontsize}px`
      textSpan.style.lineHeight = lineHeight
      const size = textSpan.getBoundingClientRect()
      textHeight = size.height
      textWidth = size.width
      scaledFontsize -= 2
    } while ((textHeight >= height || textWidth >= width) && scaledFontsize > 1)

    if (scaledFontsize > 36) {
      return 36
    }
    return scaledFontsize
  }

  render() {
    const {
      text,
      fontFamily,
      fill,
      padding,
      fontAlignment,
      fontStyle,
      name
    } = this.props

    const { width, height } = this.context.attrs

    return (
      <Text
        name={name}
        key={name}
        ref="node"
        height={height}
        text={text}
        fontSize={this.scaledFontsize()}
        fontFamily={fontFamily}
        fill={fill}
        lineHeight={1}
        padding={padding}
        fontStyle={fontStyle}
        width={width}
        align={fontAlignment}
      />
    )
  }
}
