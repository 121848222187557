import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import FieldsGroup from 'src/models/form/FieldsGroup'
import Field from 'src/models/form/Field'
import TagsInput from 'src/components/shared/formComponents/TagsInput'
import { selectedFields, helpers } from 'src/utils/helpers'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import TagsSelect from 'src/components/shared/formComponents/TagsSelect'

type SubscriptionSelectFormProps = {
  rQuery: string[]
  setData: (form: FieldsGroup) => void
  setNewUrl: () => void
  talentAlertStore: ITalentAlertStore
  rootStore: Record<string, any>
}

@inject('rootStore', 'talentAlertStore')
@observer
class SubscriptionSelectForm extends Component<SubscriptionSelectFormProps> {
  form: FieldsGroup

  constructor(props: SubscriptionSelectFormProps) {
    super(props)

    this.form = new FieldsGroup({
      keywords: new Field({
        name: 'keywords',
        value: ''
      }),

      location: new Field({
        name: 'location',
        value: ''
      }),

      worktype: new Field({
        name: 'worktype',
        value: ''
      }),

      skill: new Field({
        name: 'skill',
        value: ''
      })
    })

    this.state = {
      showCurrencyErrorModal: false,
      errorField: null
    }
  }

  setFormData = (props: SubscriptionSelectFormProps, key: string) => {
    const { setData, rQuery, talentAlertStore } = props

    let newKeyWords: Array<any> = []

    if (rQuery && Array.isArray(rQuery) && rQuery.length > 0) {
      newKeyWords = [...newKeyWords, ...rQuery]
    }

    let data = []

    switch (key) {
      case 'keywords':
        data = newKeyWords
        break
      case 'location':
        data = selectedFields(talentAlertStore.locations)
        break
      case 'worktype':
        data = selectedFields(talentAlertStore.worktypes)
        break
      case 'skill':
        data = selectedFields(talentAlertStore.skills)
        break
      default:
        break
    }

    // @ts-ignore mismatched types but it works
    this.form.fields[key].update(data)

    setData(this.form)
  }

  onChangeFilter = () => {
    const { setNewUrl } = this.props

    setNewUrl()
  }

  componentDidUpdate() {
    Object.keys(this.form.fields).forEach((key) => {
      this.setFormData(this.props, key)
    })
  }

  render() {
    const { rootStore, talentAlertStore } = this.props

    return (
      <>
        <div className="m_top_2x">
          <TagsInput
            label="Keywords"
            // @ts-ignore mismatched types but it works
            fieldData={this.form.fields.keywords}
            creatable
            searchable
            placeholder="Select your Keywords"
            inBetweenValues="AND"
            cb={this.onChangeFilter}
          />

          <div className="separtor-section">
            <hr className="left-line" />
            <label> AND </label>
            <hr className="right-line" />
          </div>

          <div className="m_top_2x">
            <TagsSelect
              // @ts-ignore mismatched types but it works
              fieldData={this.form.fields.location}
              // @ts-ignore mismatched types but it works
              creatable
              rootStore={rootStore}
              searchable
              dataList={talentAlertStore.locations}
              label="Location"
              valueProcessing={helpers.removeSymbols}
              optionValueKey="name"
              inBetweenValues="OR"
              cb={this.onChangeFilter}
            />
          </div>

          <div className="separtor-section">
            <hr className="left-line" />
            <label> AND </label>
            <hr className="right-line" />
          </div>
          <div className="m_top_2x">
            <TagsSelect
              // @ts-ignore mismatched types but it works
              fieldData={this.form.fields.worktype}
              // @ts-ignore mismatched types but it works
              creatable
              rootStore={rootStore}
              searchable
              dataList={talentAlertStore.worktypes}
              label="Work type"
              valueProcessing={helpers.removeSymbols}
              optionValueKey="name"
              inBetweenValues="OR"
              cb={this.onChangeFilter}
            />
          </div>

          <div className="separtor-section">
            <hr className="left-line" />
            <label> AND </label>
            <hr className="right-line" />
          </div>
          <div className="m_top_2x">
            <TagsSelect
              // @ts-ignore mismatched types but it works
              fieldData={this.form.fields.skill}
              // @ts-ignore mismatched types but it works
              creatable
              rootStore={rootStore}
              searchable
              dataList={talentAlertStore.skills}
              label="Skill"
              valueProcessing={helpers.removeSymbols}
              optionValueKey="name"
              inBetweenValues="OR"
              cb={this.onChangeFilter}
            />
          </div>
        </div>
      </>
    )
  }
}

export default SubscriptionSelectForm
