/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from './CloseModalButton'
import RaisedButton from '../common/RaisedButton'
import Modal from './Modal'

@observer
class CharacterModal extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      isOpenCharacterModal: PropTypes.bool,
      angleBracketsWords: PropTypes.array
    })
  }

  closeModal = () => {
    const {
      rootStore: { toggleCharacterModal, setData }
    } = this.props
    setData('angleBracketsWords', [])
    toggleCharacterModal()
  }

  render() {
    const {
      rootStore: { isOpenCharacterModal, angleBracketsWords },
      theme
    } = this.props
    const style = {
      title: { color: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={isOpenCharacterModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Alert!
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 400,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <p className="refari-message-template-modal--content">
              You currently have{' '}
              {angleBracketsWords
                .join(', ')}{' '}
              included in your message, these can't be sent in manual Job
              Alerts, please remove the &lt; &gt; and try again.
            </p>
            <RaisedButton
              color="primary"
              className="refari-button-raised"
              style={{ width: 'auto' }}
              onClick={this.closeModal}
            >
              Ok
            </RaisedButton>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(CharacterModal)
