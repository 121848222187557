import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

@observer
class Avatar extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  }

  renderAlt = () => (
    <img
      className="refari-alternative-avatar"
      src={this.props.altsrc}
      alt={this.props.alt}
      ref={(mynode) => {
        if (mynode) {
          mynode.style.setProperty(
            'border-radius',
            this.props.style && this.props.style.borderRadius,
            'important'
          )
        }
      }}
    />
  )

  render() {
    return (
      <div
        style={this.props.style}
        className={`refari-avatar-wrap ${this.props.className || ''}`}
      >
        {this.props.altsrc && this.renderAlt()}
        <img
          className={this.props.altsrc ? 'refari-main-avatar' : ''}
          src={
            this.props.src
            || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAACMCAYAAACuwEE+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gIPCS4p85/50AAAAuVJREFUeNrt201OW1cYBuDvOL5YTGJ2UBiUTt0hA6izgtIV1DuIl5DswN2Bd8ASgqASQzvDkIHpDmIyQOaSnAyME2gNcaWCi+/zjPxzjo509eo7Pz6OAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADg8aSqP4A3o7xRL8dvIlLr/pZ5eFU0X7zYSh8ERlhai/UQmiQsi4ZFaCoRmOPTcS/n/GtKafMhx8k5n6Va6u/+2HwtME84LBHx8lEHzfF696fmq1V9prVVDkyO3FnCmL+v8jNd6cCkSM1HH/OBp75lqztZuFUdxpHTYYoYTt9HK1JuLyN4AvN/D0rUunvbzf68749OzzsRn3uCs+JT0oLbm7dF0WjtbT/v39Vkb/t5vygarcj5rQpT8cpSrDX2d7bWzyIiTkYXm2V5+TJFbk2/T8OiWPtjZ2v9bGdr/exkdLFflpNhlStNxStMrTsLy/H7j+2ynAxSRDcitSNSO0V0y3IyOH7/sR0RMW1b65qSKlpdZtPQyehiM+dPBynSxpyd1kbOnw5ORhebs+lpuuYRmIolJh3OXl6Vl915YbkZmqvysjuvr8BUxGzrfJ2ABX5P+tbmdl+BAYH55xomblSVtEDF+Nbmdl+BqciclH/5erZQrPXuW8jmyON6sda70bctMJVbw6SN6QnudLuc0rP9eaHJkccpPft6VnN0et6p8jnMql9vyN/ZWn8oisbPNw/upruh2QI3DevFWu/2wd5kcN+OKiJid7uZBGYFA3Mdm2G9aPw2C8VdpmGaHCxyQ2+VA2OXFKlVlpPBn+/O77zHcnR63inLyeDfX+dUYVawwtyeolKOw6hd74g+51ZO0f7eFFSlCuN6w98WwpFiP3LsTz9I/odTpSlpKb/55PyXwDzVipFTbwmj9q1hnvI65t34VUTuREo/PHxlSf1V/scAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMB/7Ast1wxq3rDX/QAAAABJRU5ErkJggg=='
          }
          alt={this.props.alt}
          ref={(node) => {
            if (node) {
              node.style.setProperty(
                'border-radius',
                this.props.style && this.props.style.borderRadius,
                'important'
              )
            }
          }}
        />
      </div>
    )
  }
}

export default Avatar
