import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Stepper, Step, StepContent, StepLabel, Chip } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { action, observable } from 'mobx'
import { v1 as uuidv1 } from 'uuid'
import classnames from 'classnames'
import qp from 'query-parse'
import type { History } from 'history'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import RaisedButton from 'src/components/shared/common/RaisedButton'
import SubscriptionSelectForm from './SubscriptionSelectForm'
import Suggest from './Suggest'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import {
  selectedFieldsById,
  selectedFields,
  generateLink,
  selectedFieldsGetIDs
} from 'src/utils/helpers'
import alertMessages from 'src/constants/alertMessages'

type TalentAlertFormProps = {
  location: History['location']
  theme: MUIThemeFuncReturnType
  rootStore: Record<string, any>
  talentAlertStore: ITalentAlertStore
  agenciesStore: Record<string, any>
  email: string
  editSuggestionId: string
  keywords?: string
}

type TalentAlertFormState = {
  stepIndex: number
  suggestionErrorModal: string
}

@inject('rootStore', 'talentAlertStore', 'agenciesStore')
@observer
class TalentAlertForm extends Component<
  TalentAlertFormProps,
  TalentAlertFormState
> {
  @observable
  copyUrl = ''
  @observable
  formDatas: Record<string, any> | null = null
  queryParam: Record<string, any> = {}
  editSuggestionId?: number

  constructor(props: TalentAlertFormProps) {
    super(props)

    const { location } = props

    this.saveUrl()

    const queryData = qp.toObject(location?.search.slice(1)) ?? {}

    this.editSuggestionId = Number(props.editSuggestionId)

    this.queryParam = {
      rQuery: queryData?.rQuery?.split(',') || props.keywords?.split('|') || [],
      step: Number(queryData?.talentAlertStep) || 0,
      locationsList: queryData.location?.split(',') || [],
      talentAlertLocation: queryData?.talent_alert_location?.split(',') || [],
      workTypeList: queryData.worktype?.split(',') || [],
      talentAlertWorkType: queryData?.talent_alert_worktype?.split(',') || [],
      skillList: queryData.skill?.split(',') || [],
      talentAlertSkill: queryData?.talent_alert_skill?.split(',') || []
    }

    console.log('this.queryParam.rQuery', this.queryParam.rQuery)

    if (this.editSuggestionId) {
      this.setSuggestion(props)
    }

    this.state = {
      stepIndex: this.queryParam.step === 0 ? 0 : 1,
      suggestionErrorModal: ''
    }
  }

  setSuggestion = (props: TalentAlertFormProps) => {
    const { talentAlertStore } = props

    talentAlertStore
      .setSuggestion(Number(this.editSuggestionId))
      .then((res) => {
        this.queryParam.talentAlertLocation = res?.locations || []
        this.queryParam.talentAlertWorkType = res?.worktypes || []
        this.queryParam.talentAlertSkill = res?.skills || []
        this.queryParam.rQuery = res?.keywords || []

        talentAlertStore.fetchSelectedLocations(
          this.queryParam.locationsList,
          this.queryParam.talentAlertLocation
        )

        talentAlertStore.fetchSelectedWorktypes(
          this.queryParam.workTypeList,
          this.queryParam.talentAlertWorkType
        )

        talentAlertStore.fetchSelectedSkills(
          this.queryParam.skillList,
          this.queryParam.talentAlertSkill
        )
      })
      .catch((err) => {
        this.setState({
          suggestionErrorModal: 'Talent alert suggestion Not Found.'
        })
        this.editSuggestionId = undefined

        console.log('err', err)
      })
  }

  stepActiveStyle = (step: number) => {
    const { stepIndex } = this.state
    const { theme } = this.props

    const isActive = step <= stepIndex

    return {
      color: isActive ? theme.palette.textColor : 'currentColor'
    }
  }

  setFormData = () => {
    const { talentAlertStore } = this.props

    return [
      this.queryParam.rQuery,
      selectedFields(talentAlertStore.locations),
      selectedFields(talentAlertStore.worktypes),
      selectedFields(talentAlertStore.skills)
    ]
  }

  @action
  setData = (data: Record<string, any>) => {
    this.formDatas = data

    this.saveUrl()
  }

  getSearchList = () => {
    const { stepIndex } = this.state

    if (stepIndex && !this.formDatas) {
      // eslint-disable-next-line array-callback-return
      return Object.values(this.setFormData()).reduce((prev, next) => {
        if (Array.isArray(prev)) {
          return prev.concat(next)
        }
      }, [])
    }
    if (!this.formDatas) {
      return []
    }

    const formDataJSON = this.formDatas?.data
    // eslint-disable-next-line array-callback-return
    const data = formDataJSON ? { ...formDataJSON } : {}

    const list = Object.values(data).reduce((prev, next) => {
      if (typeof next === 'undefined' || next === '') return prev
      if (typeof next === 'number') return prev
      if (Array.isArray(prev)) {
        return prev.concat(next)
      }
    }, [])

    return list
  }

  setPreviousStep = () => {
    const { stepIndex } = this.state
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 })
    }
  }

  onAcceptSuggestion = async () => {
    const { talentAlertStore } = this.props
    const queryData = this.setQuerySortData() as Record<string, any>
    queryData.skill = selectedFieldsGetIDs(talentAlertStore.skills)

    queryData.location = selectedFieldsById(talentAlertStore.locations)
    queryData.worktype = selectedFieldsById(talentAlertStore.worktypes)

    await talentAlertStore.acceptSuggestion(
      this.editSuggestionId || 0,
      queryData
    )

    talentAlertStore.skills.forEach((el) => el.reset())
    talentAlertStore.locations.forEach((el) => el.reset())
    talentAlertStore.worktypes.forEach((el) => el.reset())

    const url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage(
      'SHOW-SUCCESS-TALENT-ALERT-SUGGESTION-ACCEPT',
      url
    )
  }

  onClickNextStep = () => {
    const { stepIndex } = this.state

    this.setState({ stepIndex: stepIndex + 1 })
    this.queryParam.rQuery = [...this.formDatas?.data.keywords]
  }

  @action
  saveUrl = () => {
    if (this.formDatas) {
      const res = generateLink(
        '#list',
        this.setQuerySortData(),
        this.props.rootStore.agency.talentBoardBaseUrl
      )
      const data = res.replace('query', 'rQuery')
      this.copyUrl = `${data.replace('#list', '&TalentAlert1=#list')}`
    }
  }

  setQuerySortData = () => {
    const { talentAlertStore } = this.props

    const params: {
      query?: string
      talent_alert_location?: Array<string>
      talent_alert_worktype?: Array<string>
      talent_alert_skill?: Array<string>
    } = {}

    if (this.formDatas) {
      Object.keys(this.formDatas.fields).forEach((key) => {
        if (key === 'keywords') {
          if (
            this.formDatas?.data.keywords &&
            this.formDatas?.data.keywords.length
          ) {
            params.query = this.formDatas.data.keywords.join(',')
          }
        } else {
          switch (key) {
            case 'location':
              // params.location = selectedFieldsById(edmStore.locationsList)
              params.talent_alert_location = selectedFieldsById(
                talentAlertStore.locations
              )
              break

            case 'worktype':
              // params.worktype = selectedFieldsById(edmStore.worktypesList)
              params.talent_alert_worktype = selectedFieldsById(
                talentAlertStore.worktypes
              )
              break

            case 'skill':
              // params.skill = selectedFieldsById(edmStore.skillsList)
              params.talent_alert_skill = selectedFieldsById(
                talentAlertStore.skills
              )
              break
          }
        }
      })
    }
    return params
  }

  suggest = async (form: Record<string, any>) => {
    const { rootStore, talentAlertStore } = this.props
    const { loggedIn, user } = rootStore
    const queryData: Record<string, any> = this.setQuerySortData()

    queryData.location = selectedFieldsById(talentAlertStore.locations)
    queryData.worktype = selectedFieldsById(talentAlertStore.worktypes)
    queryData.skill = selectedFieldsById(talentAlertStore.skills)

    if (loggedIn && !user.isHiringManager) {
      await talentAlertStore.suggest(form, queryData)
    } else {
      await talentAlertStore.subscribe(form, queryData)
    }

    talentAlertStore.locations.forEach((el) => el.reset())
    talentAlertStore.worktypes.forEach((el) => el.reset())
    talentAlertStore.skills.forEach((el) => el.reset())

    if (loggedIn && !user.isHiringManager) {
      this.setPreviousStep()
    } else {
      this.setPreviousStep()
    }
  }

  fetchData = () => {
    const { talentAlertStore, rootStore, agenciesStore } = this.props

    if (
      !talentAlertStore.locations.length &&
      !talentAlertStore.worktypes.length &&
      !talentAlertStore.skills.length &&
      rootStore.agency &&
      rootStore.agency.id
    ) {
      if (!agenciesStore.agencyKey) {
        agenciesStore.fetchApiKey(() => {
          if (this.editSuggestionId) return

          if (
            rootStore.agency &&
            rootStore.agency.id &&
            agenciesStore.agencyKey
          ) {
            this.queryParam.locationsList.length > 0 ||
            this.queryParam.talentAlertLocation.length > 0
              ? talentAlertStore.fetchSelectedLocations(
                  this.queryParam.locationsList,
                  this.queryParam.talentAlertLocation
                )
              : talentAlertStore.fetchLocations()

            this.queryParam.workTypeList.length > 0 ||
            this.queryParam.talentAlertWorkType.length > 0
              ? talentAlertStore.fetchSelectedWorktypes(
                  this.queryParam.workTypeList,
                  this.queryParam.talentAlertWorkType
                )
              : talentAlertStore.fetchWorktypes()

            this.queryParam.skillList.length > 0 ||
            this.queryParam.talentAlertSkill.length > 0
              ? talentAlertStore.fetchSelectedSkills(
                  this.queryParam.skillList,
                  this.queryParam.talentAlertSkill
                )
              : talentAlertStore.fetchSkills()
          }
        })
      } else {
        if (this.editSuggestionId) return

        this.queryParam.locationsList.length > 0 ||
        this.queryParam.talentAlertLocation.length > 0
          ? talentAlertStore.fetchSelectedLocations(
              this.queryParam.locationsList,
              this.queryParam.talentAlertLocation
            )
          : talentAlertStore.fetchLocations()

        this.queryParam.workTypeList.length > 0 ||
        this.queryParam.talentAlertWorkType.length > 0
          ? talentAlertStore.fetchSelectedWorktypes(
              this.queryParam.workTypeList,
              this.queryParam.talentAlertWorkType
            )
          : talentAlertStore.fetchWorktypes()

        this.queryParam.skillList.length > 0 ||
        this.queryParam.talentAlertSkill.length > 0
          ? talentAlertStore.fetchSelectedSkills(
              this.queryParam.skillList,
              this.queryParam.talentAlertSkill
            )
          : talentAlertStore.fetchSkills()
      }
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    this.fetchData()
  }

  componentWillUnmount() {
    const { talentAlertStore } = this.props

    talentAlertStore.locations.forEach((el) => el.reset())
    talentAlertStore.worktypes.forEach((el) => el.reset())
    talentAlertStore.skills.forEach((el) => el.reset())
  }

  closeSuggestionErrorModal = () => {
    const url: string =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage('OPEN-TALENT-BOARD', url)
    this.setState({ suggestionErrorModal: '' })
  }

  isAgencyUser = () => {
    const { rootStore } = this.props
    const { loggedIn } = rootStore

    return loggedIn && !rootStore.user.isHiringManager
  }

  handleCopy = () => {
    toast.success(alertMessages.subscription)
  }

  subscriptionContent = () => {
    if (document.body.clientWidth > 768) {
      return 'subscription link'
    }
    return 'copy link'
  }

  previewEmail = (form: Record<string, any>) => {
    const { talentAlertStore } = this.props

    const queryData: Record<string, any> = this.setQuerySortData()

    queryData.skill = selectedFieldsGetIDs(talentAlertStore.skills)
    queryData.location = selectedFieldsById(talentAlertStore.locations)
    queryData.worktype = selectedFieldsById(talentAlertStore.worktypes)

    talentAlertStore.previewEmail(form, queryData)
  }

  render() {
    const { theme, email } = this.props
    const { stepIndex } = this.state
    const rQuery = this.queryParam?.rQuery

    const style = {
      StepLabel: {
        fontSize: '16px',
        fontWeight: 600,
        paddingLeft: 2
      },
      StepLabel2: {
        marginBottom: 24,
        paddingLeft: 2
      }
    }

    return (
      <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
        <div className="refari-candidate_subscription-section">
          <Stepper activeStep={stepIndex} orientation="vertical">
            <Step key="step-1">
              <StepLabel
                style={{
                  ...style.StepLabel,
                  ...this.stepActiveStyle(0)
                }}
              >
                Talent alert parameters
              </StepLabel>
              {(this.getSearchList() as Array<Record<string, any>>).length >
                0 && (
                <div
                  className={classnames('refari-additional-info', {
                    'refari-additional-info__show': stepIndex
                  })}
                  style={
                    stepIndex
                      ? { margin: '10px 13px 0px', minHeight: '22px' }
                      : {}
                  }
                >
                  {(this.getSearchList() as Array<Record<string, any>>).map(
                    (el) => (
                      <Chip
                        key={uuidv1()}
                        style={{
                          marginRight: 5,
                          marginBottom: 5,
                          cursor: 'pointer',
                          color: '#fff',
                          backgroundColor: theme.palette.primary1Color
                        }}
                        onClick={this.setPreviousStep}
                        // @ts-ignore mis-matched types but works
                        label={el}
                      />
                    )
                  )}
                </div>
              )}
              <StepContent
                className="refari-step-content refari-subscribe-tab-content"
                transitionDuration={0}
                style={{ border: 'none' }}
              >
                {/* @ts-ignore store props are injected */}
                <SubscriptionSelectForm
                  setData={this.setData}
                  setNewUrl={this.saveUrl}
                  rQuery={rQuery}
                />
                <div className="refari-w-row refari-justify-start refari-align-items-center m_top_2x">
                  {this.editSuggestionId ? (
                    <RaisedButton
                      style={{
                        marginRight: '15px',
                        backgroundColor: theme.palette.primary1Color,
                        color: '#fff'
                      }}
                      onClick={this.onAcceptSuggestion}
                      className="refari-button-raised"
                    >
                      Accept
                    </RaisedButton>
                  ) : (
                    <RaisedButton
                      style={{
                        marginRight: '15px',
                        backgroundColor: theme.palette.primary1Color,
                        color: '#fff'
                      }}
                      onClick={this.onClickNextStep}
                      className="refari-button-raised"
                    >
                      Next
                    </RaisedButton>
                  )}
                  {this.isAgencyUser() && !this.editSuggestionId && (
                    <CopyToClipboard
                      text={this.copyUrl}
                      onCopy={this.handleCopy}
                    >
                      <RaisedButton
                        style={{
                          backgroundColor: theme.palette.primary1Color,
                          color: '#fff'
                        }}
                        className="refari-button-raised"
                      >
                        {this.subscriptionContent()}
                      </RaisedButton>
                    </CopyToClipboard>
                  )}
                </div>
              </StepContent>
            </Step>
            {!this.editSuggestionId && (
              <Step style={{ marginTop: 0 }} key="step-2">
                <StepLabel
                  style={{
                    ...style.StepLabel,
                    ...style.StepLabel2,
                    ...this.stepActiveStyle(1)
                  }}
                >
                  General details
                </StepLabel>
                <StepContent
                  className="refari-step-content"
                  transitionDuration={0}
                >
                  {/* @ts-ignore store props are injected */}
                  <Suggest
                    createSubscription
                    theme={theme}
                    setPreviousStep={this.setPreviousStep}
                    suggest={this.suggest}
                    text={this.copyUrl}
                    subscriptionContent={this.subscriptionContent}
                    previewEmail={this.previewEmail}
                    email={email}
                  />
                </StepContent>
              </Step>
            )}
          </Stepper>
        </div>
      </div>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TalentAlertForm)
