/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const MoveIcon = (props) => {
  const style = {
    width: '24px',
    height: '24px',
    marginTop: 5,
    ...props.style
  }
  return (
    <SvgIcon {...props} style={style} viewBox="0 0 59.999 59.999">
      <g>
        <path
          d="M19.999,11.999c0.256,0,0.512-0.098,0.707-0.293l8.293-8.293v18.586c0,0.552,0.447,1,1,1s1-0.448,1-1V3.413l8.293,8.293
          c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414l-9.999-9.999
          c-0.092-0.093-0.203-0.166-0.326-0.217c-0.244-0.101-0.52-0.101-0.764,0c-0.123,0.051-0.233,0.124-0.326,0.217l-9.999,9.999
          c-0.391,0.391-0.391,1.023,0,1.414C19.488,11.901,19.743,11.999,19.999,11.999z"
          fill={props.fill}
        />
        <path
          d="M39.292,48.292l-8.293,8.293V37.999c0-0.552-0.447-1-1-1s-1,0.448-1,1v18.586l-8.293-8.293
          c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l9.999,9.999c0.092,0.093,0.203,0.166,0.326,0.217
          c0.122,0.051,0.252,0.077,0.382,0.077s0.26-0.027,0.382-0.077c0.123-0.051,0.233-0.124,0.326-0.217l9.999-9.999
          c0.391-0.391,0.391-1.023,0-1.414S39.683,47.901,39.292,48.292z"
          fill={props.fill}
        />
        <path
          d="M59.922,30.381c0.101-0.244,0.101-0.52,0-0.764c-0.051-0.123-0.124-0.233-0.217-0.326l-9.999-9.999
          c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l8.293,8.293H37.999c-0.553,0-1,0.448-1,1s0.447,1,1,1h18.586l-8.293,8.293
          c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l9.999-9.999
          C59.798,30.614,59.872,30.503,59.922,30.381z"
          fill={props.fill}
        />
        <path
          d="M3.413,30.999h18.586c0.553,0,1-0.448,1-1s-0.447-1-1-1H3.413l8.293-8.293c0.391-0.391,0.391-1.023,0-1.414
          s-1.023-0.391-1.414,0l-9.999,9.999c-0.093,0.092-0.166,0.203-0.217,0.326c-0.101,0.244-0.101,0.52,0,0.764
          c0.051,0.123,0.124,0.233,0.217,0.326l9.999,9.999c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
          c0.391-0.391,0.391-1.023,0-1.414L3.413,30.999z"
          fill={props.fill}
        />
      </g>
    </SvgIcon>
  )
}

export default MoveIcon

MoveIcon.defaultProps = {
  fill: '#000'
}
