import React from 'react'
import qp from 'query-parse'
import { Button, Paper } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import illustration_thanks_for_rating from '../../../assets/images/illustration_thanks_for_rating.svg'

class ThankForRating extends React.Component {
  constructor(props) {
    super(props)
    this.setQuery(props.location.search.slice(1))
  }

  setQuery = (data) => {
    const newData = qp.toObject(data)
    this.query = {
      redirect: newData.redirect || newData['amp;redirect'],
      agencyname: newData.agencyname || newData['amp;agencyname'],
    }
  }

  render() {
    return (
      <div className="flex-center full-width">
        <Paper className={this.query.redirect ? 'testimonials-feedback testimonials-feedback__thank-modal testimonials-feedback__button' : 'testimonials-feedback testimonials-feedback__thank-modal'}>
          <img src={illustration_thanks_for_rating} alt="" />
          <p
            className="testimonials-feedback--header-content m-t-3"
            style={{ color: this.props.theme.palette.primary1Color }}
          >
            Thank you for the rating!
          </p>
          <p className="testimonials-feedback--content-text m-t-1">
            Every contribution helps us shape the future of recruitment!
          </p>
          {
            this.query.redirect
            && <Button
              variant="contained"
              className="m-t-1"
              href={this.query.redirect}
              style={{ color: this.props.theme.palette.primary1Color }}
            >
              {`Back to ${this.query.agencyname || 'Recruitment Agency'}`}
            </Button>
          }
        </Paper>
      </div>
    )
  }
}

export default withTheme(ThankForRating)