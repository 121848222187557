import React from 'react'
import Airplane from '../components/shared/icons/edm/airplane'
import BarChart from '../components/shared/icons/edm/barChart'
import Car from '../components/shared/icons/edm/car'
import Career from '../components/shared/icons/edm/career'
import Cashier from '../components/shared/icons/edm/cashier'
import Certificate from '../components/shared/icons/edm/certificate'
import Chimney from '../components/shared/icons/edm/chimney'
import Cloud from '../components/shared/icons/edm/cloud'
import Favorites from '../components/shared/icons/edm/favorites'
import Heart from '../components/shared/icons/edm/heart'
import Idea from '../components/shared/icons/edm/idea'
import Laptop from '../components/shared/icons/edm/laptop'
import Loupe from '../components/shared/icons/edm/loupe'
import Plan from '../components/shared/icons/edm/plan'
import Portfolio from '../components/shared/icons/edm/portfolio'
import Presentation from '../components/shared/icons/edm/presentation'
import Recruitment from '../components/shared/icons/edm/recruitment'
import Remote from '../components/shared/icons/edm/remote'
import Scketching from '../components/shared/icons/edm/scketching'
import Settings from '../components/shared/icons/edm/settings'
import Skyscrapper from '../components/shared/icons/edm/skyscrapper'
import Stethoscope from '../components/shared/icons/edm/stethoscope'
import ToolsAndUtensils from '../components/shared/icons/edm/toolsAndUtensils'
import Trophy from '../components/shared/icons/edm/trophy'
import Workers from '../components/shared/icons/edm/workers'
import Workspace from '../components/shared/icons/edm/workspace'
import { baseURLs } from '../utils/Urls'

export const edmAlert = [
  {
    id: 1,
    icon: (fill) => <Airplane fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/airplane.png`
  },
  {
    id: 2,
    icon: (fill) => <BarChart fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/bar-chart.png`
  },
  {
    id: 3,
    icon: (fill) => <Car fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/car.png`
  },
  {
    id: 4,
    icon: (fill) => <Career fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/career.png`
  },
  {
    id: 5,
    icon: (fill) => <Cashier fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/cashier.png`
  },
  {
    id: 6,
    icon: (fill) => <Certificate fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/certificate.png`
  },
  {
    id: 7,
    icon: (fill) => <Chimney fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/chimney.png`
  },
  {
    id: 8,
    icon: (fill) => <Cloud fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/cloud.png`
  },
  {
    id: 9,
    icon: (fill) => <Favorites fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/favorites.png`
  },
  {
    id: 10,
    icon: (fill) => <Heart fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/heart.png`
  },
  {
    id: 11,
    icon: (fill) => <Idea fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/idea.png`
  },
  {
    id: 12,
    icon: (fill) => <Laptop fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/laptop.png`
  },
  {
    id: 13,
    icon: (fill) => <Loupe fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/loupe.png`
  },
  {
    id: 14,
    icon: (fill) => <Plan fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/plan.png`
  },
  {
    id: 15,
    icon: (fill) => <Portfolio fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/plan.png`
  },
  {
    id: 16,
    icon: (fill) => <Presentation fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/presentation.png`
  },
  {
    id: 17,
    icon: (fill) => <Recruitment fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/recruitment.png`
  },
  {
    id: 18,
    icon: (fill) => <Remote fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/remote.png`
  },
  {
    id: 19,
    icon: (fill) => <Scketching fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/scketching.png`
  },
  {
    id: 20,
    icon: (fill) => <Settings fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/settings.png`
  },
  {
    id: 21,
    icon: (fill) => <Skyscrapper fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/skyscrapper.png`
  },
  {
    id: 22,
    icon: (fill) => <Stethoscope fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/stethoscope.png`
  },
  {
    id: 23,
    icon: (fill) => <ToolsAndUtensils fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/tools-and-utensils.png`
  },
  {
    id: 24,
    icon: (fill) => <Trophy fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/trophy.png`
  },
  {
    id: 25,
    icon: (fill) => <Workers fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/workers.png`
  },
  {
    id: 26,
    icon: (fill) => <Workspace fill={fill} />,
    link: `${baseURLs('frontend')}static/edm/workspace.png`
  }
]
