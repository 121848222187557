import moment, { Moment } from 'moment'

function getTimeZoneLocaleString() {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale
  return new Date()
    .toLocaleDateString(locale, {
      day: '2-digit',
      timeZoneName: 'long'
    })
    .slice(4)
}

function isValidFormatString(format: string) {
  return moment(new Date(), format, true).isValid()
}

function getFormattedDateTime(time: Moment, format: string) {
  if (isValidFormatString(format)) {
    return time.format(format)
  } else {
    throw new Error('format string is not correct')
  }
}

function isTimeInPast(time: Moment) {
  const today = moment(new Date())
  const minutes = today.diff(time, 'minutes')
  //   console.log('minutes', minutes)
  if (minutes > 0) return true
  return false
}

function isDateToday(time: Moment) {
  const today = moment(new Date())
  return today.isSame(time, 'date')
}

function isDateInPast(time: Moment) {
  const today = moment(new Date())
  if (today.isSame(time, 'date')) return false
  return today.diff(time) > 0
}

function fromLocalTimeToUTC(time: Moment) {
  if (time.isUTC()) {
    return time
  }
  return moment.utc(time)
}

function fromUTCToLocalTime(time: Moment) {
  if (time.isUTC()) {
    /**
     * @todo
     * convert the time into utc
     */
  }
  return time
}

export {
  getTimeZoneLocaleString,
  getFormattedDateTime,
  isTimeInPast,
  isDateInPast,
  isDateToday,
  fromLocalTimeToUTC,
  fromUTCToLocalTime
}
