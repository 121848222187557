/* eslint-disable react/state-in-constructor */
/* eslint-disable max-len */
import React from 'react'
import { Button, CircularProgress, TextField, Paper } from '@material-ui/core'
import qp from 'query-parse'
import hexToRgba from 'hex-to-rgba'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import isEmpty from 'lodash.isempty'

import lamp from '../../../assets/images/lamp.png'
import Rating from '../../shared/formComponents/RatingComponent'
import URLs from '../../../router/routesUrls'
import Field from 'src/models/form/Field'
import TestimonialConsultant from './TestimonialConsultant'

@inject('testimonialsStore')
@observer
class Testimonials extends React.PureComponent {
  constructor(props) {
    super(props)

    this.consultantSelectionField = new Field({
      name: 'consultant',
      value: ''
    })

    this.setQuery(props.location.search.slice(1))

    this.nextStep = this.nextStep.bind(this)

    if (isEmpty(props.testimonialsStore.consultantInfo)) {
      props.testimonialsStore.fetchTestimonials(this.query)
    }
  }

  state = {
    comment: '',
    rating: 0,
    error: ''
  }

  setQuery = (data) => {
    const newData = qp.toObject(data)
    this.query = {
      rating: newData.rating || newData['amp;rating'],
      testimonial_type:
        newData.testimonial_type || newData['amp;testimonial_type'],
      uid: newData.uid
    }
  }

  componentDidMount() {
    this.onChange(+this.query.rating)
  }

  submit = () => {
    const { comment, rating } = this.state
    const { testimonialsStore } = this.props

    const data = {
      uid: this.query.uid,
      rating,
      comment,
      testimonial_type: this.query.testimonial_type,
      consultant: this.consultantSelectionField.value
    }

    testimonialsStore.setData('comment', comment)
    testimonialsStore.setData('consultantResponse', data)
    testimonialsStore.setTestimonials(data, this.nextStep)
  }

  onChange = (val) => {
    this.setState({ rating: val })
    this.query.rating = val
  }

  handleChange = (e) => {
    this.setState({ comment: e.target.value, error: '' })
    if (e.target.value.length === 100) {
      this.setState({ error: 'Max width should be less then 100 symbols' })
    }
  }

  showHeaderContent = (val, info) => {
    switch (val) {
      case 'app_interviewed_internal':
        return (
          <>
            <span>Did we explain the role well?</span>
            <span>Did we explain the culture well?</span>
          </>
        )
      case 'app_interviewed_external':
        return (
          <>
            <span>Prompt and accurate communication?</span>
            <span>Good interview preparation?</span>
            <span>Easy to deal with?</span>
          </>
        )
      case 'app_placed':
        return `We are just checking in to find out how ${info.consultant.first_name} ${info.consultant.last_name} performed in this process!?`
      default:
        return (
          <>
            <span>Did we explain the role well?</span>
            <span>Did we explain the culture well?</span>
          </>
        )
    }
  }

  nextStep() {
    if (this.query.testimonial_type === 'app_placed') {
      this.props.history.push(URLs.testimonials.children.addTestimonials)
    } else {
      const {
        testimonialsStore: { consultantInfo },
        history
      } = this.props
      history.push(
        `${URLs.testimonials.children.thank}?redirect=${encodeURIComponent(
          consultantInfo.agency.widget_base_url
        )}&agencyname=${consultantInfo.agency.name}`
      )
    }
  }

  handleReviewUrlRedirect = (url, social) => {
    let newUrl = ''

    if (social === 'linkedin') {
      if (url.includes('/review') || url.includes('/review/')) {
        newUrl = url.replace('review', 'detail/recommendation/write')
      } else if (url.includes('/write') || url.includes('/write/')) {
        newUrl = url
      } else {
        newUrl = url.endsWith('/')
          ? `${url}detail/recommendation/write/`
          : `${url}/detail/recommendation/write/`
      }
    } else if (url.includes('/review') || url.includes('/review/')) {
      newUrl = url
    } else {
      newUrl = url.endsWith('/') ? `${url}review/` : `${url}/review/`
    }

    window.open(newUrl, '_self')
  }

  render() {
    const { theme, testimonialsStore, history } = this.props

    const { consultantInfo, fetchDate, redirect, errors } = testimonialsStore

    if (!fetchDate) {
      return (
        <div className="page-preload-spinner center-xs align-items-center full-width">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    if (redirect) {
      if (errors === 'expired') {
        history.push(URLs.testimonials.children.testimonialsExpired)
      } else if (errors !== 'expired') {
        history.push(URLs.testimonials.children.testimonialsReviewed)
      }
    }

    if (!consultantInfo || (consultantInfo && !consultantInfo.consultant)) {
      return null
    }

    const reviewDirectDriveSocialNets =
      consultantInfo['review_direct_drive_social_net']
    const reviewUrls = consultantInfo['review_urls']

    if (
      reviewDirectDriveSocialNets &&
      reviewUrls[reviewDirectDriveSocialNets]
    ) {
      /**
       * redirect to review url
       */
      this.handleReviewUrlRedirect(
        reviewUrls[reviewDirectDriveSocialNets],
        reviewDirectDriveSocialNets
      )
    }

    return (
      <div className="flex-center-all full-width">
        <Paper className="testimonials-feedback">
          <div className="testimonials-feedback__header">
            <p
              className="testimonials-feedback--header-content"
              style={{ color: theme.palette.primary1Color }}
            >
              Your Feedback
            </p>
          </div>
          <div
            className="testimonials-feedback__content"
            style={{
              backgroundColor: hexToRgba(theme.palette.primary1Color, 0.1)
            }}
          >
            <img
              className="testimonials-feedback--content-lamp"
              src={lamp}
              alt="lamp"
            />
            <p
              className="testimonials-feedback--content testimonials-feedback--content-small"
              style={{ color: theme.palette.primary1Color }}
            >
              {this.showHeaderContent(
                this.query.testimonial_type,
                consultantInfo
              )}
            </p>

            <TestimonialConsultant
              testimonialsStore={testimonialsStore}
              selectionField={this.consultantSelectionField}
            />

            <div className="full-width pad-top-3x">
              <Rating value={this.state.rating} onChange={this.onChange} />
              {this.query.testimonial_type !== 'app_placed' && (
                <p className="testimonials-feedback--comments-description">
                  If you would like to add your comment, please share your
                  thoughts. All comments are reviewed by our management team :)
                </p>
              )}
              {this.query.testimonial_type !== 'app_placed' && (
                <TextField
                  placeholder={this.state.value}
                  label="Your comment"
                  onChange={this.handleChange}
                  defaultValue={consultantInfo.comment || ''}
                  className="align-left"
                  fullWidth
                  inputProps={{
                    maxLength: 100
                  }}
                  error={!!this.state.error}
                  helperText={this.state.error}
                />
              )}

              <Button
                variant="contained"
                type="button"
                onClick={this.submit}
                className="submit-btn"
                fullWidth
                color="primary"
              >
                Submit
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default withTheme(Testimonials)
