/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Drawer,
  List,
  ListItem,
  MenuItem,
  SvgIcon,
  ListSubheader,
  Select,
  Collapse,
  ListItemText,
  ListItemIcon
} from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'
import { withTheme } from '@material-ui/core/styles'
import {
  Settings,
  CreditCard,
  Menu,
  Widgets,
  Assessment,
  HeadsetMic,
  ExpandLess,
  ExpandMore,
  PhonelinkErase
} from '@material-ui/icons'
import classnames from 'classnames'
import hexToRgba from 'hex-to-rgba'
import { Link, matchPath } from 'react-router-dom'
import { baseURLs } from '../../../utils/Urls'
import { throttle } from '../../../utils/helpers'
import { defaultPalette } from '../../../utils/MUITheme'
import routesUrls from '../../../router/routesUrls'
import { headerDrawer } from '../../../utils/materialStyle/Header'
import ToolsIcon from '../icons/Tools'
import LogoIcon from '../icons/LogoIcon'
import DashboardIcon from '../DashboardIcon'
import HeaderSwitchMenu from './HeaderSwitchMenu'

function AchievmentsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.25,0.75 L12,0.75 L12,0 L3,0 L3,0.75 L0.75,0.75 C0.3,0.75 0,1.05 0,1.5 L0,3.3 C0,5.025 1.275,6.45 3,6.675 L3,6.75 C3,8.925 4.5,10.725 6.525,11.175 L6,12.75 L4.275,12.75 C3.975,12.75 3.675,12.975 3.6,13.275 L3,15 L12,15 L11.4,13.275 C11.325,12.975 11.025,12.75 10.725,12.75 L9,12.75 L8.475,11.175 C10.5,10.725 12,8.925 12,6.75 L12,6.675 C13.725,6.45 15,5.025 15,3.3 L15,1.5 C15,1.05 14.7,0.75 14.25,0.75 Z M3,5.175 C2.175,4.95 1.5,4.2 1.5,3.3 L1.5,2.25 L3,2.25 L3,5.175 Z M9,7.5 L7.5,6.675 L6,7.5 L6.375,6 L5.25,4.5 L6.825,4.5 L7.5,3 L8.175,4.5 L9.75,4.5 L8.625,6 L9,7.5 Z M13.5,3.3 C13.5,4.2 12.825,5.025 12,5.175 L12,2.25 L13.5,2.25 L13.5,3.3 Z"
        id="Shape"
      />
    </SvgIcon>
  )
}

const threshold = 80
@inject('authStore')
@inject('rootStore')
@inject('dashboardStore')
@inject('agenciesStore')
@inject('widgetsStore')
@inject('billingStore')
@observer
class Header extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      loggedIn: PropTypes.bool,
      onTabChange: PropTypes.func.isRequired,
      user: PropTypes.object.isRequired,
      logout: PropTypes.func.isRequired,
      settingsPermissions: PropTypes.array.isRequired
    }),
    authStore: PropTypes.shape({
      toggleModal: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    dashboardStore: PropTypes.shape({
      onTabChange: PropTypes.func.isRequired,
      dashboardPermissions: PropTypes.array.isRequired
    }),
    billingStore: PropTypes.shape({
      onTabChange: PropTypes.func.isRequired,
      billingPermissions: PropTypes.array.isRequired
    }),
    agenciesStore: PropTypes.shape({
      fetchApiKey: PropTypes.func
    })
  }

  @observable isFixed = false
  @observable isMinified = false
  @observable showLogInAs = false

  state = {
    openItem: 'Dashboard',
    openDrawer: true,
    mobView: false,
    isUpdated: false,
    selectedItem: 'Dashboard',
    hoverItem: ''
  }

  componentDidMount() {
    const {
      authStore: { toggleModal },
      rootStore,
      agenciesStore,
      history: { location },
      rootStore: { loggedIn }
    } = this.props
    if (
      !loggedIn &&
      location.pathname &&
      (location.pathname === routesUrls.signIn.link ||
        location.pathname === `${routesUrls.signIn.link}/`)
    ) {
      toggleModal()
    }

    if (
      rootStore.loggedIn &&
      rootStore.user &&
      rootStore.user.userType === 'agency'
    ) {
      agenciesStore.fetchApiKey()
    }
    if (sessionStorage.getItem('showAgency')) {
      this.setDate('showLogInAs', true)
    }
    if (location.pathname) {
      const name = location.pathname.split('/').filter((el) => el.length > 1)[0]
      if (!name) {
        return
      }
      let updateName = `${name[0].toUpperCase()}${name.slice(1)}`
      if (name === 'badges') {
        updateName = 'Achievements'
      }
      this.setState({ openItem: updateName, selectedItem: updateName })
    }
    this.handleResize()
  }

  @action
  setDate = (key, data) => {
    this[key] = data
  }

  UNSAFE_componentWillMount() {
    if (!!this.props.children && !this.isFixed) {
      this.toggleHeaderMode()
      this.body = document.body
      window.addEventListener('scroll', this.minifyHeader, false)
      if (!this.isVisible && this.getOffset() <= threshold) {
        this.minifyHeader()
      }
    }
    if (!this.props.children && this.isFixed) {
      this.toggleHeaderMode()
      window.removeEventListener('scroll', this.minifyHeader)
    }
    if (!this.state.isUpdated) {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
      this.setState({ isUpdated: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.minifyHeader)
    this.setDate('showLogInAs', false)
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate() {
    const {
      rootStore: { loggedIn, user },
      authStore: { setData, isOpenModal },
      authStore,
      agenciesStore,
      history: { location }
    } = this.props
    if (document.referrer.includes('/profile/accept-invite-referrer')) {
      return null
    }

    if (
      !isOpenModal &&
      !loggedIn &&
      location.pathname &&
      (location.pathname === routesUrls.signIn.link ||
        location.pathname === `${routesUrls.signIn.link}/`)
    ) {
      setData('isOpenModal', true)
    }

    if (!loggedIn && authStore.isUpdate) {
      if (
        !document.referrer.includes(baseURLs('frontend')) &&
        document.referrer
      ) {
        authStore.updatePreviousLink(document.referrer)
      }
    }
    if (loggedIn && !agenciesStore.agencyKey && user.userType === 'agency') {
      agenciesStore.fetchApiKey()

      const qs = new URLSearchParams(window.location.search)

      const agencyIdFromWidget = Number(
        qs.has('agencyId') ? qs.get('agencyId') : ''
      )

      if (
        agencyIdFromWidget &&
        agencyIdFromWidget !== agenciesStore.activeAgency.id
      ) {
        const widgetAgencyDetails = agenciesStore.agenciesList.find(
          (agency) => agency.id === agencyIdFromWidget
        )

        this.changeAgency(widgetAgencyDetails, this.props)()
      }
    }
    const showAgency = sessionStorage.getItem('showAgency')
    if (showAgency && showAgency !== this.showLogInAs) {
      this.setDate('showLogInAs', true)
    } else {
      this.setDate('showLogInAs', false)
    }
  }

  getOffset = () =>
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : document.documentElement.scrollTop()

  minifyHeader = throttle(
    action(() => {
      const offset = this.getOffset()
      if (offset > threshold) {
        this.isMinified = true
      } else {
        this.isMinified = false
      }
    }),
    200
  )

  @action
  toggleHeaderMode = () => {
    this.isFixed = !this.isFixed
  }

  logout = async () => {
    this.setState({ isLogoutInProgress: true })
    await this.props.rootStore.logout(() => {
      this.props.history.replace(routesUrls.home.link)
    })
    this.props.agenciesStore.setData('activeAgency', {})
    this.setState({
      isUpdated: false,
      openItem: 'Dashboard'
    })
    setTimeout(() => {
      this.setState({
        isLogoutInProgress: false
      })
    }, 1000)
  }

  redirectTo = (link, space, selected) => {
    this.setState({ selectedItem: selected })
    switch (space) {
      case 'settings':
        this.props.rootStore.onTabChange(link, () => {
          this.props.history.push(link)
        })
        break
      case 'tools':
        this.props.rootStore.onTabChange(link, () => {
          this.props.history.push(link)
        })
        break
      case 'dashboard':
        this.props.dashboardStore.onTabChange(link, () => {
          this.props.history.push(link)
        })
        break
      case 'badges':
        this.props.dashboardStore.onTabChange(link, () => {
          this.props.history.push(link)
        })
        break
      case 'widgets':
        this.props.history.push(link)
        break
      case 'toprec':
        this.props.history.push(link)
        break
      case 'billing':
        this.props.billingStore.onTabChange(link, () => {
          this.props.history.push(link)
        })
        break
      case 'reports':
        this.props.history.push(link)
        break
      case 'gamification-badges':
        this.setState({ openItem: '' })
        this.props.history.push(link)
        break
      case 'data-eraser':
        this.setState({ openItem: '' })
        this.props.history.push(link)
        break
      default:
        this.props.history.push(link)
        break
    }
  }

  switchAgencyOrUserRole = (value, isHiringManager) => {
    if (isHiringManager) {
      /**
       * @note switch to hiring manager profile
       */
      const {
        history: { push }
      } = this.props
      const { changeUserType } = this.props.agenciesStore
      changeUserType(value, () => {
        push(routesUrls.dashboard.children.candidateEnquiries.link)
      })
    } else {
      /**
       * @note switch agency
       */
      const {
        history: {
          location: { pathname },
          push
        }
      } = this.props
      const { changeAgency } = this.props.agenciesStore
      changeAgency(value, () => {
        if (pathname.includes('/billing/payments')) {
          push('/billing/payments/')
        } else if (pathname.includes('/billing/invoices/')) {
          push('/billing/invoices/')
        } else if (pathname.includes('/dashboard/enquiries')) {
          push('/dashboard/candidates-info/')
        }

        /**
         * @note clear query params
         */
        window.history.pushState({}, document.title, window.location.pathname)
      })
    }
  }

  changeAgency = (agency, props) => () => {
    const {
      history: {
        location: { pathname },
        push
      }
    } = props
    if (pathname.includes('/billing/payments')) {
      push('/billing/payments/')
    } else if (pathname.includes('/billing/invoices/')) {
      push('/billing/invoices/')
    }

    /**
     * @note clear query params
     */
    window.history.pushState({}, document.title, window.location.pathname)
    const { changeAgency } = this.props.agenciesStore
    changeAgency(agency)
  }

  renderUserName = (user) => (
    <ListSubheader
      className="header__user-name-wrapper"
      disableSticky
      style={{ width: '100%' }}
    >
      {user.isAdminLoggedAs && (
        <>
          <div className="header__user-badge hidden-xs" key="badge-text">
            {this.showLogInAs
              ? 'Company admin is signed in as'
              : 'System administrator is signed in as'}
          </div>
        </>
      )}
      <div className="header__user-name" title={user.fullName}>
        {user.fullName.length > 15
          ? `${user.fullName.substr(0, 15)}...`
          : user.fullName}
      </div>
    </ListSubheader>
  )

  handleResize = () => {
    if (document.body.clientWidth <= 991) {
      this.setState({
        mobView: true,
        openDrawer: false
      })
    } else {
      this.setState({
        mobView: false,
        openDrawer: true
      })
    }
  }

  handleDrawerOpen = () => {
    this.setState({ openDrawer: false })
  }

  toggleDrawer = () => {
    this.setState((prev) => ({ openDrawer: !prev.openDrawer }))
  }

  setOpenList = (name) => () => {
    if (this.state.openItem === name) {
      this.setState({ openItem: '' })
    } else {
      this.setState({ openItem: name })
    }
  }

  borderStyle = (openItem, theme, name) => {
    const status = openItem === name || this.state.selectedItem === name
    const { selectedItem } = this.state

    const styles = {
      borderLeft: `4px solid ${
        status ? theme.palette.primary1Color : 'transparent'
      }`,
      color: '#757575'
    }

    if (status && name === 'Board') {
      styles.backgroundColor = hexToRgba(theme.palette.primary1Color, 0.15)
    }

    if (selectedItem === name) {
      styles.backgroundColor = hexToRgba(theme.palette.primary1Color, 0.15)
    }

    if (name === this.state.hoverItem) {
      styles.backgroundColor = hexToRgba(theme.palette.primary1Color, 0.25)
    }

    return styles
  }

  borderStyleInner = (openItem, theme, item, name) => {
    const { history } = this.props
    const { selectedItem, hoverItem } = this.state

    const styles = {
      borderLeft: `4px solid ${
        openItem === name ? theme.palette.primary1Color : 'transparent'
      }`,
      color:
        history.location.pathname === item.link
          ? theme.palette.primary1Color
          : '#757575'
    }

    if (selectedItem === name) {
      styles.backgroundColor = hexToRgba(theme.palette.primary1Color, 0.15)
    }

    if (item.link === hoverItem) {
      styles.backgroundColor = hexToRgba(theme.palette.primary1Color, 0.25)
    }

    return styles
  }

  setHover = (data) => () => {
    this.setState({ hoverItem: data })
  }

  removeHover = () => {
    this.setState({ hoverItem: '' })
  }

  handleSignIn = () => {
    const {
      authStore: { toggleModal }
    } = this.props
    this.props.history.replace(routesUrls.signIn.link)
    toggleModal()
  }

  render() {
    const {
      rootStore: {
        loggedIn,
        user,
        settingsPermissions,
        toolsPermissions,
        badgesPermissions,
        agency: { branding },
        topRecPermissions,
        reportsPermissions
      },
      pathname,
      theme,
      dashboardStore: { dashboardPermissions },
      agenciesStore: { agenciesList, activeAgency },
      widgetsStore: { widgetsPermissions },
      billingStore: { billingPermissions },
      children,
      rootStore
    } = this.props
    const { openItem, mobView, selectedItem } = this.state
    const isPasswordResetPage = pathname === routesUrls.passwordReset.link
    const logoStyle = {
      borderRadius: !children && !!branding.logo.thumb && '50%'
    }
    const showSupport = rootStore.user.userType === 'agency'
    const showSidebar =
      loggedIn &&
      (rootStore.user.isAdminLoggedAs ||
        rootStore.user.userType ||
        (!children && branding.logo.thumb))
    const showSuperAdminMenu = loggedIn && rootStore.user.userType === 'default'

    const showLogo =
      rootStore.user.userType !== 'default' &&
      (rootStore.user.role === 'consultant' ||
        rootStore.user.role === 'admin' ||
        rootStore.user.userType !== 'agency')
    const matchProfile = matchPath(pathname, {
      path: routesUrls.welcome.link
    })

    return (
      <div
        className={classnames('header', {
          'header--sales': !!children,
          'is-minified': !!children && this.isMinified,
          'header-border-button': loggedIn
        })}
        style={{
          ...theme.header,
          height: user.isAdminLoggedAs ? '115px' : '105px'
        }}
        ref={this.header}
      >
        <div
          className={classnames({
            container: !showSidebar,
            'header-space': showSidebar
          })}
        >
          <div
            className={classnames(
              'space-between align-items-center flex-wrap',
              {
                'flex-end': loggedIn && !showLogo
              }
            )}
          >
            {!loggedIn && (
              <div
                className="extra-div-non-loggedin"
                style={{
                  width: matchProfile && matchProfile.params ? 138 : 'inherit'
                }}
              />
            )}
            {(loggedIn ||
              (matchProfile && matchProfile.params && !loggedIn)) && (
              <div
                className="brand-logo-section"
                style={
                  matchProfile && matchProfile.params && !loggedIn
                    ? { paddingTop: 5, width: 'inherit' }
                    : branding.logo.thumb &&
                      !branding.logo_wide.thumb &&
                      !branding.logo_platform.thumb
                    ? { width: '55%' }
                    : { paddingTop: 5 }
                }
              >
                {loggedIn && (
                  <Button
                    startIcon={<Menu style={{ color: '#9e9e9e' }} />}
                    onClick={this.toggleDrawer}
                    style={{ minWidth: 50 }}
                    className="brand-logo-section--button"
                  />
                )}
                {branding.logo.thumb &&
                  !branding.logo_wide.thumb &&
                  !branding.logo_platform.thumb &&
                  rootStore.user.userType !== 'default' &&
                  (rootStore.user.role === 'consultant' ||
                    rootStore.user.role === 'admin' ||
                    rootStore.user.role === 'manager' ||
                    rootStore.user.userType !== 'agency') && (
                    <img
                      src={branding.logo.thumb}
                      alt={branding.description}
                      style={logoStyle}
                      className="brand-logo"
                    />
                  )}
                {branding.logo_platform.thumb &&
                  rootStore.user.userType !== 'default' &&
                  (rootStore.user.role === 'consultant' ||
                    rootStore.user.role === 'admin' ||
                    rootStore.user.role === 'manager' ||
                    rootStore.user.userType !== 'agency') && (
                    <img
                      src={branding.logo_platform.thumb}
                      alt={branding.description}
                      className="brand-logo"
                    />
                  )}
                {branding.logo_wide.thumb &&
                  !branding.logo_platform.thumb &&
                  rootStore.user.userType !== 'default' &&
                  (rootStore.user.role === 'consultant' ||
                    rootStore.user.role === 'admin' ||
                    rootStore.user.role === 'manager' ||
                    rootStore.user.userType !== 'agency') && (
                    <img
                      src={branding.logo_wide.thumb}
                      alt={branding.description}
                      className="brand-logo"
                    />
                  )}
              </div>
            )}
            {loggedIn && (
              <div
                className={classnames('logo', {
                  'logo-height': !loggedIn,
                  'logo-right': showSidebar
                })}
                style={
                  showSidebar &&
                  branding.logo.thumb &&
                  !branding.logo_wide.thumb &&
                  !branding.logo_platform.thumb
                    ? { width: '45%' }
                    : {}
                }
              >
                <Link className="logo-link" to={routesUrls.home.link}>
                  <small
                    className="logo-link-text"
                    style={{
                      color:
                        matchProfile && matchProfile.params
                          ? '#ffffff'
                          : defaultPalette.textColor
                    }}
                  >
                    powered by
                  </small>
                  <LogoIcon
                    style={{ width: 105, height: 60 }}
                    fill={
                      matchProfile && matchProfile.params
                        ? '#ffffff'
                        : '#000000'
                    }
                  />
                </Link>
              </div>
            )}
            {children &&
              React.cloneElement(children, { isMinified: this.isMinified })}
            {!children && !isPasswordResetPage && !loggedIn && (
              <div className="action-menu">
                <Button
                  onClick={() => {
                    this.handleSignIn()
                  }}
                  style={{
                    color: '#fff'
                  }}
                >
                  Sign In / sign Up
                </Button>
              </div>
            )}
            {showSidebar && (
              <Drawer
                variant={mobView ? 'temporary' : 'permanent'}
                className="header-drawer"
                classes={{
                  paper: 'header-drawer-container'
                }}
                PaperProps={{
                  style: { ...headerDrawer(!mobView, branding) }
                }}
                open={this.state.openDrawer}
                onClose={this.handleDrawerOpen}
              >
                <div
                  className="drawer-wrapper"
                  style={{ background: hexToRgba(branding.primaryColor, 0.05) }}
                >
                  <div
                    style={{
                      backgroundColor: theme.palette.primary1Color
                    }}
                    className={classnames({
                      'header-nav': true,
                      'header_height--regular': !user.isAdminLoggedAs,
                      'header_height--userLoggedInAs': user.isAdminLoggedAs
                    })}
                  >
                    {this.renderUserName(user)}
                    <div
                      className="header-nav--board"
                      style={{ padding: '0 24px 0 17px' }}
                    >
                      {(activeAgency ||
                        rootStore.user.userType === 'hiring_manager') &&
                        agenciesList &&
                        agenciesList.length > 0 &&
                        rootStore.user.userType !== 'referrer' && (
                          <HeaderSwitchMenu
                            agenciesList={agenciesList}
                            onMenuChange={this.switchAgencyOrUserRole}
                            defaultSelectedValue={
                              rootStore.user.userType === 'hiring_manager'
                                ? 'hiringManager'
                                : activeAgency && activeAgency.name
                            }
                            availableProfiles={user.availableProfiles}
                          />
                        )}
                    </div>
                  </div>
                  <List
                    component="nav"
                    aria-label="main"
                    className="refari-left-navigation"
                    style={{ padding: 0 }}
                  >
                    {!!dashboardPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Dashboard')}
                          style={this.borderStyle(openItem, theme, 'Dashboard')}
                          className={classnames('header-nav__item', {
                            'header-nav--text': selectedItem !== 'Dashboard'
                          })}
                          onMouseEnter={this.setHover('Dashboard')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <Menu style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Dashboard" />
                          {openItem === 'Dashboard' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Dashboard'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {dashboardPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'Dashboard'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'dashboard',
                                  'Dashboard'
                                )}
                                className="header-nav--left-space"
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                                <div>
                                  <DashboardIcon
                                    iconName={item.title.toLowerCase()}
                                    iconAdditionalStyles={{
                                      marginTop: '5px'
                                    }}
                                  />
                                </div>
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {!!settingsPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Settings')}
                          style={this.borderStyle(openItem, theme, 'Settings')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'Settings' }
                          )}
                          onMouseEnter={this.setHover('Settings')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <Settings style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Settings" />
                          {openItem === 'Settings' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Settings'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {settingsPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'Settings'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'settings',
                                  'Settings'
                                )}
                                className="header-nav--left-space"
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {!!toolsPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Tools')}
                          style={this.borderStyle(openItem, theme, 'Tools')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'Tools' }
                          )}
                          onMouseEnter={this.setHover('Tools')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <ToolsIcon style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Tools" />
                          {openItem === 'Tools' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Tools'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {toolsPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'Tools'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'tools',
                                  'Tools'
                                )}
                                className="header-nav--left-space"
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {!!billingPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Billing')}
                          style={this.borderStyle(openItem, theme, 'Billing')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'Billing' }
                          )}
                          onMouseEnter={this.setHover('Billing')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <CreditCard style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Billing" />
                          {openItem === 'Billing' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Billing'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {billingPermissions
                              .filter((item) => !item.hideActiveNavigation)
                              .map((item, index) => (
                                <ListItem
                                  key={item.link + index}
                                  style={this.borderStyleInner(
                                    openItem,
                                    theme,
                                    item,
                                    'Billing'
                                  )}
                                  onClick={this.redirectTo.bind(
                                    this,
                                    item.link,
                                    'billing',
                                    'Billing'
                                  )}
                                  className="header-nav--left-space"
                                  onMouseEnter={this.setHover(item.link)}
                                  onMouseLeave={this.removeHover}
                                >
                                  <ListItemText primary={item.title} />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {rootStore.user.userType === 'agency' &&
                      badgesPermissions.length > 0 && (
                        <>
                          <ListItem
                            button
                            onClick={this.setOpenList('Achievements')}
                            style={this.borderStyle(
                              openItem,
                              theme,
                              'Achievements'
                            )}
                            className={classnames(
                              'header-nav--top-border header-nav__item',
                              {
                                'header-nav--text':
                                  selectedItem !== 'Achievements'
                              }
                            )}
                            onMouseEnter={this.setHover('Achievements')}
                            onMouseLeave={this.removeHover}
                          >
                            <ListItemIcon>
                              <AchievmentsIcon
                                style={{
                                  width: '30px',
                                  height: '34px',
                                  color: '#757575'
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Achievements" />
                            {openItem === 'Achievements' ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={openItem === 'Achievements'}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {badgesPermissions.map((item, index) => (
                                <ListItem
                                  key={item.link + index}
                                  style={this.borderStyleInner(
                                    openItem,
                                    theme,
                                    item,
                                    'Achievements'
                                  )}
                                  onClick={this.redirectTo.bind(
                                    this,
                                    item.link,
                                    'badges',
                                    'Achievements'
                                  )}
                                  className={`${
                                    rootStore.newProgress &&
                                    `header-notification-menu-${index}`
                                  } header-nav--left-space`}
                                  onMouseEnter={this.setHover(item.link)}
                                  onMouseLeave={this.removeHover}
                                >
                                  <ListItemText primary={item.title} />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      )}
                    {!!topRecPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('TopRec')}
                          style={this.borderStyle(openItem, theme, 'TopRec')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'TopRec' }
                          )}
                          onMouseEnter={this.setHover('TopRec')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <Assessment style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="TopRec" />
                          {openItem === 'TopRec' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'TopRec'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {topRecPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'TopRec'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'toprec',
                                  'TopRec'
                                )}
                                className={`${
                                  rootStore.newProgress &&
                                  `header-notification-menu-${index}`
                                } header-nav--left-space`}
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {!!widgetsPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Widgets')}
                          style={this.borderStyle(openItem, theme, 'Widgets')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'Widgets' }
                          )}
                          onMouseEnter={this.setHover('Widgets')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <Widgets style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Widgets" />
                          {openItem === 'Widgets' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Widgets'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {widgetsPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'Widgets'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'widgets',
                                  'Widgets'
                                )}
                                className="nav-sidebar__link header-nav--left-space"
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {!!reportsPermissions.length && (
                      <>
                        <ListItem
                          button
                          onClick={this.setOpenList('Reports')}
                          style={this.borderStyle(openItem, theme, 'Reports')}
                          className={classnames(
                            'header-nav--top-border header-nav__item',
                            { 'header-nav--text': selectedItem !== 'Reports' }
                          )}
                          onMouseEnter={this.setHover('Reports')}
                          onMouseLeave={this.removeHover}
                        >
                          <ListItemIcon>
                            <Assessment style={{ color: '#757575' }} />
                          </ListItemIcon>
                          <ListItemText primary="Reports" />
                          {openItem === 'Reports' ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={openItem === 'Reports'}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {reportsPermissions.map((item, index) => (
                              <ListItem
                                key={item.link + index}
                                style={this.borderStyleInner(
                                  openItem,
                                  theme,
                                  item,
                                  'Reports'
                                )}
                                onClick={this.redirectTo.bind(
                                  this,
                                  item.link,
                                  'reports',
                                  'Reports'
                                )}
                                className={`${
                                  rootStore.newProgress &&
                                  `header-notification-menu-${index}`
                                } header-nav--left-space`}
                                onMouseEnter={this.setHover(item.link)}
                                onMouseLeave={this.removeHover}
                              >
                                <ListItemText primary={item.title} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </>
                    )}
                    {showSuperAdminMenu && (
                      <ListItem
                        button
                        onClick={this.redirectTo.bind(
                          this,
                          routesUrls.gamificationBadges.link,
                          'gamification-badges',
                          'Gamification Badges'
                        )}
                        style={{
                          backgroundColor:
                            this.state.hoverItem === 'Gamification Badges' ||
                            selectedItem === 'Gamification-badges' ||
                            selectedItem === 'Gamification Badges'
                              ? hexToRgba(theme.palette.primary1Color, 0.25)
                              : '',
                          borderLeft: `4px solid ${
                            selectedItem === 'Gamification-badges' ||
                            selectedItem === 'Gamification Badges'
                              ? theme.palette.primary1Color
                              : 'transparent'
                          }`,
                          color:
                            pathname === routesUrls.gamificationBadges.link
                              ? theme.palette.primary1Color
                              : '#757575'
                        }}
                        className="header-nav--top-border header-nav--text"
                        onMouseEnter={this.setHover('Gamification Badges')}
                        onMouseLeave={this.removeHover}
                      >
                        <ListItemIcon>
                          <AchievmentsIcon
                            style={{
                              width: '30px',
                              height: '34px',
                              color: '#757575'
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Gamification Badges" />
                      </ListItem>
                    )}
                    {showSuperAdminMenu && (
                      <ListItem
                        button
                        onClick={this.redirectTo.bind(
                          this,
                          routesUrls.dataEraser.link,
                          'data-eraser',
                          'Data Eraser'
                        )}
                        style={{
                          backgroundColor:
                            this.state.hoverItem === 'Data Eraser' ||
                            selectedItem === 'Data-eraser' ||
                            selectedItem === 'Data Eraser'
                              ? hexToRgba(theme.palette.primary1Color, 0.25)
                              : '',
                          borderLeft: `4px solid ${
                            selectedItem === 'Data-eraser' ||
                            selectedItem === 'Data Eraser'
                              ? theme.palette.primary1Color
                              : 'transparent'
                          }`,
                          color:
                            pathname === routesUrls.dataEraser.link
                              ? theme.palette.primary1Color
                              : '#757575'
                        }}
                        className="header-nav--top-border header-nav--text"
                        onMouseEnter={this.setHover('Data Eraser')}
                        onMouseLeave={this.removeHover}
                      >
                        <ListItemIcon>
                          <PhonelinkErase style={{ color: '#757575' }} />
                        </ListItemIcon>
                        <ListItemText primary="Data Erasure" />
                      </ListItem>
                    )}
                    {showSupport && (
                      <ListItem
                        component="a"
                        href="https://support.refari.co/"
                        target="_blank"
                        onClick={this.setOpenList('Widgets')}
                        style={{
                          backgroundColor:
                            this.state.hoverItem === 'Support'
                              ? hexToRgba(theme.palette.primary1Color, 0.25)
                              : ''
                        }}
                        className="header-nav--top-border header-nav--text"
                        onMouseEnter={this.setHover('Support')}
                        onMouseLeave={this.removeHover}
                      >
                        <ListItemIcon>
                          <HeadsetMic style={{ color: '#757575' }} />
                        </ListItemIcon>
                        <ListItemText primary="Support" />
                      </ListItem>
                    )}
                    <ListItem
                      button
                      onClick={this.logout}
                      style={{
                        backgroundColor:
                          this.state.hoverItem === 'Logout'
                            ? hexToRgba(theme.palette.primary1Color, 0.25)
                            : ''
                      }}
                      className="header-nav--logout header-nav--top-border header-nav--text"
                      onMouseEnter={this.setHover('Logout')}
                      onMouseLeave={this.removeHover}
                      disabled={this.state.isLogoutInProgress}
                    >
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </div>
              </Drawer>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(Header)
