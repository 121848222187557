import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

const GifFrames = ({ images, selectedImage, theme, onChange }) => {
  if (!images.length) return null

  const activeStyle = {
    borderColor: theme.palette.primary1Color
  }

  const getActiveStyle = (isActive) => (isActive ? activeStyle : {})
  return (
    <div className="refari-background-options" style={{ maxWidth: '100%' }}>
      <div style={{ position: 'relative', display: 'flex' }}>
        {images.map((canvas) => (
          <div
            key={`canvas-${canvas.id}`}
            className="refari-option-wrapper"
            style={getActiveStyle(canvas.id === selectedImage)}
          >
            <label
              htmlFor={`background-${canvas.id}`}
              style={{ width: 250, maxHeight: '100%' }}
            >
              <img src={canvas.url} alt={`frame-${canvas.id}`} />
              <input
                id={`background-${canvas.id}`}
                type="radio"
                className="refari-hidden"
                value={canvas.id}
                checked={canvas.id === selectedImage}
                onChange={onChange}
                name="backgrounds"
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

GifFrames.propTypes = {
  images: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedImage: PropTypes.any
}

export default withTheme(GifFrames)
