import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@material-ui/core'

interface ConfirmationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm
}) => {
  const [isConfirmInProgress, setIsConfirmInProgress] = useState(false)
  const handleConfirm = async () => {
    setIsConfirmInProgress(true)
    await onConfirm()
    setIsConfirmInProgress(false)
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="primary">Confirmation</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to proceed?</p>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
          disabled={isConfirmInProgress}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          disabled={isConfirmInProgress}
          startIcon={
            isConfirmInProgress ? <CircularProgress size={20} /> : null
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
