/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core'
import CloseModalButton from './CloseModalButton'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import RaisedButton from '../common/RaisedButton'
import Input from '../formComponents/Input'
import { validationErrors as errors } from '../../../utils/helpers'
import Modal from './Modal'

@observer
class MessageTemplateModal extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      suggestionTemplateMessage: PropTypes.string.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      suggestionTemplateName: new Field({
        name: 'name',
        value: '',
        validators: [errors.required()]
      }),
      suggestionTemplateMessage: new Field({
        name: 'message',
        value: props.suggestionTemplateMessage,
        validators: [errors.required()]
      }),

      messageTemplateType: new Field({
        name: 'messageTemplateType',
        value: 'personal'
      })
    })
  }

  componentDidUpdate() {
    const { rootStore } = this.props
    if (
      rootStore.isOpenSuggestionMessageModal &&
      rootStore.suggestionTemplateMessage
    ) {
      this.form.fields.suggestionTemplateMessage.update(
        rootStore.suggestionTemplateMessage
      )
    }
  }

  closeModal = () => {
    const {
      rootStore: { toggleSuggestionMessageModal, setData }
    } = this.props
    toggleSuggestionMessageModal()
    setData('suggestionTemplateMessage', '')
  }

  submitForm = (e) => {
    e.preventDefault()
    this.props.rootStore.submitSuggestionMessage(this.form)
  }

  render() {
    const {
      rootStore: {
        suggestionTemplateMessage,
        isOpenSuggestionMessageModal,
        user
      },
      theme
    } = this.props

    if (!suggestionTemplateMessage) {
      return null
    }

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    const canCreateCompanyTemplate =
      user?.role === 'admin' || user?.role === 'manager'

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={isOpenSuggestionMessageModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'modal refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Save Message Template
          </h3>

          <div
            style={{
              width: '100%',
              maxWidth: 400,
              margin: 'auto'
            }}
          >
            <form className="refari-form-section" onSubmit={this.submitForm}>
              {canCreateCompanyTemplate && (
                <div>
                  <h5>Save as</h5>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="messageTemplateType"
                      name="messageTemplateType"
                      value={this.form.fields.messageTemplateType.value}
                      onChange={(e) => {
                        this.form.fields.messageTemplateType.update(
                          e.target.value
                        )
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value="personal"
                        control={<Radio color="primary" />}
                        label="Personal Template"
                        style={{ display: 'inline-flex', marginBottom: 0 }}
                      />
                      <FormControlLabel
                        value="company"
                        control={<Radio color="primary" />}
                        label="Company Template"
                        style={{ display: 'inline-flex', marginBottom: 0 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              <Input
                fieldData={this.form.fields.suggestionTemplateName}
                label="Template name"
              />
              <RaisedButton
                type="submit"
                fullWidth
                color="primary"
                className={`refari-submit-btn refari-button-raised ${
                  !this.form.isValid || this.form.isPending
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                style={{ width: 'auto' }}
                disabled={!this.form.isValid || this.form.isPending}
              >
                Save
              </RaisedButton>
            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(MessageTemplateModal)
