import { RefariDTOTypes } from '@refari-frontend/types'

export type RecruiterBudgetDetails = {
  id: number
  recruiterName: string
  allocatedBudget: number
  remainingBudget: number
  currency: string
}

export type RecruiterBudgetDetailDTOType =
  RefariDTOTypes['/dashboard/agency/ai-budget/recruiters/']['get']['responses']['200']['content']['application/json']['results'][0]

class RecruiterBudgetModel implements RecruiterBudgetDetails {
  id: RecruiterBudgetDetails['id']
  recruiterName: RecruiterBudgetDetails['recruiterName']
  allocatedBudget: RecruiterBudgetDetails['allocatedBudget']
  remainingBudget: RecruiterBudgetDetails['remainingBudget']
  currency: RecruiterBudgetDetails['currency']

  constructor(data: RecruiterBudgetDetailDTOType) {
    /**
     * @todo get clarification on the id type
     */
    this.id = data.id
    this.recruiterName =
      data.agency_user.first_name + ' ' + data.agency_user.last_name
    this.allocatedBudget = Number(data.amount)
    this.remainingBudget = Number(data.amount) - Number(data.used_budget)
    this.currency = '$'
  }
}

export { RecruiterBudgetModel }
