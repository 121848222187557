import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withTheme
} from '@material-ui/core'
import { toJS } from 'mobx'
import React from 'react'
import LinkedInIcon from '../../icons/LinkedInIcon'
import FacebookIcon from '../../icons/FacebookIcon'
import colors from 'src/constants/colors'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

const commentTextMapping = {
  default: 'You can find out more about this here',
  job: 'You can check out the job and find more details here',
  anything: 'You can find out more about this here',
  testimonial: 'Find out more about our brand and what we do here',
  job_list:
    'See all the Jobs currently with our Brand. Have we got one perfect for you?',
  profile: 'Find out more about',
  candidate_ad: 'Find out more about this candidate here'
}

class SocialiseCommentModal extends React.Component {
  constructor(props) {
    super(props)

    const commentText = this.getCommentText(props)

    this.state = {
      linkedInCommentText: commentText,
      facebookCommentText: commentText
    }
  }

  copyToClipBoardAndRedirect = async (network, jobLink, postLink) => {
    if (network === 'linkedin') {
      await navigator.clipboard.writeText(
        `${this.state.linkedInCommentText} ${jobLink}`.trim()
      )
    } else {
      await navigator.clipboard.writeText(
        `${this.state.facebookCommentText} ${jobLink}`.trim()
      )
    }
    window.open(postLink, '_blank')
  }

  onCloseCommentModal = () => {
    this.setState({
      linkedInCommentText: commentTextMapping.default,
      facebookCommentText: commentTextMapping.default
    })
  }

  getCommentText = (props) => {
    const { rootStore } = props

    const postType = this.getPostType()

    if (!commentTextMapping[postType]) {
      return commentTextMapping.default
    }

    if (postType === 'profile') {
      return `${commentTextMapping.profile} ${rootStore.user.fullName}`
    }

    return commentTextMapping[postType]
  }

  getPostType = () => {
    const { publishData } = this.props

    const publishDataJSON = toJS(publishData)

    if (publishDataJSON['linkedin']) {
      return publishDataJSON['linkedin'].post_type
    }

    if (publishDataJSON['facebook']) {
      return publishDataJSON['facebook'].post_type
    }

    const companies = publishDataJSON['companies']

    if (Array.isArray(companies) && companies.length > 0) {
      return companies[0].post_type
    }

    return 'default'
  }

  componentDidUpdate(prevProps, prevState) {
    const commentText = this.getCommentText(this.props)

    if (commentText !== prevState.linkedInCommentText) {
      this.setState({
        linkedInCommentText: commentText,
        facebookCommentText: commentText
      })
    }
  }

  render() {
    const { isOpen, publishData, onClose } = this.props
    const publishDataJSON = toJS(publishData)
    const linkedInData = []
    const facebookData = []
    if (publishDataJSON['linkedin']) {
      linkedInData.push(publishDataJSON['linkedin'])
    }
    if (publishDataJSON['facebook']) {
      facebookData.push(publishDataJSON['facebook'])
    }
    const companies = publishDataJSON['companies']
    if (Array.isArray(companies) && companies.length > 0) {
      companies.forEach((company) => {
        if (company.network?.includes('linkedin')) linkedInData.push(company)
        if (company.network?.includes('facebook')) facebookData.push(company)
      })
    }

    const jobPostURL =
      linkedInData.length > 0
        ? linkedInData.reduce((acc, item) => {
            console.log(acc)
            console.log(item)
            if (acc) return acc
            return item?.ssr_link
          }, '')
        : facebookData.reduce((acc, item) => {
            if (acc) return acc
            return item?.ssr_link
          }, '')

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        disableBackdropClick
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ position: 'relative' }}>
          <Typography
            color="primary"
            align="center"
            variant="h2"
            style={{ fontSize: '1.5rem' }}
          >
            Amplify the reach of your social post
          </Typography>
          <IconButton
            style={{ position: 'absolute', right: 5, top: 5 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', gap: '1rem' }}>
          {linkedInData.length > 0 && (
            <div style={{ width: '100%', marginBottom: '1rem' }}>
              <div>
                <LinkedInIcon
                  style={{
                    color: colors.linkedin,
                    marginTop: 0,
                    marginRight: '4px',
                    width: '19px',
                    height: '18px'
                  }}
                />
                <Typography display="inline">Comment on Linkedin</Typography>{' '}
                <Typography display="inline" color="primary">
                  (Highly Recommended)
                </Typography>
              </div>
              <TextField
                value={this.state.linkedInCommentText}
                onChange={(event) =>
                  this.setState({
                    linkedInCommentText: event.target.value
                  })
                }
                multiline
                maxRows={4}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon />
                    </InputAdornment>
                  )
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography
                  style={{
                    display: 'inline-block',
                    padding: '4px 10px',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    background: '#F2F2F2',
                    color: '#0557D7',
                    fontSize: '0.8rem'
                  }}
                >
                  {jobPostURL}
                </Typography>
              </div>
              {linkedInData.map((data, index) => (
                <div
                  key={data.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: index === 0 ? '1rem' : '0.5rem'
                  }}
                >
                  <Button
                    onClick={() =>
                      this.copyToClipBoardAndRedirect(
                        'linkedin',
                        data.ssr_comment_link || data.ssr_link,
                        data.social_link
                      )
                    }
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: '20px'
                    }}
                  >
                    Copy and Comment to Linkedin{' '}
                    {data.isCompany ? 'Company' : 'Personal'}
                  </Button>
                </div>
              ))}
            </div>
          )}
          {facebookData.length > 0 && (
            <div style={{ width: '100%', marginBottom: '1rem' }}>
              <div>
                <FacebookIcon
                  style={{
                    color: colors.facebook,
                    marginRight: '4px'
                  }}
                />
                <Typography display="inline">Comment on Facebook</Typography>
              </div>
              {/* add input box */}
              <TextField
                value={this.state.facebookCommentText}
                onChange={(event) =>
                  this.setState({
                    facebookCommentText: event.target.value
                  })
                }
                multiline
                maxRows={4}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditIcon />
                    </InputAdornment>
                  )
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Typography
                  style={{
                    display: 'inline-block',
                    padding: '4px 10px',
                    fontWeight: 'bold',
                    borderRadius: '4px',
                    background: '#F2F2F2',
                    color: '#0557D7',
                    fontSize: '0.8rem'
                  }}
                >
                  {jobPostURL}
                </Typography>
              </div>
              {facebookData.map((data, index) => (
                <div
                  key={data.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: index === 0 ? '1rem' : '0.5rem'
                  }}
                >
                  <Button
                    onClick={() =>
                      this.copyToClipBoardAndRedirect(
                        'facebook',
                        data.ssr_comment_link || data.ssr_link,
                        data.social_link
                      )
                    }
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: '20px'
                    }}
                  >
                    Copy and Comment to facebook{' '}
                    {data.isCompany ? 'Company' : 'Personal'}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
}

export default withTheme(SocialiseCommentModal)
