/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  Dialog, Tooltip, Button, CircularProgress
} from '@material-ui/core'
import ReactCodeInput from 'react-verification-code-input'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import { validationErrors as errors, pSBC } from '../../../../utils/helpers'

@inject('authTwoFactorStore')
@observer
class SetupTwoFactorModal extends React.Component {
  static propTypes = {
    authTwoFactorStore: PropTypes.shape({
      toggleTwoFactorModal: PropTypes.func.isRequired,
      isOpenTwoFactorModal: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      token: new Field({
        name: 'token',
        value: '',
        validators: [errors.required()],
      })
    })
  }

  onClose = () => {
    const { authTwoFactorStore: { toggleTwoFactorModal } } = this.props
    toggleTwoFactorModal()
  }

  handleChange = (vals) => {
    this.form.fields.token.update(vals)
  }

  openTwoFactorAuthURL = () => {
    window.open('https://en.wikipedia.org/wiki/Multi-factor_authentication', '_blank')
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { authTwoFactorStore: { verifyAuthCode } } = this.props
    if (this.form.fields.token.value.length < 6) {
      return
    }
    this.form.submit(verifyAuthCode)
  }

  render() {
    const { authTwoFactorStore: { isOpenTwoFactorModal, isLoading, qrcode }, theme } = this.props

    const styles = {
      barcode: {
        background: `linear-gradient(to right, ${theme.palette.primary1Color} 4px, transparent 4px) 0 0, linear-gradient(to right, ${theme.palette.primary1Color} 4px, transparent 4px) 0 100%, linear-gradient(to left, ${theme.palette.primary1Color} 4px, transparent 4px) 100% 0, linear-gradient(to left, ${theme.palette.primary1Color} 4px, transparent 4px) 100% 100%, linear-gradient(to bottom, ${theme.palette.primary1Color} 4px, transparent 4px) 0 0, linear-gradient(to bottom, ${theme.palette.primary1Color} 4px, transparent 4px) 100% 0, linear-gradient(to top, ${theme.palette.primary1Color} 4px, transparent 4px) 0 100%, linear-gradient(to top, ${theme.palette.primary1Color} 4px, transparent 4px) 100% 100%`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40px 40px'
      }
    }

    return (
      <div className="question-mapping-section-answer-modal">
        <div className="question-mapping-section-answer-modal-box">
          <Dialog
            className="modal-container-without-left refari-modal"
            BackdropProps={{ className: 'modal-container-without-left-overlay' }}
            maxWidth="xs"
            PaperProps={{
              style: { maxWidth: 500 }
            }}
            fullWidth
            open={isOpenTwoFactorModal}
            onClose={this.onClose}
          >
            <div className="refari-modal-content">
              <div className="refari-placements-wrapper refari-phone-code-wrapper">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" style={{ color: theme.palette.primary1Color }}>Setup two-factor authentication</h5>
                    <button type="button" onClick={this.onClose} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="refari-phone-code-banner" style={{ backgroundColor: pSBC(0.60, theme.palette.primary1Color) }}>
                    <div
                      style={{
                        color: theme.palette.primary1Color,
                        cursor: 'pointer'
                      }}
                      onClick={this.openTwoFactorAuthURL}
                    >
                      Learn about two-factor authentication
                    </div>
                    <Tooltip
                      title="Open in new window"
                      placement="bottom"
                      TransitionProps={{
                        style: {
                          backgroundColor: '#fff',
                          color: 'rgba(0, 0, 0, 0.87)',
                          boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
                          fontSize: 12
                        }
                      }}
                    >
                      <OpenInNewIcon
                        onClick={this.openTwoFactorAuthURL}
                        className="refari-two-factor-auth-new-window"
                        style={{
                          width: 15,
                          height: 15,
                          color: theme.palette.primary1Color
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="modal-body" style={{ padding: '1rem 4rem 2rem' }}>
                    <div className="setting-cont">
                      <form onSubmit={this.onSubmit}>
                        <p className="refari-phone-code-subheading" style={{ color: '#000000', fontWeight: 700 }}>
                          1. Setup your authenticator
                        </p>
                        <p className="refari-phone-code-subheading" style={{ fontSize: 13 }}>
                          Open an authenticator app and scan this QR code.
                        </p>
                        <p className="refari-phone-code-qrcode" style={styles.barcode} dangerouslySetInnerHTML={{ __html: qrcode }} />
                        <p className="refari-phone-code-subheading" style={{ fontSize: 13 }}>
                          Once refari is registered, you'll start seeing 6-digit verification codes in the app.
                        </p>
                        <p className="refari-phone-code-subheading" style={{ color: '#000000', fontWeight: 700 }}>
                          2. Confirm your authentication code
                        </p>
                        <p className="refari-phone-code-subheading" style={{ fontSize: 13 }}>
                          Please enter your authenticator app code here.
                        </p>
                        <ReactCodeInput
                          onChange={this.handleChange}
                          fieldWidth={38}
                          fieldHeight={38}
                          className="refari-phone-code-input"
                        />
                        <div className="refari-w-row refari-justify-center refari-align-items-center m-t-3">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={this.form.fields.token.value.length < 6}
                          >
                            Verify
                          </Button>
                          {isLoading && (
                            <CircularProgress size={25} thickness={2} color="primary" style={{ marginLeft: 10 }} />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTheme(SetupTwoFactorModal)
