import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Button, Card, CardMedia, Avatar, IconButton } from '@material-ui/core'
import PublicIcon from '@material-ui/icons/Public'
import GroupIcon from '@material-ui/icons/Group'
import LinkIcon from '@material-ui/icons/Link'
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined'
import SendOutlinedIcon from '@material-ui/icons/SendOutlined'
import RepeatIcon from '@material-ui/icons/Repeat'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import Field from '../../../../models/form/Field'
import CanvasCardImage from './CanvasCardImage'
import linkedin from '../../../../assets/images/linkedin_icon.png'
import facebook from '../../../../assets/images/facebook_icon.png'
import twitter from '../../../../assets/images/twitter_icon.png'

@observer
class PreviewSocialise extends React.Component {
  static propTypes = {
    socializeStore: PropTypes.shape({
      title: PropTypes.instanceOf(Field).isRequired,
      description: PropTypes.instanceOf(Field).isRequired,
      image: PropTypes.instanceOf(Field),
      setPendingState: PropTypes.func.isRequired,
      pending: PropTypes.shape({
        image: PropTypes.bool.isRequired,
        actions: PropTypes.bool.isRequired
      }),
      toggle: PropTypes.func.isRequired,
      setTemplateImage: PropTypes.func.isRequired,
      isCustomImage: PropTypes.bool.isRequired
    }),
    canvasCardStore: PropTypes.shape({
      setData: PropTypes.func.isRequired,
      agencyImageIsUsed: PropTypes.bool.isRequired,
      descriptionIsUsed: PropTypes.bool.isRequired,
      textColor: PropTypes.string,
      playIconColor: PropTypes.string,
      toggleTitle: PropTypes.func.isRequired,
      titleIsUsed: PropTypes.bool.isRequired,
      loadFontList: PropTypes.func.isRequired,
      fonts: PropTypes.array.isRequired,
      selectFont: PropTypes.func.isRequired,
      siteFont: PropTypes.string
    }).isRequired,
    customBackground: PropTypes.object.isRequired
  }

  static defaultTypes = {
    blur: 0,
    brightness: 0
  }

  render() {
    const {
      rootStore,
      socializeStore,
      canvasCardStore,
      customBackground,
      theme,
      theme: {
        palette: { secondaryTextColor, primary1ColorSocialize }
      },
      uploadPercentage,
      isGIFRendering,
      setUploadPercentage
    } = this.props

    const {
      description,
      title,
      editedTitle,
      editedDescription,
      mainImage,
      mainWideImage,
      additionalImage,
      additionalText,
      editedAdditionalTextValue
    } = socializeStore

    const styles = {
      cardStyle: {
        maxWidth: 500,
        margin: '1px 2px 25px',
        borderRadius: theme.paper.borderRadius,
        backgroundColor: 'transparent'
      },
      IconButton: {
        width: 25,
        height: 25,
        padding: '0'
      },
      Hint: {
        color: secondaryTextColor
      },
      textWrapper: {
        cursor: 'pointer'
      },
      socialIcons: {
        width: 24,
        height: 24
      },
      socialActionIcons: {
        width: 20,
        height: 20
      }
    }

    const linkedinUrlPrefix = rootStore.agency.linkedinPostUrlPrefix

    return (
      <>
        <div className="refari-socialise-preview-linkedin">
          <Card style={styles.cardStyle}>
            <div className="refari-socialise-editor-preview-heading">
              <div className="refari-socialise-editor-preview-heading-image">
                <img src={linkedin} alt="Linkedin" style={styles.socialIcons} />
              </div>
              <div className="refari-socialise-editor-preview-heading-title">
                Linkedin (Non-Clickable)
              </div>
            </div>
            <div className="refari-socialise-editor-top">
              <div className="refari-socialise-editor-top-inner">
                <div className="refari-socialise-editor-top-inner-image">
                  {mainImage && (
                    <Avatar
                      src={mainImage}
                      style={{ height: 50, width: 50 }}
                      size={50}
                    />
                  )}
                </div>
                <div className="refari-socialise-editor-top-inner-box">
                  <div className="refari-socialise-editor-top-inner-box-title">
                    {rootStore.agency.name}
                  </div>
                  <div className="refari-socialise-editor-top-inner-box-response">
                    Designation
                  </div>
                  <div className="refari-socialise-editor-top-inner-box-response">
                    now .{' '}
                    <PublicIcon style={{ width: 14, height: 14, margin: 0 }} />
                  </div>
                </div>
              </div>
              <div
                className="refari-socialise-editor-top-desc"
                style={{ whiteSpace: 'pre-line' }}
              >
                {linkedinUrlPrefix && (
                  <div style={{ marginBottom: '15px' }}>
                    {linkedinUrlPrefix ? `${linkedinUrlPrefix} ` : ''}
                    <span style={{ display: 'inline-block', color: '#0a66c2' }}>
                      https://lnkd.in/g-E_dGmV
                    </span>
                  </div>
                )}
                {socializeStore.description.value}
              </div>
              {socializeStore.tagString && (
                <div
                  className="refari-socialise-editor-top-desc"
                  style={{ color: '#0a66c2' }}
                >
                  {socializeStore.tagString}
                </div>
              )}
            </div>
            <CardMedia
              className="refari-card-image-holder"
              style={{ overflow: 'hidden', pointerEvents: 'none' }}
            >
              <CanvasCardImage
                ref="canvasImage"
                title={title.value}
                editedTitle={editedTitle.value}
                editedDescription={editedDescription.value}
                editedAdditionalTextValue={editedAdditionalTextValue}
                textColor={canvasCardStore.textColor}
                playIconColor={canvasCardStore.playIconColor}
                additionalText={additionalText}
                description={description.value}
                mainImage={mainImage}
                mainWideImage={mainWideImage}
                additionalImage={additionalImage}
                backgroundColor={primary1ColorSocialize}
                backgroundImage={canvasCardStore.selectedBackground}
                tintColor={canvasCardStore.tintColorData}
                opacity={canvasCardStore.opacity}
                socializeStore={socializeStore}
                rootStore={rootStore}
                canvasCardStore={canvasCardStore}
                uploadPercentage={uploadPercentage}
                isGIFRendering={isGIFRendering}
                setUploadPercentage={setUploadPercentage}
                customBackground={customBackground}
              />
            </CardMedia>
            <div className="refari-socialise-editor-bottom">
              <div className="refari-socialise-editor-bottom-title">
                {socializeStore.title.value}
              </div>
              <div className="refari-socialise-editor-bottom-info">
                refari.co<span>.</span>
                <span>1 min read</span>
              </div>
            </div>
            <div className="refari-socialise-editor-action">
              <Button
                startIcon={
                  <ThumbUpOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Like
              </Button>
              <Button
                startIcon={
                  <CommentOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Comment
              </Button>
              <Button
                startIcon={
                  <ShareOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Share
              </Button>
              <Button
                startIcon={
                  <SendOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Send
              </Button>
            </div>
          </Card>
        </div>

        {/* Facebook */}
        <div className="refari-socialise-preview-facebook">
          <Card style={styles.cardStyle}>
            <div className="refari-socialise-editor-preview-heading">
              <div className="refari-socialise-editor-preview-heading-image">
                <img src={facebook} alt="Facebook" style={styles.socialIcons} />
              </div>
              <div className="refari-socialise-editor-preview-heading-title">
                Facebook
              </div>
            </div>
            <div
              className="refari-socialise-editor-top"
              style={{ paddingBottom: 6 }}
            >
              <div className="refari-socialise-editor-top-inner">
                <div className="refari-socialise-editor-top-inner-image">
                  {mainImage && (
                    <Avatar
                      src={mainImage}
                      style={{ height: 40, width: 40 }}
                      size={40}
                    />
                  )}
                </div>
                <div className="refari-socialise-editor-top-inner-box">
                  <div className="refari-socialise-editor-top-inner-box-title">
                    {rootStore.agency.name}
                  </div>
                  <div className="refari-socialise-editor-top-inner-box-response">
                    now . <span>refari.co</span>
                    <GroupIcon style={{ width: 14, height: 14, margin: 0 }} />
                  </div>
                </div>
              </div>
              <div
                className="refari-socialise-editor-top-desc"
                style={{ whiteSpace: 'pre-line' }}
              >
                {socializeStore.description.value}
              </div>
              {socializeStore.tagString && (
                <div
                  className="refari-socialise-editor-top-desc"
                  style={{ color: '#0a66c2' }}
                >
                  {socializeStore.tagString}
                </div>
              )}
            </div>
            <CardMedia
              className="refari-card-image-holder"
              style={{ overflow: 'hidden', pointerEvents: 'none' }}
            >
              <CanvasCardImage
                title={title.value}
                editedTitle={editedTitle.value}
                editedDescription={editedDescription.value}
                editedAdditionalTextValue={editedAdditionalTextValue}
                textColor={canvasCardStore.textColor}
                playIconColor={canvasCardStore.playIconColor}
                additionalText={additionalText}
                description={description.value}
                mainImage={mainImage}
                mainWideImage={mainWideImage}
                additionalImage={additionalImage}
                backgroundColor={primary1ColorSocialize}
                backgroundImage={canvasCardStore.selectedBackground}
                tintColor={canvasCardStore.tintColorData}
                opacity={canvasCardStore.opacity}
                socializeStore={socializeStore}
                rootStore={rootStore}
                canvasCardStore={canvasCardStore}
                uploadPercentage={uploadPercentage}
                isGIFRendering={isGIFRendering}
                setUploadPercentage={setUploadPercentage}
                customBackground={customBackground}
              />
            </CardMedia>
            <div
              className="refari-socialise-editor-bottom"
              style={{ backgroundColor: '#f0f2f5' }}
            >
              <div className="refari-socialise-editor-bottom-domain">
                refari.co
              </div>
              <div className="refari-socialise-editor-bottom-title">
                {socializeStore.title.value}
              </div>
            </div>
            <div
              className="refari-socialise-editor-action"
              style={{ padding: 0, margin: 0 }}
            >
              <Button
                startIcon={
                  <ThumbUpOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Like
              </Button>
              <Button
                startIcon={
                  <CommentOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Comment
              </Button>
              <Button
                startIcon={
                  <ShareOutlinedIcon style={styles.socialActionIcons} />
                }
              >
                Share
              </Button>
            </div>
          </Card>
        </div>

        {/* Twitter */}
        <div className="refari-socialise-preview-twitter">
          <Card style={styles.cardStyle}>
            <div className="refari-socialise-editor-preview-heading">
              <div className="refari-socialise-editor-preview-heading-image">
                <img src={twitter} alt="Twitter" style={styles.socialIcons} />
              </div>
              <div className="refari-socialise-editor-preview-heading-title">
                X (formerly Twitter)
              </div>
            </div>
            <div className="refari-socialise-editor-panel">
              <div className="refari-socialise-editor-left-panel">
                {mainImage && (
                  <Avatar
                    src={mainImage}
                    style={{ height: 40, width: 40 }}
                    size={40}
                  />
                )}
              </div>
              <div className="refari-socialise-editor-right-panel">
                <div
                  className="refari-socialise-editor-top"
                  style={{ padding: '12px 10px 0 0' }}
                >
                  <div className="refari-socialise-editor-top-inner">
                    <div
                      className="refari-socialise-editor-top-inner-box"
                      style={{ padding: 0, paddingBottom: 5 }}
                    >
                      <div className="refari-socialise-editor-top-inner-box-title">
                        {rootStore.agency.name}
                        <span>
                          @
                          {rootStore.agency.name
                            .replace(/ /g, '')
                            .toLowerCase()}
                        </span>
                        <span>.</span>
                        <span>now</span>
                      </div>
                      {/* <div
                        className="refari-socialise-editor-top-inner-box-response"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {socializeStore.description.value}
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="refari-socialise-editor-top-desc"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {socializeStore.description.value}
                  </div>
                  {socializeStore.tagString && (
                    <div
                      className="refari-socialise-editor-top-desc"
                      style={{ color: '#0a66c2' }}
                    >
                      {socializeStore.tagString}
                    </div>
                  )}
                </div>
                <CardMedia
                  className="refari-card-image-holder"
                  style={{ overflow: 'hidden', pointerEvents: 'none' }}
                >
                  <CanvasCardImage
                    title={title.value}
                    editedTitle={editedTitle.value}
                    editedDescription={editedDescription.value}
                    editedAdditionalTextValue={editedAdditionalTextValue}
                    textColor={canvasCardStore.textColor}
                    playIconColor={canvasCardStore.playIconColor}
                    additionalText={additionalText}
                    description={description.value}
                    mainImage={mainImage}
                    mainWideImage={mainWideImage}
                    additionalImage={additionalImage}
                    backgroundColor={primary1ColorSocialize}
                    backgroundImage={canvasCardStore.selectedBackground}
                    tintColor={canvasCardStore.tintColorData}
                    opacity={canvasCardStore.opacity}
                    socializeStore={socializeStore}
                    rootStore={rootStore}
                    canvasCardStore={canvasCardStore}
                    uploadPercentage={uploadPercentage}
                    isGIFRendering={isGIFRendering}
                    setUploadPercentage={setUploadPercentage}
                    customBackground={customBackground}
                  />
                </CardMedia>
                <div
                  className="refari-socialise-editor-bottom"
                  style={{ backgroundColor: '#f0f2f5' }}
                >
                  <div className="refari-socialise-editor-bottom-title">
                    {socializeStore.title.value}
                  </div>
                  <div className="refari-socialise-editor-bottom-domain">
                    <LinkIcon style={{ width: 14, height: 14, margin: 0 }} />
                    <span>refari.co</span>
                  </div>
                </div>
                <div
                  className="refari-socialise-editor-action"
                  style={{ padding: 0, margin: 0 }}
                >
                  <IconButton>
                    <CommentOutlinedIcon style={styles.socialActionIcons} />
                  </IconButton>
                  <IconButton>
                    <RepeatIcon style={styles.socialActionIcons} />
                  </IconButton>
                  <IconButton>
                    <FavoriteBorderIcon style={styles.socialActionIcons} />
                  </IconButton>
                  <IconButton>
                    <LaunchRoundedIcon style={styles.socialActionIcons} />
                  </IconButton>
                  <IconButton>
                    <AssessmentOutlinedIcon style={styles.socialActionIcons} />
                  </IconButton>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </>
    )
  }
}

export default withTheme(PreviewSocialise)
