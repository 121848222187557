import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Loupe = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M457.602,54.355c-72.417-72.416-190.245-72.416-262.661,0c-35.081,35.079-54.399,81.721-54.399,131.331    c0,45.193,16.039,87.917,45.413,121.688l-22.119,22.119l-22.542-22.542c-2.47-2.47-5.821-3.858-9.314-3.858    c-3.493,0-6.844,1.388-9.314,3.858L17.055,412.563C6.057,423.559,0,438.18,0,453.733c0,15.552,6.057,30.174,17.053,41.17    c10.998,10.998,25.619,17.054,41.17,17.054c15.551,0,30.174-6.057,41.17-17.053l105.612-105.61c2.47-2.47,3.858-5.821,3.858-9.314    c0-3.493-1.388-6.844-3.858-9.314l-22.542-22.542l22.126-22.126c34.793,30.215,78.234,45.331,121.682,45.331    c47.561,0,95.123-18.104,131.331-54.311C492.68,281.938,512,235.298,512,185.688C512,136.075,492.682,89.434,457.602,54.355z     M80.765,476.275c-6.021,6.021-14.026,9.337-22.542,9.337c-8.515,0-16.521-3.317-22.542-9.338    c-6.02-6.02-9.337-14.026-9.337-22.54s3.317-16.521,9.338-22.542l58.934-58.934L139.7,417.34L80.765,476.275z M158.33,398.711    l-45.084-45.084l18.734-18.734l45.084,45.085L158.33,398.711z M438.973,298.388c-62.144,62.146-163.259,62.146-225.403,0    c-30.104-30.104-46.683-70.128-46.683-112.702c0-42.574,16.579-82.598,46.683-112.701c31.072-31.072,71.887-46.609,112.702-46.609    c40.814,0,81.63,15.535,112.702,46.609c30.104,30.103,46.683,70.128,46.683,112.701S469.077,268.284,438.973,298.388z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M417.234,94.721c-50.158-50.156-131.769-50.158-181.927,0c-50.156,50.158-50.156,131.769,0.001,181.927    c25.079,25.077,58.02,37.617,90.963,37.617s65.885-12.54,90.964-37.617v-0.001C467.391,226.491,467.391,144.879,417.234,94.721z     M398.605,258.02c-39.886,39.886-104.783,39.886-144.669,0.001c-39.886-39.886-39.886-104.784-0.001-144.67    c19.945-19.946,46.136-29.914,72.336-29.914c26.193,0,52.394,9.974,72.334,29.914C438.491,153.236,438.491,218.134,398.605,258.02    z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M375.321,136.636c-27.048-27.045-71.053-27.045-98.1,0c-5.144,5.144-5.144,13.484,0,18.63    c5.144,5.144,13.484,5.144,18.63,0c16.772-16.774,44.068-16.774,60.842,0c2.573,2.573,5.943,3.858,9.314,3.858    c3.371,0,6.743-1.286,9.314-3.858C380.465,150.122,380.465,141.781,375.321,136.636z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Loupe
Loupe.propTypes = propTypes
Loupe.defaultProps = defaultProps
