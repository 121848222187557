import { RefariDTOTypes } from '@refari-frontend/types'

import rootStore from 'src/stores/RootStore'
import { utils } from 'src/utils/helpers'
import Agency, { IAgencyModel } from './Agency'
import Consultant, { IConsultantModel } from './Consultant'
import Salary, { ISalaryModel } from './Salary'

type EnquiriesAPITypes =
  RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']['results'][0]

export type IEnquiriesModel = {
  id: EnquiriesAPITypes['id']
  agency: IAgencyModel
  created: EnquiriesAPITypes['created']
  candidateAd: {
    id: EnquiriesAPITypes['candidate_ad']['id']
    title: EnquiriesAPITypes['candidate_ad']['title']
    consultant: IConsultantModel
    locations: EnquiriesAPITypes['candidate_ad']['locations']
    skills: EnquiriesAPITypes['candidate_ad']['skills']
    salary?: ISalaryModel
  }
}

class Enquiries implements IEnquiriesModel {
  id: IEnquiriesModel['id']
  agency: IEnquiriesModel['agency']
  created: IEnquiriesModel['created']
  candidateAd: IEnquiriesModel['candidateAd']

  constructor(data: EnquiriesAPITypes) {
    this.id = data['id']
    this.agency = new Agency(data['agency'])
    this.created = utils.dateFormatter(
      data['created'],
      '/',
      false,
      rootStore.agency.currency
    )
    this.candidateAd = {
      id: data['candidate_ad']['id'],
      title: data['candidate_ad']['title'],
      consultant: new Consultant(data['candidate_ad']['consultant']),
      locations: data['candidate_ad']['locations'],
      skills: data['candidate_ad']['skills'],
      salary: data['candidate_ad']['salary']
        ? new Salary(data['candidate_ad']['salary'])
        : undefined
    }
  }
}

export default Enquiries
