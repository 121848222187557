/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const LinkedInIcon = (props) => (
  <SvgIcon
    {...props}
    style={{
      width: '16px',
      height: '16px',
      marginTop: '-4px',
      ...props.style
    }}
    viewBox="0 0 19 18"
  >
    <path
      transform="translate(-459 -419)"
      fill={props.style && props.style.color ? props.style.color : '#fff'}
      d="M477.84 430.04V437h-4.04v-6.5c0-1.63-.58-2.74-2.05-2.74-1.11 0-1.78.75-2.07 1.47-.1.26-.13.62-.13.99V437h-4.04s.05-11.01 0-12.15h4.04v1.73a.14.14 0 0 0-.03.04h.03v-.04a4 4 0 0 1 3.64-2.01c2.66 0 4.65 1.74 4.65 5.47zm-14.3-8.94c0 1.16-.87 2.1-2.28 2.1h-.03c-1.35 0-2.23-.94-2.23-2.1 0-1.19.9-2.1 2.29-2.1 1.38 0 2.23.91 2.25 2.1zm-4.3 3.75h4.04V437h-4.04z"
    />
  </SvgIcon>
)

export default LinkedInIcon
