import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  MenuItem,
  Select as MUISelect,
  FormHelperText,
  FormControl,
  InputLabel,
  IconButton,
  ListItemSecondaryAction
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Field from '../../../models/form/Field'
import greenTick from '../../../assets/images/green_tick.svg'
import redCross from '../../../assets/images/red_cross.svg'

@observer
class Select extends React.Component {
  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    label: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.array.isRequired,
      PropTypes.object.isRequired
    ]),
    className: PropTypes.string,
    optionValueKey: PropTypes.string,
    optionTextKey: PropTypes.string,
    emptyOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labelColor: PropTypes.string,
    renderSecondaryText: PropTypes.func,
    menuProps: PropTypes.object,
    autoWidth: PropTypes.bool,
    underlineStyle: PropTypes.bool,
    // noMargin: PropTypes.bool,
    style: PropTypes.object,
    labelStyle: PropTypes.object,
    menuItemStyle: PropTypes.object,
    multiple: PropTypes.bool,
    selectionRenderer: PropTypes.func,
    disabled: PropTypes.bool,
    showTicks: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseMove: PropTypes.func,
    maxHeight: PropTypes.number,
    floatingLabelFixed: PropTypes.bool,
    showDeleteIcon: PropTypes.bool,
    deleteOption: PropTypes.func,
    cb: PropTypes.func,
    inputProps: PropTypes.object,
    required: PropTypes.bool,
    id: PropTypes.string,
    IconComponent: PropTypes.elementType,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    className: '',
    optionValueKey: 'id',
    floatingLabelFixed: false,
    optionTextKey: 'name',
    emptyOption: 'Any',
    renderSecondaryText: (value) => value,
    menuProps: {},
    autoWidth: false,
    // noMargin: false,
    underlineStyle: false,
    menuItemStyle: {},
    multiple: false,
    showTicks: false,
    disabled: false,
    onMouseEnter: () => {
      // Intentionally empty
    },
    onMouseLeave: () => {
      // Intentionally empty
    },
    onMouseMove: () => {
      // Intentionally empty
    },
    deleteOption: () => {
      // Intentionally empty
    },
    maxHeight: 500,
    showDeleteIcon: false,
    cb: undefined,
    inputProps: {},
    required: false,
    id: '',
    IconComponent: null,
    requiredIndicator: ' *',
    placeholder: ''
  }

  constructor(props) {
    super(props)
    this.state = {
      isIconVisibile: false,
      open: false
    }
  }

  handleClose = () => {
    this.setState({ open: false, isIconVisibile: false })
  }

  handleOpen = () => {
    this.setState({ open: true, isIconVisibile: true })
  }

  onChange = (e) => {
    this.props.fieldData.update(e.target.value)
    this.props.cb && this.props.cb(e.target.value)
    if (this.props.autoClose) {
      this.handleClose()
    }
  }

  // see https://github.com/mui/material-ui/issues/11069#issuecomment-884163722
  displayPlaceHolder = (placeholder, options, optionValueKey) => (value) => {
    return (typeof value === 'number' ||
      (typeof value === 'string' && value?.length)) &&
      options.length > 0 ? (
      options.find((option) => option[optionValueKey] === value).name
    ) : (
      <span style={{ color: '#a2a2a2' }}>{placeholder}</span>
    )
  }

  render() {
    const {
      fieldData,
      label,
      className,
      options,
      floatingLabelFixed,
      optionValueKey,
      optionTextKey,
      emptyOption,
      secondaryTextKey,
      renderSecondaryText,
      menuProps,
      autoWidth,
      style,
      labelStyle,
      menuItemStyle,
      multiple,
      selectionRenderer,
      disabled,
      showTicks,
      onMouseEnter,
      onMouseLeave,
      onMouseMove,
      maxHeight,
      // noMargin,
      underlineStyle,
      showDeleteIcon,
      deleteOption,
      labelColor,
      removeDefaultDisableOptionStyle,
      inputProps,
      required,
      id,
      IconComponent,
      requiredIndicator,
      placeholder,
      inputVariant
    } = this.props

    const { isIconVisibile, open } = this.state

    const { serverErrors, clientErrors, isTouched, value, name } = fieldData
    const errors = serverErrors.length
      ? serverErrors
      : isTouched && clientErrors
    const showCustomTicks = !!(value && showTicks)

    const singleSelectValue = placeholder
      ? this.displayPlaceHolder(placeholder, options, optionValueKey)
      : null

    return (
      <div
        className={`refari-select-wrap ${
          showCustomTicks ? 'refari-select-wrap-tick ' : ''
        }${className || ''}`}
      >
        <FormControl
          error={errors && errors.length > 0}
          style={{ width: '100%' }}
        >
          {label && (
            <InputLabel
              id={id ? `${id}-${name}-input-label` : `${name}-label`}
              style={{ color: labelColor || '#0000008a' }}
              {...(floatingLabelFixed && { shrink: true })}
            >
              {`${label}${required ? requiredIndicator : ''}`}
            </InputLabel>
          )}
          <MUISelect
            labelId={id ? `${id}-${name}-select-label` : `${name}-label`}
            id={id || name}
            displayEmpty
            style={{
              ...style,
              ...labelStyle,
              paddingRight: showCustomTicks ? 30 : 0,
              maxHeight: maxHeight || 'inherit'
            }}
            value={value || (multiple ? [] : '')}
            onChange={this.onChange}
            disabled={disabled}
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            disableUnderline={underlineStyle}
            inputProps={{
              ...inputProps
            }}
            MenuProps={{
              ...menuProps,
              className: 'refari-select-box'
            }}
            renderValue={multiple ? selectionRenderer : singleSelectValue}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            autoWidth={autoWidth}
            multiple={multiple}
            variant={inputVariant}
            {...(IconComponent && { IconComponent })}
          >
            {emptyOption && (
              <MenuItem
                key={`${label}option`}
                value=""
                className={removeDefaultDisableOptionStyle ? '' : 'empty-value'}
              >
                {emptyOption}
              </MenuItem>
            )}
            {multiple
              ? options.map((option) => (
                  <MenuItem
                    style={menuItemStyle}
                    key={`${option[optionValueKey]}option`}
                    value={option[optionValueKey]}
                    className={
                      showDeleteIcon ? 'refari-select-with-delete-option' : ''
                    }
                  >
                    {option[optionTextKey]}
                    {showDeleteIcon && isIconVisibile ? (
                      <ListItemSecondaryAction variant="outlined">
                        <IconButton
                          className="refari-delete-option-button"
                          onClick={deleteOption(option[optionValueKey])}
                          style={{ padding: 4 }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteForeverIcon
                            style={{
                              width: 20,
                              height: 20,
                              color: '#FE5E5E'
                            }}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : null}
                  </MenuItem>
                ))
              : options.map((option) => (
                  <MenuItem
                    style={menuItemStyle}
                    key={`${option[optionValueKey]}option`}
                    value={option[optionValueKey]}
                    className={
                      showDeleteIcon ? 'refari-select-with-delete-option' : ''
                    }
                  >
                    {`${option[optionTextKey]}${
                      secondaryTextKey && option[secondaryTextKey]
                        ? renderSecondaryText(option[secondaryTextKey])
                        : ''
                    }`}
                    {showDeleteIcon && isIconVisibile ? (
                      <ListItemSecondaryAction variant="outlined">
                        <IconButton
                          className="refari-delete-option-button"
                          onClick={deleteOption(option[optionValueKey])}
                          style={{ padding: 4 }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteForeverIcon
                            style={{
                              width: 20,
                              height: 20,
                              color: '#FE5E5E'
                            }}
                          />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : null}
                  </MenuItem>
                ))}
          </MUISelect>
          {errors && errors.length > 0 && (
            <FormHelperText>{errors[0]}</FormHelperText>
          )}
        </FormControl>
        {showCustomTicks && (
          <div
            className={
              errors && errors.length > 0
                ? 'right-select-section-block right-select-section-block-error'
                : 'right-select-section-block'
            }
          >
            <span className="right-select-icon">
              <img
                src={errors && errors.length > 0 ? redCross : greenTick}
                alt="tick"
              />
            </span>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Select)
