/* eslint-disable no-const-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-else-return */
/* eslint-disable no-return-await */
/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/state-in-constructor */
import React from 'react'
import { Button, Slider, CircularProgress } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import GIFEncoder from 'gif-encoder-2'
import { parseGIF, decompressFrames } from 'gifuct-js'
import CircularProgressWithLabel from '../../shared/common/CircularProgressWithLabel'
import { encode64 } from '../../../utils/helpers'
import classNames from 'classnames'

export class CropperImg extends React.PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    expectedWidth: PropTypes.number,
    expectedHeight: PropTypes.number,
    aspectRatio: PropTypes.any,
    cropShape: PropTypes.string,
    showError: PropTypes.bool
  }

  static defaultProps = {
    width: 240,
    height: 240,
    maxWidth: 500,
    maxHeight: 240,
    aspectRatio: 1 / 1,
    cropShape: 'rect',
    expectedWidth: 140,
    expectedHeight: 140,
    showError: false
  }

  state = {
    image: this.props.image,
    isLoading: false,
    zoom: 1,
    crop: {
      x: 0,
      y: 0
    },
    rotation: 0,
    croppedAreaPixels: null,
    error: false,
    isUploaded: false,
    uploadPercentage: null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.image && nextProps.image !== this.props.image) {
      this.setState({ image: nextProps.image, isUploaded: false })
    }
  }

  rotateInc = () => {
    this.cropper.current.rotate(90)
  }

  rotateDec = () => {
    this.cropper.current.rotate(-90)
  }

  createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

  createDataURI = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result))
      reader.addEventListener('error', (error) => reject(error))
      reader.readAsDataURL(file)
    })

  getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180

  renderFrame = async (index) => {
    const { uploadPercentage } = this.state
    if (index >= this.loadedFrames.length) return true
    const frame = this.loadedFrames[index]
    const totalPercentage = uploadPercentage + 100 / this.loadedFrames.length
    if (totalPercentage < 100) {
      this.setState({ uploadPercentage: totalPercentage })
    }

    if (frame.delay && frame.delay > 0) {
      this.encoder.setDelay(frame.delay)
    }

    this.drawPatch(frame)

    // delay the next gif frame
    return new Promise((resolve) => {
      // delay the next gif frame
      setTimeout(() => {
        // update the frame index
        requestAnimationFrame(async () => {
          this.encoder.addFrame(this.ctx)
          await this.renderFrame(index + 1)
          resolve()
        })
      }, frame.delay || 0)
    })
  }

  drawPatch = (frame) => {
    const { dims } = frame
    const imageData = this.tempCtx.createImageData(dims.width, dims.height)
    const origData = this.tempCtx.getImageData(
      dims.left,
      dims.top,
      dims.width,
      dims.height
    ).data

    const arrPatch = new Array(dims.width * dims.height * 4)
    // Iterate through every pixel

    let i = 0
    const len = frame.pixels.length
    while (i < len) {
      if (frame.transparentIndex === frame.pixels[i]) {
        // use previous frame for transparent pixels
        arrPatch[i * 4 + 0] = origData[i * 4 + 0]
        arrPatch[i * 4 + 1] = origData[i * 4 + 1]
        arrPatch[i * 4 + 2] = origData[i * 4 + 2]
        arrPatch[i * 4 + 3] = origData[i * 4 + 3]
      } else {
        const color = frame.colorTable[frame.pixels[i]]
        arrPatch[i * 4 + 0] = color[0] // R value
        arrPatch[i * 4 + 1] = color[1] // G value
        arrPatch[i * 4 + 2] = color[2] // B value
        arrPatch[i * 4 + 3] = 255 // A value
      }
      i++
    }

    // set the patch data as an override
    imageData.data.set(arrPatch)

    // draw the patch back over the canvas
    this.tempCtx.putImageData(imageData, dims.left, dims.top)

    // modification values from <Cropper>
    const { rotation } = this.state
    const pixelCrop = this.state.croppedAreaPixels

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    this.canvas.width = this.safeArea
    this.canvas.height = this.safeArea

    this.ctx.translate(this.safeArea / 2, this.safeArea / 2)
    this.ctx.rotate(this.getRadianAngle(rotation))
    this.ctx.translate(-this.safeArea / 2, -this.safeArea / 2)

    this.ctx.drawImage(
      this.tempCanvas,
      this.safeArea / 2 - this.tempCanvas.width / 2,
      this.safeArea / 2 - this.tempCanvas.height / 2
    )
    const data = this.ctx.getImageData(0, 0, this.safeArea, this.safeArea)
    // set canvas width to final desired crop size - this will clear existing context
    this.canvas.width = pixelCrop.width
    this.canvas.height = pixelCrop.height

    this.ctx.putImageData(
      data,
      Math.round(
        0 - this.safeArea / 2 + this.tempCanvas.width / 2 - pixelCrop.x
      ),
      Math.round(
        0 - this.safeArea / 2 + this.tempCanvas.height / 2 - pixelCrop.y
      )
    )
  }

  getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    if (
      this.props.file &&
      this.props.file.name &&
      this.props.file.name.split(/[#?]/)[0].split('.').pop().trim() === 'gif'
    ) {
      this.setState({ uploadPercentage: 0 })
      const dataURI = await this.createDataURI(this.props.file)
      this.image = await this.createImage(dataURI)

      this.loadedFrames = await fetch(dataURI)
        .then((resp) => resp.arrayBuffer())
        .then((buff) => parseGIF(buff))
        .then((_gif) => decompressFrames(_gif, true))

      this.canvas = document.createElement('canvas')
      this.ctx = this.canvas.getContext('2d')
      this.tempCanvas = document.createElement('canvas')
      this.tempCtx = this.tempCanvas.getContext('2d')
      this.maxSize = Math.max(this.image.width, this.image.height)
      this.safeArea = 2 * ((this.maxSize / 2) * Math.sqrt(2))

      this.tempCanvas.width = this.image.width
      this.tempCanvas.height = this.image.height

      this.encoder = new GIFEncoder(
        pixelCrop.width,
        pixelCrop.height,
        'neuquant',
        true
      )
      this.encoder.setRepeat(0)
      this.encoder.start()

      await this.renderFrame(0)

      this.encoder.finish()
      const dataArray = this.encoder.out.data
      const dataUrl = `data:image/gif;base64,${encode64(dataArray)}`

      this.setState({ uploadPercentage: 100 })

      return Promise.resolve(dataUrl)
    } else {
      const image = await this.createImage(imageSrc)

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const maxSize = Math.max(image.width, image.height)
      const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

      // set each dimensions to double largest dimension to allow for a safe area for the
      // image to rotate in without being clipped by canvas context
      canvas.width = safeArea
      canvas.height = safeArea

      // translate canvas context to a central location on image to allow rotating around the center.
      ctx.translate(safeArea / 2, safeArea / 2)
      ctx.rotate(this.getRadianAngle(rotation))
      ctx.translate(-safeArea / 2, -safeArea / 2)

      // draw rotated image and store data.
      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      )
      const data = ctx.getImageData(0, 0, safeArea, safeArea)

      // set canvas width to final desired crop size - this will clear existing context
      canvas.width = pixelCrop.width
      canvas.height = pixelCrop.height

      // paste generated rotate image with correct offsets for x,y crop values.
      ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
      )

      // As Base64 string
      return canvas.toDataURL(
        this.props.file ? this.props.file.type : 'image/png'
      )
    }
  }

  handleCropper = async () => {
    try {
      const { image, rotation, croppedAreaPixels } = this.state
      this.setState({ isLoading: true })
      const croppedImage = await this.getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )

      fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            this.props.file ? this.props.file.name : 'image.png'
          )
          this.setState({ isLoading: false })
          this.props.handleCropper(file)
        })
    } catch (e) {
      console.error(e)
      this.setState({ isLoading: false })
    }
  }

  handleSliderChange = (e, newValue) => {
    this.cropper.cropper.scale(newValue)
  }

  setEditorRef = (editor) => {
    if (editor) {
      this.editor = editor
    }
  }

  setCrop = (data) => {
    this.setState({ crop: data })
  }

  setRotation = (data) => {
    this.setState({ rotation: data })
  }

  setZoom = (data) => {
    this.setState({ zoom: data })
  }

  checkWidthHeight = (width, height) => {
    this.setState({ isUploaded: true })
    const { expectedHeight, expectedWidth, showError } = this.props
    if (showError) {
      if (width < expectedWidth || height < expectedHeight) {
        this.setState({ error: true })
      } else {
        this.setState({ error: false })
      }
    }
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels })
  }

  handleCancel = () => {
    const { handleCancel } = this.props
    this.setState({ uploadPercentage: null })
    handleCancel()
  }

  render() {
    const {
      image,
      zoom,
      rotation,
      crop,
      error,
      isUploaded,
      isLoading,
      uploadPercentage
    } = this.state

    const {
      additionalText,
      width,
      height,
      maxWidth,
      maxHeight,
      aspectRatio,
      cropShape,
      verticalLayout
    } = this.props

    return (
      <div className="cropper-img-section">
        <div
          className={classNames({
            'cropper-img-section__wrap-vertical-layout': verticalLayout,
            'cropper-img-section__wrap': !verticalLayout
          })}
        >
          <div className="cropper-img-section__img-section">
            <div
              className={classNames({ 'margin-auto': verticalLayout })}
              style={{
                height,
                width,
                maxWidth,
                maxHeight,
                position: 'relative'
              }}
            >
              <Cropper
                image={image}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={aspectRatio}
                cropShape={cropShape}
                restrictPosition={false}
                onCropChange={this.setCrop}
                onRotationChange={this.setRotation}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.setZoom}
                onMediaLoaded={(mediaSize) => {
                  this.checkWidthHeight(
                    mediaSize.naturalWidth,
                    mediaSize.naturalHeight
                  )
                }}
              />
            </div>
            {isUploaded && (
              <div className="cropper-img-section__button-section">
                {error && (
                  <div className="warning-message">
                    <WarningIcon />
                    <span>
                      This image is a low quality image, this may come out
                      distorted
                    </span>
                  </div>
                )}
                <div style={{ width: '100%' }}>
                  <div>Zoom</div>
                  <Slider
                    value={zoom}
                    min={0.5}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoomObj) => this.setZoom(zoomObj)}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <div>Rotation</div>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    onChange={(e, rotationObj) => this.setRotation(rotationObj)}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className={classNames({
              'cropper-img-section__button-wrap-vertical-layout':
                verticalLayout,
              'cropper-img-section__button-wrap': !verticalLayout
            })}
          >
            {additionalText && (
              <div className="field-file-additional-text">{additionalText}</div>
            )}
            {isUploaded && (
              <div className="cropper-img-section__save-button">
                {isLoading ? (
                  uploadPercentage !== null ? (
                    <CircularProgressWithLabel
                      value={uploadPercentage}
                      text={
                        uploadPercentage !== 100
                          ? 'Rendering GIF...'
                          : 'Uploading to Server...'
                      }
                    />
                  ) : (
                    <div className="submit-btn" style={{ textAlign: 'center' }}>
                      <CircularProgress color="primary" />
                    </div>
                  )
                ) : (
                  <Button
                    variant="contained"
                    className="submit-btn"
                    color="primary"
                    component="label"
                    onClick={this.handleCropper}
                  >
                    Save
                  </Button>
                )}
              </div>
            )}
            {
              <div
                className={classNames('cropper-img-section__save-button', {
                  'margin-left-1': verticalLayout
                })}
              >
                <Button
                  variant="contained"
                  className="submit-btn"
                  style={{
                    backgroundColor: '#eceff1'
                  }}
                  component="label"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
