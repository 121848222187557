/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import CloseModalButton from '../../CloseModalButton'
import Field from '../../../../../models/form/Field'
import Select from '../../../formComponents/Select'
import SelectWithAutocomplete from '../../../formComponents/SelectWithAutocomplete'
import Modal from '../../Modal'
import SmartPitchColorSettingContent from './SmartPitchColorSettingContent'
import {
  getColorContrastRatio,
  hasMinimumColorContrast,
  MINIMUM_CONTRAST_RATIO
} from '../../../../../utils/helpers'

@inject('rootStore', 'pitchMeStore')
@observer
class SmartPitchSettingsModal extends React.Component {
  constructor(props) {
    super(props)
    this.selectedFontField = new Field({
      name: 'selected_font',
      value: {
        value: props.pitchMeStore.selectedFont
          ? props.pitchMeStore.selectedFont.value
          : props.pitchMeStore.siteFont,
        label: props.pitchMeStore.selectedFont
          ? props.pitchMeStore.selectedFont.value
          : props.pitchMeStore.siteFont
      }
    })
    this.selectedFontAlignment = new Field({
      name: 'selected_font_alignment',
      value: props.pitchMeStore.selectedFontAlignment
    })
  }

  componentDidMount() {
    this.loadFonts()
  }

  loadFonts = async () => {
    try {
      const { pitchMeStore } = this.props
      await pitchMeStore.loadFontList()
      pitchMeStore.selectFont(this.selectedFontField.value.value)
    } catch (e) {
      console.error(e)
    }
  }

  hasAmpleColorContrast() {
    const { textColor, backgroundColor } = this.props.pitchMeStore

    return hasMinimumColorContrast(
      getColorContrastRatio({
        backgroundColor: backgroundColor || '#ffffff',
        foregroundColor: textColor
      }),
      MINIMUM_CONTRAST_RATIO
    )
  }

  closeModal = () => {
    const { pitchMeStore } = this.props

    const {
      togglePitchMeEditModal,
      lastAcceptedBackgroundColor,
      syncStateAfterUpdate
    } = pitchMeStore

    // when user closes the modal without choosing correct colors
    if (!this.hasAmpleColorContrast()) {
      pitchMeStore.setData('backgroundColor', lastAcceptedBackgroundColor)
    }

    syncStateAfterUpdate()
    togglePitchMeEditModal()
  }

  handleChangeComplete = (color) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('textColor', color.hex)
    pitchMeStore.setData('titleTextColor', color.hex)
    pitchMeStore.setData('descriptionTextColor', color.hex)
    pitchMeStore.setData('helpTextColor', color.hex)
    pitchMeStore.setData('expertiseTextColor', color.hex)
    pitchMeStore.setData('jobTextColor', color.hex)
    pitchMeStore.setData('clientTextColor', color.hex)
    pitchMeStore.setData('closingTextColor', color.hex)
    pitchMeStore.setData('regardsTextColor', color.hex)
  }

  handleChangeBackgroundColor = (color) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('backgroundColor', color.hex)
  }

  onChangeFont = () => {
    const {
      pitchMeStore: { selectFont, selectEditedFont }
    } = this.props
    selectFont(this.selectedFontField.value.value)
    selectEditedFont(this.selectedFontField.value.value, 'title')
    selectEditedFont(this.selectedFontField.value.value, 'description')
    selectEditedFont(this.selectedFontField.value.value, 'help')
    selectEditedFont(this.selectedFontField.value.value, 'expertise')
    selectEditedFont(this.selectedFontField.value.value, 'job')
    selectEditedFont(this.selectedFontField.value.value, 'client')
    selectEditedFont(this.selectedFontField.value.value, 'closing')
    selectEditedFont(this.selectedFontField.value.value, 'regards')
  }

  onChangeFontAlignment = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData(
      'selectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'titleSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'descriptionSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'helpSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'expertiseSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'jobSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'clientSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'closingSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    pitchMeStore.setData(
      'regardsSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
  }

  toggleBold = (e) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('isBold', e.target.checked)
    pitchMeStore.setData('titleIsBold', e.target.checked)
    pitchMeStore.setData('descriptionIsBold', e.target.checked)
    pitchMeStore.setData('helpIsBold', e.target.checked)
    pitchMeStore.setData('expertiseIsBold', e.target.checked)
    pitchMeStore.setData('jobIsBold', e.target.checked)
    pitchMeStore.setData('clientIsBold', e.target.checked)
    pitchMeStore.setData('closingIsBold', e.target.checked)
    pitchMeStore.setData('regardsIsBold', e.target.checked)
  }

  toggleItalic = (e) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('isItalic', e.target.checked)
    pitchMeStore.setData('titleIsItalic', e.target.checked)
    pitchMeStore.setData('descriptionIsItalic', e.target.checked)
    pitchMeStore.setData('helpIsItalic', e.target.checked)
    pitchMeStore.setData('expertiseIsItalic', e.target.checked)
    pitchMeStore.setData('jobIsItalic', e.target.checked)
    pitchMeStore.setData('clientIsItalic', e.target.checked)
    pitchMeStore.setData('closingIsItalic', e.target.checked)
    pitchMeStore.setData('regardsIsItalic', e.target.checked)
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToBottom() {
    const parent = document.getElementById('primaryFontSettingsModal')
    const element = parent.querySelector('.refari-modal')

    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    })
  }

  render() {
    const {
      pitchMeStore: {
        setData,
        isOpenPitchMeEditModal,
        selectedFont,
        textColor,
        backgroundColor,
        isBold,
        isItalic,
        siteFont,
        fonts,
        fontAlignment
      },
      rootStore: { agency },
      theme
    } = this.props

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }
    const fontFamily = selectedFont ? selectedFont.value : siteFont

    const isSameColor = textColor === backgroundColor

    return (
      <Modal
        className="refari-w-modal-container"
        id="primaryFontSettingsModal"
        maxWidth="xs"
        open={isOpenPitchMeEditModal}
        onClose={!isSameColor ? this.closeModal : undefined}
        classes={{
          container: 'iframe-dialog'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          {!isSameColor && <CloseModalButton onClose={this.closeModal} />}
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Font Settings
          </h3>
          <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
            <div className="refari-font-selector" style={{ marginTop: 10 }}>
              <p>
                Fonts provided by{' '}
                <a
                  style={{ color: theme.palette.primary1Color }}
                  href="https://fonts.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Fonts
                </a>
              </p>
              <label
                style={{ fontSize: '15px', margin: '0 0 5px' }}
                className="refari-font-selector__label"
              >
                Example:{' '}
                <span style={{ fontFamily }}>
                  {selectedFont
                    ? agency.name
                    : `${agency.name} (current font applied on site)`}
                </span>
              </label>
              <SelectWithAutocomplete
                hint="Select font"
                options={fonts}
                fieldData={this.selectedFontField}
                cb={this.onChangeFont}
              />
              <div
                className="refari-font-selector__provider"
                style={{ fontSize: 12, marginTop: 5, marginBottom: 10 }}
              >
                <Select
                  fieldData={this.selectedFontAlignment}
                  options={fontAlignment}
                  emptyOption=""
                  optionValueKey="value"
                  optionTextKey="label"
                  label="Font alignment"
                  floatingLabelFixed
                  cb={this.onChangeFontAlignment}
                  menuProps={{
                    keepMounted: true,
                    disablePortal: true,
                    disableEnforceFocus: true,
                    disableAutoFocus: true
                  }}
                />
              </div>
            </div>
            <div
              className="refari-font-selector"
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              <label
                style={{ fontSize: '15px', margin: '0 0 15px' }}
                className="refari-font-selector__label"
              >
                Font style:
              </label>
              <div
                className="refari-font-style"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <FormControl>
                  <FormGroup aria-label="switch" row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isBold}
                          onChange={this.toggleBold}
                        />
                      }
                      label="Bold"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isItalic}
                          onChange={this.toggleItalic}
                        />
                      }
                      label="Italic"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <SmartPitchColorSettingContent
              hasBackgroundColorPicker
              setData={setData}
              textColor={textColor}
              backgroundColor={backgroundColor}
              onFontColorChange={this.handleChangeComplete}
              onBackgroundColorChange={this.handleChangeBackgroundColor}
              onSaveSettings={this.closeModal}
              scrollToBottom={this.scrollToBottom}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(SmartPitchSettingsModal)
