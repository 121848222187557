import { observable, action, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import { RefariDTOTypes } from '@refari-frontend/types'

import Logger from 'src/utils/Logger'
import API from 'src/utils/API'
import { ApiRoutes } from 'src/utils/Urls'
import alertMessages from 'src/constants/alertMessages'
import FilterCheck from 'src/components/shared/modals/filters/FilterCheck'
import type { APIResponse } from 'src/types/APITypes'
import Messages from 'src/models/Messages'
import { mapFormServerErrors } from 'src/utils/helpers'
import TalentAlertSubscriptionModel, {
  ITalentAlertSubscriptionModel
} from 'src/models/TalentAlertSubscription'

type LocationsFromAPI =
  RefariDTOTypes['/talent-alerts/locations/']['get']['responses']['200']['content']['application/json']
type WorktypesFromAPI =
  RefariDTOTypes['/talent-alerts/worktypes/']['get']['responses']['200']['content']['application/json']
type SkillsFromAPI =
  RefariDTOTypes['/talent-alerts/skills/']['get']['responses']['200']['content']['application/json']
type TalentAlertListResponseFromApi =
  RefariDTOTypes['/dashboard/hiring-manager/talent-alerts/subscriptions/']['get']['responses']['200']['content']['application/json']
type TalentAlertDetailsFromApi =
  RefariDTOTypes['/dashboard/hiring-manager/talent-alerts/subscriptions/{id}/']['get']['responses']['200']['content']['application/json']

type ListParams = {
  page?: number
}

type Pagination = {
  totalRecordCount: number
  currentPage: number
  totalPageCount: number
  pageSize: number
}

type SetPaginationParams = {
  totalRecordCount?: number
  currentPage?: number
  totalPageCount?: number
  pageSize?: number
}

type Filters = Partial<ListParams>

export type ITalentAlertStore = {
  isFetchingLocations: boolean
  locations: Array<FilterCheck>
  suggestionData: Record<string, any> | null
  isFetchingWorktypes: boolean
  worktypes: Array<FilterCheck>
  isFetchingSkills: boolean
  skills: Array<FilterCheck>
  suggestionMessageTemplates: Array<Record<string, any>>
  pagination: Pagination
  activeTalentAlertId: number
  talentAlertDetails: TalentAlertDetailsFromApi | null
  suggest: (
    form: Record<string, any>,
    filtersData: Record<string, any>
  ) => Promise<void>
  subscribe: (
    form: Record<string, any>,
    filtersData: Record<string, any>
  ) => Promise<void>
  fetchLocations: () => Promise<void>
  fetchSelectedLocations: (
    value: string,
    locationSelectedIds: string[]
  ) => Promise<void>
  isFetchingMessageTemplateDetails: boolean
  suggestionTemplateMessage: string
  selectedMessageTemplate: Record<string, any> | null
  isOpenSuggestionMessageModal: boolean
  isOpenMessageTemplateNameChangeModal: boolean
  suggestionTemplateMessageID: number | null
  isOpenSuggestionMessageDeleteModal: boolean
  emailPreview: Record<string, any> | null
  isOpenEmailPreviewModal: boolean
  isOpenCharacterModal: boolean
  angleBracketsWords: Array<string>
  isFetchingTalentAlerts: boolean
  talentAlerts: Array<ITalentAlertSubscriptionModel>
  shouldShowPagination: boolean
  isOpenTalentAlertDetailsDrawer: boolean
  isFetchingTalentAlertDetails: boolean
  templateNames: Array<string>
  setSuggestion: (id: number) => Promise<Record<string, any> | undefined>
  fetchWorktypes: () => Promise<void>
  fetchSelectedWorktypes: (
    value: string,
    workTypeSelectedIds: string[]
  ) => Promise<void>
  fetchSkills: () => Promise<void>
  fetchSelectedSkills: (
    value: string,
    skillSelectedIds: string[]
  ) => Promise<void>
  fetchSuggestionMessageTemplates: () => Promise<void>
  fetchMessageTemplateDetails: (id: number) => Promise<void>
  setSelectedMessageTemplate: (template: Record<string, any> | null) => void
  resetSelectedMessageTemplate: () => void
  setSuggestionTemplateMessage: (message: string) => void
  openSuggestionMessageModal: () => void
  closeSuggestionMessageModal: () => void
  submitSuggestionMessage: (form: Record<string, any>) => Promise<void>
  openMessageTemplateNameChangeModal: () => void
  closeMessageTemplateNameChangeModal: () => void
  updateMessageTemplate: (args: {
    form: Record<string, any>
    name: string
  }) => Promise<void>
  setSuggestionTemplateMessageID: (id: number | null) => void
  openSuggestionsMessageDeleteModal: () => void
  closeSuggestionsMessageDeleteModal: () => void
  deleteSuggestionMessage: (id: number) => Promise<void>
  previewEmail: (
    form: Record<string, any>,
    filtersData: Record<string, any>
  ) => Promise<void>
  closeEmailPreviewModal: () => void
  setEmailPreview: (emailPreview: Record<string, any> | null) => void
  openCharacterModal: () => void
  closeCharacterModal: () => void
  setAngleBracketsWords: (words: Array<string>) => void
  acceptSuggestion: (id: number, formData: Record<string, any>) => Promise<void>
  fetchTalentAlerts: (onSuccess?: () => void) => Promise<void>
  filterTalentAlerts: (
    filters: Filters,
    onSuccess?: () => void
  ) => Promise<void>
  setPaginationParams: (paginationParams: SetPaginationParams) => void
  resetTalentAlerts: () => void
  changeInterval: (id: string, param: Record<string, any>) => Promise<void>
  deleteTalentAlert: (requestParams: any, cb: () => void) => Promise<void>
  setActiveTalentAlertId: (id: number) => void
  openTalentAlertDetailsDrawer: () => void
  closeTalentAlertDetailsDrawer: () => void
  fetchTaletAlertDetails: () => Promise<void>
}

class TalentAlertStore implements ITalentAlertStore {
  @observable isFetchingLocations = false
  @observable locations: Array<FilterCheck> = []
  @observable suggestionData: Record<string, any> | null = null
  @observable isFetchingWorktypes = false
  @observable worktypes: Array<FilterCheck> = []
  @observable isFetchingSkills = false
  @observable skills: Array<FilterCheck> = []
  @observable
  suggestionMessageTemplates: ITalentAlertStore['suggestionMessageTemplates'] =
    []
  @observable suggestionTemplateMessage = ''
  @observable isFetchingMessageTemplateDetails = false
  @observable
  selectedMessageTemplate: ITalentAlertStore['selectedMessageTemplate'] = null
  @observable isOpenSuggestionMessageModal = false
  @observable isOpenMessageTemplateNameChangeModal = false
  @observable
  suggestionTemplateMessageID: ITalentAlertStore['suggestionTemplateMessageID'] =
    null
  @observable isOpenSuggestionMessageDeleteModal = false
  @observable emailPreview: ITalentAlertStore['emailPreview'] = null
  @observable isOpenEmailPreviewModal = false
  @observable isOpenCharacterModal = false
  @observable angleBracketsWords: ITalentAlertStore['angleBracketsWords'] = []
  @observable isFetchingTalentAlerts = false
  @observable talentAlerts: ITalentAlertStore['talentAlerts'] = []
  @observable pagination: ITalentAlertStore['pagination'] = {
    totalRecordCount: 0,
    currentPage: 0,
    totalPageCount: 0,
    pageSize: 0
  }
  @observable activeTalentAlertId = 0
  @observable isOpenTalentAlertDetailsDrawer = false
  @observable isFetchingTalentAlertDetails = false
  @observable talentAlertDetails: ITalentAlertStore['talentAlertDetails'] = null

  @action
  suggest: ITalentAlertStore['suggest'] = async (form, filtersData) => {
    form.pending(true)

    try {
      filtersData = {
        keywords: filtersData.query,
        locations: filtersData.location,
        worktypes: filtersData.worktype,
        skills: filtersData.skill
      }

      const data = {
        ...filtersData,
        ...form.data
      }

      await API.postData(ApiRoutes.talentAlert.suggestion.create, data)

      toast.success(alertMessages.successfulSuggestion(data.email))

      form.reset()
      form.pending(false)
    } catch (error: any) {
      Logger.error(error)
      form.pending(false)
    }
  }

  @action
  setSuggestion: ITalentAlertStore['setSuggestion'] = async (id) => {
    try {
      const response = await API.getData(
        ApiRoutes.talentAlert.suggestion.fetch(id)
      )

      console.log('setSuggestion api response', response)

      if (response.status === 200) {
        const data = {
          locations:
            response.data.locations &&
            response.data.locations.map((el: number) => el + ''),
          worktypes:
            response.data.worktypes &&
            response.data.worktypes.map((el: number) => el + ''),
          skills:
            response.data.skills &&
            response.data.skills.map((el: number) => el + ''),
          keywords: response.data.keywords?.split(','),
          interval: response.data.interval,
          is_accepted: response.data.is_accepted
        }
        runInAction(() => {
          this.suggestionData = data
        })

        return data
      }
    } catch (e) {
      console.log(e)
    }
  }

  @action
  subscribe: ITalentAlertStore['subscribe'] = async (form, filtersData) => {
    form.pending && form.pending(true)

    try {
      filtersData = {
        keywords: filtersData.query,
        locations: filtersData.location,
        worktypes: filtersData.worktype,
        skills: filtersData.skill
      }

      const data = {
        ...filtersData,
        ...form.data
      }

      await API.postData(ApiRoutes.talentAlert.subscription.create, data)

      toast.success(alertMessages.successfulSubscription)

      form.pending && form.pending(false)
    } catch (error: any) {
      Logger.error(error)

      form.pending && form.pending(false)
    }
  }

  @action
  fetchLocations: ITalentAlertStore['fetchLocations'] = async () => {
    try {
      this.isFetchingLocations = true

      const response: APIResponse<LocationsFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.locations
      )

      if (response.data === null) {
        throw new Error('No talent alert locations data received')
      }

      runInAction(() => {
        this.locations =
          // @ts-ignore - mismatched types but it works
          response.data?.map(
            (el: any) => new FilterCheck(el, response.data?.length ?? 0)
          ) ?? []
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingLocations = false
    }
  }

  @action
  fetchSelectedLocations: ITalentAlertStore['fetchSelectedLocations'] = async (
    value,
    locationSelectedIds
  ) => {
    try {
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''

      this.isFetchingLocations = true

      const response: APIResponse<LocationsFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.selectedLocations(filter)
      )

      if (response.data === null) {
        throw new Error('No talent alert locations data received')
      }

      runInAction(() => {
        if (this.locations.length === 0) {
          this.locations =
            // @ts-ignore - mismatched types but it works
            response.data?.map(
              (el: Record<string, any>) =>
                new FilterCheck(el, response.data?.length ?? 0)
            ) ?? []
        }

        // @ts-ignore - mismatched types but it works
        response.data?.forEach((selectedItem) => {
          this.locations.forEach((el) => {
            /**
             * backend is not sending the appropriate response with selected: true
             * So when filter is present toggle the selected item if it present in response
             * If filter is not present then toggle on the basis of selected:true
             *
             * above functionality part commented is now, since backend is responding with appropriate response
             */
            // const selected = !filter ? selectedItem.selected : true
            // if (el.key === +selectedItem.id && selected) {
            //   el.toggle()
            //   return
            // }
            // @ts-ignore - data is not present in talent alert locations
            if (el.children.data) {
              // @ts-ignore - data is not present in talent alert locations
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
        })
        /**
         * @note select location on the basis of locationSelectedIds
         */
        if (
          Array.isArray(locationSelectedIds) &&
          locationSelectedIds.length > 0
        ) {
          this.locations.forEach((location) => {
            if (
              locationSelectedIds.includes(location?.key + '') &&
              !location?.selected
            ) {
              location.toggle()
            }
            // @ts-ignore - child location is not present in talent alert locations
            const childrens = location?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  locationSelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingLocations = false
    }
  }

  @action
  fetchWorktypes: ITalentAlertStore['fetchWorktypes'] = async () => {
    try {
      this.isFetchingWorktypes = true

      const response: APIResponse<WorktypesFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.worktypes
      )

      if (response.data === null) {
        throw new Error('No talent alert worktypes data received')
      }

      runInAction(() => {
        this.worktypes =
          // @ts-ignore - mismatched types but it works
          response.data?.map(
            (el: Record<string, any>) =>
              // @ts-ignore - mismatched types but it works
              new FilterCheck(el, response.data.length ?? 0)
          ) ?? []
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingWorktypes = false
    }
  }

  @action
  fetchSelectedWorktypes: ITalentAlertStore['fetchSelectedWorktypes'] = async (
    value,
    workTypeSelectedIds
  ) => {
    try {
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''

      this.isFetchingWorktypes = true

      const response: APIResponse<WorktypesFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.selectedWorktypes(filter)
      )

      if (response.data === null) {
        throw new Error('No talent alert worktypes data received')
      }

      runInAction(() => {
        if (this.worktypes.length === 0)
          this.worktypes =
            // @ts-ignore - mismatched types but it works
            response.data?.map(
              (el: Record<string, any>) =>
                new FilterCheck(el, response.data?.length ?? 0)
            ) ?? []

        // @ts-ignore - mismatched types but it works
        response.data?.forEach((selectedItem) => {
          this.worktypes.forEach((el) => {
            /**
             * backend is not sending the appropriate response with selected: true
             * So when filter is present toggle the selected item if it present in response
             * If filter is not present then toggle on the basis of selected:true
             */
            const selected = !filter ? selectedItem.selected : true
            if (el.key === +selectedItem.id && selected) {
              el.toggle()
              return
            }

            // @ts-ignore - child worktypes is not present in talent alert worktypes
            if (el.children.data) {
              // @ts-ignore - child worktypes is not present in talent alert worktypes
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
        })
        if (
          Array.isArray(workTypeSelectedIds) &&
          workTypeSelectedIds.length > 0
        ) {
          this.worktypes.forEach((workType) => {
            if (
              workTypeSelectedIds.includes(workType?.key + '') &&
              !workType?.selected
            ) {
              workType.toggle()
            }

            // @ts-ignore - child worktypes is not present in talent alert worktypes
            const childrens = workType?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  workTypeSelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingWorktypes = false
    }
  }

  @action
  fetchSkills: ITalentAlertStore['fetchSkills'] = async () => {
    try {
      this.isFetchingSkills = true

      const response: APIResponse<SkillsFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.skills
      )

      if (response.data === null) {
        throw new Error('No talent alert skills data received')
      }

      runInAction(() => {
        this.skills =
          // @ts-ignore - mismatched types but it works
          response.data?.map(
            (el: Record<string, any>) =>
              // @ts-ignore - mismatched types but it works
              new FilterCheck(el, response.data.length ?? 0)
          ) ?? []
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingSkills = false
    }
  }

  @action
  fetchSelectedSkills: ITalentAlertStore['fetchSelectedSkills'] = async (
    value,
    skillSelectedIds
  ) => {
    try {
      const filter = Array.isArray(value)
        ? value.join('|')
        : value
        ? value.replace(/%2C/g, '|')
        : ''

      this.isFetchingSkills = true

      const response: APIResponse<SkillsFromAPI> = await API.getData(
        ApiRoutes.talentAlert.subscription.selectedSkills(filter)
      )

      if (response.data === null) {
        throw new Error('No talent alert skills data received')
      }

      runInAction(() => {
        if (this.skills.length === 0)
          // @ts-ignore - mismatched types but it works
          this.skills =
            // @ts-ignore - mismatched types but it works
            response.data?.map(
              (el: Record<string, any>) =>
                new FilterCheck(el, response.data?.length ?? 0)
            ) ?? []

        // @ts-ignore - mismatched types but it works
        response.data?.forEach((selectedItem) => {
          this.skills.forEach((el) => {
            /**
             * backend is not sending the appropriate response with selected: true
             * So when filter is present toggle the selected item if it present in response
             * If filter is not present then toggle on the basis of selected:true
             */
            const selected = !filter ? selectedItem.selected : true
            if (el.key === +selectedItem.id && selected) {
              el.toggle()
              return
            }

            // @ts-ignore - child worktypes is not present in talent alert worktypes
            if (el.children.data) {
              // @ts-ignore - child worktypes is not present in talent alert worktypes
              el.children.data.forEach((item) => {
                if (item.key === +selectedItem.id && selectedItem.selected) {
                  item.applyFilter()
                }
              })
            }
          })
        })
        if (Array.isArray(skillSelectedIds) && skillSelectedIds.length > 0) {
          this.skills.forEach((skill) => {
            if (
              skillSelectedIds.includes(skill?.key + '') &&
              !skill?.selected
            ) {
              skill.toggle()
            }

            // @ts-ignore - child skills is not present in talent alert worktypes
            const childrens = skill?.children?.data
            if (childrens && Array.isArray(childrens)) {
              childrens.forEach((child) => {
                if (
                  skillSelectedIds.includes(child?.key + '') &&
                  !child?.selected
                ) {
                  child.toggle()
                }
              })
            }
          })
        }
      })
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.isFetchingSkills = false
    }
  }

  fetchSuggestionMessageTemplates: ITalentAlertStore['fetchSuggestionMessageTemplates'] =
    async () => {
      try {
        const response = await API.getData(
          ApiRoutes.talentAlert.suggestion.readMessageTemplates
        )

        if (response && response.data) {
          const messages = response.data.map((item: any) => new Messages(item))

          runInAction(() => {
            this.suggestionMessageTemplates = [
              {
                id: 0,
                name: 'Load Message Template',
                objType: 'default'
              }
            ].concat(messages)
          })
        }
      } catch (error: any) {
        Logger.error(error)
      }
    }

  @action
  private setIsFetchingMessageTemplateDetails = (value: boolean) => {
    this.isFetchingMessageTemplateDetails = value
  }

  @action
  fetchMessageTemplateDetails: ITalentAlertStore['fetchMessageTemplateDetails'] =
    async (id) => {
      try {
        this.setIsFetchingMessageTemplateDetails(true)

        const response = await API.getData(
          ApiRoutes.talentAlert.suggestion.readMessageTemplateDetails(id)
        )

        if (response && response.data) {
          runInAction(() => {
            const template = new Messages(response.data)
            this.suggestionTemplateMessage = template.message
          })
        }
      } catch (error: any) {
        Logger.error(error)
      } finally {
        this.setIsFetchingMessageTemplateDetails(false)
      }
    }

  @action
  setSelectedMessageTemplate: ITalentAlertStore['setSelectedMessageTemplate'] =
    (template) => {
      this.selectedMessageTemplate = template
    }

  @action
  resetSelectedMessageTemplate: ITalentAlertStore['resetSelectedMessageTemplate'] =
    () => {
      this.selectedMessageTemplate = null
    }

  @action
  setSuggestionTemplateMessage: ITalentAlertStore['setSuggestionTemplateMessage'] =
    (message) => {
      this.suggestionTemplateMessage = message
    }

  @action
  openSuggestionMessageModal: ITalentAlertStore['openSuggestionMessageModal'] =
    () => {
      this.isOpenSuggestionMessageModal = true
    }

  @action
  closeSuggestionMessageModal: ITalentAlertStore['closeSuggestionMessageModal'] =
    () => {
      this.isOpenSuggestionMessageModal = false
    }

  @action
  submitSuggestionMessage: ITalentAlertStore['submitSuggestionMessage'] =
    async (form) => {
      form.pending(true)

      try {
        const payload = {
          name: form.data.name,
          message: form.data.message,
          obj_type: form.data.messageTemplateType
        }

        await API.postData(
          ApiRoutes.talentAlert.suggestion.createMessageTemplate,
          payload
        )

        toast.success(alertMessages.submissionMessages)
        this.fetchSuggestionMessageTemplates()

        runInAction(() => {
          this.isOpenSuggestionMessageModal = false
          this.suggestionTemplateMessage = ''
        })

        form.reset()
        form.pending(false)
      } catch (error: any) {
        Logger.error(error)

        form.pending(false)

        const { data } = error.response
        mapFormServerErrors(data, form.fields)
      }
    }

  @action
  openMessageTemplateNameChangeModal: ITalentAlertStore['openMessageTemplateNameChangeModal'] =
    () => {
      this.isOpenMessageTemplateNameChangeModal = true
    }

  @action
  closeMessageTemplateNameChangeModal: ITalentAlertStore['closeMessageTemplateNameChangeModal'] =
    () => {
      this.isOpenMessageTemplateNameChangeModal = false
    }

  @action
  updateMessageTemplate: ITalentAlertStore['updateMessageTemplate'] = async ({
    form,
    name
  }) => {
    form.pending(true)

    try {
      const payload = {
        name,
        message: form.data.message
      }

      const response = await API.patchData(
        ApiRoutes.talentAlert.suggestion.updateMessageTemplate(
          this.selectedMessageTemplate?.id
        ),
        payload
      )

      if (response.status === 200) {
        toast.success(alertMessages.updateMessageTemplateSuccess)

        this.closeMessageTemplateNameChangeModal()

        this.fetchSuggestionMessageTemplates()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error: any) {
      Logger.error(error)

      const { data } = error.response
      mapFormServerErrors(data, form.fields)

      toast.error(alertMessages.genericErrorMessage)
    } finally {
      form.pending(false)
    }
  }

  @action
  setSuggestionTemplateMessageID: ITalentAlertStore['setSuggestionTemplateMessageID'] =
    (id) => {
      this.suggestionTemplateMessageID = id
    }

  @action
  openSuggestionsMessageDeleteModal: ITalentAlertStore['openSuggestionsMessageDeleteModal'] =
    () => {
      this.isOpenSuggestionMessageDeleteModal = true
    }

  @action
  closeSuggestionsMessageDeleteModal: ITalentAlertStore['closeSuggestionsMessageDeleteModal'] =
    () => {
      this.isOpenSuggestionMessageDeleteModal = false
    }

  @action
  deleteSuggestionMessage: ITalentAlertStore['deleteSuggestionMessage'] =
    async (id) => {
      try {
        await API.deleteData(
          ApiRoutes.talentAlert.suggestion.deleteMessageTemplate(id)
        )

        toast.success(alertMessages.deleteSubmissionMessages)

        this.fetchSuggestionMessageTemplates()

        runInAction(() => {
          this.isOpenSuggestionMessageDeleteModal = false
          this.suggestionTemplateMessageID = null
        })
      } catch (error: any) {
        Logger.error(error)
        runInAction(() => {
          this.isOpenSuggestionMessageDeleteModal = false
          this.suggestionTemplateMessageID = null
        })
      }
    }

  @action
  previewEmail: ITalentAlertStore['previewEmail'] = async (
    form,
    filtersData
  ) => {
    try {
      filtersData = {
        keywords: filtersData.query,
        skills: filtersData.skill,
        locations: filtersData.location,
        worktypes: filtersData.worktype
      }
      const formData = { ...form.data }
      if (formData.message) {
        formData.message = formData.message.toString('html')
      }

      if (formData.email === '') {
        delete formData.email
      }

      const data = {
        ...filtersData,
        ...formData
      }
      const response = await API.postData(
        ApiRoutes.talentAlert.suggestion.emailPreview,
        data
      )
      runInAction(() => {
        this.emailPreview = response.data
        this.isOpenEmailPreviewModal = true
      })
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  closeEmailPreviewModal: ITalentAlertStore['closeEmailPreviewModal'] = () => {
    this.isOpenEmailPreviewModal = false
  }

  @action
  setEmailPreview: ITalentAlertStore['setEmailPreview'] = (emailPreview) => {
    this.emailPreview = emailPreview
  }

  @action
  openCharacterModal: ITalentAlertStore['openCharacterModal'] = () => {
    this.isOpenCharacterModal = true
  }

  @action
  closeCharacterModal: ITalentAlertStore['closeCharacterModal'] = () => {
    this.isOpenCharacterModal = false
  }

  @action
  setAngleBracketsWords: ITalentAlertStore['setAngleBracketsWords'] = (
    words
  ) => {
    this.angleBracketsWords = words
  }

  @action
  acceptSuggestion: ITalentAlertStore['acceptSuggestion'] = async (
    id,
    formData
  ) => {
    try {
      const data = {
        interval: this.suggestionData?.interval,
        keywords: formData.query,
        skills: formData.skill,
        locations: formData.location,
        worktypes: formData.worktype
      }
      const response = await API.postData(
        ApiRoutes.talentAlert.suggestion.edit(id),
        data
      )
      if (response.status === 200) return response.data
    } catch (e) {
      console.log(e)
    }
  }

  @action
  private setIsFetchingTalentAlerts = (value: boolean) => {
    this.isFetchingTalentAlerts = value
  }

  @computed
  private get getParams(): ListParams {
    const params: ListParams = {}

    if (this.pagination.currentPage) {
      params.page = this.pagination.currentPage
    }

    return params
  }

  @action
  fetchTalentAlerts: ITalentAlertStore['fetchTalentAlerts'] = async (
    onSuccess
  ) => {
    try {
      this.setIsFetchingTalentAlerts(true)

      const response: APIResponse<TalentAlertListResponseFromApi> =
        await API.getData(ApiRoutes.talentAlert.dashboard.list, this.getParams)

      if (!response.data) {
        throw new Error("FetchTalentAlerts API Response:: doesn't contain data")
      }

      const talentAlertsResponseArray = response.data.results

      if (response.data) {
        runInAction(() => {
          this.talentAlerts = talentAlertsResponseArray.map(
            (item) => new TalentAlertSubscriptionModel(item)
          )

          this.setPaginationParams({
            totalRecordCount: response.data?.count ?? 0,
            currentPage: response.data?.page ?? 0,
            totalPageCount: response.data?.page_count ?? 0,
            pageSize: response.data?.page_size ?? 0
          })

          onSuccess && onSuccess()
        })
      }
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.setIsFetchingTalentAlerts(false)
    }
  }

  @action
  filterTalentAlerts: ITalentAlertStore['filterTalentAlerts'] = async (
    filters,
    onSuccess
  ) => {
    if (
      typeof filters.page !== 'undefined' &&
      this.pagination.currentPage !== filters.page
    ) {
      this.pagination.currentPage = filters.page
    }

    this.fetchTalentAlerts(onSuccess)
  }

  @action
  setPaginationParams: ITalentAlertStore['setPaginationParams'] = ({
    totalRecordCount,
    currentPage,
    totalPageCount,
    pageSize
  }) => {
    if (totalRecordCount) {
      this.pagination.totalRecordCount = totalRecordCount
    }

    if (currentPage) {
      this.pagination.currentPage = currentPage
    }

    if (totalPageCount) {
      this.pagination.totalPageCount = totalPageCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalRecordCount > this.pagination.pageSize
  }

  @action
  resetTalentAlerts: ITalentAlertStore['resetTalentAlerts'] = () => {
    this.talentAlerts = []
  }

  @action
  changeInterval: ITalentAlertStore['changeInterval'] = async (id, param) => {
    try {
      const response = await API.patchData(
        ApiRoutes.talentAlert.dashboard.partialUpdate(id),
        param
      )

      if (response.status === 200) {
        toast.success(alertMessages.intervalChanged)
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error: any) {
      Logger.error(error)

      toast.error(alertMessages.genericErrorMessage)
    }
  }

  @action
  deleteTalentAlert: ITalentAlertStore['deleteTalentAlert'] = async (
    requestParams,
    cb
  ) => {
    try {
      await API.deleteData.apply(this, requestParams)

      if (this.talentAlerts.length === 1) {
        // this condition responsive to step back to page if all items was removed
        const currentPage = this.pagination.currentPage - 1 || 1
        this.setPaginationParams({ currentPage })
      }
      this.fetchTalentAlerts()
      cb && cb()
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  setActiveTalentAlertId: ITalentAlertStore['setActiveTalentAlertId'] = (
    id
  ) => {
    this.activeTalentAlertId = id
  }

  @action
  openTalentAlertDetailsDrawer: ITalentAlertStore['openTalentAlertDetailsDrawer'] =
    () => {
      this.isOpenTalentAlertDetailsDrawer = true
    }

  @action
  closeTalentAlertDetailsDrawer: ITalentAlertStore['closeTalentAlertDetailsDrawer'] =
    () => {
      this.isOpenTalentAlertDetailsDrawer = false
    }

  @action
  private setIsFetchingTalentAlertDetails = (value: boolean) => {
    this.isFetchingTalentAlertDetails = value
  }

  @action
  fetchTaletAlertDetails: ITalentAlertStore['fetchTaletAlertDetails'] =
    async () => {
      try {
        this.setIsFetchingTalentAlertDetails(true)

        const response = await API.getData(
          ApiRoutes.talentAlert.dashboard.read(this.activeTalentAlertId)
        )

        if (response && response.data) {
          runInAction(() => {
            this.talentAlertDetails = response.data
          })
        }
      } catch (error: any) {
        Logger.error(error)
      } finally {
        this.setIsFetchingTalentAlertDetails(false)
      }
    }

  @computed
  get templateNames(): Array<string> {
    return this.suggestionMessageTemplates.map((item) =>
      item.name.toLowerCase()
    )
  }
}

export default new TalentAlertStore()
