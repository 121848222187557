const getSelectedKeys = (
  selectedList: Array<any>,
  entireList: Array<any>
): Array<any> => {
  return selectedList.map((selectedItemLabel: any) => {
    const entireData: any = entireList.find(
      (item: any) => item.name === selectedItemLabel
    )

    if (entireData) {
      return entireData.id
    }

    return selectedItemLabel
  })
}

export default getSelectedKeys
