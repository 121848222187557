import axios from 'axios'

import Storage from './Storage'
import LocalStorage from './LocalStorage'
import { createUUID } from './helpers'

class AuthSession {
  static tokenKey = 'refariUserSession'

  static isTokenSet() {
    const authToken = Storage.get(AuthSession.tokenKey)

    return authToken && !!authToken.trim()
  }

  static setHeader() {
    if (AuthSession.isTokenSet()) {
      axios.defaults.headers.Authorization = `Token ${Storage.get(AuthSession.tokenKey)}`
    }
  }

  static setGlobal(tokenValue, tokenName) {
    Storage.setGlobalCookie(tokenName, tokenValue, createUUID())
  }

  static removeHeader() {
    axios.defaults.headers.Authorization = ''
  }

  static set(tokenValue) {
    Storage.set(AuthSession.tokenKey, tokenValue)
    LocalStorage.set(`${AuthSession.tokenKey}.get.id`, createUUID())
    AuthSession.setHeader()
  }

  static remove() {
    Storage.remove(AuthSession.tokenKey)
    LocalStorage.set(`${AuthSession.tokenKey}.get.id`, createUUID())
    AuthSession.removeHeader()
  }
}

export default AuthSession
