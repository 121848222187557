import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Button } from '@material-ui/core'
import Input from '../formComponents/Input'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import { validationErrors as errors } from '../../../utils/helpers'
import NonFieldError from '../formComponents/NonFieldError'
import PasswordHint from '../formComponents/PasswordHint'

@observer
class SignIn extends React.Component {
  static propTypes = {
    authStore: PropTypes.shape({
      signIn: PropTypes.func.isRequired,
    }),
    successRedirect: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      email: new Field({
        name: 'email',
        value: '',
        validators: [errors.required(), errors.isEmail()],
      }),
      password: new Field({
        name: 'password',
        value: '',
        // validators: [errors.required(), errors.password()],
        validators: [errors.required()],
      }),
    })
  }

  submit = (e) => {
    e.preventDefault()
    this.form.submit(this.props.authStore.signIn, this.props.successRedirect)
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <NonFieldError
          error={this.form.fields.nonFieldErrors.value}
        />
        <Input
          fieldData={this.form.fields.email}
          label="Email"
        />
        <Input
          fieldData={this.form.fields.password}
          label="Password"
          type="password"
        />
        <PasswordHint
          className={this.form.fields.password.isFocused ? 'expand' : 'collapse'}
          inputValue={this.form.fields.password.value}
          showTick={false}
        />
        <Button
          variant="contained"
          type="submit"
          className="submit-btn"
          fullWidth
          color="primary"
          disabled={!this.form.isValid || this.form.isPending}
        >
          Sign In
        </Button>
      </form>
    )
  }
}

export default SignIn
