import type FilterCheck from 'src/components/shared/modals/filters/FilterCheck'

export type Args = {
  list: Array<FilterCheck>
  searchTerm: string
}

type SearchResult = Array<FilterCheck>

function getSearchResults({ list, searchTerm }: Args): SearchResult {
  if (searchTerm === '') {
    return list
  }

  return list.filter((item) => {
    const itemLabel = item.label.toLowerCase()
    // @ts-ignore migrate FilterCheck to typescript
    const itemChildren = item.children?.data || []

    if (itemLabel.includes(searchTerm.toLowerCase())) {
      return true
    }

    for (const child of itemChildren) {
      if (child.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true
      }
    }

    return false
  })
}

export default getSearchResults
