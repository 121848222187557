import type FilterSingle from '../models/FilterSingle'
import FilterGroup from '../models/FilterGroup'
import type { ChangedFilter } from '../types'

type GetSelectedDataArgs = {
  data: Array<InstanceType<typeof FilterSingle>>
  alreadySelectedData: Array<string>
  changedFilter: ChangedFilter
}

const getSelectedData = ({
  data,
  alreadySelectedData,
  changedFilter
}: GetSelectedDataArgs): Array<string> => {
  let selectedData: Array<string> = []

  if (changedFilter.operation === 'add') {
    alreadySelectedData.push(changedFilter.filterLabel)

    selectedData = [...alreadySelectedData]
  } else {
    alreadySelectedData.splice(
      alreadySelectedData.indexOf(changedFilter.filterLabel),
      1
    )

    selectedData = [...alreadySelectedData]
  }

  data.forEach((datum) => {
    if (datum.children instanceof FilterGroup) {
      if (datum.children.data?.length > 0) {
        if (datum.children.isAllSelected) {
          datum.applyFilter()

          if (!selectedData.includes(datum.label)) {
            selectedData.push(datum.label)
          }

          return selectedData
        }

        if (datum.children.data.some((childDatum) => !childDatum.selected)) {
          const foundIndex = selectedData.findIndex(
            (label) => label === datum.label
          )

          if (foundIndex > -1) {
            selectedData.splice(foundIndex, 1)
          }

          datum.setStatus(false)
        }

        datum.children.data.forEach((childDatum) => {
          if (childDatum.selected) {
            selectedData.push(childDatum.label)
          }
        })
      }
    }
  })

  return selectedData
}

export default getSelectedData
