/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'
import CloseModalButton from '../CloseModalButton'
import Modal from '../Modal'

@inject('pitchMeStore')
@observer
class PitchMeWarningModal extends React.Component {
  static propTypes = {
    pitchMeStore: PropTypes.shape({
      warningPopup: PropTypes.bool.isRequired,
      toggleWarningPopup: PropTypes.func.isRequired
    })
  }

  toggleModal = () => {
    const { toggleWarningPopup } = this.props.pitchMeStore
    toggleWarningPopup()
  }

  render() {
    const {
      pitchMeStore: { warningPopup, creationPost },
      theme,
      handleOverwrite,
      handleNewCopy
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }
    return (
      <Modal
        className="refari-w-modal-container modal test-me-container"
        maxWidth="xs"
        open={warningPopup}
        onClose={this.toggleModal}
        classes={{
          container: 'iframe-dialog'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.toggleModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Warning
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 370,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <p className="refari-message-template-modal--content">
              This will save your template, do you want to overwrite the
              original or create a new copy
            </p>
            <div
              className="refari-align-items-center refari-space-around"
              style={{ justifyContent: 'space-around' }}
            >
              <Button
                variant="outlined"
                onClick={handleNewCopy}
                color="primary"
                disabled={creationPost}
              >
                New Copy
              </Button>
              <div style={{ display: 'flex' }}>
                <Button
                  variant="contained"
                  onClick={handleOverwrite}
                  color="primary"
                  disabled={creationPost}
                  style={{ marginRight: '2px' }}
                >
                  Overwrite
                </Button>
                {creationPost && (
                  <div>
                    <CircularProgress size={25} thickness={2} color="primary" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeWarningModal)
