import RootStore from 'src/stores/RootStore'
import { utils } from 'src/utils/helpers'
import { RefariDTOTypes } from '@refari-frontend/types'

type CandidateDetailSchema =
  RefariDTOTypes['/dashboard/candidates/{id}/']['get']['responses']['200']['content']['application/json']

export type ICandidateDetailModel = {
  id: CandidateDetailSchema['id']
  externalId: CandidateDetailSchema['external_id']
  firstName: CandidateDetailSchema['first_name']
  lastName: CandidateDetailSchema['last_name']
  email: CandidateDetailSchema['email']
  jobTitle: CandidateDetailSchema['job_title']
  phone: CandidateDetailSchema['phone']
  companyName: CandidateDetailSchema['company_name']
  linkedinProfileUrl: CandidateDetailSchema['linkedin_profile_url']
  createdAt: CandidateDetailSchema['created']
  modifiedAt: CandidateDetailSchema['modified']
  hasLiveAd: CandidateDetailSchema['has_live_ad']
  hasAd: CandidateDetailSchema['has_ad']
  getCandidateName: string
  getReferrerName: string
  getReferralSource: string
  dateTimeCreated: string
  dateTimeModified: string
  hasCandidateEvent: CandidateDetailSchema['has_candidate_event']
  referralCount: CandidateDetailSchema['referral_count']
  referralData: CandidateDetailSchema['referral_data']
  isActive: CandidateDetailSchema['is_active']
}

class CandidateDetailModel implements ICandidateDetailModel {
  id: ICandidateDetailModel['id']
  externalId: ICandidateDetailModel['externalId']
  firstName: ICandidateDetailModel['firstName']
  lastName: ICandidateDetailModel['lastName']
  email: ICandidateDetailModel['email']
  jobTitle: ICandidateDetailModel['jobTitle']
  phone: ICandidateDetailModel['phone']
  companyName: ICandidateDetailModel['companyName']
  linkedinProfileUrl: ICandidateDetailModel['linkedinProfileUrl']
  createdAt: ICandidateDetailModel['createdAt']
  modifiedAt: ICandidateDetailModel['modifiedAt']
  hasLiveAd: ICandidateDetailModel['hasLiveAd']
  hasAd: ICandidateDetailModel['hasAd']
  hasCandidateEvent: ICandidateDetailModel['hasCandidateEvent']
  referralCount: ICandidateDetailModel['referralCount']
  referralData: ICandidateDetailModel['referralData']
  isActive: ICandidateDetailModel['isActive']

  constructor(data: CandidateDetailSchema) {
    this.id = data.id
    this.externalId = data.external_id
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.email = data.email
    this.jobTitle = data.job_title
    this.phone = data.phone
    this.companyName = data.company_name
    this.linkedinProfileUrl = data.linkedin_profile_url
    this.createdAt = data.created
    this.modifiedAt = data.modified
    this.hasLiveAd = data.has_live_ad
    this.hasAd = data.has_ad
    this.hasCandidateEvent = data.has_candidate_event
    this.referralData = data.referral_data
    this.referralCount = data.referral_count
    this.isActive = data.is_active
  }

  get getCandidateName(): ICandidateDetailModel['getCandidateName'] {
    return `${this.firstName} ${this.lastName}`
  }

  get getReferrerName(): ICandidateDetailModel['getReferrerName'] {
    if (this.referralData) {
      return `${this.referralData.first_name} ${this.referralData.last_name}`
    } else {
      return ''
    }
  }

  get dateTimeCreated(): ICandidateDetailModel['dateTimeCreated'] {
    return `${utils.dateFormatter(
      this.createdAt,
      '/',
      false,
      RootStore.agency.currency
    )}, ${utils.timeFormater(this.createdAt, ':', RootStore.agency.currency)}`
  }

  get dateTimeModified(): ICandidateDetailModel['dateTimeModified'] {
    return `${utils.dateFormatter(
      this.modifiedAt,
      '/',
      false,
      RootStore.agency.currency
    )}, ${utils.timeFormater(this.modifiedAt, ':', RootStore.agency.currency)}`
  }

  get getReferralSource(): string {
    if (!this.referralData?.source) return ''
    switch (this.referralData.source) {
      case 'application':
        return 'Application - Job Referred and Application received'
      case 'agency':
        return 'Agency - Candidate referred via your website'
      case 'crm':
        return 'CRM - Imported from ATS/CRM'
      case 'platform':
        return 'Platform - Referral created in Refari dashboard'
      default:
        return ''
    }
  }
}

export default CandidateDetailModel
