/* eslint-disable import/no-cycle */
import { action, computed, observable } from 'mobx'
import FilterCheck from './FilterCheck'

export class FilterGroup {
  @observable.ref data = []
  @action
  resetAll = () => {
    Object.keys(this.data).forEach((item) => this.data[item].reset())
  }
  @action
  applyAll = () => {
    Object.keys(this.data).forEach((item) => this.data[item].applyFilter())
  }

  constructor({
    data, dataLength, count, parent
  }) {
    this.data = data.map((item) => new FilterCheck(item, dataLength, parent))
    this.count = count
  }

  @computed
  get isSelectedAll() {
    return this.data.every((item) => item.selected)
  }

  @computed
  get groupValue() {
    return this.data
      .reduce((init, next) => init.concat(next.value), [])
  }

  @computed
  get appliedFiltersInstances() {
    let tempArr = []
    this.data.forEach((item) => {
      if (item.parent && item.parent.selected) {
        // Do smth
      } else {
        if (item.selected) {
          tempArr = [...tempArr, item]
        }
        if (item.children instanceof FilterGroup) {
          tempArr = tempArr.concat(item.children.appliedFiltersInstances)
        }
      }
    })
    return tempArr
  }
}

export default FilterGroup
