/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Paper } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import ReportIcon from '@material-ui/icons/Report'

class ExpiredLink extends React.Component {
  render() {
    return (
      <div className="flex-center full-width">
        <Paper className="testimonials-feedback testimonials-feedback__thank-modal">
          <ReportIcon
            style={{ width: '130px', height: '70px', marginLeft: '-25px' }}
          />
          <p
            className="testimonials-feedback--header-content m-t-2"
            style={{ color: this.props.theme.palette.primary1Color }}
          >
            The Link has Expired
          </p>
        </Paper>
      </div>
    )
  }
}

export default withTheme(ExpiredLink)
