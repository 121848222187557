/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CandidateAdIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const CandidateAdIcon = forwardRef(
  (
    { iconColor, ...restProps }: CandidateAdIconProps,
    ref: React.ForwardedRef<SVGSVGElement>
  ): React.ReactElement => (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      ref={ref}
      {...restProps}
    >
      <defs>
        <clipPath id="candidateAdIcon">
          <path
            fill="#fff"
            stroke="#707070"
            d="M-2.063-.698h24v24h-24z"
            data-name="Rectangle 1415"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#candidateAdIcon)"
        data-name="candidates Ads"
        transform="translate(2.063 .698)"
      >
        <g data-name="Group 3001">
          <g fill={iconColor} data-name="Group 3000">
            <path
              d="M17.223-.698H5.863v4.622a.7.7 0 0 1-.7.7H.538v17.8a.879.879 0 0 0 .878.878h15.807a.879.879 0 0 0 .878-.878V.18a.879.879 0 0 0-.878-.878ZM4.978 14.761a4.043 4.043 0 0 1 2-3.488 2.873 2.873 0 1 1 4.084 0 4.043 4.043 0 0 1 2 3.488v.759a.7.7 0 0 1-.7.7H5.681a.7.7 0 0 1-.7-.7Zm9.086 5.152H3.982a.703.703 0 0 1 0-1.406h10.082a.703.703 0 0 1 0 1.406Z"
              data-name="Path 2441"
            />
            <path d="M4.457.296 1.532 3.221h2.925Z" data-name="Path 2442" />
            <path
              d="M11.661 14.757a2.639 2.639 0 0 0-2.636-2.636h-.006a2.639 2.639 0 0 0-2.636 2.636v.056h5.277v-.056Z"
              data-name="Path 2443"
            />
            <path
              d="M9.023 10.718a1.47 1.47 0 1 0-1.47-1.466 1.47 1.47 0 0 0 1.47 1.466Z"
              data-name="Path 2444"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
)

CandidateAdIcon.displayName = 'CandidateAdIcon'

export default CandidateAdIcon
