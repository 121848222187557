import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Switch } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('rootStore')
@inject('agenciesStore')
@observer
class Tools extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.rootStore.initTab(pathname)
  }

  onTabChange = (link) => {
    this.props.agenciesStore.progressNew()
    this.props.rootStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props

    return (
      <div className="settings">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div
            className={
              this.props.location.pathname.includes('question-config')
                ? 'container-fluid'
                : 'container'
            }
          >
            <div
              className={`settings-container ${
                this.props.location &&
                (this.props.location.pathname.includes('question-config') ||
                  this.props.location.pathname.includes('pitch-me'))
                  ? 'question-mapping-container'
                  : ''
              }`}
            >
              <Switch>
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.tools.children.EdmBuilder.path}`}
                  component={asyncComponent(() =>
                    import('./EdmBuilder/EdmBuilder')
                  )}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.tools.children.XmlFeeds.path}`}
                  component={asyncComponent(() =>
                    import('./XmlFeeds/XmlFeeds')
                  )}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.tools.children.QuestionMapping.path}`}
                  component={asyncComponent(() =>
                    import('./QuestionMapping/QuestionMapping')
                  )}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.tools.children.CreateJobAlert.path}`}
                  component={asyncComponent(() =>
                    import('./CreateJobAlert/JobAlert')
                  )}
                />
                <PrivateRoute
                    exact
                    path={`${match.url}${URLs.tools.children.CreateTalentAlert.path}`}
                    component={asyncComponent(() =>
                      import('./CreateTalentAlert')
                    )}
                  />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.tools.children.PitchMe.path}`}
                  component={asyncComponent(() =>
                    import('./PitchMe/PitchMePage')
                  )}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.PitchMePreview.path}`}
                  component={asyncComponent(() =>
                    import('./PitchMe/PitchMePreview')
                  )}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Tools)
