import { RefariDTOTypes } from '@refari-frontend/types'

type MediaTagsAPIType =
  RefariDTOTypes['/standalone/job-ads/{id}/media-tags/']['get']['responses']['200']['content']['application/json']['results'][0]

type VideoAPIType = NonNullable<MediaTagsAPIType['video']>

type Video = {
  id: VideoAPIType['id']
  url: VideoAPIType['url']
  provider: VideoAPIType['provider']
  externalId: VideoAPIType['external_id']
}

class VideoModel implements Video {
  id: Video['id']
  url: Video['url']
  provider: Video['provider']
  externalId: Video['externalId']

  constructor(data: VideoAPIType) {
    this.id = data.id
    this.url = data.url
    this.provider = data.provider
    this.externalId = data.external_id
  }
}

export type IMediaTagsModel = {
  id: MediaTagsAPIType['id']
  hashtag: MediaTagsAPIType['hashtag']
  image: MediaTagsAPIType['image']
  video?: Video
  isSelected: MediaTagsAPIType['is_selected']
  isSame: MediaTagsAPIType['is_same']
}

class MediaTagsModel implements IMediaTagsModel {
  id: IMediaTagsModel['id']
  hashtag: IMediaTagsModel['hashtag']
  image: IMediaTagsModel['image']
  video: IMediaTagsModel['video']
  isSelected: IMediaTagsModel['isSelected']
  isSame: IMediaTagsModel['isSame']

  constructor(data: MediaTagsAPIType) {
    this.id = data.id
    this.hashtag = data.hashtag
    this.image = data.image
    this.video = data.video ? new VideoModel(data.video) : undefined
    this.isSelected = data.is_selected
    this.isSame = data.is_same
  }
}

export default MediaTagsModel
