import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import FlatButton from '../../../shared/common/FlatButton'
import { pSBC } from '../../../../utils/helpers'

class SuccessfullSubscriptionModal extends React.Component {
  checkAll = () => {
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage('OPEN-JOB-BOARD', url)
  }
  openSignUp = () => {
    let url =
      window.location != window.parent.location
        ? document.referrer
        : document.location.href
    window.parent.postMessage('OPEN-SIGN-UP', url)
  }

  componentDidMount() {
    if (window.location !== window.parent.location) {
      // this component is inside an iframe
      document.body.style.backgroundColor = 'transparent'

      const captchaBadge = document.querySelector('.grecaptcha-badge')
      captchaBadge.style.display = 'none'
    }
  }
  render() {
    const { loggedIn, theme } = this.props
    const style = {
      title: { color: theme.palette.popupPrimary }
    }

    return (
      <div className="refari-modal-content">
        <h3 style={style.title} id="subSuccess">Successful subscription</h3>
        <h4 style={{ paddingBottom: 30 }}>
          <span>
            You have successfully subscribed for the Job Alert.
            <br />
            We will send you details of latest roles as they come onto the
            market.
          </span>
        </h4>
        {!loggedIn && (
          <div
            className="refari-button-raised refari-button-custom-create-account"
            onClick={this.openSignUp}
            // style={{
            //   backgroundColor: onHover
            //     ? pSBC(-0.2, this.props.theme.palette.popupPrimary)
            //     : this.props.theme.palette.popupPrimary,
            //   color: '#fff'
            // }}
            style={{
              backgroundColor: pSBC(-0.2, theme.palette.primary1Color),
              color: '#fff',
              padding: '10px',
              width: '100%',
              margin: '0 auto 15px auto',
              textAlign: 'center',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            //   onMouseEnter={this.onMouseHover}
            //   onMouseLeave={this.onMouseHover}
          >
            <div
              className="refari-button-custom-create-account-top-label"
              style={{
                textTransform: 'uppercase',
                padding: '8px',
                fontSize: '14px',
                letterSpacing: '0px',
                fontWeight: '500'
              }}
            >
              Create an account
            </div>
            <div
              className="refari-button-custom-create-account-bottom-label"
              style={{
                padding: '0 8px 8px 8px',
                fontSize: '13px',
                letterSpacing: '0px',
                fontWeight: 'normal'
              }}
            >
              To manage my job alerts in the future
            </div>
          </div>
        )}
        <div className="refari-justify-center">
          <FlatButton
            className="refari-button-flat"
            onClick={this.checkAll}
            color="primary"
          >
            TAKE ME BACK TO THE JOBS
          </FlatButton>
        </div>
      </div>
    )
  }
}

export default withTheme(SuccessfullSubscriptionModal)
