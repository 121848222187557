/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Dialog, Button } from '@material-ui/core'
import success from '../../../../assets/images/success.jpeg'

@inject('authTwoFactorStore')
@observer
class TwoFactorSuccessModal extends React.Component {
  static propTypes = {
    authTwoFactorStore: PropTypes.shape({
      toggleSuccessModal: PropTypes.func.isRequired,
      isOpenSuccessModal: PropTypes.bool.isRequired
    })
  }

  onClose = () => {
    const { authTwoFactorStore: { toggleSuccessModal } } = this.props
    toggleSuccessModal()
  }

  render() {
    const { authTwoFactorStore: { isOpenSuccessModal }, theme } = this.props

    return (
      <div className="question-mapping-section-answer-modal">
        <div className="question-mapping-section-answer-modal-box">
          <Dialog
            className="modal-container-without-left refari-modal"
            BackdropProps={{ className: 'modal-container-without-left-overlay' }}
            maxWidth="xs"
            fullWidth
            open={isOpenSuccessModal}
            onClose={this.onClose}
          >
            <div className="refari-modal-content">
              <div className="refari-placements-wrapper refari-phone-code-wrapper">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" style={{ color: theme.palette.primary1Color }}>Success!</h5>
                    <button type="button" onClick={this.onClose} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: '1rem 4rem 2rem' }}>
                    <div className="setting-cont">
                      <div className="align-center">
                        <img src={success} alt="Success" style={{ maxWidth: 150 }} />
                      </div>
                      <div className="align-center m-t-3">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.onClose}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTheme(TwoFactorSuccessModal)
