/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
import React from 'react'
import PropTypes from 'prop-types'
import {
  inject,
  observer,
} from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Button, Dialog } from '@material-ui/core'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import { validationErrors as errors } from '../../../utils/helpers'
import NonFieldError from '../formComponents/NonFieldError'
import Input from '../formComponents/Input'
import PasswordHint from '../formComponents/PasswordHint'
import routesUrls from '../../../router/routesUrls'

@inject('authStore')
@observer
class ResetPasswordModal extends React.Component {
  static propTypes = {
    authStore: PropTypes.shape({
      forgotPasswordConfirm: PropTypes.func.isRequired,
      resetPasswordData: PropTypes.object,
      isOpenResetPasswordModal: PropTypes.bool.isRequired
    }),
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      password1: new Field({
        name: 'new_password1',
        value: '',
        validators: [errors.required(), errors.password()],
      }),
      password2: new Field({
        name: 'new_password2',
        value: '',
        validators: [errors.required(), errors.password()],
      }),
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { history, authStore } = this.props
    authStore.forgotPasswordConfirm(this.form, authStore.resetPasswordData, () => {
      history.replace(routesUrls.home.link)
    })
  }

  render() {
    const { authStore, theme } = this.props

    if (!authStore.resetPasswordData) {
      return null
    }

    return (
      <Dialog
        open={authStore.isOpenResetPasswordModal}
        fullWidth
        className="modal modal-small"
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className="modal-content">
          <div className="auth-modal">
            <div className="centred-form refari-reset-password">
              <div className="align-center">
                <h2
                  style={{ color: theme.palette.primary1Color }}
                >
                  Welcome, {authStore.resetPasswordData.first_name}
                </h2>
                <h5>Your password does not meet the minimum password requirements.</h5>
                <p>
                  Please create new password.
                </p>
              </div>

              <form onSubmit={this.onSubmit}>
                <NonFieldError
                  error={this.form.fields.nonFieldErrors.value}
                />
                <Input
                  fieldData={this.form.fields.password1}
                  type="password"
                  label="New Password"
                />
                <PasswordHint
                  className={this.form.fields.password1.isFocused ? 'expand' : 'collapse'}
                  inputValue={this.form.fields.password1.value}
                  showTick={false}
                />
                <Input
                  fieldData={this.form.fields.password2}
                  type="password"
                  label="Confirm Password"
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={!this.form.isValid}
                  style={{ marginTop: 30 }}
                >
                  Change password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(ResetPasswordModal)
