import React from 'react'
import PropTypes from 'prop-types'

import qp from 'query-parse'
import {
  ApiRoutes,
  baseServerURLs,
} from '../../../utils/Urls'

const propTypes = {
  btnTitle: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const LinkedinLogin = ({
  btnTitle, clientId, className, callback
}) => {
  const login = () => {
    window.newWindow && window.newWindow.close()

    const linkedinApiAuth = 'https://www.linkedin.com/oauth/v2/authorization'
    const queries = qp.toString({
      response_type: 'code',
      client_id: clientId,
      state: Math.random().toString(36).substring(7),
      redirect_uri: ApiRoutes.auth.linkedinCallback,
      scope: 'r_liteprofile,r_emailaddress',
    })

    const title = 'Authorization vie LinkedIn'

    const options = qp.toString({
      width: 600,
      height: 400,
      left: 100,
      top: 100,
    }).split('&').join(',')

    window.newWindow = window.open(`${linkedinApiAuth}?${queries}`, title, options)

    window.addEventListener('message', receiveMessage, false)

    function receiveMessage(event) {
      if (`${event.origin}/` === baseServerURLs() && event.data) {
        callback(event.data.code)
        window.removeEventListener('message', receiveMessage, false)
        window.newWindow && window.newWindow.close()
        delete window.newWindow
      }
    }
  }

  return (
    <button
      className={className}
      onClick={login}
      type="button"
    >
      {btnTitle}
    </button>
  )
}


export default LinkedinLogin
LinkedinLogin.propTypes = propTypes
