import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import User from '../models/User'
import AuthSession from '../utils/AuthSession'
import RootStore from './RootStore'
import { mapFormServerErrors } from '../utils/helpers'
import routesUrls from '../router/routesUrls'
import alertMessages from '../constants/alertMessages'

export class InviteStore {
  @observable invitedUser
  @observable isOpenModal = false

  @action
  initAgencyInvite = async (key, cb) => {
    try {
      const response = await API.postData(ApiRoutes.auth.agencyUserInfo, { key })
      this.setData('invitedUser', new User(response.data))
    } catch (error) {
      Logger.error(error)
      if (error.status && error.status === 400 && error.data && error.data.key) {
        toast.error(error.data.key.toString())
        cb && cb()
      }
    }
  }

  @action
  initReferrerInvite = async (key, cb, noalert) => {
    try {
      const response = await API.postData(ApiRoutes.auth.referrerUserInfo, { key })
      this.setData('invitedUser', new User(response.data))
    } catch (error) {
      Logger.error(error)
      if (error.data.key) {
        if (!noalert) {
          toast.error(error.data.key.toString())
        }
        cb && cb()
      }
    }
  }

  @action
  initHiringManagerInvite = async (key, cb, noalert) => {
    try {
      const response = await API.postData(ApiRoutes.auth.hiringManagerUserInfo, { key })
      this.setData('invitedUser', new User(response.data))
    } catch (error) {
      Logger.error(error)
      if (error.data.key) {
        if (!noalert) {
          toast.error(error.data.key.toString())
        }
        cb && cb()
      }
    }
  }

  @action
  inviteAgencyCreate = async (form, redirect) => {
    try {
      if (!AuthSession.isTokenSet()) {
        const response = await API.postData(ApiRoutes.auth.agencyCreate, form.data)
        AuthSession.set(response.data.key)
      }

      RootStore.initialProfileRequest(() => {
        const redirectUrl = RootStore.user.isAgencyAdminUser
          ? routesUrls.inviteConsultants.link
          : routesUrls.dashboard.link

        redirect && redirect(redirectUrl)
      })

      toast.success(alertMessages.signin)
    } catch (error) {
      Logger.error(error)
      let data = null
      if (error.response) {
        data = error.response.data
      } else {
        data = error.data
      }
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  inviteReferrerCreate = async (form, redirect) => {
    try {
      if (!AuthSession.isTokenSet()) {
        const response = await API.postData(ApiRoutes.auth.referrerCreate, form.data)
        AuthSession.set(response.data.key)
      }

      RootStore.initialProfileRequest(() => {
        const redirectUrl = routesUrls.dashboard.link
        redirect && redirect(redirectUrl)
      })

      toast.success(alertMessages.signin)
    } catch (error) {
      Logger.error(error)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  inviteHiringManagerCreate = async (form, redirect) => {
    try {
      if (!AuthSession.isTokenSet()) {
        const response = await API.postData(ApiRoutes.auth.hiringManagerCreate, form.data)
        AuthSession.set(response.data.key)
      }

      RootStore.initialProfileRequest(() => {
        const redirectUrl = routesUrls.dashboard.link
        redirect && redirect(redirectUrl)
      })

      toast.success(alertMessages.signin)
    } catch (error) {
      Logger.error(error)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  inviteReferrerManually = async (form) => {
    try {
      form.pending(true)
      const res = await API.postData(ApiRoutes.dashboard.referrer.invite, form.data)
      if (res.response && res.response.status === 400) {
        const { data } = res.response
        mapFormServerErrors(data, form.fields)
      } else {
        runInAction(() => {
          this.isOpenModal = false
        })
        toast.success(alertMessages.inviteReferrer(form.data.invitee_email))
      }
    } catch (error) {
      Logger.error(error)
      form.pending(false)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  toggleModal = () => {
    this.isOpenModal = !this.isOpenModal
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new InviteStore()
