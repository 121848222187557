/* eslint-disable new-cap */
import { observable, action, computed, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import Logger from '../utils/Logger'
import SpinnersStore from './SpinnersStore'

export class ListStore {
  initSort = {
    param: '',
    direction: false
  }
  initPagination = {
    totalCount: 0,
    pageCount: 0,
    pageCurrent: 1,
    pageSize: 0,
    lastSync: '',
    isLastPage: false
  }

  executeCaptchaFn = null

  constructor(entityConfig = {}, paramsConfig = {}, extraOptions = {}) {
    this.entityConstructor = entityConfig.entityConstructor
    this.detailEntityConstructor = entityConfig.detailEntityConstructor
    this.additionalEntityData = entityConfig.additionalEntityData || {}
    this.url = entityConfig.url

    this.sort = paramsConfig.sort || this.initSort // use sort for first level, yet
    this.filters = paramsConfig.filters
    this.pageSize = paramsConfig.pageSize

    if (extraOptions.warningsMessageKey) {
      this.warningsMessageKey = extraOptions.warningsMessageKey
      // TODO: Move to candidates store
    }
    if (extraOptions.additionalFieldKey) {
      this.additionalFieldKey = extraOptions.additionalFieldKey
      // TODO: Move to candidates store
    }

    if (typeof extraOptions.executeCaptchaFn === 'function') {
      this.executeCaptchaFn = extraOptions.executeCaptchaFn
    }
  }

  @observable entityConstructor
  @observable additionalEntityData
  @observable url

  @observable sort
  @observable.ref filters = {}
  @observable pagination = this.initPagination

  @observable.ref list = []
  @observable detailData = null

  @observable isDetailsOpen = false
  @observable idOfActiveEntity

  @observable warningsMessage
  @observable additionalField
  @observable isFetching = true

  @observable additionalFilters = null
  @observable filtersToBeRemoved = []

  @action
  toggleDetails = (isOpen = !this.isDetailsOpen, id) => {
    this.isDetailsOpen = isOpen
    if (isOpen) {
      this.idOfActiveEntity = id
    } else {
      this.idOfActiveEntity = undefined
    }
  }

  @action
  closeDetails = () => {
    this.isDetailsOpen = false
    this.idOfActiveEntity = undefined
    this.detailData = null
  }

  @computed
  get activeEntityData() {
    return this.list.filter((item) => item.id === this.idOfActiveEntity)[0]
  }

  @action
  changeSortDirection = (forceBool) => {
    this.sort.direction = forceBool || !this.sort.direction
  }

  @action
  changeSortParam = (value) => {
    if (this.sort.param.includes(value)) {
      this.changeSortDirection()
    } else {
      this.changeSortDirection(true)
      this.sort.param = value
    }
    this.fetchListData()
  }

  @computed
  get getFullParamsData() {
    const sortParamDirectionParse = (sortParam) => {
      if (
        typeof sortParam === 'string' &&
        sortParam.charAt(0) === '-' &&
        sortParam.charAt(1) === '-'
      ) {
        return sortParam.substring(2)
      }
      return sortParam
    }

    let tempObj = {}
    if (this.filters) {
      tempObj = { ...this.filters.dataWithoutEmptyValue }
    }

    // Backend doesn't accept "toolbox=""
    if (!tempObj.toolbox) {
      delete tempObj.toolbox
    }

    tempObj.page = this.pagination.pageCurrent

    if (this.sort.param.indexOf(',') > -1) {
      const sortParam = this.sort.param.split(',')
      tempObj.ordering =
        (this.sort.direction ? '' : '-') +
        sortParam.join(this.sort.direction ? ',' : ',-')
    } else {
      tempObj.ordering = (this.sort.direction ? '' : '-') + this.sort.param
      tempObj.ordering = sortParamDirectionParse(tempObj.ordering)
    }

    if (this.pageSize) {
      tempObj.page_size = this.pageSize
    }

    if (this.additionalFilters) {
      Object.keys(this.additionalFilters).forEach((filterKey) => {
        tempObj[filterKey] = this.additionalFilters[filterKey]
      })
    }

    if (this.filtersToBeRemoved.length > 0) {
      this.filtersToBeRemoved.forEach((filterKey) => {
        delete tempObj[filterKey]
      })
    }

    return tempObj
  }

  @action
  fetchListData = async (
    { detailID = null, refresh = false } = {},
    callback
  ) => {
    this.setData('isFetching', true)

    try {
      let paramList = this.getFullParamsData
      if (refresh) {
        paramList = { ...paramList, force_calculate: true }
      }
      let headers = undefined
      if (this.executeCaptchaFn) {
        const token = await this.executeCaptchaFn()
        headers = {
          captcha: token
        }
      }

      const response = await API.getData(this.url, paramList, headers)

      if (!response.data) {
        console.error('Response data is empty')
      }

      this.setData(
        'list',
        response.data.results.map(
          (item) =>
            new this.entityConstructor(
              item,
              this.additionalEntityData,
              response.data.agency
            )
        )
      )

      this.setPaginateParams({
        totalCount: response.data.count,
        pageCount: response.data.page_count,
        pageCurrent: response.data.page,
        pageSize: response.data.page_size,
        lastSync: response.data.counts_last_sync,
        isLastPage: response.data?.is_last_page
      })

      if (response.data[this.warningsMessageKey]) {
        this.setData('warningsMessage', response.data[this.warningsMessageKey])
      }

      if (response.data[this.additionalFieldKey]) {
        this.setData('additionalField', response.data[this.additionalFieldKey])
      }

      if (detailID) {
        this.toggleDetails(true, detailID)
      }
      typeof callback === 'function' && callback()
      // window.scrollTo(0, 0)
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetching', false)
    }
  }

  @action
  fetchEntityDetails = async (url) => {
    try {
      let headers = undefined
      if (this.executeCaptchaFn) {
        const token = await this.executeCaptchaFn()
        headers = {
          captcha: token
        }
      }
      const response = await API.getData(
        url(this.idOfActiveEntity),
        undefined,
        headers
      )

      if (!response.data) {
        console.error('Response data is empty')
      }

      if (this.detailEntityConstructor) {
        runInAction(() => {
          this.detailData = new this.detailEntityConstructor(response.data)
        })
      } else {
        this.replaceItemInList(response.data)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchDetailPage = async (url, id) => {
    try {
      let headers = undefined
      if (this.executeCaptchaFn) {
        const token = await this.executeCaptchaFn()
        headers = {
          captcha: token
        }
      }
      const response = await API.getData(url(id), undefined, headers)
      if (response && response.data && response.data.id) {
        if (this.detailEntityConstructor) {
          return new this.detailEntityConstructor(response.data)
        }

        return new this.entityConstructor(response.data)
      }
      return null
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchEmailDetails = async (url) => {
    try {
      let headers = undefined
      if (this.executeCaptchaFn) {
        const token = await this.executeCaptchaFn()
        headers = {
          captcha: token
        }
      }
      const response = await API.getData(
        url(this.idOfActiveEntity),
        undefined,
        headers
      )
      this.replaceItemInList(response.data)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateEntityAction = async (
    requestParams,
    msg,
    loadingKey,
    updateOption = []
  ) => {
    const [updateTarget = 'list', detailUrl] = updateOption
    loadingKey && SpinnersStore.loadingIsStart(loadingKey)
    try {
      const response = await API.postData.apply(this, requestParams)
      toast.success(msg || response.data.detail)
      loadingKey && SpinnersStore.loadingIsEnd(loadingKey)

      switch (updateTarget) {
        case 'details':
          await this.fetchEntityDetails(detailUrl)
          break
        case 'list':
        default:
          await this.fetchListData()
          break
      }
    } catch (error) {
      Logger.error(error)
      loadingKey && SpinnersStore.loadingIsEnd(loadingKey)
    }
  }

  @action
  patchEntityAction = async (
    requestParams,
    msg,
    loadingKey,
    updateOption = []
  ) => {
    const [updateTarget = 'list', detailUrl] = updateOption
    loadingKey && SpinnersStore.loadingIsStart(loadingKey)
    try {
      const response = await API.patchData.apply(this, requestParams)
      if (msg || response.data.detail) {
        toast.success(msg || response.data.detail, { delay: 3000 })
      }
      loadingKey && SpinnersStore.loadingIsEnd(loadingKey)

      switch (updateTarget) {
        case 'list & details':
          await this.fetchListData()
          await this.fetchEntityDetails(detailUrl)
          break
        case 'details':
          await this.fetchEntityDetails(detailUrl)
          break
        case 'list':
        default:
          await this.fetchListData()
          break
      }
    } catch (error) {
      Logger.error(error)
      const data = requestParams[1]
      const errorMsg = Object.keys(data)
        .map((key) => error?.response?.data[key])
        .join(', ')
      errorMsg && toast.error(errorMsg)
      loadingKey && SpinnersStore.loadingIsEnd(loadingKey)
      return error?.response?.data || error.data
    }
  }

  @action
  deleteEntityAction = async (requestParams, cb) => {
    try {
      await API.deleteData.apply(this, requestParams)
      if (this.list.length === 1) {
        // this condition responsive to step back to page if all items was removed
        const pageCurrent = this.pagination.pageCurrent - 1 || 1
        this.setPaginateParams({ pageCurrent })
      }
      this.fetchListData()
      cb && cb()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  replaceItemInList = (data) => {
    this.list = this.list.map((item) => {
      if (item.id === this.idOfActiveEntity) {
        const { constructor } = Object.getPrototypeOf(item)

        return new constructor(data, this.additionalEntityData)
        // TODO: Optimize. Add inner method for updating data without replacing with new constructor
      }
      return item
    })
  }

  @action
  setFilters = () => {
    this.setInitPagination()
    this.fetchListData()
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }

  @action
  setToCustomConfig = (key, data) => {
    this.customConfig[key] = data
  }

  @action
  onPaginate = (event, pageCurrent) => {
    this.setPaginateParams({ pageCurrent })
    this.fetchListData()
  }

  @action
  setPaginateParams = ({
    pageCurrent,
    pageCount,
    totalCount,
    pageSize,
    lastSync,
    isLastPage
  }) => {
    if (pageCurrent) {
      this.pagination.pageCurrent = pageCurrent
    }

    if (pageCount) {
      this.pagination.pageCount = pageCount
    }

    if (totalCount) {
      this.pagination.totalCount = totalCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }

    if (lastSync) {
      this.pagination.lastSync = lastSync
    }

    if (isLastPage) {
      this.pagination.isLastPage = isLastPage
    }
  }

  @action
  setInitPagination = () => {
    this.pagination = this.initPagination
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalCount > this.pagination.pageSize
  }

  @action
  resetDetailData = () => {
    this.detailData = null
  }
}

export default ListStore
