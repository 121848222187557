import React, { Component } from 'react'
import { Avatar } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

import Select from 'src/components/shared/formComponents/Select'
import './styles.styl'

type Consultant = {
  id: number
  slug: string
  first_name: string
  last_name: string
  avatar?: {
    thumb: string
  }
}

type ConsultantInfo = {
  consultant: Consultant
  consultants: Array<Consultant>
}

type TestimonialsStore = {
  consultantInfo: ConsultantInfo
  setData: (key: string, value: any) => void
}

type TestimonialConsultantProps = {
  testimonialsStore: TestimonialsStore
  selectionField: Record<string, any>
}

class TestimonialConsultant extends Component<TestimonialConsultantProps> {
  constructor(props: TestimonialConsultantProps) {
    super(props)

    props.selectionField.update(
      props.testimonialsStore.consultantInfo.consultant.id
    )
  }

  getSelectOptions = () => {
    const { testimonialsStore } = this.props

    return testimonialsStore.consultantInfo.consultants.map((consultant) => ({
      id: consultant.id,
      value: consultant.id,
      name: `${consultant.first_name} ${consultant.last_name}`
    }))
  }

  updateSelectedConsultant = (selectedId: number) => {
    const { testimonialsStore } = this.props

    const updatedConsultantInfo = {
      ...testimonialsStore.consultantInfo,
      consultant: testimonialsStore.consultantInfo.consultants.filter(
        (consultant) => consultant.id === selectedId
      )[0]
    }

    testimonialsStore.setData('consultantInfo', updatedConsultantInfo)
  }

  render() {
    const { selectionField, testimonialsStore } = this.props

    const { consultantInfo } = testimonialsStore

    return (
      <>
        <Avatar src={consultantInfo.consultant.avatar?.thumb} />
        <div id="testimonialConsultantSelection">
          <Select
            optionValueKey="value"
            // @ts-ignore Need proper Field types
            fieldData={selectionField}
            options={this.getSelectOptions()}
            emptyOption={false}
            cb={this.updateSelectedConsultant}
          />
        </div>
      </>
    )
  }
}

export default TestimonialConsultant
