import { action, observable } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import alertMessages from '../constants/alertMessages'
import { mapFormServerErrors } from '../utils/helpers'
import { GoogleBusinessReviewAccount } from 'src/models/GoogleBusinessReviewAccount'

export class TopRecStore {
  @observable settings = null
  @observable googleBusinessAccountsList = []
  @observable isFetchingGoogleBusinessAccounts = false

  @action
  fetchSettings = async () => {
    try {
      const response = await API.getData(ApiRoutes.dashboard.toprec.settings)

      this.setData('settings', response.data)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateSettings = async (form) => {
    try {
      const data = { ...form.data }

      data.external_determination = {
        field_name: form.data.externalDeterminationName,
        value: form.data.externalDeterminationValue
      }

      const publish_social_nets = []

      for (const social of data.publish_social_nets) {
        if (social === 'linkedin') publish_social_nets.push(social)

        if (data[`${social}_review_url`]) {
          publish_social_nets.push(social)
        } else {
          delete data[`${social}_review_url`]
        }
      }

      data.publish_social_nets = publish_social_nets

      delete data.externalDeterminationName
      delete data.externalDeterminationValue

      const response = await API.putData(
        ApiRoutes.dashboard.toprec.settings,
        data
      )

      if (response && response.status === 200) {
        toast.success(alertMessages.agencySettingsUpdated)
      } else {
        mapFormServerErrors(response.data, form.fields)
      }
    } catch (error) {
      Logger.error(error)
      const { data } = error
      if (
        data.external_determination &&
        data.external_determination.field_name
      ) {
        data.externalDeterminationName = data.external_determination.field_name
      }
      if (data.external_determination && data.external_determination.value) {
        data.externalDeterminationValue = data.external_determination.value
      }
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  updateTopRecCRMStatus = async (list) => {
    try {
      const fetchdata = {}
      list.map((listData) => {
        fetchdata[listData.id] = listData.data
          .filter((v) => v.selected)
          .map((val) => val.name)
        return listData
      })
      const data = {}
      data.status_match = fetchdata
      const response = await API.putData(
        ApiRoutes.dashboard.toprec.settings,
        data
      )
      this.setData('settings', response.data)
      toast.success(alertMessages.triggerUpdated)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchLinkedGoogleBusinessAccounts = async () => {
    try {
      this.isFetchingGoogleBusinessAccounts = true
      const response = await API.getData(
        ApiRoutes.dashboard.toprec.googleBusinessAccounts
      )
      const listOfBusinessAccounts = response.data?.results || []
      this.setData(
        'googleBusinessAccountsList',
        listOfBusinessAccounts?.map((account) => {
          return new GoogleBusinessReviewAccount(account)
        })
      )
    } catch (error) {
      Logger.error(error)
    } finally {
      this.setData('isFetchingGoogleBusinessAccounts', false)
    }
  }

  @action
  getGoogleBusinessAuthLink = async () => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.toprec.googleBusinessAuthLink
      )
      console.log(response.data)
      return response.data?.link
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setUpdatedGoogleBusinessAccount = (accountId, data) => {
    const updatedGoogleBusinessAccounts = this.googleBusinessAccountsList.map(
      (account) => {
        if (account.id === accountId) {
          return new GoogleBusinessReviewAccount(data)
        }
        return account
      }
    )
    this.setData('googleBusinessAccountsList', updatedGoogleBusinessAccounts)
  }

  @action
  publishGoogleBusinessAccountTestimonials = async (accountId, needPublish) => {
    try {
      const response = await API.patchData(
        ApiRoutes.dashboard.toprec.googleBusinessAccountDetail(accountId),
        { need_publish: needPublish }
      )
      if (response.status === 200) {
        toast.success('Changes saved successfully')
        this.setUpdatedGoogleBusinessAccount(accountId, response.data)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setDefaultGoogleBusinessAccount = async (accountId, isEnabled) => {
    try {
      const response = await API.patchData(
        ApiRoutes.dashboard.toprec.googleBusinessAccountDetail(accountId),
        { is_primary: isEnabled }
      )
      if (response.status === 200) {
        toast.success('Changes saved successfully')
        this.fetchLinkedGoogleBusinessAccounts()
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  deleteGoogleBusinessAccount = async (accountId) => {
    try {
      const response = await API.deleteData(
        ApiRoutes.dashboard.toprec.googleBusinessAccountDetail(accountId),
        {}
      )
      if (response.status === 204) {
        toast.success('Google business account deleted successfully')
        this.fetchLinkedGoogleBusinessAccounts()
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  syncGoogleBusinessAccount = async (accountId) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.toprec.googleBusinessAccountSync(accountId)
      )
      if (response.status === 200) {
        toast.success('Google business account synced successfully')
        this.setUpdatedGoogleBusinessAccount(accountId, response.data)
      }
    } catch (error) {
      if (error?.data?.detail) toast.error(error.data.detail)
      Logger.error(error)
    }
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }
}

export default new TopRecStore()
