const wrapIframesWithDiv = (html: string): string => {
  const regex =
    /(?:youtube\.com\/embed\/|player.vimeo.com\/video\/|www.loom.com\/embed\/)([a-zA-Z0-9_-]+)/g
  const matches = html.match(regex)

  if (matches) {
    let wrappedHtml = html

    matches.forEach((match) => {
      const videoId = match.split('/').pop()

      const iframeRegex = new RegExp(
        `<iframe\\s[^>]*src="https://(?:www.youtube.com/embed|player.vimeo.com/video|www.loom.com/embed)/${videoId}[^"]*"\\s*[^>]*><\\/iframe>`,
        'g'
      )

      wrappedHtml = wrappedHtml.replace(
        iframeRegex,
        `<div class="video-responsive" id="${videoId}">$&</div>`
      )
    })

    return wrappedHtml
  }

  return html
}

export default wrapIframesWithDiv
