/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import qp from 'query-parse'
import { inject, observer } from 'mobx-react'
import { observable, runInAction } from 'mobx'
import routesUrls from '../../router/routesUrls'

@inject('rootStore')
@observer
class subscriptionRemove extends React.Component {
  @observable subscription = {}

  constructor(props) {
    super(props)

    props.rootStore.setData('isSubscriptionDelete', false)

    const subscriptionId = (qp.toObject(props.history.location.search.slice(1))).key

    if (!subscriptionId) {
      props.history.replace(routesUrls.error.link)
    }

    props.rootStore.fetchSubscription(subscriptionId, () => {
      props.history.replace(routesUrls.error.link)
    }).then((data) => {
      runInAction(() => {
        this.subscription = data
      })
    })
  }

  handleDeleteSubscription = () => {
    const { location, replace } = this.props.history
    const subscriptionId = (qp.toObject(location.search.slice(1))).key
    this.props.rootStore.deleteSubscription(subscriptionId, () => {
      replace(routesUrls.error.link)
    })
  }


  render() {
    const { theme, history, rootStore } = this.props
    const { isSubscriptionDelete } = rootStore
    const styles = {
      colorWhite: { color: theme.palette.whiteTextColor },
      backgroundPrimary: { background: theme.palette.primary1Color },
    }

    let query
    if (this.subscription.id) {
      query = this.subscription.query
        ? this.subscription.query
        : this.subscription.categories.length
          ? this.subscription.categories.map((item) => item.name).join(', ')
          : this.subscription.locations.length
            ? this.subscription.locations.map((item) => item.name).join(', ')
            : this.subscription.worktypes.map((item) => item.name).join(', ')
      query = query.substring(0, 200) + (query.length > 200 ? '...' : '')
    }

    return (
      <div
        style={styles.backgroundPrimary}
        className="subscription-remove flex"
      >
        <div className="container align-items-center">
          <div className="full-width flex center-xs">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <h1
                style={styles.colorWhite}
              >
                {
                  isSubscriptionDelete
                    ? 'Subscription has been removed'
                    : 'If you wish to stop receiving notification, please click button below to unsubscribe'
                }

              </h1>
              {
                query
                && <h5 style={{ marginBottom: 30, ...styles.colorWhite }}>
                  {
                    `Subscription on jobs with search parameters: "${query}"`
                  }
                   </h5>
              }
              <div className="flex center-xs">
                <div className="col-md-9 col-sm-6 col-xs-12">
                  {
                    !isSubscriptionDelete
                    && <Button
                      fullWidth
                      variant="contained"
                      style={{ color: theme.palette.primary1Color }}
                      onClick={this.handleDeleteSubscription}
                    >
                      Unsubscribe
                    </Button>
                  }
                  {
                    isSubscriptionDelete
                    && <Button
                      fullWidth
                      variant="contained"
                      style={{ color: theme.palette.primary1Color }}
                      onClick={() => {
                        history.push(routesUrls.home.link)
                      }}
                    >
                      Let's go home
                    </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(subscriptionRemove)
