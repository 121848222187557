import { observable, action, computed } from 'mobx'
import RootStore from './RootStore'
import permissions from '../constants/permissions'

export class WidgetsStore {
  @observable activeTab = ''

  @action
  initTab = (path) => {
    this.activeTab = path
  }

  @action
  onTabChange = (link, cb) => {
    cb && cb(link)
    this.activeTab = link
  }

  @computed
  get widgetsPermissions() {
    const { role, userType } = RootStore.user
    if (RootStore.loggedIn) {
      if (RootStore.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToWidgets.pages
      }

      if (role) {
        return permissions[userType][role].hasAccessToWidgets.pages
      }
      return permissions[userType].hasAccessToWidgets.pages
    }
    return []
  }
}

export default new WidgetsStore()
