/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from '../CloseModalButton'
import Field from '../../../../models/form/Field'
import Select from '../../formComponents/Select'
import SelectWithAutocomplete from '../../formComponents/SelectWithAutocomplete'
import Modal from '../Modal'
import SmartPitchColorSettingContent from './SmartPitchSettingsModal/SmartPitchColorSettingContent'

@inject('rootStore', 'pitchMeStore')
@observer
class PitchMeInputEditModal extends React.Component {
  static propTypes = {
    pitchMeStore: PropTypes.shape({
      editedInputType: PropTypes.string.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.selectedFontField = new Field({
      name: 'selected_font',
      value: {
        value: props.pitchMeStore[
          `${props.pitchMeStore.editedInputType}SelectedFont`
        ]
          ? props.pitchMeStore[
              `${props.pitchMeStore.editedInputType}SelectedFont`
            ].value
          : props.pitchMeStore.selectedFont
          ? props.pitchMeStore.selectedFont.value
          : props.pitchMeStore.siteFont,
        label: props.pitchMeStore[
          `${props.pitchMeStore.editedInputType}SelectedFont`
        ]
          ? props.pitchMeStore[
              `${props.pitchMeStore.editedInputType}SelectedFont`
            ].value
          : props.pitchMeStore.selectedFont
          ? props.pitchMeStore.selectedFont.value
          : props.pitchMeStore.siteFont
      }
    })
    this.selectedFontAlignment = new Field({
      name: 'selected_font_alignment',
      value:
        props.pitchMeStore[
          `${props.pitchMeStore.editedInputType}SelectedFontAlignment`
        ]
    })
    this.selectedFontSize = new Field({
      name: 'selected_font_size',
      value:
        props.pitchMeStore[
          `${props.pitchMeStore.editedInputType}SelectedFontSize`
        ]
    })
  }

  closeModal = () => {
    const {
      pitchMeStore: { toggleInputEditModal, setData, syncStateAfterUpdate }
    } = this.props
    toggleInputEditModal()
    setData('editedInputType', '')
    setData('isEditedUpdated', false)
    syncStateAfterUpdate()
  }

  handleChangeComplete = (color) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData(`${pitchMeStore.editedInputType}TextColor`, color.hex)
  }

  toggleBold = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData(
      `${pitchMeStore.editedInputType}IsBold`,
      !pitchMeStore[`${pitchMeStore.editedInputType}IsBold`]
    )
  }

  toggleItalic = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData(
      `${pitchMeStore.editedInputType}IsItalic`,
      !pitchMeStore[`${pitchMeStore.editedInputType}IsItalic`]
    )
  }

  onChangeFont = () => {
    const {
      pitchMeStore: { selectEditedFont, editedInputType }
    } = this.props
    selectEditedFont(this.selectedFontField.value.value, editedInputType)
  }

  onChangeFontAlignment = () => {
    const {
      pitchMeStore: { setData, editedInputType }
    } = this.props
    setData(
      `${editedInputType}SelectedFontAlignment`,
      this.selectedFontAlignment.value
    )
  }

  onChangeFontSize = () => {
    const {
      pitchMeStore: { setData, editedInputType }
    } = this.props
    setData(`${editedInputType}SelectedFontSize`, this.selectedFontSize.value)
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToBottom() {
    const parent = document.getElementById('sectionFontSettingsModal')
    const element = parent.querySelector('.refari-modal')

    element.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    })
  }

  render() {
    const {
      pitchMeStore: { isOpenInputEditModal, editedInputType },
      pitchMeStore,
      rootStore: { agency },
      theme
    } = this.props
    if (!editedInputType) {
      return null
    }

    /**
     * expertise
     * job
     * client
     * testimonials
     * closing
     * regards
     */
    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }
    const selectedFont =
      pitchMeStore[`${editedInputType}SelectedFont`] ||
      pitchMeStore.selectedFont
    const textColor =
      pitchMeStore[`${editedInputType}TextColor`] || pitchMeStore.textColor
    const { backgroundColor } = pitchMeStore
    const isBold = pitchMeStore[`${editedInputType}IsBold`] || false
    const isItalic = pitchMeStore[`${editedInputType}IsItalic`] || false
    const fontFamily = selectedFont ? selectedFont.value : pitchMeStore.siteFont
    let containerClass = 'iframe-dialog'
    if (
      editedInputType === 'expertise' ||
      editedInputType === 'job' ||
      editedInputType === 'client'
    ) {
      containerClass = 'iframe-dialog-center'
    }

    if (
      editedInputType === 'testimonials' ||
      editedInputType === 'closing' ||
      editedInputType === 'regards'
    ) {
      containerClass = 'iframe-dialog-end'
    }
    return (
      <Modal
        id="sectionFontSettingsModal"
        className="refari-w-modal-container"
        maxWidth="xs"
        open={isOpenInputEditModal}
        onClose={this.closeModal}
        classes={{
          container: containerClass
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Font Settings
          </h3>
          <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
            <div className="refari-font-selector" style={{ marginTop: 10 }}>
              <p>
                Fonts provided by{' '}
                <a
                  style={{ color: theme.palette.primary1Color }}
                  href="https://fonts.google.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Fonts
                </a>
              </p>
              <label
                style={{ fontSize: '15px', margin: '0 0 10px' }}
                className="refari-font-selector__label"
              >
                Example:{' '}
                <span style={{ fontFamily }}>
                  {selectedFont
                    ? agency.name
                    : `${agency.name} (current font applied on site)`}
                </span>
              </label>
              <SelectWithAutocomplete
                hint="Select font"
                options={pitchMeStore.fonts}
                fieldData={this.selectedFontField}
                cb={this.onChangeFont}
              />
              <div
                className="refari-font-selector__provider"
                style={{ marginTop: 5 }}
              >
                <Select
                  fieldData={this.selectedFontAlignment}
                  options={pitchMeStore.fontAlignment}
                  emptyOption=""
                  optionValueKey="value"
                  optionTextKey="label"
                  label="Font alignment"
                  floatingLabelFixed
                  cb={this.onChangeFontAlignment}
                  menuProps={{
                    keepMounted: true,
                    disablePortal: true,
                    disableEnforceFocus: true,
                    disableAutoFocus: true
                  }}
                />
              </div>
              <div
                className="refari-font-selector__provider"
                style={{ marginTop: 5 }}
              >
                <Select
                  fieldData={this.selectedFontSize}
                  options={pitchMeStore.fontSizeArray}
                  emptyOption=""
                  optionValueKey="value"
                  optionTextKey="label"
                  label="Font size"
                  floatingLabelFixed
                  cb={this.onChangeFontSize}
                  menuProps={{
                    keepMounted: true,
                    disablePortal: true,
                    disableEnforceFocus: true,
                    disableAutoFocus: true
                  }}
                />
              </div>
            </div>
            <div
              className="refari-font-selector"
              style={{ marginTop: 20, marginBottom: 10 }}
            >
              <label
                style={{ fontSize: '15px', margin: '0 0 15px' }}
                className="refari-font-selector__label"
              >
                Font style:
              </label>
              <div
                className="refari-font-style"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <FormControl>
                  <FormGroup aria-label="switch" row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isBold}
                          onChange={this.toggleBold}
                        />
                      }
                      label="Bold"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isItalic}
                          onChange={this.toggleItalic}
                        />
                      }
                      label="Italic"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
            <SmartPitchColorSettingContent
              textColor={textColor}
              backgroundColor={backgroundColor}
              onFontColorChange={this.handleChangeComplete}
              onBackgroundColorChange={this.handleChangeBackgroundColor}
              onSaveSettings={this.closeModal}
              scrollToBottom={this.scrollToBottom}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeInputEditModal)
