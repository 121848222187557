import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Portfolio = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 50 50" width="90px">
    <g>
      <path
        d="m1.00494 41.8916c0 1.91895 1.58594 3.48047 3.53613 3.48047h40.81543c2.00391 0 3.63379-1.60449 3.63379-3.57617l.00977-26.86963c0-1.91895-1.58594-3.48047-3.53613-3.48047h-10.72364v-3.81787c0-1.6543-1.3457-3-3-3h-13.48438c-1.6543 0-3 1.3457-3 3v3.81787h-10.7246c-1.92456 0-3.48877 1.53125-3.52051 3.42334-.00098.01868-.01074.03442-.01074.05322.0001.5592.00478 26.40992.00488 26.96924zm44.35156 1.48047h-40.81543c-.84668 0-1.53613-.66406-1.53613-1.48047v-17.21875c1.58057 1.85303 3.95361 3.03516 6.60449 3.03516h11.80859v.44189c0 1.6543 1.3457 3 3 3h1.16016c1.6543 0 3-1.3457 3-3v-.44189h11.80957c2.65308 0 5.02832-1.18408 6.60889-3.03979l-.00635 17.1272c0 .86913-.7334 1.57665-1.63379 1.57665zm-21.93848-15.22217v-2.88916c0-.55127.44824-1 1-1h1.16016c.55176 0 1 .44873 1 1v2.88916c0 .55127-.44824 1-1 1h-1.16016c-.55175 0-1-.44873-1-1zm-6.16211-20.52197c0-.55127.44824-1 1-1h13.48438c.55176 0 1 .44873 1 1v3.81787h-15.48438zm-12.7246 5.81787c4.67804.00141 38.60685.00008 40.93262 0 .84229 0 1.52783.65771 1.53491 1.46826 0 .00293-.00171.00537-.00171.0083v4.3457c0 3.55078-2.96484 6.43994-6.60938 6.43994h-11.80957v-.44727c0-1.6543-1.3457-3-3-3h-1.16016c-1.6543 0-3 1.3457-3 3v.44727h-11.80859c-3.61206 0-6.55225-2.83887-6.60449-6.34595v-4.43579c0-.8164.68457-1.48046 1.52637-1.48046z"
        fill={fill}
      />
    </g>
  </svg>
)


export default Portfolio
Portfolio.propTypes = propTypes
Portfolio.defaultProps = defaultProps
