import { observable, computed, action } from 'mobx'

import FilterGroup from './FilterGroup'
import type { Data, SavedData } from '../types'
import getKeyOfWhoseValuesAreArray from '../utils/getKeyOfWhoseValuesAreArray'

export type IFilterSingle = {
  key: number
  label: string
  selected: boolean
  isOpenMenu: boolean
  children: InstanceType<typeof FilterGroup> | []
  parent?: IFilterSingle
  value: Array<number>
  setStatus: (status: boolean) => void
  toggle: () => void
  reset: () => void
  applyFilter: () => void
  toggleOpenMenu: () => void
}

type ConstructorArgs = {
  data: Data
  dataLength: number
  parent?: IFilterSingle
  savedData?: Array<SavedData>
}

class FilterSingle implements IFilterSingle {
  key: IFilterSingle['key']
  @observable selected: IFilterSingle['selected']
  @observable isOpenMenu: IFilterSingle['isOpenMenu']
  label: IFilterSingle['label']
  children: IFilterSingle['children']
  parent?: IFilterSingle['parent']

  constructor({ data, dataLength, parent, savedData }: ConstructorArgs) {
    this.key = data.id
    this.selected = savedData
      ? savedData.some((datum) => datum.id === data.id)
      : false
    this.isOpenMenu = false
    this.label = data.name
    this.parent = parent

    const childKey = data.children && getKeyOfWhoseValuesAreArray(data.children)
    this.children =
      data.children && childKey && data.children[childKey].length > 0
        ? new FilterGroup({
            data: data.children[childKey] as Array<Data>,
            dataLength,
            parent: this
          })
        : []
  }

  @computed
  get value(): IFilterSingle['value'] {
    let keyList: Array<number> =
      this.children instanceof FilterGroup ? this.children.groupValue : []

    if (this.selected && !(this.children instanceof FilterGroup)) {
      keyList = [this.key]
    }

    if (this.children instanceof FilterGroup && this.children.isAllSelected) {
      keyList = [this.key, ...keyList]
    }

    if (this.children instanceof FilterGroup && !this.children.isAllSelected) {
      keyList = [...keyList]
    }

    return keyList
  }

  @action
  toggle: IFilterSingle['toggle'] = () => {
    this.selected = !this.selected

    if (this.children instanceof FilterGroup) {
      if (this.selected) {
        this.children.applyAll()
      } else {
        this.children.resetAll()
      }
    }
  }

  @action
  reset: IFilterSingle['reset'] = () => {
    if (this.selected) {
      this.selected = false
    }

    if (this.children instanceof FilterGroup) {
      this.children.resetAll()
    }
  }

  @action
  applyFilter: IFilterSingle['applyFilter'] = () => {
    this.selected = true
  }

  @action
  toggleOpenMenu: IFilterSingle['toggleOpenMenu'] = () => {
    this.isOpenMenu = !this.isOpenMenu
  }

  @action
  setStatus: IFilterSingle['setStatus'] = (status) => {
    this.selected = status
  }
}

export default FilterSingle
