import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object.isRequired,
}

const defaultProps = {
  className: '',
}

const ArrowIcon = ({ theme, className }) => (
  <SvgIcon
    className={className}
    style={{
      width: '11px',
      height: '18px',
    }}
    viewBox="0 0 11 18"
  >
    <path
      fill={theme.palette.textColor}
      transform="translate(-135 -157)"
      d="M146 159.25l-6.84 6.75 6.84 6.9-2.08 2.1-8.92-9 8.92-9z"
    />
  </SvgIcon>
)


export default withTheme(ArrowIcon)
ArrowIcon.propTypes = propTypes
ArrowIcon.defaultProps = defaultProps
