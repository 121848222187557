import { Worktypes } from '../../types/APITypes'

export type IWorktypesModel = {
  id: number
  value: string
  name: string
}

class WorktypesModel implements IWorktypesModel {
  id: IWorktypesModel['id']
  value: IWorktypesModel['value']
  name: IWorktypesModel['name']

  constructor(data: Worktypes) {
    this.id = data.id
    this.value = String(data.id)
    this.name = data.name
  }
}

export default WorktypesModel
