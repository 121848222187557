/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import qp from 'query-parse'
import credentials from '../../constants/credentials'
import { baseURLs } from '../Urls'

class FacebookShare {
  title = 'Share via Facebook'
  FacebookApiShare = 'https://www.facebook.com/dialog/share'
  fields = [
    'id',
    'first_name',
    'last_name',
    'middle_name',
    'name',
    'email',
    'link'
  ]
  scope =
    'pages_show_list,pages_manage_posts,public_profile,pages_read_engagement'
  options = qp
    .toString({
      width: 580,
      height: 400,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')

  process = (options) => {
    this.initSharing(options)
  }

  initSharing = ({ callback, onError, ssr_link, id, title }) => {
    if (!ssr_link) {
      onError()
    }

    let callbackIsApplied = false

    if (window.newShareFacebookWindow) {
      window.newShareFacebookWindow.stop()
      window.newShareFacebookWindow.popup.close()
    }

    const params = qp.toString({
      app_id: credentials.facebook.appId,
      href: ssr_link.replace('publications/', ''), // TODO: remove replacing
      redirect_uri: `${baseURLs('frontend')}?fb_shared_success=true`,
      error_message: 'fb_shared_success=false',
      method: 'page'
    })

    window.newShareFacebookWindow = {
      stop: this.stop,
      popup: window.open(
        `${this.FacebookApiShare}?${params}`,
        title || this.title,
        this.options
      )
    }

    const receiveMessage = (event) => {
      if (event.data && event.source === window.newShareFacebookWindow.popup) {
        window.removeEventListener('message', receiveMessage)
        const messageObj =
          typeof event.data === 'string' ? JSON.parse(event.data) : event.data
        if (messageObj.fb_shared_success) {
          callbackIsApplied = true
          this.stop()
          window.newShareFacebookWindow &&
            window.newShareFacebookWindow.popup.close()
          delete window.newShareFacebookWindow
          this.getProfileUrl(id, callback)
        } else {
          this.stop()
          onError()
        }
      }
    }

    window.addEventListener('message', receiveMessage, false)

    // check if popup is closed, call onError
    const check = () => {
      if (
        window.newShareFacebookWindow &&
        window.newShareFacebookWindow.popup.closed &&
        !callbackIsApplied
      ) {
        this.stop()
        window.removeEventListener('message', receiveMessage)
        onError && onError()
        return
      }
      this._timer = setTimeout(check, 1500)
    }
    this._timer = setTimeout(check, 1500)
  }

  getProfileUrl = (id, callback) => {
    const fetchMe = (accessToken) => {
      window.FB.api(
        '/me',
        'get',
        {
          access_token: accessToken,
          fields: this.fields
        },
        (response) => {
          callback({ id, link: response.link })
        }
      )
    }

    if (window.FB.getAccessToken()) {
      fetchMe(window.FB.getAccessToken())
      return
    }

    window.FB.login(
      (response) => {
        let token
        if (response.authResponse) {
          token = response.authResponse.accessToken
          fetchMe(token)
        } else {
          callback({ id, link: null })
        }
      },
      {
        scope: this.scope,
        auth_type: 'rerequest',
        return_scopes: true
      }
    )
  }

  stop() {
    clearTimeout(this._timer)
  }
}

export default new FacebookShare()
