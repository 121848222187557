/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { observable, action } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import { mapFormServerErrors } from '../utils/helpers'
import alertMessages from '../constants/alertMessages'

type ModalKeys =
  | 'success'
  | 'confirmation'
  | 'subscription'
  | 'switchagency'
  | 'jobadlink'
  | 'error'

type ModalOptions = {
  isOpen: boolean
  message?: string
  footerMessage?: string
  title?: string
  name?: string
  confirmBtnName?: string
  buttonName?: string
  type?: 'integration'
  cb?: () => void
}

type IUtilsStoreFields = {
  successKey: 'success'
  confirmationKey: 'confirmation'
  subscriptionKey: 'subscription'
  switchAgencyKey: 'switchagency'
  jobAdLinkKey: 'jobadlink'
  errorKey: 'error'
}

export type IUtilsStore = IUtilsStoreFields & {
  modals: Record<ModalKeys, ModalOptions>
  showFloatActionGroup: boolean
  toggleModal(key: ModalKeys, modalOptions: ModalOptions): void
  submitReport(id: number, form: any, cb: () => void): Promise<void>
  toggleFloatActionGroup(): void
  // TODO:: derive correct types for field values
  setData(key: keyof IUtilsStoreFields, value: any): void
}

export class UtilsStore implements IUtilsStore {
  successKey: IUtilsStore['successKey'] = 'success'
  confirmationKey: IUtilsStore['confirmationKey'] = 'confirmation'
  subscriptionKey: IUtilsStore['subscriptionKey'] = 'subscription'
  switchAgencyKey: IUtilsStore['switchAgencyKey'] = 'switchagency'
  jobAdLinkKey: IUtilsStore['jobAdLinkKey'] = 'jobadlink'
  errorKey: IUtilsStore['errorKey'] = 'error'

  initOptions: Record<ModalKeys, ModalOptions> = {
    [this.confirmationKey]: {
      isOpen: false,
      message: '',
      title: '',
      confirmBtnName: 'Confirm',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      cb: (): void => {}
    },
    [this.successKey]: {
      isOpen: false,
      message: ''
    },
    [this.subscriptionKey]: {
      isOpen: false
    },
    [this.switchAgencyKey]: {
      isOpen: false
    },
    [this.jobAdLinkKey]: {
      isOpen: false
    },
    [this.errorKey]: {
      isOpen: false
    }
  }

  @observable modals = {
    [this.successKey]: this.initOptions[this.successKey],
    [this.confirmationKey]: this.initOptions[this.confirmationKey],
    [this.subscriptionKey]: this.initOptions[this.subscriptionKey],
    [this.switchAgencyKey]: this.initOptions[this.switchAgencyKey],
    [this.jobAdLinkKey]: this.initOptions[this.jobAdLinkKey],
    [this.errorKey]: this.initOptions[this.errorKey]
  }

  @observable showFloatActionGroup = false

  @action.bound
  async resetPassword(id: number) {
    try {
      await API.postData(ApiRoutes.dashboard.consultants.resetPassword(id))
      toast.success('Password Reset Email Sent')
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound
  async resetPasswordSystemAdmin(id: number) {
    try {
      await API.postData(
        ApiRoutes.dashboard.systemAdmin.users.resetPassword(id)
      )
      toast.success('Password Reset Email Sent')
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound
  toggleModal(key: ModalKeys, modalObj: ModalOptions): void {
    if (modalObj) {
      this.modals[key] = modalObj
    } else {
      this.modals[key] = this.initOptions[key]
    }
  }

  @action.bound
  async submitReport(id: number, form: any, cb: () => void): Promise<void> {
    try {
      await API.postData(ApiRoutes.dashboard.referrers.report(id), form.data)
      toast.success(alertMessages.report)

      cb && cb()
    } catch (error: any) {
      Logger.error(error)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action.bound
  toggleFloatActionGroup(): void {
    this.showFloatActionGroup = !this.showFloatActionGroup
  }

  @action.bound
  setData(key: keyof IUtilsStoreFields, value: any): void {
    // @ts-ignore TODO:: fix this type mismatch
    this[key] = value
  }
}

export default new UtilsStore()
