/* eslint-disable import/no-cycle */
import { action, observable, computed } from 'mobx'
import CanvasCardStore from './CanvasCardStore'

export class CustomBackgroundStore {
  initialData = {}
  @observable isOpenModal
  @observable warningPopup = false
  @observable isUsedApplyText = true
  @observable isUsedreferAFriendText = true
  @observable pending = {
    actions: false
  }

  constructor() {
    this.isOpenModal = false
  }

  @computed
  get additionalText() {
    const output = []

    if (this.isUsedApplyText) {
      output.push('Apply')
    }

    if (this.isUsedreferAFriendText) {
      output.push('Refer a friend')
    }

    if (output.length === 0) {
      return ''
    }

    return `Click here to ${
      output.length === 2 ? output.join(' or ') : output[0]
    }`
  }

  @action.bound
  toggleModal() {
    this.isOpenModal = !this.isOpenModal
  }

  @action.bound
  toggleWarningPopup() {
    this.warningPopup = !this.warningPopup
  }

  toggle = (key) => () => {
    this.setData(key, !this[key])
  }

  @action.bound
  setInitalData(data) {
    this.initialData = data
    this.setPendingState({ actions: false })
    CanvasCardStore.reset()
  }

  @action.bound
  setPendingState(pendingState) {
    this.pending = {
      ...this.pending,
      ...pendingState
    }
  }

  @action.bound
  reset = () => {
    this.initialData = {}
    this.isUsedApplyText = true
    this.isUsedreferAFriendText = true
    this.setPendingState({ actions: false })
    CanvasCardStore.reset()
  }

  @action.bound
  setData(key, data) {
    this[key] = data
  }
}

export default new CustomBackgroundStore()
