/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */
import { computed, observable } from 'mobx'
import { Consultant } from './User'
import { withCurrencySign, numberFormatter } from '../utils/helpers'

export class MainJob {
  constructor(data, additionalData = {}) {
    if (!data) data = {}
    this.id = data.id
    this.title = data.title
    this.slug = data.slug
    this.summary = data.summary
    this.video_thumbs = data.video_thumbs || []
    this.salary = new Salary(data.salary)
    this.company = data.company
    this.is_featured = data.is_featured
    this.is_agency_featured = data.is_agency_featured
    this.agency = data.agency
    this.agencyRewardFee = data.agency_reward_fee
    this.is_internal = data.is_internal || false
    this.consultant = new Consultant(data.consultant || additionalData)
    this.category = new Category(data.category)
    this.location = data.location
    this.workType = data.worktype
    this.postedAt = data.posted_at
    this.description = data.description
    this.isApplied = data.current_user_already_applied
    this.isActive = data.is_active
    this.hashtag = data.hashtag
    this.tags = data.tags || []
    this.needQuestionnaire = data.need_questionnaire
    this.reward = new Currency(data.agency_reward_fee, 'amount')
  }
}

export default MainJob

export class Salary {
  period
  rateMin
  rateMax

  constructor(data) {
    if (!data) data = {}
    this.period = data.period
    this.rateMin = data.rate_min || {}
    this.rateMax = data.rate_max || {}
  }

  @computed
  get currencySign() {
    return this.rateMin.currency_sign || {}
  }

  @computed
  get minAmount() {
    return this.rateMin.amount && this.rateMin.amount.toFixed(2)
  }

  @computed
  get maxAmount() {
    return this.rateMax.amount && this.rateMax.amount.toFixed(2)
  }
}

export class Category {
  @observable id
  @observable name_obj_id
  @observable name
  @observable parent

  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.name_obj_id = data.name_obj_id || 0
    this.name = data.name
    this.parent = data.parent || {}
  }

  @computed
  get breadcrumbs() {
    const initParams = {
      page: 1,
      search: ''
    }

    let data = [
      {
        params: {
          category: this.id.toString(),
          ...initParams
        },
        name: this.name
      }
    ]

    if (this.parent.id) {
      data = [
        {
          params: {
            category: this.parent.id.toString(),
            ...initParams
          },
          name: this.parent.name
        },
        ...data
      ]
    }
    return data
  }
}

export class Currency {
  @observable value
  constructor(data, keyValue) {
    if (!data) data = {}
    this.value = data.value || data[keyValue] || 0
    this.currency = data.currency || ''
    this.currencySign = data.currency_sign || {}
  }

  @computed
  get formattedCurrency() {
    return withCurrencySign(numberFormatter(this.value), this.currencySign)
  }
}
