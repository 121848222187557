import React, { Component, type FormEvent } from 'react'
import { observer, inject } from 'mobx-react'
import { action, computed, observable } from 'mobx'
import {
  withGoogleReCaptcha,
  type IGoogleReCaptchaConsumerProps
} from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
import CopyToClipboard from 'react-copy-to-clipboard'

import FieldsGroup from 'src/models/form/FieldsGroup'
import Field from 'src/models/form/Field'
import EmailTemplateForm from './EmailTemplateForm'
import { validationErrors as errors } from 'src/utils/helpers'
import RaisedButton from 'src/components/shared/common/RaisedButton'
import FlatButton from 'src/components/shared/common/FlatButton'
import alertMessages from 'src/constants/alertMessages'
import Logger from 'src/utils/Logger'
import Select from 'src/components/shared/formComponents/Select'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import EmailPreviewModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/EmailPreviewModal'

const intervals = [
  {
    value: 'daily',
    name: 'Daily'
  },
  {
    value: 'weekly',
    name: 'Weekly'
  },
  {
    value: 'monthly',
    name: 'Monthly'
  },
  {
    value: 'quarterly',
    name: 'Quarterly'
  }
]

type SuggestProps = {
  email: string
  createSubscription: boolean
  setPreviousStep: () => void
  googleReCaptchaProps: IGoogleReCaptchaConsumerProps
  suggest: (form: Record<string, any>) => void
  text: string
  subscriptionContent: () => string
  rootStore: Record<string, any>
  theme: MUIThemeFuncReturnType
  previewEmail: (form: Record<string, any>) => void
  talentAlertStore: ITalentAlertStore
}

@inject('rootStore', 'talentAlertStore')
@observer
class Suggest extends Component<SuggestProps> {
  @observable width = 0
  @observable copyUrl = ''

  form: Record<string, any>

  constructor(props: SuggestProps) {
    super(props)

    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),

      email: new Field({
        name: 'email',
        value: props.email || '',
        validators: [errors.isEmail()]
      }),

      message: new Field({
        name: 'message',
        value: ''
      }),

      template: new Field({
        name: 'template',
        value: ''
      }),

      interval: new Field({
        name: 'interval',
        value: intervals[1].value
      }),

      recaptchaResponse: new Field({
        name: 'recaptcha_response',
        value: ''
      })
    })

    this.setData(
      'copyUrl',
      `${props.text.replace('isSubscriptionFirst', 'isSubscription')}`
    )
  }

  @action
  setData = (name: string, data: any) => {
    // @ts-ignore need to fix types
    this[name] = data
  }

  @action
  resize = () => {
    this.width = document.body.clientWidth
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  @computed
  get buttonText() {
    if (this.props.createSubscription) {
      return 'Send'
    }

    let text = 'Suggest a talent alert'

    if (
      (this.width < 1100 && this.width > 1024) ||
      (this.width < 880 && this.width > 792) ||
      this.width < 660
    ) {
      text = 'Create'
    }

    return text
  }

  handleCopy = () => {
    toast.success(alertMessages.subscription)
  }

  submitData = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { googleReCaptchaProps, suggest, talentAlertStore } = this.props

    const { executeRecaptcha } = googleReCaptchaProps

    if (!executeRecaptcha) {
      toast.error(alertMessages.notValidated)
      return
    }
    const token = await executeRecaptcha()

    if (token) {
      try {
        this.form.fields.recaptchaResponse.update(token)

        if (this.form.fields.message.value) {
          const msg = this.form.fields.message.value
          // eslint-disable-next-line no-useless-escape
          const angleBracketsWords = msg.match(/\<([A-Za-z0-9_ ]+)\>/g)

          if (angleBracketsWords && angleBracketsWords.length > 0) {
            talentAlertStore.setAngleBracketsWords(angleBracketsWords)
            talentAlertStore.openCharacterModal()
          } else {
            await suggest(this.form)
          }
        } else {
          await suggest(this.form)
        }

        const url =
          window.location != window.parent.location
            ? document.referrer
            : document.location.href
        window.parent.postMessage('SHOW-TALENT-ALERT-SUCCESS-MODAL', url)
      } catch (error: any) {
        Logger.error(error)
      }
    }
  }

  previewEmail = () => {
    const { previewEmail } = this.props

    previewEmail(this.form)
  }

  render() {
    const {
      createSubscription,
      setPreviousStep,
      rootStore,
      subscriptionContent,
      theme,
      talentAlertStore
    } = this.props

    return (
      <>
        <form className="refari-form-section">
          {/* @ts-ignore store props are injected */}
          <EmailTemplateForm view="regular" form={this.form} />

          <Select
            fieldData={this.form.fields.interval}
            emptyOption=""
            options={intervals}
            optionValueKey="value"
            label="Frequency"
            floatingLabelFixed
          />

          {createSubscription ? (
            <div
              className="refari-subscribe-tab-content__center-button"
              style={{ marginTop: 'auto' }}
            >
              <RaisedButton
                onClick={this.submitData}
                color="primary"
                className={`refari-submit-btn m-right_1x refari-button-raised ${
                  !this.form.isValid || this.form.isPending
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                disabled={!this.form.isValid || this.form.isPending}
              >
                {this.buttonText}
              </RaisedButton>
              <FlatButton
                color="primary"
                className="refari-button-flat m_r_1px"
                onClick={setPreviousStep}
              >
                Back
              </FlatButton>

              {rootStore.loggedIn && !rootStore.user.isHiringManager && (
                <CopyToClipboard text={this.copyUrl} onCopy={this.handleCopy}>
                  <RaisedButton
                    style={{
                      backgroundColor: theme.palette.primary1Color,
                      color: '#fff'
                    }}
                    className="refari-button-raised"
                  >
                    {subscriptionContent()}
                  </RaisedButton>
                </CopyToClipboard>
              )}

              {rootStore.loggedIn && !rootStore.user.isHiringManager && (
                <RaisedButton
                  onClick={this.previewEmail}
                  color="primary"
                  className={`refari-submit-btn refari-button-raised ${
                    this.form.isPending ? 'refari-button-raised-disabled' : ''
                  }`}
                  disabled={this.form.isPending}
                >
                  Preview email
                </RaisedButton>
              )}
            </div>
          ) : (
            <RaisedButton
              onClick={this.submitData}
              fullWidth
              color="primary"
              className={`refari-submit-btn refari-button-raised ${
                !this.form.isValid || this.form.isPending
                  ? 'refari-button-raised-disabled'
                  : ''
              }`}
              disabled={this.form.isValid || this.form.isPending}
            >
              {this.buttonText}
            </RaisedButton>
          )}
        </form>

        {/* @ts-ignore store props are injected */}
        {talentAlertStore.emailPreview && <EmailPreviewModal />}
      </>
    )
  }
}

export default withGoogleReCaptcha(Suggest)
