import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import Modal from '../Modal'
import CloseModalButton from '../CloseModalButton'
import { Button } from '@material-ui/core'

class FacebookWarningModal extends React.Component {
  constructor(props) {
    super(props)
  }

  onClose = () => {
    const { onClose } = this.props
    onClose && onClose()
  }

  render() {
    const { open } = this.props
    return (
      <Modal
        className="refari-w-modal-container"
        open={open}
        onClose={this.onClose}
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: '#fff',
            // borderColor: theme.widgetBackgroundColor,
            padding: 0,
            height: '200'
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton
            onClose={this.onClose}
            style={{ top: 0, right: 0, padding: 0 }}
          />
          <div
            style={{
              width: '100%',
              // maxWidth: 625,
              margin: 'auto',
              minHeight: 100,
              padding: '2rem 1rem'
            }}
          >
            {/* <h3 style={{ color: this.props?.theme?.palette?.primary1Color }}>
              Link Social Network
            </h3> */}
            <p
              style={{
                padding: '1rem 2rem',
                textAlign: 'center',
                lineHeight: 1.5
              }}
            >
              Unfortunately, you cannot schedule socialise posts via your
              personal Facebook page.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onClose}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(FacebookWarningModal)
