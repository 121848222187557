import { observable, computed, action } from 'mobx'

import type { Data } from '../types'
import FilterSingle from './FilterSingle'

export type IFilterGroup = {
  data: Array<InstanceType<typeof FilterSingle>>
  isAllSelected: boolean
  groupValue: Array<number>
  getAppliedFiltersInstances: Array<InstanceType<typeof FilterSingle>>
  resetAll: () => void
  applyAll: () => void
}

type ConstructorArgs = {
  data: Array<Data>
  dataLength: number
  parent: InstanceType<typeof FilterSingle>
}

class FilterGroup implements IFilterGroup {
  @observable.ref data: IFilterGroup['data'] = []

  constructor({ data, dataLength, parent }: ConstructorArgs) {
    this.data = data.map(
      (datum) =>
        new FilterSingle({
          data: datum,
          dataLength,
          parent
        })
    )
  }

  @computed
  get isAllSelected(): IFilterGroup['isAllSelected'] {
    return this.data.every((item) => item.selected)
  }

  @computed
  get groupValue(): IFilterGroup['groupValue'] {
    return this.data.reduce(
      (init, next) => init.concat(next.value),
      [] as Array<number>
    )
  }

  resetAll: IFilterGroup['resetAll'] = () => {
    Object.keys(this.data).forEach((item) =>
      this.data[item as unknown as number].reset()
    )
  }
  @action
  applyAll: IFilterGroup['applyAll'] = () => {
    Object.keys(this.data).forEach((item) =>
      this.data[item as unknown as number].applyFilter()
    )
  }

  @computed
  get getAppliedFiltersInstances(): IFilterGroup['getAppliedFiltersInstances'] {
    let appliedFiltersInstances: Array<InstanceType<typeof FilterSingle>> = []

    this.data.forEach((item) => {
      if (item.parent?.selected) {
        // Do smth
      } else {
        if (item.selected) {
          appliedFiltersInstances = [...appliedFiltersInstances, item]
        }

        if (item.children instanceof FilterGroup) {
          appliedFiltersInstances = appliedFiltersInstances.concat(
            item.children.getAppliedFiltersInstances
          )
        }
      }
    })
    return appliedFiltersInstances
  }
}

export default FilterGroup
