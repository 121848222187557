import React, { Component } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import {
  Checkbox as MUICheckbox,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import FilterSingle from './models/FilterSingle'
import FilterGroup from './models/FilterGroup'
import type { ChangedFilter } from './types'

type FilterListItemProps = {
  data: InstanceType<typeof FilterSingle>
  filters: Array<InstanceType<typeof FilterSingle>>
  onChangeFilter: (onChangeFilterArgs: ChangedFilter) => void
  isNested?: boolean
}

@observer
class FilterListItem extends Component<FilterListItemProps> {
  static defaultProps: Partial<FilterListItemProps> = {
    isNested: false
  }

  addOrRemoveIdList = () => {
    const { data, onChangeFilter } = this.props

    data.toggle()

    onChangeFilter({
      operation: data.selected ? 'add' : 'remove',
      filterLabel: data.label
    })
  }

  handleClick = () => {
    const { data } = this.props

    data.toggleOpenMenu()
  }

  render() {
    const { data, isNested = false, onChangeFilter, filters } = this.props

    const { key, label, selected, isOpenMenu } = data

    const haveChildren =
      data.children instanceof FilterGroup &&
      data.children.data &&
      data.children.data.length > 0

    return (
      <>
        <ListItem
          id={label}
          className={classNames({
            'refari-filter-item': true,
            'refari-nested': isNested
          })}
          button
          onClick={this.addOrRemoveIdList}
        >
          <ListItemIcon>
            <MUICheckbox
              edge="start"
              color="primary"
              disableRipple
              className={classNames({
                'refari-filter-checkbox-nested': isNested
              })}
              checked={selected}
            />
          </ListItemIcon>

          <ListItemText primary={label} />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={this.handleClick}
            >
              {haveChildren && (
                <>{isOpenMenu ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={isOpenMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.children instanceof FilterGroup &&
              data.children.data &&
              data.children.data
                .sort((a, b) => {
                  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
                  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
                  return 0
                })
                .map((filter) => {
                  return (
                    <FilterListItem
                      isNested
                      filters={filters}
                      key={`${key}_filter`}
                      data={filter}
                      onChangeFilter={onChangeFilter}
                    />
                  )
                })}
          </List>
        </Collapse>
      </>
    )
  }
}

export default FilterListItem
