/* eslint-disable camelcase */
/* eslint-disable react/state-in-constructor */
import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-konva'

export default class StarLogo extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.image = new window.Image()
    this.image.setAttribute('crossOrigin', 'anonymous')
    // hack - safari can't draw an image that is not loaded yet
    this.image.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
  }

  state = {
    width: 20,
    height: 20,
    x: 0,
    y: 0
  }

  componentDidMount() {
    this.image.src = this.props.imageUrl
    this.image.onload = () => {
      if (this.refs.imageNode) {
        this.refs.imageNode.getLayer().batchDraw()
      }
    }
    this.setState({
      width: this.props.width,
      height: this.props.height,
      x: this.props.x,
      y: this.props.y
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      width: props.width,
      height: props.height,
      x: props.x,
      y: props.y
    })
    this.image.src = props.imageUrl
    this.image.onload = () => {
      if (this.refs.imageNode) {
        this.refs.imageNode.getLayer().batchDraw()
      }
    }
  }

  render() {
    const {
      width, height, x, y
    } = this.state
    return (
      <Image
        image={this.image}
        width={width}
        height={height}
        ref="imageNode"
        x={x}
        y={y}
      />
    )
  }
}
