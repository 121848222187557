/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react'
import { Provider, observer } from 'mobx-react'
import { ToastContainer } from 'react-toastify'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import 'react-toastify/dist/ReactToastify.css'
import 'flexboxgrid'
import 'react-infinite-calendar/styles.css'
import './styles/index.styl'
import { reaction, observable, action } from 'mobx'
import classNames from 'classnames'
import { Route, Switch, Redirect, matchPath } from 'react-router-dom'
import { MUITheme } from './utils/MUITheme'
import Header from './components/shared/common/Header'
import ConfirmationModal from './components/shared/modals/ConfirmationModal'
import SuccessModal from './components/shared/modals/SuccessModal'
import SwitchAgencyModal from './components/shared/modals/SwitchAgencyModal'
import JobAdLinkModal from './components/shared/modals/JobAdLinkModal'
import AuthModal from './components/shared/modals/AuthModal'
import ResetPasswordModal from './components/shared/modals/ResetPasswordModal'
import PhoneVerficationModal from './components/shared/modals/authTwoFactor/PhoneVerficationModal'
import SetupTwoFactorModal from './components/shared/modals/authTwoFactor/SetupTwoFactorModal'
import TwoFactorSuccessModal from './components/shared/modals/authTwoFactor/TwoFactorSuccessModal'
import OneTimePasswordModal from './components/shared/modals/authTwoFactor/OneTimePasswordModal'
import AuthAppCodeModal from './components/shared/modals/authTwoFactor/AuthAppCodeModal'
import Footer from './components/shared/common/Footer'
import CommonSpinner from './components/shared/common/CommonSpinner'
import stores from './stores'
import credentials from './constants/credentials'
import AuthSession from './utils/AuthSession'
import EdmAlertModal from './components/shared/modals/EdmAlertModal'

import URLs from './router/routesUrls'
import { PrivateRoute, PublicRoute } from './router/RouterHooks'
import asyncComponent from './components/shared/asyncComponent'

import Home from './components/pages/Home'
import Dashboard from './components/pages/Dashboard'
import Error from './components/pages/Error'
import Settings from './components/pages/settings/Settings'
import WelcomePage from './components/pages/WelcomePage'
import SubscriptionRemove from './components/pages/SubscriptionRemove'
import ServerError from './components/pages/ServerError'
import Widgets from './components/pages/widgets/Widgets'
import Reports from './components/pages/reports/Reports'
import Billing from './components/pages/billing/Billing'
import Badges from './components/pages/badges/Badges'
import TopRec from './components/pages/toprec/Reports'
import Testimonials from './components/pages/Testimonials/Testimonials'
import ThankForRating from './components/pages/Testimonials/ThankForRating'
import AddTestimonials from './components/pages/Testimonials/AddTestimonials'
import TestimonialShare from './components/pages/Testimonials/TestimonialShare'
import TestimonialResponse from './components/pages/Testimonials/TestimonialResponse'
import ExpiredLink from './components/pages/Testimonials/ExpiredLink'
import SecondReview from './components/pages/Testimonials/SecondReview'
import Approve from './components/pages/Testimonials/Approve'
import EdmPreviewPage from './components/pages/tools/EdmBuilder/EdmPreviewPage'
import Tools from './components/pages/tools/Tools'
import TwitterLinking from './components/pages/settings/socialNetworksComponents/TwitterLinking'
import RequestRating from './components/pages/RequestRating/RequestRating'
import CreateJobAlert from './components/pages/createJobAlert/CreateJobAlert'
import CreateTalentAlert from './components/pages/createTalentAlert/CreateTalentAlert'
import SocialiseJobPage from './components/pages/socialiseJob/SocialiseJobPage'
import PitchMeUrlPage from './components/pages/pitchMeUrl/PitchMeUrlPage'
import TestimonialsRevision from './components/pages/Testimonials/TestimonialsRevision'
import DashboardFloat from './components/shared/floatComponents/DashboardFloat'
import ErrorModal from './components/shared/modals/ErrorModal'

const Flat = asyncComponent(() => import('./components/pages/Flat'))
const { rootStore, spinnersStore } = stores

@observer
class App extends React.Component {
  @observable.ref MUITheme

  constructor(props) {
    super(props)
    this.setMUITheme()
    const isInvitationPath = matchPath(props.location?.pathname, {
      path: [
        '/' + URLs.acceptInvite.path,
        '/' + URLs.acceptInviteReferrer.path
      ],
      exact: true
    })
    if (isInvitationPath) {
      AuthSession.remove()
    }
    rootStore.initialProfileRequest()
    rootStore.fetchPublicCommonData()
    reaction(
      () => rootStore.loggedIn,
      (loggedIn) => {
        if (
          (rootStore.user.isReferrer || rootStore.user.isSystemAdminUser) &&
          loggedIn === false
        ) {
          AuthSession.remove()
          props.history.replace(URLs.home.link)
          window.location.reload(true)
        }
      }
    )
    reaction(
      () => rootStore.branding,
      (branding) => {
        this.setMUITheme(branding)
        spinnersStore.loadingIsEndAfterTimeOut(spinnersStore.keys.common)
        this.forceUpdate()
      }
    )
    reaction(
      () => rootStore.loggedIn && rootStore.user.isAgencyUser,
      () => {
        if (rootStore.user.isAgencyUser) {
          if (props?.location?.pathname !== '/socialise-job/') {
            rootStore.fetchAgencyData()
          }
        }
      }
    )
  }

  @action
  setMUITheme = (customTheme) => {
    this.MUITheme = createTheme(MUITheme(customTheme))
  }

  UNSAFE_componentWillMount() {
    if (AuthSession.isTokenSet()) {
      spinnersStore.loadingIsStart(spinnersStore.keys.common)
    }
  }

  componentDidMount() {
    const cb = () => {
      this.props.history.replace(URLs.home.link)
    }
    const setUser = (event) => {
      if (
        event &&
        (event.key === `${AuthSession.tokenKey}.get.id` ||
          event.key === `${AuthSession.tokenKey}.set.id`)
      ) {
        rootStore.initialProfileRequest(cb)
      }
    }
    window.addEventListener('storage', setUser, false)
  }

  render() {
    const privatPage = [
      '/for-recruiters/',
      '/integrations/',
      '/about/',
      '/socialise-job/',
      '/pitch-me-url-page/'
    ].includes(window.location.pathname)
    const { match, history, location, headerContent } = this.props

    return (
      <Provider {...stores}>
        <GoogleReCaptchaProvider
          reCaptchaKey={credentials.google.captchaSiteKey}
        >
          <MuiThemeProvider theme={this.MUITheme}>
            <div className="app">
              <CommonSpinner
                showLoader={spinnersStore.spinners.common.loading}
              />
              <Switch>
                <Route exact path={`${match.url}${URLs.createJobAlert.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PUBLIC Suggest a job alert */}
                </Route>
                <Route exact path={`${match.url}${URLs.createTalentAlert.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PUBLIC Suggest a talent alert */}
                </Route>
                <Route exact path={`${match.url}${URLs.socialiseJob.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PUBLIC Suggest a job alert */}
                </Route>
                <Route exact path={`${match.url}${URLs.pitchMeUrlPage.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PITCHME ENDPOINT */}
                </Route>
                <Route>
                  <Header
                    className={classNames({
                      transparent: spinnersStore.spinners.common.loading
                    })}
                    history={history}
                    pathname={location.pathname}
                  >
                    {headerContent || null}
                  </Header>
                </Route>
              </Switch>
              <div
                className={classNames({
                  transparent: spinnersStore.spinners.common.loading,
                  'left-space': rootStore.loggedIn && !privatPage
                })}
              >
                <Switch>
                  <PublicRoute
                    exact
                    path={`${match.url}${URLs.signIn.path}`}
                    name={URLs.signIn.name}
                    component={Home}
                  />
                  <PublicRoute exact path={match.url} component={Home} />
                  <PrivateRoute
                    path={`${match.url}${URLs.dashboard.path}`}
                    component={Dashboard}
                  />
                  {/* <Redirect
                    from={`${match.url}${URLs.settings.path}`}
                    to={URLs.settings.children.editProfile.link}
                  /> */}
                  <PrivateRoute
                    path={`${match.url}${URLs.settings.path}`}
                    component={Settings}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.tools.path}`}
                    component={Tools}
                  />
                  {/* <Redirect
                    from={`${match.url}${URLs.widgets.path}`}
                    to={URLs.widgets.children.floatingLink.link}
                  /> */}
                  <PrivateRoute
                    path={`${match.url}${URLs.widgets.path}`}
                    component={Widgets}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.reports.path}`}
                    component={Reports}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.billing.path}`}
                    component={Billing}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.badges.path}`}
                    component={Badges}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.gamificationBadges.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/badges/GamificationBadges')
                    )}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.dataEraser.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/DataEraser')
                    )}
                  />
                  <PrivateRoute
                    path={`${match.url}${URLs.toprec.path}`}
                    component={TopRec}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.passwordReset.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/ForgotPasswordAccept')
                    )}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.twitterCallback.path}`}
                    component={TwitterLinking}
                  />

                  <Route
                    exact
                    path={URLs.testimonials.feedback}
                    component={Testimonials}
                  />

                  <Route
                    exact
                    path={URLs.testimonials.revisionTestimonial}
                    component={TestimonialsRevision}
                  />

                  <Route
                    exact
                    path={URLs.testimonials.requestRating}
                    component={RequestRating}
                  />

                  <Route
                    exact
                    path={URLs.testimonials.children.thank}
                    component={ThankForRating}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.addTestimonials}
                    component={AddTestimonials}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.testimonialsShare}
                    component={TestimonialShare}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.testimonialsReviewed}
                    component={SecondReview}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.testimonialsExpired}
                    component={ExpiredLink}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.testimonialsApprove}
                    component={Approve}
                  />
                  <Route
                    exact
                    path={URLs.testimonials.children.testimonialsResponse}
                    component={TestimonialResponse}
                  />
                  <Route
                    exact
                    path={`${match.url}${URLs.emailVerify.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/EmailVerify')
                    )}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.acceptInvite.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/AcceptInviteAgency')
                    )}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.acceptInviteReferrer.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/AcceptInviteReferrer')
                    )}
                  />

<                 Route
                    exact
                    path={`${match.url}${URLs.acceptInviteHiringManager.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/AcceptInviteHiringManager')
                    )}
                  />

                  {/* <Route
                    path={`${match.url}${URLs.dashboard.children.candidates.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/candidatesPage')
                    )}
                  /> */}

                  <Route
                    exact
                    path={`${match.url}${URLs.inviteConsultants.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/inviteConsultants/Invites')
                    )}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.contactUs.path}`}
                    component={asyncComponent(() =>
                      import('./components/pages/ContactUs')
                    )}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.privacy.path}`}
                    name={URLs.privacy.name}
                    component={Flat}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.terms.path}`}
                    name={URLs.terms.name}
                    component={Flat}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.welcome.path}`}
                    component={WelcomePage}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.subscriptionsRemove.path}`}
                    component={SubscriptionRemove}
                  />

                  <Route
                    exact
                    path={URLs.EdmBuilderPreview.path}
                    component={EdmPreviewPage}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.createJobAlert.path}`}
                    component={CreateJobAlert}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.createTalentAlert.path}`}
                    component={CreateTalentAlert}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.socialiseJob.path}`}
                    component={SocialiseJobPage}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.pitchMeUrlPage.path}`}
                    component={PitchMeUrlPage}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.serverError.path}`}
                    component={ServerError}
                  />

                  <Route
                    exact
                    path={`${match.url}${URLs.error.path}`}
                    component={Error}
                  />

                  <Redirect from="*" to={URLs.error.link} />
                </Switch>
              </div>
              <Switch>
                <Route exact path={`${match.url}${URLs.createJobAlert.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PUBLIC Suggest a job alert */}
                </Route>
                <Route exact path={`${match.url}${URLs.socialiseJob.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR SOCIALISE ENDPOINT */}
                </Route>
                <Route exact path={`${match.url}${URLs.pitchMeUrlPage.path}`}>
                  {/**DO NOT RENDER ANYTHING FOR PITCHME ENDPOINT */}
                </Route>
                <Route>
                  <Footer
                    className={classNames({
                      transparent: spinnersStore.spinners.common.loading
                    })}
                    socialLinks={
                      rootStore.commonData && rootStore.commonData.social_links
                        ? rootStore.commonData.social_links
                        : null
                    }
                    indexStatus={rootStore.loggedIn && !privatPage}
                    isLogin={rootStore.loggedIn}
                  />
                </Route>
              </Switch>
              <ToastContainer
                position="bottom-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                limit={1}
              />

              <AuthModal history={history} />
              <ResetPasswordModal history={history} />
              <SuccessModal />
              <ErrorModal />
              <SwitchAgencyModal history={history} />
              <JobAdLinkModal history={history} />
              <ConfirmationModal />
              <EdmAlertModal />
              <PhoneVerficationModal />
              <SetupTwoFactorModal />
              <TwoFactorSuccessModal />
              <OneTimePasswordModal />
              <AuthAppCodeModal />
              <DashboardFloat rootStore={rootStore} />
            </div>
          </MuiThemeProvider>
        </GoogleReCaptchaProvider>
      </Provider>
    )
  }
}

App.propTypes = {}

App.defaultProps = {}

export default App
