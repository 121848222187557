const getKeyOfWhoseValuesAreArray = (obj: Record<string, any>): string => {
  return Object.keys(obj).filter((key) => {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }

    return Array.isArray(obj[key])
  })[0]
}

export default getKeyOfWhoseValuesAreArray
