/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import qp from 'query-parse'
import credentials from '../../constants/credentials'
import { baseURLs } from '../Urls'
import { ApiRoutes } from '../Urls'

/**
 * https://www.facebook.com/v13.0/dialog/oauth?
 * response_type=token&
 * display=popup&
 * client_id=389594252812753&
 * redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer%2Fcallback%3Fmethod%3DGET%26path%3D485507843012515%252Faccounts%252F%26version%3Dv13.0&
 * auth_type=rerequest&
 * scope=pages_show_list%2Cpages_read_engagement%2Cpages_manage_posts%2Cpublic_profile
 *
 * https://www.facebook.com/v13.0/dialog/oauth?
 * response_type=token&
 * display=popup&
 * client_id=676323342930092&
 * redirect_uri=https%253A%252F%252Fdevelopers.facebook.com%252Ftools%252Fexplorer%252Fcallback%253Fmethod%253DGET%2526path%253D485507843012515%25252Faccounts%25252F%2526version%253Dv13.0&
 * auth_type=rerequest&
 * scope=pages_show_list%2Cpages_read_engagement%2Cpages_manage_posts%2Cpublic_profile
 *
 * https://www.facebook.com/v13.0/dialog/oauth?
 * response_type=token&
 * client_id=676323342930092&
 * display=popup&
 * redirect_uri=https%3A%2F%2Fapi.dev.refari.co%2Fauth%2Ffacebook%2F&
 * auth_type=rerequest&
 * scope=pages_show_list%2Cpages_read_engagement%2Cpages_manage_posts%2Cpublic_profile
 *
 * https://www.facebook.com/v13.0/dialog/oauth?
 * app_id=676323342930092&
 * auth_type=rerequest&
 * cbt=1651223272084&
 * channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df182c9454bacb9c%26domain%3Ddev.refari.co%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fdev.refari.co%252Ff12c3c4dbb8d57%26relation%3Dopener&
 * client_id=676323342930092&
 * display=popup&
 * domain=dev.refari.co&
 * e2e=%7B%7D&
 * fallback_redirect_uri=https%3A%2F%2Fdev.refari.co%2Fsettings%2Fsocial-networks%2F&locale=en_US&
 * logger_id=f1daacecf965b0c&origin=1&
 * redirect_uri=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2348b97917e0c%26domain%3Ddev.refari.co%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fdev.refari.co%252Ff12c3c4dbb8d57%26relation%3Dopener%26frame%3Df38087649bdf938&
 * response_type=token%2Csigned_request%2Cgraph_domain&
 * return_scopes=false&scope=pages_show_list%2Cpages_read_engagement%2Cpages_manage_posts%2Cpublic_profile&sdk=joey&version=v13.0
 * 
 * https://www.facebook.com/v13.0/dialog/login?
 * app_id=676323342930092&
 * channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df12652bbcbf87%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A3000%252Ff2d191ebb0b982c%26relation%3Dopener&
 * display=popup&
 * e2e=%7B%7D&
 * fallback_redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fsettings%2Fsocial-networks%2F&href=https%3A%2F%2Fwww.facebook.com%2Fv13.0%2Fdialog%2Foauth%3Fauth_type%3Drerequest%26client_id%3D676323342930092%26display%3Dpopup%26redirect_uri%3Dhttps%253A%252F%252Fdevelopers.facebook.com%252Ftools%252Fexplorer%252Fcallback%253Fmethod%253DGET%2526path%253D485507843012515%25252Faccounts%25252F%2526version%253Dv13.0%26response_type%3Dtoken%26scope%3Dpages_show_list%252Cpages_read_engagement%252Cpages_manage_posts%252Cpublic_profile&locale=en_US&next=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1aace1f6196cc4%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A3000%252Ff2d191ebb0b982c%26relation%3Dopener%26frame%3Df66f41136e0dcc%26result%3D%2522xxRESULTTOKENxx%2522&scope=pages_show_list%2Cpages_read_engagement%2Cpages_manage_posts%2Cpublic_profile&sdk=joey&version=v13.0
    https://www.facebook.com/v13.0/dialog/oauth?app_id=676323342930092&cbt=1651214917435&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df392b9d6777cdb%26domain%3Dstg.refari.co%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fstg.refari.co%252Ff27e2989ce4f91%26relation%3Dopener&client_id=676323342930092&display=popup&domain=stg.refari.co&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Fstg.refari.co%2Fsign-in&locale=en_US&logger_id=f14d079f80119e&origin=1&
    redirect_uri=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2863e242d84758%26domain%3Dstg.refari.co%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fstg.refari.co%252Ff27e2989ce4f91%26relation%3Dopener%26frame%3Df350f5df20d05e8&response_type=token%2Csigned_request%2Cgraph_domain&scope=email&sdk=joey&version=v13.0
*/

class FacebookAccessTokenHelper {
  title = 'Share via Facebook'
  FacebookApiShare = 'https://www.facebook.com/dialog/share'
  fields = [
    'id',
    'first_name',
    'last_name',
    'middle_name',
    'name',
    'email',
    'link'
  ]

  facebookApiAuth = 'https://www.facebook.com/v13.0/dialog/oauth'
  title = 'Authorization via Facebook'
  options = qp
    .toString({
      width: 600,
      height: 400,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')
  scope =
    'pages_show_list,pages_read_engagement,pages_manage_posts,public_profile'

  process = (option, isPersonal) => {
    this.getToken(option, isPersonal)
  }

  getToken = ({ clientId, callback, onError }, isPersonal) => {
    let callbackIsApplied = false
    window.newFacebookTokenWindow && window.newFacebookTokenWindow.close()

    const queries = qp.toString({
      response_type: 'token',
      display: 'popup',
      client_id: clientId,
      // state: Math.random().toString(36).substring(7),
      redirect_uri: ApiRoutes.auth.linkedinCallback,
      //   redirect_uri:
      //     'https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer%2Fcallback%3Fmethod%3DGET%26path%3D485507843012515%252Faccounts%252F%26version%3Dv13.0',
      //   redirect_uri:
      //     'https://developers.facebook.com/tools/explorer/callback?method=GET&path=485507843012515%2Faccounts%2F&version=v13.0',
      auth_type: 'rerequest',
      scope: this.scope
    })

    // window.FB.login(
    //   (response) => {
    //     let token
    //     if (response.authResponse) {
    //       token = response.authResponse.accessToken
    //       console.log('token')
    //       console.log(token)
    //       callback({ code: token, network: credentials.facebook.key })
    //     } else {
    //       //   callback({ id, link: null })
    //       console.log(response)
    //       onError('facebook', 'error')
    //     }
    //   },
    //   {
    //     scope: this.scope,
    //     auth_type: 'rerequest',
    //     return_scopes: false
    //   }
    // )

    window.newFacebookTokenWindow = window.open(
      `${this.facebookApiAuth}?${queries}`,
      this.title,
      this.options
    )

    window.newFacebookTokenWindow.onunload = function () {
      // Run your code, the popup window is unloading
      // Beware though, this will also fire if the user navigates to a different
      // page within thepopup. If you need to support that, you will have to play around
      // with pop.closed and setTimeouts
      console.log('onunload invoked')
      console.log(window?.opener?.location?.href)
    }

    // window.newFacebookTokenWindow.addEventListener(
    //   'locationchange',
    //   function (event) {
    //     console.log('location changed!')
    //     console.log(event)
    //   }
    // )
    // console.log(window.newFacebookTokenWindow)
    // // check if popup is closed, call onErrer
    this._timer = setInterval(() => {
      if (
        window.newFacebookTokenWindow &&
        window.newFacebookTokenWindow.closed &&
        !callbackIsApplied
      ) {
        onError && onError(credentials.facebook.key)
        // this.logout()
        clearInterval(this._timer)
      }
    }, 1000)

    const receiveMessage = (event) => {
      if (event.data && event.source === window.newFacebookTokenWindow) {
        clearInterval(this._timer)
        console.log('facebook success code')
        console.log(event)
        callback({ code: event.data.code, network: credentials.facebook.key })
        window.removeEventListener('message', receiveMessage, false)
        callbackIsApplied = true
        window.newFacebookTokenWindow && window.newFacebookTokenWindow.close()
        delete window.newFacebookTokenWindow
        setTimeout(() => {
          this.logout()
        }, 0)
      }
    }

    window.addEventListener('message', receiveMessage, false)

    // check if popup is closed, call onError
    const check = () => {
      if (
        window.newFacebookTokenWindow &&
        window.newFacebookTokenWindow.closed &&
        !callbackIsApplied
      ) {
        this.stop()
        window.removeEventListener('message', receiveMessage)
        onError && onError()
        return
      }
      this._timer = setTimeout(check, 1500)
    }
    this._timer = setTimeout(check, 1500)
  }

  logout = () =>
    new Promise((resolve, reject) => {
      window.IN.User.refresh()
      const isAuthorized = window.IN.User.isAuthorized()
      if (!isAuthorized) {
        resolve()
        return
      }
      window.IN.User.logout((success) => {
        if (success) {
          resolve()
        } else {
          resolve()
        }
      })
    })

  stop() {
    clearInterval(this._timer)
  }

  initLoginSDK = ({ clientId, callback, onError }) => {
    if (window.FB.getAccessToken()) {
      const result = window.FB.getAccessToken()
      console.log('getAccessToken')
      console.log(result)
      return
    }
    window.FB.login(
      (response) => {
        let token
        if (response.authResponse) {
          token = response.authResponse.accessToken
          console.log('token')
          console.log(token)
          callback({ code: token, network: credentials.facebook.key })
        } else {
          //   callback({ id, link: null })
          console.log(response)
          onError('facebook', 'error')
        }
      },
      {
        scope: this.scope,
        auth_type: 'rerequest',
        return_scopes: false
      }
    )
  }

  //   initSharing = ({ callback, onError, ssr_link, id, title }) => {
  //     // if (!ssr_link) {
  //     //   onError()
  //     // }

  //     let callbackIsApplied = false

  //     if (window.newShareFacebookWindow) {
  //       window.newShareFacebookWindow.stop()
  //       window.newShareFacebookWindow.popup.close()
  //     }

  // const params = qp.toString({
  //   app_id: credentials.facebook.appId,
  // //   href: ssr_link.replace('publications/', ''), // TODO: remove replacing
  // //   redirect_uri: `${baseURLs('frontend')}?fb_shared_success=true`,
  // redirect_uri: ,
  //   error_message: 'fb_shared_success=false',
  //   method: 'page'
  // })

  // window.newShareFacebookWindow = {
  //   stop: this.stop,
  //   popup: window.open(
  //     `${this.FacebookApiShare}?${params}`,
  //     title || this.title,
  //     this.options
  //   )
  // }

  // const receiveMessage = (event) => {
  //   if (event.data && event.source === window.newShareFacebookWindow.popup) {
  //     window.removeEventListener('message', receiveMessage)
  //     const messageObj = typeof event.data === 'string' ? JSON.parse(event.data) : event.data
  //     if (messageObj.fb_shared_success) {
  //       callbackIsApplied = true
  //       this.stop()
  //       window.newShareFacebookWindow
  //         && window.newShareFacebookWindow.popup.close()
  //       delete window.newShareFacebookWindow
  //       this.getProfileUrl(id, callback)
  //     } else {
  //       this.stop()
  //       onError()
  //     }
  //   }
  // }

  // window.addEventListener('message', receiveMessage, false)

  // check if popup is closed, call onError
  //     const check = () => {
  //       if (
  //         window.newShareFacebookWindow
  //         && window.newShareFacebookWindow.popup.closed
  //         && !callbackIsApplied
  //       ) {
  //         this.stop()
  //         window.removeEventListener('message', receiveMessage)
  //         onError && onError()
  //         return
  //       }
  //       this._timer = setTimeout(check, 1500)
  //     }
  //     this._timer = setTimeout(check, 1500)
  //   }

  //   getProfileUrl = (id, callback) => {
  //     const fetchMe = (accessToken) => {
  //       window.FB.api(
  //         '/me',
  //         'get',
  //         {
  //           access_token: accessToken,
  //           fields: this.fields
  //         },
  //         (response) => {
  //           callback({ id, link: response.link })
  //         }
  //       )
  //     }

  //     if (window.FB.getAccessToken()) {
  //       fetchMe(window.FB.getAccessToken())
  //       return
  //     }

  //     window.FB.login(
  //       (response) => {
  //         let token
  //         if (response.authResponse) {
  //           token = response.authResponse.accessToken
  //           console.log('token')
  //           console.log(token)
  //         } else {
  //           callback({ id, link: null })
  //         }
  //       },
  //       {
  //         scope: this.scope,
  //         auth_type: 'rerequest',
  //         return_scopes: true
  //       }
  //     )
  //   }

  //   stop() {
  //     clearTimeout(this._timer)
  //   }
}

export default new FacebookAccessTokenHelper()
