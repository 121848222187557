/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from './CloseModalButton'
import Modal from './Modal'

@observer
class MailPreviewModal extends React.Component {
  static propTypes = {
    edmStore: PropTypes.shape({
      emailPreview: PropTypes.object.isRequired,
      isOpenEmailPreviewModal: PropTypes.bool,
      toggleEmailPreviewModal: PropTypes.func
    })
  }

  closeModal = () => {
    const {
      edmStore: { toggleEmailPreviewModal, setData }
    } = this.props
    toggleEmailPreviewModal()
    setData('emailPreview', null)
  }

  processBody = (body) =>
    body
      .replace(/href="(.*?)"/g, 'href="javascript: void(0)"')
      .replace(/class=/g, '') // remove class attributes

  render() {
    const {
      edmStore: { emailPreview, isOpenEmailPreviewModal },
      theme
    } = this.props

    if (!emailPreview) {
      return null
    }

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        maxWidth="xs"
        open={isOpenEmailPreviewModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Email preview
          </h3>
          <div
            style={{
              width: '100%',
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            {emailPreview.message_html && (
              <iframe
                title="Email preview"
                border="0"
                className="mail-preview__body"
                srcDoc={this.processBody(emailPreview.message_html)}
                style={{ width: '100%', minHeight: '620px' }}
              />
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(MailPreviewModal)
