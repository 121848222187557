export const ownershipOptions = [
  {
    name: '3 months',
    value: 3
  },
  {
    name: '6 months',
    value: 6
  },
  {
    name: '12 months',
    value: 12
  },
  {
    name: '18 months',
    value: 18
  },
  {
    name: '24 months',
    value: 24
  }
] as const

export const jobDetailButtonText = [
  {
    name: 'Read more',
    value: 'read_more'
  },
  {
    name: 'Apply Now',
    value: 'apply_now'
  }
] as const

export const referButtonTitle = [
  {
    name: 'Refer',
    value: 'refer'
  },
  {
    name: 'Refer to a friend',
    value: 'refer_to_a_friend'
  }
] as const

export const authorType = [
  {
    name: 'Candidate',
    value: 'candidate'
  },
  {
    name: 'Client',
    value: 'contact'
  }
] as const

export const customAuthorType = [
  {
    name: 'Candidate',
    value: 'candidate'
  },
  {
    name: 'Client',
    value: 'contact'
  },
  {
    name: 'Other',
    value: 'other'
  }
] as const

export const referralCRMStatus = [
  {
    id: 'viewed',
    name: 'Viewed'
  },
  {
    id: 'interviewed',
    name: 'Interviewed'
  },
  {
    id: 'offered',
    name: 'Offered/Reference Checking'
  },
  {
    id: 'placed',
    name: 'Placed'
  }
] as const

export const topRecCRMStatus = [
  {
    id: 'interviewed_internal',
    name: 'Internal Interview'
  },
  {
    id: 'interviewed_external',
    name: 'External Interview'
  },
  {
    id: 'placed',
    name: 'Placed'
  }
] as const

export const progressionInternalCRMStatus = [
  {
    id: 'interviewed_1',
    name: 'Interview 1'
  },
  {
    id: 'resume_submitted',
    name: 'Resume Submitted'
  },
  {
    id: 'interviewed_2',
    name: 'Interview 2'
  },
  {
    id: 'offered',
    name: 'Offered/Reference Checking'
  },
  {
    id: 'placed',
    name: 'Placed'
  }
] as const

export const progressionExternalCRMStatus = [
  {
    id: 'interviewed_1',
    name: 'Recruiter Interview'
  },
  {
    id: 'resume_submitted',
    name: 'Resume Submitted'
  },
  {
    id: 'interviewed_2',
    name: 'Hiring Manager Interview'
  },
  {
    id: 'offered',
    name: 'Offered/Reference Checking'
  },
  {
    id: 'placed',
    name: 'Placed'
  }
] as const

export const NOT_APPLICABLE_OPTION_NAME = 'N/A'

export const APPLIED_OPTION_NAME = 'Applied'
