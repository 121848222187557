import Cookies from './Cookies'

class Storage {
  static get(key) {
    let value
    value = Cookies.get(key)
    try {
      if (value) {
        value = JSON.parse(value)
      }
    } catch (error) {
      value = ''
    }
    return value
  }

  static set(key, data) {
    const value = JSON.stringify(data)
    Cookies.set(key, value,)
  }

  static setGlobalCookie(key, data) {
    const value = JSON.stringify(data)
    Cookies.set(key, value, { domain: '.refari.co' })
  }

  static remove(key) {
    Cookies.remove(key)
  }
}

export default Storage
