import { Box, Button, Dialog, Fab, Grid, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import TopRecLogo from 'src/assets/images/TopRecLogo.svg'
import TopRecImage from 'src/assets/images/toprec-img.svg'
import ToprecGradientBackground from 'src/assets/images/toprec_gradient_background.svg'
import ToprecVideoThumbnail from 'src/assets/images/toprec_video_thumbnail.png'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import qp from 'query-parse'

type UpsellContentProps = {
  featureName: string
}

type UpsellContentState = {
  bgURL: string
  isVideoModalOpen: boolean
}

// const TOPREC_VIDEO_URL = 'https://vimeo.com/982020128?share=copy'
const TOPREC_VIDEO_EXTERNAL_ID = '982020128'
const TOPREC_WEBSITE_URL = 'https://toprec.co'
const TOPREC_BOOK_DEMO_URL = 'https://toprec.co/#RequestPersonalDemo'

const VIDEO_PLAYER_SETTINGS_QUERY = qp.toString({
  autoplay: 1,
  title: 0,
  byline: 0,
  portrait: 0
})
class UpsellContent extends Component<UpsellContentProps, UpsellContentState> {
  state: Readonly<UpsellContentState> = {
    bgURL: '',
    isVideoModalOpen: false
  }

  componentDidMount(): void {
    this.fetchVimeo(TOPREC_VIDEO_EXTERNAL_ID)
  }

  fetchVimeo = async (id: string) => {
    const response = await fetch(
      `${window.location.protocol}//vimeo.com/api/v2/video/${id}.json`
    )
    const json = await response.json()
    const thumbnail =
      json[0].thumbnail_large ||
      json[0].thumbnail_medium ||
      json[0].thumbnail_small

    this.setState({
      bgURL: `url("${thumbnail}")`
    })
  }

  render() {
    const { featureName } = this.props

    if (featureName === 'TopRec') {
      return (
        <div
          className="modal-content"
          style={{
            background: `transparent url("${ToprecGradientBackground}") 0% 0% no-repeat padding-box`,
            backgroundSize: 'cover',
            borderRadius: '8px',
            padding: '0.5rem'
          }}
        >
          <Grid container style={{ padding: '1rem', width: '100%' }}>
            <Grid
              item
              sm={8}
              component={Box}
              padding={{ xs: '0', sm: '2rem 1rem' }}
            >
              <div>
                <img src={TopRecLogo} width="100" height="40" alt="TopRec" />
              </div>
              <div>
                <Typography
                  variant="h6"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    marginTop: '1.5rem'
                  }}
                >
                  You are not using TopRec!
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ color: 'black', marginBottom: '1.5rem' }}
                >
                  TopRec monitors the performance of your team and encourages
                  positive testimonials from users, then displays them
                  prominently in the places that matter the most to potential
                  new leads.
                </Typography>
              </div>
              <Box display="flex" justifyContent="start">
                <Button
                  variant="contained"
                  style={{ marginRight: '1rem', background: '#F553B4' }}
                  startIcon={<OpenInNewIcon />}
                  onClick={() => window.open(TOPREC_WEBSITE_URL, '_blank')}
                >
                  Visit Website
                </Button>
                <Button
                  variant="contained"
                  style={{ background: '#F553B4' }}
                  startIcon={<OpenInNewIcon />}
                  onClick={() => window.open(TOPREC_BOOK_DEMO_URL, '_blank')}
                >
                  Book a demo now
                </Button>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <div style={{ height: '100%' }}>
                <div
                  className="hashtag-item__video"
                  style={{
                    backgroundImage: `url(${ToprecVideoThumbnail})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px'
                  }}
                  onClick={() => this.setState({ isVideoModalOpen: true })}
                >
                  <Fab
                    className="refari-play-button"
                    size="small"
                    style={{ color: '#F553B4', backgroundColor: '#F553B4' }}
                    aria-label="play"
                  >
                    <PlayArrowIcon
                      style={{ width: 20, margin: 0, color: 'white' }}
                    />
                  </Fab>
                </div>
              </div>
            </Grid>
          </Grid>
          <Dialog
            className="refari-w-modal-container modal refari-modal consultant-preview-modal"
            fullWidth
            open={this.state.isVideoModalOpen}
            onClose={() => this.setState({ isVideoModalOpen: false })}
          >
            <div
              className="refari-modal-content"
              style={{ overflow: 'hidden' }}
            >
              <div className="refari-video-wrapper">
                <iframe
                  ref="player"
                  key="frame"
                  id="vm-preview"
                  title="preview"
                  src={`https://player.vimeo.com/video/${TOPREC_VIDEO_EXTERNAL_ID}?${VIDEO_PLAYER_SETTINGS_QUERY}`}
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay"
                />
              </div>
            </div>
          </Dialog>
        </div>
      )
    }

    return (
      <div className="modal-content">
        <div className="toprec-box">
          <div className="toprec-img">
            <img src={TopRecImage} className="img-fluid" alt="" />
          </div>
          <div className="toprec-cont">
            <h5>You&apos;re not using {featureName}</h5>
            <p>
              To view this page, Please get in contact to your system
              administrator to enable the {featureName} feature.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default UpsellContent
