import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const propTypes = {
  fill: PropTypes.string,
  styles: PropTypes.object
}

const defaultProps = {
  fill: '#3886D1',
  styles: {}
}

const TestimonialsShareIcon = ({ fill, styles }) => {
  const style = {
    width: '15px',
    height: '13px'
  }
  return (
    <SvgIcon viewBox="0 0 18 15" style={{ ...style, ...styles }}>
      <path
        d="M18 7L11 0V4C4 5 1 10 0 15C2.5 11.5 6 9.9 11 9.9V14L18 7Z"
        fill={fill}
      />
    </SvgIcon>
  )
}

export default withTheme(TestimonialsShareIcon)
TestimonialsShareIcon.propTypes = propTypes
TestimonialsShareIcon.defaultProps = defaultProps
