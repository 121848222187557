/* eslint-disable class-methods-use-this */
import { observable, action, computed } from 'mobx'
import RootStore from './RootStore'
import standAloneStore from './StandAloneStore'
import permissions from '../constants/permissions'
import routesUrls from 'src/router/routesUrls'

export class DashboardStore {
  @observable activeTab = ''

  @action
  initTab = (path) => {
    this.activeTab = path
  }

  @action
  onTabChange = (link, cb) => {
    RootStore.resetSearchParams()
    RootStore.resetSelectedMessageTemplate()

    standAloneStore.resetLocationFieldIds()
    standAloneStore.resetLocationRecord()

    cb && cb(link)
    this.activeTab = link
  }

  @computed
  get dashboardPermissions() {
    const {
      user: { role, userType },
      agency
    } = RootStore
    if (RootStore.loggedIn) {
      if (agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToDashboard.pages
      }

      if (role) {
        if (userType === 'agency') {
          return this.showCandidateAdsIfTalentBoardIsEnabled({ userType, role })
        }

        return permissions[userType][role].hasAccessToDashboard.pages
      }

      return permissions[userType].hasAccessToDashboard.pages
    }
    return []
  }

  showCandidateAdsIfTalentBoardIsEnabled = ({ userType, role }) => {
    const dashboardPages =
      permissions[userType][role].hasAccessToDashboard.pages

    if (RootStore.agency.hasTalentBoard) {
      return dashboardPages
    }

    return dashboardPages.filter(
      (page) => page.path !== routesUrls.dashboard.children.candidateAds.path
    )
  }
}

export default new DashboardStore()
