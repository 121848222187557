/* eslint-disable prefer-destructuring */
/* eslint-disable react/prefer-stateless-function */
import React, { useState } from 'react'
import Radium from 'radium'
import PropTypes from 'prop-types'
import { TextareaAutosize } from '@material-ui/core'
import PencilIcon from '../icons/PencilIcon'

const { Style } = Radium
class CustomTextareaWrap extends React.Component {
  static propTypes = {
    wrapDiv: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    handleChange: PropTypes.func,
    handleSize: PropTypes.func,
    handleEdit: PropTypes.func,
    inputClass: PropTypes.string,
    placeholder: PropTypes.string,
    iconFill: PropTypes.string,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    placeholderColor: PropTypes.string
  }

  static defaultProps = {
    rows: 2,
    rowsMax: 4,
    placeholderColor: '#757575',
    inputClass: 'refari-textarea-box'
  }

  constructor(props) {
    super(props)
    // this.resizeObserver = null
    this.textBox = null
    this.textBoxHeight = undefined
    // this.numberOfCharacters = undefined
    this.mouseDown = false
  }

  componentDidMount() {
    const { inputClass, handleSize } = this.props
    const container = document.getElementsByClassName(inputClass)
    if (container && container.length > 0 && handleSize) {
      // this.mutationObserver = new window.MutationObserver(this.outputSize)
      this.textBox = container[0]
      this.textBoxHeight = this.textBox.offsetHeight
      // this.mutationObserver.observe(this.textBox, {
      //   attributes: true,
      //   attributeFilter: ['style']
      // })
      if (typeof window !== 'undefined' && window.ResizeObserver) {
        this.resizeObserver = new ResizeObserver((entries) => {
          if (!this.mouseDown) this.textBoxHeight = this.textBox.offsetHeight
        })
        this.resizeObserver.observe(this.textBox)
      }
    }
  }

  outputSize = () => {
    const { handleSize } = this.props
    if (this.textBox && this.textBoxHeight !== this.textBox.offsetHeight) {
      handleSize &&
        handleSize(this.textBox.offsetWidth, this.textBox.offsetHeight)
    }
    this.mouseDown = false
  }

  render() {
    const {
      wrapDiv,
      value,
      name,
      handleChange,
      handleEdit,
      inputClass,
      placeholder,
      iconFill,
      rows,
      rowsMax,
      style,
      placeholderColor
    } = this.props

    return (
      <div className={wrapDiv}>
        <Style
          scopeSelector={`.${inputClass}::placeholder`}
          rules={{
            color: placeholderColor
          }}
        />
        <TextareaAutosize
          value={value}
          name={name}
          style={style}
          onChange={handleChange}
          className={`${inputClass} custom-text-field`}
          placeholder={placeholder}
          minRows={rows}
          maxRows={rowsMax}
          onMouseDown={() => {
            this.mouseDown = true
          }}
          onMouseUp={this.outputSize}
        />
        <PencilIcon
          className="pencil-icon"
          fill={iconFill}
          onClick={handleEdit}
          style={{ cursor: 'pointer' }}
        />
      </div>
    )
  }
}

export default CustomTextareaWrap
