import React from 'react'
import { IconButton, withTheme } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import MuiAutocomplete, {
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { AddCircle } from '@material-ui/icons'
import Chip from '@material-ui/core/Chip'

import type { Data } from './types'
import { baseURLs } from 'src/utils/Urls'
import greenTick from 'src/assets/images/green_tick.svg'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

type AutoCompleteProps = {
  autoCompleteID: string
  multiple?: boolean
  options: Array<string | Data>
  openSaveModal: () => void
  selectedOptions: string | string[] | Data
  onChangeData: (
    selectedValues: NonNullable<string | Data> | (string | Data)[]
  ) => void
  autoCompleteTextFieldPlaceholder?: string
  disableAutoCompleteCloseOnSelect?: boolean
  getOptionalLabel: (option: string | Data) => string
  renderChipValue: (option: string | Data) => React.ReactNode
  renderOption: (option: string | Data) => React.ReactNode
  getOptionSelected: (option: string | Data, value: string | Data) => boolean
  disabled?: boolean
  // Used to disable the creation of new options
  // in certain situations
  disableCreation?: boolean
  showTicks?: boolean
}

function AutoComplete(props: AutoCompleteProps) {
  const {
    autoCompleteID,
    options,
    openSaveModal,
    onChangeData,
    selectedOptions,
    multiple = false,
    autoCompleteTextFieldPlaceholder = '',
    disableAutoCompleteCloseOnSelect = false,
    getOptionalLabel,
    renderChipValue,
    renderOption,
    getOptionSelected,
    disabled = false,
    disableCreation = false,
    showTicks = false
  } = props
  /**
   * @todo bug here
   * try to remove the tag from opened popover
   */
  return (
    <MuiAutocomplete
      disabled={disabled}
      disableClearable
      multiple={multiple}
      id={autoCompleteID}
      options={options}
      value={selectedOptions}
      getOptionSelected={getOptionSelected}
      onChange={(_event, selectedData) => {
        onChangeData(selectedData)
      }}
      disableCloseOnSelect={disableAutoCompleteCloseOnSelect}
      getOptionLabel={getOptionalLabel}
      style={{
        position: 'relative'
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {multiple && (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {renderOption(option)}
        </React.Fragment>
      )}
      renderTags={
        multiple
          ? (value, tagProps) => {
              return (
                <>
                  {value.map((value, index) => (
                    <Chip
                      key={index}
                      color="primary"
                      label={renderChipValue(value)}
                      {...tagProps({ index })}
                    />
                  ))}
                </>
              )
            }
          : undefined
      }
      renderInput={(params: AutocompleteRenderInputParams) => {
        // @ts-ignore invalid type for inputProps
        const value = params.inputProps?.value
        const isValidOptionSelected = value
          ? options.map(getOptionalLabel).includes(value)
          : false
        return (
          <>
            <TextField
              {...params}
              variant="standard"
              placeholder={autoCompleteTextFieldPlaceholder}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
            />

            {showTicks && disableCreation && isValidOptionSelected ? (
              <div
                style={{ position: 'absolute', right: '25px', bottom: '12px' }}
              >
                <span>
                  <img
                    src={`${baseURLs('widget').replace(/\/$/, '')}${greenTick}`}
                    alt="tick"
                  />
                </span>
              </div>
            ) : null}

            {!disableCreation && (
              <IconButton
                onClick={() => {
                  openSaveModal()
                }}
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                edge="end"
                color="primary"
                style={{
                  position: 'absolute',
                  right: '25px',
                  bottom: '7px'
                }}
              >
                <AddCircle />
              </IconButton>
            )}
          </>
        )
      }}
    />
  )
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(AutoComplete)
