/* eslint-disable max-len */
import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import axios from 'axios'
import API from '../utils/API'
import {
  ApiRoutes,
} from '../utils/Urls'
import alertMessages from '../constants/alertMessages'
import { mapFormServerErrors } from '../utils/helpers'
import Logger from '../utils/Logger'
import AuthSession from '../utils/AuthSession'
import RootStore from './RootStore'

export class AuthTwoFactorStore {
  @observable isOpenPhoneVerificationModal = false
  @observable isOpenTwoFactorModal = false
  @observable isOpenSuccessModal = false
  @observable isOpenOneTimePasswordModal = false
  @observable isOpenAuthAppCodeModal = false
  @observable signInToken = null
  @observable signInCallback = null
  @observable deviceIds = []
  @observable phoneIds = []
  @observable isLoading = false
  @observable isInitialLoading = false
  @observable phoneID = null
  @observable checkData = null
  @observable authAppID = null
  @observable qrcode = null
  @observable phoneData = null

  @action
  togglePhoneVerificationModal = (data = null) => {
    this.checkData = data
    this.phoneID = null
    this.isOpenPhoneVerificationModal = !this.isOpenPhoneVerificationModal
  }

  @action
  toggleTwoFactorModal = () => {
    this.isOpenTwoFactorModal = !this.isOpenTwoFactorModal
  }

  @action
  toggleSuccessModal = () => {
    this.isOpenSuccessModal = !this.isOpenSuccessModal
  }

  @action
  toggleOneTimePasswordModal = () => {
    this.isOpenOneTimePasswordModal = !this.isOpenOneTimePasswordModal
  }

  @action
  toggleAuthAppCodeModal = (signInToken = null, cb = null) => {
    this.signInToken = signInToken
    this.signInCallback = cb
    this.isOpenAuthAppCodeModal = !this.isOpenAuthAppCodeModal
  }

  @action
  checkDeviceAvailable = async (cb) => {
    try {
      runInAction(() => { this.isInitialLoading = true })
      const response = await API.getData(ApiRoutes.auth.twoFactor.apps.list)
      const res = await API.getData(ApiRoutes.auth.twoFactor.phones.list)
      runInAction(() => {
        this.deviceIds = response.data || []
        this.phoneIds = res.data || []
        this.isInitialLoading = false
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isInitialLoading = false })
    }
  }

  @action
  updatePhoneNumber = async (form, cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      const data = { phone: form.data.phone }
      const response = await API.postData(ApiRoutes.auth.twoFactor.phones.list, data)
      runInAction(() => {
        this.phoneID = response.data.id
        this.isLoading = false
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  deletePhoneNumber = async (id, cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      await API.deleteData(ApiRoutes.auth.twoFactor.phones.delete(id))
      await this.checkDeviceAvailable()
      runInAction(() => {
        this.isLoading = false
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
    }
  }

  @action
  verifyPhoneCode = async (form, cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      const data = { token: form.data.token }
      await API.postData(ApiRoutes.auth.twoFactor.phones.verify(this.phoneID), data)
      if (this.phoneIds && this.phoneIds.length > 0) {
        await this.deletePhoneNumber(this.phoneIds[0].id)
      } else {
        await this.checkDeviceAvailable()
      }
      if (!this.checkData || (this.checkData && this.checkData.both)) {
        await this.updateAuthDevice()
      }

      runInAction(() => {
        this.phoneID = null
        this.isLoading = false
        if (this.checkData && this.checkData.onlyPhone) {
          form.reset()
          this.togglePhoneVerificationModal()
          this.toggleSuccessModal()
        } else {
          form.reset()
          this.togglePhoneVerificationModal()
          this.toggleTwoFactorModal()
        }
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
      if (error.status && error.status === 400 && error.data) {
        toast.error(error.data.token && error.data.token.length > 0 ? error.data.token[0] : alertMessages.error)
      }
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  resendPhoneCode = async (cb) => {
    try {
      await API.postData(ApiRoutes.auth.twoFactor.phones.resend(this.phoneID), {})
      toast.success(alertMessages.codeSent)
      cb && cb()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateAuthDevice = async (cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      const response = await API.postData(ApiRoutes.auth.twoFactor.apps.list)
      await this.getQRCode(response.data.id)
      runInAction(() => {
        this.authAppID = response.data.id
        this.isLoading = false
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
    }
  }

  @action
  deleteAuthDevice = async (id, cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      await API.deleteData(ApiRoutes.auth.twoFactor.apps.delete(id))
      await this.checkDeviceAvailable()
      runInAction(() => {
        this.isLoading = false
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
    }
  }

  @action
  getQRCode = async (id) => {
    try {
      const res = await API.getData(ApiRoutes.auth.twoFactor.apps.qrcode(id))
      if (res && res.data) {
        runInAction(() => { this.qrcode = res.data })
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  verifyAuthCode = async (form, cb) => {
    try {
      runInAction(() => { this.isLoading = true })
      const data = { token: form.data.token }
      await API.postData(ApiRoutes.auth.twoFactor.apps.verify(this.authAppID), data)
      if (this.deviceIds && this.deviceIds.length > 0) {
        await this.deleteAuthDevice(this.deviceIds[0].id)
      } else {
        await this.checkDeviceAvailable()
      }
      runInAction(() => {
        this.isLoading = false
        this.toggleTwoFactorModal()
        form.reset()
        this.phoneID = null
        this.authAppID = null
        this.toggleSuccessModal()
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
      if (error.status && error.status === 400 && error.data) {
        toast.error(error.data.token && error.data.token.length > 0 ? error.data.token[0] : alertMessages.error)
      }
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  verifySignInAuthCode = async (form, cb) => {
    try {
      if (this.signInToken) {
        runInAction(() => { this.isLoading = true })
        const data = { token: form.data.token }
        axios.defaults.headers.token = this.signInToken
        const response = await API.postData(ApiRoutes.auth.twoFactor.verify, data)
        delete axios.defaults.headers.token
        runInAction(() => {
          this.isLoading = false
          AuthSession.set(response.data.key)
          AuthSession.setGlobal(true, 'RefariLoggedIn')
          RootStore.initialProfileRequest(this.signInCallback)
          this.toggleAuthAppCodeModal()
          this.isOpenOneTimePasswordModal = false
          form.reset()
          toast.success(alertMessages.signin)
        })
        cb && cb()
      } else {
        toast.error(alertMessages.error)
      }
    } catch (error) {
      Logger.error(error)
      runInAction(() => { this.isLoading = false })
      if (error.status && error.status === 400 && error.data) {
        toast.error(error.data.token && error.data.token.length > 0 ? error.data.token[0] : alertMessages.error)
      }
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  sendBackupPhoneCode = async (cb) => {
    try {
      axios.defaults.headers.token = this.signInToken
      const res = await API.postData(ApiRoutes.auth.twoFactor.backup)
      delete axios.defaults.headers.token
      runInAction(() => {
        this.phoneData = res.data
      })
      cb && cb()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new AuthTwoFactorStore()
