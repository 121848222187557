import React from 'react'
import hexToRgba from 'hex-to-rgba'
import { observable, action } from 'mobx'
import { toast } from 'react-toastify'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import classnames from 'classnames'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
  Chip,
  Fab,
  IconButton
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import Clear from '@material-ui/icons/Clear'
import AvatarPlaceholder from '../../../shared/common/AvatarPlaceholder'
import TickIcon from '../../../shared/icons/TickIcon'
import StarIcon from '../../../shared/icons/StarIcon'
import QuotesIcon from '../../../shared/icons/QuotesIcon'
import { pSBC, getContrastColor } from '../../../../utils/helpers'
import RaisedButton from '../../../shared/common/RaisedButton'
import FlatButton from '../../../shared/common/FlatButton'
import PencilIcon from '../../../shared/icons/PencilIcon'
import MoveIcon from '../../../shared/icons/MoveIcon'
import CustomTextareaWrap from '../../../shared/formComponents/CustomTextareaWrap'
import { CustomInputWrap } from '../../../shared/formComponents/CustomInputWrap'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import Youtube from '../../../shared/modals/VideoModal/Youtube'
import Vimeo from '../../../shared/modals/VideoModal/Vimeo'
import Loom from '../../../shared/modals/VideoModal/Loom'
import PitchMeModal from '../../../shared/modals/pitchMe/PitchMeModal'
import PitchMeCTAModal from '../../../shared/modals/pitchMe/PitchMeCTAModal'
import PitchMeInputEditModal from '../../../shared/modals/pitchMe/PitchMeInputEditModal'
import AutoCompleteComponent from '../../../shared/common/AutoComplate'
import ButtonLink from '../../../shared/buttons/ButtonLink'
import SmartPitchTestimonialDetailsModal from './SmartPitchTestimonialDetailsModal'
import SmartPitchSettingsModal from '../../../shared/modals/pitchMe/SmartPitchSettingsModal'

@inject('rootStore', 'utilsStore', 'pitchMeStore')
@observer
class PitchMe extends React.Component {
  @observable myPlay = false
  @observable myThumbnailUrl = ''

  @observable clientPlay = false
  @observable clientThumbnailUrl = ''

  constructor(props) {
    super(props)
    props.pitchMeStore.checkSiteFontFamily()
    const customTextColor = '#000000'
    props.pitchMeStore.setData('textColor', customTextColor)
    props.pitchMeStore.resetStoreData()

    this.closeTestimonialDetailsModal =
      this.closeTestimonialDetailsModal.bind(this)

    this.form = new FieldsGroup({
      myVideoURL: new Field({
        name: 'myVideoURL',
        value: ''
      }),
      clientVideoURL: new Field({
        name: 'clientVideoURL',
        value: ''
      }),
      template: new Field({
        name: 'template',
        value: ''
      })
    })

    const { previewData } = props.pitchMeStore
    this.state = {
      buttonWidth:
        previewData && previewData.pitchMeCTAWidth
          ? previewData.pitchMeCTAWidth
          : props.pitchMeStore.pitchMeCTAWidth,
      buttonHeight:
        previewData && previewData.pitchMeCTAHeight
          ? previewData.pitchMeCTAHeight
          : props.pitchMeStore.pitchMeCTAHeight,
      deltaPosition:
        previewData && previewData.pitchMeCTAAlignment
          ? previewData.pitchMeCTAAlignment
          : props.pitchMeStore.pitchMeCTAAlignment,
      pitchMeTitle:
        // eslint-disable-next-line no-nested-ternary
        previewData && previewData.pitchMeTitle
          ? previewData.pitchMeTitle
          : props.data && props.data.fullName
          ? `Hello, my name is ${props.data.fullName}`
          : '',
      pitchMeDescription:
        previewData && previewData.pitchMeDescription
          ? previewData.pitchMeDescription.replace(/<br\s*\/?>/gi, '\n')
          : 'One of the things about approaching someone via the web is you never really get the opportunity to demonstrate why or even if you are different. Below is a video of me, some details about my industry specialities and what current and past clients say about working with me.',
      pitchMeClosingText:
        previewData && previewData.pitchMeClosingText
          ? previewData.pitchMeClosingText.replace(/<br\s*\/?>/gi, '\n')
          : '',
      pitchMeRegardsText:
        // eslint-disable-next-line no-nested-ternary
        previewData && previewData.pitchMeRegardsText
          ? previewData.pitchMeRegardsText.replace(/<br\s*\/?>/gi, '\n')
          : props.data && props.data.firstName
          ? `Kind Regards,\n${props.data.firstName}${
              props.data.phone ? `\n${props.data.phone}` : ''
            }\n${props.data.email}`
          : '',
      myLinkChange:
        previewData && previewData.myLinkChange !== undefined
          ? previewData.myLinkChange
          : !(props.data && props.data.video && props.data.video.external_id),
      clientLinkChange:
        previewData && previewData.clientLinkChange !== undefined
          ? previewData.clientLinkChange
          : true,
      pitchMeHelpTitle:
        previewData && previewData.pitchMeHelpTitle
          ? previewData.pitchMeHelpTitle
          : 'How can I help you?',
      pitchMeExpertiseTitle:
        previewData && previewData.pitchMeExpertiseTitle
          ? previewData.pitchMeExpertiseTitle
          : 'Areas of expertise:',
      pitchMeJobTitle:
        previewData && previewData.pitchMeJobTitle
          ? previewData.pitchMeJobTitle
          : 'Recently placed roles that could be of interest:',
      pitchMeClientTitle:
        previewData && previewData.pitchMeClientTitle
          ? previewData.pitchMeClientTitle
          : 'What clients say about me:',
      pitchMeMyTestimonials:
        previewData && previewData.pitchMeMyTestimonials
          ? previewData.pitchMeMyTestimonials
          : 'My Testimonials',
      testimonialsDetailsModal: {
        open: false,
        testimonialData: null
      }
    }
    window.setMyThumb = this.setMyThumb
    window.setClientThumb = this.setClientThumb

    Object.keys(this.state).map((key) => {
      if (key === 'testimonialsDetailsModal') return
      let parsedKey = key

      parsedKey = key === 'buttonWidth' ? 'pitchMeCTAWidth' : key
      parsedKey = key === 'buttonHeight' ? 'pitchMeCTAHeight' : key
      parsedKey = key === 'deltaPosition' ? 'pitchMeCTAAlignment' : key
      props.pitchMeStore.updateInitialAdditionalPitchMeState(
        parsedKey,
        this.state[key]
      )
    })

    if (previewData && previewData.pitchMeMyVideoURL) {
      this.form.fields.myVideoURL.update(previewData.pitchMeMyVideoURL)
    } else if (props.data && props.data.video && props.data.video.external_id) {
      this.form.fields.myVideoURL.update(props.data.video.url)
      if (
        props.data.video.provider === 'youtube' ||
        props.data.video.provider === 'loom'
      ) {
        this.setMyThumb()
      } else {
        this.getMyThumb(props.data.video.external_id)
      }
    }

    if (previewData && previewData.pitchMeClientVideoURL) {
      this.form.fields.clientVideoURL.update(previewData.pitchMeClientVideoURL)
    } else if (
      props.data &&
      props.data.clientVideo &&
      props.data.clientVideo.external_id
    ) {
      if (
        props.data.clientVideo.provider === 'youtube' ||
        props.data.clientVideo.provider === 'loom'
      ) {
        this.setClientThumb()
      } else {
        this.getClientThumb(props.data.clientVideo.external_id)
      }
    }
  }

  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY
      }
    })
  }

  @action
  myTogglePlay = (bool) => {
    this.myPlay = typeof bool === 'boolean' ? bool : !this.myPlay
  }

  resetPitchMeState = () => {
    const { pitchMeStore, data } = this.props
    const { previewData } = pitchMeStore

    this.setState(
      {
        buttonWidth:
          previewData && previewData.pitchMeCTAWidth
            ? previewData.pitchMeCTAWidth
            : pitchMeStore.pitchMeCTAWidth,
        buttonHeight:
          previewData && previewData.pitchMeCTAHeight
            ? previewData.pitchMeCTAHeight
            : pitchMeStore.pitchMeCTAHeight,
        deltaPosition:
          previewData && previewData.pitchMeCTAAlignment
            ? previewData.pitchMeCTAAlignment
            : pitchMeStore.pitchMeCTAAlignment,
        pitchMeTitle:
          // eslint-disable-next-line no-nested-ternary
          previewData && previewData.pitchMeTitle
            ? previewData.pitchMeTitle
            : data && data.fullName
            ? `Hello, my name is ${data.fullName}`
            : '',
        pitchMeDescription:
          previewData && previewData.pitchMeDescription
            ? previewData.pitchMeDescription.replace(/<br\s*\/?>/gi, '\n')
            : 'One of the things about approaching someone via the web is you never really get the opportunity to demonstrate why or even if you are different. Below is a video of me, some details about my industry specialities and what current and past clients say about working with me.',
        pitchMeClosingText:
          previewData && previewData.pitchMeClosingText
            ? previewData.pitchMeClosingText.replace(/<br\s*\/?>/gi, '\n')
            : '',
        pitchMeRegardsText:
          // eslint-disable-next-line no-nested-ternary
          previewData && previewData.pitchMeRegardsText
            ? previewData.pitchMeRegardsText.replace(/<br\s*\/?>/gi, '\n')
            : data && data.firstName
            ? `Kind Regards,\n${data.firstName}${
                data.phone ? `\n${data.phone}` : ''
              }\n${data.email}`
            : '',
        myLinkChange:
          previewData && previewData.myLinkChange !== undefined
            ? previewData.myLinkChange
            : !(data && data.video && data.video.external_id),
        clientLinkChange:
          previewData && previewData.clientLinkChange !== undefined
            ? previewData.clientLinkChange
            : true,
        pitchMeHelpTitle:
          previewData && previewData.pitchMeHelpTitle
            ? previewData.pitchMeHelpTitle
            : 'How can I help you?',
        pitchMeExpertiseTitle:
          previewData && previewData.pitchMeExpertiseTitle
            ? previewData.pitchMeExpertiseTitle
            : 'Areas of expertise:',
        pitchMeJobTitle:
          previewData && previewData.pitchMeJobTitle
            ? previewData.pitchMeJobTitle
            : 'Recently placed roles that could be of interest:',
        pitchMeClientTitle:
          previewData && previewData.pitchMeClientTitle
            ? previewData.pitchMeClientTitle
            : 'What clients say about me:',
        pitchMeMyTestimonials:
          previewData && previewData.pitchMeMyTestimonials
            ? previewData.pitchMeMyTestimonials
            : 'My Testimonials'
      },
      () => {
        Object.keys(this.state).map((key) => {
          if (key === 'testimonialsDetailsModal') return
          let parsedKey = key

          parsedKey = key === 'buttonWidth' ? 'pitchMeCTAWidth' : key
          parsedKey = key === 'buttonHeight' ? 'pitchMeCTAHeight' : key
          parsedKey = key === 'deltaPosition' ? 'pitchMeCTAAlignment' : key
          this.props.pitchMeStore.updateInitialAdditionalPitchMeState(
            parsedKey,
            this.state[key]
          )
        })
      }
    )
  }

  resetFormData = () => {
    this.form.reset()
    this.resetPitchMeState()
  }

  clearMyURL = () => {
    const { pitchMeStore, removeVideo } = this.props
    if (
      pitchMeStore.previewData &&
      pitchMeStore.previewData.myVideoObj &&
      pitchMeStore.previewData.myVideoObj.external_id
    ) {
      const preview = { ...pitchMeStore.previewData }
      preview.myVideoObj = {}
      pitchMeStore.setData('previewData', preview)
    }
    removeVideo()
    this.form.fields.myVideoURL.update('')
  }

  clearClientURL = () => {
    const { pitchMeStore } = this.props
    if (
      pitchMeStore.previewData &&
      pitchMeStore.previewData.clientVideoObj &&
      pitchMeStore.previewData.clientVideoObj.external_id
    ) {
      const preview = { ...pitchMeStore.previewData }
      preview.clientVideoObj = {}
      pitchMeStore.setData('previewData', preview)
    }
    this.form.fields.clientVideoURL.update('')
  }

  @action
  clientTogglePlay = (bool) => {
    this.clientPlay = typeof bool === 'boolean' ? bool : !this.clientPlay
  }

  @action
  setMyThumb = (getdata) => {
    const { data } = this.props
    if (data.video && data.video.external_id) {
      switch (data.video.provider) {
        case 'youtube':
          this.myThumbnailUrl = `https://img.youtube.com/vi/${data.video.external_id}/0.jpg`
          break
        case 'vimeo':
          if (getdata && getdata.thumbnail_url) {
            this.myThumbnailUrl = getdata.thumbnail_url
          }
          break
        case 'loom':
          this.myThumbnailUrl = `https://cdn.loom.com/sessions/thumbnails/${data.video.external_id}-00001.jpg`
          break
        default:
          break
      }
    }
  }

  getMyThumb = (id) => {
    const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}&callback=setMyThumb`
    if (this.myscript) {
      this.myscript.src = url
      return
    }
    this.myscript = document.createElement('script')
    this.myscript.type = 'text/javascript'
    this.myscript.src = url
    document.body.appendChild(this.myscript)
  }

  @action
  setClientThumb = (getdata) => {
    const { data } = this.props
    if (data.clientVideo && data.clientVideo.external_id) {
      switch (data.clientVideo.provider) {
        case 'youtube':
          this.clientThumbnailUrl = `https://img.youtube.com/vi/${data.clientVideo.external_id}/0.jpg`
          break
        case 'vimeo':
          if (getdata.thumbnail_url) {
            this.clientThumbnailUrl = getdata.thumbnail_url
          }
          break
        case 'loom':
          this.clientThumbnailUrl = `https://cdn.loom.com/sessions/thumbnails/${data.clientVideo.external_id}-00001.jpg`
          break
        default:
          break
      }
    }
  }

  getClientThumb = (id) => {
    const url = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}&callback=setClientThumb`
    if (this.clientscript) {
      this.clientscript.src = url
      return
    }
    this.clientscript = document.createElement('script')
    this.clientscript.type = 'text/javascript'
    this.clientscript.src = url
    document.body.appendChild(this.clientscript)
  }

  componentDidMount() {
    const { pitchMeStore } = this.props
    pitchMeStore.fetchTemplates()
  }

  componentDidUpdate() {
    const { pitchMeStore, data } = this.props
    if (!pitchMeStore.isComponentUpdated) {
      const { previewData } = pitchMeStore
      if (previewData) {
        pitchMeStore.setPreviewData(previewData)
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        buttonWidth:
          previewData && previewData.pitchMeCTAWidth
            ? previewData.pitchMeCTAWidth
            : pitchMeStore.pitchMeCTAWidth,
        buttonHeight:
          previewData && previewData.pitchMeCTAHeight
            ? previewData.pitchMeCTAHeight
            : pitchMeStore.pitchMeCTAHeight,
        deltaPosition:
          previewData && previewData.pitchMeCTAAlignment
            ? previewData.pitchMeCTAAlignment
            : pitchMeStore.pitchMeCTAAlignment,
        pitchMeTitle:
          // eslint-disable-next-line no-nested-ternary
          previewData && previewData.pitchMeTitle
            ? previewData.pitchMeTitle
            : data && data.fullName
            ? `Hello, my name is ${data.fullName}`
            : '',
        pitchMeDescription:
          previewData && previewData.pitchMeDescription
            ? previewData.pitchMeDescription.replace(/<br\s*\/?>/gi, '\n')
            : 'One of the things about approaching someone via the web is you never really get the opportunity to demonstrate why or even if you are different. Below is a video of me, some details about my industry specialities and what current and past clients say about working with me.',
        pitchMeClosingText:
          previewData && previewData.pitchMeClosingText
            ? previewData.pitchMeClosingText.replace(/<br\s*\/?>/gi, '\n')
            : '',
        pitchMeRegardsText:
          // eslint-disable-next-line no-nested-ternary
          previewData && previewData.pitchMeRegardsText
            ? previewData.pitchMeRegardsText.replace(/<br\s*\/?>/gi, '\n')
            : data && data.firstName
            ? `Kind Regards,\n${data.firstName}${
                data.phone ? `\n${data.phone}` : ''
              }\n${data.email}`
            : '',
        myLinkChange:
          previewData && previewData.myLinkChange !== undefined
            ? previewData.myLinkChange
            : !(data && data.video && data.video.external_id),
        clientLinkChange:
          previewData && previewData.clientLinkChange !== undefined
            ? previewData.clientLinkChange
            : true,
        pitchMeHelpTitle:
          previewData && previewData.pitchMeHelpTitle
            ? previewData.pitchMeHelpTitle
            : 'How can I help you?',
        pitchMeExpertiseTitle:
          previewData && previewData.pitchMeExpertiseTitle
            ? previewData.pitchMeExpertiseTitle
            : 'Areas of expertise:',
        pitchMeJobTitle:
          previewData && previewData.pitchMeJobTitle
            ? previewData.pitchMeJobTitle
            : 'Recently placed roles that could be of interest:',
        pitchMeClientTitle:
          previewData && previewData.pitchMeClientTitle
            ? previewData.pitchMeClientTitle
            : 'What clients say about me:',
        pitchMeMyTestimonials:
          previewData && previewData.pitchMeMyTestimonials
            ? previewData.pitchMeMyTestimonials
            : 'My Testimonials'
      })

      if (previewData && previewData.pitchMeMyVideoURL) {
        this.form.fields.myVideoURL.update(previewData.pitchMeMyVideoURL)
      } else if (data && data.video && data.video.external_id) {
        this.form.fields.myVideoURL.update(data.video.url)
        if (
          data.video.provider === 'youtube' ||
          data.video.provider === 'loom'
        ) {
          this.setMyThumb()
        } else {
          this.getMyThumb(data.video.external_id)
        }
      }

      if (previewData && previewData.pitchMeClientVideoURL) {
        this.form.fields.clientVideoURL.update(
          previewData.pitchMeClientVideoURL
        )
      } else if (data && data.clientVideo && data.clientVideo.external_id) {
        if (
          data.clientVideo.provider === 'youtube' ||
          data.clientVideo.provider === 'loom'
        ) {
          this.setClientThumb()
        } else {
          this.getClientThumb(data.clientVideo.external_id)
        }
      }

      pitchMeStore.setData(
        'enablePitchMeProfileSection',
        previewData ? previewData.showProfile : true
      )
      pitchMeStore.setData(
        'enablePitchMeHelpSection',
        previewData ? previewData.enablePitchMeHelpSection : true
      )
      pitchMeStore.setData(
        'enablePitchMeExpertiseSection',
        previewData ? previewData.enablePitchMeExpertiseSection : true
      )
      pitchMeStore.setData(
        'enablePitchMeJobSection',
        previewData ? previewData.enablePitchMeJobSection : true
      )
      pitchMeStore.setData(
        'enablePitchMeClientSection',
        previewData ? previewData.enablePitchMeClientSection : true
      )

      pitchMeStore.setData('isComponentUpdated', true)
      pitchMeStore.syncStateAfterUpdate()
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { data } = this.props
    if (data && data.video && data.video.external_id) {
      if (data.video.provider === 'youtube' || data.video.provider === 'loom') {
        this.setMyThumb()
      } else {
        this.getMyThumb(data.video.external_id)
      }
    }
    if (data && data.clientVideo && data.clientVideo.external_id) {
      if (
        data.clientVideo.provider === 'youtube' ||
        data.clientVideo.provider === 'loom'
      ) {
        this.setClientThumb()
      } else {
        this.getClientThumb(data.clientVideo.external_id)
      }
    }
  }

  componentWillUnmount() {
    this.myscript && this.myscript.parentNode.removeChild(this.myscript)
    this.clientscript &&
      this.clientscript.parentNode.removeChild(this.clientscript)
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })

    // key = e.target.name === "buttonWidth" ? "pitchMeCTAWidth" : e.target.name
    // key = e.target.name === "buttonHeight" ? "pitchMeCTAHeight" : e.target.name
    // key = e.target.name === "deltaPosition" ? "pitchMeCTAAlignment" : e.target.name
    // console.log('input change', e.target.name, e.target.value)
    this.props.pitchMeStore.updateAdditionalStates(
      e.target.name,
      e.target.value
    )
    this.props.pitchMeStore.syncStateAfterUpdate()
  }

  // isPitchMeUpdated = () => {}

  handleChange = (e) => {
    if (e.target && e.target.name) {
      this.form.fields[e.target.name].update(e.target.value)
    }
    this.props.pitchMeStore.syncStateAfterUpdate()
  }

  addCallToAction = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.togglePitchMeCTAModal()
  }

  renderMyFrame = (video) => {
    switch (video.provider) {
      case 'youtube':
        return <Youtube id={video.external_id} isPlay={this.myPlay} />
      case 'vimeo':
        return <Vimeo id={video.external_id} isPlay={this.myPlay} />
      case 'loom':
        return <Loom id={video.external_id} isPlay={this.myPlay} />
      default:
        return null
    }
  }

  renderClientFrame = (video) => {
    switch (video.provider) {
      case 'youtube':
        return <Youtube id={video.external_id} isPlay={this.clientPlay} />
      case 'vimeo':
        return <Vimeo id={video.external_id} isPlay={this.clientPlay} />
      case 'loom':
        return <Loom id={video.external_id} isPlay={this.clientPlay} />
      default:
        return null
    }
  }

  toggle = (value) => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData(value, !pitchMeStore[value])
    if (value === 'enablePitchMeHelpSection') {
      this.myTogglePlay(false)
    } else if (value === 'enablePitchMeClientSection') {
      this.clientTogglePlay(false)
    }
    this.props.pitchMeStore.syncStateAfterUpdate()
  }

  @action
  onMyLinkChange = () => {
    this.myPlay = false
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ myLinkChange: !this.state.myLinkChange })
  }

  @action
  onClientLinkChange = () => {
    this.clientPlay = false
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ clientLinkChange: !this.state.clientLinkChange })
  }

  jobSearch = (name) => {
    this.props.onSearchJobs(name)
  }

  selectJob = (job) => {
    if (this.props.selectedJobList && this.props.selectedJobList.length < 12) {
      this.props.selectJob(job)
    } else {
      toast.warning('You can only add a maximum of 4 jobs')
    }
  }

  handleRemove = (job) => () => {
    this.props.removeJob(job)
  }

  expertiseSearch = (name) => {
    this.props.onSearchExpertise(name)
  }

  selectExpertise = (expertise) => {
    this.props.selectExpertise(expertise)
  }

  handleExpertiseRemove = (expertise) => () => {
    this.props.removeExpertise(expertise)
  }

  testimonialSearch = (name) => {
    this.props.onSearchTestimonials(name)
  }

  selectTestimonial = (testimonial) => {
    if (
      this.props.selectedTestimonialList &&
      this.props.selectedTestimonialList.length < 4
    ) {
      this.props.selectTestimonial(testimonial)
    } else {
      toast.warning('You can only add a maximum of 4 testimonials')
    }
  }

  handleRemoveTestimonial = (testimonial) => () => {
    this.props.removeTestimonial(testimonial)
  }

  getAllowedLength = (isVerified) => {
    let allowedLength = 180

    if (isVerified) {
      allowedLength = 90
    }

    return allowedLength
  }

  shouldTruncate({ text, isVerified }) {
    return text.length > this.getAllowedLength(isVerified)
  }

  showText({ text, isVerified }) {
    if (this.shouldTruncate({ text, isVerified })) {
      return `${text.slice(0, this.getAllowedLength(isVerified))}...`
    }
    return text
  }

  dateFormat = (date) => moment(date).format('ll')

  savePreviewData = async () => {
    const {
      pitchMeStore,
      data,
      selectedJobList,
      selectedExpertiseList,
      selectedTestimonialList,
      pitchMeStore: {
        enablePitchMeHelpSection,
        enablePitchMeExpertiseSection,
        enablePitchMeJobSection,
        enablePitchMeClientSection,
        enablePitchMeProfileSection,
        selectedFont,
        selectedFontAlignment,
        isBold,
        isItalic,
        textColor,
        backgroundColor,
        titleTextColor,
        titleSelectedFont,
        titleSelectedFontAlignment,
        titleIsItalic,
        titleIsBold,
        titleSelectedFontSize,
        descriptionTextColor,
        descriptionSelectedFont,
        descriptionSelectedFontAlignment,
        descriptionIsItalic,
        descriptionIsBold,
        descriptionSelectedFontSize,
        helpTextColor,
        helpSelectedFont,
        helpSelectedFontAlignment,
        helpIsItalic,
        helpIsBold,
        helpSelectedFontSize,
        expertiseTextColor,
        expertiseSelectedFont,
        expertiseSelectedFontAlignment,
        expertiseIsItalic,
        expertiseIsBold,
        expertiseSelectedFontSize,
        jobTextColor,
        jobSelectedFont,
        jobSelectedFontAlignment,
        jobIsItalic,
        jobIsBold,
        jobSelectedFontSize,
        clientTextColor,
        clientSelectedFont,
        clientSelectedFontAlignment,
        clientIsItalic,
        clientIsBold,
        clientSelectedFontSize,
        closingTextColor,
        closingSelectedFont,
        closingSelectedFontAlignment,
        closingIsItalic,
        closingIsBold,
        closingSelectedFontSize,
        regardsTextColor,
        regardsSelectedFont,
        regardsSelectedFontAlignment,
        regardsIsItalic,
        regardsIsBold,
        regardsSelectedFontSize
      }
    } = this.props
    const {
      pitchMeTitle,
      pitchMeDescription,
      pitchMeClosingText,
      pitchMeRegardsText,
      myLinkChange,
      clientLinkChange,
      pitchMeHelpTitle,
      pitchMeExpertiseTitle,
      pitchMeJobTitle,
      pitchMeClientTitle,
      pitchMeMyTestimonials,
      deltaPosition,
      buttonWidth,
      buttonHeight
    } = this.state

    const previewData = {
      pitchMeTitle,
      titleTextColor,
      myLinkChange,
      clientLinkChange,
      selectedFont,
      selectedFontAlignment,
      isBold,
      isItalic,
      textColor,
      backgroundColor,
      titleSelectedFont,
      titleSelectedFontAlignment,
      titleIsItalic,
      titleIsBold,
      titleSelectedFontSize,
      pitchMeDescription: pitchMeDescription
        ? pitchMeDescription.replace(/\r\n|\r|\n/g, '<br />')
        : '',
      descriptionTextColor,
      descriptionSelectedFont,
      descriptionSelectedFontAlignment,
      descriptionIsItalic,
      descriptionIsBold,
      descriptionSelectedFontSize,
      pitchMeClosingText: pitchMeClosingText
        ? pitchMeClosingText.replace(/\r\n|\r|\n/g, '<br />')
        : '',
      closingTextColor,
      closingSelectedFont,
      closingSelectedFontAlignment,
      closingIsItalic,
      closingIsBold,
      closingSelectedFontSize,
      pitchMeRegardsText: pitchMeRegardsText
        ? pitchMeRegardsText.replace(/\r\n|\r|\n/g, '<br />')
        : '',
      regardsTextColor,
      regardsSelectedFont,
      regardsSelectedFontAlignment,
      regardsIsItalic,
      regardsIsBold,
      regardsSelectedFontSize,
      pitchMeHelpTitle,
      helpTextColor,
      helpSelectedFont,
      helpSelectedFontAlignment,
      helpIsItalic,
      helpIsBold,
      helpSelectedFontSize,
      pitchMeExpertiseTitle,
      expertiseTextColor,
      expertiseSelectedFont,
      expertiseSelectedFontAlignment,
      expertiseIsItalic,
      expertiseIsBold,
      expertiseSelectedFontSize,
      pitchMeJobTitle,
      jobTextColor,
      jobSelectedFont,
      jobSelectedFontAlignment,
      jobIsItalic,
      jobIsBold,
      jobSelectedFontSize,
      pitchMeClientTitle,
      pitchMeMyTestimonials,
      clientTextColor,
      clientSelectedFont,
      clientSelectedFontAlignment,
      clientIsItalic,
      clientIsBold,
      clientSelectedFontSize,
      showProfile: enablePitchMeProfileSection,
      enablePitchMeHelpSection,
      enablePitchMeExpertiseSection,
      enablePitchMeJobSection,
      enablePitchMeClientSection,
      pitchMeCTALink: pitchMeStore.pitchMeCTALink,
      pitchMeCTATitle: pitchMeStore.pitchMeCTATitle,
      pitchMeCTAWidth: buttonWidth,
      pitchMeCTAHeight: buttonHeight,
      pitchMeCTABorderRadius: pitchMeStore.pitchMeCTABorderRadius,
      pitchMeCTAFontSize: pitchMeStore.pitchMeCTAFontSize,
      pitchMeCTAAlignment: deltaPosition,
      // eslint-disable-next-line no-nested-ternary
      pitchMeMyVideoURL: enablePitchMeHelpSection
        ? // eslint-disable-next-line no-nested-ternary
          pitchMeStore.previewData &&
          pitchMeStore.previewData.myVideoObj &&
          pitchMeStore.previewData.myVideoObj.external_id &&
          !myLinkChange
          ? pitchMeStore.previewData.myVideoObj.url
          : data.video && data.video.external_id && !myLinkChange
          ? data.video.url
          : this.form.fields.myVideoURL.value || ''
        : '',
      // eslint-disable-next-line no-nested-ternary
      pitchMeClientVideoURL: enablePitchMeClientSection
        ? // eslint-disable-next-line no-nested-ternary
          pitchMeStore.previewData &&
          pitchMeStore.previewData.clientVideoObj &&
          pitchMeStore.previewData.clientVideoObj.external_id &&
          !clientLinkChange
          ? pitchMeStore.previewData.clientVideoObj.url
          : data.clientVideo &&
            data.clientVideo.external_id &&
            !clientLinkChange
          ? data.clientVideo.url
          : this.form.fields.clientVideoURL.value || ''
        : '',
      pitchMeExpertise: enablePitchMeExpertiseSection
        ? selectedExpertiseList.map((expertise) => expertise.name)
        : [],
      pitchMeSelectedJobList: enablePitchMeJobSection ? selectedJobList : [],
      pitchMeSelectedTestimonialList: enablePitchMeClientSection
        ? selectedTestimonialList
        : [],
      pitchMeConsultantData: { ...data, slug: data.slug }
    }

    pitchMeStore.setData('previewData', previewData)
    await pitchMeStore.validateData({
      video_1: previewData.pitchMeMyVideoURL,
      video_2: previewData.pitchMeClientVideoURL
    })
  }

  previewVideoThumb = (value, name) => async () => {
    if (value) {
      const {
        pitchMeStore: { enablePitchMeHelpSection, enablePitchMeClientSection },
        pitchMeStore,
        data
      } = this.props

      const { myLinkChange, clientLinkChange } = this.state

      // eslint-disable-next-line no-nested-ternary
      const pitchMeMyVideoURL = enablePitchMeHelpSection
        ? // eslint-disable-next-line no-nested-ternary
          pitchMeStore.previewData &&
          pitchMeStore.previewData.myVideoObj &&
          pitchMeStore.previewData.myVideoObj.external_id &&
          !myLinkChange
          ? pitchMeStore.previewData.myVideoObj.url
          : data.video && data.video.external_id && !myLinkChange
          ? data.video.url
          : this.form.fields.myVideoURL.value || ''
        : ''

      // eslint-disable-next-line no-nested-ternary
      const pitchMeClientVideoURL = enablePitchMeClientSection
        ? // eslint-disable-next-line no-nested-ternary
          pitchMeStore.previewData &&
          pitchMeStore.previewData.clientVideoObj &&
          pitchMeStore.previewData.clientVideoObj.external_id &&
          !clientLinkChange
          ? pitchMeStore.previewData.clientVideoObj.url
          : data.clientVideo &&
            data.clientVideo.external_id &&
            !clientLinkChange
          ? data.clientVideo.url
          : this.form.fields.clientVideoURL.value || ''
        : ''

      await pitchMeStore.validateData({
        video_1: pitchMeMyVideoURL,
        video_2: pitchMeClientVideoURL
      })

      // Hack for removing data if clear my url button pressed
      if (name === 'myVideo' && !this.form.fields.myVideoURL.value) {
        this.clearMyURL()
        return null
      }

      // Hack for removing data if clear client url button pressed
      if (name === 'clientVideo' && !this.form.fields.clientVideoURL.value) {
        this.clearClientURL()
        return null
      }

      if (
        (pitchMeStore.previewData.myVideoObj &&
          pitchMeStore.previewData.myVideoObj.url) ||
        (pitchMeStore.previewData.clientVideoObj &&
          pitchMeStore.previewData.clientVideoObj.url)
      ) {
        this.setState({ myLinkChange: false, clientLinkChange: false })
      }
    }
  }

  setConsultantParams = () => {
    const { data } = this.props
    const slug = `${data.firstName.toLowerCase()}-${data.lastName.toLowerCase()}`
    return { consultant: `${slug}_${data.id}` }
  }

  showPitchMeSettings = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.togglePitchMeEditModal()
  }

  editFont = (type) => () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('editedInputType', type)
    pitchMeStore.toggleInputEditModal()
  }

  handleCallToAction = () => {
    const { pitchMeStore } = this.props
    if (pitchMeStore.pitchMeCTALink) {
      window.open(pitchMeStore.pitchMeCTALink, '_blank')
    }
  }

  deleteOption = (id) => () => {
    const { pitchMeStore, utilsStore } = this.props
    const modalOptions = {
      isOpen: true,
      title: 'Delete Template',
      message: 'Are you sure you want to delete it?',
      buttonName: 'Yes, Delete it!',
      cb: () => {
        pitchMeStore.deleteTemplate(id)
      }
    }
    utilsStore.toggleModal('warning', modalOptions)
  }

  onChangeTemplate = (id) => {
    if (id) {
      const { pitchMeStore } = this.props
      pitchMeStore.loadTemplate(id)
    } else {
      window.location.reload(true)
    }
  }

  inputFontFamily = (name) => {
    const { pitchMeStore } = this.props
    // eslint-disable-next-line no-nested-ternary
    return pitchMeStore[`${name}SelectedFont`]
      ? pitchMeStore[`${name}SelectedFont`].value
      : pitchMeStore.selectedFont
      ? pitchMeStore.selectedFont.value
      : pitchMeStore.siteFont
  }

  inputFontFamilyAlignment = (name) => {
    const { pitchMeStore } = this.props
    return (
      pitchMeStore[`${name}SelectedFontAlignment`] ||
      pitchMeStore.selectedFontAlignment
    )
  }

  inputFontSize = (name) => {
    const { pitchMeStore } = this.props
    return pitchMeStore[`${name}SelectedFontSize`]
  }

  inputFontColor = (name) => {
    const { pitchMeStore } = this.props
    return pitchMeStore[`${name}TextColor`] || pitchMeStore.textColor
  }

  inputFontItalic = (name) => {
    const { pitchMeStore } = this.props
    return pitchMeStore[`${name}IsItalic`]
  }

  inputFontBold = (name) => {
    const { pitchMeStore } = this.props
    return pitchMeStore[`${name}IsBold`]
  }

  handleBack = () => {
    window.history.back()
  }

  openTestimonialDetailsModal(testimonialId) {
    const { selectedTestimonialList } = this.props

    this.setState({
      testimonialsDetailsModal: {
        open: true,
        testimonialData: selectedTestimonialList.find(
          ({ id }) => id === testimonialId
        )
      }
    })
  }

  closeTestimonialDetailsModal() {
    this.setState({
      testimonialsDetailsModal: {
        open: false,
        testimonialData: null
      }
    })
  }

  modifiedHandleExpertiseSearch = async (name = '') => {
    const { handleExpertiseSearch } = this.props

    const expertise = await handleExpertiseSearch(name)

    const modifiedExpertise = expertise.map((item) => ({
      id: item,
      name: item
    }))

    return modifiedExpertise
  }

  render() {
    const {
      theme,
      pitchMeStore: {
        enablePitchMeProfileSection,
        enablePitchMeHelpSection,
        enablePitchMeExpertiseSection,
        enablePitchMeJobSection,
        enablePitchMeClientSection,
        toggle
      },
      data,
      jobsList,
      expertiseList,
      testimonialsList,
      selectedJobList,
      selectedExpertiseList,
      selectedTestimonialList,
      handleJobSearch,
      handleExpertiseSearch,
      handleTestimonialSearch,
      pitchMeStore
    } = this.props

    const {
      pitchMeTitle,
      pitchMeDescription,
      pitchMeClosingText,
      pitchMeRegardsText,
      myLinkChange,
      clientLinkChange,
      pitchMeHelpTitle,
      pitchMeExpertiseTitle,
      pitchMeJobTitle,
      pitchMeClientTitle,
      pitchMeMyTestimonials,
      buttonWidth,
      buttonHeight,
      deltaPosition,
      testimonialsDetailsModal
    } = this.state

    const backgroundColor = pitchMeStore.backgroundColor || '#fff'
    const isWhiteBg = ['#fff', '#ffffff'].includes(backgroundColor)

    const complimentaryContrastColor = getContrastColor(backgroundColor)

    // console.log('pitch me', this)

    return (
      <div className="question-mapping-section">
        <div
          className="refari-w-row refari-vertical"
          style={{ backgroundColor }}
        >
          <div className="refari-col-12">
            <div className="refari-pitch-me">
              <div className="refari-pitch-me-top-block">
                <div className="refari-pitch-me-top-block-head">
                  <h5
                    className="refari-pitch-me-head-title"
                    style={{
                      color:
                        pitchMeStore.textColor || theme.palette.primary1Color
                    }}
                  >
                    SmartPitch
                  </h5>
                  <IconButton
                    onClick={this.showPitchMeSettings}
                    className="refari-pitch-me-head-title-settings"
                    edge="end"
                  >
                    <SettingsIcon
                      style={{
                        color: isWhiteBg
                          ? theme?.palette?.iconColor ||
                            theme?.palette?.primary1Color
                          : complimentaryContrastColor
                      }}
                    />
                  </IconButton>
                </div>
                <p
                  className="refari-pitch-me-desc"
                  style={{
                    color: pitchMeStore.textColor || theme.palette.textColor,
                    fontStyle: 'italic'
                  }}
                >
                  We have generated a page from the information you provided
                  that you can send to your customers for self-presentation.
                  Review it, make changes and generate it for sharing:
                </p>
              </div>
              <hr className="refari-pitch-me-divider" />
              <div className="refari-pitch-me-middle-block">
                {/* Title and description */}
                <div className="refari-pitch-me-heading">
                  <div className="refari-pitch-me-heading-title-desc">
                    <CustomTextareaWrap
                      name="pitchMeTitle"
                      value={pitchMeTitle}
                      placeholder="Enter your title here"
                      placeholderColor={pSBC(0.5, this.inputFontColor('title'))}
                      handleChange={this.handleInputChange}
                      handleEdit={this.editFont('title')}
                      inputClass="refari-pitch-me-heading--header-input"
                      wrapDiv="refari-pitch-me-heading--header-editor input-wrap"
                      iconFill={
                        isWhiteBg
                          ? theme?.palette?.primary1Color
                          : complimentaryContrastColor
                      }
                      style={{
                        color: this.inputFontColor('title'),
                        fontFamily: this.inputFontFamily('title'),
                        textAlign: this.inputFontFamilyAlignment('title'),
                        fontStyle: this.inputFontItalic('title')
                          ? 'italic'
                          : 'normal',
                        fontWeight: this.inputFontBold('title')
                          ? 700
                          : 'normal',
                        fontSize: this.inputFontSize('title')
                      }}
                      rowsMax={3}
                      rows={1}
                    />
                    <CustomTextareaWrap
                      name="pitchMeDescription"
                      value={pitchMeDescription}
                      placeholder="Enter your description here"
                      placeholderColor={pSBC(
                        0.5,
                        this.inputFontColor('description')
                      )}
                      handleChange={this.handleInputChange}
                      handleEdit={this.editFont('description')}
                      inputClass="refari-pitch-me-heading-description-input"
                      wrapDiv="refari-pitch-me-heading--input-editor input-wrap m-t-2"
                      iconFill={
                        isWhiteBg
                          ? theme?.palette?.primary1Color
                          : complimentaryContrastColor
                      }
                      style={{
                        color: this.inputFontColor('description'),
                        fontFamily: this.inputFontFamily('description'),
                        textAlign: this.inputFontFamilyAlignment('description'),
                        fontStyle: this.inputFontItalic('description')
                          ? 'italic'
                          : 'normal',
                        fontWeight: this.inputFontBold('description')
                          ? 700
                          : 'normal',
                        fontSize: this.inputFontSize('description')
                      }}
                      rowsMax={10}
                    />
                  </div>
                  {enablePitchMeProfileSection && (
                    <div className="refari-pitch-me-heading-avatar">
                      <div className="refari-job-item-consultant-avatar-link refari-justify-center refari-rounded">
                        <AvatarPlaceholder
                          className="refari-show-alternative"
                          src={data.avatar}
                          altsrc={data.avatarAlternative}
                          alt={data.fullName}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="refari-pitch-me-heading-profile">
                  <FormControl>
                    <FormGroup aria-label="switch" row>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            style={{
                              color: isWhiteBg
                                ? theme?.palette?.primary1Color
                                : complimentaryContrastColor
                            }}
                            checked={enablePitchMeProfileSection}
                            onChange={() => {
                              this.toggle('enablePitchMeProfileSection')
                            }}
                          />
                        }
                        style={{
                          color: isWhiteBg
                            ? pitchMeStore.textColor ||
                              theme?.palette?.textColor
                            : complimentaryContrastColor
                        }}
                        label="Show Profile"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                {/* How can I help you */}
                <div className="refari-pitch-me-help">
                  <div className="refari-pitch-me-block">
                    <div className="refari-pitch-me-block-title">
                      <CustomInputWrap
                        value={pitchMeHelpTitle}
                        handleChange={this.handleInputChange}
                        inputClass="refari-pitch-me-block-title-help-input"
                        style={{
                          color: this.inputFontColor('help'),
                          fontFamily: this.inputFontFamily('help'),
                          textAlign: this.inputFontFamilyAlignment('help'),
                          fontStyle: this.inputFontItalic('help')
                            ? 'italic'
                            : 'normal',
                          fontWeight: this.inputFontBold('help')
                            ? 700
                            : 'normal',
                          fontSize: this.inputFontSize('help')
                        }}
                        name="pitchMeHelpTitle"
                        handleEdit={this.editFont('help')}
                        placeholder="How can I help you?"
                        wrapDiv="refari-pitch-me-block-title-editor input-wrap"
                        iconFill={
                          isWhiteBg
                            ? theme.palette.primary1Color
                            : complimentaryContrastColor
                        }
                        placeholderColor={pSBC(
                          0.5,
                          this.inputFontColor('help')
                        )}
                      />
                    </div>
                    <div className="refari-pitch-me-block-toggle">
                      <FormControl>
                        <FormGroup aria-label="switch" row>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={enablePitchMeHelpSection}
                                onChange={() => {
                                  this.toggle('enablePitchMeHelpSection')
                                }}
                                style={{
                                  color: isWhiteBg
                                    ? theme?.palette?.primary1Color
                                    : complimentaryContrastColor
                                }}
                              />
                            }
                            style={{
                              color: isWhiteBg
                                ? pitchMeStore.textColor ||
                                  theme.palette.textColor
                                : complimentaryContrastColor
                            }}
                            label="Show this block"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                  {enablePitchMeHelpSection && (
                    <div className="refari-pitch-me-help-section">
                      {((pitchMeStore.previewData &&
                        pitchMeStore.previewData.myVideoObj &&
                        pitchMeStore.previewData.myVideoObj.external_id) ||
                        (data.video && data.video.external_id)) &&
                      !myLinkChange ? (
                        <div className="refari-pitch-me-video-wrapper">
                          {this.renderMyFrame(
                            pitchMeStore.previewData &&
                              pitchMeStore.previewData.myVideoObj &&
                              pitchMeStore.previewData.myVideoObj.external_id
                              ? pitchMeStore.previewData.myVideoObj
                              : data.video
                          )}
                          <FlatButton
                            className="refari-button-flat"
                            color="primary"
                            onClick={this.onMyLinkChange}
                            style={{
                              backgroundColor: '#ffffff',
                              position: 'absolute',
                              right: this.myPlay ? 25 : 20,
                              top: this.myPlay ? 10 : 20,
                              border: `1px solid ${theme.palette.primary1Color}`,
                              color: theme.palette.primary1Color,
                              zIndex: 1
                            }}
                            startIcon={
                              <PencilIcon
                                fill={
                                  isWhiteBg
                                    ? theme.palette.primary1Color
                                    : complimentaryContrastColor
                                }
                                style={{
                                  marginTop: 0,
                                  paddingTop: 5
                                }}
                              />
                            }
                          >
                            Change link
                          </FlatButton>
                          {!this.myPlay && (
                            <div
                              className="refari-video-dimmer"
                              style={{
                                backgroundImage: `url(${
                                  pitchMeStore.previewData &&
                                  pitchMeStore.previewData.myVideoObj &&
                                  pitchMeStore.previewData.myVideoObj.image_url
                                    ? pitchMeStore.previewData.myVideoObj
                                        .image_url
                                    : this.myThumbnailUrl
                                })`
                              }}
                            >
                              <Fab
                                className="refari-play-button"
                                onClick={this.myTogglePlay}
                                color="primary"
                                style={{ borderRadius: '50%', height: 56 }}
                              >
                                <PlayIcon style={{ width: 40, height: 40 }} />
                              </Fab>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="refari-pitch-me-video-wrapper"
                          style={{ height: 200 }}
                        >
                          <div className="refari-video-dimmer refari-video-dimmer-change-link">
                            <div className="refari-pitch-me-video-change-link-block">
                              <h5
                                style={{
                                  color: isWhiteBg
                                    ? this.inputFontColor('help')
                                    : complimentaryContrastColor,
                                  fontStyle: 'italic'
                                }}
                              >
                                Enter video URL to add video of you
                              </h5>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'baseline'
                                }}
                              >
                                <TextField
                                  className="settings-field add-edit-hashtag__input"
                                  value={this.form.fields.myVideoURL.value}
                                  label="Youtube or Vimeo or Loom URL:"
                                  name="myVideoURL"
                                  inputProps={{
                                    style: {
                                      color: this.inputFontColor('help'),
                                      fontFamily: this.inputFontFamily('help')
                                    }
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: this.inputFontColor('help'),
                                      fontFamily: this.inputFontFamily('help')
                                    }
                                  }}
                                  fullWidth
                                  onChange={this.handleChange}
                                  onBlur={this.previewVideoThumb(
                                    this.form.fields.myVideoURL.value,
                                    'myVideo'
                                  )}
                                />
                                <div>
                                  <FlatButton
                                    className="refari-button-flat"
                                    color="primary"
                                    onClick={this.clearMyURL}
                                    style={{
                                      border: `1px solid ${
                                        isWhiteBg
                                          ? theme.palette.primary1Color
                                          : complimentaryContrastColor
                                      }`,
                                      color: isWhiteBg
                                        ? theme.palette.primary1Color
                                        : complimentaryContrastColor,
                                      marginLeft: 10
                                    }}
                                    startIcon={
                                      <Clear
                                        style={{
                                          marginTop: 0,
                                          color: isWhiteBg
                                            ? theme.palette.primary1Color
                                            : complimentaryContrastColor
                                        }}
                                      />
                                    }
                                  >
                                    Clear
                                  </FlatButton>
                                </div>
                              </div>
                            </div>
                            {((pitchMeStore.previewData &&
                              pitchMeStore.previewData.myVideoObj &&
                              pitchMeStore.previewData.myVideoObj
                                .external_id) ||
                              (data.video && data.video.external_id)) && (
                              <RaisedButton
                                onClick={this.onMyLinkChange}
                                className="refari-button-raised"
                                style={{
                                  backgroundColor: '#ffffff',
                                  position: 'absolute',
                                  right: 20,
                                  top: 20,
                                  border: `1px solid ${theme.palette.primary1Color}`,
                                  color: theme.palette.primary1Color
                                }}
                              >
                                Back
                              </RaisedButton>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Areas of expertise */}
                <div className="refari-pitch-me-expertise">
                  <div className="refari-pitch-me-block">
                    <div className="refari-pitch-me-block-title">
                      <CustomInputWrap
                        value={pitchMeExpertiseTitle}
                        handleChange={this.handleInputChange}
                        inputClass="refari-pitch-me-block-title-expertise-input"
                        style={{
                          color: this.inputFontColor('expertise'),
                          fontFamily: this.inputFontFamily('expertise'),
                          textAlign: this.inputFontFamilyAlignment('expertise'),
                          fontStyle: this.inputFontItalic('expertise')
                            ? 'italic'
                            : 'normal',
                          fontWeight: this.inputFontBold('expertise')
                            ? 700
                            : 'normal',
                          fontSize: this.inputFontSize('expertise')
                        }}
                        name="pitchMeExpertiseTitle"
                        handleEdit={this.editFont('expertise')}
                        placeholder="Areas of expertise:"
                        wrapDiv="refari-pitch-me-block-title-editor input-wrap"
                        iconFill={
                          isWhiteBg
                            ? theme.palette.primary1Color
                            : complimentaryContrastColor
                        }
                        placeholderColor={pSBC(
                          0.5,
                          this.inputFontColor('expertise')
                        )}
                      />
                    </div>
                    <div className="refari-pitch-me-block-toggle">
                      <FormControl>
                        <FormGroup aria-label="switch" row>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={enablePitchMeExpertiseSection}
                                onChange={toggle(
                                  'enablePitchMeExpertiseSection'
                                )}
                                style={{
                                  color: isWhiteBg
                                    ? theme?.palette?.primary1Color
                                    : complimentaryContrastColor
                                }}
                              />
                            }
                            style={{
                              color: isWhiteBg
                                ? pitchMeStore.textColor ||
                                  theme.palette.textColor
                                : complimentaryContrastColor
                            }}
                            label="Show this block"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                  {enablePitchMeExpertiseSection && (
                    <div className="refari-pitch-me-expertise-section">
                      <div className="refari-pitch-me-expertise__search">
                        <AutoCompleteComponent
                          jobSearch={this.expertiseSearch}
                          list={expertiseList}
                          handleJobSearch={this.modifiedHandleExpertiseSearch}
                          selectJob={this.selectExpertise}
                          handleRemove={this.handleExpertiseRemove}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          style={{
                            color: this.inputFontColor('expertise'),
                            fontFamily: this.inputFontFamily('expertise'),
                            fontStyle: this.inputFontItalic('expertise')
                              ? 'italic'
                              : 'normal',
                            fontWeight: this.inputFontBold('expertise')
                              ? 700
                              : 'normal'
                          }}
                          placeholder="Add specialities from your profile..."
                          placeholderColor={pSBC(
                            0.5,
                            theme.palette.primary1Color
                          )}
                        />
                      </div>
                      <div className="refari-pitch-me-expertise-job-selected">
                        {selectedExpertiseList.map((el, index) => (
                          <Chip
                            style={{
                              color: '#ffffff',
                              backgroundColor: theme.palette.primary1Color,
                              fontFamily: this.inputFontFamily('expertise'),
                              fontStyle: this.inputFontItalic('expertise')
                                ? 'italic'
                                : 'normal',
                              fontWeight: this.inputFontBold('expertise')
                                ? 700
                                : 'normal'
                            }}
                            color="primary"
                            className="refari-pitch-me-expertise-job-item"
                            onDelete={this.handleExpertiseRemove(el)}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            label={el.name}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* Recently placed roles */}
                <div className="refari-pitch-me-placed">
                  <div className="refari-pitch-me-block">
                    <div className="refari-pitch-me-block-title">
                      <CustomInputWrap
                        value={pitchMeJobTitle}
                        handleChange={this.handleInputChange}
                        inputClass="refari-pitch-me-block-title-job-input"
                        style={{
                          color: this.inputFontColor('job'),
                          fontFamily: this.inputFontFamily('job'),
                          textAlign: this.inputFontFamilyAlignment('job'),
                          fontStyle: this.inputFontItalic('job')
                            ? 'italic'
                            : 'normal',
                          fontWeight: this.inputFontBold('job')
                            ? 700
                            : 'normal',
                          fontSize: this.inputFontSize('job')
                        }}
                        name="pitchMeJobTitle"
                        handleEdit={this.editFont('job')}
                        placeholder="Recently placed roles that could be of interest:"
                        wrapDiv="refari-pitch-me-block-title-editor input-wrap"
                        iconFill={
                          isWhiteBg
                            ? theme.palette.primary1Color
                            : complimentaryContrastColor
                        }
                        placeholderColor={pSBC(0.5, this.inputFontColor('job'))}
                      />
                    </div>
                    <div className="refari-pitch-me-block-toggle">
                      <FormControl>
                        <FormGroup aria-label="switch" row>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={enablePitchMeJobSection}
                                onChange={toggle('enablePitchMeJobSection')}
                                style={{
                                  color: isWhiteBg
                                    ? theme?.palette?.primary1Color
                                    : complimentaryContrastColor
                                }}
                              />
                            }
                            style={{
                              color: isWhiteBg
                                ? pitchMeStore.textColor ||
                                  theme?.palette?.textColor
                                : complimentaryContrastColor
                            }}
                            label="Show this block"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                  {enablePitchMeJobSection && (
                    <div className="refari-pitch-me-placed-section">
                      <div className="refari-pitch-me-placed__search">
                        <AutoCompleteComponent
                          jobSearch={this.jobSearch}
                          list={jobsList}
                          handleJobSearch={handleJobSearch}
                          selectJob={this.selectJob}
                          handleRemove={this.handleRemove}
                          style={{
                            color: this.inputFontColor('job'),
                            fontFamily: this.inputFontFamily('job'),
                            fontStyle: this.inputFontItalic('job')
                              ? 'italic'
                              : 'normal',
                            fontWeight: this.inputFontBold('job')
                              ? 700
                              : 'normal'
                          }}
                          placeholder="Add jobs from your profile..."
                          placeholderColor={pSBC(
                            0.5,
                            theme.palette.primary1Color
                          )}
                        />
                      </div>
                      <div className="refari-pitch-me-placed-job-selected">
                        {selectedJobList.map((el) => (
                          <div
                            className="refari-pitch-me-placed-job-item"
                            key={el.id}
                          >
                            <div className="refari-pitch-me-placed-job-item-pointer">
                              <p
                                className="refari-pitch-me-placed-job-item-header"
                                style={{
                                  color: theme.palette.primary1Color,
                                  fontFamily: this.inputFontFamily('job'),
                                  fontStyle: this.inputFontItalic('job')
                                    ? 'italic'
                                    : 'normal',
                                  fontWeight: this.inputFontBold('job')
                                    ? 700
                                    : 'normal'
                                }}
                              >
                                {el.title}
                              </p>
                              <p
                                className="refari-pitch-me-placed-job-item-subtitle"
                                style={{
                                  color: this.inputFontColor('job'),
                                  fontFamily: this.inputFontFamily('job'),
                                  fontStyle: this.inputFontItalic('job')
                                    ? 'italic'
                                    : 'normal',
                                  fontWeight: this.inputFontBold('job')
                                    ? 700
                                    : 'normal'
                                }}
                              >
                                {el.category.parent.name &&
                                  `${el.category.parent.name} >`}{' '}
                                {el.category.name}
                              </p>
                            </div>
                            {/* eslint-disable-next-line react/button-has-type */}
                            <button
                              className="refari-pitch-me-placed-job-item-close"
                              onClick={this.handleRemove(el)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* What clients say about me */}
                <div className="refari-pitch-me-help">
                  <div className="refari-pitch-me-block">
                    <div className="refari-pitch-me-block-title">
                      <CustomInputWrap
                        value={pitchMeClientTitle}
                        handleChange={this.handleInputChange}
                        inputClass="refari-pitch-me-block-title-client-input"
                        style={{
                          color: this.inputFontColor('client'),
                          fontFamily: this.inputFontFamily('client'),
                          textAlign: this.inputFontFamilyAlignment('client'),
                          fontStyle: this.inputFontItalic('client')
                            ? 'italic'
                            : 'normal',
                          fontWeight: this.inputFontBold('client')
                            ? 700
                            : 'normal',
                          fontSize: this.inputFontSize('client')
                        }}
                        name="pitchMeClientTitle"
                        handleEdit={this.editFont('client')}
                        placeholder="What clients say about me:"
                        wrapDiv="refari-pitch-me-block-title-editor input-wrap"
                        iconFill={
                          isWhiteBg
                            ? theme.palette.primary1Color
                            : complimentaryContrastColor
                        }
                        placeholderColor={pSBC(
                          0.5,
                          this.inputFontColor('client')
                        )}
                      />
                    </div>
                    <div className="refari-pitch-me-block-toggle">
                      <FormControl>
                        <FormGroup aria-label="switch" row>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={enablePitchMeClientSection}
                                onChange={() => {
                                  this.toggle('enablePitchMeClientSection')
                                }}
                                style={{
                                  color: isWhiteBg
                                    ? theme?.palette?.primary1Color
                                    : complimentaryContrastColor
                                }}
                              />
                            }
                            style={{
                              color: isWhiteBg
                                ? pitchMeStore.textColor ||
                                  theme?.palette?.textColor
                                : complimentaryContrastColor
                            }}
                            label="Show this block"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </div>
                  {enablePitchMeClientSection && (
                    <div className="refari-pitch-me-help-section">
                      {((pitchMeStore.previewData &&
                        pitchMeStore.previewData.clientVideoObj &&
                        pitchMeStore.previewData.clientVideoObj.external_id) ||
                        (data.clientVideo && data.clientVideo.external_id)) &&
                      !clientLinkChange ? (
                        <div className="refari-pitch-me-video-wrapper">
                          {this.renderClientFrame(
                            pitchMeStore.previewData &&
                              pitchMeStore.previewData.clientVideoObj &&
                              pitchMeStore.previewData.clientVideoObj
                                .external_id
                              ? pitchMeStore.previewData.clientVideoObj
                              : data.clientVideo
                          )}
                          <FlatButton
                            className="refari-button-flat"
                            color="primary"
                            onClick={this.onClientLinkChange}
                            style={{
                              backgroundColor: '#ffffff',
                              position: 'absolute',
                              right: this.clientPlay ? 25 : 20,
                              top: this.clientPlay ? 10 : 20,
                              border: `1px solid ${theme.palette.primary1Color}`,
                              color: theme.palette.primary1Color,
                              zIndex: 1
                            }}
                            startIcon={
                              <PencilIcon
                                fill={theme.palette.primary1Color}
                                style={{
                                  marginTop: 0,
                                  paddingTop: 5
                                }}
                              />
                            }
                          >
                            Change link
                          </FlatButton>
                          {!this.clientPlay && (
                            <div
                              className="refari-video-dimmer"
                              style={{
                                backgroundImage: `url(${
                                  pitchMeStore.previewData &&
                                  pitchMeStore.previewData.clientVideoObj &&
                                  pitchMeStore.previewData.clientVideoObj
                                    .image_url
                                    ? pitchMeStore.previewData.clientVideoObj
                                        .image_url
                                    : this.clientThumbnailUrl
                                })`
                              }}
                            >
                              <Fab
                                className="refari-play-button"
                                color="primary"
                                onClick={this.clientTogglePlay}
                                style={{ borderRadius: '50%', height: 56 }}
                              >
                                <PlayIcon style={{ width: 40, height: 40 }} />
                              </Fab>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="refari-pitch-me-video-wrapper"
                          style={{ height: 200 }}
                        >
                          <div className="refari-video-dimmer refari-video-dimmer-change-link">
                            <div className="refari-pitch-me-video-change-link-block">
                              <h5
                                style={{
                                  color: isWhiteBg
                                    ? this.inputFontColor('client')
                                    : complimentaryContrastColor,
                                  fontStyle: 'italic'
                                }}
                              >
                                Enter video URL to add video with testimonials
                              </h5>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'baseline',
                                  fontFamily: this.inputFontFamily('client')
                                }}
                              >
                                <TextField
                                  fullWidth
                                  className="settings-field add-edit-hashtag__input"
                                  value={this.form.fields.clientVideoURL.value}
                                  label="Youtube or Vimeo or Loom URL:"
                                  onChange={this.handleChange}
                                  onBlur={this.previewVideoThumb(
                                    this.form.fields.clientVideoURL.value,
                                    'clientVideo'
                                  )}
                                  inputProps={{
                                    style: {
                                      color: this.inputFontColor('client'),
                                      fontFamily: this.inputFontFamily('client')
                                    }
                                  }}
                                  InputLabelProps={{
                                    style: {
                                      color: this.inputFontColor('client'),
                                      fontFamily: this.inputFontFamily('client')
                                    }
                                  }}
                                  name="clientVideoURL"
                                />
                                <div>
                                  <FlatButton
                                    className="refari-button-flat"
                                    color="primary"
                                    onClick={this.clearClientURL}
                                    style={{
                                      border: `1px solid ${
                                        isWhiteBg
                                          ? theme.palette.primary1Color
                                          : complimentaryContrastColor
                                      }`,
                                      color: isWhiteBg
                                        ? theme.palette.primary1Color
                                        : complimentaryContrastColor,
                                      marginLeft: 10
                                    }}
                                    startIcon={
                                      <Clear
                                        style={{
                                          marginTop: 0,
                                          color: isWhiteBg
                                            ? theme.palette.primary1Color
                                            : complimentaryContrastColor
                                        }}
                                      />
                                    }
                                  >
                                    Clear
                                  </FlatButton>
                                </div>
                              </div>
                            </div>
                            {((pitchMeStore.previewData &&
                              pitchMeStore.previewData.clientVideoObj &&
                              pitchMeStore.previewData.clientVideoObj
                                .external_id) ||
                              (data.clientVideo &&
                                data.clientVideo.external_id)) && (
                              // eslint-disable-next-line react/jsx-indent
                              <RaisedButton
                                onClick={this.onClientLinkChange}
                                className="refari-button-raised"
                                style={{
                                  backgroundColor: '#ffffff',
                                  position: 'absolute',
                                  right: 20,
                                  top: 20,
                                  color: theme.palette.primary1Color,
                                  border: `1px solid ${theme.palette.primary1Color}`
                                }}
                              >
                                Back
                              </RaisedButton>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="refari-pitch-me-testimonial-section">
                        <div className="refari-pitch-me-block-title">
                          <CustomInputWrap
                            value={pitchMeMyTestimonials}
                            handleChange={this.handleInputChange}
                            inputClass="refari-pitch-me-block-title-client-input"
                            style={{
                              color: this.inputFontColor('testimonials'),
                              fontFamily: this.inputFontFamily('testimonials'),
                              textAlign:
                                this.inputFontFamilyAlignment('testimonials'),
                              fontStyle: this.inputFontItalic('testimonials')
                                ? 'italic'
                                : 'normal',
                              fontWeight: this.inputFontBold('testimonials')
                                ? 700
                                : 'normal',
                              fontSize: this.inputFontSize('testimonials')
                            }}
                            name="pitchMeMyTestimonials"
                            handleEdit={this.editFont('testimonials')}
                            placeholder="My Testimonials"
                            wrapDiv="refari-pitch-me-block-title-editor input-wrap"
                            iconFill={
                              isWhiteBg
                                ? theme.palette.primary1Color
                                : complimentaryContrastColor
                            }
                            placeholderColor={pSBC(
                              0.5,
                              this.inputFontColor('testimonials')
                            )}
                          />
                        </div>
                        <div className="refari-pitch-me-testimonial__search">
                          <AutoCompleteComponent
                            jobSearch={this.testimonialSearch}
                            list={testimonialsList}
                            handleJobSearch={handleTestimonialSearch}
                            selectJob={this.selectTestimonial}
                            handleRemove={this.handleRemoveTestimonial}
                            placeholder="Add testimonials from your profile..."
                            style={{
                              color: this.inputFontColor('client'),
                              fontFamily: this.inputFontFamily('client'),
                              fontStyle: this.inputFontItalic('client')
                                ? 'italic'
                                : 'normal',
                              fontWeight: this.inputFontBold('client')
                                ? 700
                                : 'normal'
                            }}
                            placeholderColor={pSBC(
                              0.5,
                              theme.palette.primary1Color
                            )}
                          />
                        </div>
                        <div className="refari-pitch-me-testimonial-job-selected">
                          {selectedTestimonialList.map((el) => (
                            <div
                              className="refari-pitch-me-testimonial-job-item"
                              key={el.testimonialId}
                            >
                              <div>
                                <div
                                  style={{
                                    backgroundColor: hexToRgba(
                                      theme.palette.primary1Color,
                                      0.1
                                    ),
                                    fontFamily: this.inputFontFamily('client'),
                                    color: this.inputFontColor('client'),
                                    fontStyle: this.inputFontItalic('client')
                                      ? 'italic'
                                      : 'normal',
                                    fontWeight: this.inputFontBold('client')
                                      ? 700
                                      : 'normal'
                                  }}
                                  className="refari-pitch-me-testimonial-job-item-inner"
                                >
                                  <QuotesIcon
                                    fill={hexToRgba(
                                      theme.palette.primary1Color,
                                      0.7
                                    )}
                                    className="refari-pitch-me-testimonial-job-item-quotes"
                                  />
                                  <p
                                    className={classnames(
                                      'refari-pitch-me-testimonial-job-item-content',
                                      {
                                        'refari-pitch-me-testimonial-job-item-content-small':
                                          el.isVerified
                                      }
                                    )}
                                    style={{
                                      color: this.inputFontColor('client'),
                                      fontFamily:
                                        this.inputFontFamily('client'),
                                      fontStyle: this.inputFontItalic('client')
                                        ? 'italic'
                                        : 'normal',
                                      fontWeight: this.inputFontBold('client')
                                        ? 700
                                        : 'normal'
                                    }}
                                  >
                                    {this.showText(el)}
                                    {this.shouldTruncate(el) && (
                                      <ButtonLink
                                        theme={theme}
                                        onClick={() =>
                                          this.openTestimonialDetailsModal(
                                            el.id
                                          )
                                        }
                                        textLink="read more"
                                        color={this.inputFontColor('client')}
                                        className="refari-pitch-me-testimonial-job-item-content-read-more"
                                      />
                                    )}
                                  </p>
                                  <div className="refari-pitch-me-testimonial-job-item-bottom-section">
                                    {el.isVerified && (
                                      <div>
                                        <div className="refari-pitch-me-testimonial-job-item-star">
                                          {[0, 1, 2, 3, 4].map((mydata) => (
                                            <StarIcon key={mydata} />
                                          ))}
                                        </div>
                                        <div className="refari-pitch-me-testimonial-job-item__verified">
                                          <span className="refari-pitch-me-testimonial-job-item--verified-show">
                                            <TickIcon />
                                            <span className="refari-pitch-me-testimonial-job-item--verified">
                                              Verified review
                                            </span>
                                          </span>
                                          <span className="refari-pitch-me-testimonial-job-item--verified-hide">
                                            <span className="refari-pitch-me-testimonial-job-item--verified">
                                              Independently verified by{' '}
                                              <a
                                                href="https://toprec.co/"
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{
                                                  textDecoration: 'underline'
                                                }}
                                              >
                                                TopRec
                                              </a>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <p
                                      className="refari-pitch-me-testimonial-job-item-name"
                                      style={{
                                        color: this.inputFontColor('client'),
                                        fontFamily:
                                          this.inputFontFamily('client'),
                                        fontStyle: this.inputFontItalic(
                                          'client'
                                        )
                                          ? 'italic'
                                          : 'normal',
                                        fontWeight: this.inputFontBold('client')
                                          ? 700
                                          : 'normal'
                                      }}
                                    >
                                      {el.authorFirstName} {el.authorLastName}
                                    </p>
                                    {el.jobTitle && (
                                      <p
                                        className="refari-pitch-me-testimonial-job-item-occupation"
                                        style={{
                                          color: this.inputFontColor('client'),
                                          fontFamily:
                                            this.inputFontFamily('client'),
                                          fontStyle: this.inputFontItalic(
                                            'client'
                                          )
                                            ? 'italic'
                                            : 'normal',
                                          fontWeight: this.inputFontBold(
                                            'client'
                                          )
                                            ? 700
                                            : 'normal'
                                        }}
                                      >
                                        {el.jobTitle}
                                      </p>
                                    )}
                                    <p
                                      className="refari-pitch-me-testimonial-job-item-date"
                                      style={{
                                        color: this.inputFontColor('client'),
                                        fontFamily:
                                          this.inputFontFamily('client'),
                                        fontStyle: this.inputFontItalic(
                                          'client'
                                        )
                                          ? 'italic'
                                          : 'normal',
                                        fontWeight: this.inputFontBold('client')
                                          ? 700
                                          : 'normal'
                                      }}
                                    >
                                      {this.dateFormat(el.date)}
                                    </p>
                                  </div>
                                </div>
                                <div className="refari-pitch-me-testimonial-job-item-close-section">
                                  {/* eslint-disable-next-line react/button-has-type */}
                                  <button
                                    className="refari-pitch-me-testimonial-job-item-close"
                                    onClick={this.handleRemoveTestimonial(el)}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Closing text */}
                <div className="refari-pitch-me-heading refari-pitch-me-closing">
                  <CustomTextareaWrap
                    value={pitchMeClosingText}
                    handleChange={this.handleInputChange}
                    inputClass="refari-pitch-me-heading-closing-input"
                    name="pitchMeClosingText"
                    rowsMax={15}
                    handleEdit={this.editFont('closing')}
                    placeholder="Enter your closing text here"
                    wrapDiv="refari-pitch-me-heading--input-editor input-wrap"
                    iconFill={
                      isWhiteBg
                        ? theme.palette.primary1Color
                        : complimentaryContrastColor
                    }
                    style={{
                      color: this.inputFontColor('closing'),
                      fontFamily: this.inputFontFamily('closing'),
                      textAlign: this.inputFontFamilyAlignment('closing'),
                      fontStyle: this.inputFontItalic('closing')
                        ? 'italic'
                        : 'normal',
                      fontWeight: this.inputFontBold('closing')
                        ? 700
                        : 'normal',
                      fontSize: this.inputFontSize('closing')
                    }}
                    placeholderColor={pSBC(0.5, this.inputFontColor('closing'))}
                  />
                </div>

                {/* Regards text */}
                <div className="refari-pitch-me-heading refari-pitch-me-regards-section">
                  <div
                    className="refari-pitch-me-regards"
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      maxWidth: '50%'
                    }}
                  >
                    <CustomTextareaWrap
                      value={pitchMeRegardsText}
                      handleChange={this.handleInputChange}
                      // inputClass="refari-pitch-me-heading-regards-input"
                      name="pitchMeRegardsText"
                      handleEdit={this.editFont('regards')}
                      placeholder="Regards,"
                      rowsMax={15}
                      wrapDiv="refari-pitch-me-heading--input-editor input-wrap"
                      iconFill={
                        isWhiteBg
                          ? theme.palette.primary1Color
                          : complimentaryContrastColor
                      }
                      style={{
                        color: this.inputFontColor('regards'),
                        fontFamily: this.inputFontFamily('regards'),
                        textAlign: this.inputFontFamilyAlignment('regards'),
                        fontStyle: this.inputFontItalic('regards')
                          ? 'italic'
                          : 'normal',
                        fontWeight: this.inputFontBold('regards')
                          ? 700
                          : 'normal',
                        fontSize: this.inputFontSize('regards'),
                        resize: 'both',
                        maxWidth: '100%'
                      }}
                      placeholderColor={pSBC(
                        0.5,
                        this.inputFontColor('regards')
                      )}
                    />
                  </div>

                  {/* Call To Action Button */}
                  <div
                    className="refari-pitch-me-call-to-action"
                    style={{ width: 'auto' }}
                  >
                    <div className="refari-pitch-me-call-to-action-box">
                      <Draggable
                        onDrag={this.handleDrag}
                        bounds="parent"
                        positionOffset={{
                          x: 0,
                          y: 0
                        }}
                        defaultPosition={deltaPosition}
                        handle=".handleDrag"
                      >
                        <Resizable
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px dashed #757575',
                            background: 'transparent'
                          }}
                          bounds="parent"
                          minWidth={230}
                          minHeight={42}
                          size={{
                            height: buttonHeight + 20
                          }}
                          onResizeStop={(e, direction, ref, d) => {
                            this.setState({
                              buttonWidth: buttonWidth + d.width,
                              buttonHeight: buttonHeight + d.height
                            })
                          }}
                        >
                          <div
                            className="refari-pitch-me-call-to-action-box-inner"
                            style={{
                              height: 'calc(100% - 14px)'
                            }}
                          >
                            <RaisedButton
                              onClick={this.handleCallToAction}
                              className="refari-pitch-me-call-to-action-button refari-button-raised"
                              style={{
                                border: `1px solid ${theme.palette.primary1Color}`,
                                color: theme.palette.primary1Color,
                                backgroundColor: '#fff',
                                minWidth: 'calc(100% - 40px)',
                                minHeight: 'calc(100% - 6px)',
                                borderRadius:
                                  pitchMeStore.pitchMeCTABorderRadius,
                                fontSize: pitchMeStore.pitchMeCTAFontSize
                              }}
                            >
                              <span
                                style={{
                                  fontSize: pitchMeStore.pitchMeCTAFontSize
                                }}
                              >
                                {pitchMeStore.pitchMeCTATitle ||
                                  'Add Call to Action'}
                              </span>
                            </RaisedButton>
                            <div className="refari-pitch-me-call-to-action-button-block">
                              <PencilIcon
                                className="refari-pitch-me-call-to-action-edit"
                                fill={
                                  isWhiteBg
                                    ? theme.palette.primary1Color
                                    : complimentaryContrastColor
                                }
                                style={{
                                  marginLeft: 0,
                                  marginTop: 0,
                                  paddingTop: 0,
                                  cursor: 'pointer',
                                  width: 20,
                                  height: 22
                                }}
                                onClick={this.addCallToAction}
                              />
                              <MoveIcon
                                className="handleDrag"
                                fill={
                                  isWhiteBg
                                    ? theme.palette.primary1Color
                                    : complimentaryContrastColor
                                }
                                style={{
                                  marginLeft: 0,
                                  marginTop: 2,
                                  paddingTop: 0,
                                  cursor: 'move',
                                  width: 18,
                                  height: 18
                                }}
                              />
                            </div>
                          </div>
                        </Resizable>
                      </Draggable>
                    </div>
                  </div>
                </div>
              </div>
              <PitchMeModal />
              <PitchMeCTAModal />
              {pitchMeStore.isOpenPitchMeEditModal && (
                <SmartPitchSettingsModal />
              )}
              {pitchMeStore.isOpenInputEditModal && <PitchMeInputEditModal />}
              {testimonialsDetailsModal.open && (
                <SmartPitchTestimonialDetailsModal
                  theme={theme}
                  userData={data}
                  open={testimonialsDetailsModal.open}
                  testimonialDetails={testimonialsDetailsModal.testimonialData}
                  onClose={this.closeTestimonialDetailsModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(PitchMe)
