/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Group, Rect } from 'react-konva'
import { observer } from 'mobx-react'
import { observable, action, runInAction } from 'mobx'
import { Transformer } from 'konva'

@observer
class CanvasEditableGroup extends React.Component {
  static propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    enabledAnchors: PropTypes.arrayOf(PropTypes.string),
    keepRatio: PropTypes.bool,
    canvasCardStore: PropTypes.shape({
      setData: PropTypes.func.isRequired,
      title: PropTypes.object.isRequired,
      additionalText: PropTypes.object.isRequired,
      description: PropTypes.object.isRequired,
      mainImage: PropTypes.object.isRequired,
      additionalImage: PropTypes.object.isRequired
    }).isRequired,
    showTransformer: PropTypes.bool
  }
  static defaultTypes = {
    showTransformer: false
  }
  static childContextTypes = {
    attrs: PropTypes.object.isRequired
  }

  // boundaryPadding
  BP = 0
  // minSize
  MS = 30
  // transformer padding
  TP = 3

  @observable x
  @observable y
  @observable width
  @observable height
  @observable scaleX
  @observable scaleY
  @observable originalWidth
  @observable originalHeight

  constructor(props) {
    super(props)
    this.originalWidth = props.width
    this.originalHeight = props.height
    this.setAttrs(props)
    this.state = {
      groupInfo: {}
    }
  }

  componentDidMount() {
    const contentMinSize = this.MS - this.TP * 2
    this.stage = this.refs.group.getStage()
    this.layer = this.refs.group.getLayer()

    this.refs.group.on('mousedown', () => {
      document.body.style.cursor = 'move'
    })
    this.refs.group.on('mouseover mouseup', () => {
      document.body.style.cursor = 'pointer'
    })
    this.refs.group.on('mouseout', () => {
      document.body.style.cursor = 'default'
    })
    this.refs.group.on('dragmove', (e) => {
      runInAction(() => {
        this.x = e.currentTarget.x()
        this.y = e.currentTarget.y()
      })
    })
    this.refs.group.on('transformend', (e) => {
      if (
        e.currentTarget
        && e.currentTarget.children.length > 0
        && e.currentTarget.children[1].attrs
      ) {
        const nodeName = e.currentTarget.children[1].attrs?.name
        const { canvasCardStore } = this.props
        if (nodeName === 'title') {
          canvasCardStore.setData(
            'titleFontSize',
            e.currentTarget.children[1].attrs?.fontSize || 26
          )
        } else if (nodeName === 'description') {
          canvasCardStore.setData(
            'descriptionFontSize',
            e.currentTarget.children[1].attrs?.fontSize || 26
          )
        } else if (nodeName === 'additionalText') {
          canvasCardStore.setData(
            'additionalFontSize',
            e.currentTarget.children[1].attrs?.fontSize || 26
          )
        }
      }
    })
    this.refs.group.on('transform', (e) => {
      const scale = e.currentTarget.scale()

      let newW = e.currentTarget.getWidth() * scale.x
      let newH = e.currentTarget.getHeight() * scale.y

      if (newW > 500) {
        newW = 500
      }

      if (newH > 260) {
        newH = 260
      }

      if (newW <= contentMinSize) {
        if (this.x < e.currentTarget.x()) {
          this.transformer.stopTransform()
          e.currentTarget.x(this.x + 1)
          e.currentTarget.width(this.width)
        }
      }

      if (newH <= contentMinSize) {
        if (this.y < e.currentTarget.y()) {
          this.transformer.stopTransform()
          e.currentTarget.y(this.y + 1)
          e.currentTarget.height(this.height)
        }
      }

      runInAction(() => {
        this.x = e.currentTarget.x()
        this.y = e.currentTarget.y()
        this.width = newW
        this.height = newH
      })

      const nodeName = e.currentTarget.children[1].attrs?.name
      if (
        nodeName === 'title'
        || nodeName === 'description'
        || nodeName === 'additionalText'
      ) {
        const absScale = e.currentTarget.children[1].getAbsoluteScale()

        e.currentTarget.children[1].scaleX(
          e.currentTarget.children[1].scaleX() / absScale.x
        )
        e.currentTarget.children[1].scaleY(
          e.currentTarget.children[1].scaleY() / absScale.y
        )
        e.currentTarget.children[1].textHeight = newH
        e.currentTarget.children[1].textWidth = newW
      }
    })
  }

  getChildContext() {
    return {
      attrs: {
        width: this.width,
        height: this.height,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        x: this.x,
        y: this.y
      }
    }
  }

  componentWillUnmount() {
    this.refs.group.off(
      'mouseover mouseout mouseup mousedown dragmove transform'
    )
    document.body.style.cursor = 'default'
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showTransformer !== this.props.showTransformer) {
      if (nextProps.showTransformer) {
        this.enableTransformer()
      } else {
        this.disableTransformer()
      }

      const { canvasCardStore, name } = this.props
      const { groupInfo } = this.state
      const data = { ...this.refs.group.toObject() }
      data.attrs.width = groupInfo.width || data.attrs.width
      data.attrs.height = groupInfo.height || data.attrs.height
      canvasCardStore.setData(name, data)
    }
    if (nextProps.width !== this.props.width
      || nextProps.height !== this.props.height
      || nextProps.x !== this.props.x
      || nextProps.y !== this.props.y
    ) {
      runInAction(() => {
        if (this.refs.group) {
          this.refs.group.scale({ x: 1, y: 1 })
          this.refs.group.scaleX(1)
          this.refs.group.scaleY(1)
          const nodeName = this.refs.group.children[1].attrs?.name
          if (
            nodeName === 'title'
            || nodeName === 'description'
            || nodeName === 'additionalText'
          ) {
            this.refs.group.children[1].scaleX(1)
            this.refs.group.children[1].scaleY(1)
            this.refs.group.children[1].textHeight = nextProps.width
            this.refs.group.children[1].textWidth = nextProps.height
          }
        }
        this.originalWidth = nextProps.width
        this.originalHeight = nextProps.height
        this.x = nextProps.x
        this.y = nextProps.y
        this.width = nextProps.width
        this.height = nextProps.height
      })
    }
  }

  enableTransformer = () => {
    this.disableTransformer()
    const trConfig = {
      node: this.refs.group,
      keepRatio: this.props.keepRatio,
      rotateEnabled: false,
      padding: 3,
      boundBoxFunc: this.boundBoxFunc
    }
    const { enabledAnchors } = this.props
    if (enabledAnchors) {
      trConfig.enabledAnchors = enabledAnchors
    }
    this.transformer = new Transformer(trConfig)
    this.layer.add(this.transformer)
    this.layer.batchDraw()
  }
  disableTransformer = () => {
    if (!this.transformer) {
      return
    }
    this.transformer.stopTransform()
    this.transformer.off('transform')
    this.transformer.destroy()
    this.layer.batchDraw()
  }

  @action
  setAttrs = (propsData) => {
    const {
      x, y, width, height, name, canvasCardStore
    } = propsData
    const storedData = canvasCardStore[name]
    this.x = storedData.attrs ? storedData.attrs.x : x
    this.y = storedData.attrs ? storedData.attrs.y : y
    this.width = storedData.attrs ? storedData.attrs.width : width
    this.height = storedData.attrs ? storedData.attrs.height : height
  }

  // Transformer boundBox
  boundBoxFunc = (oldBoundBox, newBoundBox) => {
    let normalizedBoundBox = { ...newBoundBox }
    // min width
    if (newBoundBox.width < this.MS) {
      normalizedBoundBox = { ...normalizedBoundBox, width: this.MS }
    }
    // min heigth
    if (newBoundBox.height < this.MS) {
      normalizedBoundBox = { ...normalizedBoundBox, height: this.MS }
    }
    // bound left
    if (newBoundBox.x < this.BP) {
      normalizedBoundBox = { ...normalizedBoundBox, x: this.BP }
    }
    // bound top
    if (newBoundBox.y < this.BP) {
      normalizedBoundBox = { ...normalizedBoundBox, y: this.BP }
    }
    this.forceUpdate()
    this.setState({ groupInfo: normalizedBoundBox })
    return normalizedBoundBox
  }

  render() {
    const {
      name, rectName, children, onDblClick
    } = this.props

    return (
      <Group
        ref="group"
        name={name}
        key={`${name}-group`}
        width={this.originalWidth}
        height={this.originalHeight}
        onDblClick={(e) => {
          onDblClick && onDblClick(e)
        }}
        x={this.x}
        y={this.y}
        draggable
      >
        <Rect
          name={rectName}
          width={this.originalWidth}
          height={this.originalHeight}
          shadowColor="black"
          shadowBlur={5}
          shadowOpacity={0.3}
        />
        {children}
      </Group>
    )
  }
}

export default CanvasEditableGroup
