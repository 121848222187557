import routesUrls from '../router/routesUrls'

export default {
  agency: {
    admin: {
      hasAccessToDashboard: {
        pages: [
          routesUrls.dashboard.children.candidates,
          routesUrls.dashboard.children.candidateAds,
          routesUrls.dashboard.children.candidateEvents,
          routesUrls.dashboard.children.referrers,
          routesUrls.dashboard.children.consultants,
          routesUrls.dashboard.children.socialize,
          routesUrls.dashboard.children.jobs,
          routesUrls.dashboard.children.jobAds,
          routesUrls.dashboard.children.activities,
          routesUrls.dashboard.children.communications
        ]
      },
      hasAccessToSettings: {
        pages: [
          routesUrls.settings.children.editProfile,
          routesUrls.settings.children.accountSettings,
          routesUrls.settings.children.agency,
          routesUrls.settings.children.agencyBranding,
          routesUrls.settings.children.socialNetworks,
          routesUrls.settings.children.floatingLink
        ]
      },
      hasAccessToTools: {
        pages: [
          // routesUrls.tools.children.EdmBuilder,
          routesUrls.tools.children.XmlFeeds,
          routesUrls.tools.children.CreateJobAlert,
          routesUrls.tools.children.CreateTalentAlert,
          routesUrls.tools.children.PitchMe
        ],
        restrictedPages: [routesUrls.tools.children.QuestionMapping]
      },
      hasAccessToWidgets: {
        pages: [
          routesUrls.widgets.children.jobBoard,
          routesUrls.widgets.children.talentBoard,
          routesUrls.widgets.children.searchBar,
          routesUrls.widgets.children.latestJobs,
          routesUrls.widgets.children.widgetAbout,
          routesUrls.widgets.children.floatingLink,
          routesUrls.widgets.children.testimonials,
          routesUrls.widgets.children.testimonialGrids,
          routesUrls.widgets.children.registerWithUs,
          routesUrls.widgets.children.yourRatings,
          routesUrls.widgets.children.referral,
          routesUrls.widgets.children.CategoryIDs
        ]
      },
      hasAccessToBilling: {
        pages: [
          routesUrls.billing.children.cardSettings,
          routesUrls.billing.children.invoices.children.details,
          routesUrls.billing.children.payments,
          routesUrls.billing.children.payments.children.details,
          routesUrls.billing.children.budget
        ]
      },
      hasAccessToBadges: {
        pages: [
          routesUrls.badges.children.yourProgress,
          routesUrls.badges.children.allBadges,
          routesUrls.badges.children.agencyLeaderboard
        ]
      },
      hasAccessToTopRec: {
        pages: [
          routesUrls.toprec.children.ratings,
          routesUrls.toprec.children.feedback,
          routesUrls.toprec.children.settings,
          routesUrls.toprec.children.placements
        ]
      },
      hasAccessToReports: {
        pages: []
      }
    },
    manager: {
      hasAccessToDashboard: {
        pages: [
          routesUrls.dashboard.children.candidates,
          routesUrls.dashboard.children.candidateAds,
          routesUrls.dashboard.children.candidateEvents,
          routesUrls.dashboard.children.referrers,
          routesUrls.dashboard.children.consultants,
          routesUrls.dashboard.children.socialize,
          routesUrls.dashboard.children.jobs,
          routesUrls.dashboard.children.jobAds,
          routesUrls.dashboard.children.activities,
          routesUrls.dashboard.children.communications
        ]
      },
      hasAccessToSettings: {
        pages: [
          routesUrls.settings.children.editProfile,
          routesUrls.settings.children.accountSettings,
          routesUrls.settings.children.agency,
          routesUrls.settings.children.agencyBranding,
          routesUrls.settings.children.socialNetworks
        ]
      },
      hasAccessToTools: {
        pages: [
          // routesUrls.tools.children.EdmBuilder,
          routesUrls.tools.children.XmlFeeds,
          routesUrls.tools.children.CreateJobAlert,
          routesUrls.tools.children.CreateTalentAlert,
          routesUrls.tools.children.PitchMe
        ],
        restrictedPages: [routesUrls.tools.children.QuestionMapping]
      },
      hasAccessToWidgets: {
        pages: []
      },
      hasAccessToBadges: {
        pages: [
          routesUrls.badges.children.yourProgress,
          routesUrls.badges.children.allBadges,
          routesUrls.badges.children.agencyLeaderboard
        ]
      },
      hasAccessToBilling: {
        pages: []
      },
      hasAccessToTopRec: {
        pages: [
          routesUrls.toprec.children.ratings,
          routesUrls.toprec.children.feedback,
          routesUrls.toprec.children.placements
        ]
      },
      hasAccessToReports: {
        pages: []
      }
    },
    consultant: {
      hasAccessToDashboard: {
        pages: [
          routesUrls.dashboard.children.candidates,
          routesUrls.dashboard.children.candidateAds,
          routesUrls.dashboard.children.candidateEvents,
          routesUrls.dashboard.children.referrers,
          routesUrls.dashboard.children.socialize,
          routesUrls.dashboard.children.jobs,
          routesUrls.dashboard.children.jobAds,
          routesUrls.dashboard.children.activities
        ]
      },
      hasAccessToSettings: {
        pages: [
          routesUrls.settings.children.editProfile,
          routesUrls.settings.children.accountSettings,
          routesUrls.settings.children.agency,
          routesUrls.settings.children.socialNetworks
        ]
      },
      hasAccessToTools: {
        pages: [
          routesUrls.tools.children.CreateJobAlert,
          routesUrls.tools.children.CreateTalentAlert,
          routesUrls.tools.children.PitchMe
        ],
        restrictedPages: []
      },
      hasAccessToWidgets: {
        pages: []
      },
      hasAccessToBilling: {
        pages: []
      },
      hasAccessToBadges: {
        pages: [
          routesUrls.badges.children.yourProgress,
          routesUrls.badges.children.allBadges,
          routesUrls.badges.children.agencyLeaderboard
        ]
      },
      hasAccessToTopRec: {
        pages: [
          routesUrls.toprec.children.ratings,
          routesUrls.toprec.children.feedback,
          routesUrls.toprec.children.placements
        ]
      },
      hasAccessToReports: {
        pages: []
      }
    },
    profile_only: {
      hasAccessToDashboard: {
        pages: [
          // routesUrls.dashboard.children.recruiters,
          // routesUrls.dashboard.children.users,
          // routesUrls.dashboard.children.leaderboard
        ]
      },
      hasAccessToSettings: {
        pages: [
          routesUrls.settings.children.editProfile,
          routesUrls.settings.children.accountSettings
        ]
      },
      hasAccessToTools: {
        pages: [],
        restrictedPages: []
      },
      hasAccessToWidgets: {
        pages: []
      },
      hasAccessToBilling: {
        pages: []
      },
      hasAccessToBadges: {
        pages: []
      },
      hasAccessToTopRec: {
        pages: []
      },
      hasAccessToReports: {
        pages: []
      }
    }
  },
  referrer: {
    hasAccessToDashboard: {
      pages: [
        routesUrls.dashboard.children.referrals,
        // routesUrls.dashboard.children.jobReferrals,
        routesUrls.dashboard.children.appliedJobs,
        routesUrls.dashboard.children.featured,
        routesUrls.dashboard.children.jobAlerts
      ]
    },
    hasAccessToSettings: {
      pages: [
        routesUrls.settings.children.editProfile,
        routesUrls.settings.children.accountSettings
      ]
    },
    hasAccessToTools: {
      pages: [],
      restrictedPages: []
    },
    hasAccessToWidgets: {
      pages: []
    },
    hasAccessToBilling: {
      pages: []
    },
    hasAccessToTopRec: {
      pages: []
    },
    hasAccessToReports: {
      pages: []
    }
  },
  hiring_manager: {
    hasAccessToDashboard: {
      pages: [
        routesUrls.dashboard.children.candidateEnquiries,
        routesUrls.dashboard.children.talentAlerts
      ]
    },
    hasAccessToSettings: {
      pages: [
        routesUrls.settings.children.editProfile,
        routesUrls.settings.children.accountSettings
      ]
    },
    hasAccessToTools: {
      pages: [],
      restrictedPages: []
    },
    hasAccessToWidgets: {
      pages: []
    },
    hasAccessToBilling: {
      pages: []
    },
    hasAccessToTopRec: {
      pages: []
    },
    hasAccessToReports: {
      pages: []
    }
  },

  default: {
    hasAccessToDashboard: {
      pages: [
        routesUrls.dashboard.children.recruiters,
        routesUrls.dashboard.children.users,
        routesUrls.dashboard.children.leaderboard,
        routesUrls.dashboard.children.clients
      ]
    },
    hasAccessToSettings: {
      pages: []
    },
    hasAccessToTools: {
      pages: [],
      restrictedPages: []
    },
    hasAccessToWidgets: {
      pages: []
    },
    hasAccessToBilling: {
      pages: []
    },
    hasAccessToTopRec: {
      pages: []
    },
    hasAccessToReports: {
      pages: [
        routesUrls.reports.children.general,
        routesUrls.reports.children.candidateAquisition,
        routesUrls.reports.children.billingPredictor
      ]
    }
  },
  candidateWidget: {
    hasAccessToDashboard: {
      pages: [
        routesUrls.dashboard.children.candidateEvents,
        routesUrls.dashboard.children.consultants,
        routesUrls.dashboard.children.communications
      ]
    },
    hasAccessToSettings: {
      pages: [
        routesUrls.settings.children.editProfile,
        routesUrls.settings.children.accountSettings
      ]
    },
    hasAccessToTools: {
      pages: [],
      restrictedPages: []
    },
    hasAccessToBadges: {
      pages: []
    },
    hasAccessToTopRec: {
      pages: []
    },
    hasAccessToWidgets: {
      pages: [routesUrls.widgets.children.registerWithUs]
    },
    hasAccessToBilling: {
      pages: []
    },
    hasAccessToReports: {
      pages: []
    }
  }
}
