import { RefariDTOTypes } from '@refari-frontend/types'

type AgencyAPITypes =
  RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']['results'][0]['agency']

export type IAgencyModel = {
  id: AgencyAPITypes['id']
  name: AgencyAPITypes['name']
  isActive: AgencyAPITypes['is_active']
  widgetBaseUrl: AgencyAPITypes['widget_base_url']
}

class Agency implements IAgencyModel {
  id: IAgencyModel['id']
  name: IAgencyModel['name']
  isActive: IAgencyModel['isActive']
  widgetBaseUrl: IAgencyModel['widgetBaseUrl']

  constructor(data: AgencyAPITypes) {
    this.id = data['id']
    this.name = data['name']
    this.isActive = data['is_active']
    this.widgetBaseUrl = data['widget_base_url']
  }
}

export default Agency
