// see https://github.com/lodash/lodash/issues/4815#issuecomment-1006573679
import debounce from 'lodash.debounce'

function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number
): (...args: Parameters<F>) => ReturnType<F> {
  const resolveSet = new Set<(p: any) => void>()
  const rejectSet = new Set<(p: any) => void>()

  const debounced = debounce((args: Parameters<F>) => {
    func(...args)
      .then((...res) => {
        resolveSet.forEach((resolve) => resolve(...res))
        resolveSet.clear()
      })
      .catch((...res) => {
        rejectSet.forEach((reject) => reject(...res))
        rejectSet.clear()
      })
  }, wait)

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve)
      rejectSet.add(reject)
      debounced(args)
    }) as ReturnType<F>
}

export default asyncDebounce
