/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import Particles from 'react-tsparticles'
import configs from '../../../constants/configs'

const MAX_CLICKS = 17

class ParticlesBackground extends React.Component {
  state = {
    count: 0
  }
  componentDidUpdate() {
    this.setState({ count: 0 })
  }
  shouldComponentUpdate() {
    if (this.state.count === MAX_CLICKS) {
      return true
    }
    return false
  }
  onClick = () => {
    this.setState({ count: this.state.count + 1 })
  }
  render() {
    const style = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    }
    return (
      <div onClick={this.onClick}>
        <Particles
          className="particles-background-wrapper"
          canvasClassName="particles-background"
          options={configs.particlesBackground}
          style={style}
          width="100%"
          height="100%"
        />
      </div>
    )
  }
}

export default ParticlesBackground
