import React, { Component } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { TextField, Paper, List } from '@material-ui/core'
import type { RenderInputProps } from 'react-tagsinput'

import FilterListItem from './FilterListItem'
import FilterSingle from './models/FilterSingle'
import type { ChangedFilter } from './types'

type InputRendererProps = {
  value: string
  showInputTag: boolean
  inBetweenValues?: 'OR' | 'AND'
  onBlur: RenderInputProps['onBlur']
  parentRef: RenderInputProps['ref']
  openPopup: () => void
  handleValueChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  displayDropdown: boolean
  label?: string
  checkList: (
    list: Array<InstanceType<typeof FilterSingle>>
  ) => Array<InstanceType<typeof FilterSingle>>
  dataList: Array<InstanceType<typeof FilterSingle>>
  onChangeFilter: (onChangleFilterArgs: ChangedFilter) => void
}

@observer
class InputRenderer extends Component<InputRendererProps> {
  render() {
    const {
      showInputTag,
      inBetweenValues,
      parentRef,
      openPopup,
      value,
      handleValueChange,
      label,
      displayDropdown,
      checkList,
      dataList,
      onChangeFilter
    } = this.props

    return (
      <div
        style={{ display: showInputTag ? 'inline-block' : 'none' }}
        className={classNames({
          'refari-react-tags-select-input': true,
          'refari-react-tags-select-input-new': inBetweenValues
        })}
      >
        <TextField
          name="tagSelect"
          ref={parentRef}
          onClick={openPopup}
          fullWidth
          autoComplete="off"
          inputProps={{
            form: {
              autoComplete: 'off'
            }
          }}
          value={value}
          onChange={handleValueChange}
          placeholder={`Select your ${label}`}
        />

        {displayDropdown && (
          <Paper
            id="refari-react-tags-select-input__modal"
            className="refari-react-tags-select-input__modal refari-filter-list-wrap"
          >
            <List className="refari-filter-list" key={`${label}_filter-list`}>
              {checkList(dataList)
                .sort((a, b) => {
                  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1
                  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
                  return 0
                })
                .map((filter) => {
                  return (
                    <FilterListItem
                      key={`${filter.key}_filter`}
                      data={filter}
                      filters={dataList}
                      onChangeFilter={onChangeFilter}
                    />
                  )
                })}
            </List>
          </Paper>
        )}
      </div>
    )
  }
}

export default InputRenderer
