/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { observable, runInAction, action } from 'mobx'
import {
  CircularProgress,
  Button,
  Checkbox as MUICheckbox,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Card,
  CardMedia,
  CardActions,
  Collapse,
  Radio,
  RadioGroup,
  Tabs,
  Tab,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import hexToRgba from 'hex-to-rgba'
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import BackgroundOptions from './BackgroundsOptions'
import GifFrames from './GifFrames'
import RaisedButton from '../../common/RaisedButton'
import FlatButton from '../../common/FlatButton'
import CircularProgressWithLabel from '../../common/CircularProgressWithLabel'
import Field from '../../../../models/form/Field'
import SocializeEditForm from '../../forms/SocializeEditForm'
import CanvasCardImage from './CanvasCardImage'
import PreviewSocialise from './PreviewSocialise'
import CanvasEditModal from './CanvasEditModal'
import { ApiRoutes } from '../../../../utils/Urls'
import SliderLabled from '../../formComponents/SliderLabled'
import Select from '../../formComponents/Select'
import SelectWithAutocomplete from '../../formComponents/SelectWithAutocomplete'
import ColorPicker from '../../formComponents/colorSlider/ColorPicker'
import UploadImageSocialise from '../../formComponents/UploadImageSocialise'

function allowSaveTestimonialBackground(email) {
  const ALLOWED_EMAILS = [
    'stevenjonessmith1@gmail.com',
    'Aarontester@refari.co'
  ]
  return ALLOWED_EMAILS.includes(email)
}

@observer
class FirstStep extends React.Component {
  static propTypes = {
    socializeStore: PropTypes.shape({
      title: PropTypes.instanceOf(Field).isRequired,
      description: PropTypes.instanceOf(Field).isRequired,
      image: PropTypes.instanceOf(Field),
      setPendingState: PropTypes.func.isRequired,
      pending: PropTypes.shape({
        image: PropTypes.bool.isRequired,
        actions: PropTypes.bool.isRequired
      }),
      toggle: PropTypes.func.isRequired,
      setTemplateImage: PropTypes.func.isRequired,
      isCustomImage: PropTypes.bool.isRequired
    }),
    setNextStep: PropTypes.func.isRequired,
    canvasCardStore: PropTypes.shape({
      setData: PropTypes.func.isRequired,
      agencyImageIsUsed: PropTypes.bool.isRequired,
      descriptionIsUsed: PropTypes.bool.isRequired,
      textColor: PropTypes.string,
      playIconColor: PropTypes.string,
      toggleTitle: PropTypes.func.isRequired,
      toggleRecruiterTopRecBar: PropTypes.func.isRequired,
      titleIsUsed: PropTypes.bool.isRequired,
      loadFontList: PropTypes.func.isRequired,
      fonts: PropTypes.array.isRequired,
      selectFont: PropTypes.func.isRequired,
      siteFont: PropTypes.string
    }).isRequired,
    customBackground: PropTypes.object.isRequired
  }

  @observable uploadPercentage = null
  @observable windowSize = window.innerWidth

  constructor(props) {
    super(props)
    this.blurBackgroundImage = new Field({
      name: 'blur_bg_description',
      value: props.canvasCardStore.blurValue
    })
    this.brightnessBackgroundImage = new Field({
      name: 'darken_bg_description',
      value: props.canvasCardStore.brightnessValue
    })
    this.tintLocation = new Field({
      name: 'tint_location',
      value: props.canvasCardStore.tintLocation
    })
    this.tintAngle = new Field({
      name: 'tint_angle',
      value: props.canvasCardStore.tintAngle
    })
    this.selectedFontField = new Field({
      name: 'selected_font',
      value: props.canvasCardStore.selectedFont
        ? {
            value: props.canvasCardStore.selectedFont.value,
            label: props.canvasCardStore.selectedFont.label
          }
        : {
            value: props.rootStore.agency.branding.socializeFont,
            label: props.rootStore.agency.branding.socializeFont
          }
    })
    this.selectedFontAlignment = new Field({
      name: 'selected_font_alignment',
      value: props.canvasCardStore.selectedFontAlignment
    })
    props.canvasCardStore.setData('fonts', props.rootStore.fonts)
    this.state = { tabValue: 0, isNewTemplateCreationInProgress: false }
    const isTestimonials = props?.canvasCardStore?.isTestimonials
    if (isTestimonials && !props?.canvasCardStore?.descriptionIsUsed) {
      props?.canvasCardStore.toggleDescription()
    }

    this.backgroundOptionsListRef = React.createRef()
  }

  componentDidMount() {
    this.loadFonts()
    this.setPrimaryColor(this.props)
    this.getWindowSize()
    window.addEventListener('resize', this.getWindowSize)
  }

  @action
  getWindowSize = () => {
    this.windowSize = window.innerWidth
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.customBackground &&
      this.props.customBackground &&
      nextProps.customBackground.settings_data !==
        this.props.customBackground.settings_data
    ) {
      if (
        nextProps.customBackground.settings_data &&
        nextProps.customBackground.settings_data.common
      ) {
        this.blurBackgroundImage.update(
          nextProps.customBackground.settings_data.common.blur
        )
        this.brightnessBackgroundImage.update(
          nextProps.customBackground.settings_data.common.brightness
        )
        this.tintLocation.update(
          nextProps.customBackground.settings_data.common.tintLocation !==
            undefined
            ? nextProps.customBackground.settings_data.common.tintLocation
            : 100
        )
        this.tintAngle.update(
          nextProps.customBackground.settings_data.common.tintAngle !==
            undefined
            ? nextProps.customBackground.settings_data.common.tintAngle
            : 0
        )
        this.selectedFontField.update({
          value: nextProps.customBackground.settings_data.common.font,
          label: nextProps.customBackground.settings_data.common.font
        })
      } else {
        this.blurBackgroundImage.update(nextProps.canvasCardStore.blurValue)
        this.brightnessBackgroundImage.update(
          nextProps.canvasCardStore.brightnessValue
        )
        this.tintLocation.update(nextProps.canvasCardStore.tintLocation)
        this.tintAngle.update(nextProps.canvasCardStore.tintAngle)
        this.selectedFontField.update(
          nextProps.canvasCardStore.selectedFont
            ? {
                value: nextProps.canvasCardStore.selectedFont.value,
                label: nextProps.canvasCardStore.selectedFont.label
              }
            : {
                value: nextProps.rootStore.agency.branding.socializeFont,
                label: nextProps.rootStore.agency.branding.socializeFont
              }
        )
      }
      this.setPrimaryColor(nextProps)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowSize)
  }

  setUploadPercentage = (percentage) => {
    runInAction(() => {
      this.uploadPercentage = percentage
    })
  }

  isGIFRendering = (status) => {
    const { canvasCardStore } = this.props
    runInAction(() => {
      canvasCardStore.setData('isGIFRenderingOnScreen', status)
    })
  }

  setPrimaryColor = (customProps) => {
    const { theme, canvasCardStore, customBackground } = customProps
    let color = hexToRgba(
      customBackground.settings_data && customBackground.settings_data.common
        ? customBackground.settings_data.common.tint
        : theme.palette.primary1Color
    )
    if (color) {
      color = color.replace('rgba(', '').replace(')', '')
      color = color.split(', ').slice(0, 3)
      if (!canvasCardStore.tintColorData.rgb) {
        canvasCardStore.setData('tintColorData', {
          rgb: { r: color[0], g: color[1], b: color[2] }
        })
      }
    }
  }

  loadFonts = async () => {
    try {
      const { canvasCardStore } = this.props
      await canvasCardStore.loadFontList()
      this.onChangeFont(false)
    } catch (e) {
      console.error(e)
    }
  }

  onPendingState = (isPending) => {
    const { socializeStore } = this.props
    socializeStore.setPendingState({
      image: isPending,
      actions: isPending
    })
    const { canvasCardStore } = this.props
    if (!isPending) {
      console.log('reset styles')
      this.blurBackgroundImage.update(0)
      canvasCardStore.setData('blurValue', 0)
      this.brightnessBackgroundImage.update(0)
      canvasCardStore.setData('brightnessValue', 0)
      this.tintLocation.update(0)
      canvasCardStore.setData('tintLocation', 0)
      this.tintAngle.update(0)
      canvasCardStore.setData('tintAngle', 0)
      canvasCardStore.setData('opacity', 0)
    }
  }

  onCheckApply = (e, isChecked) => {
    const { applyField } = this.props.socializeStore
    applyField.update(isChecked)
  }

  onCheckreferAFriend = (e, isChecked) => {
    const { referAFriendField } = this.props.socializeStore
    referAFriendField.update(isChecked)
  }

  onChangeBlur = (value) => {
    const { canvasCardStore } = this.props
    this.blurBackgroundImage.update(value)
    canvasCardStore.setData('blurValue', value)
  }

  onChangeBrightness = (value) => {
    const { canvasCardStore } = this.props
    this.brightnessBackgroundImage.update(value)
    canvasCardStore.setData('brightnessValue', value)
  }

  onChangeTintLocation = (value) => {
    const { canvasCardStore } = this.props
    this.tintLocation.update(value)
    canvasCardStore.setData('tintLocation', value)
  }

  onChangeTintAngle = (value) => {
    const { canvasCardStore } = this.props
    this.tintAngle.update(value)
    canvasCardStore.setData('tintAngle', value)
  }

  isGifUrl = (url = null) =>
    url?.split(/[#?]/)[0].split('.').pop().trim() === 'gif'

  onClickNextStep = async () => {
    const { setNextStep, canvasCardStore, socializeStore, customBackground } =
      this.props

    const canvasImageRef =
      this.refs.canvasImage || this.refs.previewSocialise.refs.canvasImage

    if (
      ((canvasCardStore.selectedBackground &&
        this.isGifUrl(canvasCardStore.selectedBackground.image)) ||
        (canvasCardStore.agencyImageIsUsed &&
          this.isGifUrl(
            canvasCardStore.selectedLogo &&
              canvasCardStore.selectedLogo === 'wide_logo'
              ? socializeStore.mainWideImage
              : socializeStore.mainImage
          ))) &&
      canvasImageRef
    ) {
      const canvasImage = await canvasImageRef.getImageBlob({
        sizeIndex: 2
      })
      if (
        canvasImageRef &&
        canvasImageRef.allFramesCanvas &&
        canvasImageRef.allFramesCanvas.length > 0 &&
        canvasImage
      ) {
        if (
          customBackground.settings_data &&
          customBackground.settings_data.common &&
          customBackground.settings_data.common.selectedFrameIndex !== undefined
        ) {
          const selectedFrame = canvasImageRef.allFramesCanvas.find(
            (frame) =>
              frame.id ===
              customBackground.settings_data.common.selectedFrameIndex
          )
          if (
            canvasCardStore.selectedBackground &&
            canvasCardStore.selectedBackground.isVideo
          ) {
            setNextStep(
              dataURLtoBlob(selectedFrame.url),
              canvasCardStore.selectedBackground.id,
              canvasImage
            )
          } else {
            // setNextStep(dataURLtoBlob(selectedFrame.url), null, canvasImage)
            socializeStore.setPendingState({
              actions: false,
              creationPost: false
            })
            canvasCardStore.setData('canvasImageGif', canvasImage)
            canvasCardStore.setData(
              'gifFramesCanvas',
              canvasImageRef.allFramesCanvas
            )
          }
        } else {
          socializeStore.setPendingState({
            actions: false,
            creationPost: false
          })
          canvasCardStore.setData('canvasImageGif', canvasImage)
          canvasCardStore.setData(
            'gifFramesCanvas',
            canvasImageRef.allFramesCanvas
          )
        }
      } else if (
        canvasCardStore.selectedBackground &&
        canvasCardStore.selectedBackground.isVideo &&
        canvasImage
      ) {
        setNextStep(canvasImage, canvasCardStore.selectedBackground.id)
      } else if (canvasImage) {
        setNextStep(canvasImage)
      }
    } else if (canvasImageRef) {
      const canvasImage = await canvasImageRef.getImageBlob({
        sizeIndex: 2
      })
      if (
        canvasCardStore.selectedBackground &&
        canvasCardStore.selectedBackground.isVideo &&
        canvasImage
      ) {
        setNextStep(canvasImage, canvasCardStore.selectedBackground.id)
      } else if (canvasImage) {
        setNextStep(canvasImage)
      }
    } else {
      setNextStep()
    }
  }

  onClickNextStepFrame = async () => {
    const { setNextStep, canvasCardStore } = this.props
    const selectedFrame =
      canvasCardStore.gifFramesCanvas &&
      canvasCardStore.gifFramesCanvas.find(
        (frame) => frame.id === canvasCardStore.selectedGifFrame
      )
    if (canvasCardStore.canvasImageGif && selectedFrame) {
      if (
        canvasCardStore.selectedBackground &&
        canvasCardStore.selectedBackground.isVideo
      ) {
        setNextStep(
          dataURLtoBlob(selectedFrame.url),
          canvasCardStore.selectedBackground.id,
          canvasCardStore.canvasImageGif
        )
      } else {
        setNextStep(
          dataURLtoBlob(selectedFrame.url),
          null,
          canvasCardStore.canvasImageGif
        )
      }
    } else {
      setNextStep()
    }
  }

  getSaveData = async () => {
    const {
      canvasCardStore: {
        isUsedRecruiterTopRecBar,
        isUsedCompanyTopRecBar,
        titleIsUsed,
        descriptionIsUsed,
        agencyImageIsUsed,
        tintColor,
        tintLocation,
        tintAngle,
        opacity,
        blurValue,
        brightnessValue,
        selectedLogo,
        selectedFont,
        siteFont,
        textColor,
        titleSelectedFont,
        descriptionSelectedFont,
        additionalSelectedFont,
        selectedFontAlignment,
        titleSelectedFontAlignment,
        descriptionSelectedFontAlignment,
        additionalSelectedFontAlignment,
        titleTextColor,
        descriptionTextColor,
        additionalTextColor,
        titleIsBold,
        descriptionIsBold,
        additionalIsBold,
        titleIsItalic,
        descriptionIsItalic,
        additionalIsItalic,
        titleFontSize,
        descriptionFontSize,
        additionalFontSize
      },
      socializeStore: { isUsedApplyText, isUsedreferAFriendText }
    } = this.props

    let settings = {}
    let canvasImage = null

    if (this.refs.canvasImage && this.refs.canvasImage.refs) {
      settings = {
        common: {
          showTitle: titleIsUsed,
          showDescription: descriptionIsUsed,
          showApplyText: isUsedApplyText,
          showTopRecBar: isUsedRecruiterTopRecBar,
          showCompanyTopRecBar: isUsedCompanyTopRecBar,
          showReferAFriendText: isUsedreferAFriendText,
          showLogo: agencyImageIsUsed,
          whichLogo: selectedLogo,
          tint: tintColor,
          tintLocation,
          tintAngle,
          opacity,
          blur: blurValue,
          brightness: brightnessValue,
          font: selectedFont ? selectedFont.value : siteFont,
          fontAlignment: selectedFontAlignment || 'center',
          color: textColor
        }
      }
      if (this.refs.canvasImage.refs.stage) {
        const stageSettings = this.refs.canvasImage.refs.stage.attrs
        settings.stage = {
          width: stageSettings.width,
          height: stageSettings.height
        }
      }

      /**
       * logo ref
       */
      if (this.refs.canvasImage.refs.mainImageRef) {
        const logoSettings = this.refs.canvasImage.refs.mainImageRef
        settings.logo = {
          x: logoSettings.x,
          y: logoSettings.y,
          width: logoSettings.width,
          height: logoSettings.height
        }
      }
      if (this.refs.canvasImage.refs.titleRef) {
        const titleSettings = this.refs.canvasImage.refs.titleRef
        settings.title = {
          x: titleSettings.x,
          y: titleSettings.y,
          width: titleSettings.width,
          height: titleSettings.height,
          font: titleSelectedFont
            ? titleSelectedFont.value
            : selectedFont
            ? selectedFont.value
            : siteFont,
          fontAlignment: titleSelectedFontAlignment || 'center',
          color: titleTextColor || textColor,
          bold: titleIsBold || false,
          italic: titleIsItalic || false,
          fontSize: titleFontSize
        }
      }
      if (this.refs.canvasImage.refs.descriptionRef) {
        const descriptionSettings = this.refs.canvasImage.refs.descriptionRef
        settings.description = {
          x: descriptionSettings.x,
          y: descriptionSettings.y,
          width: descriptionSettings.width,
          height: descriptionSettings.height,
          font: descriptionSelectedFont
            ? descriptionSelectedFont.value
            : selectedFont
            ? selectedFont.value
            : siteFont,
          fontAlignment: descriptionSelectedFontAlignment || 'center',
          color: descriptionTextColor || textColor,
          bold: descriptionIsBold || false,
          italic: descriptionIsItalic || false,
          fontSize: descriptionFontSize
        }
      }
      if (this.refs.canvasImage.refs.additionalRef) {
        const additionalSettings = this.refs.canvasImage.refs.additionalRef
        settings.additional = {
          x: additionalSettings.x,
          y: additionalSettings.y,
          width: additionalSettings.width,
          height: additionalSettings.height,
          font: additionalSelectedFont
            ? additionalSelectedFont.value
            : selectedFont
            ? selectedFont.value
            : siteFont,
          fontAlignment: additionalSelectedFontAlignment || 'center',
          color: additionalTextColor || textColor,
          bold: additionalIsBold || false,
          italic: additionalIsItalic || false,
          fontSize: additionalFontSize
        }
      }

      runInAction(() => {
        this.allFramesCanvas = []
      })
      canvasImage = await this.refs.canvasImage.getImageBlob({
        sizeIndex: 2
      })
    }

    return {
      settings,
      canvasImage
    }
  }

  getImageBlobFromUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'anonymous'
      img.src = url
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        canvas.toBlob((blob) => {
          resolve(blob)
        }, 'image/png')
      }
      img.onerror = () => {
        reject(new Error('Failed to load image'))
      }
    })
  }

  onSaveTemplate = async () => {
    const { canvasCardStore, rootStore } = this.props
    this.setState({ ...this.state, isNewTemplateCreationInProgress: true })
    if (canvasCardStore.backgrounds.length > 100) {
      this.setState({ ...this.state, openCustomBackgroundErrorModal: true })
      return
    }
    const { settings, canvasImage } = await this.getSaveData()
    const originalImageBlob = await this.getImageBlobFromUrl(
      canvasCardStore.selectedBackground.image
    )

    rootStore
      .addCustomBackground(settings, canvasImage, originalImageBlob)
      .then(() => {
        this.props.canvasCardStore.getBackgroundsList(
          this.props.socializeStore.videoThumbs
        )
        this.setState({ ...this.state, isNewTemplateCreationInProgress: false })
        if (this.backgroundOptionsListRef.current?.scrollTop)
          this.backgroundOptionsListRef.current.scrollTop = 0
      })
  }

  onChangeBackgroundOptions = async (e) => {
    console.log('onChange background options')
    const {
      setBackground,
      isGIFRenderingOnScreen,
      backgroundsVideoThumbs,
      disableAllBackgroundOptions
    } = this.props.canvasCardStore

    const { socializeStore } = this.props
    /**
     * remove uploaded image
     */
    socializeStore?.setTemplateImage()
    const isVideoBackground =
      Array.isArray(backgroundsVideoThumbs) &&
      backgroundsVideoThumbs.filter((item) => item.id === e.target.value)
        .length > 0

    if (!isGIFRenderingOnScreen) {
      await setBackground(e.target.value, true)
    }

    if (isVideoBackground) {
      disableAllBackgroundOptions()
      socializeStore.setData('isUsedApplyText', false)
      socializeStore.setData('isUsedreferAFriendText', false)
    }
  }

  onChangeGifFrame = (e) => {
    const { setGifFrame } = this.props.canvasCardStore
    setGifFrame(Number(e.target.value))
  }

  onChangeFont = (isUpdateAll = true) => {
    const {
      canvasCardStore: { selectFont, selectEditedFont },
      customBackground
    } = this.props
    selectFont(this.selectedFontField.value.value)
    if (!isUpdateAll && customBackground.settings_data) {
      selectEditedFont(
        customBackground.settings_data.title
          ? customBackground.settings_data.title.font
          : this.selectedFontField.value.value,
        'title'
      )
      selectEditedFont(
        customBackground.settings_data.description
          ? customBackground.settings_data.description.font
          : this.selectedFontField.value.value,
        'description'
      )
      selectEditedFont(
        customBackground.settings_data.additional
          ? customBackground.settings_data.additional.font
          : this.selectedFontField.value.value,
        'additional'
      )
    } else {
      selectEditedFont(this.selectedFontField.value.value, 'title')
      selectEditedFont(this.selectedFontField.value.value, 'description')
      selectEditedFont(this.selectedFontField.value.value, 'additional')
    }
  }

  onChangeFontAlignment = () => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData(
      'selectedFontAlignment',
      this.selectedFontAlignment.value
    )
    canvasCardStore.setData(
      'titleSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    canvasCardStore.setData(
      'descriptionSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
    canvasCardStore.setData(
      'additionalSelectedFontAlignment',
      this.selectedFontAlignment.value
    )
  }

  handleChangeComplete = (color) => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData('textColor', color.hex)
    canvasCardStore.setData('titleTextColor', color.hex)
    canvasCardStore.setData('descriptionTextColor', color.hex)
    canvasCardStore.setData('additionalTextColor', color.hex)
  }

  handleChangeTint = (color) => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData('tintColor', color.hex)
    canvasCardStore.setData('tintColorData', color)
  }

  handleChangePlayIcon = (color) => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData('playIconColor', color.hex)
  }

  handleOpacity = (val) => {
    const { canvasCardStore } = this.props
    canvasCardStore.setData('opacity', val)
  }

  handleTabChange = (e, newValue) => {
    this.setState({ tabValue: newValue })
  }

  handleChangeSwipe = (value) => {
    this.setState({ tabValue: value })
  }

  handleSaveTestimonialBackground = () => {
    const { setTestimonialBackgroundSettings } = this.props.canvasCardStore
    const settings = {}
    if (this.refs.canvasImage.refs.mainImageRef) {
      const logoSettings = this.refs.canvasImage.refs.mainImageRef
      settings.logo = {
        x: logoSettings.x,
        y: logoSettings.y,
        width: logoSettings.width,
        height: logoSettings.height
      }
    }

    // titleRef
    if (this.refs.canvasImage.refs.titleRef) {
      const titleSettings = this.refs.canvasImage.refs.titleRef

      settings.title = {
        x: titleSettings.x,
        y: titleSettings.y,
        width: titleSettings.width,
        height: titleSettings.height
      }
    }

    // descriptionRef
    if (this.refs.canvasImage.refs.descriptionRef) {
      const descriptionSettings = this.refs.canvasImage.refs.descriptionRef

      settings.description = {
        x: descriptionSettings.x,
        y: descriptionSettings.y,
        width: descriptionSettings.width,
        height: descriptionSettings.height
      }
    }

    // starRatingRef
    if (this.refs.canvasImage.refs.starRatingRef) {
      const starRatingSettings = this.refs.canvasImage.refs.starRatingRef
      settings.star_rating = {
        x: starRatingSettings.x,
        y: starRatingSettings.y,
        width: starRatingSettings.width,
        height: starRatingSettings.height
      }
    }
    // torpecVerifiedRef
    if (this.refs.canvasImage.refs.torpecVerifiedRef) {
      const toprecVerifiedSettings =
        this.refs.canvasImage.refs.torpecVerifiedRef
      settings.toprec_verified = {
        x: toprecVerifiedSettings.x,
        y: toprecVerifiedSettings.y,
        width: toprecVerifiedSettings.width,
        height: toprecVerifiedSettings.height
      }
    }

    setTestimonialBackgroundSettings(settings)
  }

  setGoogleReviewSettings = () => {
    const { canvasCardStore } = this.props
    if (canvasCardStore.isUsedRecruiterTopRecBar) {
      canvasCardStore.toggleRecruiterTopRecBar()
    }
    if (canvasCardStore.selectedLogo === 'square_logo') {
      canvasCardStore.setData('selectedLogo', 'wide_logo')
    }

    if (canvasCardStore.toggleTitle) {
      canvasCardStore.setData('titleIsUsed', false)
    }
  }

  render() {
    const {
      theme,
      socializeStore,
      theme: {
        palette: { secondaryTextColor, primary1Color }
      },
      canvasCardStore,
      rootStore,
      customBackground,
      rootStore: {
        averageRating,
        totalRatingCount,
        companyAverageRating,
        companyTotalRatingCount,
        user
      }
    } = this.props
    const { tabValue } = this.state

    const {
      description,
      title,
      editedTitle,
      editedDescription,
      pending,
      setTemplateImage,
      isCustomImage,
      mainImage,
      mainWideImage,
      additionalImage,
      additionalText,
      editedAdditionalTextValue,
      showAdditionalText,
      image,
      videoThumbs,
      postType,
      destinationURL
    } = socializeStore

    const styles = {
      cardStyle: {
        maxWidth: 500,
        margin: '1px 2px 15px',
        borderRadius: theme.paper.borderRadius,
        backgroundColor: 'transparent'
      },
      Hint: {
        color: secondaryTextColor
      },
      socialIcons: {
        width: 24,
        height: 24
      }
    }

    const fontFamily = [
      canvasCardStore.selectedFont
        ? canvasCardStore.selectedFont.value
        : rootStore.agency.branding.socializeFont
        ? rootStore.agency.branding.socializeFont
        : '',
      canvasCardStore.siteFont
    ]
      .filter((font) => !!font)
      .join(',')

    const allBackgrounds = canvasCardStore.backgroundsVideoThumbs.concat(
      canvasCardStore.backgrounds
    )

    const allowRecruitersUploadSocializeImage = Boolean(
      rootStore.agency?.allowRecruitersUploadSocializeImage
    )

    const showUploadNow =
      user.isAgencyAdminUser || allowRecruitersUploadSocializeImage

    const isTestimonials = canvasCardStore?.isTestimonials

    const showCreateNewTemplateButton =
      !isTestimonials &&
      (rootStore.user.isAgencyAdminUser || rootStore.user.isAgencyManagerUser)

    const isGoogleReview =
      this.props.socializeStore?.initialData?.isGoogleReview

    if (isGoogleReview && canvasCardStore.isUsedRecruiterTopRecBar) {
      this.setGoogleReviewSettings()
    }

    return (
      <div className="refari-socialize__first-step">
        {canvasCardStore.gifFramesCanvas &&
        canvasCardStore.gifFramesCanvas.length > 0 ? (
          <>
            <p>
              Select any one GIF frame which you want to use for twitter,
              facebook and direct link socialisations
            </p>
            <GifFrames
              images={canvasCardStore.gifFramesCanvas}
              selectedImage={canvasCardStore.selectedGifFrame}
              onChange={this.onChangeGifFrame}
            />
            <div className="refari-w-row refari-justify-start refari-align-items-center">
              <RaisedButton
                style={{
                  marginRight: '15px',
                  backgroundColor: theme.palette.primary1Color,
                  color: '#fff'
                }}
                disabled={pending.actions}
                className={`refari-button-raised refari-socialise-next-button ${
                  pending.actions ? 'refari-button-raised-disabled' : ''
                }`}
                onClick={this.onClickNextStepFrame}
              >
                Next
              </RaisedButton>

              <FlatButton
                color="primary"
                disabled={pending.actions}
                className="refari-button-flat"
                onClick={() => {
                  this.setUploadPercentage(null)
                  canvasCardStore.setData('gifFramesCanvas', [])
                  canvasCardStore.setData('canvasImageGif', null)
                }}
              >
                Back
              </FlatButton>
              {pending.creationPost && (
                <CircularProgress size={25} thickness={2} color="primary" />
              )}
            </div>
          </>
        ) : (
          <div className="refari-custom-background-template">
            {this.windowSize > 1100 && (
              <div
                className="refari-custom-background-template-left-most"
                style={{
                  maxHeight: 500
                }}
                ref={this.backgroundOptionsListRef}
              >
                <BackgroundOptions
                  images={allBackgrounds}
                  selectedImage={canvasCardStore.selectedBackgroundId}
                  onChange={this.onChangeBackgroundOptions}
                  canvasCardStore={canvasCardStore}
                />
              </div>
            )}
            <div className="refari-custom-background-template-left">
              <>
                <div className="refari-socialise-editor-tabs">
                  <Tabs
                    value={tabValue}
                    onChange={this.handleTabChange}
                    className="refari-socialise-editor-tabs_heading"
                    textColor="primary"
                    indicatorColor="primary"
                  >
                    <Tab
                      label="Editor"
                      disabled={canvasCardStore.isGIFRenderingOnScreen}
                    />
                    <Tab
                      label="Preview"
                      disabled={canvasCardStore.isGIFRenderingOnScreen}
                    />
                  </Tabs>
                </div>
                <div className="refari-socialise-editor-data">
                  {tabValue === 0 ? (
                    <div className="refari-socialise-editor">
                      <Card style={styles.cardStyle}>
                        <div className="refari-socialise-editor-top">
                          <div className="refari-socialise-editor-top-inner">
                            <div className="refari-socialise-editor-top-inner-image">
                              {mainImage && (
                                <Avatar
                                  src={
                                    isTestimonials ? mainWideImage : mainImage
                                  }
                                  style={{ height: 40, width: 40 }}
                                  size={40}
                                />
                              )}
                              {user.avatar && (
                                <Avatar
                                  src={
                                    isTestimonials ? mainImage : user.avatar.url
                                  }
                                  style={{
                                    height: 40,
                                    width: 40,
                                    position: 'absolute',
                                    left: 20,
                                    top: 0
                                  }}
                                  size={40}
                                />
                              )}
                            </div>
                            <div className="refari-socialise-editor-top-inner-box">
                              <div className="refari-socialise-editor-top-inner-box-title">
                                {`${rootStore.agency.name} / ${
                                  isTestimonials ? title?.value : user.fullName
                                }`}
                              </div>
                              <div className="refari-socialise-editor-top-inner-box-response">
                                Just now
                              </div>
                            </div>
                          </div>
                          <div
                            className="refari-socialise-editor-top-desc"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {socializeStore.description.value}
                          </div>
                        </div>
                        <CardMedia
                          className="refari-card-image-holder"
                          style={{ overflow: 'hidden' }}
                        >
                          <CanvasCardImage
                            ref="canvasImage"
                            title={title.value}
                            editedTitle={editedTitle.value}
                            editedDescription={editedDescription.value}
                            editedAdditionalTextValue={
                              editedAdditionalTextValue
                            }
                            textColor={canvasCardStore.textColor}
                            playIconColor={canvasCardStore.playIconColor}
                            additionalText={additionalText}
                            description={description.value}
                            mainImage={mainImage}
                            mainWideImage={mainWideImage}
                            additionalImage={additionalImage}
                            backgroundColor={
                              rootStore.agency.branding.useAdvancedOptions
                                ? rootStore.agency.branding
                                    .primaryColorSocialize
                                : theme.palette.primary1Color
                            }
                            backgroundImage={canvasCardStore.selectedBackground}
                            tintColor={canvasCardStore.tintColorData}
                            opacity={canvasCardStore.opacity}
                            socializeStore={socializeStore}
                            rootStore={rootStore}
                            canvasCardStore={canvasCardStore}
                            uploadPercentage={this.uploadPercentage}
                            setUploadPercentage={this.setUploadPercentage}
                            isGIFRendering={this.isGIFRendering}
                            customBackground={customBackground}
                          />
                          {pending.image && (
                            <div className="refari-card-media__loader-wrapper">
                              <CircularProgress color="primary" />
                            </div>
                          )}
                        </CardMedia>
                        <div className="refari-socialise-editor-bottom">
                          <div className="refari-socialise-editor-bottom-title">
                            {socializeStore.title.value}
                          </div>
                        </div>
                      </Card>
                      {this.windowSize <= 1100 && (
                        <BackgroundOptions
                          images={allBackgrounds}
                          selectedImage={canvasCardStore.selectedBackgroundId}
                          onChange={this.onChangeBackgroundOptions}
                          canvasCardStore={canvasCardStore}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="refari-socialise-editor refari-socialise-preview">
                      <PreviewSocialise
                        ref="previewSocialise"
                        rootStore={rootStore}
                        socializeStore={socializeStore}
                        canvasCardStore={canvasCardStore}
                        customBackground={customBackground}
                        uploadPercentage={this.uploadPercentage}
                        isGIFRendering={this.isGIFRendering}
                        setUploadPercentage={this.setUploadPercentage}
                      />
                    </div>
                  )}
                </div>
              </>
              {this.windowSize > 1100 && (
                <div className="refari-w-row refari-justify-start refari-align-items-center">
                  <RaisedButton
                    style={{
                      marginRight: '15px',
                      backgroundColor: theme.palette.primary1Color,
                      color: '#fff'
                    }}
                    disabled={
                      pending.actions ||
                      this.uploadPercentage !== null ||
                      canvasCardStore.isGIFRenderingOnScreen ||
                      (postType === 'anything' && !destinationURL.value) ||
                      (!canvasCardStore.selectedBackgroundId && !image?.value)
                    }
                    className={`refari-button-raised refari-socialise-next-button  ${
                      pending.actions ? 'refari-button-raised-disabled' : ''
                    }`}
                    onClick={this.onClickNextStep}
                  >
                    Next
                  </RaisedButton>
                  {showCreateNewTemplateButton && (
                    <>
                      <RaisedButton
                        color="primary"
                        style={{
                          marginRight: '15px'
                        }}
                        onClick={this.onSaveTemplate}
                        disabled={
                          this.state.isNewTemplateCreationInProgress ||
                          (!canvasCardStore.selectedBackgroundId &&
                            !image?.value)
                        }
                      >
                        Save As New Template
                      </RaisedButton>
                      {this.state.isNewTemplateCreationInProgress && (
                        <CircularProgress color="primary" size={24} />
                      )}
                    </>
                  )}
                  {isTestimonials &&
                  allowSaveTestimonialBackground(rootStore.user?.email) ? (
                    <RaisedButton
                      style={{
                        marginRight: '15px',
                        backgroundColor: theme.palette.primary1Color,
                        color: '#fff'
                      }}
                      onClick={this.handleSaveTestimonialBackground}
                    >
                      Save Background
                    </RaisedButton>
                  ) : null}
                  {pending.creationPost &&
                    (this.uploadPercentage !== null ? (
                      <CircularProgressWithLabel
                        value={this.uploadPercentage}
                        text={
                          this.uploadPercentage !== 100
                            ? 'Rendering GIF...'
                            : 'Uploading to Server...'
                        }
                      />
                    ) : (
                      <CircularProgress
                        size={25}
                        thickness={2}
                        color="primary"
                      />
                    ))}
                </div>
              )}
            </div>
            <div
              className="refari-custom-background-template-right"
              style={{ maxHeight: 500 }}
            >
              <div>
                <p className="refari-first-step__hint" style={styles.Hint}>
                  Photos attract people's attention. Use a default image or
                  choose a custom one. <br /> Select a memorable photo for your
                  post to get more candidate's attention:
                </p>
              </div>
              {showUploadNow && (
                <UploadImageSocialise
                  onPendingState={this.onPendingState}
                  fieldData={image}
                  uploadUrl={ApiRoutes.socializeWidget.imageCreate}
                  fileKey="image"
                  btnText="Upload now"
                  accept="image/*"
                  maxFileSize={10}
                />
              )}
              {isCustomImage && (
                <Button
                  color="primary"
                  className="refari-file-btn refari-button-flat"
                  component="label"
                  onClick={() => {
                    canvasCardStore.setData('selectedBackgroundId', null)
                    setTemplateImage()
                  }}
                  disabled={pending.image}
                  style={{
                    borderRadius: theme.flatButton.borderRadius,
                    color: theme.palette.primary1Color
                  }}
                >
                  Remove image
                </Button>
              )}

              <div className="refari-card-header">
                <SocializeEditForm socializeStore={socializeStore} />
              </div>
              <Collapse in timeout="auto" unmountOnExit>
                <CardActions
                  style={{ padding: '0', marginTop: 10, display: 'inherit' }}
                >
                  {((averageRating >= 4 && totalRatingCount > 4) ||
                    (companyAverageRating >= 4 &&
                      companyTotalRatingCount > 4)) &&
                  rootStore.agency.enableTestimonials &&
                  rootStore.agency.enableToprec ? (
                    <div className="refari-additional-options-row">
                      <FormControl component="fieldset" fullWidth>
                        <FormGroup row>
                          {averageRating >= 4 && totalRatingCount >= 5 && (
                            <FormControlLabel
                              control={
                                <MUICheckbox
                                  checked={
                                    canvasCardStore.isUsedRecruiterTopRecBar
                                  }
                                  onChange={
                                    canvasCardStore.toggleRecruiterTopRecBar
                                  }
                                  disabled={isGoogleReview}
                                  name="Recruiter TopRec bar"
                                  color="primary"
                                />
                              }
                              label="Recruiter TopRec bar"
                              style={{
                                width: '50%',
                                minWidth: 190,
                                marginRight: 0,
                                marginBottom: 0
                              }}
                            />
                          )}
                          {companyAverageRating >= 4 &&
                            companyTotalRatingCount >= 5 && (
                              <FormControlLabel
                                control={
                                  <MUICheckbox
                                    checked={
                                      canvasCardStore.isUsedCompanyTopRecBar
                                    }
                                    onChange={
                                      canvasCardStore.toggleCompanyTopRecBar
                                    }
                                    disabled={isGoogleReview}
                                    name="Company TopRec bar"
                                    color="primary"
                                  />
                                }
                                label="Company TopRec bar"
                                style={{
                                  width: '50%',
                                  minWidth: 190,
                                  marginRight: 0,
                                  marginBottom: 0
                                }}
                              />
                            )}
                        </FormGroup>
                      </FormControl>
                    </div>
                  ) : null}
                  <div className="refari-additional-options-row">
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <MUICheckbox
                              checked={canvasCardStore.titleIsUsed}
                              onChange={canvasCardStore.toggleTitle}
                              name="Add Text Box 1"
                              color="primary"
                            />
                          }
                          label="Add Text Box 1"
                          style={{
                            width: '50%',
                            minWidth: 190,
                            marginRight: 0,
                            marginBottom: 0
                          }}
                        />
                        <FormControlLabel
                          control={
                            <MUICheckbox
                              checked={canvasCardStore.descriptionIsUsed}
                              onChange={canvasCardStore.toggleDescription}
                              name="Add Text Box 2"
                              color="primary"
                            />
                          }
                          label="Add Text Box 2"
                          style={{
                            width: '50%',
                            minWidth: 190,
                            marginRight: 0,
                            marginBottom: 0
                          }}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  {showAdditionalText ? (
                    <div className="refari-additional-options-row">
                      <FormControl component="fieldset" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <MUICheckbox
                                checked={socializeStore.isUsedApplyText}
                                onChange={socializeStore.toggle(
                                  'isUsedApplyText'
                                )}
                                name="Click here to Apply"
                                color="primary"
                              />
                            }
                            label="Click here to Apply"
                            style={{
                              width: '50%',
                              minWidth: 190,
                              marginRight: 0,
                              marginBottom: 0
                            }}
                          />
                          <FormControlLabel
                            control={
                              <MUICheckbox
                                checked={socializeStore.isUsedreferAFriendText}
                                onChange={socializeStore.toggle(
                                  'isUsedreferAFriendText'
                                )}
                                name="Click here to Refer a friend"
                                color="primary"
                              />
                            }
                            label="Click here to Refer a friend"
                            style={{
                              width: '50%',
                              minWidth: 190,
                              marginRight: 0,
                              marginBottom: 0
                            }}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  ) : null}
                  <div className="refari-additional-options-row">
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <MUICheckbox
                              checked={canvasCardStore.agencyImageIsUsed}
                              onChange={canvasCardStore.toggleLogo}
                              name={
                                isTestimonials
                                  ? 'Show Image'
                                  : 'Show Company Logo'
                              }
                              color="primary"
                            />
                          }
                          label={
                            isTestimonials ? 'Show Image' : 'Show Company Logo'
                          }
                          style={{
                            width: '50%',
                            minWidth: 190,
                            marginRight: 0,
                            marginBottom: 0
                          }}
                        />
                        {additionalImage && (
                          <FormControlLabel
                            control={
                              <MUICheckbox
                                checked={canvasCardStore.consultantImageIsUsed}
                                onChange={canvasCardStore.toggleProfilePhoto}
                                name="Show Profile Photo"
                                color="primary"
                              />
                            }
                            label="Show Profile Photo"
                            style={{
                              width: '50%',
                              minWidth: 190,
                              marginRight: 0,
                              marginBottom: 0
                            }}
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                  </div>
                  {mainImage && (
                    <div className="refari-socialize__radio-group">
                      <FormControl component="fieldset" fullWidth>
                        <FormLabel
                          className="refari-socialize__radio-group-label"
                          component="legend"
                          style={{ fontSize: '15px', margin: '0 0 15px' }}
                        >
                          Change Logo:
                        </FormLabel>

                        <RadioGroup
                          name="logoImage"
                          onChange={canvasCardStore.handleLogoRadioCheck}
                          value={canvasCardStore.selectedLogo}
                          style={{ flexDirection: 'row', display: 'flex' }}
                        >
                          <FormControlLabel
                            name="square_logo"
                            value="square_logo"
                            control={<Radio color="primary" />}
                            label={
                              isTestimonials ? 'Recruiter Image' : 'Round Logo'
                            }
                            disabled={isGoogleReview}
                            style={{
                              width: '50%',
                              minWidth: 190,
                              marginRight: 0,
                              marginBottom: 0
                            }}
                          />
                          {mainWideImage && (
                            <FormControlLabel
                              name="wide_logo"
                              value="wide_logo"
                              control={<Radio color="primary" />}
                              label={
                                isTestimonials ? 'Company Image' : 'Wide Logo'
                              }
                              style={{
                                width: '50%',
                                minWidth: 190,
                                marginRight: 0,
                                marginBottom: 0
                              }}
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                  <div>
                    <SliderLabled
                      label="Blur background image:"
                      min={0}
                      max={25}
                      step={1}
                      value={this.blurBackgroundImage.value}
                      defaultValue={0}
                      onChange={this.onChangeBlur}
                    />
                    <SliderLabled
                      label="Brightness background image:"
                      min={-1}
                      max={1}
                      minLabelText="darkest"
                      maxLabelText="lightest"
                      step={0.1}
                      value={this.brightnessBackgroundImage.value}
                      defaultValue={0}
                      onChange={this.onChangeBrightness}
                    />
                  </div>
                  <div className="refari-font-selector">
                    <label
                      style={{ fontSize: '15px', margin: '0 0 15px' }}
                      className="refari-font-selector__label"
                    >
                      Font:{' '}
                      <span style={{ fontFamily }}>
                        {canvasCardStore.selectedFont
                          ? canvasCardStore.selectedFont.value
                          : rootStore.agency.branding.socializeFont
                          ? rootStore.agency.branding.socializeFont
                          : `${canvasCardStore.siteFont} (current font applied on site)`}
                      </span>
                    </label>
                    <SelectWithAutocomplete
                      hint="Select font"
                      options={canvasCardStore.fonts}
                      fieldData={this.selectedFontField}
                      cb={this.onChangeFont}
                    />
                    <div className="refari-font-selector__provider">
                      Fonts provided by{' '}
                      <a
                        style={{ color: primary1Color }}
                        href="https://fonts.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Fonts
                      </a>
                    </div>
                    <Select
                      fieldData={this.selectedFontAlignment}
                      options={canvasCardStore.fontAlignment}
                      emptyOption=""
                      optionValueKey="value"
                      optionTextKey="label"
                      label="Font alignment"
                      floatingLabelFixed
                      cb={this.onChangeFontAlignment}
                    />
                  </div>
                  {videoThumbs &&
                    videoThumbs.length > 0 &&
                    canvasCardStore.selectedBackground &&
                    canvasCardStore.selectedBackground.isVideo && (
                      <ColorPicker
                        label="Play icon colour"
                        color={canvasCardStore.playIconColor}
                        onChangeComplete={this.handleChangePlayIcon}
                      />
                    )}
                  <ColorPicker
                    label="Text colour"
                    color={canvasCardStore.textColor}
                    onChangeComplete={this.handleChangeComplete}
                  />
                  <div className="refari-font-selector">
                    <p>Socialise tint:</p>
                    <ColorPicker
                      label="Tint colour"
                      color={canvasCardStore.tintColor}
                      onChangeComplete={this.handleChangeTint}
                    />
                    <div style={{ marginTop: 20 }}>
                      <SliderLabled
                        label="Tint location"
                        min={0}
                        max={200}
                        step={10}
                        value={this.tintLocation.value}
                        defaultValue={100}
                        onChange={this.onChangeTintLocation}
                      />
                      <SliderLabled
                        label="Tint angle"
                        min={0}
                        max={360}
                        minLabelText="0°"
                        maxLabelText="360°"
                        step={90}
                        value={this.tintAngle.value}
                        defaultValue={0}
                        onChange={this.onChangeTintAngle}
                      />
                      <SliderLabled
                        label="Tint opacity"
                        min={0}
                        max={1}
                        step={0.1}
                        value={canvasCardStore.opacity}
                        defaultValue={0}
                        onChange={this.handleOpacity}
                      />
                    </div>
                  </div>
                </CardActions>
              </Collapse>
              {this.windowSize <= 1100 && (
                <div className="refari-w-row refari-justify-start refari-align-items-center">
                  <RaisedButton
                    style={{
                      marginRight: '15px',
                      backgroundColor: theme.palette.primary1Color,
                      color: '#fff'
                    }}
                    disabled={
                      pending.actions ||
                      this.uploadPercentage !== null ||
                      canvasCardStore.isGIFRenderingOnScreen ||
                      (postType === 'anything' && !destinationURL.value) ||
                      (!canvasCardStore.selectedBackgroundId && !image?.value)
                    }
                    className={`refari-button-raised refari-socialise-next-button ${
                      pending.actions ? 'refari-button-raised-disabled' : ''
                    }`}
                    onClick={this.onClickNextStep}
                  >
                    Next
                  </RaisedButton>
                  {pending.creationPost &&
                    (this.uploadPercentage !== null ? (
                      <CircularProgressWithLabel
                        value={this.uploadPercentage}
                        text={
                          this.uploadPercentage !== 100
                            ? 'Rendering GIF...'
                            : 'Uploading to Server...'
                        }
                      />
                    ) : (
                      <CircularProgress
                        size={25}
                        thickness={2}
                        color="primary"
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
        <CanvasEditModal
          rootStore={rootStore}
          canvasCardStore={canvasCardStore}
        />
        <Dialog
          open={this.state.openCustomBackgroundErrorModal}
          onClose={() =>
            this.setState({
              ...this.state,
              openCustomBackgroundErrorModal: false
            })
          }
          maxWidth="sm"
          fullWidth
        >
          <DialogContent>
            You have reached your 100 limit, you will need to delete a
            background in Company Branding to save this.
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({
                  ...this.state,
                  openCustomBackgroundErrorModal: false
                })
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withTheme(FirstStep)
