import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react'
import Modal from '../Modal'
import CloseModalButton from '../CloseModalButton'
import { baseURLs } from '../../../../utils/Urls'

@inject('networkStore')
@observer
class AddSocialAccount extends React.Component {
  constructor(props) {
    super(props)
  }

  onClose = () => {
    const { onClose, socializeStore } = this.props
    socializeStore.refreshTokens()
    socializeStore.fetchCompanies()
    onClose && onClose()
  }

  render() {
    const { open, networkStore } = this.props

    return (
      <Modal
        className="refari-w-modal-container"
        open={open}
        onClose={this.onClose}
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: '#fff',
            // borderColor: theme.widgetBackgroundColor,
            padding: 0,
            height: '200'
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton
            onClose={this.onClose}
            style={{ top: 0, right: 0, padding: 0 }}
          />
          <div
            style={{
              width: '100%',
              // maxWidth: 625,
              margin: 'auto',
              minHeight: 300
            }}
          >
            <iframe
              src={`${baseURLs(
                'frontend'
              )}settings/social-networks/?external=true&source=${
                networkStore.source
              }`}
              id="add-social-account-alert-iframe"
              frameBorder="0"
              style={{
                position: 'relative',
                height: '80vh',
                width: '100%'
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(AddSocialAccount)
