/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'

import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'
import { Dialog } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseButton from '../buttons/CloseButton'

@inject('utilsStore')
@observer
class SuccessModal extends React.Component {
  static propTypes = {
    utilsStore: PropTypes.shape({
      successKey: PropTypes.string.isRequired,
      toggleModal: PropTypes.func.isRequired,
      modals: PropTypes.object.isRequired
    })
  }

  toggleModal = () => {
    const { toggleModal, successKey } = this.props.utilsStore
    toggleModal(successKey)
  }

  UNSAFE_componentWillMount() {
    const { successKey, modals } = this.props.utilsStore
    this.disposeReaction = autorun(() => {
      if (modals[successKey].isOpen) {
        setTimeout(() => {
          this.toggleModal()
        }, 5000)
      }
    })
  }

  componentWillUnmount() {
    this.disposeReaction()
  }

  render() {
    const {
      utilsStore: { successKey, modals },
      theme,
      title
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }
    return (
      <Dialog
        className="modal modal-override"
        fullWidth
        open={modals[successKey].isOpen}
        onClose={this.toggleModal}
      >
        <div className="modal-content">
          <CloseButton onClose={this.toggleModal} />
          <h3 style={style.title}>{modals[successKey].title || 'Success!'}</h3>
          <h4>
            {modals[successKey].message}
            {modals[successKey].footerMessage && (
              <>
                <br />
                {modals[successKey].footerMessage}
              </>
            )}
          </h4>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(SuccessModal)
