/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const GoogleIcon = (props) => (
  <SvgIcon
    {...props}
    width="24"
    height="18"
    viewBox="0 0 24 18"
  >
    <path
      transform="translate(-460 -477)"
      fill={props.style && props.style.color ? props.style.color : '#fff'}
      d="M477.97 484.99h-8.38v2.79h5.11a5.98 5.98 0 0 1-11.7-1.79 5.99 5.99 0 0 1 10.25-4.22l2.03-2.2a8.98 8.98 0 1 0-6.29 15.4 9.17 9.17 0 0 0 8.98-7.19v-2.79z"
    />
    <path
      transform="translate(-460 -477)"
      fill={props.style && props.style.color ? props.style.color : '#fff'}
      d="M483.9 484.72h-1.71V483h-1.47v1.72H479v1.47h1.72v1.71h1.47v-1.71h1.71z"
    />
  </SvgIcon>
)

export default GoogleIcon
