/* eslint-disable max-len */
import React from 'react'
import hexToRgba from 'hex-to-rgba'
import {
  Button,
  Avatar,
  Paper,
  ListSubheader,
  Typography
} from '@material-ui/core'
import qp from 'query-parse'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import Input from '../../shared/formComponents/Input'
import { validationErrors as errors } from '../../../utils/helpers'
import star from '../../../assets/images/star.png'
import disabledStar from '../../../assets/images/disabledStar.png'
import URLs from '../../../router/routesUrls'
import { starsList } from '../../../constants/testimonials'
import { Rating } from '@material-ui/lab'

@inject('testimonialsStore')
@observer
class AddTestimonials extends React.PureComponent {
  constructor(props) {
    super(props)
    !props.isReviewedTestimonial &&
      this.setQueryAndFindData(props.location.search.slice(1))
    this.nextStep = this.nextStep.bind(this)
    this.form = new FieldsGroup({
      data: new Field({
        name: 'data',
        value:
          props.testimonialsStore && props.testimonialsStore.consultantInfo
            ? props.testimonialsStore.consultantInfo?.comment ||
              props.testimonialsStore.consultantInfo?.text
            : '',
        validators: [errors.required(), this.validateInputValueSize]
      })
    })
    this.state = {
      consultantRating: props.testimonialsStore?.consultantResponse?.rating || 0
    }
  }

  validateInputValueSize = (value) => {
    const { testimonialsStore } = this.props
    const minLength =
      testimonialsStore.consultantResponse?.rating === 5 ? 100 : 0
    const maxLength =
      testimonialsStore.consultantResponse?.rating === 5 ? 1000 : 100
    if (value.length > maxLength) {
      return `Field must be maximum of ${maxLength} characters`
    }
    if (value.length < minLength) {
      return `Field must be minimum of ${minLength} characters`
    }
    return null
  }

  setQueryAndFindData = (data) => {
    const newData = qp.toObject(data)
    if (newData && newData.id) {
      this.props.testimonialsStore.checkTestimonial(newData.id)
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()
    if (!this.form.isValid) {
      return
    }
    const { testimonialsStore, location } = this.props

    let data = {}
    const newData = qp.toObject(location.search.slice(1))
    if (newData && newData.id) {
      data = {
        id: testimonialsStore.consultantInfo.id
      }
    } else {
      data = {
        id: testimonialsStore.testimonialsInfo.id
      }
    }

    if (testimonialsStore.consultantResponse.rating === 5) {
      data.text = this.form.fields.data.value
    } else {
      data.comment = this.form.fields.data.value
    }

    if (
      testimonialsStore.consultantResponse.rating !==
      this.state.consultantRating
    ) {
      /**
       * consultant ratings are changed make a revision
       */

      data.rating =
        this.state.consultantRating ||
        testimonialsStore.consultantResponse.rating
    }

    if (this.props.isReviewedTestimonial) {
      data.revision = this.props.revisionID
      data.rating = Number(
        this.state.consultantRating || testimonialsStore.consultantInfo.rating
      )
      data.consultant = Number(testimonialsStore.consultantInfo.consultant.id)

      testimonialsStore.submitReviewedTestimonials(data, this.nextStep)
    } else {
      testimonialsStore.updateTestimonials(data, this.nextStep)
    }
  }

  nextStep() {
    const { consultantResponse, consultantInfo } = this.props.testimonialsStore

    if (consultantResponse.rating === 5) {
      this.props.history.push(URLs.testimonials.children.testimonialsShare)
    } else {
      this.props.history.push(
        `${URLs.testimonials.children.thank}?redirect=${encodeURIComponent(
          consultantInfo.agency.widget_base_url
        )}&agencyname=${consultantInfo.agency.name}`
      )
    }
  }

  render() {
    const {
      theme,
      testimonialsStore: {
        consultantInfo,
        redirect,
        consultantResponse,
        errors
      },
      history
    } = this.props

    if (redirect) {
      if (errors === 'expired') {
        history.push(URLs.testimonials.children.testimonialsExpired)
      } else {
        history.push(URLs.testimonials.children.testimonialsReviewed)
      }
    }

    if (!consultantInfo || (consultantInfo && !consultantInfo.consultant)) {
      return null
    }

    return (
      <div className="flex-center full-width">
        <Paper className="testimonials-feedback testimonials-feedback__add-modal">
          <p
            style={{ color: theme.palette.primary1Color }}
            className="testimonials-feedback--header-content"
          >
            {consultantResponse.rating === 5
              ? 'Thank you for the rating!'
              : 'Add comment'}
          </p>
          {consultantResponse.rating === 5 ? (
            <p className="testimonials-feedback--content-text testimonials-feedback--content-text-add m-t-1">
              We are very grateful that you shared your opinion! Would you like
              to leave a public testimonial about your recruiter? Share your
              experience with the community
            </p>
          ) : (
            <p className="testimonials-feedback--content-text testimonials-feedback--content-text-add m-t-1">
              If you would like to add your comment, please share your thoughts.
              All comments are reviewed by our management team :)
            </p>
          )}
          <div
            className="testimonials-feedback__content m_top_2x m-bot-2x"
            style={{
              backgroundColor: 'transparent',
              paddingTop: 0,
              paddingBottom: 0
            }}
          >
            <Avatar
              src={
                consultantInfo.consultant.avatar &&
                consultantInfo.consultant.avatar.thumb
              }
              style={{ width: '7rem', height: '7rem' }}
            />
            <p className="testimonials-feedback--user-name">
              {consultantInfo.consultant.first_name}{' '}
              {consultantInfo.consultant.last_name}
            </p>
            <div>
              <Rating
                name="consultant-rating"
                value={this.state.consultantRating}
                onChange={(_event, newValue) => {
                  this.setState({ consultantRating: newValue })
                }}
              />
            </div>
          </div>
          <div className="full-width testimonials-feedback__add-modal-submit m-b-4x">
            <form noValidate onSubmit={this.onSubmit}>
              <Typography variant="subtitle2" style={{ color: '#757575' }}>
                {consultantResponse.rating === 5
                  ? 'Add your testimonial'
                  : 'Add your comment'}
              </Typography>
              <Input
                fieldData={this.form.fields.data}
                hideLabel
                required
                variant="outlined"
                rows={4}
                rowsMax={5}
                multiline
                fullWidth
                maxLength={consultantResponse.rating === 5 ? 1000 : 100}
                counter
                counterIndicator={consultantResponse.rating === 5}
              />
              <Button
                variant="contained"
                type="submit"
                className="submit-btn"
                fullWidth
                color="primary"
                disabled={!this.form.isValid}
              >
                Submit
              </Button>
              {consultantResponse.rating === 5 && (
                <ListSubheader
                  disableSticky
                  style={{ width: '100%', padding: 0 }}
                >
                  * Your testimonial must be a minimum of 100 characters.
                </ListSubheader>
              )}
            </form>
          </div>
        </Paper>
      </div>
    )
  }
}

export default withTheme(AddTestimonials)
