import { toJS } from 'mobx'
import isEmpty from 'lodash/isEmpty'

type Args = {
  locationFieldId: string
  form: Record<string, any>
}

const formatDetailLocationFromForm = (args: Args): string => {
  const { locationFieldId, form } = args

  const formattableData = []

  const countryField = toJS(
    form.fields[`detailedLocationSelectionCountry${locationFieldId}`]
  )
  const stateField = toJS(
    form.fields[`detailedLocationSelectionState${locationFieldId}`]
  )
  const cityField = toJS(
    form.fields[`detailedLocationSelectionCity${locationFieldId}`]
  )
  const postalCodeField = toJS(
    form.fields[`detailedLocationSelectionPostalCode${locationFieldId}`]
  )

  if (!isEmpty(countryField.value)) {
    formattableData.push(countryField.value.name)
  }

  if (!isEmpty(stateField.value)) {
    formattableData.push(stateField.value.name)
  }

  if (!isEmpty(cityField.value)) {
    formattableData.push(cityField.value.name)
  }

  if (!isEmpty(postalCodeField.value)) {
    formattableData.push(postalCodeField.value)
  }

  return formattableData.join(', ')
}

export default formatDetailLocationFromForm
