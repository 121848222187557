/* eslint-disable camelcase */
/* eslint-disable max-len */
import { observable, action } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import { mapFormServerErrors } from '../utils/helpers'
import Logger from '../utils/Logger'
import AuthSession from '../utils/AuthSession'
import RootStore from './RootStore'
import PitchMeStore from './PitchMeStore'
import alertMessages from '../constants/alertMessages'
import credentials from '../constants/credentials'
import AgenciesStore from './AgenciesStore'
import AuthTwoFactorStore from './AuthTwoFactorStore'
import RewardStore from './RewardStore'

export class AuthStore {
  @observable isOpenModal = false
  @observable isOpenResetPasswordModal = false
  @observable resetPasswordData = null
  @observable isSignUp = false
  @observable isSignIn = true
  @observable isForgotPassword = false
  @observable isResendVerify = false
  @observable isUpdate = true
  @observable previousLink = ''
  @observable stayOnSameLink = ''

  @observable tempNotVerifiedEmail

  // TODO: Refactor modal handling
  @action.bound
  toggleModal() {
    this.isOpenModal = !this.isOpenModal
    this.isResendVerify = false
  }

  @action
  closeModal = () => {
    this.toggleModal()
    this.isSignUp = false
    this.isSignIn = true
    this.isForgotPassword = false
  }

  @action updatePreviousLink = (link) => {
    if (!window.location.hash.includes('login') || RootStore.loggedIn) {
      return
    }
    this.previousLink = link
    this.toggleModal()
    this.isUpdate = false
  }

  @action updateSameLinkForApprove = (link) => {
    if (RootStore.loggedIn) {
      return
    }
    this.stayOnSameLink = link
    this.toggleModal()
  }

  @action
  toggleSignUp = () => {
    this.isSignUp = !this.isSignUp
  }

  @action
  toggleSignIn = () => {
    this.isSignIn = !this.isSignIn
  }

  @action
  toggleForgotPassword = () => {
    this.isForgotPassword = !this.isForgotPassword
  }

  @action
  toggleResendVerify = () => {
    this.isResendVerify = !this.isResendVerify
  }

  @action
  signInAs = async (url, id, cb) => {
    try {
      const response = await API.postData(url)
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      await RootStore.fetchUserData(cb)
      PitchMeStore.resetStoreData()
      const name = `${response.data.first_name} ${response.data.last_name}`
      toast.success(alertMessages.signinAs(name, response.data.email))

      await AgenciesStore.fetchApiKey()
      RewardStore.setData('crmStatusList', [])

      if (
        response.data.user_type === 'agency' &&
        (response.data.role === 'admin' || response.data.role === 'manager')
      ) {
        await RootStore.fetchAgencyBackgrounds()
      }
    } catch (error) {
      Logger.error(error)
      if (error.data) {
        toast.error(error.data[0])
      }
    }
  }

  @action
  loginAsConsultant = async (id, cb) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.loginAs(id)
      )
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      RootStore.fetchUserData(cb)
      PitchMeStore.resetStoreData()
      const name = `${response.data.first_name} ${response.data.last_name}`
      let resAlert = alertMessages.signinAs(name, response.data.email)
      if (resAlert.length > 80) {
        resAlert = `${resAlert.slice(0, 80)}...`
      }
      toast.success(resAlert)
    } catch (e) {
      Logger.error(e)
    }
  }

  @action
  signIn = async (form, cb) => {
    try {
      const response = await API.postData(ApiRoutes.auth.signIn, form.data)

      if (response.response && response.response.status === 400) {
        const { data } = response.response

        if (data.non_field_errors) {
          toast.error(data.non_field_errors[0])
        }
      } else if (response.response && response.response.status === 426) {
        const { data } = response.response

        this.setData('resetPasswordData', data)
        this.closeModal()
        this.setData('isOpenResetPasswordModal', true)
      } else if (response && response.status && response.status === 202) {
        this.closeModal()

        AuthTwoFactorStore.setData('signInToken', response.data.token)
        AuthTwoFactorStore.setData('signInCallback', cb)
        AuthTwoFactorStore.setData('isOpenAuthAppCodeModal', true)
      } else {
        AuthSession.set(response.data.key)
        AuthSession.setGlobal(true, 'RefariLoggedIn')

        RootStore.initialProfileRequest(cb)

        this.closeModal()

        await AgenciesStore.fetchApiKey()

        toast.success(alertMessages.signin)
      }
    } catch (error) {
      Logger.error(error)

      if (error.data && error.data.non_field_errors[0]) {
        toast.error(error.data.non_field_errors[0])
      }

      const { data } = error.response

      if (
        data.codes &&
        data.codes.non_field_errors[0] === 'email_not_verified'
      ) {
        // custom condition for show 'not verified email' screen
        this.setData('tempNotVerifiedEmail', form.data.email)

        this.toggleResendVerify()
        this.toggleSignIn()
      }

      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  signUp = async (form) => {
    try {
      const response = await API.postData(ApiRoutes.auth.signUp, form.data)
      this.setData('tempNotVerifiedEmail', form.data.email)
      this.toggleResendVerify()
      this.toggleSignUp()
      toast.success(response.data.detail)
    } catch (error) {
      Logger.error(error)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  forgotPasswordCreate = async (form) => {
    try {
      const response = await API.postData(
        ApiRoutes.auth.forgotPasswordCreate,
        form.data
      )
      this.closeModal()
      toast.success(response.data.detail)
    } catch (error) {
      Logger.error(error)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  passwordChange = async (form) => {
    try {
      const response = await API.postData(
        ApiRoutes.auth.passwordChange,
        form.data
      )
      form.reset()
      toast.success(response.data.detail)
    } catch (error) {
      Logger.error(error)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  forgotPasswordConfirm = async (form, params, cb) => {
    try {
      const data = { ...form.data, ...params }
      const response = await API.postData(
        ApiRoutes.auth.forgotPasswordConfirm,
        data
      )
      form.reset()
      toast.success(response.data.detail)
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      RootStore.initialProfileRequest(cb)
      this.setData('isOpenResetPasswordModal', false)
    } catch (error) {
      Logger.error(error)
      let response = error
      if (error && error.response) {
        response = error.response
      }
      const { data } = response
      if (data.token) {
        toast.error(data.token[0])
        if (cb) {
          cb()
        }
      } else {
        mapFormServerErrors(data, form.fields)
      }
    }
  }

  @action
  emailVerify = async (data) => {
    try {
      const response = await API.postData(ApiRoutes.auth.emailVerify, data)
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      RootStore.initialProfileRequest()
      toast.success(alertMessages.emailVerified)
    } catch (error) {
      Logger.error(error)
      toast.error(alertMessages.error)
    }
  }

  @action
  resendEmailVerify = async () => {
    try {
      const response = await API.postData(ApiRoutes.auth.resendEmailVerify, {
        email: this.tempNotVerifiedEmail
      })
      toast.success(response.data.detail)
    } catch (error) {
      Logger.error(error)
      toast.error(alertMessages.error)
    }
  }

  @action
  socialCallback = async (socialName, res, cb) => {
    try {
      const access_token = res.accessToken
      const response = await API.postData(ApiRoutes.auth[socialName], {
        access_token
      })
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      RootStore.initialProfileRequest(cb)
      this.closeModal()
      toast.success(alertMessages.signin)
    } catch (error) {
      Logger.error(error)
      const { non_field_errors } = error.response.data
      if (non_field_errors && socialName === credentials.facebook.key) {
        toast.error(non_field_errors.join('. '))
        window.FB.logout()
      }
    }
  }

  @action
  linkedinCodeCallback = async (code, cb) => {
    try {
      const response = await API.postData(ApiRoutes.auth.linkedin, { code })
      AuthSession.set(response.data.key)
      AuthSession.setGlobal(true, 'RefariLoggedIn')
      RootStore.initialProfileRequest(cb)
      this.closeModal()
      toast.success(alertMessages.signin)
    } catch (error) {
      Logger.error(error)
      const data = error.data
      console.log('data', data)
      if (error.data.non_field_errors) {
        toast.error(
          Array.isArray(error.data.non_field_errors)
            ? error.data.non_field_errors.join('. ')
            : error.data.non_field_errors
        )
      }
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new AuthStore()
