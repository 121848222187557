export default class Testimonial {
  constructor(data) {
    if (!data) {
      return {}
    }
    this.author = {
      email: data.author.email || '',
      firstName: data.author.first_name || '',
      lastName: data.author.last_name || '',
      authorType: data.author.author_type || ''
    }
    this.date = data.date || ''
    this.id = data.id || ''
    this.text = data.text || ''
    this.jobTitle = data.job_title || ''
    this.jobCompanyName = data.job_company_name || ''
    this.showJobCompany = data.show_job_company || false
    this.showJobTitle = data.show_job_title || false
    this.showLastName = data.show_last_name || false
    this.is_verified = data.is_verified || false
    this.showAuthorType = Boolean(data.show_author_type)
  }

  get fullName() {
    return `${this.author.firstName}${
      this.author.lastName ? ` ${this.author.lastName}` : ''
    }`
  }
}
