import React from 'react'
import classnames from 'classnames'

type NumberCounterProps = {
  data?: string
  length?: number
  minLength?: number
  applyIndicator?: boolean
}

export const NumberCounter = ({
  data = '',
  length = 1000,
  applyIndicator = false,
  minLength
}: NumberCounterProps): React.ReactElement => {
  const hasReachedMaxLimit = data.length === length
  const shouldShowIndicatorStyle = minLength && applyIndicator

  return (
    <div
      className={classnames('field-counter field-counter-flex', {
        'field-counter-error': hasReachedMaxLimit
      })}
      style={hasReachedMaxLimit ? { justifyContent: 'space-between' } : {}}
    >
      {hasReachedMaxLimit && (
        <p className="space-null">
          Ensure this field has no more than {length} characters.
        </p>
      )}
      <p
        className="space-null"
        style={
          shouldShowIndicatorStyle
            ? {
                color: data.length < minLength ? '#f44336' : '#03bf61'
              }
            : {}
        }
      >
        {data.length}/{length}
      </p>
    </div>
  )
}
