import RootStore from 'src/stores/RootStore'
import { utils } from 'src/utils/helpers'
import { RefariDTOTypes } from '@refari-frontend/types'

export type CandidatesListGetResponse =
  RefariDTOTypes['/dashboard/candidates/']['get']['responses']['200']['content']['application/json']
type CandidateListSchema = CandidatesListGetResponse['results'][0]

export type ICandidateListModel = {
  id: CandidateListSchema['id']
  externalId: CandidateListSchema['external_id']
  firstName: CandidateListSchema['first_name']
  lastName: CandidateListSchema['last_name']
  email: CandidateListSchema['email']
  jobTitle: CandidateListSchema['job_title']
  phone: CandidateListSchema['phone']
  companyName: CandidateListSchema['company_name']
  linkedinProfileUrl: CandidateListSchema['linkedin_profile_url']
  createdAt: CandidateListSchema['created']
  modifiedAt: CandidateListSchema['modified']
  hasLiveAd: CandidateListSchema['has_live_ad']
  hasAd: CandidateListSchema['has_ad']
  getCandidateName: string
  dateTimeCreated: string
  dateTimeModified: string
  is_referrer_owner: CandidateListSchema['is_referrer_owner']
  has_job_alert_accepted: CandidateListSchema['has_job_alert_accepted']
  has_job_alert_suggestion: CandidateListSchema['has_job_alert_suggestion']
  is_applied: CandidateListSchema['is_applied']
  is_registered: CandidateListSchema['is_registered']
  is_imported: CandidateListSchema['is_imported']
  has_job_alert: CandidateListSchema['has_job_alert']
  referral_count: CandidateListSchema['referral_count']
  is_active: CandidateListSchema['is_active']
}

class CandidateListModel implements ICandidateListModel {
  id: ICandidateListModel['id']
  externalId: ICandidateListModel['externalId']
  firstName: ICandidateListModel['firstName']
  lastName: ICandidateListModel['lastName']
  email: ICandidateListModel['email']
  jobTitle: ICandidateListModel['jobTitle']
  phone: ICandidateListModel['phone']
  companyName: ICandidateListModel['companyName']
  linkedinProfileUrl: ICandidateListModel['linkedinProfileUrl']
  createdAt: ICandidateListModel['createdAt']
  modifiedAt: ICandidateListModel['modifiedAt']
  hasLiveAd: ICandidateListModel['hasLiveAd']
  hasAd: ICandidateListModel['hasAd']
  is_referrer_owner: ICandidateListModel['is_referrer_owner']
  has_job_alert_accepted: ICandidateListModel['has_job_alert_accepted']
  has_job_alert_suggestion: ICandidateListModel['has_job_alert_suggestion']
  is_applied: ICandidateListModel['is_applied']
  is_registered: ICandidateListModel['is_registered']
  is_imported: ICandidateListModel['is_imported']
  has_job_alert: ICandidateListModel['has_job_alert']
  referral_count: ICandidateListModel['referral_count']
  is_active: ICandidateListModel['is_active']

  constructor(data: CandidateListSchema) {
    this.id = data.id
    this.externalId = data.external_id
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.email = data.email
    this.jobTitle = data.job_title
    this.phone = data.phone
    this.companyName = data.company_name
    this.linkedinProfileUrl = data.linkedin_profile_url
    this.createdAt = data.created
    this.modifiedAt = data.modified
    this.hasLiveAd = data.has_live_ad
    this.hasAd = data.has_ad
    this.is_referrer_owner = data.is_referrer_owner
    this.has_job_alert_accepted = data.has_job_alert_accepted
    this.has_job_alert_suggestion = data.has_job_alert_suggestion
    this.is_applied = data.is_applied
    this.is_registered = data.is_registered
    this.is_imported = data.is_imported
    this.has_job_alert = data.has_job_alert
    this.referral_count = data.referral_count
    this.is_active = data.is_active
  }

  get getCandidateName(): ICandidateListModel['getCandidateName'] {
    return `${this.firstName} ${this.lastName}`
  }

  get dateTimeCreated(): ICandidateListModel['dateTimeCreated'] {
    return `${utils.dateFormatter(
      this.createdAt,
      '/',
      false,
      RootStore.agency.currency
    )}, ${utils.timeFormater(this.createdAt, ':', RootStore.agency.currency)}`
  }

  get dateTimeModified(): ICandidateListModel['dateTimeModified'] {
    return `${utils.dateFormatter(
      this.modifiedAt,
      '/',
      false,
      RootStore.agency.currency
    )}, ${utils.timeFormater(this.modifiedAt, ':', RootStore.agency.currency)}`
  }

  get isDisabled(): boolean {
    return !this.is_active
  }
}

export default CandidateListModel
