import type { AddressStatus } from 'src/stores/JobAdStore'

type Args = {
  isATSfield: boolean
  workAddressStatus: AddressStatus
  isATSFieldEdited: boolean
  locationFieldId: string
  form: Record<string, any>
}

const getCompletitionLevel = (
  FieldValues: Array<Record<string, any> | undefined | null>
): AddressStatus => {
  const inputValues = [...FieldValues]
  const filteredValues = inputValues.filter((value) => value)

  if (filteredValues.length === inputValues.length) {
    return 'complete'
  } else if (filteredValues.length > 0) {
    return 'partialComplete'
  } else {
    return 'none'
  }
}

const getDetailLocationCompletitionLevel = ({
  isATSfield,
  locationFieldId,
  form,
  workAddressStatus,
  isATSFieldEdited
}: Args): AddressStatus => {
  const countryField =
    form.fields[`detailedLocationSelectionCountry${locationFieldId}`]
  const stateField =
    form.fields[`detailedLocationSelectionState${locationFieldId}`]
  const cityField =
    form.fields[`detailedLocationSelectionCity${locationFieldId}`]
  const postalCodeField =
    form.fields[`detailedLocationSelectionPostalCode${locationFieldId}`]

  const valueList = [
    countryField.value,
    stateField.value,
    cityField.value,
    postalCodeField.value
  ]

  if (isATSfield) {
    if (!isATSFieldEdited) {
      return workAddressStatus
    } else {
      return getCompletitionLevel(valueList)
    }
  }

  return getCompletitionLevel(valueList)
}

export default getDetailLocationCompletitionLevel
