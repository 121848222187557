import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Button } from '@material-ui/core'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import Input from '../formComponents/Input'
import { validationErrors as errors } from '../../../utils/helpers'
import NonFieldError from '../formComponents/NonFieldError'

@observer
class ForgotPassword extends React.Component {
  static propTypes = {
    authStore: PropTypes.shape({
      forgotPasswordCreate: PropTypes.func.isRequired
    })
  }

  constructor() {
    super()
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      email: new Field({
        name: 'email',
        value: '',
        validators: [errors.required(), errors.isEmail()]
      })
    })
  }

  submit = (e) => {
    e.preventDefault()
    this.form.submit(this.props.authStore.forgotPasswordCreate)
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <NonFieldError error={this.form.fields.nonFieldErrors.value} />
        <Input fieldData={this.form.fields.email} label="Email" />

        <p style={{ maginBottom: 0, marginTop: '5px' }}>
          If there is an account with the provided email address, we will send
          you a password reset email.
        </p>

        <Button
          variant="contained"
          type="submit"
          className="submit-btn"
          fullWidth
          color="primary"
          disabled={!this.form.isValid || this.form.isPending}
        >
          Send reset password email
        </Button>
      </form>
    )
  }
}

export default ForgotPassword
