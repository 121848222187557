import { computed } from 'mobx'
import { RefariDTOTypes } from '@refari-frontend/types'
import moment from 'moment'

type TalentAlertListItemFromApi =
  RefariDTOTypes['/dashboard/hiring-manager/talent-alerts/subscriptions/']['get']['responses']['200']['content']['application/json']['results'][0]

export type ITalentAlertSubscriptionModel = TalentAlertListItemFromApi & {
  generatedTitle: string
  agencyName: string
}

class TalentAlertSubscriptionModel implements ITalentAlertSubscriptionModel {
  id: ITalentAlertSubscriptionModel['id']
  agency: ITalentAlertSubscriptionModel['agency']
  email: ITalentAlertSubscriptionModel['email']
  is_suspended: ITalentAlertSubscriptionModel['is_suspended']
  interval: ITalentAlertSubscriptionModel['interval']
  keywords: ITalentAlertSubscriptionModel['keywords']
  skills: ITalentAlertSubscriptionModel['skills']
  locations: ITalentAlertSubscriptionModel['locations']
  worktypes: ITalentAlertSubscriptionModel['worktypes']
  agencyName: ITalentAlertSubscriptionModel['agencyName']
  created: ITalentAlertSubscriptionModel['created']

  constructor(data: TalentAlertListItemFromApi) {
    this.id = data.id
    this.agency = data.agency
    this.email = data.email
    this.is_suspended = data.is_suspended
    this.interval = data.interval
    this.keywords = data.keywords
    this.skills = data.skills
    this.locations = data.locations
    this.worktypes = data.worktypes
    this.agencyName = data.agency.name || '-'
    this.created = data.created
  }

  @computed
  get generatedTitle(): string {
    const joiner = (data: Array<Record<string, any>>) =>
      data.map((item) => item.name).join(', ')

    const skillsString = joiner(this.skills ?? [])
    const locationsString = joiner(this.locations ?? [])
    const workTypesString = joiner(this.worktypes ?? [])
    const keywordsString = Array.isArray(this.keywords)
      ? joiner(this.keywords)
      : this.keywords

    return skillsString || locationsString || workTypesString || keywordsString
      ? `${skillsString && skillsString + ','} ${
          locationsString && locationsString + ','
        } ${workTypesString && workTypesString + ','} ${
          keywordsString && keywordsString + ','
        }`
      : 'All talents'
  }

  @computed
  get createdAt(): string {
    if (!this.created) return '-'
    return moment(this.created).format('DD/MM/YYYY [at] HH:mm')
  }
}

export default TalentAlertSubscriptionModel
