import { action, observable } from 'mobx'
import axios from 'axios'
import API from '../utils/API'
import Logger from '../utils/Logger'

export class DetailsStore {
  @observable activeId
  @observable.ref Model
  @observable.ref params

  @action
  initDetails = ({ id, model, params }) => {
    this.activeId = id
    this.Model = model
    this.params = params
  }

  @observable.ref data = {}

  @action.bound
  async fetchDetailData(url, sourceKey = null) {
    try {
      if (sourceKey) {
        axios.defaults.headers['source-key'] = sourceKey
      }
      const response = await API.getData(
        this.activeId ? url(this.activeId) : url,
        this.params
      )
      delete axios.defaults.headers['source-key']
      this.setData('data', new this.Model(response.data))
    } catch (error) {
      Logger.error(error)
    }
  }

  clearData() {
    this.data = {}
  }

  @action.bound
  setData(key, data) {
    this[key] = data
  }
}

export default DetailsStore
