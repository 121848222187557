import { v4 as uuidv4 } from 'uuid'
import { RefariDTOTypes } from '@refari-frontend/types'

type LocationApiSpec =
  RefariDTOTypes['/dashboard/job-ads/{id}/']['get']['responses']['200']['content']['application/json']['locations']

type LocationSchema = LocationApiSpec[0]

type DataFromAPI = LocationSchema

type ParentAndChildIdsWithFieldId = {
  fieldId: string
  parentId: number
  childId: number | null
  detailedLocation: LocationSchema['detailed_location']
}

type LocationsWithFieldId = {
  fieldId: string
  location: number
  subLocation: number | null
  detailedLocation: LocationSchema['detailed_location']
}

const getParentAndChildIdsWithFieldId = (
  dataFromAPI: DataFromAPI
): ParentAndChildIdsWithFieldId => {
  const fieldId = uuidv4()

  if (dataFromAPI.parent === undefined || dataFromAPI.parent === null) {
    return {
      fieldId,
      parentId: dataFromAPI.id,
      childId: null,
      detailedLocation: dataFromAPI.detailed_location
    }
  }

  return {
    fieldId,
    parentId: dataFromAPI.parent,
    childId: dataFromAPI.id,
    detailedLocation: dataFromAPI.detailed_location
  }
}

const getLocationsWithFieldId = (
  locations: Array<LocationSchema>
): Array<LocationsWithFieldId> => {
  if (!locations) {
    return []
  }

  return locations.map((location) => {
    const {
      parentId: parentLocationId,
      childId: childLocationId,
      fieldId,
      detailedLocation
    } = getParentAndChildIdsWithFieldId(location)

    return {
      fieldId,
      detailedLocation,
      location: parentLocationId,
      subLocation: childLocationId
    }
  })
}

export default getLocationsWithFieldId
