import { observable, computed, action } from 'mobx'
import SocialCompany from './SocialCompany'
import { utils } from '../utils/helpers'
import RootStore from '../stores/RootStore'

export default class SocialAccount {
  @observable id
  @observable name
  @observable avatar
  @observable network
  @observable.ref companies = []
  @observable expiredAt
  @observable isInvalid

  constructor(data, network) {
    /**
     * @example
     * @personal
     * {
     *  'avatar': None, # or image url
     *  'expired_at': '2022-04-10T00:00:00Z', # or None
     *  'id': 16,
     *  'is_invalid': True,
     *  'name': 'Adam Smith',
     *  'social_net': 'twitter'
     * }
     *
     * @company
     * {
     *  'avatar': None,
     *  'can_manage': False,
     *  'companies': [{'id': 9, 'is_active': True, 'name': 'Company Name'}],
     *  'expired_at': '2022-03-18T11:29:08.366337Z',
     *  'id': 29,
     *  'is_invalid': False,
     *  'name': '',
     *  'social_net': 'twitter'
     *  'user' : {
     *    "id": 57,
     *    "first_name": "Ellis second",
     *    "last_name": "Sedrick"
     *   }
     * }
     */
    if (!data) data = {}
    this.id = data.id
    this.name = data.name || '-'
    this.avatar = data.avatar
    this.companies =
      Array.isArray(data.companies) &&
      data.companies.map((item) => new SocialCompany(item))
    this.network = data.social_net
    this.expiredAt = data.expired_at
    this.isInvalid = data.is_invalid
    this.isBlocked = data.is_blocked
    this.canManage = data.can_manage
    this.user = data.user
      ? {
          id: data.user?.id,
          firstName: data.user?.first_name,
          lastName: data.user?.last_name
        }
      : null
  }

  @computed
  get expiresAt() {
    return `${utils.dateFormatter(
      this.expiredAt,
      '/',
      '',
      RootStore.agency.currency
    )}`
  }

  @action
  toggleCompany = (id) => {
    this.companies.map((company) => {
      if (company.id === id) company.toggleCompany()
    })
  }

  @computed
  get remainingTime() {
    return utils.getTimeRemaining(this.expiredAt).days
  }
}
