import React, { Component } from 'react'

import FieldsGroup from 'src/models/form/FieldsGroup'
import Field from 'src/models/form/Field'
import { validationErrors as errors } from 'src/utils/helpers'
import EmailTemplateForm from '../EmailTemplateForm'

class EmailTemplatePanelContnet extends Component {
  form: Record<string, any>

  constructor(props: Record<string, any>) {
    super(props)

    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      message: new Field({
        name: 'message',
        value: ''
      }),
      template: new Field({
        name: 'template',
        value: ''
      })
    })
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          maxWidth: '625px',
          margin: 'auto'
        }}
      >
        {/* @ts-ignore store props are injected */}
        <EmailTemplateForm view="tabPanel" form={this.form} />
      </div>
    )
  }
}

export default EmailTemplatePanelContnet
