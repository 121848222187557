/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TextField } from '@material-ui/core'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import Field from '../../../models/form/Field'
import { NumberCounter } from '../forms/NumberCounter'
import greenTick from '../../../assets/images/green_tick.svg'
import redCross from '../../../assets/images/red_cross.svg'
import { isPasswordValidated } from '../../../utils/helpers'

@observer
class Input extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    fieldData: PropTypes.instanceOf(Field).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    multiline: PropTypes.bool,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    underlineStyle: PropTypes.bool,
    rows: PropTypes.number,
    showTicks: PropTypes.bool,
    isPasswordField: PropTypes.bool,
    floatingLabelFixed: PropTypes.string,
    maxLength: PropTypes.number,
    updateState: PropTypes.func,
    counter: PropTypes.bool,
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
    valueFormatter: PropTypes.func,
    fullWidth: PropTypes.bool,
    disableFullWidthMargin: PropTypes.bool,
    counterIndicator: PropTypes.bool,
    errorMessageFromParent: PropTypes.string,
    hideLabel: PropTypes.bool,
    disableInputLabelAnimation: PropTypes.bool,
    innerInputStyle: PropTypes.object
  }

  state = {
    showCustomTicks: !this.props.isPasswordField
      ? !!(
          this.props.fieldData &&
          this.props.fieldData.value &&
          this.props.showTicks
        )
      : !!(
          this.props.fieldData &&
          this.props.fieldData.value &&
          this.props.showTicks &&
          isPasswordValidated(this.props.fieldData.value)
        )
  }

  UNSAFE_componentWillReceiveProps() {
    if (
      this.props.fieldData &&
      this.props.fieldData.value &&
      this.props.showTicks &&
      !this.props.isPasswordField
    ) {
      this.setState({ showCustomTicks: true })
    } else if (
      this.props.fieldData &&
      this.props.fieldData.value &&
      this.props.showTicks &&
      this.props.isPasswordField &&
      isPasswordValidated(this.props.fieldData.value)
    ) {
      this.setState({ showCustomTicks: true })
    } else {
      this.setState({ showCustomTicks: false })
    }
  }

  static defaultProps = {
    type: 'text',
    className: '',
    floatingLabelFixed: 'false',
    showTicks: false,
    isPasswordField: false,
    maxLength: 7000,
    underlineStyle: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateState: () => {},
    counter: false,
    endAdornment: null,
    startAdornment: null,
    valueFormatter: undefined,
    fullWidth: true,
    disableFullWidthMargin: false,
    counterIndicator: false,
    errorMessageFromParent: '',
    hideLabel: false,
    disableInputLabelAnimation: false,
    innerInputStyle: {}
  }

  onChange = (e) => {
    e.preventDefault()
    this.props.fieldData.update(e.target.value ? e.target.value : '')
    this.props.cb && this.props.cb(e.target.value ? e.target.value : '')
    this.props.updateState(e)
  }

  onBlur = () => {
    if (this.props.showTicks) {
      this.setState({ showCustomTicks: true })
    }
    this.props.fieldData.blur()

    const { serverErrors, clientErrors, isBlurred, isDirty, value } =
      this.props.fieldData
    let errors = []
    if (this.props.showTicks) {
      errors = serverErrors.length ? serverErrors : isBlurred && clientErrors
    } else {
      errors = serverErrors.length
        ? serverErrors
        : isDirty && isBlurred && clientErrors
    }
    if (!errors || (errors && errors.length < 1)) {
      this.props.onBlur && this.props.onBlur(value)
    }
  }

  onfocus = () => {
    if (this.props.showTicks) {
      this.setState({ showCustomTicks: false })
    }
    this.props.fieldData.focus()
    this.props.onFocus && this.props.onFocus()
  }

  render() {
    const {
      id,
      fieldData,
      label,
      hideLabel,
      variant,
      className,
      type,
      multiline,
      required,
      disabled,
      placeholder,
      underlineStyle,
      inputStyle,
      rows,
      maxLength,
      style,
      floatingLabelFixed,
      counter,
      autocomplete,
      showTicks,
      endAdornment,
      startAdornment,
      valueFormatter,
      fullWidth,
      disableFullWidthMargin,
      counterIndicator,
      errorMessageFromParent,
      disableInputLabelAnimation,
      innerInputStyle
    } = this.props
    const { serverErrors, clientErrors, isBlurred, isDirty, value } = fieldData
    let errors = []

    if (showTicks) {
      errors = serverErrors.length ? serverErrors : isBlurred && clientErrors
    } else {
      errors = serverErrors.length
        ? serverErrors
        : isDirty && isBlurred && clientErrors
    }

    const shouldDisplayErrorMessageFromParent =
      errorMessageFromParent && fieldData?.currentValue === ''

    const errorText = shouldDisplayErrorMessageFromParent
      ? errorMessageFromParent
      : errors[0]

    const shouldApplyValueFormatter =
      !isDirty &&
      typeof valueFormatter === 'function' &&
      typeof value !== 'undefined' &&
      value.length > 0

    return (
      <div
        className={classNames({
          'refari-input-wrap': true,
          [className]: true,
          'refari-input-wrap-tick':
            this.state.showCustomTicks && ((!required && value) || required),
          'refari-input--nofullWidthMargin': disableFullWidthMargin,
          'refari-input--disableLabelTransition': disableInputLabelAnimation
        })}
      >
        <TextField
          id={id || hideLabel ? undefined : `${label}`}
          autoComplete={autocomplete}
          fullWidth={fullWidth}
          type={type}
          inputProps={{
            maxLength,
            style: {
              ...innerInputStyle
            }
          }}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: underlineStyle,
            shrink: floatingLabelFixed === 'true',
            endAdornment,
            startAdornment
          }}
          error={
            Boolean(errorMessageFromParent) || (errors && errors.length > 0)
          }
          helperText={errorText}
          label={hideLabel ? undefined : label}
          variant={variant || 'standard'}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onfocus}
          value={shouldApplyValueFormatter ? valueFormatter(value) : value}
          required={required}
          multiline={multiline}
          disabled={disabled}
          maxRows={4}
          rows={rows}
          style={{ ...style, ...inputStyle }}
        />
        {counter && (
          <NumberCounter
            data={value}
            length={maxLength}
            applyIndicator={counterIndicator}
          />
        )}
        {this.state.showCustomTicks && (
          <div
            className={
              errors && errors.length > 0
                ? 'right-input-section-block right-input-section-block-error'
                : 'right-input-section-block'
            }
          >
            <span className="right-input-icon">
              <img
                src={errors && errors.length > 0 ? redCross : greenTick}
                alt="tick"
              />
            </span>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Input)
