import { action, observable } from 'mobx'

type JobsStoreParams = {
  cb?: () => Promise<void>
}

type RewardFeeType = 'external' | 'internal'

type IJobsStoreFields = {
  isOpenModal: boolean
  isOpenEditPromptModal: boolean
  isOpenSalaryRangeEditModal: boolean
  isOpenDeleteConfirmationModal: boolean
  isOpenRewardFeeEditModal: boolean
  rewardFeeType: RewardFeeType
  params: JobsStoreParams | null
}

export type IJobsStore = IJobsStoreFields & {
  openModal: (params?: JobsStoreParams) => void
  openEditPromptModal: () => void
  openSalaryRangeEditModal: () => void
  openDeleteConfirmationModal: () => void
  openRewardFeeEditModal: () => void
  closeModal: () => void
  closeEditPromptModal: () => void
  closeSalaryRangeEditModal: () => void
  closeDeleteConfirmationModal: () => void
  closeRewardFeeEditModal: () => void
  updateRewardFeeType: (feeType: RewardFeeType) => void
  // TODO:: derive correct types for field values
  setData: (key: keyof IJobsStoreFields, value: any) => void
}

export class JobsStore implements IJobsStore {
  @observable isOpenModal: IJobsStore['isOpenModal'] = false
  @observable isOpenEditPromptModal: IJobsStore['isOpenEditPromptModal'] = false
  @observable
  isOpenSalaryRangeEditModal: IJobsStore['isOpenSalaryRangeEditModal'] = false
  @observable
  isOpenDeleteConfirmationModal: IJobsStore['isOpenDeleteConfirmationModal'] =
    false
  @observable
  isOpenRewardFeeEditModal: IJobsStore['isOpenRewardFeeEditModal'] = false
  @observable rewardFeeType: IJobsStore['rewardFeeType'] = 'external'
  @observable params: IJobsStore['params'] = null

  @action
  openModal: IJobsStore['openModal'] = (params) => {
    this.params = params || null
    this.isOpenModal = true
  }

  @action
  closeModal: IJobsStore['closeModal'] = () => {
    this.params = null
    this.isOpenModal = false
  }

  @action
  openSalaryRangeEditModal: IJobsStore['openSalaryRangeEditModal'] = () => {
    this.isOpenSalaryRangeEditModal = true
  }

  @action
  closeSalaryRangeEditModal: IJobsStore['closeSalaryRangeEditModal'] = () => {
    this.isOpenSalaryRangeEditModal = false
  }

  @action
  openEditPromptModal: IJobsStore['openEditPromptModal'] = () => {
    this.isOpenEditPromptModal = true
  }

  @action
  closeEditPromptModal: IJobsStore['closeEditPromptModal'] = () => {
    this.isOpenEditPromptModal = false
  }

  @action
  openDeleteConfirmationModal: IJobsStore['openDeleteConfirmationModal'] =
    () => {
      this.isOpenDeleteConfirmationModal = true
    }

  @action
  closeDeleteConfirmationModal: IJobsStore['closeDeleteConfirmationModal'] =
    () => {
      this.isOpenDeleteConfirmationModal = false
    }

  @action
  openRewardFeeEditModal: IJobsStore['openRewardFeeEditModal'] = () => {
    this.isOpenRewardFeeEditModal = true
  }

  @action
  closeRewardFeeEditModal: IJobsStore['closeRewardFeeEditModal'] = () => {
    this.isOpenRewardFeeEditModal = false
  }

  @action
  updateRewardFeeType: IJobsStore['updateRewardFeeType'] = (feeType) => {
    this.rewardFeeType = feeType
  }

  @action
  setData: IJobsStore['setData'] = (key, value) => {
    // @ts-ignore TODO: fix key
    this[key] = value
  }
}

export default new JobsStore()
