import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Plan = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M292.911,224.456l-81.187-46.874c-4.376-2.527-9.814-2.527-14.192,0l-81.189,46.874    c-4.509,2.606-7.096,7.366-7.096,12.293v93.747c0,5.054,2.72,9.764,7.097,12.292l81.187,46.873c4.591,2.65,10.043,2.398,14.193,0    l81.186-46.873c4.378-2.527,7.098-7.236,7.098-12.293v-93.747C300.008,231.866,297.427,227.063,292.911,224.456z M197.127,372.106    l-72.879-42.078v-84.152l72.879,42.077V372.106z M204.627,274.962l-72.879-42.077l72.879-42.077l72.878,42.077L204.627,274.962z     M285.006,330.028l-72.878,42.078v-84.153l72.878-42.077V330.028z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M504.499,285.72c4.143,0,7.5-3.358,7.5-7.5V119.518c0.001-8.053-6.551-14.606-14.605-14.606h-30.402V20.16    c0-6.429-5.23-11.66-11.66-11.66h-61.701c-0.003,0-0.006,0-0.01,0h-24.766h-0.002h-1.181c-6.429,0-11.66,5.23-11.66,11.66v1.182    v83.571H222.128c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h133.885v331.395H92.255v-63.56    c0-7.551-6.143-13.694-13.694-13.694h-63.56V120.272c0-0.198,0.161-0.358,0.358-0.358h171.767c4.143,0,7.5-3.358,7.5-7.5    c0-4.142-3.357-7.5-7.5-7.5H15.359C6.89,104.912,0,111.802,0,120.272v261.284c0,2.049,0.857,3.964,2.196,5.303l77.254,77.255    c1.429,1.427,3.372,2.197,5.304,2.197h271.259v24.346c0,8.697,5.574,12.842,12.842,12.842c8.297,0,77.346,0,86.477,0    c6.429,0,11.66-5.23,11.66-11.66v-25.528h31.733c7.319,0,13.274-5.954,13.274-13.273V313.221c0-4.142-3.357-7.5-7.5-7.5    s-7.5,3.358-7.5,7.5v138.087h-30.007V119.913h30.007v158.307C496.998,282.362,500.355,285.72,504.499,285.72z M77.254,440.701    c-7.486-7.486-44.16-44.16-51.646-51.646h51.646V440.701z M386.119,488.496h-15.105c0-2.363,0-457.875,0-464.996h15.106    L386.119,488.496z M451.991,43.33h-15.275c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274    c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v8.304v22.474h-15.274c-4.143,0-7.5,3.358-7.5,7.5    c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v30.778h-15.274    c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274c-4.143,0-7.5,3.358-7.5,7.5    c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274    c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v30.778h-15.274c-4.143,0-7.5,3.358-7.5,7.5    c0,4.142,3.357,7.5,7.5,7.5h15.274v30.777h-15.274c-4.143,0-7.5,3.358-7.5,7.5c0,4.142,3.357,7.5,7.5,7.5h15.274v18.161h-50.869    L401.122,23.5h50.869V43.33z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Plan
Plan.propTypes = propTypes
Plan.defaultProps = defaultProps
