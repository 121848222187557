/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, type FormEvent } from 'react'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core'
import CloseModalButton from 'src/components/shared/modals/CloseModalButton'
import FieldsGroup from 'src/models/form/FieldsGroup'
import Field from 'src/models/form/Field'
import RaisedButton from 'src/components/shared/common/RaisedButton'
import Input from 'src/components/shared/formComponents/Input'
import { validationErrors as errors } from 'src/utils/helpers'
import Modal from 'src/components/shared/modals/Modal'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'

type MessageTemplateModalProps = {
  talentAlertStore: ITalentAlertStore
  theme: MUIThemeFuncReturnType
  rootStore: Record<string, any>
}

type MessageTemplateModalState = {
  duplicateNameError: boolean
}

@inject('talentAlertStore', 'rootStore')
@observer
class MessageTemplateModal extends Component<
  MessageTemplateModalProps,
  MessageTemplateModalState
> {
  form: any

  constructor(props: MessageTemplateModalProps) {
    super(props)

    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      suggestionTemplateName: new Field({
        name: 'name',
        value: '',
        validators: [errors.required()]
      }),
      suggestionTemplateMessage: new Field({
        name: 'message',
        value: props.talentAlertStore.suggestionTemplateMessage,
        validators: [errors.required()]
      }),

      messageTemplateType: new Field({
        name: 'messageTemplateType',
        value: 'personal'
      })
    })

    this.state = {
      duplicateNameError: false
    }
  }

  componentDidUpdate() {
    const { talentAlertStore } = this.props
    if (
      talentAlertStore.isOpenSuggestionMessageModal &&
      talentAlertStore.suggestionTemplateMessage
    ) {
      this.form.fields.suggestionTemplateMessage.update(
        talentAlertStore.suggestionTemplateMessage
      )
    }
  }

  closeModal = () => {
    const { talentAlertStore } = this.props

    talentAlertStore.closeSuggestionMessageModal()
    talentAlertStore.setSuggestionTemplateMessage('')
  }

  submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const { talentAlertStore } = this.props

    if (
      talentAlertStore.templateNames.includes(
        this.form.fields.suggestionTemplateName.value
      )
    ) {
      this.setState({ duplicateNameError: true })
      return
    }

    talentAlertStore.submitSuggestionMessage(this.form)
  }

  render() {
    const { rootStore, talentAlertStore, theme } = this.props
    const { duplicateNameError } = this.state

    if (!talentAlertStore.suggestionTemplateMessage) {
      return null
    }

    const style = {
      // @ts-ignore TODO: fix this
      title: { color: theme.palette.popupPrimary },
      // @ts-ignore TODO: fix this
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    const canCreateCompanyTemplate =
      rootStore.user?.role === 'admin' || rootStore.user?.role === 'manager'

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={talentAlertStore.isOpenSuggestionMessageModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'modal refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            // @ts-ignore TODO: fix this
            backgroundColor: theme.widgetBackgroundColor,
            // @ts-ignore TODO: fix this
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Save Message Template
          </h3>

          <div
            style={{
              width: '100%',
              maxWidth: 400,
              margin: 'auto'
            }}
          >
            <form className="refari-form-section" onSubmit={this.submitForm}>
              {canCreateCompanyTemplate && (
                <div>
                  <h5>Save as</h5>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="messageTemplateType"
                      name="messageTemplateType"
                      value={this.form.fields.messageTemplateType.value}
                      onChange={(e) => {
                        this.form.fields.messageTemplateType.update(
                          e.target.value
                        )
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value="personal"
                        control={<Radio color="primary" />}
                        label="Personal Template"
                        style={{ display: 'inline-flex', marginBottom: 0 }}
                      />
                      <FormControlLabel
                        value="company"
                        control={<Radio color="primary" />}
                        label="Company Template"
                        style={{ display: 'inline-flex', marginBottom: 0 }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              <Input
                fieldData={this.form.fields.suggestionTemplateName}
                label="Template name"
              />

              {duplicateNameError && (
                <div className="mb-10 mt-10">
                  <p style={{ color: '#f44336', marginBottom: 0 }}>
                    {' '}
                    A template with this name is already in use. Please try a
                    different name.
                  </p>
                </div>
              )}
              <RaisedButton
                type="submit"
                fullWidth
                color="primary"
                className={`refari-submit-btn refari-button-raised ${
                  !this.form.isValid || this.form.isPending
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                style={{ width: 'auto' }}
                disabled={!this.form.isValid || this.form.isPending}
              >
                Save
              </RaisedButton>
            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(MessageTemplateModal)
