import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Typography } from '@material-ui/core'
import { formatSocialNetworkName } from 'src/utils/StringUtil'
import SmileyGif from 'src/components/shared/icons/SmileyGif'
import CopyGif from 'src/components/shared/icons/CopyGif'
import LoadingAnimation from 'src/components/shared/icons/LoadingAnimation'

type ReviewCopiedAndRedirectionModalProps = {
  open: boolean
  handleClose: () => void
  handleRedirect: () => void
  step: number
  socialNet?: string
}

export function ReviewCopiedAndRedirectionModal({
  open,
  handleClose,
  handleRedirect,
  step,
  socialNet = 'google'
}: ReviewCopiedAndRedirectionModalProps) {
  const [currentStep, setCurrentStep] = useState(step || 0)

  const manageStepsActions = () => {
    if (currentStep === 0) {
      setCurrentStep(1)
    } else if (currentStep === 1) {
      handleRedirect()
      handleClose()
    }
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      manageStepsActions()
    }, 5000)
    return () => clearTimeout(timerId)
  }, [currentStep])
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent>
        {currentStep === 0 ? (
          <div style={{ padding: '1rem 4rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SmileyGif style={{ height: '2rem' }} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '2rem auto'
              }}
            >
              <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                {`We would absolutely love it if you could also leave this Testimonial on our ${formatSocialNetworkName(
                  socialNet
                )} Reviews Page`}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
                marginTop: '3rem'
              }}
            >
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div style={{ padding: '1rem 4rem', paddingTop: '0rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CopyGif style={{ height: '4rem', width: '4rem' }} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '2rem auto'
              }}
            >
              <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                {`This Testimonial has been copied to your clipboard. We are now going to take you to ${formatSocialNetworkName(
                  socialNet
                )} in the hope you may leave this as a ${formatSocialNetworkName(
                  socialNet
                )} review.`}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '1rem',
                marginTop: '3rem'
              }}
            >
              <LoadingAnimation />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
