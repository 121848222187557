/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react'
import { Button, Avatar, TextField, Paper, Typography } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FileCopyOutlined } from '@material-ui/icons'
import { toast } from 'react-toastify'
import { inject } from 'mobx-react'
import star from '../../../assets/images/star.png'
import URLs from '../../../router/routesUrls'
import GoogleIcon from '../../shared/icons/GoogleIcon'
import LinkedInIcon from '../../shared/icons/LinkedInIcon'
import FacebookIcon from '../../shared/icons/FacebookIcon'
import hexToRgba from 'hex-to-rgba'
import { ReviewCopiedAndRedirectionModal } from './ReviewCopiedAndRedirectionModal'

@inject('testimonialsStore')
class TestimonialShare extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      testimonialsStore: { testimonialsInfo }
    } = props
    const listReviewSocialNet = Object.keys(testimonialsInfo.review_urls)
    const activeReviewUrls = listReviewSocialNet.filter(
      (socialNet) => testimonialsInfo.review_urls[socialNet]
    )
    this.isSingleSocialReview = activeReviewUrls.length === 1
    const primaryReviewSocialNet = testimonialsInfo.review_primary_social_net
    const copiedSocial =
      Boolean(primaryReviewSocialNet) &&
      testimonialsInfo.review_urls[primaryReviewSocialNet]
        ? primaryReviewSocialNet
        : this.isSingleSocialReview
        ? activeReviewUrls[0]
        : ''
    this.state = {
      text: '',
      starsLength: [1, 2, 3, 4, 5],
      isCopied: false,
      error: '',
      isReviewRedirectionDialogOpen:
        this.isSingleSocialReview ||
        (Boolean(primaryReviewSocialNet) &&
          Boolean(testimonialsInfo.review_urls[primaryReviewSocialNet])),
      copiedSocial: copiedSocial
    }
    // this.isSingleSocialReview &&
    navigator.clipboard.writeText(
      `${testimonialsInfo.text} [powered by TopRec].`
    )
  }

  submit = () => {
    const { text } = this.state
    const { testimonialsStore } = this.props
    const data = {
      text,
      id: testimonialsStore.testimonialsInfo.id
    }
    testimonialsStore.updateTestimonials(data, this.nextStep)
  }

  handleChange = (e) => {
    this.setState({
      text: `${e.target.value}  ${
        e.target.value.length ? '[powered by TopRec].' : ''
      } `,
      error: ''
    })

    if (e.target.value.length === 1000) {
      this.setState({ error: 'Max width should be less then 1000 symbols' })
    }
  }

  componentDidMount() {
    const newText = `${this.props.testimonialsStore.testimonialsInfo.text} [powered by TopRec].`
    this.setState({ text: newText })
  }

  nextStep() {
    this.props.history.push(URLs.testimonials.children.testimonialsShare)
  }

  setCopied = () => {
    this.setState({ isCopied: true })
  }

  handleClick =
    (_url, social = '') =>
    () => {
      if (!this.state.isCopied) {
        // toast.success('Copied review to your clipboard', {
        //   onClose: () => this.handleRedirect(url, social)
        // })
        this.setState({
          isReviewRedirectionDialogOpen: true,
          copiedSocial: social
        })
      } else {
        this.setState({
          isReviewRedirectionDialogOpen: true,
          copiedSocial: social
        })
        // this.handleRedirect(url, social)
      }
    }

  handleRedirect = (url, social) => {
    let newUrl = ''

    if (social === 'linkedin') {
      if (url.includes('/review') || url.includes('/review/')) {
        newUrl = url.replace('review', 'detail/recommendation/write')
      } else if (url.includes('/write') || url.includes('/write/')) {
        newUrl = url
      } else {
        newUrl = url.endsWith('/')
          ? `${url}detail/recommendation/write/`
          : `${url}/detail/recommendation/write/`
      }
    } else if (url.includes('/review') || url.includes('/review/')) {
      newUrl = url
    } else {
      newUrl = url.endsWith('/') ? `${url}review/` : `${url}/review/`
    }

    window.open(newUrl, '_blank')
  }

  render() {
    const {
      theme,
      testimonialsStore: { consultantInfo, testimonialsInfo, redirect },
      history
    } = this.props
    const { text, isCopied } = this.state
    if (redirect) {
      history.push(URLs.testimonials.children.testimonialsReviewed)
    }

    if (!consultantInfo || (consultantInfo && !consultantInfo.consultant)) {
      return null
    }

    return (
      <div className="flex-center-all full-width">
        <Paper className="testimonials-feedback testimonials-feedback__add-modal testimonials-feedback__share-modal">
          <p
            className="testimonials-feedback--header-content"
            style={{ color: theme.palette.primary1Color }}
          >
            Thanks for leaving your review!
          </p>
          <p className="testimonials-feedback--content-text testimonials-feedback--content-text-add m-t-1 m-b-2">
            Are you willing to help us with a public review? Review your
            consultant on social networks
          </p>
          <Avatar
            src={
              consultantInfo.consultant.avatar &&
              consultantInfo.consultant.avatar.thumb
            }
            style={{ height: '7rem', width: '7rem' }}
            size={80}
          />
          <p className="testimonials-feedback--user-name">
            {consultantInfo.consultant.first_name}{' '}
            {consultantInfo.consultant.last_name}
          </p>
          <div className="testimonials-feedback__stars full-width">
            {this.state.starsLength.map((el, i) => (
              <img
                key={i}
                src={star}
                className="testimonials-feedback--stars"
                alt=""
              />
            ))}
          </div>
          {/* <div className="testimonials-feedback__share-review full-width m_top_2x m-bot-2x">
            <div className="title">Review your consultant</div>
            <div className="testimonials-feedback--review-input-block">
              <TextField
                placeholder={text}
                onChange={this.handleChange}
                className="testimonials-feedback--review-input"
                defaultValue={testimonialsInfo.text}
                multiline
                rows={2}
                maxRows={3}
                inputProps={{
                  maxLength: 1000
                }}
                error={!!this.state.error}
                helperText={this.state.error}
              />
              <CopyToClipboard
                text={text || `${testimonialsInfo.text} [powered by TopRec].`}
                onCopy={this.setCopied}
              >
                <Button
                  variant="contained"
                  type="button"
                  className="submit-btn testimonials-feedback--review-button"
                  color="primary"
                  style={{ borderRadius: 5, textTransform: 'initial' }}
                  startIcon={<FileCopyOutlined />}
                >
                  {isCopied ? 'Copied!' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </div>
          </div> */}
          {(testimonialsInfo.review_urls.google ||
            testimonialsInfo.review_urls.linkedin ||
            testimonialsInfo.review_urls.facebook) && (
            <div className="full-width testimonials-feedback__add-modal-submit m-b-2">
              {/* <div className="social-title">
                <span>Post review on our social pages</span>
              </div> */}
              {testimonialsInfo.review_urls.google && (
                <CopyToClipboard
                  text={text || `${testimonialsInfo.text} [powered by TopRec].`}
                  onCopy={this.setCopied}
                >
                  <Button
                    variant="contained"
                    type="button"
                    className="testimonials-feedback--social"
                    style={{
                      marginTop: '2rem',
                      borderRadius: 5,
                      textTransform: 'initial',
                      color: '#fff',
                      backgroundColor: '#E93F33',
                      padding: '0.7rem 1rem'
                    }}
                    startIcon={<GoogleIcon fill="#fff" />}
                    onClick={this.handleClick(
                      `${testimonialsInfo.review_urls.google}`,
                      'google'
                    )}
                  >
                    {/* {`Leave ${
                      consultantInfo.agency.name || 'recruiter'
                    } a Google review`} */}
                    Copy your review and paste it as a Google review
                  </Button>
                </CopyToClipboard>
              )}
              {testimonialsInfo.review_urls.facebook && (
                <CopyToClipboard
                  text={text || `${testimonialsInfo.text} [powered by TopRec].`}
                  onCopy={this.setCopied}
                >
                  <Button
                    variant="contained"
                    type="button"
                    className="testimonials-feedback--social"
                    style={{
                      marginTop: '1rem',
                      borderRadius: 5,
                      textTransform: 'initial',
                      color: '#fff',
                      backgroundColor: '#3096F3',
                      padding: '0.7rem 1rem'
                    }}
                    startIcon={<FacebookIcon fill="#fff" />}
                    onClick={this.handleClick(
                      `${testimonialsInfo.review_urls.facebook}`,
                      'facebook'
                    )}
                  >
                    {/* {`Leave ${
                      consultantInfo.agency.name || 'recruiter'
                    } a Facebook review`} */}
                    Copy your review and paste it as a Facebook review
                  </Button>
                </CopyToClipboard>
              )}
              {testimonialsInfo.review_urls.linkedin && (
                <CopyToClipboard
                  text={text || `${testimonialsInfo.text} [powered by TopRec].`}
                  onCopy={this.setCopied}
                >
                  <Button
                    variant="contained"
                    type="button"
                    className="testimonials-feedback--social"
                    style={{
                      marginTop: '1rem',
                      borderRadius: 5,
                      textTransform: 'initial',
                      color: '#fff',
                      backgroundColor: '#2977B5',
                      padding: '0.7rem 1rem'
                    }}
                    startIcon={<LinkedInIcon fill="#fff" />}
                    onClick={this.handleClick(
                      `${testimonialsInfo.review_urls.linkedin}`,
                      'linkedin'
                    )}
                  >
                    {/* {`Leave recruiter ${consultantInfo.consultant.first_name} a Linkedin review`} */}
                    Copy your review and paste it as a LinkedIn review
                  </Button>
                </CopyToClipboard>
              )}
              <div
                style={{
                  backgroundColor: hexToRgba(theme.palette.primary1Color, 0.1),
                  marginTop: '1rem',
                  padding: '1rem 3rem',
                  color: '#757575',
                  wordBreak: 'keep-all'
                }}
                className="testimonials-feedback--social"
              >
                {`"${testimonialsInfo.text}"`}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#757575',
                  marginTop: '2rem',
                  fontSize: '0.7rem'
                }}
              >
                <span>
                  If you&apos;d rather do this manually you can{' '}
                  <span
                    style={{
                      color: theme.palette.primary1Color,
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                    Copy
                  </span>{' '}
                  it here
                </span>
              </div>
            </div>
          )}
          {this.state.isReviewRedirectionDialogOpen && (
            <ReviewCopiedAndRedirectionModal
              open={this.state.isReviewRedirectionDialogOpen}
              handleClose={() => {
                this.setState({ isReviewRedirectionDialogOpen: false })
              }}
              step={this.isSingleSocialReview ? 0 : 1}
              socialNet={this.state.copiedSocial}
              handleRedirect={() =>
                this.handleRedirect(
                  testimonialsInfo.review_urls[this.state.copiedSocial],
                  this.state.copiedSocial
                )
              }
            />
          )}
        </Paper>
      </div>
    )
  }
}

export default withTheme(TestimonialShare)
