/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { action, computed, observable } from 'mobx'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import RaisedButton from '../../../shared/common/RaisedButton'
import Select from '../../../shared/formComponents/Select'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import { validationErrors as errors } from '../../../../utils/helpers'
import FlatButton from '../../../shared/common/FlatButton'
import alertMessages from '../../../../constants/alertMessages'
import MailPreviewModal from '../../../shared/modals/MailPreviewModal'
import EmailTemplateForm from './EmailTemplateForm'
import Logger from 'src/utils/Logger'

const intervals = [
  {
    value: 'daily',
    name: 'Daily'
  },
  {
    value: 'weekly',
    name: 'Weekly'
  },
  {
    value: 'monthly',
    name: 'Monthly'
  },
  {
    value: 'quarterly',
    name: 'Quarterly'
  }
]

@observer
class Subscribe extends React.Component {
  static propTypes = {
    suggest: PropTypes.func.isRequired,
    createSubscription: PropTypes.bool
  }

  static defaultProps = {
    createSubscription: false
  }

  @observable width
  @observable copyUrl = ''

  submitData = async (e) => {
    e.preventDefault()
    const { rootStore } = this.props
    const { executeRecaptcha } = this.props.googleReCaptchaProps
    if (!executeRecaptcha) {
      toast.error(alertMessages.notValidated)
      return
    }
    const token = await executeRecaptcha()

    if (token) {
      try {
        this.form.fields.recaptchaResponse.update(token)
        if (this.form.fields.message.value) {
          const msg = this.form.fields.message.value
          const angleBracketsWords = msg.match(/\<([A-Za-z0-9_ ]+)\>/g)
          if (angleBracketsWords && angleBracketsWords.length > 0) {
            rootStore.setData('angleBracketsWords', angleBracketsWords)
            rootStore.setData('isOpenCharacterModal', true)
          } else {
            await this.props.suggest(this.form)
          }
        } else {
          await this.props.suggest(this.form)
        }
        const url =
          window.location != window.parent.location
            ? document.referrer
            : document.location.href
        window.parent.postMessage('SHOW-SUCCESS-MODAL', url)
      } catch (error) {
        Logger.error(error)
      }
    }
  }

  @action
  resize = () => {
    this.width = document.body.clientWidth
  }

  constructor(props) {
    super(props)
    this.width = document.body.clientWidth

    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      email: new Field({
        name: 'email',
        value: props.email || '',
        validators: [errors.isEmail()]
      }),
      message: new Field({
        name: 'message',
        value: ''
      }),
      template: new Field({
        name: 'template',
        value: ''
      }),
      interval: new Field({
        name: 'interval',
        value: intervals[1].value
      }),
      recaptchaResponse: new Field({
        name: 'recaptcha_response',
        value: ''
      })
    })

    this.setData(
      'copyUrl',
      `${props.text.replace('isSubscriptionFirst', 'isSubscription')}`
    )
  }

  @action
  setData = (name, data) => {
    this[name] = data
  }

  @computed
  get buttonText() {
    if (this.props.createSubscription) {
      return 'Send'
    }

    let text = 'Suggest a job alert'
    if (
      (this.width < 1100 && this.width > 1024) ||
      (this.width < 880 && this.width > 792) ||
      this.width < 660
    ) {
      text = 'Create'
    }
    return text
  }

  previewEmail = () => {
    this.props.previewEmail(this.form)
  }

  handleCopy = () => {
    toast.success(alertMessages.subscription)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.props.email) {
      this.form.fields.email.update(nextProps.email || '')
    }
  }

  render() {
    const {
      createSubscription,
      setPreviousStep,
      theme,
      subscriptionContent,
      rootStore,
      edmStore
    } = this.props

    const { loggedIn } = rootStore

    return (
      <>
        <form className="refari-form-section">
          <EmailTemplateForm view="regular" form={this.form} />

          <Select
            fieldData={this.form.fields.interval}
            emptyOption=""
            options={intervals}
            optionValueKey="value"
            label="Frequency"
            floatingLabelFixed
          />
          {createSubscription ? (
            <div
              // className="refari-subscribe-tab-content__center-button"
              style={{ marginTop: 'auto', paddingTop: '20px' }}
            >
              {loggedIn && !rootStore.user.isReferrer && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '8px'
                  }}
                >
                  <CopyToClipboard text={this.copyUrl} onCopy={this.handleCopy}>
                    <RaisedButton
                      style={{
                        backgroundColor: theme.palette.primary1Color,
                        color: '#fff',
                        flex: 1
                      }}
                      className="refari-submit-btn refari-button-raised"
                    >
                      {subscriptionContent()}
                    </RaisedButton>
                  </CopyToClipboard>

                  <RaisedButton
                    onClick={this.previewEmail}
                    color="primary"
                    className={`refari-submit-btn refari-button-raised ${
                      this.form.isPending ? 'refari-button-raised-disabled' : ''
                    }`}
                    style={{
                      flex: 1
                    }}
                    disabled={this.form.isPending}
                  >
                    Preview email
                  </RaisedButton>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '8px'
                }}
              >
                <RaisedButton
                  onClick={this.submitData}
                  color="primary"
                  className={`refari-submit-btn m-right_1x refari-button-raised ${
                    !this.form.isValid || this.form.isPending
                      ? 'refari-button-raised-disabled'
                      : ''
                  }`}
                  disabled={!this.form.isValid || this.form.isPending}
                >
                  {this.buttonText}
                </RaisedButton>
                <RaisedButton
                  color="primary"
                  className="refari-submit-btn"
                  onClick={setPreviousStep}
                >
                  Back
                </RaisedButton>
              </div>
            </div>
          ) : (
            <RaisedButton
              onClick={this.submitData}
              fullWidth
              color="primary"
              className={`refari-submit-btn refari-button-raised ${
                !this.form.isValid || this.form.isPending
                  ? 'refari-button-raised-disabled'
                  : ''
              }`}
              disabled={this.form.isValid || this.form.isPending}
            >
              {this.buttonText}
            </RaisedButton>
          )}
        </form>
        {edmStore.emailPreview && <MailPreviewModal edmStore={edmStore} />}
      </>
    )
  }
}

export default withGoogleReCaptcha(Subscribe)
