/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/state-in-constructor */
import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { SketchPicker } from 'react-color'
import { ListSubheader } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

@observer
class ColorPicker extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    onChangeComplete: PropTypes.func,
    shouldScrollToBottom: PropTypes.bool,
    onDisplayColorPicker: PropTypes.func
  }

  static defaultProps = {
    className: '',
    shouldScrollToBottom: false,
    onDisplayColorPicker: undefined
  }

  state = {
    displayColorPicker: false
  }

  handleChangeComplete = (color) => {
    this.props.onChangeComplete(color)
  }

  handleClick = () => {
    this.setState(
      (prev) => ({
        displayColorPicker: !prev.displayColorPicker
      }),
      this.addListener
    )
  }

  addListener = () => {
    setTimeout(() => {
      if (this.props.shouldScrollToBottom) {
        const scrollEnd = document.getElementsByClassName('.scroll-end')
        if (scrollEnd && scrollEnd.length > 0) {
          scrollEnd[0].scrollIntoView()
        }
      }
      if (this.state.displayColorPicker) {
        window.addEventListener('click', this.handleOutsideClick)
      } else {
        window.removeEventListener('click', this.handleOutsideClick)
      }
    })
  }

  handleOutsideClick = (e) => {
    const editedElement = document.getElementsByClassName(
      'color-picker__popover'
    )
    let isClickInside = false
    if (
      editedElement &&
      editedElement.length > 0 &&
      editedElement[0].contains(e.target)
    ) {
      isClickInside = true
    }
    if (!isClickInside) {
      window.removeEventListener('click', this.handleOutsideClick)
      this.setState({ displayColorPicker: false })
    }
  }

  swatchIsActive = () => {
    const { theme } = this.props
    if (this.state.displayColorPicker) {
      return { borderColor: theme.palette.primary1Color }
    }
    return {}
  }

  componentDidUpdate() {
    const { displayColorPicker } = this.state
    const { onDisplayColorPicker } = this.props

    if (displayColorPicker) {
      onDisplayColorPicker && onDisplayColorPicker()
    }
  }

  render() {
    const {
      className,
      color,
      label,
      shouldScrollToBottom,
      labelPlacement = 'start',
      sketcherStyles
    } = this.props

    return (
      <div className={`color-picker-wrap ${className}`}>
        {labelPlacement === 'start' && (
          <ListSubheader
            className="color-picker__label pl-0"
            disableSticky
            style={{ width: '100%' }}
          >
            {label}
          </ListSubheader>
        )}
        <div
          className="color-picker__swatch"
          style={this.swatchIsActive()}
          onClick={this.handleClick}
        >
          <div
            className="color-picker__swatch-inner"
            style={{ backgroundColor: color }}
          />
        </div>
        {this.state.displayColorPicker && (
          <div className="color-picker__popover" style={sketcherStyles || {}}>
            <SketchPicker
              className="color-picker__picker-widget"
              color={color}
              disableAlpha
              onChangeComplete={this.handleChangeComplete}
            />
            {shouldScrollToBottom && <div className=".scroll-end" />}
          </div>
        )}

        {labelPlacement === 'end' && (
          <ListSubheader
            className="color-picker__label pr-0"
            disableSticky
            style={{ width: '100%', paddingRight: 0, paddingLeft: '16px' }}
          >
            {label}
          </ListSubheader>
        )}
      </div>
    )
  }
}

export default withTheme(ColorPicker)
