/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const EmailIcon = (props) => {
  const style = {
    width: '18px',
    height: '15px',
    marginBottom: '-2px',
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 18 15"
    >
      <path
        fill={props.color}
        transform="translate(-461 -535)"
        d="M479 536.8v10.8c0 .99-.81 1.8-1.8 1.8h-14.4a1.8 1.8 0 0 1-1.8-1.8l.01-10.8c0-.99.8-1.8 1.79-1.8h14.4c.99 0 1.8.81 1.8 1.8zm-1.8 0l-7.2 4.5-7.2-4.5v1.8l7.2 4.5 7.2-4.5z"
      />
    </SvgIcon>
  )
}

export default EmailIcon
