import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from 'src/components/shared/modals/CloseModalButton'
import RaisedButton from 'src/components/shared/common/RaisedButton'
import Modal from 'src/components/shared/modals/Modal'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import FlatButton from 'src/components/shared/common/FlatButton'

type DeleteMessageTemplateModalProps = {
  templateSelectRef: Record<string, any>
  talentAlertStore: ITalentAlertStore
  theme: MUIThemeFuncReturnType
}

type DeleteMessageTemplateModalState = {
  disableBtn: boolean
}

@inject('talentAlertStore')
@observer
class DeleteMessageTemplateModal extends Component<
  DeleteMessageTemplateModalProps,
  DeleteMessageTemplateModalState
> {
  constructor(props: DeleteMessageTemplateModalProps) {
    super(props)

    this.state = {
      disableBtn: false
    }
  }

  closeModal = () => {
    const { talentAlertStore, templateSelectRef } = this.props

    talentAlertStore.closeSuggestionsMessageDeleteModal()
    talentAlertStore.setSuggestionTemplateMessageID(null)

    // Interestingly focusing from componentDidUpdate does not work
    // when close icon button is clicked
    templateSelectRef.current?.reactSelectRef.current?.focus()
  }

  deleteTemplate = async () => {
    this.setState({ disableBtn: true })

    const { talentAlertStore } = this.props

    const selectedMessage = talentAlertStore.suggestionMessageTemplates.filter(
      (message) => message.id === talentAlertStore.suggestionTemplateMessageID
    )

    if (selectedMessage && selectedMessage.length > 0) {
      await talentAlertStore.deleteSuggestionMessage(
        talentAlertStore.suggestionTemplateMessageID as number
      )
    }

    this.setState({ disableBtn: false })
  }

  componentDidUpdate() {
    this.props.templateSelectRef.current?.reactSelectRef.current?.focus()
  }

  render() {
    const { talentAlertStore, theme } = this.props
    const { disableBtn } = this.state

    if (!talentAlertStore.suggestionTemplateMessageID) {
      return null
    }

    const selectedMessage = talentAlertStore.suggestionMessageTemplates.filter(
      (message) => message.id === talentAlertStore.suggestionTemplateMessageID
    )

    const style = {
      // @ts-ignore Need to revisit this
      title: { color: theme.palette.popupPrimary },
      // @ts-ignore Need to revisit this
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={talentAlertStore.isOpenSuggestionMessageDeleteModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            // @ts-ignore Need to revisit this
            backgroundColor: theme.widgetBackgroundColor,
            // @ts-ignore Need to revisit this
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Delete Message Template
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 300,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <p className="refari-message-template-modal--content">
              Are you sure you want to delete this template:{' '}
              {selectedMessage && selectedMessage.length > 0
                ? selectedMessage[0].name
                : ''}
              ?
            </p>
            <RaisedButton
              color="primary"
              className="refari-button-raised"
              style={{ width: 'auto', marginRight: '10px' }}
              onClick={this.deleteTemplate}
              disabled={disableBtn}
            >
              Yes, Delete it!
            </RaisedButton>

            <FlatButton
              color="primary"
              style={{ border: '1px solid' }}
              className="m_r_2x refari-button-flat"
              onClick={this.closeModal}
            >
              No
            </FlatButton>
          </div>
        </div>
      </Modal>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(DeleteMessageTemplateModal)
