import { v1 as uuidv1 } from 'uuid'

export class MainTestimonials {
  constructor(data) {
    if (!data) data = {}
    this.consultantId = data.consultant ? data.consultant.id : ''
    this.testimonialId = data.id || ''
    this.consultantFirstName = data.consultant ? data.consultant.first_name : ''
    this.consultantLastName = data.consultant ? data.consultant.last_name : ''
    this.consultantAvatar = data.consultant ? data.consultant.avatar : null
    this.date = data.date
    this.isVerified = data.is_verified
    this.text = data.text
    this.authorFirstName = data.author.first_name || ''
    this.authorLastName = data.author.last_name || ''
    this.jobCompanyName = data.job_company_name || ''
    this.comments = data.comments || []
    this.jobTitle = data.job_title || ''
    this.authorType = data.author.author_type || ''
    this.authorEmail = data.author.email || ''
    this.id = uuidv1()
    this.customProfileUrl = data.consultant
      ? data.consultant.custom_profile_url
      : ''
    this.slug = data.consultant && data.consultant.first_name
      ? `${data.consultant.first_name.toLowerCase()}_${data.consultant.last_name.toLowerCase()}`
      : ''
  }

  get authorFullName() {
    return `${this.authorFirstName} ${this.authorLastName}`
  }
}
