/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import {
  inject,
  observer,
} from 'mobx-react'
import { action, observable } from 'mobx'
import {
  Dialog, TextField, IconButton
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import Copy from '@material-ui/icons/FileCopy'
import CloseButton from '../buttons/CloseButton'

@inject('utilsStore', 'agenciesStore', 'rootStore')
@observer
class JobAdLinkModal extends React.Component {
  static propTypes = {
    utilsStore: PropTypes.shape({
      jobAdLinkKey: PropTypes.string.isRequired,
      toggleModal: PropTypes.func.isRequired,
      modals: PropTypes.object.isRequired,
    }),
  }

  toggleModal = () => {
    const { toggleModal, jobAdLinkKey } = this.props.utilsStore
    this.props.rootStore.setData('socialize', '')
    toggleModal(jobAdLinkKey)
  }

  @observable
  isCopied = false

  @action
  setCopied = () => {
    this.isCopied = true
  }

  onClick = (e) => {
    e.target.select()
  }

  render() {
    const {
      utilsStore: { jobAdLinkKey, modals },
      rootStore: { socialize },
      theme,
    } = this.props

    if (!socialize) {
      return null
    }

    const style = {
      title: { color: theme.palette.primary1Color, padding: '20px 0 15px 0' }
    }
    return (
      <Dialog
        className="modal"
        open={modals[jobAdLinkKey].isOpen}
        onClose={this.toggleModal}
        fullWidth
      >
        <div className="modal-content">
          <CloseButton onClose={this.toggleModal} />
          <h3
            style={style.title}
          >
            JobAd Link
          </h3>
          <div className="refari-direct-link-wrap" style={{ padding: '0 70px' }}>
            <div className="refari-align-items-center refari-copied-wrap">
              <TextField
                fullWidth
                name="directLink"
                onClick={this.onClick}
                value={socialize}
                style={{ margin: 0 }}
              />
              <CopyToClipboard text={socialize} onCopy={this.setCopied}>
                <div className="refari-copied-click-box">
                  <IconButton>
                    <Copy />
                  </IconButton>
                </div>
              </CopyToClipboard>
              {this.isCopied && <span className="refari-copied-msg">Copied!</span>}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(JobAdLinkModal)
