import React, { useEffect, useState } from 'react'
import { withTheme, Button, Menu, MenuItem, Select } from '@material-ui/core'
import CustomNestedMenu from '../forms/CustomNestedMenu'

type Props = {
  agenciesList: any[]
  defaultSelectedValue: string
  onMenuChange: (value: string, isHiringManager?: boolean) => void
  availableProfiles: string[]
}

const userType = {
  hiringManager: 'hiringManager',
  referrer: 'referrer'
}

function HeaderSwitchMenu(props: Props) {
  const { agenciesList, defaultSelectedValue, availableProfiles } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue)

  useEffect(() => {
    setSelectedValue(defaultSelectedValue)
  }, [defaultSelectedValue])

  const updateSelectedValue = (value: string, agency?: any) => {
    const { onMenuChange } = props
    const isHiringManager = value === userType.hiringManager
    setIsMenuOpen(false)
    setSelectedValue(value)
    onMenuChange(isHiringManager ? value : agency, isHiringManager)
  }

  const isHiringManagerAvailable =
    Array.isArray(availableProfiles) &&
    availableProfiles.includes('hiring_manager')
  if (!isHiringManagerAvailable) {
    return (
      <Select
        disableUnderline
        displayEmpty={false}
        value={selectedValue}
        style={{ color: '#fff', width: '100%' }}
        // autoWidth
        className="refari-select-white"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onOpen={() => setIsMenuOpen(true)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {Array.isArray(agenciesList) &&
          agenciesList.map((agency, index) => (
            <MenuItem
              key={agency.name + index}
              value={agency.name}
              className="header-nav-menu"
              onClick={() => updateSelectedValue(agency.name, agency)}
            >
              {agency.name}
            </MenuItem>
          ))}
      </Select>
    )
  }

  return (
    <>
      <Select
        disableUnderline
        displayEmpty={false}
        value={selectedValue}
        style={{ color: '#fff', width: '100%' }}
        // autoWidth
        className="refari-select-white"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        onOpen={() => setIsMenuOpen(true)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {/**
         * @note the reason for duplicate menu item is to provide values in non-nested way
         * otherwise select won't update the value
         * one solution could be, instead of using select replace it with Menu itself
         */}
        {Array.isArray(agenciesList) &&
          agenciesList.map((agency, index) => (
            <MenuItem
              key={agency.name + index}
              value={agency.name}
              className="header-nav-menu"
              style={{ display: 'none' }}
            >
              {agency.name}
            </MenuItem>
          ))}
        <CustomNestedMenu label="Company User" parentMenuOpen={isMenuOpen}>
          {Array.isArray(agenciesList) &&
            agenciesList.map((agency, index) => (
              <MenuItem
                key={agency.name + index}
                value={agency.name}
                className="header-nav-menu"
                onClick={() => updateSelectedValue(agency.name, agency)}
              >
                {agency.name}
              </MenuItem>
            ))}
        </CustomNestedMenu>
        <MenuItem
          value={userType.hiringManager}
          className="header-nav-menu"
          onClick={() => updateSelectedValue(userType.hiringManager)}
        >
          Hiring Manager
        </MenuItem>
      </Select>
    </>
  )
}

export default withTheme(HeaderSwitchMenu)
