const generateCloneTag = (tag: string): string => {
  const splitTag = tag.split('-')

  if (splitTag.length == 2 && splitTag[1] == 'copy') {
    return tag + '-1'
  } else if (
    splitTag.length > 2 &&
    splitTag[1] == 'copy' &&
    Number(splitTag[2])
  ) {
    const length = splitTag.length
    return splitTag.reduce((acc, tagItem, index) => {
      if (index === 0) {
        return tagItem
      }
      if (index === length - 1) {
        return acc + '-' + (Number(tagItem) + 1)
      }
      return acc + '-' + tagItem
    }, '')
  } else {
    return tag + '-copy'
  }
}

export default generateCloneTag
