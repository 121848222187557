import { baseUrls } from '../utils/Urls'

export default {
  alert: {
    position: 'bottom-right',
    effect: 'stackslide',
    timeout: 5000
  },
  particlesBackground: {
    particles: {
      number: {
        value: 40, // 25
        density: {
          enable: false,
          value_area: 800
        }
      },
      color: {
        value: '#ffffff'
      },
      shape: {
        type: 'image',
        stroke: {
          width: 0,
          color: '#000000'
        },
        polygon: {
          nb_sides: 5
        },
        image: {
          src: `${baseUrls.static}profile-icon.png`,
          width: 150,
          height: 150
        }
      },
      // "opacity": {
      //   "value": 0,
      //   "random": false,
      //   "anim": {
      //     "enable": false,
      //     "speed": 1,
      //     "opacity_min": 0.1,
      //     "sync": false
      //   }
      // },
      size: {
        value: 8, // 6
        random: true, // true
        anim: {
          enable: true,
          speed: 1,
          size_min: 4,
          sync: false
        }
      },
      links: {
        enable: true,
        distance: 250,
        color: '#ffffff',
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        speed: 4,
        direction: 'none',
        random: false,
        straight: false,
        outMode: 'bounce',
        bounce: false, // true
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detectsOn: 'canvas',
      events: {
        onHover: {
          enable: true,
          mode: 'grab'
        },
        onClick: {
          enable: true,
          mode: 'push'
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },

        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          quantity: 3 // 4
        },
        remove: {
          quantity: 2
        }
      }
    },
    detectRetina: true
  }
}

export const AutoCompleteStyle = {
  control: (provided) => ({
    // width: 200,
    ...provided,
    borderLeft: '0 !important',
    borderRight: '0 !important',
    borderTop: '0 !important',
    boxShadow: '0 !important',
    flexDirection: 'row-reverse',
    '&:hover': {
      borderLeft: '0 !important',
      borderRight: '0 !important',
      borderTop: '0 !important'
    }
  }),
  indicatorContainer: () => ({
    display: 'none'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    position: 'absolute',
    right: '32px'
  })
}

export const MAX_FILE_SIZE = 50 // in Mb
