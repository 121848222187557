/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('defaultReportStore')
@inject('agenciesStore')
@observer
class Reports extends React.Component {
  static propTypes = {
    defaultReportStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.defaultReportStore.initTab(pathname)
  }

  componentDidUpdate() {
    const { pathname } = this.props.history.location
    this.props.defaultReportStore.initTab(pathname)
  }

  onTabChange = (link) => {
    this.props.defaultReportStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props
    return (
      <div className="dashboard dashboard-report-container">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container">
            <Switch>
              <PrivateRoute
                exact
                path={`${match.url}${URLs.reports.children.general.path}`}
                component={asyncComponent(() =>
                  import('../adminReports/GeneralReports')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.reports.children.candidateAquisition.path}`}
                component={asyncComponent(() =>
                  import('../adminReports/CandidateAquisitionReports')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.reports.children.billingPredictor.path}`}
                component={asyncComponent(() =>
                  import('../adminReports/BillingPredictor')
                )}
              />
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Reports)
