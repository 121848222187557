/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CandidateIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const CandidateIcon = ({
  iconColor,
  ...restProps
}: CandidateIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="candidateIcon">
        <path fill="none" d="M239 216h24v24h-24z" data-name="Rectangle 1428" />
      </clipPath>
    </defs>
    <g clipPath="url(#candidateIcon)" transform="translate(-239 -216)">
      <g fill={iconColor} data-name="Group 3007">
        <path
          d="M262.613 216H239.34a.323.323 0 0 0-.322.322v1.244a.323.323 0 0 0 .322.322h23.273a.323.323 0 0 0 .322-.322v-1.244a.323.323 0 0 0-.322-.322Z"
          data-name="Path 2461"
        />
        <path
          d="M239.827 236.231a.764.764 0 0 0 .764.764h9.937a2.248 2.248 0 1 0 .9 0h9.933a.764.764 0 0 0 .764-.764v-17.084h-22.3Zm11.869 2.967a.719.719 0 1 1-.719-.719.72.72 0 0 1 .719.719Zm-.719-16.772a2.524 2.524 0 1 1-2.524 2.524 2.527 2.527 0 0 1 2.524-2.524Zm-4.406 7.94a2.5 2.5 0 0 1 1.71-2.372h.015l.664-.2a.643.643 0 0 1 .631.148l1.386 1.317 1.384-1.326a.643.643 0 0 1 .631-.148l.664.2h.015a2.5 2.5 0 0 1 1.71 2.372v3.255a.642.642 0 0 1-.643.643h-7.525a.642.642 0 0 1-.643-.643Z"
          data-name="Path 2462"
        />
        <path
          d="M250.977 226.189a1.239 1.239 0 1 0-1.239-1.239 1.24 1.24 0 0 0 1.239 1.239Z"
          data-name="Path 2463"
        />
        <path
          d="M254.097 230.366a1.214 1.214 0 0 0-.825-1.151l-.3-.091-1.557 1.479a.643.643 0 0 1-.885 0l-1.557-1.479-.3.091a1.214 1.214 0 0 0-.825 1.151v2.613h6.241Z"
          data-name="Path 2464"
        />
      </g>
    </g>
  </SvgIcon>
)

export default CandidateIcon
