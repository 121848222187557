/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { MenuItem, Select, Dialog } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseButton from '../buttons/CloseButton'
import routesUrls from 'src/router/routesUrls'

@inject('utilsStore', 'agenciesStore')
@observer
class SwitchAgencyModal extends React.Component {
  static propTypes = {
    utilsStore: PropTypes.shape({
      switchAgencyKey: PropTypes.string.isRequired,
      toggleModal: PropTypes.func.isRequired,
      modals: PropTypes.object.isRequired
    })
  }

  toggleModal = () => {
    const { toggleModal, switchAgencyKey } = this.props.utilsStore
    toggleModal(switchAgencyKey)
  }

  changeAgency = (agency) => async () => {
    const { agenciesStore, utilsStore, history } = this.props

    const { changeAgency } = agenciesStore
    const { modals, switchAgencyKey } = utilsStore

    if (modals[switchAgencyKey].source === 'candidatesPage') {
      const fetchParterActions = modals[switchAgencyKey].fetchPartnerActions

      const partnerActions = await fetchParterActions(agency.id)

      const isCandidateAdCreateAction =
        partnerActions.action?.obj_type === 'candidate_ad_create'

      const pathName = isCandidateAdCreateAction
        ? routesUrls.dashboard.children.candidateAds.link
        : window.location.pathname

      changeAgency(agency, undefined, () => {
        if (history) {
          history.replace({
            pathname: pathName,
            search: `${window.location.search}&company=${agency.id}`
          })
        }
      })
    } else {
      if (this.props.history) {
        this.props.history.replace({
          pathname: window.location.pathname,
          search: `${window.location.search}&company=${agency.id}`
        })
        changeAgency(agency)
      }
    }

    this.toggleModal()
  }

  getActiveAgencyName = () => {
    const { agenciesStore, utilsStore } = this.props
    const { modals, switchAgencyKey } = utilsStore

    if (!agenciesStore.activeAgency) {
      return ''
    }

    // agencies from partner actions are passed
    // from candidates page
    const partnerActionApplicableCompanies = modals[switchAgencyKey].agencies

    if (Array.isArray(partnerActionApplicableCompanies)) {
      const foundActiveAgency = partnerActionApplicableCompanies.find(
        (company) => company.id === agenciesStore.activeAgency.id
      )

      if (!foundActiveAgency) {
        return ''
      }

      return foundActiveAgency.name
    }

    return agenciesStore.activeAgency.name
  }

  getAgenciesList = () => {
    const { utilsStore } = this.props
    const { modals, switchAgencyKey } = utilsStore

    const partnerActionApplicableCompanies = modals[switchAgencyKey].agencies

    return partnerActionApplicableCompanies ?? []
  }

  render() {
    const {
      utilsStore: { switchAgencyKey, modals },
      theme
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color, padding: '20px 0 15px 0' }
    }

    return (
      <Dialog
        className="modal"
        open={modals[switchAgencyKey].isOpen}
        onClose={this.toggleModal}
        maxWidth="xs"
        PaperProps={{
          style: { maxWidth: 400 }
        }}
        fullWidth
      >
        <div className="modal-content">
          <CloseButton onClose={this.toggleModal} />
          <h3 style={style.title}>Choose company</h3>
          <Select
            displayEmpty={false}
            value={this.getActiveAgencyName()}
            style={{ margin: '20px 0', width: '100%' }}
            autoWidth
          >
            {this.getAgenciesList().map((item, index) => (
              <MenuItem
                key={item.name + index}
                value={item.name}
                onClick={this.changeAgency(item)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(SwitchAgencyModal)
