/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { CircularProgress, Button } from '@material-ui/core'

const ErrorMessage = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      margin: 20,
      flexDirection: 'column'
    }}
  >
    <div>Sorry, there was a problem loading the page.</div>
    <Button
      color="primary"
      variant="outlined"
      onClick={() => {
        window.location.reload(true)
      }}
      style={{ marginTop: 15 }}
    >
      Your page requires a refresh
    </Button>
  </div>
)

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props)
      this._isMounted = false
      this.state = {
        component: null
      }
    }

    async componentDidMount() {
      this._isMounted = true
      try {
        const { default: component } = await importComponent()
        this._isMounted && this.setState({
          component
        })
      } catch (error) {
        console.error(error)
        this._isMounted && this.setState({
          component: ErrorMessage
        })
      }
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      const Component = this.state.component

      return (
        Component
          ? <Component {...this.props} />
          : <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress
              size={80}
              color="primary"
            />
          </div>
      )
    }
  }

  return AsyncComponent
}
