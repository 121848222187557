/* eslint-disable import/no-named-as-default */
import RootStore from './RootStore'
import AuthStore from './AuthStore'
import DashboardStore from './DashboardStore'
import DefaultReportStore from './DefaultReportStore'
import UtilsStore from './UtilsStore'
import JobAlertsStore from './JobAlertsStore'
import InviteStore from './InviteStore'
import AuthTwoFactorStore from './AuthTwoFactorStore'
import JobsStore from './JobsStore'
import ConsultantStore from './ConsultantStore'
import CandidatesStore from './CandidatesStore'
import SpinnersStore from './SpinnersStore'
import NetworkStore from './NetworkStore'
import ConsultantsTagsStore from './ConsultantsTagsStore'
import CustomBackgroundStore from './CustomBackgroundStore'
import AgenciesStore from './AgenciesStore'
import PlacementStore from './PlacementStore'
import RequestStore from './RequestStore'
import WidgetsStore from './WidgetsStore'
import BillingStore from './BillingStore'
import GameficationStore from './GameficationStore'
import RewardsStore from './RewardStore'
import TestimonialsStore from './TestimonialsStore'
import EdmStore from './EdmStore'
import XmlStore from './XmlStore'
import MappingStore from './MappingStore'
import TopRecStore from './TopRecStore'
import CanvasCardStore from './CanvasCardStore'
import SocializeStore from './SocializeStore'
import PitchMeStore from './PitchMeStore'
import CommentStore from './CommentStore'
import DetailsStore from './DetailsStore'
import StandAloneStore from './StandAloneStore'
import CandidatesMainStore from './CandidatesMainStore'
import CandidateAdsStore from './CandidateAdsStore'
import JobAdStore from './JobAdStore'
import ClientsStore from './ClientsStore'
import TalentAlertStore from './TalentAlertStore'

export default {
  rootStore: RootStore,
  authStore: AuthStore,
  dashboardStore: DashboardStore,
  defaultReportStore: DefaultReportStore,
  utilsStore: UtilsStore,
  jobAlertsStore: JobAlertsStore,
  inviteStore: InviteStore,
  authTwoFactorStore: AuthTwoFactorStore,
  jobsStore: JobsStore,
  consultantStore: ConsultantStore,
  candidatesStore: CandidatesStore,
  spinnersStore: SpinnersStore,
  networkStore: NetworkStore,
  consultantsTagsStore: ConsultantsTagsStore,
  customBackgroundStore: CustomBackgroundStore,
  agenciesStore: AgenciesStore,
  placementStore: PlacementStore,
  requestStore: RequestStore,
  widgetsStore: WidgetsStore,
  billingStore: BillingStore,
  detailsStore: DetailsStore,
  gameficationStore: GameficationStore,
  rewardsStore: RewardsStore,
  testimonialsStore: TestimonialsStore,
  edmStore: EdmStore,
  xmlStore: XmlStore,
  mappingStore: MappingStore,
  topRecStore: TopRecStore,
  canvasCardStore: CanvasCardStore,
  socializeStore: SocializeStore,
  pitchMeStore: PitchMeStore,
  commentStore: CommentStore,
  standAloneStore: StandAloneStore,
  candidatesMainStore: CandidatesMainStore,
  candidateAdsStore: CandidateAdsStore,
  jobAdStore: JobAdStore,
  clientsStore: ClientsStore,
  talentAlertStore: TalentAlertStore
}
