import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Rating as MUIRating } from '@material-ui/lab'

@observer
class Rating extends React.Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    cb: PropTypes.func,
  }
  onChange = (e, value) => {
    this.props.onChange(value)
    this.props.cb && this.props.cb()
  }

  render() {
    return (
      <div>
        <MUIRating
          name="refari-rating"
          value={this.props.value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default Rating
