/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthSession from '../utils/AuthSession'
import routesUrls from './routesUrls'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthSession.isTokenSet() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: routesUrls.signIn.link,
            search: `?redirect_url=${props.location.pathname}`,
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

export const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !AuthSession.isTokenSet() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard/',
            state: { from: props.location }
          }}
        />
      )
    }
  />
)
