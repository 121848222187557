import React from 'react'
import { SvgIcon } from '@material-ui/core'

const LogoIcon = (props) => {
  const style = {
    width: '50px',
    height: '50px',
    ...props.style
  }
  return (
    <SvgIcon
      style={style}
      {...props}
      viewBox="0 0 378 116.16"
    >
      <path fill={props.fill} d="M28.71,116.05H26.33l-.94,0L0,116.16V98.52C.87,89.33,3.66,76.93,11.76,67,23.35,52.9,29,49.23,38.23,44.86l4,12.52,18.94-28L30.06,18.22l3.73,13.14C27.58,34.07,12.56,41.37,0,52.64V.12H41.14c24.51,0,44.67,8.52,44.67,36.05,0,17-8.24,27.8-20.59,33.38l26.15,46.26L59.51,116l-23-39.42L29,76.76Z" />
      <path fill={props.fill} d="M357.13,37.43H378v78.48H357.13ZM357.28,10H378V28.6H357.28Z" />
      <path fill={props.fill} d="M177.79,54H165.86V37.36h11.93V29.65C177.79,6.07,193.38,0,205.05,0c4.27,0,7.07,0,7.07,0V18.24a28.67,28.67,0,0,0-4.28.15c-7.27.75-9.18,5.75-9.18,11.75v7.22h13.06V54H198.66v62H177.79Z" />
      <path fill={props.fill} d="M318,47c6.31-8.05,13.81-9.49,26.63-9.49h3.78v18.3h-4.93c-8.88,0-18.9,3.44-22.85,14.56a36.09,36.09,0,0,0-1.8,12.16v33.35H297.9V37.47H318V47Z" />
      <path fill={props.fill} d="M143.49,68.41s-.46-2.54-.49-2.68a13.32,13.32,0,0,0-3.12-6.52,14.17,14.17,0,0,0-5.91-4c-4.45-1.71-11.11-1.56-15.15,1.13a17.21,17.21,0,0,0-7.53,12.1Zm19.63,5a64,64,0,0,1-.93,9.93H111.08c1.62,7.44,10.12,15.21,22.39,15.21,6.79,0,12.49-2.78,19.12-6.14l7.51,14.79A57.24,57.24,0,0,1,130.43,116C107.1,116,89,101.55,89,76.67c0-24.48,18.89-39.19,38.94-39.19,23.08,0,35.23,15.58,35.23,35.93" />
      <path fill={props.fill} d="M270.05,89.81l-.48-8.68c-8.85-.84-32.8-2.77-33.84,9.75-.25,3.05,1,6.64,3.69,8.11,5.66,3.09,16.68-.77,21-2.92a57.49,57.49,0,0,0,9.68-6.26M216.27,93c0-17,13.09-30.87,53.15-27.58-.8-6.79-4.5-13.38-19.27-12.37-7.32.5-14.62,3.93-23.18,8.22l-8-14.77A71.59,71.59,0,0,1,254.5,37c21.38,0,34.33,10.32,34.33,33.62v45.18h-20l-.05-7.14h-.57C260.53,114.47,251,116,241.26,116c-15.38,0-25-10.13-25-22.91" />
    </SvgIcon>
  )
}

export default LogoIcon

LogoIcon.defaultProps = {
  fill: '#fff'
}
