/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type ReferrerIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const ReferrerIcon = ({
  iconColor,
  ...restProps
}: ReferrerIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="referrerIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M-2.081-2.217h24v24h-24z"
          data-name="Rectangle 1390"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#referrerIcon)" transform="translate(2.081 2.217)">
      <g
        fill={iconColor}
        data-name="Group 2986"
        transform="translate(-1.87 -2.005)"
      >
        <circle
          cx="2.249"
          cy="2.249"
          r="2.249"
          data-name="Ellipse 5"
          transform="translate(9.539)"
        />
        <path
          d="M12.66 4.535h-1.707a3.119 3.119 0 0 0-3.06 3.177v.512c0 .24.09.51.36.51h7.078c.27 0 .36-.27.36-.51v-.48a3.119 3.119 0 0 0-3.031-3.209Z"
          data-name="Path 2379"
        />
        <path
          d="M3.15 14.13a.45.45 0 0 0 .45-.45 9.267 9.267 0 0 1 3.479-7.257.42.42 0 0 0 .06-.63.45.45 0 0 0-.63-.06A10.2 10.2 0 0 0 2.7 13.68a.45.45 0 0 0 .45.45Z"
          data-name="Path 2380"
        />
        <path
          d="M14.757 21.147a9.477 9.477 0 0 1-5.908 0 .45.45 0 0 0-.567.289v.011a.48.48 0 0 0 .3.57 10.407 10.407 0 0 0 3.209.51 10.107 10.107 0 0 0 3.269-.54.45.45 0 0 0-.3-.84Z"
          data-name="Path 2381"
        />
        <path
          d="M16.042 6.117h.006a9.327 9.327 0 0 1 3.929 7.587.45.45 0 1 0 .9 0 10.256 10.256 0 0 0-4.318-8.337.48.48 0 0 0-.63.12.45.45 0 0 0 .113.63Z"
          data-name="Path 2382"
        />
        <circle
          cx="2.249"
          cy="2.249"
          r="2.249"
          data-name="Ellipse 6"
          transform="translate(1.651 14.82)"
        />
        <path
          d="M4.772 19.379H3.06A3.119 3.119 0 0 0 0 22.555v.512c0 .24.09.51.36.51h7.079c.27 0 .36-.27.36-.51v-.48a3.119 3.119 0 0 0-3.027-3.208Z"
          data-name="Path 2383"
        />
        <circle
          cx="2.249"
          cy="2.249"
          r="2.249"
          data-name="Ellipse 7"
          transform="translate(17.427 14.82)"
        />
        <path
          d="M20.548 19.379h-1.711a3.119 3.119 0 0 0-3.06 3.177v.512c0 .24.09.51.36.51h7.078c.27 0 .36-.27.36-.51v-.48a3.119 3.119 0 0 0-3.027-3.209Z"
          data-name="Path 2384"
        />
      </g>
    </g>
  </SvgIcon>
)

export default ReferrerIcon
