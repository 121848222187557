import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'

import CloseModalButton from 'src/components/shared/modals/CloseModalButton'
import RaisedButton from 'src/components/shared/common/RaisedButton'
import Modal from 'src/components/shared/modals/Modal'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'

type CharacterModalProps = {
  talentAlertStore: ITalentAlertStore
  theme: MUIThemeFuncReturnType
}

@inject('talentAlertStore')
@observer
class CharacterModal extends Component<CharacterModalProps> {
  closeModal = () => {
    const { talentAlertStore } = this.props

    talentAlertStore.setAngleBracketsWords([])
    talentAlertStore.closeCharacterModal()
  }

  render() {
    const { talentAlertStore, theme } = this.props
    const style = {
      // @ts-ignore suppressing this
      title: { color: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container modal"
        maxWidth="xs"
        open={talentAlertStore.isOpenCharacterModal}
        onClose={this.closeModal}
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            // @ts-ignore suppressing this
            backgroundColor: theme.widgetBackgroundColor,
            // @ts-ignore suppressing this
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Alert!
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 400,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <p className="refari-message-template-modal--content">
              You currently have{' '}
              {talentAlertStore.angleBracketsWords.join(', ')} included in your
              message, these can&apos;t be sent in manual Job Alerts, please
              remove the &lt; &gt; and try again.
            </p>
            <RaisedButton
              color="primary"
              className="refari-button-raised"
              style={{ width: 'auto' }}
              onClick={this.closeModal}
            >
              Ok
            </RaisedButton>
          </div>
        </div>
      </Modal>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(CharacterModal)
