import React from 'react'

const PlayIcon = ({ className, ...restProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="45"
    height="45"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
  >
    <path
      d="M24,2c12.1,0,22,9.9,22,22s-9.9,22-22,22S2,36.1,2,24S11.9,2,24,2 M24,0C10.7,0,0,10.7,0,24s10.7,24,24,24s24-10.7,24-24S37.3,0,24,0L24,0z"
      fill={restProps.color}
    />
    <g opacity="0.69">
      <circle cx="24" cy="24" r="22" fill="#020202" />
    </g>
    <path
      d="M32.6,22.7l-13.2-9.6c-0.5-0.4-1.1-0.4-1.7-0.1c-0.5,0.3-0.9,0.8-0.9,1.4v19.2c0,0.6,0.3,1.2,0.9,1.4c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.7-0.1,0.9-0.3l13.2-9.6c0.4-0.3,0.7-0.8,0.7-1.3C33.2,23.5,33,23,32.6,22.7z"
      fill={restProps.color}
    />
  </svg>
)

export default PlayIcon
