export default {
  required: 'This field may not be blank.',
  isEmail: 'Enter a valid email address.',
  isURL: 'Enter a valid URL.',
  isLinkedinProfileURL: 'Enter a valid linkedin profile URL.',
  isLinkedinCompanyURL: 'Invalid URL!! You need to signin to your linkedin company page as an administrator, so that you can generate a URL like: https://www.linkedin.com/company/33246706/',
  isMessage: (length) => `Message must be a minimum of ${length} characters.`,
  min: (length) => `Field must be a minimum of ${length} characters.`,
  max: (length) => `Field must be a maximum of ${length} characters.`,
  passwordEquals: 'The two password fields didn\'t match.',
  password: 'Enter a valid password.',
  maxFileSize: (max, current) => {
    current = `${(current / 1024 / 1024).toFixed(2)} Mb`
    return `Please keep file size under ${max} Mb. Current file size is ${current}.`
  },
  emptyValue: "This field shouldn't be empty"
}
