/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import { observable, computed } from 'mobx'
import { utils } from '../utils/helpers'
import Currency from './Currency'
import RootStore from '../stores/RootStore'

export default class Payment {
    @observable id
    @observable invoiceId
    @observable paymentType
    @observable paymentMethod
    @observable consultantCount
    @observable transactionCount
    @observable isGroup
    @observable status
    @observable created
    @observable dateFrom
    @observable dateTo
    @observable rates
    @observable.ref total

    constructor(data) {
      if (!data) data = {}
      this.id = data.id
      this.invoiceId = data.id
      this.consultantCount = data.consultant_count || 0
      this.paymentType = data.payment_type
      this.paymentMethod = this.returnMethod(data)
      this.total = new Currency(data.total, 'amount')
      this.status = data.status
      this.created = data.created
      this.transactionCount = data.transaction_count
      this.isGroup = data.is_group || false
      this.dateFrom = this.formatDate(data.date_from)
      this.dateTo = this.formatDate(data.date_to)
      this.rates = data.rates ? this.getRatesDate(data.rates) : null
      this.pdf = data.pdf
    }

    @computed
    get presentationalID() {
      return this.id.substring(0, 8)
    }

    getRatesDate(rates) {
      return {
        perApplication: rates.per_application,
        perReferrer: rates.per_referrer,
        perConsultant: rates.per_consultant
      }
    }

    formatDate(date) {
      if (!date) {
        return null
      }
      return utils.dateFormatter(date, '/', '', RootStore.agency.currency)
    }

    returnMethod(data) {
      return data.payment_method === 'stripe'
        ? `card ${data.payment_data ? data.payment_data.last4 : ''}`
        : data.payment_method === 'internal'
          ? 'system credit'
          : data.payment_method
    }

    @computed
    get createdDate() {
      return utils.dateFormatter(this.created, '/', '', RootStore.agency.currency)
    }
}
