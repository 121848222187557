/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type JobAdsIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const JobAdsIcon = forwardRef(
  (
    { iconColor, ...restProps }: JobAdsIconProps,
    ref: React.ForwardedRef<SVGSVGElement>
  ): React.ReactElement => (
    <SvgIcon
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...restProps}
    >
      <defs>
        <clipPath id="jobAdsIcon">
          <path
            fill={iconColor}
            stroke={iconColor}
            d="M239 502h24v24h-24z"
            data-name="Rectangle 1394"
          />
        </clipPath>
      </defs>
      <g
        clipPath="url(#jobAdsIcon)"
        data-name="Jobs Ads"
        transform="translate(-239 -502)"
      >
        <g fill={iconColor} data-name="Group 2982">
          <path
            d="m260.012 505.858-2.432 1.24a.67.67 0 0 0 .609 1.195l2.432-1.24a.67.67 0 1 0-.609-1.195Z"
            data-name="Path 2393"
          />
          <path
            d="M258.903 502.362a.671.671 0 0 0-.938.14l-1.822 2.435a.671.671 0 1 0 1.079.796l1.822-2.434a.67.67 0 0 0-.14-.937Z"
            data-name="Path 2394"
          />
          <path
            d="m262.13 509.116-3.04.045a.671.671 0 1 0 .01 1.341l3.04-.044a.671.671 0 1 0-.01-1.342Z"
            data-name="Path 2395"
          />
          <path
            d="M251.564 502.675a1.341 1.341 0 0 0-.586 1.804l6.087 11.947a1.341 1.341 0 1 0 2.39-1.217l-6.088-11.948a1.341 1.341 0 0 0-1.803-.586Z"
            data-name="Path 2396"
          />
          <path
            d="M250.775 518.062a.662.662 0 0 1-.321.802l-.732.372a.648.648 0 0 0-.13.104l-.633-1.005-.6.09-3.14 1.601 2.85 4.363a2.011 2.011 0 0 0 2.84.195 1.983 1.983 0 0 0 .256-2.755l-.876-1.386c.013-.007.029-.005.042-.012l.732-.373a2.008 2.008 0 0 0 1.047-2.197Z"
            data-name="Path 2397"
          />
          <path
            d="m244.91 510.29 3.888 7.63 6.985-1.05-5.918-11.614Z"
            data-name="Path 2398"
          />
          <path
            d="M240.792 512.148a2.014 2.014 0 0 0-.879 2.705l1.826 3.584a2.014 2.014 0 0 0 2.705.88l3.03-1.544-3.653-7.169Z"
            data-name="Path 2399"
          />
        </g>
      </g>
    </SvgIcon>
  )
)

JobAdsIcon.displayName = 'JobAdsIcon'

export default JobAdsIcon
