/* eslint-disable max-classes-per-file */
import { defaultPalette } from '../utils/MUITheme'

export default class Branding {
  constructor(data) {
    if (!data) data = {}
    this.description = data.description || ''
    this.logo = new Image(data.logo)
    this.logo_wide = new Image(data.logo_wide)
    this.logo_platform = new Image(data.logo_platform)
    this.useAdvancedOptions = data.use_advanced_options
    // main color palette
    this.primaryColor = data.color_primary || defaultPalette.primaryColor
    this.accentColor = data.color_accent || defaultPalette.accentColor
    this.textColor = data.color_font || defaultPalette.textColor
    // widget color palette
    this.primaryColorWidget = data.color_widget_primary || this.primaryColor
    this.accentColorWidget = data.color_widget_accent || this.accentColor
    this.textColorWidget = data.color_widget_font || this.textColor
    // socialize color palette
    this.primaryColorSocialize =
      data.color_socialize_primary || this.primaryColor
    this.accentColorSocialize = data.color_socialize_accent || this.accentColor
    this.textColorSocialize = data.color_socialize_font || this.textColor
    this.colorWidgetBackground = data.color_widget_background || ''
    this.colorWidgetTile = data.color_widget_tile || ''
    this.socializeFont = data.socialize_font || ''
    this.colorWidgetPopupPrimary =
      data.color_widget_popup_primary || defaultPalette.primaryColor
    this.colorWidgetPopupBackground =
      data.color_widget_popup_background || '#fff'
  }
}

export class Image {
  constructor(data) {
    if (!data) data = {}
    this.image = data.image || ''
    this.thumb = data.thumb || data.thumb_small || ''
    this.thumb_large = data.thumb_large || ''
    this.thumb_list = data.thumb_list || ''
    this.settings_data = data.settings_data || null
    this.id = data.id || null
    this.isVideo = false
  }
}
