/* eslint-disable no-useless-escape */
import React, { Component, createRef } from 'react'
import { observer, inject } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'
import Editor from 'react-simple-code-editor'

import RaisedButton from 'src/components/shared/common/RaisedButton'
import NonFieldError from 'src/components/shared/formComponents/NonFieldError'
import Input from 'src/components/shared/formComponents/Input'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import type { ITalentAlertStore } from 'src/stores/TalentAlertStore'
import Select from 'src/components/shared/formComponents/v2/Select'
import {
  groupByTemplateType,
  shouldDisableMessageTemplateUpdate
} from 'src/utils/helpers'
import CustomEmailTemplateOption from 'src/components/pages/tools/Shared/CustomEmailTemplateOption'
import MessageTemplateModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/SuggestionMessageModal'
import TemplateNameChangeModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/TemplateNameChangeModal'
import DeleteMessageTemplateModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/DeleteMessageTemplateModal'
import EmailPreviewModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/EmailPreviewModal'
import CharacterModal from 'src/components/pages/tools/CreateTalentAlert/TalentAlertForm/ChracterModal'

require('prismjs/components/prism-jsx')

type EmailTemplateFormProps = {
  form: Record<string, any>
  view: 'tabPanel' | 'regular'
  theme: MUIThemeFuncReturnType
  talentAlertStore: ITalentAlertStore
  rootStore: Record<string, any>
}

@inject('talentAlertStore', 'rootStore')
@observer
class EmailTemplateForm extends Component<EmailTemplateFormProps> {
  templateSelectRef: Record<string, any>

  constructor(props: EmailTemplateFormProps) {
    super(props)

    this.templateSelectRef = createRef()
  }

  componentDidMount() {
    const { talentAlertStore } = this.props

    if (this.isAgencyUser()) {
      talentAlertStore.fetchSuggestionMessageTemplates()
    }
  }

  isAgencyUser = () => {
    const { rootStore } = this.props

    return rootStore.loggedIn && !rootStore.user.isHiringManager
  }

  deleteOption = (id: number) => {
    const { talentAlertStore } = this.props

    talentAlertStore.setSuggestionTemplateMessageID(id)
    talentAlertStore.openSuggestionsMessageDeleteModal()
  }

  formatTemplateGroupLabel = (data: Record<string, any>) => {
    return (
      <div
        style={
          data.name
            ? {
                paddingTop: '5px',
                paddingBottom: '5px'
              }
            : {}
        }
      >
        <h6>
          {data.name} {data.name ? 'templates' : null}
        </h6>
      </div>
    )
  }

  editorChanged = (value: string) => {
    const { form } = this.props

    form.fields.message.update(value)
  }

  previewEmail = () => {
    const { form, talentAlertStore } = this.props

    talentAlertStore.previewEmail(form, {
      skill: [],
      location: [],
      workType: []
    })
  }

  onChangeTemplate = async (id: number) => {
    const { form, talentAlertStore } = this.props

    if (id === 0) {
      talentAlertStore.setSelectedMessageTemplate(null)
      form.fields.message.update('')

      return
    }

    const selectedMessageTemplate =
      talentAlertStore.suggestionMessageTemplates.filter(
        (message: Record<string, any>) => message.id === id
      )

    talentAlertStore.setSelectedMessageTemplate(
      selectedMessageTemplate && selectedMessageTemplate.length > 0
        ? selectedMessageTemplate[0]
        : null
    )

    if (selectedMessageTemplate && selectedMessageTemplate.length > 0) {
      await talentAlertStore.fetchMessageTemplateDetails(id)

      const msg = talentAlertStore.suggestionTemplateMessage
        .replace(/\<span class="marker">/g, '')
        .replace(/\<\/span>/g, '')
        .replace(/\<mark class="marker-yellow">/g, '')
        .replace(/\<\/mark>/g, '')
        .replace(/\<strong>/g, '')
        .replace(/\<\/strong>/g, '')
        .replace(/\<br>/g, '')
        .replace(/\<\/br>/g, '')
        .replace(/\<\/p><p>/g, '\n\n')
        .replace(/\<p>/g, '')
        .replace(/\<\/p>/g, '')
        .replace(/\&lt;/g, '<')
        .replace(/\&gt;/g, '>')

      form.fields.message.update(msg)
    }
  }

  saveTemplate = () => {
    const { form, talentAlertStore } = this.props

    talentAlertStore.setSuggestionTemplateMessage(form.fields.message.value)
    talentAlertStore.openSuggestionMessageModal()
  }

  updateMessageTemplate = (templateName: string) => {
    const { form, talentAlertStore } = this.props

    talentAlertStore.updateMessageTemplate({
      form: form,
      name: templateName
    })
  }

  render() {
    const { form, view, talentAlertStore, theme, rootStore } = this.props

    const groupedOptions = groupByTemplateType(
      // @ts-ignore TODO: fix this
      talentAlertStore.suggestionMessageTemplates
    )

    return (
      <>
        <NonFieldError error={form.fields.nonFieldErrors.value} />

        {view === 'regular' && (
          <Input fieldData={form.fields.email} label="Email" />
        )}

        {talentAlertStore.suggestionMessageTemplates &&
          talentAlertStore.suggestionMessageTemplates.length > 0 && (
            <div style={{ marginTop: '15px' }}>
              <Select
                // @ts-ignore ref type can be ignored
                ref={this.templateSelectRef}
                refariTheme={theme}
                isSearchable={false}
                options={groupedOptions}
                // @ts-ignore option can not be null
                onChange={(option) => this.onChangeTemplate(option.id)}
                // @ts-ignore need to fix type
                defaultValue={talentAlertStore.suggestionMessageTemplates[0]}
                // @ts-ignore we can ignore this warning
                components={{ Option: CustomEmailTemplateOption }}
                onMenuItemDelete={this.deleteOption}
                formatGroupLabel={this.formatTemplateGroupLabel}
                menuGroupHeadingStyles={{
                  backgroundColor: '#34302f',
                  color: '#fff'
                }}
                closeMenuOnSelect={true}
              />
            </div>
          )}

        {this.isAgencyUser() && (
          <>
            <div className="refari-input-editor-wrap">
              <Typography variant="subtitle1" component="label">
                Your message
              </Typography>
              <Editor
                placeholder="Type your message…"
                value={form.fields.message.value}
                onValueChange={(code) => this.editorChanged(code)}
                highlight={(code) => highlight(code, languages.jsx)}
                padding={10}
                className="container__editor"
                style={{
                  color: '#000000',
                  opacity: 0.8
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '15px'
              }}
            >
              {view === 'tabPanel' && (
                <RaisedButton
                  onClick={this.previewEmail}
                  color="primary"
                  className={`refari-button-raised ${
                    !form.isValid || form.isPending
                      ? 'refari-button-raised-disabled'
                      : ''
                  }`}
                  disabled={!form.isValid || form.isPending}
                  style={{ marginRight: '10px' }}
                >
                  Preview email
                </RaisedButton>
              )}

              <RaisedButton
                color="primary"
                disabled={shouldDisableMessageTemplateUpdate({
                  // @ts-ignore TODO: fix this
                  selectedMessageTemplate:
                    talentAlertStore.selectedMessageTemplate,
                  isFetchingMessageTemplateDetails:
                    talentAlertStore.isFetchingMessageTemplateDetails,
                  userRole: rootStore.user?.role
                })}
                style={{ marginRight: '10px' }}
                onClick={talentAlertStore.openMessageTemplateNameChangeModal}
              >
                Update Existing Template
              </RaisedButton>
              <RaisedButton
                color="primary"
                className={`refari-button-raised ${
                  !form.fields.message.value
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                disabled={
                  !form.fields.message.value ||
                  rootStore.isFetchingMessageTemplateDetails
                }
                onClick={this.saveTemplate}
              >
                Save as template
              </RaisedButton>
            </div>
          </>
        )}

        {/* @ts-ignore store props are injected */}
        <MessageTemplateModal />

        {/* @ts-ignore store props are injected */}
        <TemplateNameChangeModal
          updateMessageTemplate={this.updateMessageTemplate}
        />

        {/* @ts-ignore store props are injected */}
        <DeleteMessageTemplateModal
          templateSelectRef={this.templateSelectRef}
        />

        {/* @ts-ignore store props are injected */}
        <CharacterModal />

        {talentAlertStore.emailPreview && view === 'tabPanel' && (
          // @ts-ignore store props are injected
          <EmailPreviewModal />
        )}
      </>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(EmailTemplateForm)
