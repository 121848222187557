import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress
} from '@material-ui/core'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'

@observer
class NetworkTabContent extends React.Component {
  static propTypes = {
    socializeStore: PropTypes.shape({
      isOpenModal: PropTypes.bool.isRequired,
      stepIndex: PropTypes.number.isRequired,
      socialCallback: PropTypes.func.isRequired,
      initialData: PropTypes.object.isRequired
    })
  }

  constructor(props) {
    super(props)
    setTimeout(
      props.canvasCardStore.getBackgroundsList(
        props.socializeStore.videoThumbs
      ),
      500
    )

    console.log('network tab content', props)
    if (props.rootStore.agency.enableTestimonials) {
      if (props.rootStore.agency.showConsultantRating) {
        console.log('fetch for ratings', props.rootStore.user.id)
        setTimeout(() => {
          console.log('fetching ratings')
          props.rootStore.fetchRatings(props.rootStore.user.id)
        }, 300)
      }
      props.rootStore.fetchCompanyRating()
    }
  }

  setNextStep = async (canvasImage, videoCode, gifCanvasImage) => {
    const { createPost, setNextStep } = this.props.socializeStore
    const isTestimonials = this.props.canvasCardStore?.isTestimonials
    await createPost(canvasImage, videoCode, gifCanvasImage, isTestimonials)
    await setNextStep()
  }

  stepActiveStyle = (step) => {
    const {
      socializeStore: { stepIndex },
      theme
    } = this.props
    const isActive = step <= stepIndex
    return {
      color: isActive ? theme.palette.textColor : theme.palette.disabledColor
    }
  }

  render() {
    const {
      socializeStore: { stepIndex, socialCallback },
      socializeStore,
      rootStore,
      canvasCardStore,
      closeModal,
      onSuccessShare
    } = this.props

    const style = {
      StepLabel: {
        fontSize: '16px',
        fontWeight: 600,
        marginBottom: 10
      },
      StepContent: {
        borderLeft: 0,
        padding: 0,
        marginLeft: 0
      }
    }

    return canvasCardStore.customBackground ? (
      <>
        {stepIndex !== 1 ? (
          <FirstStep
            setNextStep={this.setNextStep}
            socializeStore={socializeStore}
            rootStore={rootStore}
            canvasCardStore={canvasCardStore}
            customBackground={canvasCardStore.customBackground}
          />
        ) : (
          <SecondStep
            socialCallback={socialCallback}
            closeModal={closeModal}
            socializeStore={socializeStore}
            rootStore={rootStore}
            canvasCardStore={canvasCardStore}
            onSuccessShare={onSuccessShare}
          />
        )}
      </>
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 540
        }}
      >
        <CircularProgress />
      </div>
    )

    // return canvasCardStore.customBackground ? (
    //   <Stepper
    //     activeStep={stepIndex}
    //     orientation="vertical"
    //     style={{ padding: '0 10px' }}
    //   >
    //     <Step>
    //       <StepLabel style={{ ...style.StepLabel, ...this.stepActiveStyle(0) }}>
    //         Customize Job Card
    //       </StepLabel>
    //       <StepContent
    //         className="refari-step-content"
    //         transitionDuration={0}
    //         style={style.StepContent}
    //       >
    //         <FirstStep
    //           setNextStep={this.setNextStep}
    //           socializeStore={socializeStore}
    //           rootStore={rootStore}
    //           canvasCardStore={canvasCardStore}
    //           customBackground={canvasCardStore.customBackground}
    //         />
    //       </StepContent>
    //     </Step>
    //     <Step>
    //       <StepLabel style={{ ...style.StepLabel, ...this.stepActiveStyle(1) }}>
    //         Share to Social Network
    //       </StepLabel>
    //       <StepContent className="refari-step-content" transitionDuration={0}>
    //         <SecondStep
    //           socialCallback={socialCallback}
    //           closeModal={closeModal}
    //           socializeStore={socializeStore}
    //           rootStore={rootStore}
    //           canvasCardStore={canvasCardStore}
    //           onSuccessShare={onSuccessShare}
    //         />
    //       </StepContent>
    //     </Step>
    //   </Stepper>
    // ) : (
    //   <div
    //     style={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       minHeight: 540
    //     }}
    //   >
    //     <CircularProgress />
    //   </div>
    // )
  }
}

export default withTheme(NetworkTabContent)
