import React from 'react'
import { Tooltip, withTheme } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

const TWITTER_DISABLE_MESSAGE =
  'X is currently changing API rules on a regular basis. We are currently discussing as to whether we continue supporting the X socialisation integration.'

function TwitterDisableMessageTooltip() {
  return (
    <Tooltip
      placement="bottom"
      title={TWITTER_DISABLE_MESSAGE}
      TransitionProps={{
        style: {
          backgroundColor: '#fff',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: '1px 1px 10px rgba(0,0,0,.5)',
          fontSize: 12
        }
      }}
    >
      <InfoIcon />
    </Tooltip>
  )
}

// @ts-ignore @TODO why this is showing error for functional component,
// May be passing types as generics will resolve this
export default withTheme(TwitterDisableMessageTooltip)
