/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import CloseModalButton from '../CloseModalButton'
import Modal from '../Modal'

@inject('pitchMeStore')
@observer
class PitchMeRenameTemplateModal extends React.Component {
  static propTypes = {
    pitchMeStore: PropTypes.shape({
      renameTemplateName: PropTypes.func.isRequired,
      selectedTemplate: PropTypes.object.isRequired
    }),
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    templateName: ''
  }

  handleRenameClick = async (event) => {
    event.preventDefault()
    const {
      pitchMeStore: { renameTemplateName, selectedTemplate }
    } = this.props
    if (selectedTemplate.id)
      await renameTemplateName(selectedTemplate.id, this.state.templateName)
    this.props.onClose()
  }

  render() {
    const { theme, isOpen, onClose } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }
    return (
      <Modal
        className="refari-w-modal-container modal test-me-container"
        maxWidth="xs"
        open={isOpen}
        onClose={onClose}
        classes={{
          container: 'iframe-dialog'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={onClose} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            New Template Name
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 370,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <TextField
              value={this.state.templateName}
              onChange={(event) => {
                event.preventDefault()
                this.setState({ templateName: event.target.value })
              }}
            />
            <div
              className="refari-align-items-center refari-space-around"
              style={{ justifyContent: 'space-around' }}
            >
              <Button
                variant="outlined"
                onClick={this.handleRenameClick}
                color="primary"
                style={{ marginTop: '1rem' }}
              >
                Rename
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeRenameTemplateModal)
