type Data = {
  objType?: string
  templateType?: string
}

type UserRole = 'admin' | 'manager' | 'consultant' | undefined

const canDeleteEmailTemplate = (data: Data, userRole: UserRole): boolean => {
  const { objType, templateType } = data

  if (objType === 'default' || templateType === 'system') {
    // No one can delete default or system templates
    return false
  } else if (templateType === 'company') {
    if (userRole === 'admin' || userRole === 'manager') {
      // Company and manager can delete company templates
      return true
    } else if (userRole === 'consultant') {
      // consultant cannot delete company templates
      return false
    }
  } else if (templateType === 'personal') {
    // Everyone can delete personal templates
    return true
  }

  // For invalid templateType or userRole, return false
  return false
}

export default canDeleteEmailTemplate
