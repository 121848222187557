import { computed, observable } from 'mobx'

export class Job {
  constructor(data, additionalData = {}) {
    if (!data) data = {}
    this.id = data.id
    this.title = data.title
    this.value = data.title
    this.label = data.title
    this.slug = data.slug
    this.summary = data.summary
    this.salary = new Salary(data.salary)
    this.company = data.company
    this.is_featured = data.is_featured
    this.agency = data.agency
    this.agencyRewardFee = data.agency_reward_fee
    this.consultant = data.consultant
    this.category = new Category(data.category)
    this.location = data.location
    this.workType = data.worktype
    this.postedAt = data.posted_at
    this.description = data.description
    this.isApplied = data.current_user_already_applied
    this.isActive = data.is_active
    this.hashtag = data.hashtag
    this.needQuestionnaire = data.need_questionnaire
  }
}

export default Job

export class Salary {
  period
  rateMin
  rateMax

  constructor(data) {
    if (!data) data = {}
    this.period = data.period
    this.rateMin = data.rate_min || {}
    this.rateMax = data.rate_max || {}
  }

  @computed
  get currencySign() {
    return this.rateMin.currency_sign || {}
  }

  @computed
  get minAmount() {
    return this.rateMin.amount && this.rateMin.amount.toFixed(2)
  }

  @computed
  get maxAmount() {
    return this.rateMax.amount && this.rateMax.amount.toFixed(2)
  }
}

export class Category {
  @observable id
  @observable name
  @observable parent

  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.name = data.name
    this.parent = data.parent || {}
  }

  @computed
  get breadcrumbs() {
    const initParams = {
      page: 1,
      search: '',
    }

    let data = [{
      params: {
        category: this.id.toString(),
        ...initParams,
      },
      name: this.name,
    }]

    if (this.parent.id) {
      data = [{
        params: {
          category: this.parent.id.toString(),
          ...initParams,
        },
        name: this.parent.name,
      },
      ...data,
      ]
    }
    return data
  }
}
