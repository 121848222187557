/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import PropTypes from 'prop-types'
import qp from 'query-parse'

class Loom extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isPlay: PropTypes.bool.isRequired,
  }
  render() {
    const { id, isPlay } = this.props
    const query = qp.toString({
      autoplay: isPlay ? 1 : 0,
      hide_owner: true,
      hide_share: true,
      hide_title: true,
      hideEmbedTopBar: true
    })
    return (
      <iframe
        id="player"
        type="text/html"
        title="preview"
        src={`https://www.loom.com/embed/${id}?${query}`}
        frameBorder="0"
      />
    )
  }
}

export default Loom
