/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { observer } from 'mobx-react'
import TagsInput from '../../../shared/formComponents/TagsInput'
import { helpers, selectedFields } from '../../../../utils/helpers'
import TagsSelect from '../../../shared/formComponents/TagsSelect'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import {
  Dialog,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import Select from 'src/components/shared/formComponents/Select'

function getCurrencySymbol(currency = 'USD') {
  const amount = 0
  const options = { style: 'currency', currency: currency }
  const numberFormat = new Intl.NumberFormat('en-US', options)
  if (!numberFormat) return '$'
  const parts = numberFormat.formatToParts(amount)
  return parts[0]?.value
}

function replaceAll(str, search, replacement) {
  return str.replace(new RegExp(search, 'g'), replacement)
}

function getFormatedAmount(amount = 0) {
  const userLocale = navigator.language
  // Create a new Intl.NumberFormat object with the user's locale
  const formatter = new Intl.NumberFormat(userLocale)
  // Use the formatter to format the number
  return formatter.format(amount)
}

@observer
class SubscriptionSelectForm extends React.Component {
  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      keywords: new Field({
        name: 'keywords',
        value: ''
      }),
      location: new Field({
        name: 'location',
        value: ''
      }),
      category: new Field({
        name: 'category',
        value: 'Accounting'
      }),
      worktype: new Field({
        name: 'worktype',
        value: ''
      }),
      salaryCurrency: new Field({
        name: 'salaryCurrency',
        value: ''
      }),
      minSalary: new Field({
        name: 'minSalary',
        value: ''
      }),
      maxSalary: new Field({
        name: 'maxSalary',
        value: ''
      })
    })

    Object.keys(this.form.fields).forEach((key) => {
      this.setFormData(props, key)
    })

    this.state = {
      showCurrencyErrorModal: false,
      errorField: null
    }
  }

  setFormData = (props, key) => {
    const { edmStore, setDate } = props

    let newKeyWords = []
    if (
      props.rQuery &&
      Array.isArray(props.rQuery) &&
      props.rQuery.length > 0
    ) {
      newKeyWords = [...newKeyWords, ...props.rQuery]
    }

    let data = []

    switch (key) {
      case 'keywords':
        data = newKeyWords
        break
      case 'category':
        data = selectedFields(edmStore.categoriesList)
        break
      case 'location':
        data = selectedFields(edmStore.locationsList)
        break
      case 'worktype':
        data = selectedFields(edmStore.worktypesList)
        break
      case 'salaryCurrency':
        data = selectedFields(edmStore.currencyList)
        break
      case 'minSalary':
        data = edmStore.minSalaryFilter
        break
      case 'maxSalary':
        data = edmStore.maxSalaryFilter
        break
      default:
        break
    }

    this.form.fields[key].update(data)
    setDate(this.form)
  }

  onChangeFilter = () => {
    const { setNewUrl } = this.props
    setNewUrl()
  }

  onChangeCurrency = (currency) => {
    const { edmStore } = this.props
    let updatedCurrencyList = [...edmStore.currencyList]
    if (!currency) {
      /**
       * @note If currency is not selected then set selected false for all currencies
       */
      updatedCurrencyList = updatedCurrencyList.map((item) => {
        return { ...item, selected: false }
      })
    } else {
      updatedCurrencyList = updatedCurrencyList.map((item) => {
        if (item.label === currency) {
          return { ...item, selected: true }
        }
        return { ...item, selected: false }
      })
    }

    edmStore.setData('currencyList', updatedCurrencyList)
    edmStore.setData('minSalaryFilter', undefined)
    edmStore.setData('maxSalaryFilter', undefined)
    this.form.fields.minSalary.update(undefined)
    this.form.fields.maxSalary.update(undefined)
    this.onChangeFilter()
  }

  setCurrencySelected = (currency) => {
    const { edmStore } = this.props
    let updatedCurrencyList = [...edmStore.currencyList]
    updatedCurrencyList = updatedCurrencyList.map((item) => {
      if (item.label === currency) {
        return { ...item, selected: true }
      }
      return { ...item, selected: false }
    })
    edmStore.setData('currencyList', updatedCurrencyList)
    this.form.fields.salaryCurrency.update(currency)
  }

  resetCurrencySelected = () => {
    const { edmStore } = this.props
    let updatedCurrencyList = [...edmStore.currencyList]
    updatedCurrencyList = updatedCurrencyList.map((item) => {
      return { ...item, selected: false }
    })
    edmStore.setData('currencyList', updatedCurrencyList)
    this.form.fields.salaryCurrency.update(undefined)
  }

  setMinSalaryFilter = (minSalary) => {
    if (isNaN(minSalary)) return

    const { edmStore } = this.props
    edmStore.setData('minSalaryFilter', minSalary)
    this.form.fields.minSalary.update(minSalary)
    if (this.isOnlyOneCurrencyAvailable()) {
      if (Number(minSalary)) {
        this.setCurrencySelected(edmStore.currencyList[0].label)
      }
      if (!Number(minSalary) && !Number(edmStore.maxSalaryFilter)) {
        this.resetCurrencySelected()
      }
    }

    if (
      minSalary?.trim() !== '' &&
      edmStore.maxSalaryFilter?.trim() !== '' &&
      Number(minSalary) > Number(edmStore.maxSalaryFilter)
    ) {
      this.setState({
        errorField: {
          field: 'minSalary',
          message: 'Min salary should be smaller than max salary'
        }
      })
    } else {
      this.setState({ errorField: null })
    }

    this.onChangeFilter()
  }

  setMaxSalaryFilter = (maxSalary) => {
    if (isNaN(maxSalary)) return
    const { edmStore } = this.props
    edmStore.setData('maxSalaryFilter', maxSalary)
    this.form.fields.maxSalary.update(maxSalary)
    if (this.isOnlyOneCurrencyAvailable()) {
      if (Number(maxSalary)) {
        this.setCurrencySelected(edmStore.currencyList[0].label)
      }
      if (!Number(maxSalary) && !Number(edmStore.minSalaryFilter)) {
        this.resetCurrencySelected()
      }
    }

    if (
      maxSalary?.trim() !== '' &&
      Number(maxSalary) < Number(edmStore.minSalaryFilter)
    ) {
      this.setState({
        errorField: {
          field: 'maxSalary',
          message: 'Max salary should be greater than min salary'
        }
      })
    } else {
      this.setState({ errorField: null })
    }

    this.onChangeFilter()
  }

  isOnlyOneCurrencyAvailable = () => {
    const { edmStore } = this.props
    return edmStore.currencyList.length === 1
  }

  render() {
    const { edmStore, rootStore, showSalaryFilter } = this.props

    const selectedCurrency = edmStore.currencyList.find(
      (currency) => currency.selected
    )

    return (
      <>
        <div className="m_top_2x">
          <TagsInput
            label="Keywords"
            fieldData={this.form.fields.keywords}
            creatable
            searchable
            placeholder="Select your Keywords"
            inBetweenValues="AND"
            cb={this.onChangeFilter}
          />
        </div>
        <div className="separtor-section">
          <hr className="left-line" />
          <label> AND </label>
          <hr className="right-line" />
        </div>
        <div className="m_top_2x">
          <TagsSelect
            fieldData={this.form.fields.category}
            creatable
            rootStore={rootStore}
            dataList={edmStore.categoriesList}
            searchable
            label="Category"
            valueProcessing={helpers.removeSymbols}
            optionValueKey="name"
            inBetweenValues="OR"
            cb={this.onChangeFilter}
          />
        </div>
        <div className="separtor-section">
          <hr className="left-line" />
          <label> AND </label>
          <hr className="right-line" />
        </div>
        <div className="m_top_2x">
          <TagsSelect
            fieldData={this.form.fields.location}
            creatable
            rootStore={rootStore}
            searchable
            dataList={edmStore.locationsList}
            label="Location"
            valueProcessing={helpers.removeSymbols}
            optionValueKey="name"
            inBetweenValues="OR"
            cb={this.onChangeFilter}
          />
        </div>
        <div className="separtor-section">
          <hr className="left-line" />
          <label> AND </label>
          <hr className="right-line" />
        </div>
        <div className="m_top_2x">
          <TagsSelect
            fieldData={this.form.fields.worktype}
            creatable
            rootStore={rootStore}
            searchable
            dataList={edmStore.worktypesList}
            label="Work type"
            valueProcessing={helpers.removeSymbols}
            optionValueKey="name"
            inBetweenValues="OR"
            cb={this.onChangeFilter}
          />
        </div>
        {showSalaryFilter && (
          <>
            <div className="separtor-section">
              <hr className="left-line" />
              <label> AND </label>
              <hr className="right-line" />
            </div>
            <div className="m_top_2x">
              <div style={{ width: '100%' }}>
                <label style={{ color: 'rgba(74, 74, 74, 0.6)' }}>
                  Salary Filter
                </label>
                {!this.isOnlyOneCurrencyAvailable() && (
                  <Select
                    required
                    floatingLabelFixed
                    fieldData={this.form.fields.salaryCurrency}
                    options={edmStore.currencyList}
                    optionValueKey="key"
                    optionTextKey="label"
                    style={{
                      color: 'rgba(74, 74, 74, 0.6)',
                      marginBottom: '1rem'
                    }}
                    autoClose
                    cb={this.onChangeCurrency}
                    inputVariant="outlined"
                    emptyOption="Select Currency"
                  />
                )}
              </div>
              <div
                style={{
                  padding: '0rem',
                  paddingTop: '1rem',
                  gap: '1rem',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
                onClick={(e) => {
                  e.preventDefault()
                  if (this.isOnlyOneCurrencyAvailable()) return
                  if (selectedCurrency?.label) {
                    this.setState({ showCurrencyErrorModal: false })
                  } else {
                    this.setState({ showCurrencyErrorModal: true })
                  }
                }}
              >
                <TextField
                  id="salary-range-from"
                  label="Min Salary"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  style={{ marginTop: 0 }}
                  variant="outlined"
                  value={
                    edmStore.minSalaryFilter
                      ? getFormatedAmount(edmStore.minSalaryFilter)
                      : ''
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol(selectedCurrency?.label)}
                      </InputAdornment>
                    ),
                    style: { border: 'none' }
                  }}
                  inputProps={{
                    style: {
                      border: 'none',
                      padding: '1rem',
                      paddingLeft: 0
                    }
                  }}
                  disabled={
                    !this.isOnlyOneCurrencyAvailable() &&
                    !selectedCurrency?.label
                  }
                  onChange={(event) => {
                    this.setMinSalaryFilter(
                      replaceAll('' + event.target.value, ',', '')
                    )
                  }}
                  error={this.state.errorField?.field === 'minSalary'}
                  helperText={
                    this.state.errorField?.field === 'minSalary'
                      ? this.state.errorField?.message
                      : undefined
                  }
                />

                <TextField
                  id="salary-range-to"
                  label="Max Salary"
                  fullWidth
                  margin="dense"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  style={{ marginTop: 0 }}
                  variant="outlined"
                  value={
                    edmStore.maxSalaryFilter
                      ? getFormatedAmount(edmStore.maxSalaryFilter)
                      : ''
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbol(selectedCurrency?.label)}
                      </InputAdornment>
                    ),
                    style: { border: 'none' }
                  }}
                  inputProps={{
                    style: {
                      border: 'none',
                      padding: '1rem',
                      paddingLeft: 0
                    }
                  }}
                  disabled={
                    !this.isOnlyOneCurrencyAvailable() &&
                    !selectedCurrency?.label
                  }
                  onChange={(event) => {
                    this.setMaxSalaryFilter(
                      replaceAll('' + event.target.value, ',', '')
                    )
                  }}
                  error={this.state.errorField?.field === 'maxSalary'}
                  helperText={
                    this.state.errorField?.field === 'maxSalary'
                      ? this.state.errorField?.message
                      : undefined
                  }
                />
              </div>
              <div>
                <Typography
                  style={{
                    color: 'rgba(74, 74, 74, 0.6)',
                    fontSize: '0.8rem'
                  }}
                >
                  *Salary based per Annum
                </Typography>
              </div>
            </div>
          </>
        )}
        <Dialog
          open={this.state.showCurrencyErrorModal}
          onClose={() => this.setState({ showCurrencyErrorModal: false })}
        >
          <DialogTitle>
            <Typography color="primary" variant="h5">
              Please Select a currency.
            </Typography>
          </DialogTitle>
        </Dialog>
      </>
    )
  }
}

export default SubscriptionSelectForm
