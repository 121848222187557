import React, { Component } from 'react'
import ReactTagsInput, {
  type RenderTagProps,
  type RenderInputProps
} from 'react-tagsinput'
import { toJS, observable, action } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import type { IField } from 'src/models/form/Field'
import type { Data, SavedData, ChangedFilter } from './types'
import getSelectedData from './utils/getSelectedData'
import FilterSingle from './models/FilterSingle'
import FilterGroup from './models/FilterGroup'
import TagsRenderer from './TagsRenderer'
import InputRenderer from './InputRenderer'

type TagsSelectProps = {
  field: IField
  data: Array<Data>
  inBetweenValues?: 'OR' | 'AND'
  label?: string
  required?: boolean
  savedData?: Array<SavedData>
}

type TagsSelectState = {
  value: string
  showInputTag: boolean
  openDropdown: boolean
}

@observer
class TagsSelect extends Component<TagsSelectProps, TagsSelectState> {
  static defaultProps: Partial<TagsSelectProps> = {
    required: false
  }

  removeValue = true
  transformedData: Array<InstanceType<typeof FilterSingle>> = []
  @observable alreadySelectedData: Array<string> = []
  inputElement: ReactTagsInput<string> | null = null

  constructor(props: TagsSelectProps) {
    super(props)

    this.state = {
      value: '',
      showInputTag: true,
      openDropdown: false
    }

    const { savedData } = props

    if (savedData) {
      savedData.forEach((datum) => {
        this.alreadySelectedData.push(datum.name)
      })
    }
  }

  componentDidUpdate() {
    const { data, savedData } = this.props

    if (this.transformedData.length === 0) {
      this.transformedData = data.map(
        (datum) =>
          new FilterSingle({
            savedData,
            data: datum,
            dataLength: data.length
          })
      )
    }
  }

  @action
  handleChangeFilter = (changedFilter: ChangedFilter) => {
    this.setState({ value: '' })

    const { field, inBetweenValues } = this.props

    this.removeValue = true

    const selectedData = getSelectedData({
      data: this.transformedData,
      alreadySelectedData: this.alreadySelectedData,
      changedFilter
    })

    field.update(selectedData)

    if (inBetweenValues && selectedData.length < 1) {
      this.setState({ showInputTag: true })
    }
  }

  removeChips = (
    onRemove: RenderTagProps['onRemove'],
    key: RenderTagProps['key']
  ) => {
    this.removeValue = false
    onRemove && onRemove(key)
  }

  @action
  closePopup = (event: MouseEvent) => {
    // @ts-ignore Copied from legacy Tags Select. Kept as it was.
    const path = event.path || (event.composedPath && event.composedPath())
    const idList = path.map((el: Record<string, string>) => el.id)

    if (idList.includes('refari-react-tags-select-input__modal')) {
      return null
    }

    document.body.removeEventListener('click', this.closePopup)

    const { inBetweenValues } = this.props

    if (inBetweenValues) {
      if (
        this.transformedData &&
        getSelectedData({
          data: this.transformedData,
          alreadySelectedData: this.alreadySelectedData,
          changedFilter: { operation: 'remove', filterLabel: '' }
        }).length > 0
      ) {
        this.setState({ showInputTag: false, openDropdown: false })
      } else {
        this.setState({ showInputTag: true, openDropdown: false })
      }
    } else {
      this.setState({ openDropdown: false })
    }
    return null
  }

  onFocus = () => {
    // @ts-ignore Need to have proper callback ref types
    if (this.inputElement && this.inputElement.input) {
      this.inputElement.focus()

      const { openDropdown } = this.state

      if (!openDropdown) {
        this.setState({ openDropdown: true })

        document.body.addEventListener('click', this.closePopup)
      }
    }
  }

  toggleInput = () => {
    const { showInputTag } = this.state

    if (showInputTag) {
      this.setState({ showInputTag: false })
    } else {
      this.setState({ showInputTag: true }, this.onFocus)
    }
  }

  renderTag = (parentProps: RenderTagProps) => {
    const { tag, key, onRemove, getTagDisplayValue } = parentProps
    const { field, inBetweenValues } = this.props
    const { showInputTag } = this.state

    return (
      <TagsRenderer
        key={key}
        parentKey={key}
        tag={tag}
        getTagDisplayValue={getTagDisplayValue}
        field={field}
        onRemove={onRemove}
        showInputTag={showInputTag}
        removeTags={this.removeChips}
        inBetweenValues={inBetweenValues}
        toggleInput={this.toggleInput}
      />
    )
  }

  checkList = (list: Array<InstanceType<typeof FilterSingle>>) => {
    const { value } = this.state

    return list.filter((el) =>
      el.label.toLowerCase().startsWith(value.toLowerCase())
    )
  }

  openPopup = () => {
    const { openDropdown } = this.state
    const { inBetweenValues } = this.props

    if (!openDropdown) {
      if (inBetweenValues) {
        this.setState({ showInputTag: true, openDropdown: true })
      } else {
        this.setState({ openDropdown: true })
      }
      document.body.addEventListener('click', this.closePopup)
    }
  }

  handleValueChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement

    this.setState({ value: target.value })
  }

  renderInput = (parentProps: RenderInputProps) => {
    const { openDropdown, value, showInputTag } = this.state
    const { ref, onBlur } = parentProps
    const { inBetweenValues, label } = this.props

    const displayDrodown =
      openDropdown && this.checkList(this.transformedData).length > 0

    return (
      <InputRenderer
        displayDropdown={displayDrodown}
        value={value}
        parentRef={ref}
        onBlur={onBlur}
        inBetweenValues={inBetweenValues}
        showInputTag={showInputTag}
        label={label}
        openPopup={this.openPopup}
        handleValueChange={this.handleValueChange}
        checkList={this.checkList}
        dataList={this.transformedData}
        onChangeFilter={this.handleChangeFilter}
      />
    )
  }

  changeStatus = (
    data: Array<InstanceType<typeof FilterSingle>>,
    removed: Array<string>
  ) => {
    const removedFilter = removed.toString().replace('_', ' - ')

    data.forEach((el) => {
      if (el.label === removedFilter) {
        el.toggle()
      }

      if (el.children instanceof FilterGroup && el.children.data) {
        el.children.data.forEach((item) => {
          if (item.label === removedFilter) {
            if (removedFilter === 'Other' && !item.selected) return

            item.toggle()
          }
        })
      }
    })
  }

  @action
  handleChange = (_tags: Array<string>, changed: Array<string>) => {
    const { value } = this.state
    const { field, inBetweenValues } = this.props

    if (value.length && this.removeValue && changed) {
      this.setState((prevState) => ({ value: prevState.value.slice(0, -1) }))
    } else {
      if (changed) {
        this.changeStatus(this.transformedData, changed)
      }

      this.removeValue = true

      const selectedData = getSelectedData({
        data: this.transformedData,
        alreadySelectedData: this.alreadySelectedData,
        changedFilter: {
          operation: 'remove',
          filterLabel: changed[0]
        }
      })

      field.update(selectedData)
    }

    if (
      inBetweenValues &&
      getSelectedData({
        data: this.transformedData,
        alreadySelectedData: this.alreadySelectedData,
        changedFilter: {
          operation: 'remove',
          filterLabel: changed[0]
        }
      }).length < 1
    ) {
      this.setState({ showInputTag: true })
    }
  }

  render() {
    const { field, inBetweenValues, label, required = false } = this.props

    const { value = '', serverErrors, clientErrors, isTouched } = field

    const errors = serverErrors.length
      ? serverErrors
      : isTouched && clientErrors

    return (
      <div
        className={classNames({
          'refari-field-tags-wrap': true,
          'refari-field-tags-wrap-new': Boolean(inBetweenValues)
        })}
      >
        {label && (
          <label style={{ color: 'rgba(74, 74, 74, 0.6)' }}>{`${label}${
            required ? '*' : ''
          }`}</label>
        )}
        <ReactTagsInput
          ref={(el) => (this.inputElement = el)}
          value={Array.isArray(value) ? toJS(value) : []}
          onChange={this.handleChange}
          renderTag={this.renderTag}
          renderInput={this.renderInput}
        />

        {errors && <span style={{ color: '#f44336' }}>{errors[0]}</span>}
      </div>
    )
  }
}

export default TagsSelect
