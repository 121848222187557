import { observable, action, runInAction } from 'mobx'
import { ApiRoutes } from '../utils/Urls'
import API from '../utils/API'
import Logger from '../utils/Logger'
import RootStore from './RootStore'

export class GameficationStore {
  @observable leaders = []
  @observable rules = { points: [], categories: [] }
  @observable progress = {}
  @observable adminProgress = {}
  @observable isOpenBadgesModal = false
  @observable selectedBadge = null
  @observable selectedBadgeItem = null
  @observable aliasLevel = {}
  @observable datesOfAchievement = null

  @action loadLeaders = async () => {
    try {
      const res = await API.getData(ApiRoutes.gamefication.leaders)
      runInAction(() => {
        this.leaders = res.data
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action loadProgress = async () => {
    try {
      await RootStore.fetchAgencyData()
      const res = await API.getData(ApiRoutes.gamefication.progress)
      runInAction(() => {
        this.progress = res.data
      })
    } catch (error) {
      if (error.request.status === 403) {
        window.location.pathname = '/404/'
      }
      Logger.error(error)
    }
  }

  @action loadAdminProgress = async () => {
    try {
      const res = await API.getData(
        ApiRoutes.dashboard.systemAdmin.gamification.progress
      )
      runInAction(() => {
        this.adminProgress = res.data
      })
    } catch (error) {
      if (error.request.status === 403) {
        window.location.pathname = '/404/'
      }
      Logger.error(error)
    }
  }

  @action loadAliasLevel = async (category_alias, level, page_value) => {
    try {
      const res = await API.getData(
        ApiRoutes.dashboard.systemAdmin.gamification.aliasLevel(
          category_alias,
          level,
          page_value
        )
      )
      runInAction(() => {
        this.aliasLevel = res.data
      })
    } catch (error) {
      if (error.request.status === 403) {
        window.location.pathname = '/404/'
      }
      Logger.error(error)
    }
  }

  @action loadRepeatableCategoryDatesOfAchievement = async (
    category_alias,
    level,
    progress_id,
    page_value
  ) => {
    try {
      this.datesOfAchievement = null
      const res = await API.getData(
        ApiRoutes.dashboard.systemAdmin.gamification.aliasLevelDatesOfAchievement(
          category_alias,
          level,
          progress_id,
          page_value
        )
      )
      runInAction(() => {
        this.datesOfAchievement = res.data
      })
    } catch (error) {
      if (error.request.status === 403) {
        window.location.pathname = '/404/'
      }
      Logger.error(error)
    }
  }
  @action loadListOfDatesOfAchievements = async (
    category_alias,
    level,
    page_value
  ) => {
    try {
      this.datesOfAchievement = null
      const res = await API.getData(
        ApiRoutes.gamefication.listDatesOfAchievement(
          category_alias,
          level,
          page_value
        )
      )
      runInAction(() => {
        this.datesOfAchievement = res.data
      })
    } catch (error) {
      Logger.error(error)
    }
  }

  @action.bound
  toggleBadgesModal() {
    this.isOpenBadgesModal = !this.isOpenBadgesModal
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new GameficationStore()
