import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject } from 'mobx-react'
import { baseUrls } from '../../utils/Urls'

@inject('history')
class Error extends React.PureComponent {
  constructor(props) {
    super(props)
    if (window.history.state === 'prevPageError') {
      window.history.back()
      window.history.replaceState(null, '')
    } else {
      window.history.replaceState('prevPageError', '500')
    }
  }

  render() {
    const { theme } = this.props
    const styles = {
      colorWhite: { color: theme.palette.whiteTextColor },
      colorPrimary: { color: theme.palette.primary1Color },
      backgroundPrimary: { background: theme.palette.primary1Color },
    }

    return (
      <div
        style={styles.backgroundPrimary}
        className="error flex"
      >
        <div className="container align-items-center">
          <div className="full-width row middle-md start-md center-sm start-xs">
            <div className="col-md-6 col-sm-12 col-xs-12 error-left">
              <h1
                style={styles.colorWhite}
              >
                Maintenance.
              </h1>
              <div className="flex start-md center-sm start-xs">
                <div className="col-md-9 col-sm-6 col-xs-12">
                  <h3
                    style={styles.colorWhite}
                  >
                    We're undergoing a bit of scheduled maintenance.
                  </h3>
                  <h4
                    style={styles.colorWhite}
                  >
                    Sorry for the inconvenience. We'll back up and running as fast as possible.
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 center-md error-right">
              <img
                src={`${baseUrls.static}error.png`}
                alt="Home"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(Error)
