import React from 'react'
import PropTypes from 'prop-types'
import {
  Clear
} from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const propTypes = {
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

const CloseButton = ({ onClose }) => (
  <div className="modal-close-btn">
    <IconButton
      onClick={onClose}
      type="button"
    >
      <Clear />
    </IconButton>
  </div>
)

export default CloseButton
CloseButton.propTypes = propTypes
CloseButton.defaultProps = defaultProps
