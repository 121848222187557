/* eslint-disable camelcase */
import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import { CircularProgress } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('billingStore')
@inject('agenciesStore')
@inject(({ gameficationStore }) => ({
  loadLeaders: gameficationStore.loadLeaders,
  loadProgress: gameficationStore.loadProgress
}))
@inject('rootStore')
@observer
class Badges extends React.Component {
  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.billingStore.initTab(pathname)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.history.location
    this.props.billingStore.onTabChange(pathname)
  }

  componentDidMount() {
    this.props.loadLeaders()
    this.props.loadProgress()
    this.props.rootStore.updateProgress(false)
  }

  onTabChange = (link) => {
    this.props.agenciesStore.progressNew()
    this.props.billingStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props
    return (
      <div className="badges">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="full-container">
            <Switch>
              <PrivateRoute
                exact
                path={`${match.url}${URLs.badges.children.allBadges.path}`}
                component={asyncComponent(() => import('./AllBadges'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.badges.children.yourProgress.path}`}
                component={asyncComponent(() => import('./Progress'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.badges.children.agencyLeaderboard.path}`}
                component={asyncComponent(() => import('./AgencyLeaderboard'))}
              />
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Badges)
