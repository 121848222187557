/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import {
  inject,
  observer,
} from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import classNames from 'classnames'
import ParticlesBackground from '../shared/common/ParticlesBackground'
import Background from '../shared/common/Background'
import routesUrls from '../../router/routesUrls'

@inject('rootStore')
@inject('dashboardStore')
@inject('authStore')
@observer
class Home extends React.Component {
  static propTypes = {
    rootStore: PropTypes.shape({
      fetchRestrictedAgencyData: PropTypes.func.isRequired,
      loggedIn: PropTypes.bool,
      agency: PropTypes.shape({
        name: PropTypes.string
      }),
      authStore: PropTypes.shape({
        toggleModal: PropTypes.func.isRequired,
      }),
      dashboardStore: PropTypes.shape({
        dashboardPermissions: PropTypes.array,
      }),
    }),
  }

  UNSAFE_componentWillMount() {
    const { history, match, rootStore } = this.props
    rootStore.fetchRestrictedAgencyData(match.params.slug, () => {
      history.replace(routesUrls.error.link)
    })
  }

  componentWillUnmount() {
    const { rootStore } = this.props
    rootStore.setAgencyBranding(rootStore.user.branding)
  }

  renderCTA(userType) {
    switch (userType) {
      case 'referrer':
        return 'Manage your account to start exploring the possibility of the platform'
      case 'agency':
        return 'Proceed to the platform and start exploring'
      default:
        return 'Create an account or sign in to manage your job, applications, referrals, subscriptions and more'
    }
  }

  redirectToDashboard = () => {
    const { dashboardStore, history } = this.props
    history.push(dashboardStore.dashboardPermissions[0].link)
  }

  render() {
    const {
      theme,
      rootStore,
      authStore: { toggleModal },
    } = this.props
    const { loggedIn } = rootStore
    const styles = {
      colorWhite: { color: theme.palette.whiteTextColor },
      borderWhite: { borderColor: theme.palette.whiteTextColor },
      backgroundPrimary: { background: theme.palette.primary1Color },
    }

    return (
      <div
        style={styles.backgroundPrimary}
        className="welcome with-particle-background flex"
      >
        <Background
          className="welcome-background"
          containerStyle={styles.backgroundPrimary}
          style={styles.backgroundPrimary}
        />
        {
          rootStore.agency.name
          && <ParticlesBackground />
        }
        <div className="container align-items-center">
          <div className="full-width row center-xs">
            <div className="col-xs-12 col-md-6 welcome-block">
              <h1
                style={styles.colorWhite}
                className={classNames({
                  show: !!rootStore.agency.name
                })}
              >

                {`Welcome to ${rootStore.publicAgency.name}'s jobs and refer a friend platform`}
              </h1>
              <div className="flex center-xs">
                <div className="col-md-10 col-sm-6">
                  <h3
                    style={styles.colorWhite}
                  >
                    { this.renderCTA(rootStore.user.userType) }
                  </h3>
                  <div className={classNames('welcome__buttons align-items-center space-between')}>
                    {
                      loggedIn
                      && <Button
                        onClick={this.redirectToDashboard}
                        variant="contained"
                        style={{ color: theme.palette.primary1Color }}
                      >
                        Go to dashboard
                      </Button>
                    }
                    {
                      !loggedIn
                      && <Button
                        variant="contained"
                        style={{ color: theme.palette.primary1Color }}
                        onClick={toggleModal}
                      >
                        Sign In / Sign Up
                      </Button>
                    }
                    <Button
                      href={rootStore.publicAgency.widgetBaseUrl}
                      variant="contained"
                      style={{ color: theme.palette.primary1Color }}
                    >
                      Go back to our job board
                    </Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTheme(Home)
