/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import ReactTagsInput from 'react-tagsinput'
import {
  TextField,
  IconButton,
  Chip,
  InputBase,
  InputAdornment
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import Field from '../../../models/form/Field'
import greenTick from '../../../assets/images/green_tick.svg'
import { baseURLs } from '../../../utils/Urls'

/**
 * @todo when user clicks outside then save the changes
 * @todo when user type that the final change
 * @todo get rid of tick icon
 * @todo cross is always delete.
 * @todo debug onDrag on Questions Set.
 * @todo implement new way to add values.
 */
class CustomChip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isChipEdit: false,
      editValue: '',
      defaultValue: this.props.label
    }
    this.timer = null
    this.deleteInvoked = false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.label !== state.defaultValue)
      return { ...state, isChipEdit: false, defaultValue: props.label }
    // if (props.label === state.editValue) return null
    return state
  }

  chipEditOnClick = () => {
    this.setState({
      isChipEdit: true,
      editValue: this.props.label
    })
  }

  onSave = () => {
    this.timer = setTimeout(() => {
      this.setState({
        ...this.state,
        isChipEdit: false
      })
      this.props.onSave(this.state.editValue)
    }, 300)
  }

  onDelete = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.props.onDelete()
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer)
  }

  render() {
    const { style, label, iconColor, editableChip, ...other } = this.props
    if (this.timer) clearTimeout(this.timer)
    if (!this.state.isChipEdit || !editableChip) {
      return (
        <Chip
          style={style}
          onDelete={this.onDelete}
          {...other}
          label={label}
          clickable
          classes={{
            label: 'cursor-text'
          }}
          onClick={() => {
            this.chipEditOnClick()
          }}
        />
      )
    }

    return (
      <InputBase
        // defaultValue={this.props.label}
        value={this.state.editValue}
        onChange={(event) =>
          this.setState({ ...this.state, editValue: event.target.value })
        }
        inputProps={{
          style: {
            padding: '0px 12px',
            height: '32px'
          }
        }}
        autoFocus
        fullWidth
        onBlur={this.onSave}
        style={{
          borderRadius: '16px',
          ...style
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className="add-tag-button"
              disableRipple
              style={{ padding: '0px', paddingRight: '8px', color: iconColor }}
              onClick={this.onDelete}
            >
              <CancelIcon width="8" height="8" />
            </IconButton>
          </InputAdornment>
        }
      />
    )
  }
}

@observer
class TagsInput extends React.Component {
  constructor(props) {
    super(props)
    this.inputElement = React.createRef()
    this.state = {
      showInputTag: true,
      inputFocus: false
    }
  }

  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    cb: PropTypes.func,
    getChipBackgroundColor: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    labelColor: PropTypes.string,
    valueProcessing: PropTypes.func,
    placeholder: PropTypes.string,
    inBetweenValues: PropTypes.string,
    showTicks: PropTypes.bool,
    floatingLabel: PropTypes.bool,
    editableChip: PropTypes.bool,
    creatable: PropTypes.bool,
    searchable: PropTypes.bool,
    onRemoveCb: PropTypes.func
  }

  static defaultProps = {
    className: '',
    valueProcessing: (value) => value,
    placeholder: '',
    inBetweenValues: '',
    showTicks: false,
    floatingLabel: false,
    editableChip: false,
    creatable: false,
    searchable: false
  }

  onBlur = (e, blurFnc) => {
    blurFnc && blurFnc(e)
    const { value } = this.props.fieldData
    const { floatingLabel } = this.props
    let inputFocusValue = false
    if (
      floatingLabel &&
      this.inputElement &&
      this.inputElement.state &&
      this.inputElement.input
    ) {
      inputFocusValue = !!(
        document.activeElement === this.inputElement.input ||
        this.inputElement.state.tag ||
        (value && toJS(value).length > 0)
      )
    }

    if (
      this.props.inBetweenValues !== undefined &&
      this.props.inBetweenValues !== ''
    ) {
      if (value && toJS(value).length > 0) {
        this.setState({ showInputTag: false, inputFocus: inputFocusValue })
      } else {
        this.setState({ showInputTag: true, inputFocus: inputFocusValue })
      }
    } else if (floatingLabel) {
      this.setState({ inputFocus: inputFocusValue })
    }
    const { onInputBlur } = this.props
    onInputBlur && onInputBlur()
  }

  onFocusInput = () => {
    const { floatingLabel, onInputFocus } = this.props
    if (floatingLabel && !this.state.inputFocus) {
      this.setState({ inputFocus: true })
    }
    onInputFocus && onInputFocus()
  }

  onChange = (value) => {
    const { floatingLabel } = this.props
    if (
      this.props.inBetweenValues !== undefined &&
      this.props.inBetweenValues !== ''
    ) {
      if (value && toJS(value).length < 1) {
        this.setState({ showInputTag: true })
      }
    }

    const nonEmptyValues = value.filter((v) => v)

    this.props.fieldData.update(nonEmptyValues)

    this.props.cb && this.props.cb()

    if (floatingLabel && value && toJS(value).length < 1) {
      this.onBlur()
    }
  }

  toggleInput = () => {
    if (this.state.showInputTag) {
      this.setState({ showInputTag: false })
    } else {
      this.setState({ showInputTag: true }, this.onFocus)
    }
  }

  onFocus = () => {
    if (this.inputElement && this.inputElement.input) {
      this.inputElement.focus()
    }
  }

  renderTag = (props) => {
    const { primary1Color } = this.props.theme.palette
    const { getChipBackgroundColor, onRemoveCb } = this.props
    const {
      tag,
      key,
      onRemove,
      getTagDisplayValue,
      inBetweenValues,
      ...other
    } = props
    const { value } = this.props.fieldData
    const { editableChip } = this.props

    let showBetweenOp = true

    if (value && toJS(value).length > 0 && toJS(value).length - 1 === key) {
      showBetweenOp = false
    }

    const onSave = (newValue) => {
      const newFieldData = toJS(this.props.fieldData?.value)?.map(
        (item, index) => {
          if (index === key) return newValue
          return item
        }
      )

      this.onChange(newFieldData)
    }

    const customChipColor =
      typeof getChipBackgroundColor === 'function' &&
      getChipBackgroundColor(tag)

    return (
      <React.Fragment key={key}>
        <div
          key={key}
          className={
            this.props.inBetweenValues !== undefined &&
            this.props.inBetweenValues !== ''
              ? 'refari-react-tagsinput-tag-wrap refari-react-tagsinput-tag-wrap-new'
              : 'refari-react-tagsinput-tag-wrap'
          }
        >
          <CustomChip
            key={key}
            style={{
              cursor: editableChip ? 'text' : 'pointer',
              color: '#fff',
              background: customChipColor
                ? customChipColor
                : this.props.inBetweenValues !== undefined &&
                  this.props.inBetweenValues !== ''
                ? '#65707b'
                : primary1Color
            }}
            onDelete={() => {
              onRemove(key)
              onRemoveCb && onRemoveCb(tag)
            }}
            {...other}
            label={getTagDisplayValue(tag)}
            iconColor={primary1Color || '#000'}
            onSave={onSave}
            editableChip={this.props.editableChip}
          />
          {this.props.inBetweenValues !== undefined &&
            this.props.inBetweenValues !== '' &&
            showBetweenOp && (
              <span
                className={
                  this.props.inBetweenValues.toLowerCase() === 'or'
                    ? 'refari-react-tagsinput-between-op refari-react-tagsinput-between-op-or'
                    : 'refari-react-tagsinput-between-op'
                }
              >
                {this.props.inBetweenValues}
              </span>
            )}
        </div>
        {this.props.inBetweenValues !== undefined &&
          this.props.inBetweenValues !== '' &&
          !showBetweenOp &&
          !this.state.showInputTag && (
            <div className="refari-react-tagsinput-tag-add-button">
              <IconButton
                className="add-tag-button"
                onClick={this.toggleInput}
                style={{ color: primary1Color || '#000' }}
              >
                <AddIcon
                  width="35"
                  height="35"
                  style={{ width: 30, height: 30 }}
                  fill={primary1Color || '#000'}
                />
              </IconButton>
            </div>
          )}
      </React.Fragment>
    )
  }

  renderInput = (props) => {
    const {
      valueProcessing,
      label,
      placeholder,
      showTicks,
      fieldData,
      floatingLabel,
      theme,
      labelColor
    } = this.props
    const { onChange, onBlur, onFocus, ref, value, ...other } = props
    const { inputFocus } = this.state
    return (
      <>
        {floatingLabel && (
          <label
            className="refari-react-tagsinput-input-floating-label"
            style={
              inputFocus ||
              (fieldData.value && toJS(fieldData.value).length > 0)
                ? {
                    transform: 'scale(0.75) translate(0px, -28px)',
                    color: labelColor || theme.palette.primary1Color
                  }
                : {
                    color: labelColor || '#0000008a'
                  }
            }
          >
            {label || placeholder}
          </label>
        )}
        <div
          style={
            this.state.showInputTag
              ? { display: 'inline-block' }
              : { display: 'none' }
          }
          className={
            this.props.inBetweenValues !== undefined &&
            this.props.inBetweenValues !== ''
              ? 'refari-react-tagsinput-input refari-react-tagsinput-input-new'
              : `${
                  showTicks
                    ? 'refari-react-tagsinput-input refari-react-tagsinput-input-tick'
                    : 'refari-react-tagsinput-input'
                }`
          }
        >
          <TextField
            ref={ref}
            onChange={onChange}
            onBlur={(e) => this.onBlur(e, onBlur)}
            onFocus={this.onFocusInput}
            name="text-field-input"
            value={valueProcessing(value)}
            autoComplete="off"
            inputProps={{
              form: {
                autoComplete: 'off'
              }
            }}
            style={{
              whiteSpace: 'nowrap',
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            fullWidth
            placeholder={floatingLabel ? '' : placeholder}
            {...other}
          />
          {showTicks && fieldData.value && toJS(fieldData.value).length > 0 && (
            <div className="refari-react-tagsinput-input-right-block">
              <span className="refari-react-tagsinput-input-right-icon">
                <img
                  src={`${baseURLs('widget').replace(/\/$/, '')}${greenTick}`}
                  alt="tick"
                />
              </span>
            </div>
          )}
        </div>
      </>
    )
  }

  render() {
    const {
      fieldData,
      label,
      className,
      inBetweenValues,
      floatingLabel,
      placeholder,
      labelColor
    } = this.props
    const { serverErrors, clientErrors, isTouched, value } = fieldData
    const errors = serverErrors.length
      ? serverErrors
      : isTouched && clientErrors

    return (
      <div
        className={`refari-field-tags-wrap ${
          inBetweenValues !== undefined && inBetweenValues !== ''
            ? 'refari-field-tags-wrap-new '
            : ''
        }${
          floatingLabel ? 'refari-field-tags-wrap-floating ' : ''
        }${className}`}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
        {label && !floatingLabel && (
          <label
            className="refari-field-tags-title"
            style={{ color: labelColor || 'rgba(74, 74, 74, 0.6)' }}
          >
            {label}
          </label>
        )}
        <ReactTagsInput
          ref={(el) => (this.inputElement = el)}
          value={toJS(value || [])}
          onChange={this.onChange}
          renderTag={this.renderTag}
          renderInput={this.renderInput}
          addOnBlur
          inputProps={{ placeholder: floatingLabel ? '' : placeholder }}
          addKeys={[9, 13, 188]}
        />
        <span>{errors[0]}</span>
      </div>
    )
  }
}

export default withTheme(TagsInput)
