/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Typography, Box } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const CircularProgressWithLabel = (props) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
    {props.text && (
    <Typography
      variant="subtitle1"
      component="div"
      style={{ marginLeft: 10, whiteSpace: 'nowrap' }}
    >
      {props.text}
    </Typography>
    )}
  </div>
)

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
}

export default withTheme(CircularProgressWithLabel)
