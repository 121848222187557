import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ToolsIcon = (props) => {
  const style = {
    width: '24px',
    height: '24px',
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 24 24"
    >
      <path
        d="M21.71 20.29L20.29 21.71C20.1026 21.8962 19.8492 22.0008 19.585 22.0008C19.3208 22.0008 19.0674 21.8962 18.88 21.71L7 9.85C6.67481 9.94421 6.33852 9.99465 6 10C5.3633 9.99955 4.73591 9.84711 4.16999 9.55536C3.60407 9.26362 3.11596 8.84099 2.74626 8.32263C2.37656 7.80426 2.13594 7.20513 2.04441 6.57505C1.95287 5.94496 2.01307 5.30213 2.22 4.7L4.76 7.24L5.29 6.71L6.71 5.29L7.24 4.76L4.7 2.22C5.30213 2.01307 5.94496 1.95287 6.57505 2.04441C7.20513 2.13594 7.80426 2.37656 8.32263 2.74626C8.84099 3.11596 9.26362 3.60407 9.55536 4.16999C9.84711 4.73591 9.99954 5.3633 10 6C9.99465 6.33852 9.94421 6.67481 9.85 7L21.71 18.88C21.8962 19.0674 22.0008 19.3208 22.0008 19.585C22.0008 19.8492 21.8962 20.1026 21.71 20.29ZM2.29 18.88C2.10375 19.0674 1.99921 19.3208 1.99921 19.585C1.99921 19.8492 2.10375 20.1026 2.29 20.29L3.71 21.71C3.89736 21.8962 4.15081 22.0008 4.415 22.0008C4.67918 22.0008 4.93264 21.8962 5.12 21.71L10.59 16.25L7.76 13.42L2.29 18.88ZM20 2L16 4V6L13.83 8.17L15.83 10.17L18 8H20L22 4L20 2Z"
        fill="#757575"
      />
    </SvgIcon>
  )
}


export default ToolsIcon
