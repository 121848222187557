import React, { Component, createRef } from 'react'
import ReactSelect, {
  type ActionMeta,
  type SingleValue,
  components as selectComponents
} from 'react-select'

import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'

export type Option = {
  id: number | string
  name: string
}

export type GroupedOption = {
  name: string
  options: Array<Option>
}

type MenuGroupHeadingStyles = {
  backgroundColor: string
  color: string
}

type SelectProps = {
  isSearchable: boolean
  options: Array<Option> | Array<GroupedOption>
  onChange: (option: SingleValue<Option>, action: ActionMeta<Option>) => void
  defaultValue?: Option
  onMenuItemDelete?: (id: number) => void
  formatGroupLabel?: (data: GroupedOption) => React.ReactNode
  components?: typeof selectComponents
  refariTheme: MUIThemeFuncReturnType
  menuGroupHeadingStyles?: MenuGroupHeadingStyles
  controlStyles?: React.CSSProperties
  optionStyles?: React.CSSProperties
  optionSelectedStyles?: React.CSSProperties
  optionHoverStyles?: React.CSSProperties
  optionStateDataKeysWithStyles?: { [key: string]: React.CSSProperties }
  defaultMenuIsOpen?: boolean
  placeholder?: string
  id?: string
  // For some feature we might need refari form for inner custom
  // components. Do not use this to provide value inside
  // this component.
  form?: Record<string, any>
  isLoading?: boolean
  openMenuOnFocus?: boolean
  closeMenuOnSelect?: boolean
}

class Select extends Component<SelectProps> {
  reactSelectRef: Record<string, any>

  constructor(props: SelectProps) {
    super(props)

    this.reactSelectRef = createRef()
  }

  render() {
    const {
      isSearchable,
      options,
      onChange,
      defaultValue,
      onMenuItemDelete,
      formatGroupLabel,
      components,
      refariTheme,
      menuGroupHeadingStyles = {},
      controlStyles = {},
      optionStyles = {},
      optionHoverStyles = {},
      optionSelectedStyles = {},
      defaultMenuIsOpen = false,
      optionStateDataKeysWithStyles = {},
      placeholder,
      id,
      form,
      isLoading = false,
      openMenuOnFocus = true,
      closeMenuOnSelect = false
    } = this.props

    const optionStateDataKeys = Object.keys(optionStateDataKeysWithStyles)

    return (
      <ReactSelect
        // @ts-ignore ref type can be ignored
        ref={this.reactSelectRef}
        isLoading={isLoading}
        closeMenuOnSelect={closeMenuOnSelect}
        id={id}
        placeholder={placeholder}
        defaultMenuIsOpen={defaultMenuIsOpen}
        openMenuOnFocus={openMenuOnFocus}
        refariTheme={refariTheme}
        isSearchable={isSearchable}
        options={options}
        getOptionLabel={(option) => option.name}
        // @ts-ignore type number works for us
        getOptionValue={(option) => option.id}
        // @ts-ignore no idea what is going on here
        onChange={onChange}
        defaultValue={defaultValue}
        onMenuItemDelete={onMenuItemDelete}
        formatGroupLabel={formatGroupLabel}
        components={components}
        // @ts-ignore we can ignore here
        form={form}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
            color: '#000000'
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            borderBottom: `1px solid #949494`,
            borderRadius: 'none',
            cursor: 'pointer',
            ...controlStyles,
            '&:hover': {
              borderBottomColor: refariTheme.palette.primary1Color
            },
            ...(state.isFocused && {
              boxShadow: 'none',
              borderBottomWidth: '2px',
              borderBottomColor: refariTheme.palette.primary1Color
            })
          }),
          groupHeading: (baseStyles) => ({
            ...baseStyles,
            ...menuGroupHeadingStyles
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            paddingLeft: 0
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none'
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            ...optionStyles,
            '&:hover': {
              backgroundColor: '#eee',
              cursor: 'pointer',
              ...optionHoverStyles
            },
            ...(state.isSelected && {
              backgroundColor: '#f2f2f2',
              ...optionSelectedStyles
            }),
            ...optionStateDataKeys.reduce((acc, key) => {
              const styles = optionStateDataKeysWithStyles[key]

              return {
                ...acc,
                // @ts-ignore we can ignore here
                ...(state.data[key] && styles)
              }
            }, {})
          })
        }}
      />
    )
  }
}

export default Select
