import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import hexToRgba from 'hex-to-rgba'

import Modal from '../../../../shared/modals/Modal'
import QuotesIcon from '../../../../shared/icons/QuotesIcon'
import EndQuotesIcon from '../../../../shared/icons/EndQuotesIcon'
import StarIcon from '../../../../shared/icons/StarIcon'
import AvatarPlaceholder from '../../../../shared/common/AvatarPlaceholder'
import TopRecLogo from '../../../../../assets/images/TopRecLogo.svg'

import './styles.styl'

// eslint-disable-next-line react/prefer-stateless-function
class SmartPitchTestimonialDetailsModal extends Component {
  static propTypes = {
    theme: PropTypes.object.isRequired,
    userData: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      avatarAlternative: PropTypes.string.isRequired
    }).isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    testimonialDetails: PropTypes.shape({
      open: PropTypes.bool,
      testimonialDetails: PropTypes.shape({
        text: PropTypes.string,
        isVerified: PropTypes.bool,
        authorFirstName: PropTypes.string,
        authorLastName: PropTypes.string,
        jobTitle: PropTypes.string,
      })
    }).isRequired
  }

  render() {
    const {
      theme,
      open,
      onClose,
      testimonialDetails,
      userData
    } = this.props

    const {
      text,
      isVerified,
      authorFirstName,
      authorLastName,
      jobTitle
    } = testimonialDetails

    const {
      fullName,
      avatar,
      avatarAlternative
    } = userData

    return (
      <Modal
        open={open}
        onClose={onClose}
        className="smartPitchTestimonialDetailsModal"
        style={{
          '--smartPitchTestimonialDetailsModal-testimonialTextSection-bacground-color': hexToRgba(
            theme.palette.primary1Color,
            0.1
          )
        }}
      >
        <div className="smartPitchTestimonialDetailsModal-testimonialTextSection">
          <Box alignSelf="end">
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="smartPitchTestimonialDetailsModal-testimonialQuotedText">
            <Box className="smartPitchTestimonialDetailsModal-testimonialQuotedText-quotesIcon">
              <QuotesIcon />
            </Box>
            <p className="mt-20 mt-20 ml-40 mr-40">{text}</p>
            <Box className="smartPitchTestimonialDetailsModal-testimonialQuotedText-endQuotesIcon">
              <EndQuotesIcon />
            </Box>
          </Box>
          <Box className="ml-40 mr-40">
            <p className="m-0">
              {`- ${authorFirstName} ${authorLastName}`}
            </p>
            {jobTitle && <p className="m-0 text-14 smartPitchTestimonialDetailsModal-jobtitle">{jobTitle}</p>}
          </Box>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classNames({
            'mb-40 smartPitchTestimonialDetailsModal-avatarRatingsSection': true,
            'smartPitchTestimonialDetailsModal-avatarRatingsSection-topMargin': Boolean(avatar)
          })}
        >
          {avatar && (
            <AvatarPlaceholder
              className="refari-show-alternative"
              src={avatar}
              altsrc={avatarAlternative}
              alt={fullName}
            />
          )}
          {isVerified && (
          <Box display="flex">
            {[0, 1, 2, 3, 4].map((star) => (
              <StarIcon key={star} style={{ height: '17px', width: '17px' }} />
            ))}
          </Box>
          )}
          <p className={`${!isVerified && 'mt-15'} m-0 text-16 mb-5 smartPitchTestimonialDetailsModal-avatarRatingsSection-fullNameAdditional`}>
            Quote about:
          </p>
          <p className="m-0 text-20 font-bold">{fullName}</p>
        </Box>
        <Box display="flex" justifyContent="center">
          <span className="mr-5 smartPitchTestimonialDetailsModal-verificationText">Independently verified by{' '}</span>
          <a href="https://toprec.co" target="_blank" rel="noreferrer">
            <img
              src={TopRecLogo}
              width="45.43"
              height="18"
              alt="TopRec"
            />
          </a>
        </Box>
      </Modal>
    )
  }
}

export default SmartPitchTestimonialDetailsModal
