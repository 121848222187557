/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Image, Group } from 'react-konva'

export default class TestimonialCanvasCircleImage extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.image = new window.Image()
    this.image.setAttribute('crossOrigin', 'anonymous')
    // hack - safari can't draw an image that is not loaded yet
    this.image.src = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
    this.state = {
      width: 57,
      height: 57,
      x: 200,
      y: 25
    }
  }

  componentDidMount() {
    this.image.src = this.props.imageUrl
    this.image.onload = () => {
      if (this.refs.imageNode) {
        this.refs.imageNode.getLayer().batchDraw()
      }
    }
    this.setState({
      width: this.props.width,
      height: this.props.height,
      x: this.props.x,
      y: this.props.y
    })
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      width: props.width,
      height: props.height,
      x: props.x,
      y: props.y
    })
    this.image.src = props.imageUrl
    this.image.onload = () => {
      if (this.refs.imageNode) {
        this.refs.imageNode.getLayer().batchDraw()
      }
    }
  }

  clipFunc = (ctx) => {
    const { width, height } = this.state
    ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, false)
  }

  render() {
    const {
      width, height, x, y
    } = this.state
    const { isCircle } = this.props
    return (
      <Group
        clipFunc={isCircle ? this.clipFunc : null}
        draggable={false}
        x={x}
        y={y}
      >
        <Image
          image={this.image}
          width={width}
          height={height}
          ref="imageNode"
          x={0}
          y={0}
        />
      </Group>
    )
  }
}
