import {
  observable,
  action,
} from 'mobx'
import { toast } from 'react-toastify'
import { ApiRoutes } from '../utils/Urls'
import API from '../utils/API'
import Logger from '../utils/Logger'
import { mapFormServerErrors } from '../utils/helpers'
import alertMessages from '../constants/alertMessages'


class RequestStore {
  @observable isOpenModal = false
  @observable.ref requestData = {}

  @action
  toggleModal = () => {
    this.isOpenModal = !this.isOpenModal
  }

  @action
  setRequestData = (data = {}) => {
    this.requestData = data
  }

  @action
  updateUserData = async (form) => {
    try {
      const data = {
        name: form.data.name,
        email: form.data.email,
        company_name: form.data.company_name,
        crm_type: form.data.crm_type,
        phone: form.data.phone,
        is_agree: form.data.is_agree,
      }

      if (form.data.hear_about) {
        data.hear_about = form.data.hear_about
      }

      if (form.data.consultant_count) {
        data.consultant_count = form.data.consultant_count
      }

      const response = await API.postData(ApiRoutes.demo.request.create, data)
      if (response.response && response.response.data) {
        if (response.response.data.phone) {
          toast.error(response.response.data.phone)
        }
        return
      }
      toast.success(alertMessages.requestDemo)
      this.setRequestData()
      this.toggleModal()
    } catch (error) {
      Logger.error(error, 'updateUserData')
      mapFormServerErrors(error.data, form.fields)
    }
  }
}

export default new RequestStore()
