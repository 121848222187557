/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Button } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const RaisedButton = ({
  children, style, theme, ...restProps
}) => (
  <Button
    variant="contained"
    {...restProps}
    style={{
      ...style,
      borderRadius:
          style && style.borderRadius
            ? style.borderRadius
            : theme.raisedButton.borderRadius
    }}
  >
    {children}
  </Button>
)

export default withTheme(RaisedButton)
