import React from 'react'
import { SvgIcon } from '@material-ui/core'

const EndQuotesIcon = (props) => {
  const style = {
    width: '60px',
    height: '28px',
    ...props.style
  }
  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={style}
      // viewBox="0 0 38.616 31.802"
      viewBox="0 0 10 30"

    >
      <path
        id="endQuotes"
        d="M144.666,97.8v15.9h-15.9V97.8h9.086a9.087,9.087,0,0,0-9.086-9.086V81.9a15.9,15.9,0,0,1,15.9,15.9ZM106.05,88.713V81.9a15.9,15.9,0,0,1,15.9,15.9v15.9h-15.9V97.8h9.086a9.087,9.087,0,0,0-9.086-9.086Z"
        transform="translate(-106.05 -81.898)"
        fillRule="evenodd"
        fill={props.fill}
      />
    </SvgIcon>
  )
}

export default EndQuotesIcon
