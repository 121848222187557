import React, { useState } from 'react'
import { withTheme } from '@material-ui/styles'
import classNames from 'classnames'

import Field from 'src/models/form/Field'
import AutoComplete from './AutoComplete'
import DefaultAddView from './DefaultAddView'

import type { Data } from './types'

type AddViewArgs = {
  value: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder: string
  id: string
  onSaveClick: () => void
  closeSaveModal: () => void
}

type CreatableAutoCompleteProps = {
  field: InstanceType<typeof Field>
  data: Data[]
  multiple?: boolean
  autoCompleteID: string
  autoCompleteTextFieldPlaceholder?: string
  label: string
  required?: boolean
  onSave?: (newData: string) => Promise<void>
  saveModalTextFieldPlaceholder?: string
  saveModalTextFieldId: string
  disableAutoCompleteCloseOnSelect?: boolean
  getOptionalLabel?: (option: string | Data) => string
  renderChipValue?: (option: string | Data) => React.ReactNode
  renderOption?: (option: string | Data) => string
  getOptionSelected?: (option: string | Data, value: string | Data) => boolean
  actionWithSelectedData?: (
    selectedData: (string | Data)[] | NonNullable<string | Data>
  ) => void
  disabled?: boolean
  renderAddView?: (args: AddViewArgs) => React.ReactNode
  disableCreation?: boolean
}

const defaultGetOptionalLabel = (option: string | Data) => {
  if (typeof option === 'string') {
    return option
  }

  return ''
}

const defaultRenderChipValue = (value: string | Data) => {
  if (typeof value === 'string') {
    return value
  }

  return value.name
}

const defaultRenderOption = (option: string | Data) => {
  if (typeof option === 'string') {
    return option
  }

  return option.name
}

const defaultGetOptionSelected = (
  option: string | Data,
  value: string | Data
) => {
  if (typeof option === 'string' && typeof value === 'string') {
    return option === value
  }

  return (option as Data).id === (value as Data).id
}

function CreatableAutoComplete(props: CreatableAutoCompleteProps) {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [newData, setNewData] = useState('')
  const {
    autoCompleteID,
    field,
    data,
    multiple = false,
    autoCompleteTextFieldPlaceholder = '',
    saveModalTextFieldPlaceholder = '',
    saveModalTextFieldId,
    label,
    required = false,
    onSave,
    disableAutoCompleteCloseOnSelect = false,
    getOptionalLabel = defaultGetOptionalLabel,
    renderChipValue = defaultRenderChipValue,
    renderOption = defaultRenderOption,
    getOptionSelected = defaultGetOptionSelected,
    actionWithSelectedData,
    disabled = false,
    disableCreation = false,
    renderAddView
  } = props

  const getSelectedOptions = (): string | Array<string> => {
    if (multiple) {
      return Array.isArray(field.value) ? field.value : []
    }

    return field.value as string
  }

  return (
    <div
      className={classNames({
        'refari-field-tags-wrap': true
      })}
    >
      <label style={{ color: 'rgba(74, 74, 74, 0.6)' }}>{`${label}${
        required ? '*' : ''
      }`}</label>

      {isSaveModalOpen ? (
        renderAddView ? (
          renderAddView({
            value: newData,
            onChange: (event) => {
              setNewData(event.target.value)
            },
            placeholder: saveModalTextFieldPlaceholder,
            id: saveModalTextFieldId,
            onSaveClick: () => {
              onSave &&
                onSave(newData).then(() => {
                  setIsSaveModalOpen(false)
                  setNewData('')
                })
            },
            closeSaveModal: () => {
              setIsSaveModalOpen(false)
            }
          })
        ) : (
          <DefaultAddView
            value={newData}
            onChange={(event) => {
              setNewData(event.target.value)
            }}
            placeholder={saveModalTextFieldPlaceholder}
            id={saveModalTextFieldId}
            onSaveClick={() => {
              onSave &&
                onSave(newData).then(() => {
                  setIsSaveModalOpen(false)
                  setNewData('')
                })
            }}
          />
        )
      ) : (
        <AutoComplete
          disabled={disabled}
          disableCreation={disableCreation}
          autoCompleteID={autoCompleteID}
          multiple={multiple}
          options={data}
          getOptionalLabel={getOptionalLabel}
          selectedOptions={getSelectedOptions()}
          openSaveModal={() => setIsSaveModalOpen(true)}
          onChangeData={(selectedData) => {
            actionWithSelectedData && actionWithSelectedData(selectedData)
            // @ts-ignore do this later
            field.update(selectedData)
          }}
          autoCompleteTextFieldPlaceholder={autoCompleteTextFieldPlaceholder}
          disableAutoCompleteCloseOnSelect={disableAutoCompleteCloseOnSelect}
          renderChipValue={renderChipValue}
          renderOption={renderOption}
          getOptionSelected={getOptionSelected}
        />
      )}
    </div>
  )
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(CreatableAutoComplete)
