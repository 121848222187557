import React from 'react'
import { IconButton, Typography, withTheme } from '@material-ui/core'
import CalendarIcon from '../../icons/CalendarIcon'
import { Delete, Edit } from '@material-ui/icons'

function ScheduleSocialise(props) {
  const { isFacebookEnabled, socializeStore } = props
  const { scheduleSocialise } = socializeStore

  function openScheduleSocialiseLayout() {
    if (isFacebookEnabled) {
      props.openFacebookWarningPopup()
    } else {
      props.openSchedulePopup()

      socializeStore.setPendingState({
        schedulePost: true
      })
    }
  }

  /**
   * If socialise is not scheduled before
   */
  if (scheduleSocialise) {
    return (
      <div>
        <Typography
          variant="body1"
          style={{
            fontWeight: 'bold',
            marginBottom: 0,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Schedule Post{' '}
          <IconButton
            size="small"
            disableFocusRipple
            disableRipple
            color="primary"
            style={{
              marginLeft: '0.2rem'
            }}
            onClick={openScheduleSocialiseLayout}
          >
            <Edit />
          </IconButton>
        </Typography>
        <div>
          <CalendarIcon width={40} height={40} />{' '}
          <Typography
            display="inline"
            style={{ color: '#25B4D5', fontSize: '0.9rem' }}
          >
            Will send on {scheduleSocialise.format('ddd, MMM DD, YYYY')}
            <IconButton
              size="small"
              disableFocusRipple
              disableRipple
              style={{
                marginLeft: '0.2rem'
              }}
              onClick={() => {
                socializeStore.addScheduleSocialiseDateTime(null)
              }}
            >
              <Delete style={{ color: 'red' }} />
            </IconButton>
          </Typography>
        </div>
      </div>
    )
  }
  return null
}

export default withTheme(ScheduleSocialise)
