/* eslint-disable max-len */
import { observable, action, runInAction, computed } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import alertMessages from '../constants/alertMessages'
import credentials from '../constants/credentials'
import SocialAccount from '../models/SocialAccount'

import { LinkedinHelper, TwitterHelper } from '../utils/socialHelpers'
import FacebookAccessTokenHelper from '../utils/socialHelpers/FacebookAccessTokenHelper'

export class NetworkStore {
  static notificationThreshold = 3 // 3 days
  @observable.ref linkedinAccountsList = []
  @observable.ref twitterAccountsList = []
  @observable.ref facebookAccountsList = []
  @observable isFetchAccounts = true
  @observable.ref canAddAccount = {
    linkedin: false,
    twitter: false
  }
  @observable isPersonal = true
  @observable settingsTab = false
  @observable source = ''

  @computed
  get showWarnings() {
    return {
      linkedin: {
        expiresAt: this.linkedinAccountsList.some(this.expiresAtCallback),
        isInvalid: this.linkedinAccountsList.some(this.isInvalidCallback)
      },
      twitter: {
        expiresAt: this.twitterAccountsList.some(this.expiresAtCallback),
        isInvalid: this.twitterAccountsList.some(this.isInvalidCallback)
      }
    }
  }

  expiresAtCallback = (account) =>
    account.remainingTime < NetworkStore.notificationThreshold &&
    !account.isInvalid

  isInvalidCallback = (account) => account.isInvalid

  @action
  connectAccount = (network, token) => {
    switch (network) {
      case 'linkedin':
        LinkedinHelper.process(
          {
            clientId: credentials[network].clientId,
            callback: this.successCallback,
            onError: this.errorCallback
          },
          this.isPersonal
        )
        break
      case 'twitter':
        TwitterHelper.process(
          {
            clientId: credentials[network].clientId,
            callback: this.successCallback,
            onError: this.errorCallback
          },
          this.isPersonal
        )
        break
      case 'facebook':
        this.successCallback(res)
        // FacebookAccessTokenHelper.process({
        //   clientId: credentials[network].appId,
        //   callback: this.successCallback,
        //   onError: this.errorCallback
        // })
        break
      default:
        break
    }
  }

  @action
  successCallback = async (res) => {
    this.setData('isFetchAccounts', true)
    if (res.network && res.network !== credentials.twitter.key) {
      let connectionUrl = this.isPersonal
        ? ApiRoutes.socialNetworks.personal.createConnection
        : ApiRoutes.socialNetworks.company.createConnection
      // ApiRoutes.socialCompanies.createConnection(res.network),
      /**
       * @bug this must be change
       */
      if (res.network === 'facebook') {
        connectionUrl =
          ApiRoutes.socialNetworks.company.createFacebookConnection
      }
      try {
        const response = await API.postData(connectionUrl, res)
        toast.success(response.data.message)
        this.fetchAccountData()
      } catch (error) {
        Logger.error(error)
        this.setData('isFetchAccounts', false)
      }
    }
    this.fetchAccountData()
  }

  errorCallback = (network, error) => {
    Logger.error(error)
    this.setData('isFetchAccounts', false)
  }

  // @action
  // unlinkAccount = async (account) => {
  //   const listName = `${account.network}AccountsList`
  //   const tempArray = this[listName].slice()
  //   const index = this[listName].indexOf(account)
  //   this[listName].splice(index, 1)
  //   try {
  //     this[listName] = [...this[listName]]
  //     const response = await API.deleteData(
  //       ApiRoutes.socialCompanies.unlink(account.network, account.id)
  //     )
  //     runInAction(() => {
  //       this.canAddAccount = {
  //         ...this.canAddAccount,
  //         [account.network]: true
  //       }
  //     })
  //     toast.success(response.data.message)
  //   } catch (error) {
  //     runInAction(() => {
  //       this[listName] = [...tempArray]
  //     })
  //     Logger.error(error)
  //   }
  // }
  @action
  unlinkAccount = async (accountId) => {
    try {
      const response = await API.deleteData(
        this.isPersonal
          ? ApiRoutes.socialNetworks.personal.unlink(accountId)
          : ApiRoutes.socialNetworks.company.unlink(accountId)
      )
      runInAction(() => {
        /**
         * Decide what to do in this or remove it.
         */
        this.fetchAccountData()
      })
      toast.success(response.data.message)
    } catch (error) {
      runInAction(() => {
        /**
         * Decide what to in this or remove it
         */
      })
      Logger.error(error)
    }
  }

  @action
  fetchAccountData = async () => {
    try {
      // const response = await API.getData(ApiRoutes.socialCompanies.list)
      let response = null
      this.linkedinAccountsList = []
      this.twitterAccountsList = []
      this.facebookAccountsList = []
      if (this.isPersonal) {
        response = await API.getData(ApiRoutes.socialNetworks.personal.list)
      } else {
        response = await API.getData(ApiRoutes.socialNetworks.company.list)
      }
      this.setData('isFetchAccounts', true)

      runInAction(() => {
        console.log(response)
        const { data } = response
        const linkedinAccounts = []
        const twitterAccounts = []
        const facebookAccounts = []
        if (Array.isArray(data)) {
          data.map((account) => {
            const socialAccountInstance = new SocialAccount(account)
            if (socialAccountInstance.network === 'twitter') {
              twitterAccounts.push(socialAccountInstance)
            } else if (socialAccountInstance.network === 'facebook') {
              facebookAccounts.push(socialAccountInstance)
            } else {
              linkedinAccounts.push(socialAccountInstance)
            }
          })
        }
        this.linkedinAccountsList = linkedinAccounts
        this.twitterAccountsList = twitterAccounts
        this.facebookAccountsList = facebookAccounts
        // this.canAddAccount = {
        //   linkedin: response.data.linkedin.can_add_account,
        //   twitter: response.data.twitter.can_add_account
        // }
        // this.linkedinAccountsList = response.data.linkedin.accounts.map(
        //   (item) =>
        //     new SocialAccount(item, 'linkedin', this.notificationThreshold)
        // )
        // this.twitterAccountsList = response.data.twitter.accounts.map(
        //   (item) =>
        //     new SocialAccount(item, 'twitter', this.notificationThreshold)
        // )
      })
      this.setData('isFetchAccounts', false)
    } catch (error) {
      Logger.error(error)
      this.setData('isFetchAccounts', false)
    }
  }

  @action
  toggleIsPersonal = () => {
    this.isPersonal = !this.isPersonal
  }

  @action
  toggleCompany = async (accountId, company, network) => {
    try {
      company.isActive = !company.isActive
      // await API.patchData(ApiRoutes.socialCompanies.partialUpdate(company.id), {
      //   is_active: company.isActive
      // })
      await API.patchData(
        ApiRoutes.socialNetworks.company.companyToggle(accountId, company?.id),
        {
          is_active: company.isActive
        }
      )

      const msg = company.isActive
        ? alertMessages.companyWasActivated(company.name)
        : alertMessages.companyWasDeactivated(company.name)

      /**
       * @note toggle the company status on mobx
       */

      if (typeof network === 'string' && network) {
        if (network === 'linkedin') {
          this.linkedinAccountsList.forEach((account) =>
            account.toggleCompany(accountId)
          )
          this.setData('linkedinAccountsList', [...this.linkedinAccountsList])
        } else if (network === 'twitter') {
          this.twitterAccountsList.forEach((account) =>
            account.toggleCompany(accountId)
          )
          this.setData('twitterAccountsList', [...this.twitterAccountsList])
        } else {
          this.facebookAccountsList.forEach((account) =>
            account.toggleCompany(accountId)
          )
          this.setData('facebookAccountsList', [...this.facebookAccountsList])
        }
      }

      toast.success(msg)
    } catch (error) {
      runInAction(() => {
        company.isActive = !company.isActive
      })
      Logger.error(error)
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  reset = () => {
    LinkedinHelper.stop()
    TwitterHelper.stop()

    this.source = ''
  }
}

export default new NetworkStore()
