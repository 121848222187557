import {
  action,
} from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import {
  ApiRoutes,
} from '../utils/Urls'
import Logger from '../utils/Logger'
import alertMessages from '../constants/alertMessages'

export class JobAlertsStore {
  @action
  changeInterval = async (id, param) => {
    try {
      await API.postData(ApiRoutes.dashboard.jobAlerts.partialUpdate(id), param)
      toast.success(alertMessages.intervalChanged)
    } catch (error) {
      Logger.error(error)
    }
  }
}

export default new JobAlertsStore()
