type SelectedMessageTemplate = {
  id: number
  name: string
  message: string
  templateType: 'personal' | 'company' | 'system'
}

type Args = {
  selectedMessageTemplate: SelectedMessageTemplate | null
  isFetchingMessageTemplateDetails: boolean
  userRole: 'admin' | 'manager' | 'consultant' | undefined
}

const shouldDisableMessageTemplateUpdate = ({
  selectedMessageTemplate,
  isFetchingMessageTemplateDetails,
  userRole
}: Args): boolean => {
  if (isFetchingMessageTemplateDetails) {
    return true
  }

  if (!selectedMessageTemplate) {
    return true
  } else {
    if (selectedMessageTemplate.templateType === 'system') {
      return true
    }

    if (
      selectedMessageTemplate.templateType === 'company' &&
      userRole === 'consultant'
    ) {
      return true
    }
  }

  return false
}

export default shouldDisableMessageTemplateUpdate
