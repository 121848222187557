import React from 'react'
import { analyzeString } from '../../../utils/helpers'
import greenTick from '../../../assets/images/green_tick.svg'

const PasswordHint = ({ className, inputValue, showTick = false }) => {
  const stringData = analyzeString(inputValue)
  return (
    <div className={`password-hint ${className}`}>
      The password
      <ul>
        <li>
          <div className="password-hint-section">
            <span>must have at least 8 characters.</span>
            {showTick && stringData.length > 7 && (
              <span className="password-hint-icon">
                <img
                  src={greenTick}
                  alt="Check"
                />
              </span>
            )}
          </div>
        </li>
        <li>
          <div className="password-hint-section">
            <span>must have at least 3 x numbers.</span>
            {showTick && stringData.numberCount > 2 && (
              <span className="password-hint-icon">
                <img
                  src={greenTick}
                  alt="Check"
                />
              </span>
            )}
          </div>
        </li>
        <li>
          <div className="password-hint-section">
            <span>
              must have at least one upper case character, one special
              character.
            </span>
            {showTick
              && stringData.uppercaseCount > 0
              && stringData.symbolCount > 0 && (
                <span className="password-hint-icon">
                  <img
                    src={greenTick}
                    alt="Check"
                  />
                </span>
            )}
          </div>
        </li>
      </ul>
    </div>
  )
}

export default PasswordHint
