import { action, observable, runInAction } from 'mobx'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import {
  referralCRMStatus,
  topRecCRMStatus,
  progressionInternalCRMStatus,
  progressionExternalCRMStatus
} from '../constants/AgencySettingsForm'

export class RewardsStore {
    @observable rewards = []
    @observable referralTriggers = false
    @observable progressionStatus = false
    @observable.ref crmStatusList = []
    @observable isFetching = false

    @action
    fetchRewards = async () => {
      try {
        const response = await API.getData(ApiRoutes.dashboard.rewards.read)
        runInAction(() => {
          this.rewards = response.data
        })
      } catch (error) {
        Logger.error(error)
      }
    }

    @action
    getCRMStatuses = async (crmstatus, selectedValues) => {
      try {
        this.setData('isFetching', true)
        const response = await API.getData(ApiRoutes.dashboard.agency.crmStatuses)
        if (response
          && response.data
          && response.data.status_list
          && response.data.status_list.length > 0) {
          let selectedArray = []
          if (selectedValues) {
            Object.keys(selectedValues).forEach((key) => {
              selectedArray = [...selectedValues[key], ...selectedArray]
            })
          }

          let currentStatus = []
          if (crmstatus === 'referral') {
            currentStatus = referralCRMStatus
          } else if (crmstatus === 'toprec') {
            currentStatus = topRecCRMStatus
          } else if (crmstatus === 'progression_internal') {
            currentStatus = progressionInternalCRMStatus
          } else if (crmstatus === 'progression_external') {
            currentStatus = progressionExternalCRMStatus
          }

          const data = currentStatus.map((status) => {
            const statusList = response.data.status_list.map((sts) => {
              const stsData = {}
              if (selectedValues && selectedValues[status.id].find((value) => sts === value)) {
                stsData.selected = true
              } else {
                stsData.selected = false
              }
              if (!stsData.selected && selectedArray.includes(sts)) {
                stsData.disabled = true
              } else {
                stsData.disabled = false
              }
              stsData.name = sts
              return stsData
            })
            status.data = statusList
            return status
          })

          runInAction(() => {
            this.crmStatusList = data
          })
        } else {
          runInAction(() => {
            this.crmStatusList = []
          })
        }
        this.setData('isFetching', false)
      } catch (error) {
        Logger.error(error)
        this.setData('isFetching', false)
      }
    }

    @action
    sendRewards = async (data) => {
      try {
        await API.postData(ApiRoutes.dashboard.rewards.read, data)
      } catch (error) {
        Logger.error(error)
      }
    }

    @action.bound
    toggleReferralTriggers() {
      this.referralTriggers = !this.referralTriggers
    }

    @action.bound
    toggleProgressionStatus() {
      this.progressionStatus = !this.progressionStatus
    }

    @action
    setData = (key, value) => {
      this[key] = value
    }
}

export default new RewardsStore()
