/* eslint-disable class-methods-use-this */
import { observable, action, computed, reaction, runInAction } from 'mobx'
import * as axios from 'axios'
import { toast } from 'react-toastify'
import RootStore from './RootStore'
import AuthSession from '../utils/AuthSession'
import alertMessages from '../constants/alertMessages'
import { ApiRoutes } from '../utils/Urls'
import API from '../utils/API'
import Logger from '../utils/Logger'
import GameficationStore from './GameficationStore'
import { mapFormServerErrors } from '../utils/helpers'
import CanvasCardStore from './CanvasCardStore'
import PitchMeStore from './PitchMeStore'
import TopRecStore from './TopRecStore'
import RewardStore from './RewardStore'

export class AgenciesStore {
  @observable activeAgency = {}
  @observable isFetch = false
  @observable agencyKey = ''
  @observable agencyApiKey = ''
  @observable hashtagList = { results: [] }

  init = {
    videoModal: {
      isOpen: false,
      type: '',
      id: ''
    },
    importPlacementModal: false
  }

  @observable videoModal = this.init.videoModal
  @observable importPlacementModal = this.init.importPlacementModal

  constructor() {
    reaction(
      () => (RootStore.user.agencies ? RootStore.user.agencies.length : 0),
      (agenciesAmount) => {
        if (agenciesAmount > 0) {
          this.activeAgency = RootStore.user.agencies.find(
            (item) => item.is_current
          )
        }
      }
    )
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  addHashtags = async (form, cd) => {
    const newData = {
      ...form.data,
      image: form.data.image
    }
    if (newData.hashtag.startsWith('#')) {
      newData.hashtag = newData.hashtag.replace(/#/, '')
    }
    try {
      await API.postData(ApiRoutes.dashboard.agency.hashtags, newData)
      toast.success(alertMessages.hashtagsAdd)
      cd && cd()
      this.fetchHashtags()
    } catch (error) {
      Logger.error(error, 'addHashtags')
      mapFormServerErrors(error.data, form.fields)
      if (error.data.non_field_errors) {
        toast.error(error.data.non_field_errors)
      }
      throw error
    }
  }

  editHashtags = async (form, cb, id) => {
    const newData = {
      id
    }

    if (form.data.hashtag) {
      newData.hashtag = form.data.hashtag.replace(/#/, '')
    }

    if (newData.hashtag.startsWith('#')) {
      newData.hashtag = newData.hashtag.replace(/#/, '')
    }

    if (form.data.image && typeof form.data.image === 'number') {
      newData.image = form.data.image
    }

    if (form.data.video) {
      newData.video = form.data.video
    }

    try {
      const res = await API.patchData(
        ApiRoutes.dashboard.agency.updateHashtags(id),
        newData
      )
      const index = this.hashtagList.results.findIndex(
        (el) => el.id === res.data.id
      )
      runInAction(() => {
        this.hashtagList.results.splice(index, 1, res.data)
      })
      cb && cb()
      toast.success(alertMessages.hashtagsUpdate)
    } catch (error) {
      Logger.error(error, 'addHashtags')
      mapFormServerErrors(error.data, form.fields)
      if (error.data.non_field_errors) {
        toast.error(error.data.non_field_errors)
      }
      throw error
    }
  }

  removeHashtags = async (data, page) => {
    try {
      await API.deleteData(ApiRoutes.dashboard.agency.updateHashtags(data.id))
      this.fetchHashtags({ page })
      toast.success(alertMessages.hashtagsRemoved)
    } catch (e) {
      Logger.error(e, 'removeHashtags')
    }
  }

  fetchVimeo = (id) =>
    fetch(
      `${window.location.protocol}//vimeo.com/api/v2/video/${id}.json`
    ).then((res) => res.json())

  @action.bound
  toggleVideoModal(modalObj) {
    if (modalObj) {
      this.videoModal = modalObj
    } else {
      this.videoModal = this.init.videoModal
    }
  }

  @action.bound
  toggleImportPlacementModal() {
    this.importPlacementModal = !this.importPlacementModal
  }

  @action
  fetchHashtags = async (page) => {
    try {
      const res = await API.getData(ApiRoutes.dashboard.agency.hashtags, page)
      runInAction(() => {
        this.hashtagList = res.data
      })
    } catch (e) {
      Logger.error(e, 'fetchHashtags')
    }
  }

  @computed
  get agenciesList() {
    if (!RootStore.user.agencies) return []
    return RootStore.user.agencies
  }

  @computed
  get availableSeveralAgencies() {
    if (RootStore.user.isReferrer) return false
    return this.agenciesList.length > 1
  }

  @action async progressNew() {
    if (
      RootStore.user.userType === 'referrer' ||
      RootStore.user.userType === 'default'
    ) {
      RootStore.updateProgress(false)
      return null
    }
    try {
      const res = await API.getData(ApiRoutes.gamefication.updateProgress)
      runInAction(() => {
        RootStore.updateProgress(res.data.has_new)
      })
    } catch (e) {
      Logger.error(e, 'progressNew')
    }
  }

  @action
  fetchApiKey = async (onSuccess) => {
    try {
      const res = await API.getData(ApiRoutes.getApiKey)
      runInAction(() => {
        this.agencyKey = res.data.key
        this.agencyApiKey = res.data.key
      })
      axios.defaults.headers['Api-Key'] = res.data.key
      onSuccess && onSuccess()
    } catch (e) {
      Logger.error(e)
    }
  }

  @action
  changeUserType = async (userType, cb) => {
    if (!userType) return
    this.isFetch = true

    console.log('change user type -> agencies store', userType)
    const user_type =
      userType === 'hiringManager' ? 'hiring_manager' : 'referrer'
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.switchUserType,
        { user_type }
      )
      AuthSession.set(response.data.key)
      await RootStore.fetchUserData(cb)
      // RootStore.switchToHiringManagerUser()
      runInAction(() => {
        this.activeAgency = null
        this.isFetch = false
      })
      // typeof cb === 'function' && cb()
      console.log(response)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  changeAgency = async (agency, cb, cb2) => {
    const isHiringManager = RootStore.user.userType === 'hiring_manager'
    if (agency.is_current && !isHiringManager) return
    this.isFetch = true
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.switchAgency,
        { agency: agency.id }
      )
      AuthSession.set(response.data.key)
      await RootStore.fetchUserData(cb)
      await RootStore.fetchAgencyData()
      await RootStore.fetchAgencyBackgrounds()
      CanvasCardStore.setData('backgrounds', [])
      PitchMeStore.resetStoreData()
      RootStore.setData('averageRating', 0)
      RootStore.setData('totalRatingCount', 0)
      RootStore.setData('companyAverageRating', 0)
      RootStore.setData('companyTotalRatingCount', 0)
      TopRecStore.setData('googleBusinessAccountsList', [])
      RewardStore.setData('crmStatusList', [])
      if (RootStore.agency.access === 'full') {
        await GameficationStore.loadLeaders()
        await GameficationStore.loadProgress()
        this.progressNew()
      }
      await this.fetchApiKey() // Fetch the new agency API key - R
      runInAction(() => {
        this.activeAgency = agency
        this.isFetch = false
      })

      cb2 && cb2()
      toast.success(alertMessages.changedAgency(agency.name))
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  replaceAgency = async (agency, cb) => {
    if (agency.is_current) return
    this.isFetch = true
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.switchAgency,
        { agency: agency.id }
      )
      AuthSession.set(response.data.key)
      await RootStore.fetchUserData(cb)
      await RootStore.fetchAgencyData()
      await RootStore.fetchAgencyBackgrounds()
      PitchMeStore.setData('previewData', {})
      RootStore.setData('averageRating', 0)
      CanvasCardStore.setData('backgrounds', [])
      RootStore.setData('totalRatingCount', 0)
      RootStore.setData('companyAverageRating', 0)
      RootStore.setData('companyTotalRatingCount', 0)
      await GameficationStore.loadLeaders()
      await GameficationStore.loadProgress()
      await this.fetchApiKey() // Fetch the new agency API key - R
      runInAction(() => {
        // this.agencyKey = response.data.key
        // this.agencyApiKey = response.data.key
        this.activeAgency = agency
        this.isFetch = false
      })
      this.progressNew()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }
}

export default new AgenciesStore()
