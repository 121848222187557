/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type XIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const XIcon = ({ iconColor, ...restProps }: XIconProps): React.ReactElement => (
  <SvgIcon width="20" height="16" viewBox="0 0 20 16" {...restProps}>
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1460"
          data-name="Rectangle 1460"
          width="20"
          height="16"
          transform="translate(1344 3095)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="X_Logo_"
      data-name="X Logo_"
      transform="translate(-1344 -3095)"
      clipPath="url(#clip-path)"
    >
      <path
        id="twitter"
        d="M21.968,6.249,27.462,0h-1.3L21.39,5.426,17.58,0H13.185l5.762,8.205-5.762,6.553h1.3l5.038-5.73,4.024,5.73h4.395L21.968,6.249ZM20.185,8.277,19.6,7.46,14.956.959h2L20.7,6.206l.584.817,4.873,6.82h-2L20.185,8.278Z"
        transform="translate(1333.436 3095.621)"
        fill={iconColor || '#000'}
      />
    </g>
  </SvgIcon>
)

export default XIcon
