/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-classes-per-file */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Switch, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { PrivateRoute } from '../../router/RouterHooks'
import URLs from '../../router/routesUrls'
import asyncComponent from '../shared/asyncComponent'
import { baseURLs } from '../../utils/Urls'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

@inject('rootStore')
@inject('dashboardStore')
@inject('agenciesStore')
@observer
class DashboardWrap extends React.Component {
  render() {
    if (!this.props.rootStore.user.userType) return null
    return <Dashboard {...this.props} />
  }
}

@observer
class Dashboard extends React.Component {
  static propTypes = {
    dashboardStore: PropTypes.shape({
      dashboardPermissions: PropTypes.array,
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired,
      activeTab: PropTypes.string.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      isFetch: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object
    })
  }
  onTabChange = (link) => {
    // this.props.agenciesStore.progressNew()
    this.props.dashboardStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }
  dynamicIndexRedirect = (props) => {
    const redirectUrl = props.location?.state?.from?.search
    const isProfileOnlyUser = props.rootStore?.user?.role === 'profile_only'

    if (isProfileOnlyUser) {
      props.history.replace(URLs.settings.children.editProfile.link)
      return
    }

    if (redirectUrl && typeof redirectUrl === 'string') {
      // props.history.replace(redirectUrl.replace('?redirect_url=', ''))
      window.open(
        `${baseURLs('frontend')}${redirectUrl.replace('?redirect_url=/', '')}`,
        '_self'
      )
      return
    }
    if (URLs.dashboard.link === props.location.pathname) {
      const { dashboardPermissions } = props.dashboardStore
      if (dashboardPermissions.length) {
        this.onTabChange(dashboardPermissions[0].link)
      } else {
        props.history.replace(URLs.error.link)
      }
    }
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    /* const accessPages = props.dashboardStore.dashboardPermissions.map(item => item.link)

    if (!accessPages.includes(pathname)) {
      //TODO: rewrite to history layer
      props.history.replace(URLs.error.link)
    } */

    props.dashboardStore.initTab(pathname)
    this.dynamicIndexRedirect(props)
    const { executeRecaptcha } = props.googleReCaptchaProps

    props.rootStore.setData('executeCaptchaFn', executeRecaptcha)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.dynamicIndexRedirect(nextProps)
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match,
      location,
      history
    } = this.props

    return (
      <div className="dashboard">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container-fluid">
            <Switch>
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.candidateEvents.path}`}
                component={asyncComponent(() =>
                  import('./candidates/Candidates')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.candidateEventsDetails.path}`}
                component={asyncComponent(() =>
                  import('./candidates/Candidates')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.consultants.path}`}
                component={asyncComponent(() =>
                  import('./consultants/Consultants')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.referrers.path}`}
                component={asyncComponent(() =>
                  import('./referrers/Referrers')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.referrals.path}`}
                component={asyncComponent(() =>
                  import('./referrals/Referrals')
                )}
              />
              {/* <PrivateRoute
                  exact
                  path={`${match.url}${URLs.dashboard.children.jobReferrals.path}`}
                  component={asyncComponent(() => import('./jobReferrals/JobReferrals'))}
                /> */}
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.appliedJobs.path}`}
                component={asyncComponent(() =>
                  import('./appliedJobs/AppliedJobs')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.jobAlerts.path}`}
                component={asyncComponent(() =>
                  import('./jobAlerts/JobAlerts')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.featured.path}`}
                component={asyncComponent(() => import('./featured/Featured'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.socialize.path}`}
                component={asyncComponent(() =>
                  import('./socialize/Socialize')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.jobs.path}`}
                component={asyncComponent(() => import('./jobs/Jobs'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.jobAds.path}`}
                component={asyncComponent(() => import('./jobAds/JobAds'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.activities.path}`}
                component={asyncComponent(() =>
                  import('./activities/Activities')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.communications.path}`}
                component={asyncComponent(() =>
                  import('./communications/Communications')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.recruiters.path}`}
                component={asyncComponent(() =>
                  import('./recruiters/Recruiters')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.users.path}`}
                component={asyncComponent(() => import('./users/Users'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.leaderboard.path}`}
                component={asyncComponent(() =>
                  import('./leaderboard/Leaderboard')
                )}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.clients.path}`}
                component={asyncComponent(() => import('./clients'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.candidates.path}`}
                component={asyncComponent(() => import('./candidatesPage'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.candidateAds.path}`}
                component={asyncComponent(() => import('./candidateAds'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.candidateEnquiries.path}`}
                component={asyncComponent(() => import('./enquiries'))}
              />
              <PrivateRoute
                exact
                path={`${match.url}${URLs.dashboard.children.talentAlerts.path}`}
                component={asyncComponent(() =>
                  import('./talentAlerts/index')
                )}
              />
              <Redirect
                from={`${match.url}/candidates/`}
                to={`${match.url}${URLs.dashboard.children.candidateEvents.path}`}
              />
            </Switch>
          </div>
        )}
      </div>
    )
  }
}

export default withGoogleReCaptcha(withTheme(DashboardWrap))
