/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

const BackgroundOptions = ({
  images, selectedImage, theme, onChange, canvasCardStore
}) => {
  if (!images.length) return null

  const activeStyle = {
    borderColor: theme.palette.primary1Color
  }

  const getActiveStyle = (isActive) => (isActive ? activeStyle : {})
  return (
    <div className="refari-background-options">
      <div
        className="refari-background-options-inner"
        style={{ position: 'relative', display: 'flex' }}
      >
        {images.map((item, i) => (
          <div
            key={item.id}
            className="refari-option-wrapper"
            style={getActiveStyle(item.id === selectedImage)}
          >
            <label
              htmlFor={`background-${item.id}`}
              className={item.isVideo ? 'hasVideo' : ''}
            >
              <img src={item.thumb_large || item.image} alt={`background-${i}`} />
              <input
                id={`background-${item.id}`}
                type="radio"
                className="refari-hidden"
                value={item.id}
                checked={item.id === selectedImage}
                onChange={onChange}
                name="backgrounds"
                disabled={canvasCardStore.isGIFRenderingOnScreen}
              />
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

BackgroundOptions.propTypes = {
  images: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedImage: PropTypes.any
}

export default withTheme(BackgroundOptions)
