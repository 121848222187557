import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import Modal from '../Modal'
import CloseModalButton from '../CloseModalButton'
// import sad from '../../../images/sad.png'
import RaisedButton from '../../common/RaisedButton'
import FlatButton from '../../common/FlatButton'

@inject('socializeStore', 'canvasCardStore', 'rootStore')
@observer
class SocializeConfirmModal extends React.Component {
  toggleSocializeModal = () => {
    const { socializeStore } = this.props
    socializeStore.setData(
      'isOpenModalConfirm',
      !socializeStore.isOpenModalConfirm
    )
  }

  handlerClose = () => {
    const { socializeStore, canvasCardStore } = this.props
    // socializeStore.reset()
    // canvasCardStore.setData('canvasImage', {})
    // canvasCardStore.setData('canvasImageSaved', {})
    // canvasCardStore.setData('opacity', 0)
    // canvasCardStore.setData('tintColorData', {})
    // canvasCardStore.setData('gifFramesCanvas', [])
    // canvasCardStore.setData('canvasImageGif', null)
    // canvasCardStore.setData('selectedGifFrame', 0)
    this.toggleSocializeModal()
  }

  closeSocialisation = () => {
    const { socializeStore, onClose } = this.props
    this.toggleSocializeModal()
    socializeStore.setData('isOpenModal', !socializeStore.isOpenModal)
    socializeStore.reset()
    onClose && onClose()
  }

  continueSocialisation = () => {
    this.toggleSocializeModal()
  }

  render() {
    const {
      socializeStore: { isOpenModalConfirm },
      theme
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        open={isOpenModalConfirm}
        onClose={this.handlerClose}
        PaperProps={{
          className: 'refari-modal refari-refer-modal-wrap',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch'
            // backgroundColor: theme.widgetBackgroundColor,
            // borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content let-us-help-you-modal">
          <h3 style={style.title} className="refari-long-text m_bottom_1x">
            Are you sure?
          </h3>
          <p className="let-us-help-you-modal--content">
            Are you sure you wish to cancel socialising? Cancelling will lose
            all progress you've currently made
          </p>
          <CloseModalButton onClose={this.continueSocialisation} />
          <div>
            <FlatButton
              color="primary"
              style={{ border: '1px solid', marginRight: '24px' }}
              className="m_r_2x refari-button-flat"
              onClick={this.closeSocialisation}
            >
              Cancel Socialisation
            </FlatButton>
            <RaisedButton
              className="refari-button-raised"
              style={{ color: '#fff' }}
              color="primary"
              onClick={this.continueSocialisation}
            >
              Continue Socialisation
            </RaisedButton>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(SocializeConfirmModal)
