import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Box } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

import Modal from 'src/components/shared/modals/Modal'
import CloseModalButton from 'src/components/shared/modals/CloseModalButton'
import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import TemplateNameChangeForm from './TemplateNameChangeForm'

type TemplateNameChangeModalProps = {
  theme: MUIThemeFuncReturnType
  rootStore: Record<string, any>
  updateMessageTemplate: (templateName: string) => void
}

@inject('rootStore')
@observer
class TemplateNameChangeModal extends Component<TemplateNameChangeModalProps> {
  render() {
    const { theme, rootStore, updateMessageTemplate } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        open={rootStore.isOpenMessageTemplateNameChangeModal}
        onClose={rootStore.closeMessageTemplateNameChangeModal}
      >
        <div className="refari-modal-content let-us-help-you-modal">
          <h3 style={style.title} className="refari-long-text m_bottom_1x">
            Update existing template
          </h3>

          <Box className="let-us-help-you-modal--content">
            {/* @ts-ignore other props are provided by store */}
            <TemplateNameChangeForm
              updateMessageTemplate={updateMessageTemplate}
            />
          </Box>
          <CloseModalButton
            onClose={rootStore.closeMessageTemplateNameChangeModal}
          />
        </div>
      </Modal>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TemplateNameChangeModal)
