import qp from 'query-parse'
import { ApiRoutes } from '../Urls'
import API from '../API'
import credentials from '../../constants/credentials'

class TwitterHelper {
  title = 'Authorization via Twitter'
  alert =
    'Pop-ups were blocked on this page. To proceed, please allow pop-ups now and click "ok".'
  options = qp
    .toString({
      width: 600,
      height: 485,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')

  process = (options, isPersonal) => {
    this.getToken(options, isPersonal)
  }

  getToken = async ({ clientId, callback, onError }, isPersonal) => {
    try {
      let callbackIsApplied = false
      const response = await API.getData(
        isPersonal
          ? ApiRoutes.socialNetworks.personal.twitterAccessLink
          : ApiRoutes.socialNetworks.company.twitterAccessLink
      )

      window.newTwitterWindow && window.newTwitterWindow.close()

      window.newTwitterWindow = window.open(
        response.data.link,
        this.title,
        this.options
      )

      // check if popup is closed
      this._timer = setInterval(() => {
        if (
          window.newTwitterWindow &&
          window.newTwitterWindow.closed &&
          !callbackIsApplied
        ) {
          clearInterval(this._timer)
          callback({
            network: credentials.twitter.key
          })
          callbackIsApplied = true
          window.newTwitterWindow && window.newTwitterWindow.close()
          delete window.newTwitterWindow
          setTimeout(() => {
            this.logout()
          }, 0)
        } else if (
          window.newTwitterWindow &&
          window.newTwitterWindow.location &&
          window.newTwitterWindow.location.href &&
          window.newTwitterWindow.location.href.indexOf('oauth_token') !== -1 &&
          !callbackIsApplied
        ) {
          clearInterval(this._timer)
          callback({
            network: credentials.twitter.key
          })
          callbackIsApplied = true
          setTimeout(() => {
            window.newTwitterWindow && window.newTwitterWindow.close()
            delete window.newTwitterWindow
          }, 6000)
        }
      }, 1000)
    } catch (error) {
      clearInterval(this._timer)
      onError && onError(credentials.twitter.key, error)
    }
  }

  logout = () =>
    new Promise((resolve, reject) => {
      window.IN.User.refresh()
      const isAuthorized = window.IN.User.isAuthorized()
      if (!isAuthorized) {
        resolve()
        return
      }
      window.IN.User.logout((success) => {
        if (success) {
          resolve()
        } else {
          resolve()
        }
      })
    })

  stop() {
    clearInterval(this._timer)
  }
}

export default new TwitterHelper()
