import { observable, computed, action, runInAction, toJS } from 'mobx'
import FieldsArray from './FieldsArray'
import camelCase from 'lodash.camelcase'

export default class FieldsGroup {
  name

  @observable fieldsName = []
  @observable.ref fields = {}

  @observable isPending = false

  @computed
  get isValid() {
    return this.fieldsName.every((fieldName) => this.fields[fieldName].isValid)
  }

  @computed
  get isDirty() {
    return this.fieldsName.some((fieldName) => !this.fields[fieldName].isDirty)
  }

  @computed
  get data() {
    const data = {}
    this.fieldsName.forEach((fieldName) => {
      const field = this.fields[fieldName]
      const { name } = field
      if (!field.isErrorField) {
        data[name || fieldName] =
          field instanceof FieldsGroup || field instanceof FieldsArray
            ? field.data
            : toJS(field.value)
      }
    })

    return data
  }

  @computed
  get dataWithoutEmptyValue() {
    const data = {}
    this.fieldsName.forEach((fieldName) => {
      const field = this.fields[fieldName]
      const { name } = field

      if (!field.isErrorField && (field.value || field.data)) {
        data[name || fieldName] =
          field instanceof FieldsGroup || field instanceof FieldsArray
            ? toJS(field.data).toString()
            : toJS(field.value).toString()
      }
    })

    return data
  }

  @computed
  get dataWithoutEmptyValueCamelCasedFieldName() {
    const data = {}

    this.fieldsName.forEach((fieldName) => {
      const field = this.fields[fieldName]
      const { name } = field

      if (!field.isErrorField && (field.value || field.data)) {
        data[camelCase(name) || camelCase(fieldName)] =
          field instanceof FieldsGroup || field instanceof FieldsArray
            ? toJS(field.data)
            : toJS(field.value)
      }
    })

    return data
  }

  constructor(fields = {}, name) {
    if (typeof fields !== 'object') {
      throw new Error(
        'Property "fields" must be an object!',
        'FieldsGroup.js',
        '40'
      )
    }

    if (!Object.keys(fields).length) {
      throw new Error(
        'Property "fields" must be filled',
        'FieldsGroup.js',
        '44'
      )
    }

    runInAction('Initialize form:', () => {
      this.name = name
      this.fieldsName = Object.keys(fields)
      this.fieldsName.forEach((fieldName) => {
        this.fields[fieldName] = fields[fieldName]
      })
    })
  }

  @action
  addNewField = (name, field) => {
    this.fieldsName.push(name)
    this.fields[name] = field
  }

  @action
  removeField = (name) => {
    const index = this.fieldsName.indexOf(name)

    if (index !== -1) {
      this.fieldsName.splice(index, 1)
    }

    delete this.fields[name]
  }

  @action('Submit form')
  submit = (request, cb) => {
    if (this.isPending) return
    if (!request || typeof request !== 'function') {
      throw new Error(
        'Property "callback" is required and this property must be a function!',
        'FieldsGroup.js',
        '62'
      )
    }
    this.pending(true)
    request(this, cb)
      .then(() => {
        this.pending(false)
      })
      .catch(() => {
        this.pending(false)
      })
  }

  @action('Reset form')
  reset = () => {
    this.fieldsName.forEach((fieldName) => {
      this.fields[fieldName].reset()
    })
  }

  @action
  pending = (bool) => {
    this.isPending = bool
  }

  @computed
  get requiredFields() {
    return this.fieldsName.filter(
      (fieldName) => this.fields[fieldName].validators.length > 0
    )
  }

  @computed
  get emptyRequiredFields() {
    return this.requiredFields.filter((fieldName) => {
      const field = this.fields[fieldName]

      return field instanceof FieldsGroup || field instanceof FieldsArray
        ? !field.data
        : Array.isArray(field.value)? field.value.length === 0 : !field.value
    })
  }
}
