import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import qp from 'query-parse'
import { inject, observer } from 'mobx-react'

// import ListStore from '../../../stores/ListStore'
import API from '../../../utils/API'
import { ApiRoutes, widgetUrls } from '../../../utils/Urls'
import NetworkTabContent from '../../shared/modals/socializeModalParts/NetworkTabContent'
import * as axios from 'axios'
import hexToRgba from 'hex-to-rgba'
import SocializeConfirmModal from 'src/components/shared/modals/socializeModalParts/SocializeConfirmModal'
import SocialiseCommentModal from 'src/components/shared/modals/socializeModalParts/SocialiseCommentModal'
import CandidateAdsDetailsModel from 'src/models/CandidateAds/DetailsModel'

@inject('rootStore')
@inject('socializeStore')
@inject('agenciesStore')
@inject('canvasCardStore')
@observer
class SocialiseJobPage extends React.Component {
  constructor(props) {
    super(props)
    const { pathname } = props.history.location
    const queryData = qp.toObject(props.history.location.search.slice(1))
    this.jobId = queryData.jobId || ''
    this.apiKey = queryData.apiKey
    this.postType = queryData.postType || 'job'
    this.consultantId = queryData.consultantId || ''
    this.testimonialId = queryData.testimonialId || ''
    this.candidateAdId = queryData.candidateAdId || ''
    this.currentHref = queryData.currentHref || ''
    this.state = {
      loading: true,
      isSuccessShare: false
      //   forceRender: 0
    }
    const agencyStore = props.agenciesStore
    agencyStore.setData('agencyKey', this.apiKey)
    agencyStore.setData('agencyApiKey', this.apiKey)
    axios.defaults.headers['Api-Key'] = this.apiKey

    // props.rootStore.fetchWidgetAgency().then(() => {
    //   this.setupSocialisePage()
    // })
    props.rootStore.fetchAgencyData(true).then(() => {
      // setTimeout(this.setupSocialisePage, 500)
      this.setupSocialisePage()
    })
    this.separator = '#'
  }

  handleIFrameEvent = (event) => {
    const { socializeStore } = this.props

    if (event.data === 'OPEN_CONFIRM_MODAL_SOCIAL') {
      // OPEN_CONFIRM_MODAL_SOCIAL
      // console.log('OPEN_CONFIRM_MODAL_SOCIAL')
      console.log('OPEN_CONFIRM_MODAL_SOCIAL -> from frontend')
      socializeStore.setData('isOpenModalConfirm', true)
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIFrameEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIFrameEvent)
  }

  setupSocialisePage = async () => {
    console.log('setupSocialisePage')
    console.log(this.props)
    if (this.postType === 'job') {
      if (!this.jobId) return
      const { data } = await API.getData(
        // ApiRoutes.dashboard.jobAds.read(this.jobId)
        ApiRoutes.jobs.read(this.jobId)
      )
      console.log('data from setupSocialisePage')
      console.log(data)
      this.socialiseJob(data)
    } else if (this.postType === 'job_list') {
      let data
      if (this.consultantId) {
        const response = await API.getData(
          ApiRoutes.agency.consultants.read(this.consultantId)
        )
        data = response.data
      }
      this.socialiseJobList(data)
    } else if (this.postType === 'testimonial') {
      let data

      if (this.consultantId && this.testimonialId) {
        const response = await API.getData(
          ApiRoutes.testimonials.readTestimonial(
            this.consultantId,
            this.testimonialId
          )
        )
        data = response.data
        this.socialiseTestimonial(data)
      }

      /**
       * @note if consultantID is not present, then it is a google review
       */
      if (!this.consultantId && this.testimonialId) {
        const response = await API.getData(
          ApiRoutes.testimonials.readTestimonialLite(this.testimonialId)
        )
        data = response.data
        console.log('testimonial data', data)

        this.socialiseTestimonial(data, true)
      }
    } else if (this.postType === 'candidateAd') {
      // candidateAdId={candiateAdID}&postType=candidateAd
      if (!this.candidateAdId) {
        /**
         * handle the error case
         */
      } else {
        const response = await API.getData(
          ApiRoutes.dashboard.candidateAd.details(this.candidateAdId)
        )

        const { data } = response
        this.socialiseCandidateAd(data)
      }
    } else {
      // post type === "profile"
      let data
      if (this.consultantId) {
        const response = await API.getData(
          ApiRoutes.agency.consultants.read(this.consultantId)
        )
        data = response.data
      }
      this.socialiseConsultant(data)
    }
  }

  generateLink = (path, params = {}, external) => {
    const checkedParams = this.checkParams(params)
    let paramsString = qp.toString(checkedParams)
    paramsString = paramsString ? `?${paramsString}` : ''
    const { origin, pathname } = window.location
    return `${external || origin + pathname}${paramsString}${
      this.separator
    }${path}`
  }

  checkParams = (params) => {
    const checkedParams = {}
    Object.keys(params).forEach((item) => {
      const thisItem = params[item]
      checkedParams[item] = Array.isArray(thisItem)
        ? thisItem.toString()
        : thisItem
    })
    return checkedParams
  }

  socialiseTestimonial = async (testimonialData, isGoogleReview) => {
    const { socializeStore, rootStore, canvasCardStore, theme } = this.props
    // const { agency } = rootStore

    console.log('testimonial Data')
    console.log(testimonialData)
    console.log(this.props)
    const { user } = this.props.rootStore
    const firstName = user?.firstName
    const lastName = user?.lastName
    const fullName = `${firstName} ${lastName}`.trim()
    const consultant = testimonialData?.consultant
    console.log(consultant)

    const consultantFullName =
      `${consultant?.first_name} ${consultant?.last_name}`.trim() || fullName

    const consultantImage =
      consultant && consultant.avatar
        ? consultant.avatar.thumb_small
        : user?.avatar?.url || ''

    let link = ''
    if (consultant && consultant?.id) {
      const param = {
        consultant: `${consultant.slug}_${consultant.id}`
      }
      link = this.generateLink(
        'consultant-profile',
        param,
        rootStore?.agency?.widgetBaseUrl
      )
    } else {
      link = this.generateLink('list', {}, rootStore?.agency?.widgetBaseUrl)
    }
    canvasCardStore.setData('isTestimonials', true)

    socializeStore.setInitalData({
      // postType: 'anything',
      modalTitle: 'Socialise the Testimonial',
      title: isGoogleReview ? 'Google Review' : consultantFullName,
      description: testimonialData.text,
      link: link,
      jobId: testimonialData.id,
      tags: [],
      mainImage: consultantImage,
      mainWideImage: rootStore.agency.branding.logo.image,
      showAdditionalText: false,
      isGoogleReview
    })
    // this.onClose()
    canvasCardStore.getBackgroundsList()
    socializeStore.setData('updateSocialiseData', true)

    // socializeStore.setData('isOpenModal', true)
  }

  socialiseJobList = async (consultantData) => {
    const { socializeStore, rootStore, agenciesStore } = this.props

    const { agency } = rootStore
    socializeStore.setInitalData({
      postType: 'job_list',
      modalTitle: 'Socialise the Job Board',
      title:
        consultantData && consultantData.fullName
          ? consultantData.fullName
          : `Job Vacancies by ${agency.name}`,
      description:
        consultantData && consultantData.about
          ? consultantData.about.substring(0, 150)
          : agency.description.substring(0, 150),
      link: this.currentHref,
      tags: consultantData ? consultantData.specialities : [],
      mainImage: agency.branding.logo.image,
      mainWideImage: agency.branding.logo_wide.thumb,
      showAdditionalText: false,
      additionalImage: consultantData && consultantData.avatar
    })

    socializeStore.setData('updateSocialiseData', true)
  }

  socialiseConsultant = async (consultantData) => {
    const { socializeStore, rootStore, agenciesStore } = this.props
    const { agency } = rootStore

    socializeStore.setInitalData({
      postType: 'profile',
      modalTitle: 'Socialise the profile',
      title: consultantData.first_name + ' ' + consultantData.last_name,
      description: consultantData ? consultantData.about.substring(0, 150) : '',
      link: this.currentHref,
      tags: consultantData.specialities,
      mainImage: agency.branding.logo.image,
      mainWideImage: agency.branding.logo_wide.thumb,
      showAdditionalText: false,
      additionalImage: consultantData.avatar
    })

    socializeStore.setData('updateSocialiseData', true)
  }

  socialiseJob = (data) => {
    const { socializeStore, rootStore, canvasCardStore } = this.props
    let tags = []
    if (
      !rootStore.agency.socializeHashtags ||
      (rootStore.agency.socializeHashtags &&
        rootStore.agency.socializeHashtags.length < 1)
    ) {
      if (data.category && data.category.name) {
        tags.push(data.category.name)
      }
      if (data.location && data.location.name) {
        tags.push(data.location.name)
      }
      if (data.workType && data.workType.name) {
        tags.push(data.workType.name)
      }
    } else {
      tags = rootStore.agency.socializeHashtags
    }
    console.log('data from socialiseJob')
    console.log(data)
    socializeStore.setInitalData({
      postType: this.postType,
      title: data.title,
      description: data.summary,
      link: `${rootStore.agency.widgetBaseUrl}#social-link`,
      jobId: data.id,
      tags,
      mainImage: rootStore.agency.branding.logo.image,
      mainWideImage: rootStore.agency.branding.logo_wide.image,
      showAdditionalText: true,
      videoThumbs: data.video_thumbs
    })
    canvasCardStore.getBackgroundsList(data.video_thumbs)
    socializeStore.setData('updateSocialiseData', true)
  }

  getCandidateAdvertWidgetLink = (candidateAdId) => {
    const { rootStore } = this.props

    return `${widgetUrls.candidateAdDetailPage(
      rootStore.agency.talentBoardBaseUrl,
      candidateAdId
    )}`
  }

  socialiseCandidateAd = async (data) => {
    const { rootStore, socializeStore } = this.props

    let tags = []
    const candidateAdDetails = new CandidateAdsDetailsModel(data)
    const candidateSkills =
      candidateAdDetails?.skills.map((skill) => skill.name) || []
    if (candidateSkills.length) {
      tags = candidateSkills
    } else {
      if (
        rootStore.agency.socializeHashtags &&
        rootStore.agency.socializeHashtags.length
      ) {
        tags = rootStore.agency.socializeHashtags
      }
    }

    const candidateAdId = candidateAdDetails?.id
    socializeStore.setInitalData({
      postType: 'candidate_ad',
      candidateAdId: candidateAdId,
      modalTitle: 'Socialise Candidate Ad',
      title: candidateAdDetails?.title || '',
      description: candidateAdDetails?.summary || '',
      link: this.getCandidateAdvertWidgetLink(candidateAdDetails.id),
      tags: tags,
      mainImage: rootStore.agency.branding.logo.image,
      mainWideImage: rootStore.agency.branding.logo_wide.image,
      showAdditionalText: false,
      videoThumbs: []
    })

    socializeStore.setData('updateSocialiseData', true)
  }

  sendCloseModalEvent = () => {
    if (typeof window !== 'undefined') {
      let url =
        window.location != window.parent.location
          ? document.referrer
          : document.location.href
      window.parent.postMessage('CLOSE_SOCIAL_MODAL', url)
    }
    this.props.socializeStore.setData('openSocializeCommentModal', false)
  }

  closeModal = () => {
    const { socializeStore } = this.props
    socializeStore.setData('isOpenModalConfirm', true)
  }

  onSuccessShare = () => {
    console.log('onSuccessShare invoked')
    this.setState({ isSuccessShare: true })
  }

  render() {
    const { socializeStore, rootStore, canvasCardStore, theme } = this.props
    const { agency } = rootStore
    const { tintColor } = canvasCardStore
    const { sharingComplete } = socializeStore
    if (tintColor) {
      let color = hexToRgba(tintColor)
      color = color.replace('rgba(', '').replace(')', '')
      color = color.split(', ').slice(0, 3)
      canvasCardStore.setData('tintColorData', {
        rgb: { r: color[0], g: color[1], b: color[2] }
      })
    }
    // const { fetchingAgency } = rootStore
    if (!agency || (agency && !agency.id)) return <h1>Loading</h1>

    const style = {
      title: { color: theme.palette.primary1Color },
      background: { backgroundColor: theme.palette.primary1Color }
    }
    const { initialData } = socializeStore

    if (this.state.isSuccessShare)
      return (
        <div>
          <SocializeConfirmModal onClose={this.sendCloseModalEvent} />
          <SocialiseCommentModal
            isOpen={socializeStore.openSocializeCommentModal}
            publishData={socializeStore.publishData}
            onClose={this.sendCloseModalEvent}
            rootStore={rootStore}
            socializeStore={socializeStore}
          />
        </div>
      )
    // if (sharingComplete) return <div></div>

    return (
      <div
        className="refari-modal refari-custom-background-modal refari-custom-background-modal-socialise"
        style={{
          overflowScrolling: 'touch',
          WebkitOverflowScrolling: 'touch',
          backgroundColor: theme.widgetBackgroundColor,
          borderColor: theme.widgetBackgroundColor
        }}
      >
        <div className="refari-modal-content">
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            {initialData.modalTitle || 'Socialise the job'}
          </h3>
          <div style={{ width: '100%', margin: 'auto' }}>
            <NetworkTabContent
              closeModal={this.closeModal}
              socializeStore={socializeStore}
              rootStore={rootStore}
              canvasCardStore={canvasCardStore}
              onSuccessShare={this.onSuccessShare}
            />
          </div>
        </div>
        <SocializeConfirmModal onClose={this.sendCloseModalEvent} />
      </div>
    )
  }
}

export default withTheme(SocialiseJobPage)
