/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { computed, extendObservable } from 'mobx'
import User from './User'

const agency = 'agency'
const admin = 'admin'
const consultant = 'consultant'
const manager = 'manager'
const systemAdmin = 'default'

export default class Profile extends User {
  constructor(data) {
    if (!data) data = {}
    super(data)
    extendObservable(this, {
      isAdminLoggedAs: data.is_admin_logged_as,
      isCompleted: data.agency_user?.is_completed,
      branding:
        this.userType === agency
          ? data.agency_user.branding
          : data.referrer?.branding
    })
    if (data.user_type === agency) {
      extendObservable(this, {
        avatar: new Avatar(data.agency_user.avatar),
        about: data.agency_user.about,
        inviteStatus: data.agency_user.status || '',
        interests: data.agency_user.interests,
        specialities: data.agency_user.specialities || [],
        endorsements: data.agency_user.endorsements || [],
        linkedinProfileUrl: data.agency_user.linkedin_profile_url,
        customProfileURL: data.agency_user.custom_profile_url || '',
        video: new Video(data.agency_user.video),
        avatarAlternative: new Avatar(data.agency_user.avatar_alternative),
        agencies: data.agency_user.agencies,
        contactEmail: data.agency_user.contact_email,
        showProfileOnTeamWidget: data.agency_user.is_visible_on_team_widget,
        externalId: data.agency_user.external_id
      })
    }
  }

  @computed
  get isSystemAdminUser() {
    return this.userType === systemAdmin
  }

  @computed
  get isAgencyUser() {
    return this.userType === agency
  }

  @computed
  get isAgencyAdminUser() {
    return this.role === admin
  }

  @computed
  get isAgencyConsultantUser() {
    return this.role === consultant
  }

  @computed
  get isAgencyManagerUser() {
    return this.role === manager
  }

  @computed
  get slug() {
    return `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}`
  }
}

class Avatar {
  constructor(data) {
    if (!data) data = {}
    this.url = data.url
    this.id = data.id
  }
}

class Video {
  constructor(data) {
    if (!data) data = {}
    this.id = data.id
    this.url = data.url
    this.provider = data.provider
    this.externalId = data.external_id
  }
}
