import {
  observable,
  action,
} from 'mobx'
import { utils } from '../utils/helpers'


export class SpinnersStore {
  keys = {
    common: 'common',
    candidates: 'candidates',
    referrers: 'referrers',
    mails: 'mails',
    jobs: 'jobs',
    feedback: 'feedback',
  }

  get initSpinners() {
    const temp = {}
    Object.keys(this.keys).forEach((key) => {
      temp[this.keys[key]] = {
        loading: false,
      }
    })
    return temp
  }

  @observable spinners = this.initSpinners

  @action
  loadingIsStart = (key) => {
    this.spinners[key].loading = true
  }

  @action
  loadingIsEnd = (key) => {
    this.spinners[key].loading = false
  }

  @action
  loadingIsEndAfterTimeOut = (key) => {
    utils.delay(() => {
      this.loadingIsEnd(key)
    }, 1000)
  }
}

export default new SpinnersStore()
