import { RefariDTOTypes } from '@refari-frontend/types'

type QuestionSetsAPIType =
  RefariDTOTypes['/standalone/job-ads/{id}/question-sets/']['get']['responses']['200']['content']['application/json']['results'][0]

export type IQuestionSetsModel = {
  id: QuestionSetsAPIType['id']
  tag: QuestionSetsAPIType['tag']
  isSelected: QuestionSetsAPIType['is_selected']
  isSame: QuestionSetsAPIType['is_same']
}

class QuestionSetsModel implements IQuestionSetsModel {
  id: IQuestionSetsModel['id']
  tag: IQuestionSetsModel['tag']
  isSelected: IQuestionSetsModel['isSelected']
  isSame: IQuestionSetsModel['isSame']
  constructor(data: QuestionSetsAPIType) {
    this.id = data.id
    this.tag = data.tag
    this.isSelected = data.is_selected
    this.isSame = data.is_same
  }
}

export default QuestionSetsModel
