import capitalize from 'lodash.capitalize'

import formatWithComma from './formatWithComma'
import getCurrencySymbol from './getCurrencySymbol'

type FormatRangePerPeriod = {
  minValue: string
  maxValue: string
  period: string
  currencyCode?: string
}

const formatRangePerPeriod = ({
  minValue,
  maxValue,
  period,
  currencyCode
}: FormatRangePerPeriod): string => {
  const currencySymbol = currencyCode ? getCurrencySymbol(currencyCode) : ''

  return `${currencySymbol}${formatWithComma(
    minValue
  )} - ${currencySymbol}${formatWithComma(maxValue)} per ${capitalize(period)}`
}

export default formatRangePerPeriod
