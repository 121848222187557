import React from 'react'
import PropTypes from 'prop-types'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { TextField, IconButton } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import Copy from '@material-ui/icons/FileCopy'
import CloseModalButton from '../CloseModalButton'
import Modal from '../Modal'

@inject('pitchMeStore')
@observer
class PitchMeModal extends React.Component {
  static propTypes = {
    pitchMeStore: PropTypes.shape({
      isOpenModal: PropTypes.bool.isRequired,
      pitchMeLink: PropTypes.string
    })
  }

  @observable
  isCopied = false

  closeModal = () => {
    const { pitchMeStore } = this.props
    pitchMeStore.setData('isOpenModal', !pitchMeStore.isOpenModal)
  }

  onClick = (e) => {
    e.target.select()
  }

  @action
  setCopied = () => {
    this.isCopied = true
  }

  render() {
    const {
      pitchMeStore: { isOpenModal, pitchMeLink },
      theme
    } = this.props

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        open={isOpenModal}
        onClose={this.closeModal}
        classes={{
          container: 'iframe-dialog'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-socialize-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Pitch me
          </h3>
          <div style={{ width: '100%', maxWidth: 625, margin: 'auto' }}>
            <div className="refari-direct-link-wrap">
              <h4>Share direct link:</h4>
              <div className="refari-flex refari-copied-wrap">
                <TextField
                  fullWidth
                  name="directLink"
                  onClick={this.onClick}
                  value={pitchMeLink}
                  style={{ margin: 0 }}
                />
                <CopyToClipboard text={pitchMeLink} onCopy={this.setCopied}>
                  <IconButton>
                    <Copy />
                  </IconButton>
                </CopyToClipboard>
                {this.isCopied && (
                  <span className="refari-copied-msg">Copied!</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeModal)
