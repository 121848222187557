import React from 'react'
import PropTypes from 'prop-types'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react'
import { Button } from '@material-ui/core'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import Input from '../formComponents/Input'
import { validationErrors as errors } from '../../../utils/helpers'
import NonFieldError from '../formComponents/NonFieldError'
import PasswordHint from '../formComponents/PasswordHint'
import alertMessages from '../../../constants/alertMessages'

@observer
class SignUp extends React.Component {
  static propTypes = {
    authStore: PropTypes.shape({
      signUp: PropTypes.func.isRequired,
    }),
  }
  constructor() {
    super()
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      firstName: new Field({
        name: 'first_name',
        value: '',
        validators: [errors.required()],
      }),
      lastName: new Field({
        name: 'last_name',
        value: '',
        validators: [errors.required()],
      }),
      email: new Field({
        name: 'email',
        value: '',
        validators: [errors.required(), errors.isEmail()],
      }),
      password1: new Field({
        name: 'password1',
        value: '',
        validators: [errors.required(), errors.password()],
      }),
      password2: new Field({
        name: 'password2',
        value: '',
        validators: [errors.required(), errors.password()],
      }),
      recaptchaResponse: new Field({
        name: 'recaptcha_response',
        value: ''
      })
    })
  }

  submit = async (e) => {
    e.preventDefault()
    const { executeRecaptcha } = this.props.googleReCaptchaProps
    if (!executeRecaptcha) {
      toast.error(alertMessages.notValidated)
      return
    }
    const token = await executeRecaptcha()
    if (token) {
      this.form.fields.recaptchaResponse.update(token)
      this.form.submit(this.props.authStore.signUp)
    }
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <NonFieldError
          error={this.form.fields.nonFieldErrors.value}
        />
        <Input
          fieldData={this.form.fields.firstName}
          label="First name"
          required
        />
        <Input
          fieldData={this.form.fields.lastName}
          label="Last name"
          required
        />
        <Input
          fieldData={this.form.fields.email}
          label="Email"
          required
        />
        <Input
          fieldData={this.form.fields.password1}
          label="Password"
          type="password"
          showTicks
          isPasswordField
          required
        />
        <PasswordHint
          className={this.form.fields.password1.isFocused ? 'expand' : 'collapse'}
          inputValue={this.form.fields.password1.value}
          showTick
        />
        <Input
          fieldData={this.form.fields.password2}
          label="Confirm password"
          type="password"
          showTicks
          isPasswordField
          required
        />
        <Button
          variant="contained"
          type="submit"
          className="submit-btn"
          fullWidth
          color="primary"
          disabled={!this.form.isValid || this.form.isPending}
        >
          Sign Up
        </Button>
      </form>
    )
  }
}

export default withGoogleReCaptcha(SignUp)
