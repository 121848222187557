import red from '@material-ui/core/colors/red'
import { lighten } from '@material-ui/core/styles'

type DefaultPalette = {
  primaryColor: string
  accentColor: string
  textColor: string
  textColorContent: string
  black: string
  teal: string
}

export const defaultPalette: DefaultPalette = {
  primaryColor: '#0074BC',
  accentColor: '#24b3d5',
  textColor: '#3C4856',
  textColorContent: '#38383A',
  black: '#2B2B2B',
  teal: '#4CF8EE'
}

type CustomPalette = {
  primaryColor?: string
  accentColor?: string
  textColor?: string
}

type Header = {
  borderColor: string
  backgroundColor: any
}

type UploadField = {
  borderColor: string
}

type ListItem = {
  fontSize: string
}

type Subheader = {
  color: string
}

type Card = {
  titleColor: string
  subtitleColor: string
}

type TextField = {
  floatingLabelColor: string
  borderColor: string
  errorColor: string
  textColor: string
  hintColor: string
}

type Paper = {
  borderRadius: number
}

type RaisedButton = {
  borderRadius: number
}

type FlatButton = {
  borderRadius: number
}

type Tabs = {
  backgroundColor: string
  textColor: string
  selectedTextColor: string
}

type TableRow = {
  stripeColor: string
  borderColor: string
}

type Chip = {
  backgroundColor: string
  deleteIconColor: string
  textColor: string
}

type Palette = {
  primary: Primary
  primary1Color: string
  primary2Color: string
  accent1Color: string
  accent3Color: string
  textColor: string
  borderColor: string
  nonFieldErrorsColor: string
  whiteTextColor: string
}

type Primary = {
  main: string
  contrastText: string
}

type Slider = {
  trackColorSelected: string
}

export type MUIThemeFuncReturnType = {
  borderRadius: number
  header: Header
  uploadField: UploadField
  listItem: ListItem
  subheader: Subheader
  card: Card
  textField: TextField
  paper: Paper
  raisedButton: RaisedButton
  flatButton: FlatButton
  tabs: Tabs
  tableRow: TableRow
  chip: Chip
  palette: Palette
  slider: Slider
}

type MUIThemeFunc = (customPalette?: CustomPalette) => MUIThemeFuncReturnType

export const MUITheme: MUIThemeFunc = (customPalette = defaultPalette) => {
  const palette = {
    primary1Color: customPalette.primaryColor || defaultPalette.primaryColor,
    accent1Color: customPalette.accentColor || defaultPalette.accentColor,
    textColor: customPalette.textColor || defaultPalette.textColor
  }
  return {
    borderRadius: 2,
    header: {
      borderColor: lighten(palette.primary1Color, 0.6), // '#a2c2e5'
      backgroundColor: null
    },
    uploadField: {
      borderColor: lighten(palette.primary1Color, 0.6)
    },
    listItem: {
      fontSize: '11px'
    },
    subheader: {
      color: '#9e9e9e'
    },
    card: {
      titleColor: palette.textColor,
      subtitleColor: '#9e9e9e'
    },
    textField: {
      floatingLabelColor: '#9e9e9e',
      borderColor: '#9e9e9e',
      errorColor: red[500],
      textColor: palette.textColor,
      hintColor: '#9e9e9e'
    },
    paper: {
      borderRadius: 2
    },
    raisedButton: {
      borderRadius: 2
    },
    flatButton: {
      borderRadius: 2
    },
    tabs: {
      backgroundColor: '#fff',
      textColor: palette.primary1Color,
      selectedTextColor: palette.primary1Color
    },
    tableRow: {
      stripeColor: lighten(palette.primary1Color, 0.95),
      borderColor: lighten(palette.primary1Color, 0.6)
    },
    chip: {
      backgroundColor: palette.primary1Color,
      deleteIconColor: '#fff',
      textColor: '#fff'
    },
    palette: {
      primary: {
        main: palette.primary1Color,
        contrastText: '#fff'
      },
      primary1Color: palette.primary1Color,
      primary2Color: defaultPalette.primaryColor,
      // text: {
      //   primary: '#fff'
      // },
      // primary3Color: 'red',
      accent1Color: palette.accent1Color,
      // accent2Color: pinkA400,
      accent3Color: '#fff',
      textColor: palette.textColor,
      // secondaryTextColor: '#dba',
      // alternateTextColor: '#dba',
      // canvasColor: '#303030',
      borderColor: '#eceff1',
      // disabledColor: alpha(fullWhite, 0.3),
      // clockCircleColor: alpha(fullWhite, 0.12),
      nonFieldErrorsColor: red[500],
      whiteTextColor: '#fff'
    },
    slider: {
      trackColorSelected: '#bdbdbd'
    }
  }
}
