/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from 'react'
import {
  Button,
  CircularProgress,
  Avatar,
  TextField,
  Paper
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FileCopyOutlined } from '@material-ui/icons'
import { toast } from 'react-toastify'
import { inject, observer } from 'mobx-react'
import qp from 'query-parse'
import star from '../../../assets/images/star.png'
import URLs from '../../../router/routesUrls'
import GoogleIcon from '../../shared/icons/GoogleIcon'
import LinkedInIcon from '../../shared/icons/LinkedInIcon'
import FacebookIcon from '../../shared/icons/FacebookIcon'

@inject('testimonialsStore')
@observer
class TestimonialResponse extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setQuery(props.location.search.slice(1))
    this.state = {
      text: '',
      starsLength: [1, 2, 3, 4, 5],
      isCopied: false,
      error: ''
    }
    props.testimonialsStore.fetchTestimonialsResponses(this.query)
  }

  setQuery = (data) => {
    const newData = qp.toObject(data)
    this.query = {
      id: newData.id || newData['amp;id']
    }
  }

  handleChange = (e) => {
    this.setState({
      text: `${e.target.value}  ${
        e.target.value.length ? '[powered by TopRec].' : ''
      } `,
      error: ''
    })

    if (e.target.value.length === 1000) {
      this.setState({ error: 'Max width should be less then 1000 symbols' })
    }
  }

  componentDidMount() {
    const newText =
      this.props.testimonialsStore.testimonialsInfo &&
      this.props.testimonialsStore.testimonialsInfo.testimonial
        ? `${this.props.testimonialsStore.testimonialsInfo.testimonial.text} [powered by TopRec].`
        : ''
    this.setState({ text: newText })
  }

  nextStep() {
    this.props.history.push(URLs.testimonials.children.testimonialsShare)
  }

  setCopied = () => {
    this.setState({ isCopied: true })
  }

  handleClick =
    (url, social = '') =>
    () => {
      if (!this.state.isCopied) {
        toast.success('Copied review to your clipboard', {
          onClose: () => this.handleRedirect(url, social)
        })
      } else {
        this.handleRedirect(url, social)
      }
    }

  handleRedirect = (url, social) => {
    let newUrl = ''

    if (social === 'linkedin') {
      if (url.includes('/review') || url.includes('/review/')) {
        newUrl = url.replace('review', 'detail/recommendation/write')
      } else if (url.includes('/write') || url.includes('/write/')) {
        newUrl = url
      } else {
        newUrl = url.endsWith('/')
          ? `${url}detail/recommendation/write/`
          : `${url}/detail/recommendation/write/`
      }
    } else if (url.includes('/review') || url.includes('/review/')) {
      newUrl = url
    } else {
      newUrl = url.endsWith('/') ? `${url}review/` : `${url}/review/`
    }

    window.open(newUrl, '_blank')
  }

  render() {
    const {
      theme,
      testimonialsStore: { testimonialsInfo, fetchDate, redirect },
      history
    } = this.props
    const { text, isCopied } = this.state

    if (!fetchDate) {
      return (
        <div className="page-preload-spinner center-xs align-items-center full-width">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    if (redirect) {
      history.push(URLs.testimonials.children.testimonialsReviewed)
    }

    if (!testimonialsInfo.testimonial) {
      return null
    }

    return (
      <div className="flex-center-all full-width">
        <Paper className="testimonials-feedback testimonials-feedback__add-modal testimonials-feedback__share-modal">
          <p
            className="testimonials-feedback--header-content"
            style={{ color: theme.palette.primary1Color }}
          >
            Thanks for leaving your review!
          </p>
          <p className="testimonials-feedback--content-text testimonials-feedback--content-text-add m-t-1 m-b-2">
            Are you willing to help us with a public review? Review your
            consultant on social networks
          </p>
          <Avatar
            src={
              testimonialsInfo.testimonial.consultant.avatar &&
              testimonialsInfo.testimonial.consultant.avatar.thumb
            }
            style={{ height: 80, width: 80 }}
            size={80}
          />
          <p className="testimonials-feedback--user-name">
            {testimonialsInfo.testimonial.consultant.first_name}{' '}
            {testimonialsInfo.testimonial.consultant.last_name}
          </p>
          <div className="testimonials-feedback__stars full-width">
            {this.state.starsLength.map((el, i) => (
              <img
                key={i}
                src={star}
                className="testimonials-feedback--stars"
                alt=""
              />
            ))}
          </div>
          <div className="testimonials-feedback__share-review full-width m_top_2x m-bot-2x">
            <div className="title">Review your consultant</div>
            <div className="testimonials-feedback--review-input-block">
              <TextField
                placeholder={text}
                onChange={this.handleChange}
                className="testimonials-feedback--review-input"
                defaultValue={testimonialsInfo.testimonial.text}
                multiline
                rows={2}
                maxRows={3}
                inputProps={{
                  maxLength: 1000
                }}
                error={!!this.state.error}
                helperText={this.state.error}
              />
              <CopyToClipboard
                text={
                  text ||
                  `${testimonialsInfo.testimonial.text} [powered by TopRec].`
                }
                onCopy={this.setCopied}
              >
                <Button
                  variant="contained"
                  type="button"
                  className="submit-btn testimonials-feedback--review-button"
                  color="primary"
                  style={{ borderRadius: 5, textTransform: 'initial' }}
                  startIcon={<FileCopyOutlined />}
                >
                  {isCopied ? 'Copied!' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          {((testimonialsInfo.testimonial.publish_urls.google &&
            testimonialsInfo.social_net === 'google') ||
            (testimonialsInfo.testimonial.publish_urls.linkedin &&
              testimonialsInfo.social_net === 'linkedin') ||
            (testimonialsInfo.testimonial.publish_urls.facebook &&
              testimonialsInfo.social_net === 'facebook')) && (
            <div className="full-width testimonials-feedback__add-modal-submit m-b-2">
              <div className="social-title">
                <span>Post review on our social pages</span>
              </div>
              {testimonialsInfo.testimonial.publish_urls.google &&
                testimonialsInfo.social_net === 'google' && (
                  <CopyToClipboard
                    text={
                      text ||
                      `${testimonialsInfo.testimonial.text} [powered by TopRec].`
                    }
                    onCopy={this.setCopied}
                  >
                    <Button
                      variant="contained"
                      type="button"
                      className="testimonials-feedback--social"
                      style={{
                        borderRadius: 5,
                        textTransform: 'initial',
                        color: '#fff',
                        backgroundColor: '#E93F33'
                      }}
                      startIcon={<GoogleIcon fill="#fff" />}
                      onClick={this.handleClick(
                        `${testimonialsInfo.testimonial.publish_urls.google}`,
                        'google'
                      )}
                    >
                      Leave a Google review
                    </Button>
                  </CopyToClipboard>
                )}
              {testimonialsInfo.testimonial.publish_urls.facebook &&
                testimonialsInfo.social_net === 'facebook' && (
                  <CopyToClipboard
                    text={
                      text ||
                      `${testimonialsInfo.testimonial.text} [powered by TopRec].`
                    }
                    onCopy={this.setCopied}
                  >
                    <Button
                      variant="contained"
                      type="button"
                      className="testimonials-feedback--social"
                      style={{
                        borderRadius: 5,
                        textTransform: 'initial',
                        color: '#fff',
                        backgroundColor: '#3096F3'
                      }}
                      startIcon={<FacebookIcon fill="#fff" />}
                      onClick={this.handleClick(
                        `${testimonialsInfo.testimonial.publish_urls.facebook}`,
                        'facebook'
                      )}
                    >
                      Leave a Facebook review
                    </Button>
                  </CopyToClipboard>
                )}
              {testimonialsInfo.testimonial.publish_urls.linkedin &&
                testimonialsInfo.social_net === 'linkedin' && (
                  <CopyToClipboard
                    text={
                      text ||
                      `${testimonialsInfo.testimonial.text} [powered by TopRec].`
                    }
                    onCopy={this.setCopied}
                  >
                    <Button
                      variant="contained"
                      type="button"
                      className="testimonials-feedback--social"
                      style={{
                        borderRadius: 5,
                        textTransform: 'initial',
                        color: '#fff',
                        backgroundColor: '#2977B5'
                      }}
                      startIcon={<LinkedInIcon fill="#fff" />}
                      onClick={this.handleClick(
                        `${testimonialsInfo.testimonial.publish_urls.linkedin}`,
                        'linkedin'
                      )}
                    >
                      Leave a Linkedin review
                    </Button>
                  </CopyToClipboard>
                )}
            </div>
          )}
        </Paper>
      </div>
    )
  }
}

export default withTheme(TestimonialResponse)
