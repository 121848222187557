/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type SmartlinksIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const SmartlinksIcon = ({
  iconColor,
  ...restProps
}: SmartlinksIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="smartlinksIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M.064.031h24v24h-24z"
          data-name="Rectangle 1392"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#smartlinksIcon)" transform="translate(-.064 -.031)">
      <g fill={iconColor} data-name="Group 2984">
        <path
          d="M14.436 1.683 9.545 6.574c-.009.009-.015.021-.025.03a7.068 7.068 0 0 1 3.59.392l3.32-3.32a2.822 2.822 0 0 1 3.992 3.989l-4.891 4.891a2.85 2.85 0 0 1-3.988 0 1.412 1.412 0 0 0-1.994 0l-.856.856a5.594 5.594 0 0 0 .856 1.138 5.681 5.681 0 0 0 7.946.025c.009-.009.021-.015.03-.025l4.891-4.891a5.64 5.64 0 0 0-7.976-7.976Z"
          data-name="Path 2388"
        />
        <path
          d="m11.028 17.055-3.33 3.33a2.82 2.82 0 0 1-3.988-3.988l4.9-4.9a2.85 2.85 0 0 1 3.988 0 1.412 1.412 0 0 0 1.994 0l.856-.856a5.589 5.589 0 0 0-.856-1.138 5.678 5.678 0 0 0-7.946-.025c-.009.009-.021.015-.03.025l-4.9 4.9a5.64 5.64 0 0 0 7.976 7.978l4.9-4.9c.009-.009.015-.021.025-.03a7.068 7.068 0 0 1-3.59-.392Z"
          data-name="Path 2389"
        />
      </g>
    </g>
  </SvgIcon>
)

export default SmartlinksIcon
