import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Idea = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M57.318,166.155H12.746c-6.841,0-12.386,5.545-12.386,12.386s5.545,12.386,12.386,12.386h44.571    c6.842,0,12.387-5.545,12.387-12.386S64.159,166.155,57.318,166.155z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M499.251,166.155h-44.572c-6.84,0-12.386,5.545-12.386,12.386s5.546,12.386,12.386,12.386h44.572    c6.84,0,12.386-5.545,12.386-12.386S506.091,166.155,499.251,166.155z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M108.347,326.191c-4.837-4.837-12.679-4.837-17.517,0l-32.42,32.42c-4.837,4.837-4.837,12.679,0,17.517    c2.419,2.419,5.589,3.628,8.758,3.628s6.34-1.209,8.758-3.628l32.421-32.42C113.184,338.872,113.184,331.029,108.347,326.191z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M430.912,3.628c-4.838-4.837-12.678-4.837-17.517,0L381.863,35.16c-4.837,4.837-4.837,12.68,0,17.516    c2.42,2.417,5.59,3.627,8.759,3.627c3.171,0,6.339-1.21,8.758-3.628l31.531-31.531C435.748,16.307,435.748,8.464,430.912,3.628z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M130.117,35.142L98.602,3.628c-4.837-4.835-12.68-4.835-17.517,0c-4.837,4.837-4.837,12.68,0,17.517L112.6,52.66    c2.419,2.418,5.589,3.628,8.758,3.628s6.34-1.21,8.758-3.628C134.953,47.823,134.953,39.98,130.117,35.142z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M453.585,358.615l-32.3-32.303c-4.838-4.837-12.678-4.837-17.517,0.001c-4.837,4.837-4.837,12.68,0,17.516l32.301,32.301    c2.419,2.419,5.589,3.628,8.758,3.628c3.171,0,6.339-1.21,8.758-3.628C458.422,371.294,458.422,363.451,453.585,358.615z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M255.999,33.636c-85.648,0-155.327,72.885-155.327,162.474c0,41.65,14.919,86.482,40.933,123    c19.979,28.045,30.538,59.249,30.538,90.24v77.875c0,13.659,11.113,24.772,24.772,24.772h118.169    c13.659,0,24.772-11.113,24.772-24.772V409.35c0-30.99,10.559-62.195,30.536-90.24c26.014-36.517,40.934-81.35,40.934-123    C411.324,106.521,341.645,33.636,255.999,33.636z M315.082,487.227H196.913v-16.201h59.085c6.841,0,12.387-5.545,12.387-12.386    s-5.546-12.386-12.387-12.386h-59.085v-16.2h118.172l0.014,57.171C315.098,487.227,315.093,487.227,315.082,487.227z     M238.629,256.141l-16.564-35.387h67.866l-16.562,35.387c-0.652,1.393-1.039,2.896-1.142,4.43l-9.605,144.711h-13.247    l-9.605-144.711C239.668,259.037,239.28,257.534,238.629,256.141z M350.215,304.738c-22.123,31.056-34.208,65.733-35.082,100.544    h-27.684l9.341-140.749l23.831-50.915c1.796-3.836,1.507-8.325-0.767-11.899c-2.273-3.573-6.215-5.738-10.451-5.738H202.592    c-4.236,0-8.177,2.165-10.451,5.738c-2.273,3.573-2.563,8.062-0.767,11.898l23.832,50.915l9.341,140.749h-27.686    c-0.874-34.812-12.958-69.488-35.082-100.544c-22.754-31.942-36.338-72.551-36.338-108.628    c0-75.93,58.567-137.702,130.555-137.702s130.555,61.772,130.555,137.702C386.553,232.188,372.969,272.797,350.215,304.738z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Idea
Idea.propTypes = propTypes
Idea.defaultProps = defaultProps
