import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Recruitment = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M460.934,268.491c4.524-6.708,7.168-14.784,7.168-23.466v-8.021c0-23.203-18.878-42.081-42.081-42.081    c-11.577,0-22.077,4.701-29.693,12.293C376.622,148.609,321.163,106.275,256,106.275s-120.622,42.333-140.328,100.941    c-7.616-7.592-18.115-12.293-29.693-12.293c-23.203,0-42.081,18.878-42.081,42.081v8.021c0,8.682,2.644,16.758,7.168,23.466    C21.937,274.629,0,300.524,0,331.452v20.134c0,5.522,4.478,10,10,10h52c5.522,0,10-4.478,10-10c0-5.522-4.478-10-10-10H20v-10.134    c0-24.453,19.894-44.347,44.347-44.347h47.329c3.838,16.881,10.565,32.677,19.641,46.831L17.218,448.035    c-14.61,14.611-14.61,38.386,0.001,52.998c7.306,7.306,16.901,10.958,26.498,10.958c9.597,0,19.194-3.653,26.5-10.958    l62.903-62.904c3.905-3.905,3.905-10.237,0-14.143c-3.906-3.904-10.236-3.904-14.143,0L56.075,486.89    c-6.815,6.814-17.901,6.813-24.715,0.001c-6.813-6.814-6.813-17.901,0-24.715L143.367,350.17    c27.167,31.862,67.578,52.105,112.633,52.105c70.328,0,129.35-49.31,144.324-115.17h47.329c24.453,0,44.347,19.894,44.347,44.347    v10.134h-94c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h104c5.522,0,10-4.478,10-10v-20.134    C512,300.524,490.063,274.629,460.934,268.491z M108.06,245.026c0,12.175-9.905,22.08-22.081,22.08s-22.081-9.905-22.081-22.08    v-8.021c0-12.176,9.905-22.081,22.081-22.081c12.176,0,22.081,9.905,22.081,22.081V245.026z M256,382.275    c-70.579,0-128-57.421-128-128s57.421-128,128-128s128,57.421,128,128S326.579,382.275,256,382.275z M448.101,245.026    c0,12.175-9.905,22.08-22.081,22.08c-12.176,0-22.081-9.905-22.081-22.08v-8.021c0-12.176,9.905-22.081,22.081-22.081    c12.176,0,22.081,9.905,22.081,22.081V245.026z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,146.275c-59.552,0-108,48.448-108,108c0,30.91,13.056,58.825,33.938,78.53c0.357,0.386,0.74,0.75,1.16,1.08    c19.226,17.621,44.829,28.39,72.902,28.39s53.676-10.77,72.902-28.39c0.42-0.329,0.803-0.694,1.16-1.08    C350.944,313.1,364,285.185,364,254.275C364,194.724,315.552,146.275,256,146.275z M256,342.275    c-20.563,0-39.499-7.091-54.497-18.954c10.625-20.104,31.331-32.753,54.497-32.753s43.872,12.649,54.497,32.753    C295.499,335.184,276.563,342.275,256,342.275z M234.927,249.495v-7.769c0-11.62,9.453-21.073,21.073-21.073    s21.073,9.453,21.073,21.073v7.769c0,11.62-9.453,21.073-21.073,21.073S234.927,261.115,234.927,249.495z M325.125,308.674    c-9.161-14.617-22.57-25.764-38.264-32.12c6.349-7.233,10.212-16.7,10.212-27.058v-7.769c0-22.647-18.426-41.073-41.073-41.073    c-22.647,0-41.073,18.426-41.073,41.073v7.769c0,10.358,3.862,19.825,10.212,27.058c-15.694,6.357-29.102,17.503-38.264,32.12    C175.059,293.692,168,274.793,168,254.275c0-48.523,39.477-88,88-88s88,39.477,88,88C344,274.793,336.941,293.692,325.125,308.674    z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M159.87,397.239c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93c-1.859,1.86-2.93,4.44-2.93,7.07    s1.07,5.21,2.93,7.07c1.86,1.859,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07    S161.73,399.099,159.87,397.239z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256.203,0.009c-5.522,0-10,4.478-10,10v34.024c0,5.523,4.478,10,10,10c5.522,0,10-4.478,10-10V10.009    C266.203,4.487,261.725,0.009,256.203,0.009z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M263.269,69.939c-1.859-1.86-4.43-2.93-7.069-2.93c-2.631,0-5.21,1.069-7.07,2.93c-1.86,1.861-2.93,4.44-2.93,7.07    c0,2.63,1.069,5.21,2.93,7.069c1.86,1.86,4.439,2.931,7.07,2.931c2.64,0,5.21-1.07,7.069-2.931    c1.86-1.859,2.931-4.439,2.931-7.069C266.2,74.379,265.13,71.799,263.269,69.939z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M340.979,39.235c-3.906-3.904-10.236-3.904-14.143,0L309.26,56.811c-3.905,3.905-3.905,10.237,0,14.143    c1.954,1.952,4.512,2.929,7.072,2.929s5.118-0.977,7.071-2.929l17.576-17.576C344.884,49.473,344.884,43.141,340.979,39.235z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M202.908,56.932l-17.745-17.745c-3.906-3.904-10.236-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143l17.746,17.745    c1.953,1.952,4.512,2.929,7.071,2.929c2.559,0,5.118-0.977,7.071-2.929C206.813,67.17,206.813,60.838,202.908,56.932z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Recruitment
Recruitment.propTypes = propTypes
Recruitment.defaultProps = defaultProps
