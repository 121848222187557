import React, { Component } from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { withTheme } from '@material-ui/core/styles'
import { Chip, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import type { RenderTagProps } from 'react-tagsinput'

import type { MUIThemeFuncReturnType } from 'src/utils/MUITheme'
import type { IField } from 'src/models/form/Field'

type TagsRendererProps = {
  field: IField
  theme: MUIThemeFuncReturnType
  showInputTag: boolean
  removeTags: (
    onRemove: RenderTagProps['onRemove'],
    key: RenderTagProps['key']
  ) => void
  toggleInput: () => void
  inBetweenValues?: 'OR' | 'AND'
  onRemove: RenderTagProps['onRemove']
  parentKey: RenderTagProps['key']
  tag: RenderTagProps['tag']
  getTagDisplayValue: RenderTagProps['tag']
}

@observer
class TagsRenderer extends Component<TagsRendererProps> {
  handleChipDelete = () => {
    const { removeTags, onRemove, parentKey } = this.props

    removeTags(onRemove, parentKey)
  }

  render() {
    const {
      field,
      inBetweenValues,
      showInputTag,
      theme,
      tag,
      parentKey,
      getTagDisplayValue,
      toggleInput
    } = this.props

    const { value = '' } = field

    let showBetweenText = true

    if (
      value &&
      toJS(value).length > 0 &&
      toJS(value).length - 1 === parentKey
    ) {
      showBetweenText = false
    }

    return (
      <div
        className={classNames({
          'refari-react-tagsinput-tag-wrap': true,
          'refari-react-tagsinput-tag-wrap-new': Boolean(inBetweenValues)
        })}
      >
        <Chip
          style={{
            color: '#fff',
            backgroundColor: inBetweenValues
              ? '#65707b'
              : theme.palette.primary1Color
          }}
          label={getTagDisplayValue(tag)}
          onDelete={this.handleChipDelete}
        />

        {Boolean(inBetweenValues) && showBetweenText && (
          <span
            className={classNames({
              'refari-react-tagsinput-between-op': true,
              'refari-react-tagsinput-between-op-or': inBetweenValues === 'OR'
            })}
          >
            {inBetweenValues}
          </span>
        )}

        {Boolean(inBetweenValues) && !showBetweenText && !showInputTag && (
          <div className="refari-react-tagsinput-tag-add-button">
            <IconButton
              className="add-tag-button"
              onClick={toggleInput}
              style={{ color: theme.palette.primary1Color || '#000' }}
            >
              <AddIcon
                width="35"
                height="35"
                style={{ width: 30, height: 30 }}
                fill={theme.palette.primary1Color || '#000'}
              />
            </IconButton>
          </div>
        )}
      </div>
    )
  }
}

// @ts-ignore TODO:: fix HOC type
// see https://github.com/mui/material-ui/issues/8070
export default withTheme(TagsRenderer)
