import { Categories } from '../../types/APITypes'

type CategoryModelSchema = {
  id: number
  name: string
  value: number
}

export type ICategoriesModel = CategoryModelSchema & {
  children: {
    parentCategoryId: number
    subCategories: Array<CategoryModelSchema> | []
  }
}

class CategoriesModel implements ICategoriesModel {
  id: ICategoriesModel['id']
  name: ICategoriesModel['name']
  value: ICategoriesModel['value']
  children: ICategoriesModel['children']

  constructor(data: Categories) {
    this.id = data.id
    this.name = data.name
    this.value = data.id
    this.children = this.normalizeChildren(data)
  }

  normalizeChildren(data: Categories): ICategoriesModel['children'] {
    return {
      parentCategoryId: data.id,
      subCategories:
        data.children.length < 1
          ? []
          : data.children.map((subCategory) => ({
              id: subCategory.id,
              value: subCategory.id,
              name: subCategory.name
            }))
    }
  }
}

export default CategoriesModel
