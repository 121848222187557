/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { Paper } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import ThumbUp from '../../shared/icons/ThumbUp'

class SecondReview extends React.Component {
  render() {
    return (
      <div className="flex-center full-width">
        <Paper className="testimonials-feedback testimonials-feedback__thank-modal">
          <ThumbUp fill={this.props.theme.palette.primary1Color} />
          <p
            className="testimonials-feedback--header-content m-t-2"
            style={{ color: this.props.theme.palette.primary1Color }}
          >
            You've already left a feedback
          </p>
        </Paper>
      </div>
    )
  }
}

export default withTheme(SecondReview)
