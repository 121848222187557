import React from 'react'
import PropTypes from 'prop-types'

import { withTheme } from '@material-ui/core/styles'


const propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  theme: PropTypes.object.isRequired,
}

const NonFieldError = ({ error, theme }) => {
  const style = {
    color: theme.palette.nonFieldErrorsColor,
  }
  return (
    <div
      style={style}
      className="non-field-errors"
    >
      {error}
    </div>
  )
}

export default withTheme(NonFieldError)
NonFieldError.propTypes = propTypes
