import React from 'react'
import { observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { toast } from 'react-toastify'
import FlatButton from '../common/FlatButton'
import API from '../../../utils/API'
import { validationErrors } from '../../../utils/helpers'
import Field from '../../../models/form/Field'
import validationMsg from '../../../constants/validationErrorMessages'
import { Dialog, DialogContent } from '@material-ui/core'
import { CropperImg } from 'src/components/pages/consultants/CropperImg'

type UploadImageSocialiseProps = {
  fieldData: Field
  className?: string
  btnText: string
  onPendingState: (result: boolean) => void
  accept: string
  maxFileSize?: number
  fileKey: string
  uploadUrl: string
}

type UploadImageSocialiseState = {
  isCropDialogOpen: boolean
  blobUrl?: string
  imageFile: File | null
}

@observer
class UploadImageSocialise extends React.Component<
  UploadImageSocialiseProps,
  UploadImageSocialiseState
> {
  static defaultProps = {
    className: '',
    maxFileSize: 10
  }

  state = {
    isCropDialogOpen: false,
    blobUrl: undefined,
    imageFile: null
  }

  uploadInputRef: React.RefObject<HTMLInputElement> = React.createRef()

  openCropperDialog = async (file: File) => {
    if (typeof window !== 'undefined') {
      window.URL = window.URL || window.webkitURL
      const generatedBlobUrl = window.URL.createObjectURL(file)
      this.setState({
        isCropDialogOpen: true,
        blobUrl: generatedBlobUrl,
        imageFile: file
      })
    }
  }

  onChangeFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files && e.target.files[0]

    if (!file) return
    this.openCropperDialog(file)
  }

  onCropComplete = async (file: File) => {
    const { name } = file
    const { uploadUrl, fileKey, onPendingState, maxFileSize } = this.props

    if (validationErrors.maxFileSize(maxFileSize, file.size)) {
      toast.error(validationMsg.maxFileSize(maxFileSize, file.size))
      return
    }
    const formData = new FormData()
    formData.append(fileKey, file)

    this.pending(true)
    onPendingState && onPendingState(true)

    try {
      const response = await API.postData(uploadUrl, formData)
      this.pending(false)
      onPendingState && onPendingState(false)
      const { data } = response
      this.props.fieldData.update(data.id, {
        name,
        path: data[fileKey] || data[`${fileKey}_thumb`],
        // @ts-ignore Keep it as it is
        custom: true
      })
    } catch (error) {
      this.pending(false)
      onPendingState && onPendingState(false)
      // @ts-ignore find the type for error response type
      const { status, data } = error.response
      if (status === 400) {
        toast.error(data[fileKey])
      }
    }
  }

  @observable isPending = false

  @action pending = (bool: boolean) => {
    this.isPending = bool
  }

  render() {
    const { className, btnText, accept } = this.props
    return (
      <div className={`refari-field-file-wrap ${className}`}>
        <FlatButton
          color="primary"
          className="refari-file-btn refari-button-flat"
          component="label"
          disabled={this.isPending}
        >
          <input
            ref={this.uploadInputRef}
            aria-label={btnText}
            disabled={this.isPending}
            className="refari-file-input"
            onChange={this.onChangeFileInput}
            type="file"
            accept={accept || '*'}
          />
          {btnText}
        </FlatButton>
        <Dialog
          fullWidth
          open={this.state.isCropDialogOpen}
          onClose={() => {
            this.setState({ isCropDialogOpen: false })
          }}
          maxWidth="sm"
        >
          <DialogContent className="padding-2">
            <CropperImg
              handleCropper={(croppedImageFile: File) => {
                this.onCropComplete(croppedImageFile)
                this.setState({
                  isCropDialogOpen: false,
                  blobUrl: undefined,
                  imageFile: null
                })
                this.uploadInputRef.current?.value &&
                  (this.uploadInputRef.current.value = '')
              }}
              verticalLayout
              image={this.state.blobUrl}
              file={this.state.imageFile}
              aspectRatio={2 / 1}
              expectedWidth={1040}
              expectedHeight={544}
              width={500}
              height={250}
              showError
              handleCancel={() => {
                this.setState({
                  isCropDialogOpen: false,
                  blobUrl: undefined,
                  imageFile: null
                })
                this.uploadInputRef.current?.value &&
                  (this.uploadInputRef.current.value = '')
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

export default UploadImageSocialise
