import React from 'react'
import { SvgIcon } from '@material-ui/core'

const PencilIcon = (props) => {
  const style = {
    width: '21px',
    height: '39px',
    marginTop: 5,
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 21 26"
    >
      <path
        d="M17.2583 5.8668C17.5833 5.5418 17.5833 5.00013 17.2583 4.6918L15.3083 2.7418C15 2.4168 14.4583 2.4168 14.1333 2.7418L12.6 4.2668L15.725 7.3918L17.2583 5.8668ZM2.5 14.3751V17.5001H5.625L14.8417 8.27513L11.7167 5.15013L2.5 14.3751Z"
        fill={props.fill}
      />
    </SvgIcon>
  )
}

export default PencilIcon

PencilIcon.defaultProps = {
  fill: '#fff'
}
