import { RefariDTOTypes } from '@refari-frontend/types'

type ConsultantAPITypes =
  RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']['results'][0]['candidate_ad']['consultant']

export type IConsultantModel = {
  id: ConsultantAPITypes['id']
  firstName: ConsultantAPITypes['first_name']
  lastName: ConsultantAPITypes['last_name']
  fullName: string
}

class Consultant implements IConsultantModel {
  id: IConsultantModel['id']
  firstName: IConsultantModel['firstName']
  lastName: IConsultantModel['lastName']
  fullName: string

  constructor(data: ConsultantAPITypes) {
    this.id = data['id']
    this.firstName = data['first_name']
    this.lastName = data['last_name']
    this.fullName = `${data['first_name']} ${data['last_name']}`
  }
}

export default Consultant
