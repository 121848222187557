import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string
}

const defaultProps = {
  className: '',
  fill: ''
}

const StarIcon = forwardRef((props, ref) => {
  const style = {
    width: '24px',
    height: '24px',
    margin: '5px 0',
    ...props.style
  }
  return (
    <SvgIcon ref={ref} {...props} style={style} viewBox="0 0 24 24">
      <path
        d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.62L12 2L9.19 8.62L2 9.24L7.45 13.97L5.82 21L12 17.27Z"
        fill={props.fill || '#F2C94C'}
      />
    </SvgIcon>
  )
})

StarIcon.displayName = StarIcon

export default withTheme(StarIcon)
StarIcon.propTypes = propTypes
StarIcon.defaultProps = defaultProps
