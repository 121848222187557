import { RefariDTOTypes } from '@refari-frontend/types'

type SalaryAPITypes =
  RefariDTOTypes['/dashboard/hiring-manager/enquiries/']['get']['responses']['200']['content']['application/json']['results'][0]['candidate_ad']['salary']

type NoNullsableSalary = NonNullable<SalaryAPITypes>
type NonNullableRateMax = NonNullable<NoNullsableSalary['rate_max']>

type IRate = {
  amount: NonNullableRateMax['amount']
  currency: NonNullableRateMax['currency']
  currencySign: NonNullableRateMax['currency_sign']
}

class Rate implements IRate {
  amount: IRate['amount']
  currency: IRate['currency']
  currencySign: IRate['currencySign']

  constructor(data: NonNullableRateMax) {
    this.amount = data['amount']
    this.currency = data['currency']
    this.currencySign = data['currency_sign']
  }
}

export type ISalaryModel = {
  period: NoNullsableSalary['period']
  rateMax?: IRate
  rateMin?: IRate
}

class Salary implements ISalaryModel {
  period: ISalaryModel['period']
  rateMax: ISalaryModel['rateMax']
  rateMin: ISalaryModel['rateMin']

  constructor(data: NoNullsableSalary) {
    this.period = data['period']
    this.rateMax = data['rate_max'] ? new Rate(data['rate_max']) : undefined
    this.rateMin = data['rate_min'] ? new Rate(data['rate_min']) : undefined
  }
}

export default Salary
