/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import {
  observable, action, computed
} from 'mobx'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import alertMessages from '../constants/alertMessages'
import API from '../utils/API'
import Logger from '../utils/Logger'
import { ApiRoutes } from '../utils/Urls'

export class PlacementStore {
  @observable.ref placementList = []
  @observable.ref selectedList = []

  @observable.ref allResultIds = []

  @observable.ref filters = {}
  @observable pagination = this.initPagination
  @observable isFetching = true
  @observable allSelected = false
  @observable loader = true
  @observable inviteLoader = false
  @observable requestRatingModal = false
  @observable requestARatingLink = ''
  @observable refreshImport = false
  @observable sendLoader = false

  @observable EntityConstructor
  @observable placementFilter = '3'

  initPagination = {
    totalCount: 0,
    pageCount: 0,
    pageCurrent: 1,
    pageSize: 0,
  }

  constructor(entityConfig = {}, paramsConfig = {}) {
    this.EntityConstructor = entityConfig.entityConstructor
    this.filters = paramsConfig.filters
  }

  @computed
  get getFullParamsData() {
    let filterObj = {}

    if (this.filters) {
      filterObj = { ...this.filters.dataWithoutEmptyValue }
    }

    if (this.placementFilter) {
      filterObj.date_to = moment().subtract(parseInt(this.placementFilter), 'months').format('YYYY-MM-DD')
    }

    filterObj.page = this.pagination.pageCurrent
    return filterObj
  }

  @action
  fetchList = async () => {
    try {
      const response = await API.getData(ApiRoutes.dashboard.placements.testimonials.list, this.getFullParamsData)
      const results = response.data.results.map((item) => new this.EntityConstructor(item))
      // eslint-disable-next-line no-return-assign
      results.forEach((item) => {
        if (this.selectedList && this.selectedList.length > 0 && this.selectedList.indexOf(item.id) !== -1) {
          item.selected = true
        }
      })

      this.setData('placementList', results)
      this.setData('allResultIds', response.data.all_result_ids || [])

      this.setPaginateParams({
        totalCount: response.data.count,
        pageCount: response.data.page_count,
        pageCurrent: response.data.page,
        pageSize: response.data.page_size,
      })
      this.setData('isFetching', false)
      this.setData('loader', false)
      this.setData('sendLoader', false)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  refreshPlacements = async () => {
    try {
      const response = await API.postData(ApiRoutes.dashboard.placements.testimonials.import, {})
      if (response) {
        await this.fetchList()
        toast.success(alertMessages.refreshPlacements)
      }
      this.setData('refreshImport', false)
    } catch (error) {
      this.setData('refreshImport', false)
      Logger.error(error)
    }
  }

  @action
  invitePlacements = async (cb) => {
    try {
      this.setData('inviteLoader', true)
      const response = await API.getData(ApiRoutes.dashboard.placements.invite)
      cb && cb(response)
      this.setData('inviteLoader', false)
    } catch (error) {
      this.setData('inviteLoader', false)
      Logger.error(error)
    }
  }

  @action
  sendPlacementsEmail = async () => {
    try {
      this.setData('sendLoader', true)
      await API.postData(ApiRoutes.dashboard.placements.testimonials.send, { items: this.selectedList })
      this.setData('allSelected', false)
      this.setData('selectedList', [])
      await this.fetchList()
      toast.success(alertMessages.placementsUpdated)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  toggleRequestRatingModal = () => {
    this.requestRatingModal = !this.requestRatingModal
  }

  @action
  setFilters = () => {
    this.setData('loader', true)
    this.setInitPagination()
    this.fetchList()
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }

  @action
  onPaginate = (event, pageCurrent) => {
    this.setData('loader', true)
    this.setPaginateParams({ pageCurrent })
    this.fetchList()
  }

  @action
  setPaginateParams = ({
    pageCurrent, pageCount, totalCount, pageSize
  }) => {
    if (pageCurrent) {
      this.pagination.pageCurrent = pageCurrent
    }

    if (pageCount) {
      this.pagination.pageCount = pageCount
    }

    if (totalCount) {
      this.pagination.totalCount = totalCount
    }

    if (pageSize) {
      this.pagination.pageSize = pageSize
    }
  }

  @action
  setInitPagination = () => {
    this.pagination = this.initPagination
    this.placementList = []
  }

  @computed
  get shouldShowPagination() {
    return this.pagination.totalCount > this.pagination.pageSize
  }
}

export default PlacementStore
