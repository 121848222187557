/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CommunicationIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const CommunicationIcon = ({
  iconColor,
  ...restProps
}: CommunicationIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="communicationIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M-2.063-.698h24v24h-24z"
          data-name="Rectangle 1396"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#communicationIcon)" transform="translate(2.063 .698)">
      <g fill={iconColor} data-name="Group 2980">
        <path
          d="M17.733 4.979h-.639v5.767a4.491 4.491 0 0 1-4.486 4.486H7.915l-2.46 1.9a3.2 3.2 0 0 0 1.941.668h5.129l5.85 4.507v-4.571a3.21 3.21 0 0 0 2.563-3.14V8.179a3.208 3.208 0 0 0-3.2-3.2Zm0 0"
          data-name="Path 2404"
        />
        <path
          d="M7.35 13.118h5.129a3.208 3.208 0 0 0 3.2-3.2V3.502a3.208 3.208 0 0 0-3.2-3.2H2.137a3.208 3.208 0 0 0-3.2 3.2V9.91A3.21 3.21 0 0 0 1.5 13.05v4.571ZM2.782 3.502h9.055v1.286H2.782Zm0 2.563h9.055v1.286H2.782Zm0 2.563h6.494V9.91H2.782Zm0 0"
          data-name="Path 2405"
        />
      </g>
    </g>
  </SvgIcon>
)

export default CommunicationIcon
