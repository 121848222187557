import { RefariDTOTypes } from '@refari-frontend/types'

type GoogleReviewAccountResultResponseType =
  RefariDTOTypes['/dashboard/agency/google-business/']['get']['responses']['200']['content']['application/json']['results'][0]

class GoogleBusinessReviewAccount {
  id: GoogleReviewAccountResultResponseType['id']
  accountTitle: GoogleReviewAccountResultResponseType['account_title']
  locationTitle: GoogleReviewAccountResultResponseType['location_title']
  owner: GoogleReviewAccountResultResponseType['owner']
  needPublish: GoogleReviewAccountResultResponseType['need_publish']
  lastSynced: GoogleReviewAccountResultResponseType['last_sync_request']
  isBlocked: GoogleReviewAccountResultResponseType['is_blocked']
  testimonialCount: GoogleReviewAccountResultResponseType['testimonial_count']
  isPrimary: GoogleReviewAccountResultResponseType['is_primary']

  constructor(data: GoogleReviewAccountResultResponseType) {
    this.id = data.id
    this.accountTitle = data.account_title
    this.locationTitle = data.location_title
    this.owner = data.owner
    this.needPublish = data.need_publish
    this.lastSynced = data.last_sync_request
    this.isBlocked = data.is_blocked
    this.testimonialCount = data.testimonial_count
    this.isPrimary = data.is_primary
  }

  get ownerName(): string {
    return `${this.owner.first_name} ${this.owner.last_name}`
  }

  get OwnerId(): number {
    return this.owner.id
  }
}

export { GoogleBusinessReviewAccount }
