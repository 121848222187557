import { RefariDTOTypes } from '@refari-frontend/types'

export type DetailedLocation =
  RefariDTOTypes['/dashboard/job-ads/{id}/']['get']['responses']['200']['content']['application/json']['locations'][0]['detailed_location']

const formatDetailedLocation = (detailedLocation: DetailedLocation): string => {
  if (!detailedLocation) {
    return ''
  }

  const formattableData = []

  if (detailedLocation.country) {
    formattableData.push(detailedLocation.country.country)
  }

  if (detailedLocation.state) {
    formattableData.push(detailedLocation.state.name)
  }

  if (detailedLocation.city) {
    formattableData.push(detailedLocation.city.name)
  }

  if (detailedLocation.postal_code) {
    formattableData.push(detailedLocation.postal_code)
  }

  return formattableData.join(', ')
}

export default formatDetailedLocation
