import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'
import {
  MenuItem,
  Select as MUISelect,
  FormHelperText,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { toJS } from 'mobx'
import Field from '../../../models/form/Field'

const styles = () => ({
  select: {
    '& li': {
      '&.Mui-selected': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&::after': {
          content: '"\\2713"',
          display: 'flex',
          textAlign: 'right',
          color: 'green'
        }
      }
    }
  }
})
@observer
class MultipleSelect extends React.Component {
  static propTypes = {
    fieldData: PropTypes.instanceOf(Field).isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
    optionValueKey: PropTypes.string,
    optionTextKey: PropTypes.string,
    emptyOption: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoWidth: PropTypes.bool,
    autoClose: PropTypes.bool,
    style: PropTypes.object,
    menuItemStyle: PropTypes.object,
    renderValue: PropTypes.func,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    labelStyle: PropTypes.object,
    MenuProps: PropTypes.object
  }

  static defaultProps = {
    className: '',
    optionValueKey: 'id',
    optionTextKey: 'name',
    autoWidth: false,
    menuItemStyle: {},
    disabled: false,
    autoClose: false
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleClose = () => {
    const { onSubmit, fieldData } = this.props
    const { value } = fieldData
    typeof onSubmit === 'function' &&
      onSubmit(typeof toJS(value) === 'string' ? [] : toJS(value))
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  onChange = (e) => {
    if (!e.target.value) return
    this.props.fieldData.update(e.target.value)
    this.props.cb && this.props.cb()
    if (this.props.autoClose) {
      this.handleClose()
    }
  }

  render() {
    const {
      fieldData,
      label,
      className,
      options,
      optionValueKey,
      optionTextKey,
      autoWidth,
      style,
      emptyOption,
      labelStyle,
      menuItemStyle,
      renderValue,
      disabled,
      classes,
      valueTextKey,
      MenuProps
    } = this.props

    const { serverErrors, clientErrors, isTouched, value } = fieldData
    const errors = serverErrors.length
      ? serverErrors
      : isTouched && clientErrors
    const { open } = this.state

    return (
      <div className={className}>
        <FormControl
          error={errors && errors.length > 0}
          style={{ width: '100%' }}
        >
          {label && <InputLabel>{label}</InputLabel>}
          <MUISelect
            displayEmpty
            value={typeof toJS(value) === 'string' ? [] : toJS(value)}
            onChange={this.onChange}
            MenuProps={{
              classes: { paper: classes.select },
              // see https://github.com/mui/material-ui/issues/19245#issuecomment-731701960
              getContentAnchorEl: null,
              ...MenuProps
            }}
            autoWidth={autoWidth}
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            style={{ ...style, ...labelStyle }}
            multiple
            renderValue={renderValue}
            disabled={disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            {emptyOption && (
              <MenuItem key={`${label}option`} value="" className="empty-value">
                {emptyOption}
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem
                style={menuItemStyle}
                key={
                  valueTextKey
                    ? `${option}option`
                    : `${option[optionValueKey]}option`
                }
                value={valueTextKey ? option : option[optionValueKey]}
                selected={
                  value.indexOf(
                    valueTextKey ? option : option[optionValueKey]
                  ) > -1
                }
              >
                {valueTextKey ? option : option[optionTextKey]}
              </MenuItem>
            ))}
          </MUISelect>
          {errors && errors.length > 0 && (
            <FormHelperText>{errors[0]}</FormHelperText>
          )}
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(MultipleSelect)
