import { action, observable, computed, runInAction } from 'mobx'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { RefariDTOTypes } from '@refari-frontend/types'
import { toast } from 'react-toastify'

import Field from 'src/models/form/Field'
import { ApiRoutes } from 'src/utils/Urls'
import {
  LocationsModel,
  type ILocationsModel,
  RecruitersModel,
  type IRecruitersModel,
  CategoriesModel,
  type ICategoriesModel,
  WorktypesModel,
  type IWorktypesModel,
  CandidateSkillsModel,
  type ICandidateSkillsModel
} from 'src/models/StandAlone'
import Logger from 'src/utils/Logger'
import ListStore from './ListStore'
import rootStore from './RootStore'
import API from 'src/utils/API'
import type { APIResponse } from 'src/types/APITypes'
import alertMessages from 'src/constants/alertMessages'

type CountriesResponse =
  RefariDTOTypes['/standalone/detailed-locations/countries/']['get']['responses']['200']['content']['application/json']

type AvailableCountriesResponse =
  RefariDTOTypes['/standalone/detailed-locations/countries/all/']['get']['responses']['200']['content']['application/json']

type StatesResponse =
  RefariDTOTypes['/standalone/detailed-locations/states/']['get']['responses']['200']['content']['application/json']

type CitiesResponse =
  RefariDTOTypes['/standalone/detailed-locations/cities/']['get']['responses']['200']['content']['application/json']

type SubCategorySelectionConfig = ICategoriesModel['children']

type DetailedLocation = {
  allCountries: CountriesResponse | null
  selectedFromAllCountry: GenericSelectOption | null
  allStates: StatesResponse | null
  selectedFromAllState: GenericSelectOption | null
  allCities: CitiesResponse | null
  postalCode: string | null
}

type LocationRecord = {
  [fieldId: string]: {
    parentLocationId: string
    subLocations?: NonNullable<ILocationsModel['children']>['subLocations']
    detailedLocation?: DetailedLocation
  }
}

type UpdateLocationRecordArgs = {
  fieldId: string
  parentLocationId: string
  hasSubLocation: boolean
  detailedLocation?: DetailedLocation
}

type CurrencyList = {
  name: string
  value: string
}

type ListType =
  | 'locations'
  | 'recruiters'
  | 'categories'
  | 'workTypes'
  | 'candidateSkills'
  | 'currencies'

type FetchListsArgs = {
  excludeList?: Array<ListType>
}

type ListApiUrls = {
  [key in ListType]: string
}

type initListStoresConfigs = {
  entityConstructorConfig?: Partial<
    Record<Exclude<ListType, 'currencies'>, any>
  >
  listApiUrls?: Partial<ListApiUrls>
}

export type SelectedLocation = {
  fieldId: string
  locationId: number
  hasSubLocation: boolean
}

type NewLocationField = {
  form: any
  shouldAddSubLocation: boolean
  shouldAddDetailedLocation: boolean
  fieldValidators?: Array<any>
}

type RemoveLocationArgs = {
  form: any
  fieldId: string
  hasSubLocation: boolean
  hasDetailedLocation: boolean
}

export type GenericSelectOption = {
  id: string | number
  name: string
}

type SaveNewArgs = {
  newData: string
  cb: <T = GenericSelectOption>(data: T) => void
  locationFieldId: string
}

type DeleteExistingArgs = {
  id: number | string
  cb: () => void
  locationFieldId: string
}

type SetSelectedFromAllCountriesArgs = {
  selectedCountry: GenericSelectOption
  locationFieldId: string
}

type FetchAllCountriesArgs = {
  locationFieldId: string
}

type FetchAllStatesArgs = {
  locationFieldId: string
  countryId: number
}

type SetSelectedFromAllStatesArgs = {
  selectedState: GenericSelectOption
  locationFieldId: string
}

type FetchAllCitiesArgs = {
  locationFieldId: string
  stateId: number
}

export type AvailableCountry = {
  id: string
  name: string
  isAdded: boolean
  refariId?: number
}

type FetchAvailableCountriesArgs = {
  locationFieldId: string
}

export type IStandAloneStore = {
  isFetching: boolean
  locationsListStore: any
  recruitersListStore: any
  categoriesListStore: any
  worktypesListStore: any
  candidateSkillsListStore: any
  isDownloadingFile: boolean
  locationFieldIds: Array<string>
  locationRecord: LocationRecord
  subCategorySelectionConfig: SubCategorySelectionConfig | null
  currencyList: Array<CurrencyList>
  getCategoriesList: ICategoriesModel[]
  getLocationsList: ILocationsModel[]
  getWorkTypesList: IWorktypesModel[]
  getRecruitersList: IRecruitersModel[]
  /**
   * This is fetched from API and not processed
   * For proccessed data use availableCountries
   */
  avaialbleCountriesFromApi: Array<string>
  /**
   * This is processed from availableCountriesFromApi
   */
  availableCountries: Array<AvailableCountry> | null
  isSavingCountry: boolean
  isDeletingCountry: boolean

  getCandidateSkillsList: ICandidateSkillsModel[]
  refetchCandidateSkills: () => void
  refetchCandidateWorkTypes: () => void
  addLocationField: ({ form }: NewLocationField) => void
  removeLocationField: ({ form, fieldId }: RemoveLocationArgs) => void
  updateLocationRecord: (args: UpdateLocationRecordArgs) => void
  fetchLists: ({ excludeList }: FetchListsArgs) => Promise<void>
  updateSelectedLocation: (selectedLocation: SelectedLocation) => void
  updateSelectedCategory: (categoryId: number) => void
  downloadFile: () => void
  initListStores: (initListStoresConfigs?: initListStoresConfigs) => void
  resetLocationFieldIds: () => void
  resetLocationRecord: () => void
  getListPromises: (listsArgs: FetchListsArgs) => Array<() => Promise<any>>
  fetchAllCountries: (args: FetchAllCountriesArgs) => Promise<void>
  getAllCountriesSelectOptions: (
    locationFieldId: string
  ) => Array<GenericSelectOption>
  getAllStatesSelectOptions: (
    locationFieldId: string
  ) => Array<GenericSelectOption>
  getAllCitiesSelectOptions: (
    locationFieldId: string
  ) => Array<GenericSelectOption>
  fetchAvailableCountries: (args: FetchAvailableCountriesArgs) => Promise<void>
  saveNewCountry: (args: SaveNewArgs) => Promise<any>
  deleteExistingCountry: (args: DeleteExistingArgs) => Promise<any>
  fetchAllStates: (args: FetchAllStatesArgs) => Promise<void>
  saveNewState: (args: SaveNewArgs) => Promise<void>
  fetchAllCities: (args: FetchAllCitiesArgs) => Promise<void>
  saveNewCity: (args: SaveNewArgs) => Promise<void>
  setSelectedFromAllCountries: (args: SetSelectedFromAllCountriesArgs) => void
  resetDetailLocationProperties: (locationFieldId: string) => void
  setSelectedFromAllStates: (args: SetSelectedFromAllStatesArgs) => void
  setIsSavingCountry: (isSavingCountry: boolean) => void
  setIsDeletingCountry: (isDeletingCountry: boolean) => void
  resetAvailableCountries: () => void
  setAvailableCountriesFromApi: (
    availableCountriesFromApi: Array<string>
  ) => void
  resetAllCities: (locationFieldId: string) => void
  importLocations: () => Promise<void>
  importWorktypes: () => Promise<void>
}

class StandAloneStore implements IStandAloneStore {
  @observable isFetching: IStandAloneStore['isFetching'] = false
  @observable isDownloadingFile: IStandAloneStore['isDownloadingFile'] = false
  @observable locationRecord: IStandAloneStore['locationRecord'] = {}
  @observable locationFieldIds: IStandAloneStore['locationFieldIds'] = []
  @observable availableCountries: IStandAloneStore['availableCountries'] = null
  @observable isSavingCountry = false
  @observable isDeletingCountry = false
  @observable
  avaialbleCountriesFromApi: IStandAloneStore['avaialbleCountriesFromApi'] = []

  locationsListStore: IStandAloneStore['locationsListStore']
  recruitersListStore: IStandAloneStore['recruitersListStore']
  categoriesListStore: IStandAloneStore['categoriesListStore']
  worktypesListStore: IStandAloneStore['worktypesListStore']
  candidateSkillsListStore: IStandAloneStore['candidateSkillsListStore']

  initListStores: IStandAloneStore['initListStores'] = (
    initListStoresConfigs
  ) => {
    const defaultEntityConfig: {
      [key in Exclude<ListType, 'currencies'>]: any
    } = {
      locations: LocationsModel,
      recruiters: RecruitersModel,
      categories: CategoriesModel,
      workTypes: WorktypesModel,
      candidateSkills: CandidateSkillsModel
    }

    const pageSize = 200

    this.locationsListStore = new ListStore(
      {
        entityConstructor:
          initListStoresConfigs?.entityConstructorConfig?.locations ??
          defaultEntityConfig.locations,
        url:
          initListStoresConfigs?.listApiUrls?.locations ??
          ApiRoutes.standalone.list.locations
      },
      {
        pageSize
      }
    )

    this.recruitersListStore = new ListStore(
      {
        entityConstructor:
          initListStoresConfigs?.entityConstructorConfig?.recruiters ??
          defaultEntityConfig.recruiters,
        url:
          initListStoresConfigs?.listApiUrls?.recruiters ??
          ApiRoutes.standalone.list.recruiters
      },
      {
        pageSize
      }
    )

    this.categoriesListStore = new ListStore(
      {
        entityConstructor:
          initListStoresConfigs?.entityConstructorConfig?.categories ??
          defaultEntityConfig.categories,
        url:
          initListStoresConfigs?.listApiUrls?.categories ??
          ApiRoutes.standalone.list.categories
      },
      {
        pageSize
      }
    )

    this.worktypesListStore = new ListStore(
      {
        entityConstructor:
          initListStoresConfigs?.entityConstructorConfig?.workTypes ??
          defaultEntityConfig.workTypes,
        url:
          initListStoresConfigs?.listApiUrls?.workTypes ??
          ApiRoutes.standalone.list.worktypes
      },
      {
        pageSize
      }
    )

    this.candidateSkillsListStore = new ListStore(
      {
        entityConstructor:
          initListStoresConfigs?.entityConstructorConfig?.candidateSkills ??
          defaultEntityConfig.candidateSkills,
        url:
          initListStoresConfigs?.listApiUrls?.candidateSkills ??
          ApiRoutes.standalone.list.candidateSkills
      },
      {
        pageSize
      }
    )
  }

  @action
  resetAvailableCountries: IStandAloneStore['resetAvailableCountries'] = () => {
    this.availableCountries = null
  }

  @action
  fetchAllCountries: IStandAloneStore['fetchAllCountries'] = async ({
    locationFieldId
  }) => {
    try {
      const response = (await API.getData(
        ApiRoutes.standalone.list.countries
      )) as unknown as APIResponse<CountriesResponse>

      const dataFromApi = response?.data

      if (!dataFromApi) {
        throw new Error('No data for countries')
      }

      runInAction(() => {
        // @ts-ignore we can ignore here
        this.locationRecord[locationFieldId].detailedLocation.allCountries =
          dataFromApi
      })
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  getAllCountriesSelectOptions: IStandAloneStore['getAllCountriesSelectOptions'] =
    (locationFieldId) => {
      return (
        this.locationRecord[
          locationFieldId
        ].detailedLocation?.allCountries?.results.map((country) => ({
          id: country.id,
          name: country.country,
          countryCode: country.country_code
        })) ?? []
      )
    }

  @action
  setSelectedFromAllCountries: IStandAloneStore['setSelectedFromAllCountries'] =
    ({ locationFieldId, selectedCountry }) => {
      // @ts-ignore we can ignore here
      this.locationRecord[
        locationFieldId
      ].detailedLocation.selectedFromAllCountry = selectedCountry
    }

  @action
  setAvailableCountriesFromApi: IStandAloneStore['setAvailableCountriesFromApi'] =
    (availableCountriesFromApi) => {
      this.avaialbleCountriesFromApi = availableCountriesFromApi
    }

  @action
  private setAvailableCountries = (locationFieldId: string) => {
    const previouslyAddedCountries =
      this.locationRecord[locationFieldId].detailedLocation?.allCountries
        ?.results || []

    this.availableCountries =
      this.avaialbleCountriesFromApi.map((country) => {
        const foundCountry = previouslyAddedCountries.find(
          (previouslyAddedCountry) => previouslyAddedCountry.country === country
        )

        return {
          id: country,
          name: country,
          isAdded: Boolean(foundCountry),
          refariId: foundCountry?.id
        }
      }) || []
  }

  @action
  fetchAvailableCountries: IStandAloneStore['fetchAvailableCountries'] =
    async ({ locationFieldId }) => {
      try {
        let dataFromApi: string[] | null = this.avaialbleCountriesFromApi

        if (dataFromApi.length === 0) {
          const response = (await API.getData(
            ApiRoutes.standalone.list.availableCountries
          )) as unknown as APIResponse<AvailableCountriesResponse>

          dataFromApi = response?.data
        }

        if (!dataFromApi) {
          throw new Error('No data for available countries')
        }

        this.setAvailableCountriesFromApi(dataFromApi)

        this.setAvailableCountries(locationFieldId)
      } catch (error: any) {
        Logger.error(error)
      }
    }

  @action
  setIsSavingCountry: IStandAloneStore['setIsSavingCountry'] = (
    isSavingCountry
  ) => {
    this.isSavingCountry = isSavingCountry
  }

  @action
  saveNewCountry: IStandAloneStore['saveNewCountry'] = async ({
    newData: country,
    cb,
    locationFieldId
  }) => {
    try {
      this.setIsSavingCountry(true)

      const savedCountry = await API.postData(
        ApiRoutes.standalone.list.createDetailedCountry,
        {
          country: country
        }
      )

      if (savedCountry) {
        await this.fetchAllCountries({
          locationFieldId
        })

        this.setAvailableCountries(locationFieldId)

        cb({})

        return savedCountry
      }
    } catch (error: any) {
      Logger.error(error)
      toast.error(alertMessages.genericErrorMessage)
    } finally {
      this.setIsSavingCountry(false)
    }
  }

  @action
  setIsDeletingCountry: IStandAloneStore['setIsDeletingCountry'] = (
    isDeletingCountry
  ) => {
    this.isDeletingCountry = isDeletingCountry
  }

  @action
  deleteExistingCountry: IStandAloneStore['deleteExistingCountry'] = async ({
    id,
    cb,
    locationFieldId
  }) => {
    try {
      this.setIsDeletingCountry(true)

      const response = await API.deleteData(
        ApiRoutes.standalone.list.deleteDetailedCountry(id)
      )

      if (typeof response !== 'string' && response.status === 204) {
        await this.fetchAllCountries({
          locationFieldId
        })

        this.setAvailableCountries(locationFieldId)

        cb()

        return 'deleted'
      }

      return null
    } catch (error: any) {
      Logger.error(error)
      toast.error(alertMessages.genericErrorMessage)
    } finally {
      this.setIsDeletingCountry(false)
    }
  }

  @action
  fetchAllStates: IStandAloneStore['fetchAllStates'] = async ({
    locationFieldId,
    countryId
  }) => {
    try {
      const response = (await API.getData(ApiRoutes.standalone.list.states, {
        country: countryId
      })) as unknown as APIResponse<StatesResponse>

      const dataFromApi = response?.data

      if (!dataFromApi) {
        throw new Error('No data for available states')
      }

      runInAction(() => {
        // @ts-ignore we can ignore here
        this.locationRecord[locationFieldId].detailedLocation.allStates =
          dataFromApi
      })
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  getAllStatesSelectOptions: IStandAloneStore['getAllStatesSelectOptions'] = (
    locationFieldId
  ) => {
    return (
      this.locationRecord[
        locationFieldId
      ].detailedLocation?.allStates?.results.map((state) => ({
        id: state.id,
        name: state.name
      })) ?? []
    )
  }

  @action
  saveNewState: IStandAloneStore['saveNewState'] = async ({
    newData: state,
    cb,
    locationFieldId
  }) => {
    try {
      const detailedLocation =
        this.locationRecord[locationFieldId]?.detailedLocation

      const savedData = await API.postData(
        ApiRoutes.standalone.list.createDetailedState,
        {
          name: state,
          country: detailedLocation?.selectedFromAllCountry?.id as number
        }
      )

      await this.fetchAllStates({
        locationFieldId,
        countryId: detailedLocation?.selectedFromAllCountry?.id as number
      })

      cb(savedData.data)
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  setSelectedFromAllStates: IStandAloneStore['setSelectedFromAllStates'] = ({
    locationFieldId,
    selectedState
  }) => {
    // @ts-ignore we can ignore here
    this.locationRecord[locationFieldId].detailedLocation.selectedFromAllState =
      selectedState
  }

  @action
  fetchAllCities: IStandAloneStore['fetchAllCities'] = async ({
    locationFieldId,
    stateId
  }) => {
    try {
      const response = (await API.getData(ApiRoutes.standalone.list.cities, {
        state: stateId
      })) as unknown as APIResponse<CitiesResponse>

      const dataFromApi = response?.data

      if (!dataFromApi) {
        throw new Error('No data for available cities')
      }

      runInAction(() => {
        // @ts-ignore we can ignore here
        this.locationRecord[locationFieldId].detailedLocation.allCities =
          dataFromApi
      })
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  resetAllCities: IStandAloneStore['resetAllCities'] = (locationFieldId) => {
    // @ts-ignore we can ignore here
    this.locationRecord[locationFieldId].detailedLocation.allCities = null
  }

  @action
  getAllCitiesSelectOptions: IStandAloneStore['getAllCitiesSelectOptions'] = (
    locationFieldId
  ) => {
    return (
      this.locationRecord[
        locationFieldId
      ].detailedLocation?.allCities?.results.map((city) => ({
        id: city.id,
        name: city.name
      })) ?? []
    )
  }

  @action
  saveNewCity: IStandAloneStore['saveNewCity'] = async ({
    newData: city,
    cb,
    locationFieldId
  }) => {
    try {
      const detailedLocation =
        this.locationRecord[locationFieldId]?.detailedLocation

      const savedData = await API.postData(
        ApiRoutes.standalone.list.createDetailedCity,
        {
          name: city,
          state: detailedLocation?.selectedFromAllState?.id as number
        }
      )

      await this.fetchAllCities({
        locationFieldId,
        stateId: detailedLocation?.selectedFromAllState?.id as number
      })

      cb(savedData.data)
    } catch (error: any) {
      Logger.error(error)
    }
  }

  @action
  updateLocationRecord: IStandAloneStore['updateLocationRecord'] = ({
    fieldId,
    parentLocationId,
    hasSubLocation,
    detailedLocation
  }) => {
    this.locationRecord[fieldId] = {
      parentLocationId,
      ...(hasSubLocation && {
        subLocations:
          this.locationsListStore.list.find(
            (location: Record<string, any>) =>
              location.id === Number(parentLocationId)
          )?.children.subLocations ?? []
      }),
      detailedLocation
    }
  }

  @action
  resetDetailLocationProperties: IStandAloneStore['resetDetailLocationProperties'] =
    (locationFieldId) => {
      // @ts-ignore reset all countries
      this.locationRecord[locationFieldId].detailedLocation.allCountries = null

      // @ts-ignore reset all states
      this.locationRecord[locationFieldId].detailedLocation.allStates = null

      // @ts-ignore reset all cities
      this.locationRecord[locationFieldId].detailedLocation.allCities = null

      // @ts-ignore reset postal code
      this.locationRecord[locationFieldId].detailedLocation.postalCode = null

      // @ts-ignore reset selected country
      this.locationRecord[
        locationFieldId
      ].detailedLocation.selectedFromAllCountry = null

      // @ts-ignore reset selected state
      this.locationRecord[
        locationFieldId
      ].detailedLocation.selectedFromAllState = null

      // @ts-ignore reset selected city
      this.locationRecord[locationFieldId].detailedLocation.selectedCity = null
    }

  @action
  resetLocationRecord: IStandAloneStore['resetLocationRecord'] = () => {
    this.locationRecord = {}
  }

  @action
  resetLocationFieldIds: IStandAloneStore['resetLocationFieldIds'] = () => {
    this.locationFieldIds = []
  }

  @action
  addLocationField: IStandAloneStore['addLocationField'] = ({
    form,
    shouldAddSubLocation,
    shouldAddDetailedLocation,
    fieldValidators
  }) => {
    const fieldId = uuidv4()

    form.addNewField(
      `locationSelection${fieldId}`,
      // @ts-ignore TODO: Apply proper type after migrating Field to typescript
      new Field({
        name: `location_${fieldId}`,
        value: '',
        ...(fieldValidators && {
          validators: fieldValidators
        })
      })
    )

    if (shouldAddSubLocation) {
      form.addNewField(
        `subLocationSelection${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `subLocation_${fieldId}`,
          value: ''
        })
      )
    }

    if (shouldAddDetailedLocation) {
      form.addNewField(
        `detailedLocationSelection${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `detailedLocation_${fieldId}`,
          value: ''
        })
      )

      form.addNewField(
        `detailedLocationSelectionCountry${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `detailedLocationCountry_${fieldId}`,
          value: ''
        })
      )

      form.addNewField(
        `detailedLocationSelectionState${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `detailedLocationState_${fieldId}`,
          value: ''
        })
      )

      form.addNewField(
        `detailedLocationSelectionCity${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `detailedLocationCity_${fieldId}`,
          value: ''
        })
      )

      form.addNewField(
        `detailedLocationSelectionPostalCode${fieldId}`,
        // @ts-ignore TODO: Apply proper type after migrating Field to typescript
        new Field({
          name: `detailedLocationPostalCode_${fieldId}`,
          value: ''
        })
      )
    }

    this.locationFieldIds.push(fieldId)

    this.updateLocationRecord({
      fieldId,
      parentLocationId: '',
      hasSubLocation: shouldAddSubLocation,
      detailedLocation: {
        allCountries: null,
        allStates: null,
        allCities: null,
        selectedFromAllCountry: null,
        selectedFromAllState: null,
        postalCode: null
      }
    })
  }

  @action
  removeLocationField: IStandAloneStore['removeLocationField'] = ({
    form,
    fieldId,
    hasSubLocation,
    hasDetailedLocation
  }) => {
    form.removeField(`locationSelection${fieldId}`)

    if (hasSubLocation) {
      form.removeField(`subLocationSelection${fieldId}`)
    }

    if (hasDetailedLocation) {
      form.removeField(`detailedLocationSelection${fieldId}`)
      form.removeField(`detailedLocationCountrySelection${fieldId}`)
      form.removeField(`detailedLocationStateSelection${fieldId}`)
      form.removeField(`detailedLocationCitySelection${fieldId}`)
      form.removeField(`detailedLocationPostalCodeSelection${fieldId}`)
    }

    const index = this.locationFieldIds.indexOf(fieldId)

    if (index !== -1) {
      this.locationFieldIds.splice(index, 1)
    }

    delete this.locationRecord[fieldId]
  }

  getListPromises: IStandAloneStore['getListPromises'] = ({ excludeList }) => {
    const promiseMapping: { [key in ListType]: () => Promise<any> } = {
      locations: this.locationsListStore.fetchListData,
      recruiters: this.recruitersListStore.fetchListData,
      categories: this.categoriesListStore.fetchListData,
      workTypes: this.worktypesListStore.fetchListData,
      candidateSkills: this.candidateSkillsListStore.fetchListData,
      currencies: rootStore.fetchCurrency
    }

    if (excludeList) {
      excludeList.forEach((excludeItem) => {
        delete promiseMapping[excludeItem]
      })
    }

    return Object.values(promiseMapping)
  }

  @action
  endFetchingList = () => {
    this.isFetching = false
  }

  @action
  fetchLists = async ({ excludeList }: FetchListsArgs) => {
    try {
      this.isFetching = true

      const listPromiseFuncs = this.getListPromises({ excludeList })

      await Promise.all(
        listPromiseFuncs.map((listPromiseFunc) => listPromiseFunc())
      )
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.endFetchingList()
    }
  }

  @computed
  get getCategoriesList(): IStandAloneStore['getCategoriesList'] {
    return this.categoriesListStore.list
  }

  @computed
  get getLocationsList(): IStandAloneStore['getLocationsList'] {
    return this.locationsListStore.list
  }

  @computed
  get getWorkTypesList(): IStandAloneStore['getWorkTypesList'] {
    return this.worktypesListStore.list
  }

  @computed
  get getRecruitersList(): IStandAloneStore['getRecruitersList'] {
    return this.recruitersListStore.list
  }

  @computed
  get getCandidateSkillsList(): IStandAloneStore['getCandidateSkillsList'] {
    return this.candidateSkillsListStore.list
  }

  @action
  refetchCandidateSkills = async () => {
    this.candidateSkillsListStore.fetchListData()
  }

  @action
  refetchCandidateWorkTypes = async () => {
    this.worktypesListStore.fetchListData()
  }

  @action
  setDownloadingEnd = () => {
    this.isDownloadingFile = false
  }

  @action
  downloadFile = async () => {
    try {
      this.isDownloadingFile = true
      const response = await axios.post(
        ApiRoutes.standalone.download,
        {},
        {
          responseType: 'arraybuffer'
        }
      )
      if (response && response.data) {
        const blob = new Blob([response.data as ArrayBuffer], {
          type: 'application/zip'
        })
        const href = window.URL.createObjectURL(blob)
        const element = document.createElement('a')
        element.setAttribute('href', href)
        element.setAttribute('download', 'data.zip')
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    } catch (error: any) {
      Logger.error(error)
    } finally {
      this.setDownloadingEnd()
    }
  }

  @action
  updateSelectedLocation: IStandAloneStore['updateSelectedLocation'] = ({
    locationId,
    fieldId,
    hasSubLocation
  }) => {
    this.locationsListStore.setData('idOfActiveEntity', locationId)

    this.updateLocationRecord({
      fieldId,
      hasSubLocation,
      parentLocationId: String(locationId),
      detailedLocation: this.locationRecord[fieldId]?.detailedLocation
    })
  }

  @action
  importLocations: () => Promise<void> = async () => {
    try {
      const response = await API.postData(ApiRoutes.standalone.importLocations)
      await this.locationsListStore.fetchListData()
      console.log(response)
    } catch (err: any) {
      Logger.error(err)
    }
  }

  @action
  importWorktypes: () => Promise<void> = async () => {
    try {
      const response = await API.postData(ApiRoutes.standalone.importWorktypes)
      await this.worktypesListStore.fetchListData()
      console.log(response)
    } catch (err: any) {
      Logger.error(err)
    }
  }

  @action
  updateSelectedCategory = (categoryId: number) => {
    this.categoriesListStore.setData('idOfActiveEntity', categoryId)
  }

  @computed
  get subCategorySelectionConfig(): SubCategorySelectionConfig | null {
    const activeCategory: ICategoriesModel = this.categoriesListStore.list.find(
      (category: ICategoriesModel) =>
        category.id === Number(this.categoriesListStore.idOfActiveEntity)
    )

    return activeCategory ? activeCategory.children : null
  }

  @computed
  // eslint-disable-next-line class-methods-use-this
  get currencyList(): Array<CurrencyList> {
    return rootStore.currencyList
  }
}

export default new StandAloneStore()
