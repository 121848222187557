import { observable } from 'mobx'
import { createUUID } from '../utils/helpers'
import Currency from './Currency'

export default class PaymentConsultants {
  @observable id
  @observable days
  @observable firstName
  @observable lastName
  @observable fullName
  @observable agencyName
  @observable.ref total

  constructor(data) {
    if (!data) data = {}
    this.id = data.id || createUUID()
    this.days = data.days
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.fullName = `${data.first_name} ${data.last_name}`
    this.agencyName = data.agency_name || ''
    this.total = new Currency(data.total, 'amount')
    this.role = data.role
  }
}
