export const availabilityUnit = [
  { id: 'Week', name: 'Week' },
  { id: 'Month', name: 'Month' }
] as const

export const salaryPeriod = [
  { id: 'Hour', name: 'Hour' },
  { id: 'Day', name: 'Day' },
  { id: 'Week', name: 'Week' },
  { id: 'Month', name: 'Month' },
  { id: 'Year', name: 'Year' }
] as const

export const answerType = [
  { id: 'text', name: 'Text' },
  { id: 'integer', name: 'Integer' },
  { id: 'heading', name: 'Heading' },
  { id: 'file', name: 'File' }
] as const

export const fieldType = [
  {
    name: 'Text',
    id: 'text'
  },
  {
    name: 'Integer',
    id: 'integer'
  },
  {
    name: 'Heading',
    id: 'heading'
  },
  {
    name: 'List',
    id: 'list'
  },
  {
    name: 'File',
    id: 'file'
  }
] as const
