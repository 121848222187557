import React from 'react'
import PropTypes from 'prop-types'
import qp from 'query-parse'

class Vimeo extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isPlay: PropTypes.bool.isRequired,
  }
  render() {
    const { id, isPlay } = this.props
    const query = qp.toString({
      autoplay: isPlay ? 1 : 0,
      title: 0,
      byline: 0,
      portrait: 0,
    })
    return (
      <iframe
        ref="player"
        key="frame"
        id="vm-preview"
        title="preview"
        src={`https://player.vimeo.com/video/${id}?${query}`}
        frameBorder="0"
        allowFullScreen
      />
    )
  }
}

export default Vimeo
