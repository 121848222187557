const dev = {
  facebook: {
    key: 'facebook',
    appId: '676323342930092',
    title: 'Facebook'
  },
  google: {
    key: 'google',
    clientId:
      '690592239648-5ollsjmckoe3c1ofhpif31o065mpgra3.apps.googleusercontent.com',
    apiKey: 'AIzaSyAs3sdAq6H89YAOzo0lOko2lFLBaGYprqU',
    title: 'Google',
    captchaSiteKey: '6LeZLqocAAAAAMy3r4yzFtOYN8-jkR3487mQzSl3'
  },
  linkedin: {
    key: 'linkedin',
    clientId: '78t4vr5qhze4lq',
    title: 'LinkedIn'
  },
  twitter: {
    key: 'twitter',
    clientId: 'z01MW0dTjmUcUfAgpP90HplKO',
    title: 'X (formerly Twitter)'
  },
  stripe: {
    apiKey:
      'pk_test_51OBXY3GHAuuKAqUdCeXkuxh7QwvpY7btVhGjjeKacSVBCgPmxQqFthb9woNQP87y5m4UDEqa3t7BmSP3pAfXwRtq009VeFqpmh'
  },
  googleMap: {
    apiKey: 'AIzaSyA2gToXZbNlbKIA0q8TeeBmV9uTa-C0cAA'
  }
}

const stage = {
  facebook: {
    key: 'facebook',
    appId: '676323342930092',
    title: 'Facebook'
  },
  google: {
    key: 'google',
    clientId:
      '690592239648-5ollsjmckoe3c1ofhpif31o065mpgra3.apps.googleusercontent.com',
    apiKey: 'AIzaSyAs3sdAq6H89YAOzo0lOko2lFLBaGYprqU',
    title: 'Google',
    captchaSiteKey: '6LeQ1a0cAAAAAMuN1MutKBM8Pi4EWXiLQs39SfsN'
  },
  linkedin: {
    key: 'linkedin',
    clientId: '78t4vr5qhze4lq',
    title: 'LinkedIn'
  },
  twitter: {
    key: 'twitter',
    clientId: 'z01MW0dTjmUcUfAgpP90HplKO',
    title: 'X (formerly Twitter)'
  },
  stripe: {
    apiKey:
      'pk_test_51GxXkkKesHWLJO2F9TMpJp9QDM92YW1IcJJP43ttuwcTM1hYZAn9p8SZgB2ruo4ojj0UblxATVdh4Zra44EnNri100JTij57T1'
  },
  googleMap: {
    apiKey: 'AIzaSyA2gToXZbNlbKIA0q8TeeBmV9uTa-C0cAA'
  }
}

const prod = {
  facebook: {
    key: 'facebook',
    appId: '754698701401724',
    title: 'Facebook'
  },
  google: {
    key: 'google',
    clientId:
      '690592239648-n33alvl4l6o7m6piaep49u7d5fab34l9.apps.googleusercontent.com',
    apiKey: 'AIzaSyDzVwA59ijvUlWzf_1Bnn0F5MXmWkZ1mng',
    title: 'Google',
    captchaSiteKey: '6LeO1a0cAAAAAF_N9SUJUJ-fz4s1xs2A92C_mude'
  },
  linkedin: {
    key: 'linkedin',
    clientId: '77t6tv1r0ci78e',
    title: 'LinkedIn'
  },
  twitter: {
    key: 'twitter',
    clientId: '2OXL8FQmaYnZ9ILiov9fTbAXv',
    title: 'X (formerly Twitter)'
  },
  stripe: {
    apiKey: 'pk_live_dhco9CJH421O252kMGFeWk5O'
  },
  googleMap: {
    apiKey: 'AIzaSyD8BNh2Bd5KaaHaxuZOY3WYVO89fBmbJ78'
  }
}

const credentials = () => {
  switch (process.env.REACT_APP_BUILD_MODE) {
    case 'staging':
      return stage
    case 'production':
      return prod
    case 'production_new':
      return prod
    default:
      /* development */
      return dev
  }
}

export default credentials()
