import { action, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import alertMessages from '../constants/alertMessages'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import RootStore from './RootStore'

function sortAlpabetically(arr) {
  if (Array.isArray(arr)) {
    arr.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1
      }
      return 0
    })
  }
  return arr
}
export class ConsultantsTagsStore {
  @observable allTags = []
  @observable selectedTags = []
  @observable isFetching = false

  @action
  fetchTags = async () => {
    try {
      if (this.isFetching) return
      this.setData('isFetching', true)
      const response = await API.getData(ApiRoutes.dashboard.agency.getTags)
      this.setData('isFetching', false)
      this.setData('selectedTags', response.data.consultant_tags)
      this.setData('allTags', sortAlpabetically(response.data.all))
    } catch (error) {
      Logger.error(error)
    }
  }

  updateTags = async (form) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.updateTags,
        form.data
      )
      toast.success(alertMessages.agencySettingsUpdated)

      const selectedTags = response.data?.consultant_tags
      if (Array.isArray(selectedTags)) {
        runInAction(() => {
          this.selectedTags = selectedTags
        })
      }

      RootStore.resetCompanyChangesList()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }
}

export default new ConsultantsTagsStore()
