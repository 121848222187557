/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import { observable, action, computed, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import WebFont from 'webfontloader'
import axios from 'axios'
import mime from 'mime-types'

import AuthSession from '../utils/AuthSession'
import { ApiRoutes } from '../utils/Urls'
import API from '../utils/API'
import Logger from '../utils/Logger'
import Profile from '../models/Profile'
import Messages from '../models/Messages'
import { generateDefaultLink, mapFormServerErrors } from '../utils/helpers'
import Agency from '../models/Agency'
import Branding, { Image } from '../models/Branding'
import routesUrls from '../router/routesUrls'
import alertMessages from '../constants/alertMessages'
import permissions from '../constants/permissions'
import credentials from '../constants/credentials'
import Testimonial from '../models/Testimonials'
import Storage from '../utils/Storage'
import CustomBackgroundStore from './CustomBackgroundStore'
import SocializeStore from './SocializeStore'
import CanvasCardStore from './CanvasCardStore'
import PitchMeStore from './PitchMeStore'
import { prepareProgressionStatusParams } from '../utils/helpers'
export class RootStore {
  fontHistory = {}
  @observable fonts = []
  @observable.ref user = {}
  @observable agency = new Agency()
  @observable fetchingAgency = false
  @observable agencyBackgrounds = null
  @observable loggedIn
  @observable branding = new Branding()
  @observable commonData = {}
  @observable newProgress = false
  @observable publicAgency = new Agency()
  @observable testimonial = {}
  @observable socialize = null
  @observable showNavigationPrompt = false

  @observable isOpenCharacterModal = false
  @observable angleBracketsWords = []

  @observable messageTemplates = []
  @observable suggestionTemplateMessage = ''
  @observable selectedMessageTemplate = null
  @observable isOpenSuggestionMessageModal = false
  @observable suggestionTemplateMessageID = null
  @observable isOpenSuggestionMessageDeleteModal = false
  @observable isOpenMessageTemplateNameChangeModal = false

  @observable averageRating = 0
  @observable totalRatingCount = 0
  @observable companyAverageRating = 0
  @observable companyTotalRatingCount = 0

  @observable googleInfo = {
    client_id: '',
    secret: '',
    use_own_social_credentials: false
  }
  @observable floatingLink = [{ title: '', link: '' }]
  @observable activeTab
  @observable themeColorFromUrl = ''
  @observable currencyList = []
  @observable emailMapList = {}
  @observable userLocation = null
  @observable isOpenSystemAdminRecruiterModal = false
  @observable agenciesList = []
  @observable isFetchingPartnerActions = false

  @observable isOpenRetryPartnerActionModal = false
  @observable retryPartnerActionModalOptions = null

  @observable flatContent = {
    [routesUrls.terms.name]: {},
    [routesUrls.privacy.name]: {},
    [routesUrls.about.name]: {}
  }

  @observable isNewsFormSubmit = false
  @observable isSubscriptionDelete = false
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @observable ssoIframeCallback = () => {}

  @observable searchParams = {}

  @observable isFetchingMessageTemplateDetail = false

  @observable executeCaptchaFn = null

  /**
   * @typedef {object} CompanySettingsUpdate
   * @property {string} tab - for which settings tab this change associated with
   * @property {string} fieldName - name of the field which changed
   */

  /**
   *
   * @type {Array<CompanySettingsUpdate>}
   */
  @observable companySettingsUpdates = []

  @action
  setIsFetchingPartnerActions = (value) => {
    this.isFetchingPartnerActions = value
  }

  @action
  openRetryPartnerActionModal = (options) => {
    this.isOpenRetryPartnerActionModal = true
    this.retryPartnerActionModalOptions = options || null
  }

  @action
  closeRetryPartnerActionModal = () => {
    this.isOpenRetryPartnerActionModal = false
    this.retryPartnerActionModalOptions = null
  }

  @action
  initialProfileRequest = (cb) => {
    if (AuthSession.isTokenSet()) {
      AuthSession.setHeader()
      this.fetchUserData(cb)
    } else {
      this.setData('loggedIn', false)
    }
  }

  getAgencyBySlugName = (agencySlug) => {
    if (typeof agencySlug !== 'string' || agencySlug === '')
      throw new Error('agency name not valid.')
    const agency = this.user.agencies.find((el) => el.slug === agencySlug)

    if (agency.length === 0) throw new Error('agency name not valid')
    return agency
  }

  @action
  fetchCurrency = async () => {
    const response = await API.getData(ApiRoutes.dashboard.agency.currency)
    const currencyList = response.data.currencies.map((el) => ({
      name: el,
      value: el
    }))
    runInAction(() => {
      this.currencyList = currencyList
    })
  }

  @action
  fetchEmailMap = async () => {
    try {
      const response = await API.getData(ApiRoutes.dashboard.emailMapRead)
      runInAction(() => {
        this.emailMapList = response.data
      })
    } catch (e) {
      Logger.error(e, 'rootStore.fetchEmailMap')
    }
  }

  @action
  updateEmailMap = async (form) => {
    const emailSettings = form.fieldsName.map((el) => ({
      title: el,
      enabled: form.data[el]
    }))
    try {
      const response = await API.patchData(ApiRoutes.dashboard.emailMapRead, {
        email_settings: [...emailSettings]
      })
      runInAction(() => {
        toast.success(alertMessages.agencySettingsUpdated)
        this.emailMapList = response.data
      })
      this.resetCompanyChangesList()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action.bound
  toggleCharacterModal() {
    this.isOpenCharacterModal = !this.isOpenCharacterModal
  }

  getBrandingFromResponse(response) {
    if (response.data.user_type === 'agency')
      return response.data.agency_user?.branding
    if (response.data.user_type === 'hiring_manager')
      return response.data.hiring_manager?.branding
    return response.data.referrer?.branding
  }

  @action
  fetchUserData = async (cb) => {
    try {
      const response = await API.getData(ApiRoutes.profile.me)
      if (response && response.data) {
        runInAction(() => {
          this.user = new Profile(response.data)

          const branding = this.getBrandingFromResponse(response)
          if (this.themeColorFromUrl && branding) {
            branding.color_primary = this.themeColorFromUrl
          }
          this.branding = new Branding(branding)
          this.loggedIn = true
        })

        cb && cb(response.data.last_action_tab)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  switchToHiringManagerUser() {
    const userClone = Object.assign(
      Object.create(Object.getPrototypeOf(this.user)),
      this.user
    )

    userClone.userType = 'hiring_manager'
    userClone.role = ''
    console.log('switchUser', userClone)
    this.user = userClone
  }

  @action
  fetchParterActions = async (url, publicId, externalId, agencyId) => {
    const requestUrl = url(publicId, externalId, agencyId)

    try {
      this.setIsFetchingPartnerActions(true)

      // timeout after 5 seconds to handle backend slow response
      // due to jobadder api call
      const response = await Promise.race([
        API.getData(requestUrl),
        new Promise((_, reject) =>
          setTimeout(() => reject('timeoutError'), 5000)
        )
      ])

      // backend returns 503 status code when jobadder is not available
      if (axios.isAxiosError(response) && response.response?.status === 503) {
        return new Promise((_, reject) => reject('timeoutError'))
      }

      if (response && response.data) {
        return response.data
      }

      return null
    } catch (error) {
      Logger.error(error)

      if (error === 'timeoutError') {
        this.openRetryPartnerActionModal({
          requestUrl,
          publicId,
          externalId,
          agencyId,
          baseUrl: url
        })
      }
    } finally {
      this.setIsFetchingPartnerActions(false)
    }
  }

  @action
  selectFont = (name) => {
    const selectedFont = this.fonts.filter((item) => item.value === name)[0]

    if (this.fontHistory[name]) {
      this.selectedFont = selectedFont
    } else {
      WebFont.load({
        google: {
          families: [name]
        },
        classes: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fontloading: (familyName, fvd) => {},
        fontactive: (familyName, fvd) => {
          this.fontHistory[name] = true
          runInAction(() => {
            this.selectedFont = selectedFont
          })
        },
        fontinactive: (familyName, fvd) => {
          console.log('fontinactive:', familyName, fvd)
        }
      })
    }
  }

  @action
  updateUserData = async (form) => {
    try {
      const { role, userType } = this.user
      await this.setUserVideo(form)
      const response = await API.postData(
        ApiRoutes.profile.update(userType),
        form.data
      )
      if (response.response && response.response.data) {
        if (response.response.data.endorsements) {
          toast.error(response.response.data.endorsements)
        } else if (response.response.data.phone) {
          toast.error(response.response.data.phone)
        } else if (response.response.data.linkedin_profile_url) {
          toast.error(response.response.data.linkedin_profile_url)
        }
      } else {
        runInAction(() => {
          const branding = this.getBrandingFromResponse(response)

          this.user = new Profile(response.data)
          this.branding = new Branding(branding)
          this.agency.branding = new Branding(branding)
        })
        toast.success(alertMessages.profileUpdated)
      }
    } catch (error) {
      Logger.error(error)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  sendDataEraserRequest = async (form) => {
    form.pending(true)
    try {
      const { requestOption } = form.data
      await API.postData(ApiRoutes.dashboard.systemAdmin.erase, {
        email: form.data.email,
        immediately: requestOption !== 'send_email'
      })
      toast.success(
        requestOption === 'send_email'
          ? alertMessages.dataEraserEmailSent
          : alertMessages.dataEraserDeleteImmediately
      )
      form.reset()
      form.pending(false)
    } catch (error) {
      Logger.error(error)
      form.pending(false)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  getDataEraserRequestLink = async (form) => {
    form.pending(true)
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.systemAdmin.eraseLink,
        {
          email: form.data.email
        }
      )

      form.reset()
      form.pending(false)
      if (response && response.data && response.data.url) {
        return response.data.url
      }
    } catch (error) {
      Logger.error(error)
      form.pending(false)
      const { data } = error
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  downloadProfileData = async () => {
    try {
      const response = await axios.get(ApiRoutes.profile.exportData, {
        responseType: 'arraybuffer'
      })
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/zip' })
        const href = window.URL.createObjectURL(blob)
        const element = document.createElement('a')
        element.setAttribute('href', href)
        element.setAttribute('download', 'data.zip')
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      } else {
        toast.error('No data found')
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  downloadReferralReport = async (dateFrom, dateTo, onSuccess) => {
    try {
      const response = await axios.get(
        ApiRoutes.dashboard.referrers.csv(dateFrom, dateTo),
        {
          responseType: 'arraybuffer'
        }
      )
      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'text/csv' })
        const href = window.URL.createObjectURL(blob)
        const element = document.createElement('a')
        element.setAttribute('href', href)
        element.setAttribute(
          'download',
          dateFrom && dateTo
            ? `referral_report_${dateFrom}_to_${dateTo}.csv`
            : 'referral_report.csv'
        )
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        onSuccess && onSuccess()
      } else {
        toast.error('No data found')
      }
    } catch (error) {
      Logger.error(error)
      if (error.status && error.status === 400) {
        toast.error('No data found')
      }
    }
  }

  @action
  deleteAccount = async () => {
    try {
      await API.deleteData(ApiRoutes.profile.delete)
      toast.success(alertMessages.profileDeleted)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action getFloatingLinks = async () => {
    try {
      const res = await API.getData(ApiRoutes.dashboard.floatingLink)
      if (res.response && res.response.status === 403) {
        return res.response
      }
      const agencyLite = await API.getData(ApiRoutes.dashboard.agencyLite)
      if (res.data.cta_list.length) {
        runInAction(() => {
          this.floatingLink = res.data.cta_list
        })
      } else if (agencyLite.data.widget_base_url) {
        const res1 = generateDefaultLink(agencyLite.data.widget_base_url)
        runInAction(() => {
          this.floatingLink = res1
        })
      }

      return res
    } catch (e) {
      throw e.response
    }
  }

  setFloatingLinks = async (data) => {
    try {
      await API.postData(ApiRoutes.dashboard.floatingLink, data)
      toast.success(alertMessages.floatingLink)
    } catch (e) {
      e.response.data.cta_list.map((el) => {
        if (el.link || el.title) {
          toast.error('All fields must be filled')
        }
        return e.response
      })
    }
  }

  @action
  setUserVideo = async (form) => {
    try {
      const { userType } = this.user
      if (form.fieldsName.find((field) => field === 'video')) {
        if (!!form.fields.video.value && !form.fields.videoUrl.value.trim()) {
          // clear value if url removed
          form.fields.video.update(null)
        } else if (form.fields.videoUrl.value.trim()) {
          // upload to server and update form with id
          const video = await API.postData(ApiRoutes.profile.video(userType), {
            url: form.fields.videoUrl.value
          })
          form.fields.video.update(video.data.id)
        }
      }
    } catch (error) {
      const { data } = error.response
      if (data.url) {
        form.fields.videoUrl.setServerErrors(data.url)
      }
      throw error
    }
  }

  @action
  fetchAgencyData = async (isTestimonials) => {
    try {
      this.setData('fetchingAgency', true)
      const url = isTestimonials
        ? ApiRoutes.dashboard.agency.widgetRead
        : ApiRoutes.dashboard.agency.read
      const response = await API.getData(url)
      const agencyData = response.data
      if (agencyData.branding && this.themeColorFromUrl) {
        agencyData.branding.color_primary = this.themeColorFromUrl
      }
      this.setData('agency', new Agency(agencyData))
      setTimeout(() => {
        this.setData('branding', this.agency.branding)
      }, 300)
      this.setData('fetchingAgency', false)
      if (!this.userLocation) {
        this.setLocation()
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchWidgetAgency = async () => {
    try {
      const response = await API.getData(ApiRoutes.dashboard.agency.widgetRead)
      const agencyData = response.data

      if (agencyData.branding && this.themeColorFromUrl) {
        agencyData.branding.color_primary = this.themeColorFromUrl
      }
      this.setData('agency', new Agency(agencyData))
      this.setData('branding', this.agency.branding)
      // if (!this.userLocation) {
      //   this.setLocation()
      // }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchAgencyBackgrounds = async () => {
    try {
      const response = await API.getData(ApiRoutes.dashboard.backgrounds.list)
      if (response && response.data) {
        const backgrounds = Array.isArray(response.data)
          ? response.data.map((item) => new Image(item))
          : []
        this.setData('agencyBackgrounds', backgrounds)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  cloneAgencyBackgrounds = async (id) => {
    try {
      if (!id) return
      const response = await API.postData(
        ApiRoutes.dashboard.backgrounds.clone(id)
      )
      const backgrounds = Array.isArray(response.data)
        ? response.data.map((item) => new Image(item))
        : []
      this.setData('agencyBackgrounds', backgrounds)
      toast.success(alertMessages.cloneBackgroundSuccess)
    } catch (error) {
      toast.error(
        'Something went wrong while cloning background, Try again Later'
      )
      Logger.error(error)
    }
  }

  @action
  fetchTestimonials = async () => {
    try {
      const res = await API.getData(ApiRoutes.dashboard.agency.testimonials)
      const testimonial = res.data
      testimonial.results = res.data.results.map((el) => new Testimonial(el))
      this.setData('testimonial', testimonial)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  fetchRestrictedAgencyData = async (slug, onError) => {
    try {
      const response = await API.getData(ApiRoutes.agency.readRestricted(slug))
      runInAction(() => {
        this.agency = new Agency(response.data)
        this.branding = this.agency.branding
        this.publicAgency = new Agency(response.data)
      })
    } catch (error) {
      onError && onError()
      Logger.error(error)
    }
  }

  @action
  updateAgencyData = async (form) => {
    try {
      const data = { ...form.data }

      if (
        typeof form.data.progressionExternalDeterminationName === 'string' ||
        typeof form.data.progressionExternalDeterminationValue === 'string'
      ) {
        data.progression_external_determination = {
          field_name: form.data.progressionExternalDeterminationName,
          value: form.data.progressionExternalDeterminationValue
        }
      }

      if (
        data.reward_fee_percent !== undefined &&
        data.reward_fee_percent === ''
      ) {
        data.reward_fee_percent = null
      }
      if (
        data.reward_fee_percent_internal !== undefined &&
        data.reward_fee_percent_internal === ''
      ) {
        data.reward_fee_percent_internal = null
      }

      delete data.progressionExternalDeterminationName
      delete data.progressionExternalDeterminationValue

      const response = await API.postData(
        ApiRoutes.dashboard.agency.update,
        data
      )
      if (response.response && response.response.status === 400) {
        const request = response.response.data
        mapFormServerErrors(request, form.fields)
      } else {
        toast.success(alertMessages.agencySettingsUpdated)
        this.setData('agency', new Agency(response.data))
      }
      this.resetCompanyChangesList()
    } catch (error) {
      Logger.error(error)
      const { data } = error

      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  updateApplicationPolicies = async (policyData, form) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agency.update,
        policyData
      )
      if (response.response && response.response.status === 400) {
        const request = response.response.data
        mapFormServerErrors(request, form.fields)
      } else {
        toast.success(alertMessages.agencySettingsUpdated)
        this.setData('agency', new Agency(response.data))
      }
    } catch (error) {
      Logger.error(error)
      const { data } = error
      const policyUrlError = data.policy_urls
      toast.error('Policy update unsuccessful')
      Array.isArray(policyUrlError) &&
        policyUrlError.map((error, index) =>
          mapFormServerErrors(error, form.fields.policyUrls.fields[index])
        )
    }
  }

  @action
  updateAgencyCRMStatus = async (list) => {
    try {
      const fetchdata = {}
      list.map((listData) => {
        fetchdata[listData.id] = listData.data
          .filter((v) => v.selected)
          .map((val) => val.name)
        return listData
      })
      const data = {}
      data.referral_status_match = fetchdata
      const response = await API.postData(
        ApiRoutes.dashboard.agency.update,
        data
      )
      this.setData('agency', new Agency(response.data))
      toast.success(alertMessages.triggerUpdated)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateProgressionStatus = async (list) => {
    try {
      const progressionStatusParams = prepareProgressionStatusParams(list)

      const data = {}
      data.progression_status_match = progressionStatusParams
      const response = await API.postData(
        ApiRoutes.dashboard.agency.update,
        data
      )
      this.setData('agency', new Agency(response.data))
      toast.success(alertMessages.progressionStatusUpdated)
    } catch (error) {
      Logger.error(error)
    }
  }

  isLocationHasError(response) {
    try {
      // Country API responds with JSON when there's a error
      const jsonResponse = JSON.parse(response)
      return jsonResponse?.error
    } catch (error) {
      return false
    }
  }

  // TODO need rewrite this code
  @action.bound
  async setLocation() {
    try {
      const location = Storage.get('geoLocation')
      if (!location) {
        const res = await fetch('https://ipapi.co/country/')
        if (res && !res.error) {
          const data = await res.text()
          if (this.isLocationHasError(data)) throw new Error('Location Error')
          if (data) {
            Storage.set('geoLocation', data)
            this.setData('userLocation', data)
          } else {
            this.setData('userLocation', 'AU')
          }
        } else {
          this.setData('userLocation', 'AU')
        }
      } else {
        this.setData('userLocation', location)
      }
    } catch (e) {
      Logger.error(e, 'setLocation')
      this.setData('userLocation', 'AU')
    }
  }

  loadFontList = async () => {
    if (this.fonts.length) return
    try {
      const response = await fetch(
        `${ApiRoutes.googlefonts}?key=${credentials.google.apiKey}&sort=popularity`
      )
      if (response && !response.error) {
        const data = await response.json()
        if (data) {
          runInAction(() => {
            const items = data.items.sort((a, b) =>
              a.family.localeCompare(b.family)
            )
            this.fonts = items.map((font) => ({
              label: font.family,
              value: font.family
            }))
          })
        }
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updateAgencyBranding = async (form) => {
    const data = { ...form.data }
    data.socialize_font = data.socialize_font.value
    delete data.backgrounds
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.agencyBranding.update,
        data
      )
      if (response.response && response.response.status === 400) {
        if (response.response.data.socialize_font) {
          toast.error(response.response.data.socialize_font[0])
        }
      } else {
        runInAction(() => {
          this.branding = new Branding(response.data)
          this.agency.branding = new Branding(response.data)
        })
        toast.success(alertMessages.agencySettingsUpdated)
      }
    } catch (error) {
      Logger.error(error)
      const err = error.response
      mapFormServerErrors(err.data, form.fields)
    }
  }

  @action
  fetchPublicCommonData = async () => {
    try {
      const response = await API.getData(ApiRoutes.public.common)
      this.setData('commonData', response.data)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  resetAgencyBranding = () => {
    this.branding = new Branding()
  }

  @action
  setAgencyBranding = (conf) => {
    this.branding = new Branding(conf)
  }

  @action
  toggleBranding = () => {
    this.brandingDefault = !this.brandingDefault
    if (this.brandingDefault) {
      this.branding = new Branding()
    } else {
      this.branding = new Branding(this.user.branding)
    }
  }

  @action
  logout = async (cb) => {
    try {
      this.showNavigationPrompt = false
      const response = await API.postData(ApiRoutes.auth.logout)
      PitchMeStore.resetStoreData()
      if (response.data.key) {
        AuthSession.set(response.data.key)
        this.fetchUserData(cb)
      } else {
        runInAction(() => {
          this.loggedIn = false
          this.user = {}
          this.branding = new Branding()
          this.agencyBackgrounds = null
        })
        AuthSession.setGlobal(false, 'RefariLoggedIn')
        AuthSession.remove()
        sessionStorage.removeItem('showAgency')
        cb && cb()
      }
      if (sessionStorage.getItem('showAgency')) {
        sessionStorage.setItem('showAgency', false)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  setData = (key, value) => {
    this[key] = value
  }

  @action
  updateProgress = (status) => {
    this.newProgress = status
  }

  @action
  setFlatContent = (key, value) => {
    this.flatContent[key] = value
  }

  @action
  initTab = (path) => {
    this.activeTab = path
  }

  @action
  onTabChange = (link, cb) => {
    this.resetSearchParams()

    cb && cb(link)
    this.activeTab = link
  }

  @computed
  get settingsPermissions() {
    const { role, userType } = this.user
    if (this.loggedIn && userType) {
      if (this.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToSettings.pages
      }

      if (role) {
        return permissions[userType][role].hasAccessToSettings.pages
      }
      return permissions[userType].hasAccessToSettings.pages
    }
    return []
  }

  @computed
  get topRecPermissions() {
    const { role, userType } = this.user
    if (this.loggedIn && userType) {
      if (this.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToTopRec.pages
      }

      if (role) {
        return permissions[userType][role].hasAccessToTopRec.pages
      }
      return permissions[userType].hasAccessToTopRec.pages
    }
    return []
  }

  @computed
  get reportsPermissions() {
    const { role, userType } = this.user
    if (this.loggedIn && userType) {
      if (this.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToReports.pages
      }

      if (role) {
        return permissions[userType][role].hasAccessToReports.pages
      }
      return permissions[userType].hasAccessToReports.pages
    }
    return []
  }

  @computed
  get toolsPermissions() {
    const { role, userType } = this.user
    if (this.loggedIn && userType) {
      if (this.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToTools.pages
      }

      if (role) {
        // return (this.agency.integration && this.agency.integration === 'jobadder')
        //   ? [...permissions[userType][role].hasAccessToTools.pages,
        //     ...permissions[userType][role].hasAccessToTools.restrictedPages]
        //   : permissions[userType][role].hasAccessToTools.pages
        return [
          ...permissions[userType][role].hasAccessToTools.pages,
          ...permissions[userType][role].hasAccessToTools.restrictedPages
        ]
      }
      // return (this.agency.integration && this.agency.integration === 'jobadder')
      //   ? [...permissions[userType].hasAccessToTools.pages,
      //     ...permissions[userType].hasAccessToTools.restrictedPages]
      //   : permissions[userType].hasAccessToTools.pages
      return [
        ...permissions[userType].hasAccessToTools.pages,
        ...permissions[userType].hasAccessToTools.restrictedPages
      ]
    }
    return []
  }

  @computed
  get badgesPermissions() {
    const { role, userType } = this.user
    if (this.loggedIn && userType === 'agency') {
      if (this.agency.access === 'candidate_widget') {
        return permissions.candidateWidget.hasAccessToBadges.pages
      }

      if (role) {
        return permissions[userType][role].hasAccessToBadges.pages
      }
      return permissions[userType].hasAccessToBadges.pages
    }
    return []
  }

  @action.bound
  toggleSuggestionMessageModal() {
    this.isOpenSuggestionMessageModal = !this.isOpenSuggestionMessageModal
  }

  @action.bound
  toggleDeleteSuggestionMessageModal() {
    this.isOpenSuggestionMessageDeleteModal =
      !this.isOpenSuggestionMessageDeleteModal
  }

  @action
  fetchSimpleJob = async (id, onSuccess) => {
    try {
      const res = await API.postData(ApiRoutes.socializeWidget.jobSimple, {
        job: id
      })
      runInAction(() => (this.socialize = res.data.ssr_link))
      onSuccess && onSuccess()
    } catch (e) {
      Logger.error(e, 'fetchSimpleJob')
    }
  }

  @action
  submitSuggestionMessage = async (form) => {
    form.pending(true)

    try {
      const payload = {
        name: form.data.name,
        message: form.data.message,
        obj_type: form.data.messageTemplateType
      }

      await API.postData(ApiRoutes.suggestions.createMessageTemplate, payload)

      toast.success(alertMessages.submissionMessages)
      this.fetchMessageTemplates()

      runInAction(() => {
        this.isOpenSuggestionMessageModal = false
        this.suggestionTemplateMessage = ''
      })

      form.reset()
      form.pending(false)
    } catch (error) {
      Logger.error(error)

      form.pending(false)

      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  fetchMessageTemplates = async () => {
    try {
      const response = await API.getData(
        ApiRoutes.suggestions.readMessageTemplates
      )

      if (response && response.data) {
        const messages = response.data.map((item) => new Messages(item))

        runInAction(() => {
          this.messageTemplates = [
            {
              id: 0,
              name: 'Load Message Template',
              objType: 'default'
            }
          ].concat(messages)
        })
      }
    } catch (e) {
      Logger.error(e, 'fetchMessageTemplate')
    }
  }

  @action
  fetchMessageTemplateDetails = async (id) => {
    try {
      this.isFetchingMessageTemplateDetails = true

      const response = await API.getData(
        ApiRoutes.suggestions.readMessageTemplateDetails(id)
      )

      if (response && response.data) {
        runInAction(() => {
          const template = new Messages(response.data)
          this.suggestionTemplateMessage = template.message
        })
      }
    } catch (e) {
      Logger.error(e, 'fetchMessageTemplateDetails')
    } finally {
      this.isFetchingMessageTemplateDetails = false
    }
  }

  @action
  updateMessageTemplate = async ({ form, name }) => {
    form.pending(true)

    try {
      const payload = {
        name,
        message: form.data.message
      }

      const response = await API.patchData(
        ApiRoutes.suggestions.updateMessageTemplate(
          this.selectedMessageTemplate.id
        ),
        payload
      )

      if (response.status === 200) {
        toast.success(alertMessages.updateMessageTemplateSuccess)

        this.closeMessageTemplateNameChangeModal()

        this.fetchMessageTemplates()
      } else {
        toast.error(alertMessages.genericErrorMessage)
      }
    } catch (error) {
      Logger.error(error)

      const { data } = error.response
      mapFormServerErrors(data, form.fields)

      toast.error(alertMessages.genericErrorMessage)
    } finally {
      form.pending(false)
    }
  }

  @action
  subscribe = async (form, filtersData) => {
    form.pending && form.pending(true)
    try {
      filtersData = {
        categories: filtersData.category,
        locations: filtersData.location,
        worktypes: filtersData.worktype,
        keywords: filtersData.query,
        salary_currency: filtersData.salary_currency,
        salary_from: filtersData.salary_currency
          ? filtersData.salary_from
          : null,
        salary_to: filtersData.salary_currency ? filtersData.salary_to : null
      }
      const data = {
        ...filtersData,
        ...form.data
      }
      // if (this.subscriptionOnSimilarIsActive) {
      //   data.job = this.jobId
      // }
      await API.postData(ApiRoutes.subscriptions.post, data)
      toast.success(alertMessages.successfulSubscription)
      // ApplyStore.setData('subscribeInfo', {})
      form.pending && form.pending(false)
    } catch (error) {
      Logger.error(error)
      form.pending && form.pending(false)
    }
  }

  @action
  suggest = async (form, filtersData) => {
    form.pending(true)

    try {
      filtersData = {
        categories: filtersData.category,
        locations: filtersData.location,
        worktypes: filtersData.worktype,
        keywords: filtersData.query,
        salary_currency: filtersData.salary_currency,
        salary_from: filtersData.salary_currency
          ? filtersData.salary_from
          : null,
        salary_to: filtersData.salary_currency ? filtersData.salary_to : null
      }

      const data = {
        ...filtersData,
        ...form.data
      }

      await API.postData(ApiRoutes.suggestions.create, data)
      toast.success(alertMessages.successfulSuggestion(data.email))
      form.reset()
      form.pending(false)
    } catch (error) {
      Logger.error(error)
      form.pending(false)
    }
  }

  @action
  deleteSuggestionMessage = async (id) => {
    try {
      await API.deleteData(ApiRoutes.suggestions.deleteMessageTemplate(id))
      toast.success(alertMessages.deleteSubmissionMessages)
      this.fetchMessageTemplates()

      runInAction(() => {
        this.isOpenSuggestionMessageDeleteModal = false
        this.suggestionTemplateMessageID = null
      })
    } catch (error) {
      Logger.error(error)
      runInAction(() => {
        this.isOpenSuggestionMessageDeleteModal = false
        this.suggestionTemplateMessageID = null
      })
    }
  }

  @action
  newsSubscription = async (form) => {
    try {
      await API.postData(ApiRoutes.mailing.create, form.data)
      this.setData('isNewsFormSubmit', true)
    } catch (error) {
      Logger.error(error)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action newIntegration = async (data) => {
    try {
      await API.postData(ApiRoutes.demo.integrations, data)
      this.setData('isNewsFormSubmit', true)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  deleteSubscription = async (id, onError) => {
    try {
      await API.deleteData(ApiRoutes.subscriptions.delete(id))
      this.setData('isSubscriptionDelete', true)
      toast.success(alertMessages.unsubscribe)
    } catch (error) {
      Logger.error(error.response)
      onError && onError()
    }
  }

  @action
  deleteCustomBackground = async (id) => {
    try {
      await API.deleteData(ApiRoutes.dashboard.backgrounds.delete(id))
      toast.success(alertMessages.customBackgroundRemove)
    } catch (error) {
      Logger.error(error.response)
    }
  }

  @action
  fetchCustomBackground = async (id, onError) => {
    try {
      const response = await API.getData(
        // ApiRoutes.dashboard.backgrounds.read(id)
        ApiRoutes.publicPosts.readBackground(id)
      )
      const data = response ? new Image(response.data) : null
      if (data && data.settings_data && data.settings_data.common) {
        CustomBackgroundStore.setData(
          'isUsedApplyText',
          data.settings_data.common.showApplyText
        )
        CustomBackgroundStore.setData(
          'isUsedreferAFriendText',
          data.settings_data.common.showReferAFriendText
        )
        CanvasCardStore.setData(
          'isUsedRecruiterTopRecBar',
          this.agency.showConsultantRating
            ? data.settings_data.common.showTopRecBar !== undefined
              ? data.settings_data.common.showTopRecBar
              : true
            : false
        )
        CanvasCardStore.setData(
          'isUsedCompanyTopRecBar',
          data.settings_data.common.showCompanyTopRecBar !== undefined
            ? data.settings_data.common.showCompanyTopRecBar
            : false
        )
        SocializeStore.setData(
          'isUsedApplyText',
          data.settings_data.common.showApplyText
        )
        SocializeStore.setData(
          'isUsedreferAFriendText',
          data.settings_data.common.showReferAFriendText
        )
      }
      return data
    } catch (error) {
      Logger.error(error.response)
      onError && onError()
    }
  }

  @action
  fetchRatings = async (id) => {
    const filterObj = {}
    filterObj.consultant = id
    const response = await API.getData(
      ApiRoutes.dashboard.toprec.testimonials.list,
      filterObj
    )
    runInAction(() => {
      this.averageRating = response.data.average || 0
      this.totalRatingCount = response.data.count || 0
    })
  }

  @action
  fetchCompanyRating = async () => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.toprec.testimonials.list
      )
      if (response && response.data) {
        runInAction(() => {
          this.companyAverageRating = response.data.average || 0
          this.companyTotalRatingCount = response.data.count || 0
        })
      } else {
        this.companyAverageRating = 0
      }
    } catch (e) {
      this.companyAverageRating = 0
      Logger.error(e)
    }
  }

  @action
  updateTestimonialBackgroundSettings = async (id, settings) => {
    try {
      const response = await API.patchData(
        ApiRoutes.dashboard.backgrounds.update(id),
        { settings_data: settings }
      )
      toast.success('testimonial updated')
    } catch (error) {
      toast.error('something went wrong, try again')
      Logger.error(error)
    }
  }

  @action
  updateCustomBackground = async (id, settings, imageBlob) => {
    try {
      CustomBackgroundStore.setPendingState({ actions: true })
      let filename = 'image.png'
      let responseSettings
      if (imageBlob) {
        if (imageBlob.type && mime.extension(imageBlob.type)) {
          filename = `image.${mime.extension(imageBlob.type)}`
        }
        const formData = new FormData()
        formData.append('preview', imageBlob, filename)
        const response = await API.postData(
          ApiRoutes.dashboard.backgrounds.preview,
          formData
        )
        if (response.data && response.data.id) {
          responseSettings = await API.patchData(
            ApiRoutes.dashboard.backgrounds.update(id),
            { settings_data: settings, preview_id: response.data.id }
          )
        }
      }

      await this.fetchAgencyBackgrounds()
      CustomBackgroundStore.setPendingState({ actions: false })
      if (responseSettings) {
        toast.success(alertMessages.customBackgroundUpdated)
      }
      return
    } catch (error) {
      Logger.error(error.response)
    }
  }

  @action
  addCustomBackground = async (settings, imageBlob, originalImageBlob) => {
    try {
      if (originalImageBlob) {
        CustomBackgroundStore.setPendingState({ actions: true })
        let filename = 'image.png'
        if (originalImageBlob.type && mime.extension(originalImageBlob.type)) {
          filename = `image.${mime.extension(originalImageBlob.type)}`
        }
        const file = new File([originalImageBlob], filename)
        const formData = new FormData()
        formData.append('image', file)

        const response = await API.postData(
          ApiRoutes.dashboard.backgrounds.list,
          formData
        )
        if (response && response.data) {
          let mainfilename = 'image.png'
          if (imageBlob.type && mime.extension(imageBlob.type)) {
            mainfilename = `image.${mime.extension(imageBlob.type)}`
          }

          const informData = new FormData()

          informData.append('preview', imageBlob, mainfilename)

          const inRes = await API.postData(
            ApiRoutes.dashboard.backgrounds.preview,
            informData
          )

          if (inRes && inRes.data && inRes.data.id) {
            await API.patchData(
              ApiRoutes.dashboard.backgrounds.update(response.data.id),
              { settings_data: settings, preview_id: inRes.data.id }
            )
          }
        }
        await this.fetchAgencyBackgrounds()
        CustomBackgroundStore.setPendingState({ actions: false })
        toast.success(alertMessages.customBackgroundAdded)
      }
      return
    } catch (error) {
      Logger.error(error.response)
    }
  }

  @action
  fetchSubscription = async (id, onError) => {
    let response
    try {
      response = await API.getData(ApiRoutes.subscriptions.read(id))
      return response.data
    } catch (error) {
      Logger.error(error.response)
      onError && onError()
    }
  }

  @action
  onContactUsSubmit = async (form) => {
    try {
      const response = await API.postData(
        ApiRoutes.flatPages.contactUs,
        form.data
      )
      toast.success(response.data.detail)
      form.reset()
    } catch (error) {
      Logger.error(error)
      const { data } = error.response
      mapFormServerErrors(data, form.fields)
    }
  }

  @action
  fetchFlatPage = async (name) => {
    try {
      if (Object.keys(this.flatContent[name]).length) return
      const response = await API.getData(ApiRoutes.flatPages.read(name))
      this.setFlatContent(name, response.data)
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  addSystemAdminRecruiter = async (form, consultants) => {
    try {
      form.pending(true)

      const formData = form.data

      const payload = {
        ...formData,
        agencies: Array.isArray(formData.agencies)
          ? formData.agencies.map((agencyId) => agencyId)
          : [formData.agencies],
        primary_agency: formData.primary_agency
          ? formData.primary_agency
          : formData.agency
      }

      const res = await API.postData(
        ApiRoutes.dashboard.systemAdmin.recruiters.create,
        payload
      )
      if (res.response && res.response.status === 400) {
        const { data } = res.response
        mapFormServerErrors(data, form.fields)
      } else {
        if (consultants) {
          consultants.fetchListData()
        }
        runInAction(() => {
          this.isOpenSystemAdminRecruiterModal = false
        })
        toast.success(alertMessages.addRecruiter)
      }
    } catch (error) {
      Logger.error(error)
      form.pending(false)
      if (error) {
        const { data } = error
        mapFormServerErrors(data, form.fields)
      }
    }
  }

  @action
  fetchAllAgenciesForSystemAdmin = async () => {
    try {
      const response = await API.getData(
        ApiRoutes.dashboard.systemAdmin.agencies.list
      )
      if (response && response.data) {
        const agencies = response.data.map((data) => ({
          value: data.id,
          name: data.name
        }))
        this.setData('agenciesList', agencies)
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  disableCtaPopup = async (key) => {
    try {
      if (key !== 'cta_website' && key !== 'cta_toprec') {
        toast.error('Invalid CTA Override')
        return
      }
      await API.patchData(ApiRoutes.dashboard.systemAdmin.systemCta, {
        [key]: false
      })
      if (key === 'cta_website') {
        toast.success('Website CTA Disabled')
      } else {
        toast.success('TopRec CTA Disabled')
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  /**
   * @param {CompanySettingsUpdate} updatedField
   */
  @action
  addInCompanyChangesList = (updatedField) => {
    const updatedFieldIndex = this.companySettingsUpdates.findIndex(
      (settingUpdate) =>
        settingUpdate.fieldName === updatedField.fieldName &&
        settingUpdate.tab === updatedField.tab
    )

    if (updatedFieldIndex === -1) {
      this.companySettingsUpdates.push(updatedField)
      return
    }

    this.companySettingsUpdates[updatedFieldIndex]
  }

  /**
   * @param {CompanySettingsUpdate} settingUpdateField
   */
  @action
  removeFromCompanyChangesList = (settingUpdateField) => {
    const filteredList = this.companySettingsUpdates.filter(
      (settingUpdate) =>
        !(
          settingUpdate.fieldName === settingUpdateField.fieldName &&
          settingUpdate.tab === settingUpdateField.tab
        )
    )
    this.companySettingsUpdates = filteredList
  }

  @action
  resetCompanyChangesList = () => {
    this.companySettingsUpdates = []
  }

  @action
  toggleSystemAdminRecruiterModal = () => {
    this.isOpenSystemAdminRecruiterModal = !this.isOpenSystemAdminRecruiterModal
  }

  @action
  addToSearchParams({ key, value }) {
    this.searchParams = {
      ...this.searchParams,
      [key]: value
    }
  }

  @action
  removeFromSearchParams(key) {
    delete this.searchParams[key]
  }

  @action
  resetSearchParams() {
    this.searchParams = {}
  }

  @computed
  get isRefariStandAlone() {
    return this.agency?.integration === 'refari'
  }

  @action
  setSelectedMessageTemplate = (template) => {
    this.selectedMessageTemplate = template
  }

  @action
  resetSelectedMessageTemplate = () => {
    this.selectedMessageTemplate = null
  }

  @action
  openMessageTemplateNameChangeModal = () => {
    this.isOpenMessageTemplateNameChangeModal = true
  }

  @action
  closeMessageTemplateNameChangeModal = () => {
    this.isOpenMessageTemplateNameChangeModal = false
  }
}

export default new RootStore()
