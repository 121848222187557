/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
import React from 'react'
import { withTheme } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react'
import Ratings from 'react-ratings-declarative'
import { toast } from 'react-toastify'
import qp from 'query-parse'
import InfoIcon from '@material-ui/icons/Info'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {
  Button,
  CircularProgress,
  Paper,
  FormControl,
  FormControlLabel,
  TextField,
  Dialog,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { pSBC, timeHelpers } from '../../../utils/helpers'
import alertMessages from '../../../constants/alertMessages'
import FieldsGroup from '../../../models/form/FieldsGroup'
import Field from '../../../models/form/Field'
import illustration_thanks_for_rating from '../../../assets/images/illustration_thanks_for_rating.svg'

@inject('testimonialsStore', 'commentStore', 'rootStore', 'authStore')
@observer
class Approve extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setQuery(props.location.search.slice(1))
    this.state = {
      selectedSocialNet: '',
      open: false,
      emailPreview: null,
      loader: false,
      openLoggedIn: false
    }
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true,
      }),
      public: new Field({
        name: 'public',
        value: '',
      }),
      private: new Field({
        name: 'private',
        value: '',
      })
    })
  }

  setQuery = (data) => {
    const newData = qp.toObject(data)
    this.props.testimonialsStore.approveTestimonials({ uid: newData.uid })
  }

  generateLink = (path, params = {}, external) => {
    const checkedParams = this.checkParams(params)
    let paramsString = qp.toString(checkedParams)
    paramsString = paramsString ? `?${paramsString}` : ''
    const { origin, pathname } = window.location
    return `${external || origin + pathname}${paramsString}${path}`
  }

  checkParams = (params) => {
    const checkedParams = {}
    Object.keys(params).forEach((item) => {
      const thisItem = params[item]
      checkedParams[item] = Array.isArray(thisItem)
        ? thisItem.toString()
        : thisItem
    })
    return checkedParams
  }

  handleSocialRadioCheck = (e) => {
    this.setState({ selectedSocialNet: e.target.value })
  }

  processBody = (body) => body
    .replace(/href="(.*?)"/g, 'href="javascript: void(0)"')
    .replace(/class=/g, '') // remove class attributes

  handleOpenHelp = async () => {
    const {
      commentStore,
      rootStore,
      testimonialsStore: { approvalConsultantInfo }
    } = this.props
    if (rootStore.loggedIn) {
      const { selectedSocialNet } = this.state
      const emailPreview = await commentStore.fetchReviewEmailPreview(
        approvalConsultantInfo.id,
        selectedSocialNet || (approvalConsultantInfo.publish_social_nets && approvalConsultantInfo.publish_social_nets.length > 0 ? approvalConsultantInfo.publish_social_nets[0] : ''),
        this.form.fields.private.value
      )
      this.setState({
        open: true,
        emailPreview
      })
    } else {
      this.setState({
        openLoggedIn: true
      })
    }
  }

  handleCloseHelp = () => {
    this.setState({
      open: false,
      emailPreview: null
    })
  }

  handleCloseLoggedIn = () => {
    this.setState({
      openLoggedIn: false
    })
  }

  toggleAuthModal = () => {
    const { authStore } = this.props
    this.handleCloseLoggedIn()
    authStore.updateSameLinkForApprove(window.location.href)
  }

  handleChangePrivate = (e) => {
    this.form.fields.private.update(e.target.value)
  }

  handleChangePublic = (e) => {
    this.form.fields.public.update(e.target.value)
  }

  submitPublicTestimonial = async () => {
    const {
      rootStore,
      commentStore,
      testimonialsStore: { approvalConsultantInfo }
    } = this.props
    if (rootStore.loggedIn) {
      if (this.form.fields.public.value) {
        this.setState({ loader: true })
        const response = await commentStore.submitPublicResponse(
          approvalConsultantInfo.id, this.form.fields.public.value
        )
        if (response) {
          this.form.fields.public.update('')
          toast.success(alertMessages.submitPublicResponse)
        }
        this.setState({ loader: false })
      }
    } else {
      this.setState({
        openLoggedIn: true
      })
    }
  }

  submitPrivateTestimonial = async () => {
    const {
      rootStore,
      commentStore,
      testimonialsStore: { approvalConsultantInfo }
    } = this.props
    if (rootStore.loggedIn) {
      const { selectedSocialNet } = this.state
      this.setState({ loader: true })
      const response = await commentStore.submitPrivateResponse(
        approvalConsultantInfo.id,
        selectedSocialNet || (approvalConsultantInfo.publish_social_nets && approvalConsultantInfo.publish_social_nets.length > 0 ? approvalConsultantInfo.publish_social_nets[0] : ''),
        this.form.fields.private.value
      )
      if (response) {
        this.form.fields.private.update('')
        toast.success(alertMessages.submitPrivateResponse)
      }
      this.setState({ loader: false })
    } else {
      this.setState({
        openLoggedIn: true
      })
    }
  }

  render() {
    const {
      theme,
      testimonialsStore: { approvalConsultantInfo, fetchApprovalData },
      rootStore
    } = this.props
    const {
      selectedSocialNet, loader, emailPreview, open, openLoggedIn
    } = this.state
    let link = ''
    if (!fetchApprovalData && approvalConsultantInfo.consultant) {
      return <div className="page-preload-spinner center-xs align-items-center full-width">
        <CircularProgress
          size={80}
          color="primary"
        />
      </div>
    }

    if (approvalConsultantInfo.consultant && approvalConsultantInfo.agency) {
      const param = { consultant: `${approvalConsultantInfo.consultant.slug}_${approvalConsultantInfo.consultant.id}` }
      link = this.generateLink(
        '#consultant-profile',
        param,
        approvalConsultantInfo.agency.widget_base_url
      )
    }

    return (
      <div className="flex-center full-width">
        <Paper className={link ? 'testimonials-feedback testimonials-feedback_approve__thank-modal testimonials-feedback__button' : 'testimonials-feedback testimonials-feedback_approve__thank-modal'}>
          <img src={illustration_thanks_for_rating} alt="" />
          <p
            className="testimonials-feedback--header-content m-t-3"
            style={{ color: theme.palette.primary1Color }}
          >
            Whoop! This testimonial has been added to your profile!
          </p>
          {
            approvalConsultantInfo && approvalConsultantInfo.rating
            && (
              <>
                <div className="feedback-page-modal">
                  <div className="excellent-box" style={{ backgroundColor: pSBC(0.95, theme.palette.primary1Color), marginTop: 20 }}>
                    <div className="excellent-rating">
                      <div className="review-star">
                        <ul>
                          <Ratings
                            rating={approvalConsultantInfo.rating}
                            widgetDimensions="24px"
                            widgetSpacings="1px"
                            widgetRatedColors="#F2C94C"
                            widgetEmptyColors="#CBD3E3"
                          >
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                          </Ratings>
                          <div style={{ display: 'inline-block', paddingLeft: 8 }}>
                            ({approvalConsultantInfo.testimonial_type === 'app_placed' || approvalConsultantInfo.testimonial_type === 'invited' ? 'Testimonial' : 'Comment'})
                          </div>
                        </ul>
                        <ul>
                          <li className="range" style={{ color: theme.palette.primary1Color }}>({approvalConsultantInfo.rating} out of 5)</li>
                          <li className="by">by</li>
                          <li className="Bruce">{approvalConsultantInfo.author ? `${approvalConsultantInfo.author.first_name} ${approvalConsultantInfo.author.last_name}` : ''}</li>
                          <li>
                            <span>
                              {approvalConsultantInfo.author
                                ? (approvalConsultantInfo.author.author_type === 'contact'
                                  ? 'Client'
                                  : `${approvalConsultantInfo.author.author_type[0].toUpperCase()}${approvalConsultantInfo.author.author_type.slice(1)}`)
                                : ''}
                            </span>
                          </li>
                        </ul>
                      </div>
                      {
                    approvalConsultantInfo.date
                    && <div className="excelent-time">
                      <p>{
                        timeHelpers.timeDifference(
                          new Date(),
                          new Date(approvalConsultantInfo.date)
                        )
                      }</p>
                    </div>
                  }
                    </div>
                    {
                  (approvalConsultantInfo.text || approvalConsultantInfo.comment)
                  && <div className="excelent-cont">
                    <p className="moretext moretext3">{approvalConsultantInfo.text || approvalConsultantInfo.comment}</p>
                  </div>
                }
                  </div>
                </div>
                <div className="feedback-page-comment">
                  <div className="feedback-page-comment-respond">
                    <ArrowUpwardIcon />
                    <span>Respond</span>
                  </div>
                  <div className="feedback-page-comment-response">
                    <div className="feedback-page-comment-response-public">
                      <div className="feedback-page-comment-response-title">Public response</div>
                      <div className="feedback-page-comment-response-box">
                        <TextField
                          type="text"
                          value={this.form.fields.public.value}
                          fullWidth
                          inputProps={{
                            maxLength: 1000
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                          label=""
                          multiline
                          maxRows={4}
                          rows={4}
                          onChange={this.handleChangePublic}
                          error={this.form.fields.public.serverErrors
                        && this.form.fields.public.serverErrors.length > 0}
                          helperText={this.form.fields.public.serverErrors[0]}
                        />
                        <div className="feedback-page-comment-response-box-submit" style={{ margin: '10px 0' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={!this.form.fields.public.value || loader}
                            onClick={this.submitPublicTestimonial}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="feedback-page-comment-response-private">
                      {
                    approvalConsultantInfo.publish_social_nets
                    && approvalConsultantInfo.publish_social_nets.length > 0
                    && <>
                      <div className="feedback-page-comment-response-title">
                        <div className="feedback-page-comment-response-title-label">Private response</div>
                        {
                          rootStore.loggedIn && <div className="feedback-page-comment-response-title-icon" title="Preview email response">
                            <InfoIcon
                              onClick={this.handleOpenHelp}
                              style={{ color: theme.palette.primary1Color, cursor: 'pointer' }}
                            />
                          </div>
                        }
                      </div>
                      <div className="feedback-page-comment-response-box">
                        <TextField
                          type="text"
                          value={this.form.fields.private.value}
                          fullWidth
                          inputProps={{
                            maxLength: 1000
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                          label=""
                          multiline
                          maxRows={4}
                          rows={4}
                          onChange={this.handleChangePrivate}
                          error={this.form.fields.private.serverErrors
                        && this.form.fields.private.serverErrors.length > 0}
                          helperText={this.form.fields.private.serverErrors[0]}
                        />
                        <div className="feedback-page-comment-response-box-social">
                          <FormControl component="fieldset">
                            <RadioGroup
                              name="social-net"
                              onChange={this.handleSocialRadioCheck}
                              value={
                                selectedSocialNet
                                || approvalConsultantInfo.publish_social_nets[0]
                              }
                              style={{ flexDirection: 'row', display: 'flex' }}
                            >
                              {
                              approvalConsultantInfo.publish_social_nets.map((social) => (
                                <FormControlLabel
                                  key={social}
                                  name={social}
                                  value={social}
                                  control={<Radio color="primary" />}
                                  label={social.charAt(0).toUpperCase() + social.slice(1)}
                                />
                              ))
                            }
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="feedback-page-comment-response-box-submit" style={{ marginBottom: 10 }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            disabled={loader}
                            onClick={this.submitPrivateTestimonial}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </>
                  }
                    </div>
                  </div>
                </div>
              </>
            )
          }
          {
            link
            && <Button
              variant="contained"
              className="m-t-3"
              href={link}
              style={{ color: theme.palette.primary1Color }}
            >
              Back to Your Profile
            </Button>
          }
          {
            emailPreview && <Dialog
              fullWidth
              open={open}
              maxWidth="sm"
              PaperProps={{
                style: { maxWidth: 650 }
              }}
              onClose={this.handleCloseHelp}
            >
              <div className="refari-modal-content">
                <div className="refari-placements-wrapper">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Preview</h5>
                      <button type="button" onClick={this.handleCloseHelp} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {
                        emailPreview.message_html && <iframe
                          title="Email preview"
                          border="0"
                          className="mail-preview__body"
                          srcDoc={this.processBody(emailPreview.message_html)}
                          style={{ width: '100%', minHeight: '620px' }}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          }
          {
            openLoggedIn && <Dialog
              fullWidth
              open={openLoggedIn}
              maxWidth="sm"
              PaperProps={{
                style: { maxWidth: 400 }
              }}
              onClose={this.handleCloseLoggedIn}
            >
              <div className="refari-modal-content">
                <div className="refari-placements-wrapper">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Alert</h5>
                      <button type="button" onClick={this.handleCloseLoggedIn} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p style={{ textAlign: 'center' }}>In order to send this response, you need to sign in to your Refari account.</p>
                      <div className="align-items-center" style={{ justifyContent: 'center' }}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className="m-t-1"
                          onClick={this.toggleAuthModal}
                        >
                          Sign in
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          }
        </Paper>
      </div>
    )
  }
}

export default withTheme(Approve)
