import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../../router/RouterHooks'
import URLs from '../../../router/routesUrls'
import asyncComponent from '../../shared/asyncComponent'

@inject('billingStore')
@inject('agenciesStore')
@observer
class Billing extends React.Component {
  static propTypes = {
    billingStore: PropTypes.shape({
      initTab: PropTypes.func.isRequired,
      onTabChange: PropTypes.func.isRequired,
      billingPermissions: PropTypes.array.isRequired
    }),
    history: PropTypes.object.isRequired,
    agenciesStore: PropTypes.shape({
      availableSeveralAgencies: PropTypes.bool.isRequired,
      activeAgency: PropTypes.object.isRequired,
      isFetch: PropTypes.bool.isRequired
    })
  }

  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.billingStore.initTab(pathname)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.history.location
    this.props.billingStore.onTabChange(pathname)
  }

  onTabChange = (link) => {
    this.props.agenciesStore.progressNew()
    this.props.billingStore.onTabChange(link, () => {
      this.props.history.push(link)
    })
  }

  render() {
    const {
      agenciesStore: { isFetch },
      match
    } = this.props

    return (
      <div className="billing">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container">
            <div className="billing-container">
              <Switch>
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.billing.children.budget.path}`}
                  component={asyncComponent(() => import('./Budget/index'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.billing.children.cardSettings.path}`}
                  component={asyncComponent(() => import('./CardSettings'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.billing.children.payments.path}`}
                  component={asyncComponent(() => import('./Payments'))}
                />
                <PrivateRoute
                  exact
                  path={`${match.url}${URLs.billing.children.payments.children.details.path}`}
                  component={asyncComponent(() => import('./Payment'))}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(Billing)
