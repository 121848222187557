import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Workers = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <path
        d="M256,444c22.056,0,40-17.944,40-40v-8c0-22.056-17.944-40-40-40s-40,17.944-40,40v8C216,426.056,233.944,444,256,444z    M232,396c0-13.233,10.766-24,24-24s24,10.767,24,24v8c0,13.233-10.766,24-24,24s-24-10.767-24-24V396z"
        fill={fill}
      />
      <path
        d="M288.072,444.262h-6.79c-2.778,0-5.357,1.441-6.813,3.807C269.803,455.652,263.072,460,256,460   s-13.803-4.348-18.469-11.931c-1.456-2.366-4.035-3.807-6.813-3.807h-6.79C205.947,444.262,192,461.323,192,476v17.328   c0,8.09,6.582,14.672,14.672,14.672h98.689c8.071,0,14.638-6.567,14.638-14.639V476c0-6.964-3.244-14.758-8.678-20.847   C305.054,448.13,296.797,444.262,288.072,444.262z M304,492h-96v-16c0-5.404,6.203-15.738,15.928-15.738h2.568   C234.066,470.321,244.612,476,256,476s21.934-5.679,29.503-15.738h2.568c9.725,0,15.928,10.334,15.928,15.738V492z"
        fill={fill}
      />
      <path
        d="M256,92c22.056,0,40-17.944,40-40v-8c0-22.056-17.944-40-40-40s-40,17.944-40,40v8C216,74.056,233.944,92,256,92z M232,44   c0-13.233,10.766-24,24-24s24,10.767,24,24v8c0,13.233-10.766,24-24,24s-24-10.767-24-24V44z"
        fill={fill}
      />
      <path
        d="M288.072,92.262h-6.79c-2.778,0-5.357,1.441-6.813,3.807C269.803,103.651,263.072,108,256,108s-13.803-4.349-18.469-11.931   c-1.456-2.366-4.035-3.807-6.813-3.807h-6.79C205.947,92.262,192,109.323,192,124v17.328c0,8.09,6.582,14.672,14.672,14.672h98.689   c8.071,0,14.638-6.567,14.638-14.639V124c0-6.964-3.244-14.758-8.678-20.847C305.054,96.13,296.797,92.262,288.072,92.262z    M304,140h-96v-16c0-5.404,6.203-15.738,15.928-15.738h2.568C234.066,118.321,244.612,124,256,124s21.934-5.679,29.503-15.738   h2.568c9.725,0,15.928,10.334,15.928,15.738V140z"
        fill={fill}
      />
      <path
        d="M408,220v8c0,22.056,17.944,40,40,40s40-17.944,40-40v-8c0-22.056-17.944-40-40-40S408,197.944,408,220z M424,220   c0-13.233,10.766-24,24-24s24,10.767,24,24v8c0,13.233-10.766,24-24,24s-24-10.767-24-24V220z"
        fill={fill}
      />
      <path
        d="M503.322,279.153c-6.268-7.023-14.524-10.891-23.25-10.891h-6.79c-2.778,0-5.357,1.441-6.813,3.807   C461.803,279.651,455.072,284,448,284s-13.803-4.349-18.469-11.931c-1.456-2.366-4.035-3.807-6.813-3.807h-6.79   C397.947,268.262,384,285.323,384,300v17.328c0,8.09,6.582,14.672,14.672,14.672h98.689c8.071,0,14.638-6.567,14.638-14.639V300   C512,293.036,508.756,285.242,503.322,279.153z M496,316h-96v-16c0-5.404,6.203-15.738,15.928-15.738h2.568   C426.066,294.321,436.612,300,448,300s21.934-5.679,29.503-15.738h2.568c9.725,0,15.928,10.334,15.928,15.738V316z"
        fill={fill}
      />
      <path
        d="M64,268c22.056,0,40-17.944,40-40v-8c0-22.056-17.944-40-40-40s-40,17.944-40,40v8C24,250.056,41.944,268,64,268z M40,220   c0-13.233,10.766-24,24-24s24,10.767,24,24v8c0,13.233-10.766,24-24,24s-24-10.767-24-24V220z"
        fill={fill}
      />
      <path
        d="M128,317.361V300c0-6.964-3.244-14.758-8.678-20.847c-6.268-7.023-14.524-10.891-23.25-10.891h-6.79   c-2.778,0-5.357,1.441-6.813,3.807C77.803,279.651,71.072,284,64,284s-13.803-4.349-18.469-11.931   c-1.456-2.366-4.035-3.807-6.813-3.807h-6.79C13.947,268.262,0,285.323,0,300v17.328C0,325.418,6.582,332,14.672,332h98.689   C121.433,332,128,325.433,128,317.361z M112,316H16v-16c0-5.404,6.203-15.738,15.928-15.738h2.568   C42.066,294.321,52.612,300,64,300s21.934-5.679,29.503-15.738h2.568c9.725,0,15.928,10.334,15.928,15.738V316z"
        fill={fill}
      />
      <path
        d="M180.647,436.651c-39.415-16.836-72.49-46.846-93.131-84.501c-2.124-3.874-6.985-5.293-10.86-3.169   c-3.875,2.124-5.294,6.986-3.17,10.86c22.355,40.784,58.181,73.288,100.876,91.524c1.025,0.438,2.09,0.645,3.139,0.645   c3.107,0,6.063-1.822,7.361-4.86C186.597,443.087,184.71,438.387,180.647,436.651z"
        fill={fill}
      />
      <path
        d="M435.34,348.989c-3.874-2.125-8.737-0.705-10.861,3.169c-20.638,37.646-53.706,67.651-93.11,84.486   c-4.063,1.735-5.95,6.437-4.214,10.5c1.298,3.038,4.253,4.859,7.361,4.859c1.048,0,2.114-0.207,3.139-0.646   c42.684-18.236,78.501-50.734,100.854-91.508C440.634,355.975,439.215,351.112,435.34,348.989z"
        fill={fill}
      />
      <path
        d="M317.462,78.046c42.625,14.393,79.054,43.558,102.576,82.122c1.507,2.471,4.14,3.835,6.837,3.835   c1.419,0,2.857-0.378,4.158-1.171c3.772-2.301,4.964-7.224,2.664-10.996c-25.475-41.767-64.938-73.356-111.117-88.949   c-4.184-1.413-8.726,0.834-10.139,5.021C311.028,72.093,313.276,76.633,317.462,78.046z"
        fill={fill}
      />
      <path
        d="M80.966,162.83c1.301,0.794,2.738,1.172,4.158,1.172c2.697,0,5.331-1.364,6.837-3.835   c23.528-38.572,59.965-67.739,102.601-82.129c4.187-1.413,6.435-5.952,5.022-10.138c-1.414-4.187-5.956-6.436-10.138-5.021   c-46.191,15.59-85.663,47.182-111.144,88.957C76.002,155.606,77.194,160.529,80.966,162.83z"
        fill={fill}
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Workers
Workers.propTypes = propTypes
Workers.defaultProps = defaultProps
