/* eslint-disable no-alert */
/* eslint-disable no-underscore-dangle */
import qp from 'query-parse'
import { ApiRoutes } from '../Urls'
import API from '../API'

class TwitterGetShareToken {
  title = 'Share via Twitter'
  alert =
    'Pop-ups were blocked on this page. To proceed sharing to this network, please allow pop-ups now and click "ok", or "cancel" and procced charing without this network.'
  options = qp
    .toString({
      width: 600,
      height: 485,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')

  process = (options) => {
    this.getToken(options)
  }

  getToken = async ({ callback, onError }) => {
    try {
      let callbackIsApplied = false
      const response = await API.getData(ApiRoutes.socializeWidget.twitterAccessLink)

      if (response.data.has_token) {
        callback({
          id: response.data.id,
          hasToken: true
        })
        return
      }

      window.newShareTwitterWindow && window.newShareTwitterWindow.close()

      window.newShareTwitterWindow = window.open(
        response.data.link,
        this.title,
        this.options
      )

      if (!window.newShareTwitterWindow) {
        const isAccepted = window.confirm(this.alert)
        if (isAccepted) {
          window.newShareTwitterWindow = window.open(
            response.data.link,
            this.title,
            this.options
          )
        } else {
          onError && onError()
          return
        }
      }

      window.newShareTwitterWindow.focus()

      const receiveMessage = (event) => {
        if (event.data && event.source === window.newShareTwitterWindow) {
          window.removeEventListener('message', receiveMessage)
          callbackIsApplied = true
          this.stop()
          callback({
            id: response.data.id,
            hasToken: true
          })
          window.newShareTwitterWindow && window.newShareTwitterWindow.close()
          delete window.newShareTwitterWindow
        }
      }

      window.addEventListener('message', receiveMessage, false)

      // check if popup is closed, call onError
      const check = () => {
        if (
          window.newShareTwitterWindow
          && window.newShareTwitterWindow.closed
          && !callbackIsApplied
        ) {
          this.stop()
          callback({
            id: response.data.id,
            hasToken: true
          })
          window.removeEventListener('message', receiveMessage)
          callbackIsApplied = true
          return
        }
        this._timer = setTimeout(check, 1500)
      }
      this._timer = setTimeout(check, 1500)
    } catch (error) {
      clearInterval(this._timer)
      onError && onError()
    }
  }

  stop() {
    clearTimeout(this._timer)
  }
}

export default new TwitterGetShareToken()
