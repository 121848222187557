/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import CloseModalButton from '../CloseModalButton'
import FieldsGroup from '../../../../models/form/FieldsGroup'
import Field from '../../../../models/form/Field'
import RaisedButton from '../../common/RaisedButton'
import Input from '../../formComponents/Input'
import { validationErrors as errors } from '../../../../utils/helpers'
import Modal from '../Modal'

@observer
class PitchMeTemplateModal extends React.Component {
  constructor(props) {
    super(props)
    this.form = new FieldsGroup({
      nonFieldErrors: new Field({
        name: 'non_field_errors',
        value: '',
        isErrorField: true
      }),
      name: new Field({
        name: 'name',
        value: '',
        validators: [errors.required(), this.checkTemplateName]
      })
    })
  }

  closeModal = () => {
    const {
      pitchMeStore: { toggleTemplateModal }
    } = this.props
    toggleTemplateModal()
  }

  checkTemplateName = (value) => {
    const {
      pitchMeStore: { templates }
    } = this.props
    if (Array.isArray(templates)) {
      const templatesName = templates.map((item) => item.name)
      return templatesName.includes(value) ? 'Duplicate Template Name' : null
    } else {
      return 'something went wrong, Try again'
    }
  }

  submitForm = (e) => {
    e.preventDefault()
    this.props.pitchMeStore.onSubmitTemplate(this.form)
  }

  render() {
    const {
      pitchMeStore: { isOpenTemplateModal },
      theme
    } = this.props

    const style = {
      title: { color: theme.palette.popupPrimary },
      background: { backgroundColor: theme.palette.popupPrimary }
    }

    return (
      <Modal
        className="refari-w-modal-container"
        maxWidth="xs"
        open={isOpenTemplateModal}
        onClose={this.closeModal}
        classes={{
          container: 'iframe-dialog'
        }}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        PaperProps={{
          className: 'refari-modal refari-message-template-modal',
          style: {
            overflowScrolling: 'touch',
            WebkitOverflowScrolling: 'touch',
            backgroundColor: theme.widgetBackgroundColor,
            borderColor: theme.widgetBackgroundColor
          }
        }}
      >
        <div className="refari-modal-content">
          <CloseModalButton onClose={this.closeModal} />
          <h3
            style={style.title}
            className="refari-long-text refari-modal-header"
          >
            Save Template
          </h3>
          <div
            style={{
              width: '100%',
              maxWidth: 400,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <form className="refari-form-section" onSubmit={this.submitForm}>
              <Input fieldData={this.form.fields.name} label="Template name" />
              <RaisedButton
                type="submit"
                fullWidth
                color="primary"
                className={`refari-submit-btn refari-button-raised ${
                  !this.form.isValid || this.form.isPending
                    ? 'refari-button-raised-disabled'
                    : ''
                }`}
                style={{ width: 'auto' }}
                disabled={!this.form.isValid || this.form.isPending}
              >
                Save
              </RaisedButton>
            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withTheme(PitchMeTemplateModal)
