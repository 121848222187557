import { v1 as uuidv1 } from 'uuid'

export class Messages {
  constructor(data) {
    if (!data) data = {}

    this.id = data.id || uuidv1()
    this.message = data.message || ''
    this.name = data.name
    this.templateType = data.obj_type
  }
}

export default Messages
