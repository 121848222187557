/* eslint-disable react/no-render-return-value */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Button, TextField } from '@material-ui/core'
import ReactDOM from 'react-dom'
import { MUITheme } from './utils/MUITheme'
import Storage from './utils/Storage'

class DevGuard extends React.Component {
  errorMessage = 'Enter a valid password'
  state = {
    value: '',
    errorText: ''
  }

  password = process.env.REACT_APP_DEV_PASSWORD

  handleSuccess = () => {
    if (!this.state.value) return
    if (this.state.value === this.password) {
      const container = document.getElementById('root')
      ReactDOM.unmountComponentAtNode(container)
      Storage.set('dev-pass-accepted', true)
      this.props.onSuccess()
    } else {
      this.setState({
        ...this.state,
        errorText: this.errorMessage,
      })
    }
  }

  changeValue = (e) => {
    this.setState({
      errorText: '',
      value: e.target.value ? e.target.value.trim() : ''
    })
  }

  render() {
    const styles = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      height: '100%',
    }
    return (
      <MuiThemeProvider theme={createTheme(MUITheme())}>
        <div style={styles}>
          <div style={{
            display: 'flex',
            flexFlow: 'column',
            alignTtems: 'center'
          }}
          >
            <TextField
              value={this.state.value}
              onChange={this.changeValue}
              name="password"
              fullWidth
              style={{ marginBottom: 15 }}
              error={!!this.state.errorText}
              helperText={this.state.errorText}
              placeholder="Password"
            />
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSuccess}
            >
              Confirm
            </Button>
          </div>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default (renderApp) => ReactDOM.render(<DevGuard onSuccess={renderApp} />, document.getElementById('root'))
