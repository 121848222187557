/* eslint-disable no-console */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Button, Dialog } from '@material-ui/core'
import GoogleLogin from 'react-google-login'
import { withTheme } from '@material-ui/core/styles'
import { FacebookProvider, Login } from 'react-facebook'
import LinkedinLogin from '../socials/LinkedinLogin'
import SignIn from '../forms/SignIn'
import SignUp from '../forms/SignUp'
import ForgotPassword from '../forms/ForgotPasswordCreate'
import routesUrls from '../../../router/routesUrls'
import GoogleMultiColorIcon from '../icons/GoogleMultiColorIcon'
import LinkedInIcon from '../icons/LinkedInIcon'
import FacebookIcon from '../icons/FacebookIcon'
import EmailIcon from '../icons/EmailIcon'
import ButtonLink from '../buttons/ButtonLink'
import ArrowIcon from '../icons/ArrowIcon'
import CloseButton from '../buttons/CloseButton'
import ResendVerifyForm from '../forms/ResendVerifyForm'
import colors from '../../../constants/colors'
import credentials from '../../../constants/credentials'

@inject('authStore')
@observer
class AuthModal extends React.Component {
  static propTypes = {
    authStore: PropTypes.shape({
      isOpenModal: PropTypes.bool.isRequired,
      isSignUp: PropTypes.bool.isRequired,
      isSignIn: PropTypes.bool.isRequired,
      toggleModal: PropTypes.func.isRequired,
      toggleSignUp: PropTypes.func.isRequired,
      toggleSignIn: PropTypes.func.isRequired,
      socialCallback: PropTypes.func.isRequired,
      linkedinCodeCallback: PropTypes.func.isRequired,
      isResendVerify: PropTypes.bool.isRequired,
      resendEmailVerify: PropTypes.func.isRequired
    })
  }

  constructor(props) {
    super(props)
    this.googleButton = React.createRef()
  }

  componentDidUpdate() {
    if (window.location.hash.includes('google')) {
      if (
        this.googleButton &&
        this.googleButton.current &&
        !this.googleButton.current.disabled
      ) {
        setTimeout(() => {
          this.googleButton.current.click()
        }, 2000)
      }
    }
  }

  successRedirect = (lastActionTab) => {
    const { authStore, history } = this.props
    if (authStore.previousLink) {
      window.close()
    } else if (authStore.stayOnSameLink) {
      this.props.authStore.setData('isOpenModal', false)
      this.props.authStore.setData('stayOnSameLink', '')
    } else if (
      history.location.state &&
      history.location.state.from &&
      history.location.state.from.pathname
    ) {
      this.props.history.replace(history.location.state.from.pathname)
    } else if (lastActionTab) {
      if (lastActionTab === 'referrals') {
        this.props.history.replace(routesUrls.dashboard.link)
      } else if (lastActionTab === 'applications') {
        this.props.history.replace(
          routesUrls.dashboard.children.appliedJobs.link
        )
      } else if (lastActionTab === 'saved_jobs') {
        this.props.history.replace(routesUrls.dashboard.children.featured.link)
      } else if (lastActionTab === 'subscriptions') {
        this.props.history.replace(routesUrls.dashboard.children.jobAlerts.link)
      } else {
        this.props.history.replace(routesUrls.dashboard.link)
      }
    } else {
      this.props.history.replace(routesUrls.dashboard.link)
    }
  }

  handleClose = () => {
    const {
      authStore: { toggleModal },
      history: { replace }
    } = this.props
    toggleModal()
    replace({ state: null })
  }

  render() {
    const {
      authStore: {
        isOpenModal,
        isSignUp,
        isSignIn,
        isResendVerify,
        toggleSignUp,
        toggleSignIn,
        socialCallback,
        linkedinCodeCallback,
        resendEmailVerify
      },
      authStore
    } = this.props
    const { theme } = this.props
    const styles = {
      colorPrimary: { color: theme.palette.primary1Color }
    }

    return (
      <Dialog
        open={isOpenModal}
        onClose={this.handleClose}
        fullWidth
        className="modal"
      >
        <div className="modal-content">
          <CloseButton onClose={this.handleClose} />
          <div className="auth-modal">
            <div>
              {isResendVerify ? (
                <div className="verify-form">
                  <h3 style={styles.colorPrimary}>Success!</h3>
                  <ResendVerifyForm resendEmailVerify={resendEmailVerify} />
                </div>
              ) : (
                <div className="row vertical-modal">
                  {isSignUp ? (
                    <div className="col-xs-12 col-sm-6 left-side">
                      <h3 style={styles.colorPrimary} className="with-form">
                        Sign Up with:
                      </h3>
                      <SignUp authStore={authStore} />
                      <ButtonLink
                        onClick={toggleSignUp}
                        icon={<ArrowIcon className="back-btn" />}
                        textLink="Back"
                        color={theme.palette.textColor}
                        className="modal-back-btn"
                      />
                    </div>
                  ) : (
                    <div className="col-xs-12 col-sm-6 left-side">
                      <h3 style={styles.colorPrimary}>
                        {window.location.hash.includes('google')
                          ? 'Signing In with:'
                          : 'Sign In with:'}
                      </h3>
                      {!window.location.hash.includes('google') && (
                        <div className="social-btn-wrap">
                          <FacebookProvider
                            appId={credentials.facebook.appId}
                            version="v19.0"
                          >
                            <Login
                              scope="email"
                              onCompleted={(res) => {
                                socialCallback(
                                  credentials.facebook.key,
                                  res.tokenDetail,
                                  this.successRedirect
                                )
                              }}
                              onError={(e) => {
                                console.error(e, 'Facebook Login')
                              }}
                            >
                              {({ handleClick }) => (
                                <Button
                                  variant="contained"
                                  fullWidth
                                  startIcon={<FacebookIcon />}
                                  style={{
                                    backgroundColor: colors.facebook,
                                    color: '#fff'
                                  }}
                                  onClick={handleClick}
                                >
                                  Facebook
                                </Button>
                              )}
                            </Login>
                          </FacebookProvider>
                        </div>
                      )}

                      {!window.location.hash.includes('google') && (
                        <div className="social-btn-wrap">
                          <Button
                            variant="contained"
                            component="label"
                            fullWidth
                            startIcon={<LinkedInIcon />}
                            style={{
                              backgroundColor: colors.linkedin,
                              color: '#fff'
                            }}
                          >
                            <LinkedinLogin
                              clientId={credentials.linkedin.clientId}
                              className="social-btn hide"
                              btnTitle="LinkedIn"
                              callback={(code) => {
                                linkedinCodeCallback(code, this.successRedirect)
                              }}
                            />
                            LinkedIn
                          </Button>
                        </div>
                      )}

                      <div className="social-btn-wrap">
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={
                            <div
                              style={{
                                display: 'inherit',
                                backgroundColor: '#fff',
                                borderRadius: '100%',
                                padding: '1px'
                              }}
                            >
                              <GoogleMultiColorIcon
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  left: '1px',
                                  height: '0.7em',
                                  width: '0.7em'
                                }}
                              />
                            </div>
                          }
                          fullWidth
                          style={{
                            color: '#fff',
                            backgroundColor: colors.google
                          }}
                        >
                          <GoogleLogin
                            clientId={credentials.google.clientId}
                            onSuccess={(res) => {
                              socialCallback(
                                credentials.google.key,
                                res,
                                this.successRedirect
                              )
                            }}
                            className="social-btn hide"
                            render={(renderProps) => (
                              <button
                                ref={this.googleButton}
                                id="google-btn-login"
                                className="hide"
                                onClick={renderProps.onClick}
                                style={{ opacity: 0 }}
                                disabled={renderProps.disabled}
                              />
                            )}
                          />
                          Google
                        </Button>
                      </div>
                      {!window.location.hash.includes('google') && (
                        <div className="social-btn-wrap">
                          <Button
                            variant="contained"
                            onClick={toggleSignUp}
                            startIcon={<EmailIcon />}
                            color="primary"
                            fullWidth
                          >
                            Email
                          </Button>
                        </div>
                      )}
                      {!window.location.hash.includes('google') && (
                        <p className="links-to-static">
                          {'By proceeding, you agree to our '}
                          <a
                            style={{ color: theme.palette.primary1Color }}
                            className="inline"
                            href="https://refari.co/privacy-policy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          {' and '}
                          <a
                            style={{ color: theme.palette.primary1Color }}
                            className="inline"
                            href="https://refari.co/terms-of-use/"
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                          .
                        </p>
                      )}
                    </div>
                  )}

                  {!window.location.hash.includes('google') && (
                    <div className="vertical-divider" />
                  )}
                  {isSignIn
                    ? !window.location.hash.includes('google') && (
                        <div className="col-xs-12 col-sm-6 right-side">
                          <h3 style={styles.colorPrimary}>Already a member?</h3>
                          <SignIn
                            authStore={authStore}
                            successRedirect={this.successRedirect}
                          />
                          <ButtonLink
                            onClick={() => {
                              toggleSignIn()
                            }}
                            textLink="Forgot password?"
                            className="forgot-password-btn"
                          />
                        </div>
                      )
                    : !window.location.hash.includes('google') && (
                        <div className="col-xs-12 col-sm-6 right-side">
                          <h3 style={styles.colorPrimary}>Forgot password?</h3>
                          <ForgotPassword authStore={authStore} />
                          <ButtonLink
                            onClick={() => {
                              toggleSignIn()
                            }}
                            icon={<ArrowIcon className="back-btn" />}
                            textLink="Back"
                            color={theme.palette.textColor}
                            className="modal-back-btn"
                          />
                        </div>
                      )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(AuthModal)
