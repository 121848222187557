/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CompanyUsersIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const CompanyUsersIcon = ({
  iconColor,
  ...restProps
}: CompanyUsersIconProps): React.ReactElement => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...restProps}>
    <defs>
      <clipPath id="companyUsersIcon">
        <path
          fill={iconColor}
          stroke={iconColor}
          d="M-.081 1.371h24v24h-24z"
          data-name="Rectangle 1391"
        />
      </clipPath>
    </defs>
    <g
      clipPath="url(#companyUsersIcon)"
      data-name="Company user"
      transform="translate(.081 -1.371)"
    >
      <g fill={iconColor} data-name="Group 2985">
        <path
          d="M18.42 20.205a2.859 2.859 0 1 0-2.859-2.86 2.859 2.859 0 0 0 2.859 2.86Z"
          data-name="Path 2385"
        />
        <path
          d="M22.995 25.372h-9.15c0-2.86 1.234-5.719 4.575-5.719s4.575 2.859 4.575 5.719Z"
          data-name="Path 2386"
        />
        <path
          d="M15.305 20.13a3.7 3.7 0 0 1-1.05-2.142H12.38v-1.7h2.088a3.868 3.868 0 0 1 1.306-1.7H12.38v-1.716h4.615v1.076a4.432 4.432 0 0 1 1.385-.224 4.5 4.5 0 0 1 .923.1V4.78a.89.89 0 0 0-.923-.852h-2.308v-1.7a.89.89 0 0 0-.923-.856H6.842a.89.89 0 0 0-.923.852v1.7H3.611a.89.89 0 0 0-.923.852v17.045h-.923a.89.89 0 0 0-.923.851v.852h12.19a5.386 5.386 0 0 1 2.273-3.394ZM12.38 6.058h4.615v1.7H12.38Zm0 3.408h4.615v1.7H12.38Zm-2.769 8.521H4.996v-1.7h4.615Zm0-3.408H4.996v-1.707h4.615Zm0-3.408H4.996v-1.7h4.615Zm0-3.408H4.996v-1.7h4.615Z"
          data-name="Path 2387"
        />
      </g>
    </g>
  </SvgIcon>
)

export default CompanyUsersIcon
