import React from 'react'
import { CircularProgress } from '@material-ui/core'

const CommonSpinner = ({ showLoader }) => {
  if (!showLoader) return null
  return (
    <div className="common-spinner">
      <CircularProgress
        size={80}
        color="primary"
      />
    </div>
  )
}

export default CommonSpinner
