import React from 'react'
import { SvgIcon } from '@material-ui/core'

const QuotesIcon = (props) => {
  const style = {
    width: '60px',
    height: '28px',
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 10 30"
    >
      <path
        id="Path"
        d="M13.0657 5.5598C13.879 5.22678 14.2201 4.27896 13.8528 3.51046L12.5672 0.871946C12.1999 0.129318 11.3078 -0.203955 10.5207 0.129318C8.29066 1.05152 6.37541 2.2555 4.85371 3.71565C2.99093 5.43171 1.73159 7.4042 1.04945 9.58161C0.367308 11.7849 0 14.7818 0 18.5987V26.463C0 27.3083 0.708379 28 1.57418 28H11.885C12.7508 28 13.4592 27.3083 13.4592 26.463V16.3956C13.4592 15.55 12.7508 14.8586 11.885 14.8586H6.95261C7.00508 12.2711 7.63475 10.1964 8.78915 8.63354C9.73366 7.37884 11.1504 6.35417 13.0657 5.5598Z"
        fill={props.fill}
      />
      <path
        id="Path_2"
        d="M31.064 5.5391C31.8789 5.20578 32.2203 4.2571 31.8526 3.48791L30.5646 0.872881C30.1966 0.129322 29.3029 -0.203998 28.5143 0.129322C26.3063 1.05236 24.4135 2.25744 22.8629 3.69328C20.9963 5.4368 19.7086 7.41108 19.0249 9.59048C18.3415 11.744 18 14.7441 18 18.5901V26.4616C18 27.3077 18.7097 28 19.5771 28H29.9074C30.7749 28 31.4846 27.3077 31.4846 26.4616V16.3851C31.4846 15.5387 30.7749 14.8467 29.9074 14.8467H24.9394C24.992 12.2568 25.6231 10.1802 26.7794 8.6159C27.7257 7.3598 29.1452 6.3342 31.064 5.5391Z"
        fill={props.fill}
      />
    </SvgIcon>
  )
}


export default QuotesIcon
