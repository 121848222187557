/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import qp from 'query-parse'
import { ApiRoutes } from '../Urls'
import credentials from '../../constants/credentials'

class LinkedinHelper {
  linkedinApiAuth = 'https://www.linkedin.com/oauth/v2/authorization'
  title = 'Authorization via LinkedIn'
  options = qp
    .toString({
      width: 600,
      height: 400,
      left: 100,
      top: 100
    })
    .split('&')
    .join(',')

  process = (option, isPersonal) => {
    this.getToken(option, isPersonal)
  }

  getToken = ({ clientId, callback, onError }, isPersonal) => {
    let callbackIsApplied = false
    window.newLinkedInWindow && window.newLinkedInWindow.close()
    // 'r_liteprofile,r_emailaddress,rw_organization_admin,w_organization_social'
    // 'r_liteprofile,r_emailaddress,rw_organization_admin,w_organization_social'
    let scope =
      'r_liteprofile,r_emailaddress,w_member_social,rw_organization_admin,w_organization_social'
    if (isPersonal)
      scope =
        'r_liteprofile,r_emailaddress,w_member_social,rw_organization_admin,w_organization_social'
    const queries = qp.toString({
      response_type: 'code',
      client_id: clientId,
      state: Math.random().toString(36).substring(7),
      redirect_uri: ApiRoutes.auth.linkedinCallback,
      scope: scope
    })

    window.newLinkedInWindow = window.open(
      `${this.linkedinApiAuth}?${queries}`,
      this.title,
      this.options
    )

    // check if popup is closed, call onErrer
    this._timer = setInterval(() => {
      if (
        window.newLinkedInWindow &&
        window.newLinkedInWindow.closed &&
        !callbackIsApplied
      ) {
        onError && onError(credentials.linkedin.key)
        this.logout()
        clearInterval(this._timer)
      }
    }, 1000)

    const receiveMessage = (event) => {
      if (event.data && event.source === window.newLinkedInWindow) {
        clearInterval(this._timer)
        callback({ code: event.data.code, network: credentials.linkedin.key })
        window.removeEventListener('message', receiveMessage, false)
        callbackIsApplied = true
        window.newLinkedInWindow && window.newLinkedInWindow.close()
        delete window.newLinkedInWindow
        setTimeout(() => {
          this.logout()
        }, 0)
      }
    }

    window.addEventListener('message', receiveMessage, false)
  }

  logout = () =>
    new Promise((resolve, reject) => {
      window.IN.User.refresh()
      const isAuthorized = window.IN.User.isAuthorized()
      if (!isAuthorized) {
        resolve()
        return
      }
      window.IN.User.logout((success) => {
        if (success) {
          resolve()
        } else {
          resolve()
        }
      })
    })

  stop() {
    clearInterval(this._timer)
  }
}

export default new LinkedinHelper()
