import { Recruiters } from '../../types/APITypes'

export type IRecruitersModel = {
  id: number
  value: string
  firstName: string
  lastName: string
  fullName: string
  name: string
}

class RecruitersModel implements IRecruitersModel {
  id: IRecruitersModel['id']
  value: IRecruitersModel['value']
  firstName: IRecruitersModel['firstName']
  lastName: IRecruitersModel['lastName']
  fullName: IRecruitersModel['fullName']
  name: IRecruitersModel['fullName']

  constructor(data: Recruiters) {
    this.id = data.id
    this.value = String(data.id)
    this.firstName = data.first_name
    this.lastName = data.last_name
    this.fullName = this.getFullName(data.first_name, data.last_name)
    this.name = this.getFullName(data.first_name, data.last_name)
  }

  getFullName(
    firstName: Recruiters['first_name'],
    lastName: Recruiters['last_name']
  ): IRecruitersModel['fullName'] {
    return `${firstName} ${lastName}`
  }
}

export default RecruitersModel
