import React, { Component } from 'react'

import RaisedButton from '../../../common/RaisedButton'
import ColorPicker from '../../../formComponents/colorSlider/ColorPicker'
import {
  getColorContrastRatio,
  hasMinimumColorContrast,
  MINIMUM_CONTRAST_RATIO
} from '../../../../../utils/helpers'

class SmartPitchColorSettingContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shouldShowErrorMessage: false
    }

    this.handleSaveSettings = this.handleSaveSettings.bind(this)
  }

  hasAmpleColorContrast() {
    const { textColor, backgroundColor } = this.props

    return hasMinimumColorContrast(
      getColorContrastRatio({
        backgroundColor: backgroundColor || '#ffffff',
        foregroundColor: textColor
      }),
      MINIMUM_CONTRAST_RATIO
    )
  }

  handleSaveSettings() {
    const { onSaveSettings, setData, backgroundColor } = this.props

    if (!this.hasAmpleColorContrast()) {
      this.setState({
        shouldShowErrorMessage: true
      })
    } else {
      typeof setData === 'function' &&
        setData('lastAcceptedBackgroundColor', backgroundColor || '#ffffff')
      onSaveSettings()
    }
  }

  render() {
    const {
      textColor,
      backgroundColor,
      onFontColorChange,
      onBackgroundColorChange,
      hasBackgroundColorPicker,
      scrollToBottom
    } = this.props

    const { shouldShowErrorMessage } = this.state

    const displayErrorMessage =
      shouldShowErrorMessage && !this.hasAmpleColorContrast()
    const colorContrastRatio = getColorContrastRatio({
      backgroundColor: backgroundColor || '#ffffff',
      foregroundColor: textColor
    })

    const displayableContrastRatio =
      String(colorContrastRatio).length > 2
        ? colorContrastRatio.toFixed(2)
        : colorContrastRatio

    return (
      <>
        <ColorPicker
          label="Font color:"
          color={textColor}
          onChangeComplete={onFontColorChange}
          onDisplayColorPicker={scrollToBottom}
        />
        {hasBackgroundColorPicker && (
          <ColorPicker
            label="Background color:"
            color={backgroundColor || '#ffffff'}
            onChangeComplete={onBackgroundColorChange}
            onDisplayColorPicker={scrollToBottom}
          />
        )}
        <p className="m-0 mb-10 mt-10">{`The current contrast ratio between the font color and the background color is ${displayableContrastRatio}`}</p>
        {displayErrorMessage && (
          <p className="m-0 text-red">{`The contrast ratio must be at least ${MINIMUM_CONTRAST_RATIO.toFixed(
            1
          )} between the font color and the background color to be accepted. Optimal contrast ratio's would score above 4.5`}</p>
        )}
        <div className="align-center m-t-2">
          <RaisedButton
            color="primary"
            className="refari-button-raised"
            onClick={this.handleSaveSettings}
          >
            Update
          </RaisedButton>
        </div>
      </>
    )
  }
}

export default SmartPitchColorSettingContent
