import { Locations } from '../../types/APITypes'

type LocationModelSchema = {
  id: number
  name: string
  value: string
}

export type ILocationsModel = LocationModelSchema & {
  children?: {
    parentLocationId: number
    subLocations: Array<LocationModelSchema>
  }
}

class LocationsModel implements ILocationsModel {
  id: ILocationsModel['id']
  name: ILocationsModel['name']
  value: ILocationsModel['value']
  children: ILocationsModel['children']

  constructor(data: Locations) {
    this.id = data.id
    this.name = data.name
    this.value = String(data.id)
    this.children = data.children ? this.normalizeChildren(data) : undefined
  }

  private normalizeChildren(data: Locations): ILocationsModel['children'] {
    return {
      parentLocationId: data.id,
      subLocations:
        data.children.length < 1
          ? []
          : data.children.map((subLocation) => ({
              id: subLocation.id,
              value: String(subLocation.id),
              name: subLocation.name
            }))
    }
  }
}

export default LocationsModel
