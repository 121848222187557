/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import { action } from 'mobx'
import { toast } from 'react-toastify'
import alertMessages from '../constants/alertMessages'
import API from '../utils/API'
import Logger from '../utils/Logger'
import { ApiRoutes } from '../utils/Urls'

export class CommentStore {
  @action
  fetchReviewEmailPreview = async (testimonial, socialNet, text) => {
    try {
      const data = {
        testimonial,
        social_net: socialNet,
        text
      }
      const response = await API.postData(
        ApiRoutes.dashboard.reviews.emailPreview,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error)
      if (error.status && error.status === 400 && error.data) {
        toast.error(
          error.data.testimonial && error.data.testimonial.length > 0
            ? error.data.testimonial[0]
            : alertMessages.error
        )
      }
    }
  }

  @action
  fetchTestimonialRequestReviewEmail = async (
    testimonialID,
    requestRevisionText,
    isIncorrectRecruiter
  ) => {
    try {
      const data = {
        testimonial: testimonialID,
        text: requestRevisionText,
        need_select_recruiter: isIncorrectRecruiter
      }
      const response = await API.postData(
        ApiRoutes.dashboard.reviews.emailRevisionRequest,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error)
      toast.error('Something went wrong, Try again later.')
    }
  }

  @action
  submitPublicResponse = async (testimonial, text) => {
    try {
      const data = {
        testimonial,
        text
      }
      const response = await API.postData(
        ApiRoutes.dashboard.reviews.comments,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error)
      if (error.status && error.status === 400 && error.data) {
        toast.error(
          error.data.testimonial && error.data.testimonial.length > 0
            ? error.data.testimonial[0]
            : alertMessages.error
        )
      }
    }
  }

  @action
  deletePublicResponse = async (id, onSuccess) => {
    try {
      await API.deleteData(ApiRoutes.dashboard.reviews.updateComment(id))
      onSuccess && onSuccess()
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  updatePublicTestimonial = async (id, text) => {
    try {
      const response = await API.putData(
        ApiRoutes.dashboard.reviews.updateComment(id),
        { text }
      )
      return response.data
    } catch (error) {
      Logger.error(error)
    }
  }

  @action
  submitPrivateResponse = async (testimonial, socialNet, text) => {
    try {
      const data = {
        testimonial,
        social_net: socialNet,
        text
      }
      const response = await API.postData(
        ApiRoutes.dashboard.reviews.responses,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error)
      if (error.status && error.status === 400 && error.data) {
        toast.error(
          error.data.testimonial && error.data.testimonial.length > 0
            ? error.data.testimonial[0]
            : alertMessages.error
        )
      }
    }
  }

  @action
  submitTestimonialRevisionRequest = async (
    testimonialID,
    requestRevisionText,
    isIncorrectRecruiter
  ) => {
    try {
      const data = {
        testimonial: testimonialID,
        text: requestRevisionText,
        need_select_recruiter: isIncorrectRecruiter
      }
      const response = await API.postData(
        ApiRoutes.dashboard.reviews.testimonialRevision,
        data
      )
      return response.data
    } catch (error) {
      Logger.error(error)
      if (error.status && error.data) {
        toast.error('Something went wrong, Try again later.')
      }
    }
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }
}

export default new CommentStore()
