import React from 'react'
import { inject, observer } from 'mobx-react'
import { withTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import TalentAlert from '../tools/CreateTalentAlert'
import qp from 'query-parse'
import * as axios from 'axios'

@inject('rootStore', 'agenciesStore', 'talentAlertStore')
@observer
class CreateTalentAlert extends React.Component {
  constructor(props) {
    super(props)

    const { pathname } = props.history.location
    props.rootStore.initTab(pathname)
    const queryData = qp.toObject(props.history.location.search.slice(1))
    this.queryParam = {
      editSuggestionId: queryData?.['edit-suggestion-id'],
      skillsList: queryData.skill?.split(',') || [],
      locationsList: queryData.location?.split(',') || [],
      consultantList: queryData.consultant?.split(',') || [],
      workTypeList: queryData.worktype?.split(',') || [],
      apiKey: queryData.apiKey,
      themePrimaryColor: queryData.themeColor,
      email: queryData.email || '',
      search: queryData.search || undefined
    }

    const agencyStore = props.agenciesStore
    agencyStore.setData('agencyKey', this.queryParam.apiKey)
    agencyStore.setData('agencyApiKey', this.queryParam.apiKey)
    axios.defaults.headers['Api-Key'] = this.queryParam.apiKey
    /**
     * setup primary color
     */
    if (this.queryParam.themePrimaryColor)
      props.rootStore.setData('branding', {
        ...props.rootStore.agency.branding,
        primaryColor: this.queryParam.themePrimaryColor
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillUnmount() {
    const { talentAlertStore } = this.props
  
    talentAlertStore.skills.forEach((el) => el.reset())
    talentAlertStore.locations.forEach((el) => el.reset())
    talentAlertStore.worktypes.forEach((el) => el.reset())
  }

  fetchData = () => {
    const { rootStore } = this.props
    /**
     * @note make a call to widget agency endpoint to setup agency
     */

    rootStore.fetchWidgetAgency()
  }

  render() {
    const {
      agenciesStore: { isFetch }
    } = this.props

    return (
      <div className="settings">
        {isFetch ? (
          <div className="page-preload-spinner center-xs align-items-center">
            <CircularProgress size={80} color="primary" />
          </div>
        ) : (
          <div className="container">
            <div
              className={`settings-container ${
                this.props.location &&
                (this.props.location.pathname.includes('question-config') ||
                  this.props.location.pathname.includes('pitch-me'))
                  ? 'question-mapping-container'
                  : ''
              }`}
            >
              <TalentAlert {...this.props} isPublic isLoadingFromWidget email={this.queryParam.email} editSuggestionId={this.queryParam.editSuggestionId} keywords={this.queryParam.search} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withTheme(CreateTalentAlert)
