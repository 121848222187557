import React, { Component } from 'react'
import { Dialog, type DialogProps } from '@material-ui/core'

type AlertContainerProps = DialogProps & {
  isPublic: boolean
}

class AlertContainer extends Component<AlertContainerProps> {
  render() {
    const { isPublic, ...restProps } = this.props

    return isPublic ? (
      // @ts-ignore TODO:: pass only the props needed
      <div {...restProps} />
    ) : (
      <Dialog {...restProps} />
    )
  }
}

export default AlertContainer
