import { action } from 'mobx'
import { toast } from 'react-toastify'
import API from '../utils/API'
import { ApiRoutes } from '../utils/Urls'
import Logger from '../utils/Logger'
import SpinnersStore from './SpinnersStore'
import alertMessages from '../constants/alertMessages'

export class CandidatesStore {
  @action
  createRating = async (id, rating, cb) => {
    try {
      await API.putData(ApiRoutes.dashboard.applications.rating(id), { rating })
      toast.success(alertMessages.setRating(rating))
      cb && cb()
    } catch (error) {
      Logger.error(error)
    }
  }

  resendCandidateEventToATS = async (id) => {
    try {
      const response = await API.postData(
        ApiRoutes.dashboard.applications.resendToATS(id),
        {}
      )
      if (response.status === 200) {
        toast.success('Success')
      }
    } catch (error) {
      Logger.error(error)
    }
  }

  @action makeAction = async (actions, id, cb) => {
    SpinnersStore.loadingIsStart(SpinnersStore.keys.candidates)
    await Promise.all(
      // eslint-disable-next-line no-async-promise-executor
      actions.map(
        (action) =>
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve, reject) => {
            try {
              const res = await API.postData(action.url(id))
              toast.success(action.success)
              resolve(res)
            } catch (error) {
              Logger.error(error)
              reject(error)
            }
          })
      )
    )
      .then(() => {
        SpinnersStore.loadingIsEnd(SpinnersStore.keys.candidates)
        cb && cb()
      })
      .catch(() => {
        SpinnersStore.loadingIsEnd(SpinnersStore.keys.candidates)
      })
  }

  @action
  setData = (key, data) => {
    this[key] = data
  }
}

export default new CandidatesStore()
