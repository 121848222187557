/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { observable, computed, action, runInAction } from 'mobx'

export type IField = {
  name?: string
  initialValue?: string
  value?: string | Array<string>
  isTouched: boolean
  isBlurred: boolean
  isFocused: boolean
  validators: ((value: string) => string | null)[]
  serverErrors: string[]
  initFileData: {
    name: string
    path: string
  }
  fileData?: {
    name: string
    path: string
  }
  isErrorField: boolean
  isFile: boolean
  isDirty: boolean
  clientErrors: Array<string | null>
  setServerErrors(errors: string[]): void
  isValid: boolean
  hasErrors: number
  update(
    newValue: string | Array<string>,
    fileData?: { name: string; path: string }
  ): void
  blur(): void
  focus(): void
  reset(): void
  setData(key: any, data: any): void
}

type ConstructorArgs = {
  name: IField['name']
  value: IField['value']
  validators?: IField['validators']
  file?: boolean
  fileData?: IField['fileData']
  isErrorField?: IField['isErrorField']
}

export default class Field implements IField {
  @observable name: IField['name']
  @observable initialValue: IField['initialValue']
  @observable value: IField['value']
  @observable isTouched = false
  @observable isBlurred = false
  @observable isFocused = false
  @observable.ref validators: IField['validators'] = []
  @observable serverErrors: IField['serverErrors'] = []
  initFileData = {
    name: '',
    path: ''
  }
  @observable fileData: IField['fileData'] = this.initFileData
  isFile = false
  isErrorField = false

  @computed
  get isDirty(): IField['isDirty'] {
    return this.initialValue !== this.value
  }

  @computed
  get clientErrors(): IField['clientErrors'] {
    return this.validators
      .map((validator) =>
        // @ts-ignore Need to re-evaluate validation
        validator(this.value)
      )
      .filter((message) => message !== null)
  }

  @action.bound
  setServerErrors(errors: string[]): void {
    this.serverErrors = errors
  }

  @computed
  get isValid(): IField['isValid'] {
    return !this.clientErrors.length && !this.serverErrors.length
  }

  @computed
  get hasErrors(): IField['hasErrors'] {
    return this.clientErrors.length && this.serverErrors.length
  }

  constructor({
    name,
    value = '',
    validators,
    file,
    fileData,
    isErrorField = false
  }: ConstructorArgs) {
    if (!name) throw new Error('Property "name" is required!')

    runInAction(`Initialize form field: '${name}'`, () => {
      this.name = name
      this.initialValue = !Array.isArray(value) ? value : ''
      this.value = this.initialValue

      this.isErrorField = isErrorField

      if (Array.isArray(value)) {
        // @ts-ignore Need to re-evaluate this logic
        this.validators = value
      } else {
        this.validators = Array.isArray(validators) ? validators : []
      }

      if (file) {
        this.isFile = true
        if (fileData) {
          this.fileData = fileData
        }
      }
    })
  }

  @action('Update field value')
  update: IField['update'] = (newValue, fileData) => {
    this.isTouched = true
    this.value = newValue
    this.serverErrors = []
    if (this.isFile) {
      this.fileData = fileData
    }
  }

  @action('Field blurred')
  blur: IField['blur'] = () => {
    this.isFocused = false
    this.isBlurred = true
  }

  @action('Field focused')
  focus: IField['focus'] = () => {
    this.isFocused = true
  }

  @action('Reset field value')
  reset: IField['reset'] = () => {
    this.isTouched = false
    this.value = this.initialValue
    if (this.isFile) {
      this.fileData = this.initFileData
    }
  }

  @action.bound
  setData(key: any, data: any): void {
    // @ts-ignore Fix index signature
    this[key] = data

    // @ts-ignore Fix return type
    return this
  }
}
