import React from 'react'
import PropTypes from 'prop-types'
import {
  inject,
  observer,
} from 'mobx-react'
import { Button, Dialog } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import CloseButton from '../buttons/CloseButton'

@inject('utilsStore')
@observer
class ConfirmationModal extends React.Component {
  static propTypes = {
    utilsStore: PropTypes.shape({
      confirmationKey: PropTypes.string.isRequired,
      toggleModal: PropTypes.func.isRequired,
      modals: PropTypes.object.isRequired,
      Component: PropTypes.any,
    }),
  }

  toggleModal = () => {
    const { toggleModal, confirmationKey } = this.props.utilsStore
    toggleModal(confirmationKey)
  }

  render() {
    const {
      utilsStore: { confirmationKey, modals },
      theme,
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color },
    }

    return (
      <Dialog
        fullWidth
        className="modal"
        open={modals[confirmationKey].isOpen}
      >
        <div className="modal-content">
          <CloseButton onClose={this.toggleModal} />
          <h3
            style={style.title}
          >
            {modals[confirmationKey].title}
          </h3>
          <h4>{modals[confirmationKey].message}</h4>
          {
            !!modals[confirmationKey].Component
            && modals[confirmationKey].Component
          }
          <div className="align-items-center space-around">
            <Button
              onClick={() => {
                modals[confirmationKey].cancelCB && modals[confirmationKey].cancelCB()
                this.toggleModal()
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                modals[confirmationKey].cb()
                this.toggleModal()
              }}
              color="primary"
            >
              {modals[confirmationKey].confirmBtnName}
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(ConfirmationModal)
