import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

const ButtonLink = ({
  theme, onClick, textLink, className, icon, color, style, cssColor
}) => {
  const btnColor = cssColor
    ? null
    : color || theme.palette.primary1Color
  return (
    <button
      type="button"
      style={{
        color: btnColor,
        ...style
      }}
      className={`link btn-link ${className}`}
      onClick={onClick}
    >
      {icon}{textLink}
    </button>
  )
}

ButtonLink.propTypes = {
  theme: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  textLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.object,
  color: PropTypes.string,
  style: PropTypes.object,
  cssColor: PropTypes.bool,
}

ButtonLink.defaultProps = {
  className: '',
  style: {},
  cssColor: false,
}

export default withTheme(ButtonLink)
