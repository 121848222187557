import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string.isRequired,
}

const defaultProps = {
  className: '',
}

const Presentation = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="90px" viewBox="0 0 511.99931 511" width="90px">
    <g>
      <g>
        <path
          d="M489.061,35.88H267.469V22.971c0-6.334-5.135-11.469-11.469-11.469c-6.334,0-11.469,5.135-11.469,11.469V35.88H22.939    C10.29,35.88,0,46.17,0,58.819v21.284c0,12.648,10.29,22.939,22.939,22.939h3.117v257.795c0,12.648,10.29,22.939,22.939,22.939    h195.536v33.662l-84.993,62.343c-5.107,3.747-6.212,10.925-2.465,16.032c3.748,5.108,10.925,6.211,16.032,2.465l71.426-52.392    v43.144c0,6.334,5.135,11.469,11.469,11.469c6.335,0,11.469-5.135,11.469-11.469v-43.144l71.426,52.392    c2.044,1.499,4.42,2.222,6.775,2.222c3.529,0,7.01-1.623,9.257-4.686c3.747-5.107,2.643-12.285-2.465-16.032l-84.993-62.343    v-33.662h195.536c12.648,0,22.939-10.29,22.939-22.939V103.041h3.117c12.648,0,22.939-10.29,22.939-22.939V58.819    C512,46.17,501.71,35.88,489.061,35.88z M463.005,360.835H48.995V103.041h414.011V360.835z M489.061,80.102H22.939V58.819h466.123    V80.102z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M255.782,136.254c-5.701,0-10.322,4.621-10.322,10.322v79.157h-79.157c-5.701,0-10.322,4.621-10.322,10.322    c0,55.031,44.771,99.801,99.802,99.801s99.803-44.771,99.803-99.801S310.813,136.254,255.782,136.254z M255.782,315.212    c-40.15,0-73.413-30.046-78.487-68.834h78.487c5.701,0,10.322-4.621,10.322-10.322v-78.487    c38.788,5.074,68.835,38.337,68.835,78.487C334.94,279.703,299.43,315.212,255.782,315.212z"
          fill={fill}
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M220.845,125.575c-41.652,0-75.539,33.891-75.539,75.548c0,5.701,4.621,10.322,10.322,10.322h65.217    c5.701,0,10.322-4.621,10.322-10.322v-65.226C231.168,130.196,226.547,125.575,220.845,125.575z M210.523,190.801h-43.599    c4.203-22.013,21.59-39.403,43.599-43.607V190.801z"
          fill={fill}
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
)


export default Presentation
Presentation.propTypes = propTypes
Presentation.defaultProps = defaultProps
