/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon } from '@material-ui/core'

const TwitterIcon = (props) => {
  const style = {
    width: '20px',
    height: '16px',
    marginBottom: '-2px',
    ...props.style
  }
  return (
    <SvgIcon
      {...props}
      style={style}
      viewBox="0 0 20 16"
    >
      <path
        fill={props.style && props.style.color ? props.style.color : '#fff'}
        d="M19.6 1.89c-.72.32-1.5.53-2.31.63A4.05 4.05 0 0 0 19.06.29a8.1 8.1 0 0 1-2.56.98 4.02 4.02 0 0 0-6.85 3.67A11.45 11.45 0 0 1 1.36.74 4.04 4.04 0 0 0 2.61 6.1a3.99 3.99 0 0 1-1.82-.5v.05a4.01 4.01 0 0 0 3.22 3.94 3.67 3.67 0 0 1-1.81.07 4.03 4.03 0 0 0 3.75 2.8A8.1 8.1 0 0 1 0 14.12a11.38 11.38 0 0 0 6.16 1.81c7.4 0 11.44-6.13 11.44-11.44l-.01-.52a8.28 8.28 0 0 0 2.01-2.08z"
      />
    </SvgIcon>
  )
}

export default TwitterIcon
