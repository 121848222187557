import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import type { History } from 'history'
import { CircularProgress } from '@material-ui/core'

import AlertContainer from 'src/components/pages/tools/Shared/AlertContainer'
import TalentAlertContent from './TalentAlertContent'
import UpsellContent from 'src/components/shared/upsellComponents/UpsellContent'
import UpsellBg from 'src/components/shared/upsellComponents/UpsellBg'

type CreateTalentAlertProps = {
  isPublic: boolean
  rootStore: Record<string, any>
  history: History
  isLoadingFromWidget: boolean
  email: string
  editSuggestionId: string
  keywords?: string
}

@inject('rootStore')
@observer
class CreateTalentAlert extends Component<CreateTalentAlertProps> {
  isAgencyUser = () => {
    const { rootStore } = this.props

    return rootStore.loggedIn && rootStore.user.isAgency
  }

  renderDashboardContent = (props: CreateTalentAlertProps) => {
    const { rootStore, history, email, editSuggestionId, keywords } = props

    return rootStore.agency.hasTalentBoard ? (
      // @ts-ignore Store props are injected
      <TalentAlertContent
        history={history}
        email={email}
        editSuggestionId={editSuggestionId}
        keywords={keywords}
      />
    ) : (
      <UpsellContent featureName="Talent Board" />
    )
  }

  shouldRenderDashboardContent = () => {
    const { isLoadingFromWidget } = this.props

    if (isLoadingFromWidget && !this.isAgencyUser()) {
      return false
    }

    return true
  }

  render() {
    const {
      isPublic,
      rootStore,
      email,
      isLoadingFromWidget,
      editSuggestionId,
      history,
      keywords
    } = this.props

    if (rootStore.fetchingAgency) {
      return (
        <div className="page-preload-spinner center-xs align-items-center">
          <CircularProgress size={80} color="primary" />
        </div>
      )
    }

    return (
      <div className="reports">
        <div className={isLoadingFromWidget ? 'blur-mask' : 'blur-mask-rating'}>
          <AlertContainer
            className={`blur-mask-modal-container modal ${
              this.props.isPublic && 'ml-0 w-100 mt-1'
            }`}
            fullWidth
            open
            BackdropProps={{ className: 'blur-mask-modal-container-overlay' }}
            disableBackdropClick
            disableEscapeKeyDown
            isPublic={isPublic}
          >
            {!this.shouldRenderDashboardContent() ? (
              // @ts-ignore Store props are injected
              <TalentAlertContent
                history={history}
                email={email}
                editSuggestionId={editSuggestionId}
                keywords={keywords}
              />
            ) : (
              this.renderDashboardContent(this.props)
            )}
          </AlertContainer>
        </div>
        {!rootStore.agency.hasTalentBoard && (
          <UpsellBg blurImageType="talentAlert" />
        )}
      </div>
    )
  }
}

export default CreateTalentAlert
