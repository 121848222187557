export class LetUsHelpYou {
  constructor(data) {
    if (!data) data = {}
    this.question = data.question || ''
    this.id = data.id || Date.now()
    this.answerType = data.answer_type
    this.answerValues =
      data.answer_type === 'integer' ||
      data.answer_type === 'text' ||
      data.answer_type === 'file'
        ? data.answer_values || ''
        : data.answer_values || []
    this.prefix = data.prefix
    this.example = data.example
    this.status = data.status
    this.multiSelect = data.multi_select
    this.additionalText = data.additional_text || ''
    this.maxLength = data.max_length
    this.isRequired = data.is_required
    this.getAnswerValues = Array.isArray(this.answerValues)
      ? this.answerValues.map((el) => ({
          name: el,
          value: false
        }))
      : this.answerValues
    this.getFileData = null
  }
}

export let getLetUsHelpYouPreviewQuestion = (data) => {
  const instanceValues = { ...data }
  instanceValues.question = data.question
  instanceValues.id = data.id
  instanceValues.answer_type = data.answerType
  instanceValues.answer_values = data.answerValues
  instanceValues.multi_select = data.multiSelect
  instanceValues.additional_text = data.additionalText
  instanceValues.max_length = data.maxLength
  instanceValues.is_required = data.isRequired
  return new LetUsHelpYou(instanceValues)
}
