import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  withTheme
} from '@material-ui/core'
import moment from 'moment'
import { getDateFormat, pSBC } from 'src/utils/helpers'
import RootStore from 'src/stores/RootStore'
import CalendarIcon from '../../icons/CalendarIcon'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

type ScheduleSocialiseModalProps = {
  socializeStore: any
  isScheduleOpen: boolean
  theme: any
  onClose: () => void
}

function ScheduleSocialiseModal(props: ScheduleSocialiseModalProps) {
  const { socializeStore } = props
  const { scheduleSocialise } = socializeStore

  const [selectedDateTime, setSelectedDateTime] = React.useState(
    scheduleSocialise || moment(new Date()).add(5, 'minute')
  )

  const handleDateChange = (date: any) => {
    setSelectedDateTime(date)
  }

  const handleTimeChange = (time: any) => {
    console.log('handleTImeChange')
    console.log(time.format('hh:mm'))

    setSelectedDateTime(time)
  }
  const isDateValid = () => {
    const today = moment(new Date())
    if (today.isSame(selectedDateTime, 'date')) return true
    return today.diff(selectedDateTime) < 0
  }
  const isTimeValid = () => {
    const today = moment(new Date())
    // const hours = today.diff(selectedDateTime, 'hour')
    const minutes = today.diff(selectedDateTime, 'minutes')
    if (minutes <= 0) return true
    /**
     * for atleast five minutes in future
     */
    // if (minutes <= -4) return true
    return false
  }

  const primaryColor = props.theme?.palette?.primary1Color

  function getTimeZoneLocaleString() {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale
    return new Date()
      .toLocaleDateString(locale, {
        day: '2-digit',
        timeZoneName: 'long'
      })
      .slice(4)
  }

  const dateFormat = getDateFormat(RootStore.agency.currency)

  const pastSchedulePostWarning =
    "You can't schedule a post to send in the past"
  return (
    <Dialog open={props.isScheduleOpen} disableBackdropClick>
      <DialogTitle style={{ backgroundColor: '#F3F3F3' }}>
        <Typography
          align="center"
          color="primary"
          style={{ fontSize: '1.35rem' }}
        >
          Schedule Post
        </Typography>
        <IconButton
          style={{ position: 'absolute', right: 5, top: 5 }}
          onClick={() => {
            socializeStore.addScheduleSocialiseDateTime(scheduleSocialise)

            socializeStore.setPendingState({
              schedulePost: false
            })
            props.onClose()
            // reset the state to socializestore time
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: '1rem' }}>
          <Typography style={{ color: '#5F5F5F' }}>
            Select the date and time for scheduled the post
          </Typography>

          <div
            style={{
              display: 'inline-block',
              marginTop: '1rem'
            }}
          >
            {/* {!isDateValid() && <span>{pastSchedulePostWarning}</span>} */}
            <div
              style={{
                // @ts-ignore fix return type of helper function
                backgroundColor: pSBC(0.8, primaryColor),
                padding: '1rem',
                borderRadius: '4px',
                maxWidth: '14rem'
              }}
            >
              <DatePicker
                //   autoOk
                //   variant="inline"
                label="Date"
                value={selectedDateTime}
                onChange={handleDateChange}
                format={dateFormat}
                minDate={moment(new Date())}
              />
            </div>
          </div>
          <div
            style={{
              display: 'inline-block'
            }}
          >
            <div
              style={{
                // @ts-ignore fix return type of helper function
                backgroundColor: pSBC(
                  0.8,
                  isTimeValid() ? primaryColor : '#FF0000'
                ),
                padding: '1rem',
                marginLeft: '1rem',
                borderRadius: '4px',
                maxWidth: '14rem'
              }}
            >
              <TimePicker
                //   variant="inline"
                label="Time"
                value={selectedDateTime}
                onChange={handleTimeChange}
                error={!isTimeValid()}
              />
            </div>
          </div>
          {!isTimeValid() && (
            <span style={{ marginTop: '1rem', color: 'red', display: 'block' }}>
              {pastSchedulePostWarning}
            </span>
          )}
          <div style={{ marginTop: '1rem' }}>
            Time zone: {getTimeZoneLocaleString()}
          </div>
        </div>
      </DialogContent>
      <Paper
        style={{
          // marginTop: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0.5rem 2rem'
        }}
        elevation={2}
      >
        <div>
          {/* 
            // @ts-ignore fix calendarIcon propTypes */}
          <CalendarIcon width={40} height={40} />{' '}
          <Typography
            display="inline"
            style={{ color: '#25B4D5', fontSize: '0.9rem' }}
          >
            Will send on {selectedDateTime.format('ddd, MMM DD, YYYY')}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              socializeStore.addScheduleSocialiseDateTime(selectedDateTime)

              socializeStore.setPendingState({
                schedulePost: false
              })
              props.onClose()
              // setIsScheduleSocialiseOpen(false)
            }}
            disabled={!(isDateValid() && isTimeValid())}
            style={{
              borderRadius: '20px'
            }}
          >
            Save
          </Button>
        </div>
      </Paper>
    </Dialog>
  )
}

export default withTheme(ScheduleSocialiseModal)
