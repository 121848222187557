/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Image, Group } from 'react-konva'

export default class CanvasCircleImage extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired
  }
  static contextTypes = {
    attrs: PropTypes.object.isRequired
  }

  constructor() {
    super()
    this.image = new window.Image()
    this.image.setAttribute('crossOrigin', 'anonymous')
    // hack - safari can't draw an image that is not loaded yet
    this.image.src =
      'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'
    this.state = {
      width: 57,
      height: 57
    }
  }

  componentDidMount() {
    this.image.src = this.props.imageUrl
    this.image.onload = () => {
      if (this.refs.imageNode) {
        this.refs.imageNode.getLayer().batchDraw()
      }
    }
    this.setState({
      width: this.context.attrs.width,
      height: this.context.attrs.height
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.imageUrl !== this.props.imageUrl ||
      newProps.width !== this.props.width ||
      newProps.height !== this.props.height ||
      newProps.selectedLogo !== this.props.selectedLogo
    ) {
      this.setState({
        width: newProps.width || 57,
        height: newProps.height || 57
      })
      if (newProps.imageUrl) {
        this.image.src = newProps.imageUrl
        this.image.onload = () => {
          if (this.refs.imageNode) {
            this.refs.imageNode.getLayer().batchDraw()
          }
        }
      }
    }
  }

  clipFunc = (ctx) => {
    const { width, height } = this.state
    ctx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, false)
  }

  render() {
    const { width, height } = this.state
    const { selectedLogo, isTestimonials } = this.props
    if (selectedLogo && selectedLogo === 'wide_logo' && !isTestimonials) {
      return (
        <Group draggable={false}>
          <Image
            image={this.image}
            width={width}
            height={height}
            ref="imageNode"
            x={0}
            y={0}
          />
        </Group>
      )
    }
    return (
      <Group clipFunc={this.clipFunc} draggable={false}>
        <Image
          image={this.image}
          width={width}
          height={height}
          ref="imageNode"
          x={0}
          y={0}
        />
      </Group>
    )
  }
}
