/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SvgIcon, type SvgIconProps } from '@material-ui/core'

type CandidateEventsIconProps = SvgIconProps & {
  style?: React.CSSProperties
  iconColor?: string
}

const CandidateEventsIcon = ({
  iconColor,
  ...restProps
}: CandidateEventsIconProps): React.ReactElement => (
  <SvgIcon width="24" height="16.8" viewBox="0 0 24 16.8" {...restProps}>
    <defs>
      <clipPath id="candidateEvents">
        <path
          fill={iconColor}
          d="M242.6 174.8a2.4 2.4 0 1 0-2.4-2.4 2.4 2.4 0 0 0 2.4 2.4Zm16.8 0a2.4 2.4 0 1 0-2.4-2.4 2.4 2.4 0 0 0 2.4 2.4Zm1.2 1.2h-2.4a2.393 2.393 0 0 0-1.691.7 5.485 5.485 0 0 1 2.816 4.1h2.475a1.2 1.2 0 0 0 1.2-1.2v-1.2a2.4 2.4 0 0 0-2.4-2.4Zm-9.6 0a4.2 4.2 0 1 0-4.2-4.2 4.2 4.2 0 0 0 4.2 4.2Zm2.88 1.2h-.311a5.8 5.8 0 0 1-5.137 0h-.312a4.321 4.321 0 0 0-4.32 4.32v1.08a1.8 1.8 0 0 0 1.8 1.8h10.8a1.8 1.8 0 0 0 1.8-1.8v-1.08a4.321 4.321 0 0 0-4.32-4.32Zm-8.389-.5a2.393 2.393 0 0 0-1.691-.7h-2.4a2.4 2.4 0 0 0-2.4 2.4v1.2a1.2 1.2 0 0 0 1.2 1.2h2.471a5.5 5.5 0 0 1 2.82-4.103Z"
          data-name="Icon awesome-users"
        />
      </clipPath>
    </defs>
    <g
      clipPath="url(#candidateEvents)"
      data-name="candidates Events"
      transform="translate(-239 -167.6)"
    >
      <g fill={iconColor} stroke={iconColor} data-name="Rectangle 1387">
        <path stroke="none" d="M239 164h24v24h-24z" />
        <path fill="none" d="M239.5 164.5h23v23h-23z" />
      </g>
    </g>
  </SvgIcon>
)

export default CandidateEventsIcon
