import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { ExpandMore, Launch } from '@material-ui/icons'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'

import TopRecImage from '../../../assets/images/TopRecLogo.svg'
import RefariWebsiteImage from '../../../assets/images/Rw-grey.svg'
import URLs from '../../../router/routesUrls'
import ConfirmationDialog from './ConfirmationDialog'
import useWindowDimensions from '../hooks/useWindowDimension'

const useStyles = makeStyles((theme) => ({
  toprecButton: {
    padding: theme.spacing(1), // Add padding for better visibility
    background: 'linear-gradient(45deg, #ff169a 30%, #ff76c4 90%)', // Gradient background color
    marginRight: '2rem',
    textTransform: 'none'
  },

  websiteButton: {
    padding: theme.spacing(1),
    background: 'linear-gradient(45deg, #BBBBBB 30%, #F5F5F5 90%)',
    textTransform: 'none'
  },

  startIcon: {
    margin: 0,
    marginRight: theme.spacing(1), // Add margin between button text and end icon
    borderRight: '1px solid black', // Add a border line between end icon and button text
    paddingRight: '4px'
  },
  icon: {
    fontSize: '1.5rem'
  },
  dialog: {
    textAlign: 'center',
    position: 'relative'
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2)
  },
  paragraph: {
    color: 'black',
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white'
  }
}))

const publicUrls = [
  URLs.createJobAlert.path,
  URLs.socialiseJob.path,
  URLs.pitchMeUrlPage.path,
  URLs.createTalentAlert.path
] as const

type DashboardFloatProps = {
  rootStore: any
}

const DashboardFloat: React.FC<DashboardFloatProps> = (
  props: DashboardFloatProps
) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState<'' | 'toprec' | 'website'>('')
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false)

  const { height } = useWindowDimensions()

  const handleClickOpen = (type: 'toprec' | 'website') => {
    setOpen(type)
  }

  const handleClose = () => {
    setOpen('')
  }

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank')
  }

  const getDialogBackground = () => {
    if (open === 'toprec') {
      return 'linear-gradient(to right top, #ff169a, #ed65cd, #d591ee, #c3b3fe, #c2cfff, #c5d2ff, #c9d5fe, #ccd8fe, #ccc5ff, #d9aff7, #ec95e4, #ff76c4)'
    } else if (open === 'website') {
      return 'linear-gradient(45deg, #BBBBBB 30%, #F5F5F5 90%)'
    } else {
      return 'linear-gradient(45deg, #BBBBBB 30%, #F5F5F5 90%)'
    }
  }

  const disablePopup = async () => {
    if (!open) return
    const ctaKey = open === 'toprec' ? 'cta_toprec' : 'cta_website'
    await props.rootStore.disableCtaPopup(ctaKey)
    setIsConfirmationDialogOpen(false)

    setOpen('')
    await props.rootStore.fetchAgencyData()
  }

  const { agency, user } = props.rootStore

  if (!agency?.id || !user?.id || user.isSystemAdminUser) return null
  const showToprecFloat = agency.ctaToprec
  const showWebsiteFloat = agency.ctaWebsite

  const isPublicPath = publicUrls.some((url) =>
    window.location.pathname.match(url)
  )
  if (isPublicPath) return null

  /**
   * @note only available for admin users who are logged in as agency
   */
  const showDisablePopupButton =
    props.rootStore.user.role === 'admin' &&
    props.rootStore.user.userType === 'agency' &&
    props.rootStore.user.isAdminLoggedAs

  return (
    <div
      style={{
        position: 'fixed',
        right: '0%',
        bottom:
          height < 750 ? `${(height - 500) / 2}px` : `${(height - 700) / 2}px`,
        zIndex: 1000,
        transform: 'rotate(90deg)', // Rotate the button 90 degrees
        transformOrigin: 'top right',
        minWidth: height < 750 ? '450px' : '650px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      {showToprecFloat && (
        <Button
          variant="contained"
          className={classes.toprecButton}
          classes={{ startIcon: classes.startIcon }}
          startIcon={<ExpandMore classes={{ root: classes.icon }} />}
          onClick={() => handleClickOpen('toprec')}
        >
          {height < 750 && showWebsiteFloat
            ? 'Improve Reputation?'
            : 'Want to improve your reputation?'}
        </Button>
      )}
      {showWebsiteFloat && (
        <Button
          variant="contained"
          className={classes.websiteButton}
          classes={{ startIcon: classes.startIcon }}
          startIcon={<ExpandMore classes={{ root: classes.icon }} />}
          onClick={() => handleClickOpen('website')}
        >
          {height < 750 && showToprecFloat
            ? 'Upgrade Website?'
            : 'Want to upgrade your website?'}
        </Button>
      )}

      <Dialog
        open={Boolean(open)}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        fullWidth
        maxWidth="md"
      >
        <div style={{ background: getDialogBackground(), padding: '2rem' }}>
          <DialogTitle id="dialog-title" className={classes.dialog}>
            <div className={classes.heading}>
              {open === 'toprec' && (
                <img
                  src={TopRecImage}
                  style={{ maxWidth: '8rem' }}
                  className="img-fluid"
                  alt=""
                />
              )}
              {open === 'website' && (
                <img
                  src={RefariWebsiteImage}
                  style={{ maxWidth: '10rem' }}
                  className="img-fluid"
                  alt=""
                />
              )}
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialog}>
            {open === 'toprec' && (
              <p className={classes.paragraph}>
                TopRec allows you to monitor your team&apos;s Performance,
                Receive Ratings & Feedback, Verified Testimonials and in turn,
                increase your Sales & Enhance Trust in your Brand & Recruiters.
              </p>
            )}
            {open === 'website' && (
              <p className={classes.paragraph}>
                Do you require a fresh or new & improved website? We at Refari
                can build you a high quality website suited to meet your needs!
              </p>
            )}
          </DialogContent>
          <DialogActions
            className={classes.dialog}
            style={{ justifyContent: 'center' }}
          >
            <Button
              className={classes.button}
              onClick={() =>
                openLinkInNewTab(
                  open === 'toprec'
                    ? 'https://toprec.co'
                    : 'https://www.refari.co/why-build-your-recruitment-website-with-refari/'
                )
              }
              startIcon={<Launch />}
              style={
                open === 'toprec'
                  ? { backgroundColor: '#ff169a' }
                  : { backgroundColor: 'black' }
              }
            >
              Find Out More
            </Button>
            <Button
              className={classes.button}
              onClick={() =>
                openLinkInNewTab(
                  open === 'toprec'
                    ? 'https://toprec.co/#RequestPersonalDemo/'
                    : 'https://www.refari.co/request-a-personal-demo/'
                )
              }
              startIcon={<Launch />}
              style={
                open === 'toprec'
                  ? { backgroundColor: '#ff169a' }
                  : { backgroundColor: 'black' }
              }
            >
              Request A Demo Now
            </Button>
          </DialogActions>
          {showDisablePopupButton && (
            <DialogActions
              className={classes.dialog}
              style={{ justifyContent: 'center' }}
            >
              <Button
                className={classes.button}
                onClick={() => setIsConfirmationDialogOpen(true)}
                startIcon={<Launch />}
                style={{ backgroundColor: 'red' }}
              >
                Disable Popup
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={disablePopup}
      />
    </div>
  )
}

export default DashboardFloat
