/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/state-in-constructor */
import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Dialog, TextField } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import { action, observable } from 'mobx'

import CloseButton from '../buttons/CloseButton'
import { edmAlert } from '../../../constants/edm'
import { NumberCounter } from '../forms/NumberCounter'

@inject('edmStore')
@observer
class EdmAlertModal extends React.Component {
  state = {
    text: '',
    link: '',
    selectedIcon: {},
    update: true
  }

  @observable
  isOpen = false

  toggleModal = () => {
    const { edmStore: { modal, setData } } = this.props
    setData('modal', !modal)
    setData('selectedIcon', {})
    this.setState({
      text: '',
      link: '',
      selectedIcon: {},
      update: true
    })
  }

  @action
  updateOpenStatus = () => {
    this.isOpen = !this.isOpen
  }

  componentDidUpdate() {
    const {
      selectedIcon,
      modal,
      categoriesList,
      fetchCategories,
      fetchLocations,
      fetchWorktypes,
      jobAlertLink,
      setData
    } = this.props.edmStore
    if (this.state.update && selectedIcon.id && this.state.selectedIcon.id !== selectedIcon.id) {
      this.setState({
        update: false,
        text: selectedIcon.text,
        link: selectedIcon.link,
        selectedIcon: selectedIcon.selectedIcon
      })
    }

    if (modal && !this.isOpen) {
      this.updateOpenStatus()
      if (!categoriesList.length) {
        fetchCategories()
        fetchLocations()
        fetchWorktypes()
      }
    }
    if (jobAlertLink) {
      this.setState({ link: jobAlertLink })
      setData('jobAlertLink', '')
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleApply = () => {
    const { text, link, selectedIcon } = this.state
    const { edmStore } = this.props
    const data = {
      text,
      link,
      selectedIcon,
      id: edmStore.selectedIcon.id || Date.now()
    }
    this.props.edmStore.addNewIcon(data)
    this.toggleModal()
  }

  handleSelected = (icon) => () => {
    this.setState({ selectedIcon: icon })
  }

  handleGenerateLink = () => {
    const { edmStore: { setData, modal } } = this.props
    setData('modal', !modal)
    setData('modalSubscription', true)
  }

  render() {
    const {
      edmStore: { modal },
      theme,
    } = this.props

    const style = {
      title: { color: theme.palette.primary1Color }
    }

    const { text, link, selectedIcon } = this.state

    return (
      <Dialog
        className="modal"
        open={modal}
        onClose={this.toggleModal}
        fullWidth
      >
        <div className="modal-content modal-content-edm">
          <CloseButton onClose={this.toggleModal} />
          <h3 style={style.title}>
            Add a job alert call to action
          </h3>
          <p className="modal-content-edm--step">
            Step 1: Add a job alert
          </p>
          <TextField
            name="link"
            placeholder="Paste link here:"
            label="Paste link here:"
            onChange={this.handleChange}
            fullWidth
            value={link}
            style={{ marginTop: '0px' }}
          />
          <div className="text-right">
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleGenerateLink}
              className="m-t-2"
            >
              Generate link
            </Button>
          </div>
          <p className="modal-content-edm--step m-t-2">
            Step 2: Add text for this job alert
          </p>
          <TextField
            name="text"
            placeholder="Enter text here:"
            label="Enter text here:"
            onChange={this.handleChange}
            fullWidth
            inputProps={{
              maxLength: 50
            }}
            value={text}
            style={{ marginTop: '0px' }}
          />
          {
            text && <NumberCounter data={text} length={50} />
          }
          <p className="modal-content-edm--step m-t-2">
            Step 3: Choose picture
          </p>
          <div className="modal-content-edm__icon-list">
            {
              edmAlert.map((el) => {
                let color = '#757575'
                if (el.id === selectedIcon.id) {
                  color = style.title.color
                }
                return (
                  <div className="modal-content-edm--icon" key={el.id} onClick={this.handleSelected(el)}>
                    {el.icon(color)}
                  </div>
                )
              })
            }
          </div>
          <div className="text-center m-t-2 modal-content-edm__apply">
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleApply}
              disabled={!text || !link || !selectedIcon.id}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default withTheme(EdmAlertModal)
