import { Tab, withStyles } from '@material-ui/core'

const CustomTab = withStyles({
  root: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  }
})(Tab)

export { CustomTab }
