/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
import { action, computed, observable } from 'mobx'
import FilterGroup from './FilterGroup'

export class FilterCheck {
  @observable selected
  @observable isOpenMenu
  @observable.ref children = []
  @action
  toggle = () => {
    this.selected = !this.selected

    if (this.children instanceof FilterGroup) {
      if (this.selected) {
        this.children.applyAll()
      } else {
        this.children.resetAll()
      }
    }
  }
  @action
  reset = () => {
    if (this.selected) {
      this.selected = false
    }

    if (this.children instanceof FilterGroup) {
      this.children.resetAll()
    }
  }
  @action
  setStatus = (status) => {
    this.selected = status
  }
  @action
  applyFilter = () => {
    this.selected = true
  }

  @action
  toggleOpen = () => {
    this.isOpenMenu = !this.isOpenMenu
  }

  constructor(data = {}, dataLength, parent) {
    this.key = data.key || data.id
    this.selected = parent && parent.selected ? true : data.selected || false
    this.count = data.doc_count
    this.label = data.label || data.name
    this.parent = parent
    this.minSalary = data.min_salary
    this.maxSalary = data.max_salary
    this.children =
      data.children && data.children.length
        ? new FilterGroup({ data: data.children, dataLength, parent: this })
        : []

    if (this.children instanceof FilterGroup && this.children.isSelectedAll) {
      this.selected = true
    }
    let menuOpen = this.selected
    if (!menuOpen && this.children.data && this.children.data.length) {
      for (let i = 0; i < this.children.data.length; i++) {
        if (this.children.data[i].isOpenMenu) {
          menuOpen = true
        }
      }
    }
    if (dataLength && dataLength === 1) {
      menuOpen = true
    }
    this.isOpenMenu = menuOpen
  }

  @computed
  get value() {
    let arrayOfValues =
      this.children instanceof FilterGroup ? this.children.groupValue : []

    if (this.selected && !(this.children instanceof FilterGroup)) {
      arrayOfValues = [this.key]
    }

    if (this.children instanceof FilterGroup && this.children.isSelectedAll) {
      arrayOfValues = [this.key, ...arrayOfValues]
    }

    if (this.children instanceof FilterGroup && !this.children.isSelectedAll) {
      arrayOfValues = [...arrayOfValues]
    }

    return arrayOfValues
  }
}

export default FilterCheck
