/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Dialog } from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'

const Modal = ({
  children, PaperProps, theme, ...restProps
}) => (
  <Dialog
    fullWidth
    PaperProps={{
      ...PaperProps,
      style: PaperProps
        ? {
          borderRadius: theme.paper.borderRadius,
          ...PaperProps.style
        }
        : {
          borderRadius: theme.paper.borderRadius
        }
    }}
    style={{
      border: 'none',
      backgroundColor: theme.palette.popupBackground,
      ...restProps.style
    }}
    {...restProps}
  >
    {children}
  </Dialog>
)

export default withTheme(Modal)
