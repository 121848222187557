import React, { forwardRef } from 'react'
import { IconButton } from '@material-ui/core'
import blue_calendar from '../../../assets/images/calendar/blue.svg'
import green_calendar from '../../../assets/images/calendar/green.svg'
import orange_calendar from '../../../assets/images/calendar/orange.svg'
import red_calendar from '../../../assets/images/calendar/red.svg'

// eslint-disable-next-line react/display-name
const CalendarIcon = forwardRef((props, ref) => {
  const calendar_icon_list = {
    blue: blue_calendar,
    green: green_calendar,
    orange: orange_calendar,
    red: red_calendar
  }

  const {
    width = 48,
    height = 48,
    variant = 'blue',
    cursor = 'default',
    ...restProps
  } = props

  return (
    <IconButton
      ref={ref}
      style={{
        width,
        height,
        padding: 0,
        display: 'inline-block',
        backgroundColor: 'transparent',
        cursor: cursor
      }}
      disableFocusRipple
      disableRipple
      {...restProps}
    >
      {/* <div className={`wrapper-points-section wrapper-small ${pointImg[level].img}-wrapper`}> */}
      {/* <div className={`core-points-section ${pointImg[level].img}-core`}> */}
      <img
        className="all-badges--roles-img"
        src={calendar_icon_list[variant]}
        alt=""
      />
      {/* </div> */}
      {/* </div> */}
    </IconButton>
  )
})

export default CalendarIcon
